import { Col, Row } from '@themesberg/react-bootstrap';
import {
  ConfirmationModal,
  EmptyState,
  PageTitle,
  PermissionErrorToast,
  Preloader,
  PrimaryLayoutBox
} from 'components';
import { MetaDataContext } from 'contexts';
import {
  getSurveyLibraryTemplateDetails,
  launchSurvey,
  removeSurveyLibraryTemplateSchedule,
  saveSurveyLibraryAudience,
  setSurveyLibraryTemplateDuration,
  setSurveyLibraryTemplateSchedule
} from 'data';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  FormErrors,
  IMetadata,
  IRouteParams,
  ISurvey,
  ISurveyAudienceRequestData,
  ISurveyDurationRequestData,
  ISurveySchedulerFormValues,
  ISurveySchedulerRequestData,
  PermissionErrorTypes
} from 'interfaces';
import moment from 'moment';
import * as React from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { concatMap, finalize, tap } from 'rxjs';
import {
  NOTIFICATION_SETTINGS, SURVEY_DURATION_SPAN_OPTIONS,
  SURVEY_DURATION_TYPES,
  SURVEY_DURATION_UNIT_OPTIONS
} from 'teamble-constants';

import SurveyLaunchWidget from './components/SurveyLaunchWidget';
import SurveySummaryWidget from './components/SurveySummaryWidget';

interface ISurveySchedulerProps {
}

const SurveyScheduler: React.FunctionComponent<ISurveySchedulerProps> = () => {

  // State Hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSurveyLoading, setIsSurveyLoading] = React.useState<boolean>(false);
  const [surveyDetails, setSurveyDetails] = React.useState<ISurvey | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);

  // Route Hooks
  const history = useHistory();
  const { surveyId } = useParams<IRouteParams>();

  // App hook
  const app = useApp();

  // Metadata
  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  // Scheduler Form
  const surveySchedulerForm = useFormik<ISurveySchedulerFormValues>({
    initialValues: {
      durationType: SURVEY_DURATION_TYPES[0],
      durationSpan: SURVEY_DURATION_SPAN_OPTIONS[0],
      durationUnit: SURVEY_DURATION_UNIT_OPTIONS[0],
      closeSpecificTimeAt: null,
      schedulerFrequency: null,
      schedulerDateTime: null,
      notificationSetting: NOTIFICATION_SETTINGS.options[1],
    },
    validateOnBlur: false,
    onSubmit: () => { }
  });


  // Get the details of the Survey
  React.useEffect(
    () => {

      if (!surveyId) {
        setSurveyDetails(null);
        return;
      }

      setIsSurveyLoading(true);

      getSurveyLibraryTemplateDetails(surveyId, metadata, app)
        .pipe(
          finalize(() => setIsSurveyLoading(false))
        )
        .subscribe(
          (surveyResponse) => {
            setSurveyDetails(surveyResponse.data.data);
          }
        );

    },
    [app, metadata, surveyId]
  );


  // Returns the errors if any for the Schedule Widget
  const getSchedulerErrors = (): {
    errors: FormikErrors<ISurveySchedulerFormValues>,
    fieldTouched: FormikTouched<ISurveySchedulerFormValues>
  } | null => {

    let isError: boolean = false;
    const errors: FormikErrors<ISurveySchedulerFormValues> = {};
    const fieldTouched: FormikTouched<ISurveySchedulerFormValues> = {};

    // Scheduler Datetime validations
    if (!surveySchedulerForm.values.schedulerDateTime) {
      errors.schedulerDateTime = FormErrors.Required;
      fieldTouched.schedulerDateTime = true;
      isError = true;
    }
    if (moment(surveySchedulerForm.values.schedulerDateTime).isBefore(moment())) {
      errors.schedulerDateTime = FormErrors.MinDateToday;
      fieldTouched.schedulerDateTime = true;
      isError = true;
    }

    if (!surveySchedulerForm.values.schedulerFrequency) {
      errors.schedulerFrequency = FormErrors.Required;
      fieldTouched.schedulerFrequency = true;
      isError = true;
    }

    if (isError) {
      return {
        errors,
        fieldTouched
      };
    }

    return null;
  };


  // Returns the errors if any for the Schedule Widget
  const getDurationErrors = (): {
    errors: FormikErrors<ISurveySchedulerFormValues>,
    fieldTouched: FormikTouched<ISurveySchedulerFormValues>
  } | null => {

    let isError: boolean = false;
    const errors: FormikErrors<ISurveySchedulerFormValues> = {};
    const fieldTouched: FormikTouched<ISurveySchedulerFormValues> = {};

    if (surveySchedulerForm.values.durationType.value !== 'onSpecificTime') {
      return null;
    }

    if (!surveySchedulerForm.values.closeSpecificTimeAt) {
      errors.closeSpecificTimeAt = FormErrors.Required;
      fieldTouched.closeSpecificTimeAt = true;
      isError = true;
    }

    if (isError) {
      return {
        errors,
        fieldTouched
      };
    }

    return null;
  };


  // Format the data to send in the request for the Survey Duration
  const getSurveyDurationRequestData = (): Partial<ISurveyDurationRequestData> => {

    let requestData: Partial<ISurveyDurationRequestData> = {
      surveyTemplateId: surveyId,
      type: surveySchedulerForm.values.durationType.value,
    };

    if (surveySchedulerForm.values.durationType.value === 'automatic') {
      return {
        ...requestData,
        unit: surveySchedulerForm.values.durationUnit!.value,
        span: +surveySchedulerForm.values.durationSpan!.value,
      }
    }

    if (surveySchedulerForm.values.durationType.value === 'onSpecificTime') {
      return {
        ...requestData,
        timestamp: surveySchedulerForm.values.closeSpecificTimeAt?.getTime()
      }
    }

    return requestData;
  };


  // Format the data to send in the request for the Survey Scheduler
  const getSurveySchedulerRequestData = (): ISurveySchedulerRequestData | null => {

    if (
      !surveySchedulerForm.values.schedulerDateTime ||
      !surveySchedulerForm.values.schedulerFrequency
    ) {
      return null;
    }

    return {
      surveyTemplateId: surveyId || '',
      timestamp: surveySchedulerForm.values.schedulerDateTime.getTime(),
      frequency: surveySchedulerForm.values.schedulerFrequency.value,
      timezone: -(new Date().getTimezoneOffset() * 60 * 1000),
    };
  }


  // Format the data to send in the request for the Survey Notification
  const getSurveyNotificationRequestData = (): Partial<ISurveyAudienceRequestData> => {

    return {
      surveyTemplateId: surveyId,
      workspaceId: surveyDetails!.workspaceId,
      channelId: surveyDetails!.channelId,
      notifications: surveySchedulerForm.values.notificationSetting.value,
    };
  };


  const resetSurveySchedulerForm = (newValues?: Partial<ISurveySchedulerFormValues>) => {
    surveySchedulerForm.resetForm({
      values: {
        ...surveySchedulerForm.values,
        ...newValues,
      }
    });
  };


  // Make API call to save the changes of the Survey Duration
  const handleDurationUpdate = (): void => {

    const durationErrors = getDurationErrors();

    if (durationErrors) {
      surveySchedulerForm.setTouched({
        ...surveySchedulerForm.touched,
        ...durationErrors.fieldTouched
      });
      surveySchedulerForm.setErrors({
        ...surveySchedulerForm.errors,
        ...durationErrors.errors
      });
      return;
    };

    setIsLoading(true);

    const setSurveyLibraryTemplateDuration$ = setSurveyLibraryTemplateDuration(
      getSurveyDurationRequestData()
    )
      .pipe(
        tap(
          (response) => {
            toast.success(response.data.message);
            setSurveyDetails(response.data.data);
            resetSurveySchedulerForm();
          }
        ),
        finalize(() => setIsLoading(false))
      );

    let newFormValues: Partial<ISurveySchedulerFormValues> | null = null;

    switch (surveySchedulerForm.values.durationType.value) {

      case 'automatic': {
        newFormValues = {
          closeSpecificTimeAt: null,
        }
        break;
      }

      case 'onSpecificTime': {
        newFormValues = {
          durationSpan: null,
          durationUnit: null,
        }
        break;
      }

      case 'closeAfterAllSubmits':
      case 'manual': {

        newFormValues = {
          durationSpan: null,
          durationUnit: null,
          closeSpecificTimeAt: null,
        };
        break;
      }
    }

    if (newFormValues) {
      setSurveyLibraryTemplateDuration$.subscribe(
        () => {
          resetSurveySchedulerForm(newFormValues as Partial<ISurveySchedulerFormValues>);
        }
      );
    } else {
      setSurveyLibraryTemplateDuration$.subscribe();
    }

  };


  const handleSchedulerUpdate = (): void => {

    const schedulerErrors = getSchedulerErrors();

    if (schedulerErrors) {
      surveySchedulerForm.setTouched({
        ...surveySchedulerForm.touched,
        ...schedulerErrors.fieldTouched
      });
      surveySchedulerForm.setErrors({
        ...surveySchedulerForm.errors,
        ...schedulerErrors.errors
      });
      return;
    };

    if (!surveyId) {
      return;
    }

    const requestData = getSurveySchedulerRequestData();

    if (!requestData) {
      return;
    }

    setIsLoading(true);

    setSurveyLibraryTemplateSchedule(requestData)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          resetSurveySchedulerForm();
          setSurveyDetails(response.data.data);
          toast.success(response.data.message);
        }
      );

  };


  const handleSchedulerClear = (): void => {

    if (!surveyId) {
      return;
    }

    setIsLoading(true);
    removeSurveyLibraryTemplateSchedule(surveyId)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          resetSurveySchedulerForm({
            schedulerFrequency: null,
            schedulerDateTime: null,
          });
          setSurveyDetails(response.data.data);
        }
      );

  }


  const handleLaunchSurvey = (): void => {

    if (!surveyId) {
      return;
    }

    setIsLoading(true);
    launchSurvey(surveyId)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          if (response.data.errorInfo?.length) {
            toast.error(
              <PermissionErrorToast
                errorInfos={response.data.errorInfo}
                permissionErrorType={PermissionErrorTypes.LaunchSurvey}
              />,
              {
                autoClose: 10000,
                icon: false
              }
            );
          } else {
            toast.success(response.data.message);
          }
          history.push(Routes.ManageSurveys.path);
        }
      );

  };


  const handleSaveClick = (isLaunching: boolean = false): void => {

    const schedulerErrors = getSchedulerErrors();
    const durationErrors = getDurationErrors();

    const isOnlyOneSchedulerFieldInvalid = schedulerErrors &&
      Object.keys(schedulerErrors.errors).length === 1;
    const isDurationErrors = !!(durationErrors && Object.keys(durationErrors.errors).length);
    let errors: FormikErrors<ISurveySchedulerFormValues> = {};
    let fieldTouched: FormikTouched<ISurveySchedulerFormValues> = {};

    if (isOnlyOneSchedulerFieldInvalid) {
      fieldTouched = {
        ...surveySchedulerForm.touched,
        ...schedulerErrors.fieldTouched
      };
      errors = {
        ...surveySchedulerForm.errors,
        ...schedulerErrors.errors
      };
    }

    if (isDurationErrors) {
      fieldTouched = {
        ...fieldTouched,
        ...durationErrors.fieldTouched
      };
      errors = {
        ...errors,
        ...durationErrors.errors
      };
    }

    if (isOnlyOneSchedulerFieldInvalid || isDurationErrors) {
      if (isLaunching) {
        handleLaunchSurvey();
      } else {
        surveySchedulerForm.setTouched(fieldTouched);
        surveySchedulerForm.setErrors(errors);
      }
      return;
    }

    let requests = setSurveyLibraryTemplateDuration(
      getSurveyDurationRequestData()
    ).pipe(
      tap(
        (response) => {
          if (!isLaunching) {
            setSurveyDetails(response.data.data);
          }
        }
      ),
      concatMap(
        () => {

          return saveSurveyLibraryAudience(
            getSurveyNotificationRequestData()
          ).pipe(
            tap(
              (response) => {
                if (!isLaunching) {
                  toast.success(response.data.message);
                }
              }
            )
          )

        }
      )
    );

    const requestData = getSurveySchedulerRequestData();
    if (requestData) {
      requests = requests.pipe(
        concatMap(
          () => {
            return setSurveyLibraryTemplateSchedule(requestData);
          }
        ),
        tap(
          (response) => {
            if (!isLaunching) {
              setSurveyDetails(response.data.data);
            }
          }
        ),
      )
    }

    setIsLoading(true);
    requests
      .pipe(
        finalize(() => {
          if (!isLaunching) {
            setIsLoading(false)
          }
        })
      )
      .subscribe(
        () => {
          resetSurveySchedulerForm();
          if (isLaunching) {
            handleLaunchSurvey();
          }
        }
      );

  };


  const handleNotificationUpdate = (): void => {

    setIsLoading(true);

    saveSurveyLibraryAudience(
      getSurveyNotificationRequestData()
    )
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          resetSurveySchedulerForm();
          toast.success(response.data.message);
        }
      );
  };


  return (
    <>
      <PageTitle title={Routes.SurveyScheduler.title} />
      <PrimaryLayoutBox title={Routes.SurveyScheduler.title}>
        <div className='position-relative h-100 overflow-hidden'>
          {
            isSurveyLoading ?
              (<Preloader show={isSurveyLoading} />) :
              (
                surveyDetails ?
                  (
                    <Row className='h-100 overflow-hidden'>
                      <Col className='h-100 overflow-hidden'>
                        <SurveyLaunchWidget
                          isLoading={isLoading}
                          surveyDetails={surveyDetails}
                          surveySchedulerForm={surveySchedulerForm}
                          onSaveClick={() => handleSaveClick(false)}
                          onSchedulerClear={handleSchedulerClear}
                          onDurationUpdate={handleDurationUpdate}
                          onSchedulerUpdate={handleSchedulerUpdate}
                          onLaunchSurvey={() => setShowConfirmationModal(true)}
                          onNotificationUpdate={handleNotificationUpdate}
                        />
                      </Col>
                      <Col className='h-100 overflow-hidden'>
                        <SurveySummaryWidget surveyDetails={surveyDetails} />
                      </Col>
                    </Row>
                  ) :
                  (
                    <EmptyState message={<> No Survey Data available. </>} />
                  )
              )
          }
        </div>
      </PrimaryLayoutBox>
      <ConfirmationModal
        title='Launch Survey'
        show={showConfirmationModal}
        onPositiveResponse={() => handleSaveClick(true)}
        onCloseClick={() => setShowConfirmationModal(false)}
      >
        <p>
          Are you sure to launch the survey <strong>{surveyDetails?.title}</strong>?
        </p>
      </ConfirmationModal>
      <Prompt
        when={surveySchedulerForm.dirty}
        message='You have unsaved changes. Are you sure to leave the page?' />
    </>
  );
};

export default SurveyScheduler;
