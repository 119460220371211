import { CustomModal } from 'components';
import { IModalProps } from 'interfaces';
import React from 'react';

import SettingsForm from '../components/SettingsForm';

interface ISettingsModalProps extends IModalProps { }

const SettingsModal: React.FC<ISettingsModalProps> = (
  { show, onClose }
) => {

  return (
    <CustomModal
      title='Settings'
      show={show}
      onClose={onClose}
      scrollable={false}
    >
      <SettingsForm onClose={onClose} />
    </CustomModal>
  );
};

export default SettingsModal;