import { IListReducerAction } from 'interfaces';


export const listReducer = <T extends { id?: string }>(
  state: T[],
  action: IListReducerAction<T>
): T[] => {

  switch (action.type) {

    case 'SET': {
      return action.payload as T[];
    }

    case 'ADD': {
      return [...state, action.payload as T];
    }

    case 'EDIT': {
      return state.map(item => {
        if (item.id === (action.payload as T).id) {
          return action.payload as T;
        }
        return item;
      });
    }

    case 'DUPLICATE': {
      const duplicatingDataIndex = state.findIndex(
        (data) => data.id === (action.payload as T[])[0].id
      );
      return [
        ...state.slice(0, duplicatingDataIndex + 1),
        (action.payload as T[])[1],
        ...state.slice(duplicatingDataIndex + 1, state.length),
      ];
    }

    case 'DELETE': {
      return state.filter(data => data.id !== (action.payload as T).id);
    }

    default:
      return state;
  }
};