export const meetingStatusProps = {
  upcoming: "Upcoming",
  past: "Past",
  archived: "Archived",
};

export const oneOnOneStatus = {
  Preparation: "Preparation",
  Ready: "Ready",
  Canceled: "Canceled",
};

export const dummyData = [
  {
    topic: "Career Development Conversation Meeting",
    oneOnOneWith: "Gokul JS",
    scheduledOn: "06 October 2024",
    status: "Preparation",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Ready",
  },
  {
    topic: "Mid-Year Performance Review Meeting",
    oneOnOneWith: "Christina Tamron",
    scheduledOn: "21 November 2024",
    status: "Preparation",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Ready",
  },
  {
    topic: "Mid-Year Performance Review Meeting",
    oneOnOneWith: "Christina Tamron",
    scheduledOn: "21 November 2024",
    status: "Preparation",
  },
  {
    topic: "Career Development Conversation Meeting",
    oneOnOneWith: "Gokul JS",
    scheduledOn: "06 October 2024",
    status: "Preparation",
  },
  {
    topic: "Mid-Year Performance Review Meeting",
    oneOnOneWith: "Christina Tamron",
    scheduledOn: "21 November 2024",
    status: "Preparation",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Canceled",
  },
  {
    topic: "Mid-Year Performance Review Meeting",
    oneOnOneWith: "Christina Tamron",
    scheduledOn: "21 November 2024",
    status: "Preparation",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Canceled",
  },
  {
    topic: "Career Development Conversation Meeting",
    oneOnOneWith: "Gokul JS",
    scheduledOn: "06 October 2024",
    status: "Preparation",
  },
  {
    topic: "Mid-Year Performance Review Meeting",
    oneOnOneWith: "Christina Tamron",
    scheduledOn: "21 November 2024",
    status: "Preparation",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Canceled",
  },
  {
    topic: "Mid-Year Performance Review Meeting",
    oneOnOneWith: "Christina Tamron",
    scheduledOn: "21 November 2024",
    status: "Preparation",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Canceled",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Canceled",
  },
  {
    topic: "Mid-Year Performance Review Meeting",
    oneOnOneWith: "Christina Tamron",
    scheduledOn: "21 November 2024",
    status: "Preparation",
  },
  {
    topic: "Setting Expectations Meeting",
    oneOnOneWith: "Bill Smith",
    scheduledOn: "09 October 2024",
    status: "Canceled",
  },
];

export const aiOneOnOneTools = {
  specificity: "specificity",
  tone: "tone",
  focus: "focus",
  actionability: "actionability",
};
