import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@themesberg/react-bootstrap';
import { AxiosObservable } from 'axios-observable';
import { ConfirmationModal, CustomDropdown } from 'components';
import Preloader from 'components/Preloader';
import { deleteReviewTemplate, deleteSurvey, duplicateReviewTemplate, duplicateSurvey } from 'data';
import {
  IResponse,
  IReviewOrSurvey,
  IReviewOrSurveyType,
  IReviewTemplate,
  IReviewTemplateDeleteResponse,
  ISimpleMenuOption,
  ISurvey,
  ITableRowCommonActions,
} from 'interfaces';
import * as React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { REVIEW_SURVEY_TABLE_ROW_COMMON_ACTIONS } from 'teamble-constants';

interface IReviewOrSurveyRowActionsProps {
  context: IReviewOrSurvey;
  reviewOrSurveyData: IReviewOrSurveyType;
  onDuplicate: (data: IReviewOrSurveyType) => void;
  onDelete: () => void;
}

const ReviewOrSurveyRowActions: React.FunctionComponent<IReviewOrSurveyRowActionsProps> = (
  { context, reviewOrSurveyData, onDuplicate, onDelete }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const [isRowInUse, setIsRowInUse] = React.useState<boolean>(false);
  const [zIndexStyle, setZIndexStyle] = React.useState<number>(0);


  const duplicateRow = React.useCallback(
    (rowData: IReviewOrSurveyType): void => {

      setIsLoading(true);

      const duplicateSource$: AxiosObservable<IResponse<ISurvey | IReviewTemplate>> = context === 'reviews' ?
        duplicateReviewTemplate(rowData.id) :
        duplicateSurvey(rowData.id);

      duplicateSource$
        .pipe(
          finalize(
            () => setIsLoading(false)
          )
        )
        .subscribe(
          (response) => {
            toast.success(response.data.message);
            onDuplicate(response.data.data);
          }
        );

    },
    [context, onDuplicate]
  );


  const deleteRow = React.useCallback(
    (rowData: IReviewOrSurveyType, isInitialCheck: boolean): void => {

      if (
        context === 'surveys' &&
        isInitialCheck
      ) {
        setShowConfirmationModal(true);
        if ((rowData as ISurvey).schedule) {
          setIsRowInUse(true);
        }
        return;
      }

      setIsLoading(true);
      setIsRowInUse(false);

      const deleteSource$: AxiosObservable<IResponse<void | IReviewTemplateDeleteResponse>> =
        context === 'reviews' ?
          deleteReviewTemplate(rowData.id, isInitialCheck) :
          deleteSurvey(rowData.id, isInitialCheck);

      deleteSource$
        .pipe(
          finalize(
            () => setIsLoading(false)
          )
        )
        .subscribe(
          (response) => {

            if (!response.data.data) {
              toast.success(response.data.message);
              onDelete();
              return;
            }

            const rowDataInUse = context === 'reviews' ?
              (response.data.data as IReviewTemplateDeleteResponse).templateInUse :
              false;

            if (context === 'reviews') {

              if (isInitialCheck) {
                setShowConfirmationModal(true);
              } else {
                deleteRow(rowData, false);
              }

              if (rowDataInUse) {
                setIsRowInUse(true);
              } else {
                setIsRowInUse(false);
              }

            } else {
              deleteRow(rowData, false);
            }
          }
        );

    },
    [context, onDelete]
  );


  const handleActionChange = (action: ISimpleMenuOption): void => {

    switch (action.value) {

      case (ITableRowCommonActions.Duplicate): {
        duplicateRow(reviewOrSurveyData);
        break;
      }

      case (ITableRowCommonActions.Delete): {
        deleteRow(reviewOrSurveyData, true);
        break;
      }

    }
  };


  return (
    <>
      <div
        className='position-relative'
        style={{ zIndex: zIndexStyle }}
      >
        {
          isLoading
            ?
            <Button
              disabled
              variant='link'
              size='sm'
            >
              <Preloader isIcon show={isLoading} />
            </Button>
            :
            <CustomDropdown
              size='sm'
              variant='link'
              icon={faEllipsisV}
              id={`rowAction${reviewOrSurveyData.id}`}
              options={REVIEW_SURVEY_TABLE_ROW_COMMON_ACTIONS}
              onChange={handleActionChange}
              onToggle={(show) => show ? setZIndexStyle(1) : setZIndexStyle(0)}
            />
        }
      </div>
      <ConfirmationModal
        title={context === 'reviews' ? 'Delete Performance Review Questionnaire' : 'Delete Survey'}
        show={showConfirmationModal}
        onPositiveResponse={() => deleteRow(reviewOrSurveyData, false)}
        onCloseClick={() => setShowConfirmationModal(false)}
      >
        <p>
          {
            context === 'reviews' ?
              <> Are you sure to permanently delete the performance review questionnaire <strong>{reviewOrSurveyData.title}</strong>? </> :
              <> Are you sure to permanently delete the survey <strong>{reviewOrSurveyData.title}</strong>? </>
          }
        </p>
        {
          isRowInUse &&
          <p>
            <i>
              {
                context === 'reviews' ?
                  'This performance review questionnaire is being used in a draft performance review.' :
                  'This survey is being used in a scheduled survey.'
              }
            </i>
          </p>
        }
      </ConfirmationModal>
    </>

  );

};

export default ReviewOrSurveyRowActions;
