import {
  FormErrors,
  IChannelsAudience,
  IChoiceWithValue,
  IMemberOption,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  ISimpleMenuOption,
  ISurvey,
  ISurveyAudience,
  ISurveyAudienceBuilderFormValues,
  ISurveyBuilderBlock,
  ISurveyBuilderBlockValidationSchema,
  ISurveyBuilderFormValidationSchema,
  ISurveyBuilderFormValues,
  ISurveyGroupBlockValue,
  ISurveyGroupBlockValueValidationSchema,
  ISurveyInspector,
  ISurveyQuestionBlockValidationSchema,
  ISurveyQuestionTypeConstants,
  ISurveyRequestDataBlock,
  ISurveyRequestDataMultiSelectBlock,
  IUsersAudience,
  SignInWith,
  IGroupsAudience
} from 'interfaces';
import { SURVEY_RESULT_OPTIONS } from 'teamble-constants';
import { getTurndownServiceInstance } from 'utils';
import {
  INPUT_TEXT_QUESTION_VALIDATION_SCHEMA,
  STATIC_TEXT_QUESTION_VALIDATION_SCHEMA,
  SURVEY_GROUP_QUESTION_VALIDATION_SCHEMA,
  SURVEY_MULTI_SELECT_QUESTION_VALIDATION_SCHEMA,
  SURVEY_NAME_SCHEMA,
} from 'validation-schemas';
import * as Yup from 'yup';
import { ISurveyQuestionParentGroupContextValue } from '../contexts/SurveyQuestionParentGroupContext';


export const getSurveyFormInitialValues = (): ISurveyBuilderFormValues => {
  return {
    title: '',
    blocks: []
  };
};


export const getSurveyFormValidationSchema =
  (metadata: IMetadata, app: SignInWith): Yup.SchemaOf<ISurveyBuilderFormValidationSchema> => {

    const multiSelectQuestionValidationSchema = SURVEY_MULTI_SELECT_QUESTION_VALIDATION_SCHEMA;

    const updatedMultiSelectChoicesSchema: Yup.SchemaOf<ISurveyBuilderBlockValidationSchema['multiSelectValue']> =
      app === SignInWith.Slack
        ?
        multiSelectQuestionValidationSchema.shape({
          choices: Yup.array().when(
            'selectedOption',
            {
              is: (selectedOption: IMenuOption) =>
                selectedOption.value ===
                (metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants).CUSTOM_ITEMS,
              then: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.number().required(FormErrors.Required).min(0, FormErrors.MinValue0),
                    choice: Yup.string().required(FormErrors.Required),
                  })
                )
                .required(FormErrors.Required)
                .min(1, FormErrors.Required),
              otherwise: Yup.array()
            }
          )
        }).notRequired().default(undefined)
        :
        multiSelectQuestionValidationSchema.shape({
          choices: Yup.string().when(
            'selectedOption',
            {
              is: (selectedOption: IMenuOption) =>
                selectedOption.value === (metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants)
                  .CUSTOM_ITEMS,
              then: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
              otherwise: Yup.string()
            }
          )
        }).notRequired().default(undefined);

    const blockValidationSchema: Yup.SchemaOf<ISurveyQuestionBlockValidationSchema> = Yup.object().shape({
      staticTextValue: STATIC_TEXT_QUESTION_VALIDATION_SCHEMA,
      inputTextValue: INPUT_TEXT_QUESTION_VALIDATION_SCHEMA,
      multiSelectValue: updatedMultiSelectChoicesSchema,
    });

    const groupValidationSchema: Yup.SchemaOf<ISurveyGroupBlockValueValidationSchema> = SURVEY_GROUP_QUESTION_VALIDATION_SCHEMA.shape({
      questions: Yup.array().of(blockValidationSchema)
    });

    const surveyTemplateFormValidationSchema: Yup.SchemaOf<ISurveyBuilderFormValidationSchema> =
      Yup.object({
        title: SURVEY_NAME_SCHEMA,
        blocks: Yup.array().of(
          blockValidationSchema.shape({
            groupValue: groupValidationSchema,
          })
        )
      });

    return surveyTemplateFormValidationSchema;

  };


export const getSurveyAudienceBuilderFormInitialValues = (): ISurveyAudienceBuilderFormValues => {
  return {
    isAnonymous: false,
    liveResults: SURVEY_RESULT_OPTIONS[0].label,
    audienceType: null,
    selectedAudienceUsers: [],
    selectedSlackChannels: [],
    selectedGroups: [],
    inspectorType: null,
    selectedInspectorsUsers: [],
    minSubmissionCount: 0,
  };
};


export const getMultiSelectChoicesWithValues = (
  app: SignInWith,
  choicesWithValue?: IChoiceWithValue[] | string,
): {
  choices: string[],
  choicesValues: ISurveyRequestDataMultiSelectBlock['choicesValues']
} | null => {

  if (!choicesWithValue?.length) {
    return null;
  }

  switch (app) {

    case SignInWith.Slack: {

      choicesWithValue = choicesWithValue as IChoiceWithValue[];
      const choices = choicesWithValue?.map(choice => choice.choice) || [];
      const choicesValues: ISurveyRequestDataMultiSelectBlock['choicesValues'] = {};

      choicesWithValue?.forEach(
        (choice) => {
          choicesValues[choice.choice] = choice.value as number;
        }
      );

      return {
        choices,
        choicesValues,
      };
    }


    case SignInWith.MsTeams: {
      const choices =
        (choicesWithValue as string)
          .split('\n')
          .filter((c) => !!c);

      return {
        choices,
        choicesValues: {}
      };
    }


    default: {
      return null;
    }

  }

}


const getNonGroupBlockData = (
  surveyBuilderBlock: ISurveyBuilderBlock,
  metaData: IMetadata,
  app: SignInWith
): ISurveyRequestDataBlock | undefined => {

  const questionTypeConstants: ISurveyQuestionTypeConstants =
    metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

  const turndownService = getTurndownServiceInstance();

  switch (surveyBuilderBlock.type) {

    case questionTypeConstants.STATIC_TEXT: {
      return {
        ...surveyBuilderBlock,
        type: surveyBuilderBlock.type,
        text: turndownService.turndown(
          surveyBuilderBlock.staticTextValue?.value || ''
        ),
        typeTitle: surveyBuilderBlock.typeTitle,
      };
    }

    case questionTypeConstants.TEXT_INPUT: {

      return {
        ...surveyBuilderBlock,
        type: surveyBuilderBlock.type,
        typeTitle: surveyBuilderBlock.typeTitle,
        text: surveyBuilderBlock.inputTextValue?.label,
        label: surveyBuilderBlock.inputTextValue?.label,
        ...surveyBuilderBlock.inputTextValue
      };
    }

    case questionTypeConstants.CHOICE: {

      const multiSelectValue = surveyBuilderBlock.multiSelectValue;

      const multiSelectTypeConstants =
        metaData.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
      let choices: string[] = [];
      let choicesValues: ISurveyRequestDataMultiSelectBlock['choicesValues'] = {};

      if (
        multiSelectValue?.selectedOption.value === multiSelectTypeConstants.CUSTOM_ITEMS
      ) {
        const choicesWithValue = getMultiSelectChoicesWithValues(
          app,
          multiSelectValue?.choices,
        );
        choices = choicesWithValue?.choices || [];
        choicesValues = choicesWithValue?.choicesValues || {};
      }

      return {
        ...surveyBuilderBlock,
        type: surveyBuilderBlock.type,
        typeTitle: surveyBuilderBlock.typeTitle,
        text: multiSelectValue?.label,
        label: multiSelectValue?.label,
        family: multiSelectValue?.selectedOption.value,
        required: multiSelectValue?.required,
        dropdown: multiSelectValue?.dropdown,
        track: multiSelectValue?.track,
        multipleAnswers: multiSelectValue?.multipleAnswers,
        isLocked: multiSelectValue?.track || surveyBuilderBlock.isLocked,
        choices,
        choicesValues,
        comment: multiSelectValue?.commentBox ? multiSelectValue?.commentBoxGuide : '',
      };
    }

  }
};


const getGroupBlockData = (
  surveyBuilderBlock: ISurveyBuilderBlock,
  metaData: IMetadata,
  app: SignInWith
): ISurveyRequestDataBlock => {

  return {
    ...surveyBuilderBlock,
    isGroup: true,
    label: surveyBuilderBlock.groupValue?.label,
    description: surveyBuilderBlock.groupValue?.description,
    questions: surveyBuilderBlock.groupValue?.questions.map(
      (question) => getNonGroupBlockData(question, metaData, app) as ISurveyRequestDataBlock
    )
  }

};


export const getSurveyTemplateRequestBlocks =
  (
    surveyBuilderBlocks: ISurveyBuilderBlock[],
    metadata: IMetadata,
    app: SignInWith
  ): ISurveyRequestDataBlock[] => {

    const requestDataBlocks: ISurveyRequestDataBlock[] = [];

    const questionTypeConstants: ISurveyQuestionTypeConstants =
      metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

    surveyBuilderBlocks.forEach(
      (surveyBuilderBlock) => {

        let requestDataBlock: ISurveyRequestDataBlock;

        if (surveyBuilderBlock.type === questionTypeConstants.GROUP) {
          requestDataBlock =
            getGroupBlockData(surveyBuilderBlock, metadata, app) as ISurveyRequestDataBlock;
        } else {
          requestDataBlock =
            getNonGroupBlockData(surveyBuilderBlock, metadata, app) as ISurveyRequestDataBlock;
        }

        requestDataBlocks.push(requestDataBlock);

      }
    );

    return requestDataBlocks;

  }


export const getSurveyAudienceFlatArray =
  (surveyDetails: ISurvey): ISurveyAudience[] => {

    const surveyAudience: ISurveyAudience[] = [];

    surveyDetails.audience.forEach(
      (audience) => {

        switch (audience.type) {

          case 'team': {
            surveyAudience.unshift({
              id: audience.type,
              name: 'Entire Team',
              isChannel: true,
              isWorkspace: false,
            });
            break;
          }

          case 'workspace': {
            surveyAudience.unshift({
              id: audience.type,
              name: 'Entire Workspace',
              isChannel: false,
              isWorkspace: true,
            });
            break;
          }

          case 'channels': {
            (audience as IChannelsAudience).channels.forEach(
              (channel) => {
                surveyAudience.push({
                  id: channel.channelInfo.slack.channelId,
                  name: channel.channelInfo.slack.name,
                  isChannel: true,
                  isWorkspace: false,
                });
              }
            );
            break;
          }

          case 'groups': {
            (audience as unknown as IGroupsAudience).groups.forEach(
              (group) => {
                surveyAudience.push({
                  id: group.channelInfo.msteams.groupId,
                  name: group.name,
                  isChannel: true,
                  isWorkspace: false,
                });
              }
            );
            break;
          }

          case 'users': {
            (audience as IUsersAudience).users.forEach(
              (user) => {
                surveyAudience.push({
                  id: user.userId,
                  name: user.name,
                  isChannel: false,
                  isWorkspace: false,
                });
              }
            );
            break;
          }

        }

      }
    );

    return surveyAudience;

  };


export const getSurveyInspectorsFlatArray =
  (surveyDetails: ISurvey): ISurveyInspector[] => {

    const surveyInspectors: ISurveyInspector[] = [];

    surveyDetails.inspectors.forEach(
      (inspector) => {

        switch (inspector.type) {

          case 'audience': {
            surveyInspectors.unshift({
              id: inspector.type,
              name: 'Audience',
              isAudience: true,
            });
            break;
          }

          case 'users': {
            inspector.users.forEach(
              (user) => {
                surveyInspectors.push({
                  id: user.userId,
                  name: user.name,
                  isAudience: false,
                });
              }
            );
            break;
          }

        }

      }
    );

    return surveyInspectors;

  };


export const getSurveyUsersAsMenuOptions =
  (userKey: keyof ISurvey, surveyDetails: ISurvey, userOptions: IMemberOption[], app: string): ISimpleMenuOption[] => {
    const selectedUserIds: string[] = [];

    (surveyDetails[userKey] as IUsersAudience[]).forEach(
      (option) => {

        switch (option.type) {

          case 'users': {
            selectedUserIds.push(...option.users.map(
              (user) => user.channelInfo[app === SignInWith.Slack ? SignInWith.Slack : SignInWith.MsTeams].userId
            ));
            break;
          }

        }

      }
    );

    const users = userOptions.filter(
      (user) => selectedUserIds.includes(user.value)
    );

    return users;

  };


export const getSurveyChannelsAsMenuOptions =
  (surveyDetails: ISurvey, channelOptions: IMemberOption[]): ISimpleMenuOption[] => {
    const selectedChannelIds: string[] = [];

    (surveyDetails.audience as IChannelsAudience[]).forEach(
      (option) => {

        switch (option.type) {

          case 'channels': {
            selectedChannelIds.push(
              ...option.channels.map(
                (channel) => channel.channelInfo.slack.channelId
              )
            );
            break;
          }

        }

      }
    );

    const channels = channelOptions.filter(
      (user) => selectedChannelIds.includes(user.value)
    );

    return channels;

  };

export const getTeamsGroupsAsMenuOptions =
  (surveyDetails: ISurvey, groupOptions: IMemberOption[]): ISimpleMenuOption[] => {
    const selectedGroupIds: string[] = [];

    (surveyDetails.audience as unknown as IGroupsAudience[]).forEach(
      (option) => {

        switch (option.type) {

          case 'groups': {
            selectedGroupIds.push(
              ...option.groups.map(
                (group) => group.channelInfo.msteams.groupId
              )
            );
            break;
          }

        }

      }
    );

    const groups = groupOptions.filter(
      (group) => selectedGroupIds.includes(group.value)
    );

    return groups;

  };

export const getNewSurveyQuestion = (
  tempId: string,
  questionType: IMenuOption,
  questionTypeConstants: ISurveyQuestionTypeConstants,
  multiSelectQuestionTypeOptions: IMenuOption[],
  app: SignInWith
): Partial<ISurveyBuilderBlock> => {

  switch (questionType.value) {

    case questionTypeConstants.STATIC_TEXT: {
      return {
        tempId,
        type: questionType.value,
        typeTitle: questionType.label,
        isLocked: false,
        staticTextValue: { value: '' } as ISurveyBuilderBlockValidationSchema['staticTextValue']
      };
    }

    case questionTypeConstants.TEXT_INPUT: {
      return {
        tempId,
        type: questionType.value,
        typeTitle: questionType.label,
        isLocked: false,
        inputTextValue: {
          label: '',
          placeholder: 'Type your answer here',
          required: false,
        } as ISurveyBuilderBlockValidationSchema['inputTextValue']
      }
    }

    case questionTypeConstants.CHOICE: {

      return {
        tempId,
        type: questionType.value,
        typeTitle: questionType.label,
        isLocked: false,
        multiSelectValue: {
          label: '',
          choices: app === SignInWith.Slack ? [
            {
              choice: '',
              value: undefined
            }
          ] : '',
          dropdown: false,
          required: false,
          track: false,
          commentBox: false,
          commentBoxGuide: '',
          multipleAnswers: false,
          selectedOption: multiSelectQuestionTypeOptions[0],
        } as ISurveyBuilderBlockValidationSchema['multiSelectValue']
      }
    }

    case questionTypeConstants.GROUP: {
      return {
        tempId,
        type: questionType.value,
        typeTitle: questionType.label,
        isLocked: false,
        groupValue: {
          label: '',
          description: '',
          questions: [],
        } as ISurveyGroupBlockValue
      }
    }

    default: {
      return {}
    }
  }

};


export const getQuestionAccordionIndex = (
  index: number,
  questionType: string,
  questionTypeConstants: ISurveyQuestionTypeConstants,
  groupContext: ISurveyQuestionParentGroupContextValue
): string => {

  let accordionIndex = '';

  if (groupContext.isGroup) {

    accordionIndex = `group_${groupContext.groupIndex}_${index}`;
  } else {

    if (questionType === questionTypeConstants.GROUP) {
      accordionIndex = `group_${index}_0`;
    } else {
      accordionIndex = `nonGroup_${index}`;
    }
  }

  return accordionIndex;
};


export const hasTrackableNotLockedQuestion = (
  questions: ISurveyBuilderBlock[],
  questionTypeConstants: ISurveyQuestionTypeConstants
): boolean => {

  return questions.some(
    (ques) => {

      if (
        ques.type === questionTypeConstants.CHOICE &&
        ques.multiSelectValue?.track &&
        !ques.isLocked
      ) {
        return true;
      }

      if (ques.type === questionTypeConstants.GROUP) {
        return ques.groupValue?.questions.some(
          (groupQues) => groupQues.type === questionTypeConstants.CHOICE &&
            groupQues.multiSelectValue?.track &&
            !groupQues.isLocked
        );
      }

      return false;
    }
  );
};