import { Badge } from '@themesberg/react-bootstrap';
import { ACTION_COLUMN_CONFIG } from '@common/modules/table';
import { Member } from 'components';
import { MetaDataContext } from 'contexts';
import {
  BodyCellProps,
  IActiveReview,
  IActiveReviewAudience,
  IActiveReviewAudienceActionColumnProps,
  IActiveReviewRowStatusTypeConstants,
  IMetadata
} from 'interfaces';
import React from 'react';
import { CellProps, Column } from 'react-table';

import ActiveReviewAudienceActions from '../components/ActiveReviewAudienceActions';


export const useActiveReviewAudienceTableColumns = (
  isReviewClosed: boolean,
  activeReview: IActiveReview,
): readonly Column<IActiveReviewAudience>[] => {

  const metaData = React.useContext(MetaDataContext) as IMetadata;
  const activeReviewRowStatusTypeConstants =
    metaData.activeReviewRowStatusTypes.constants as IActiveReviewRowStatusTypeConstants;


  return React.useMemo<readonly Column<IActiveReviewAudience>[]>(
    () => [
      {
        id: 'reviewee',
        Header: 'Reviewee',
        accessor: (row) => row?.reviewee?.label,
        Cell: (cellProps: CellProps<IActiveReviewAudience>) => {
          return <Member member={cellProps?.row?.original?.reviewee} />;
        }
      },
      {
        Header: 'Reviewer',
        accessor: (row) => row?.reviewer?.label,
        Cell: (cellProps: CellProps<IActiveReviewAudience>) => {

          const reviewer = cellProps?.row?.original?.reviewer;

          return (
            <>
              {
                reviewer?.id
                  ?
                  <Member member={reviewer} />
                  :
                  'Awaiting reviewer'
              }
            </>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'state',
        Cell: (cellProps: CellProps<IActiveReviewAudience>) => {

          const audience = cellProps.row.original;
          let value = cellProps.value;
          if (cellProps.value === activeReviewRowStatusTypeConstants.PENDING) {
            value = 'Not Started';
          } else if (cellProps.value === 'nomination-reviewer-declined') {
            value = 'Nomination Nominee Declined';
          } else if (cellProps.value === 'started') {
            value = 'Started';
          }

          return (
            <>
              <Badge style={{ height: 12, width: 12 }} className='me-1' bg={'primary' || audience.state} />
              <p className='m-0 d-inline text-capitalize'>
                {
                  value
                    ?
                    value.split('-').join(' ')
                    :
                    'Not set'
                }
              </p>
            </>
          )
        },
      },
      {
        ...ACTION_COLUMN_CONFIG,
        Cell: (cellProps: BodyCellProps<IActiveReviewAudience, void, IActiveReviewAudienceActionColumnProps>) => {

          const audience = cellProps.row.original;

          return (
            <>
              <ActiveReviewAudienceActions
                audience={audience}
                activeReview={activeReview}
                isReviewClosed={isReviewClosed}
                onShowResult={() => cellProps.onShowResultClick(audience.id)}
                onRemoveAudiencePair={cellProps.onRemoveAudiencePair}
                onAudiencePairReopen={cellProps.onAudiencePairReopen}
                downloadReport={cellProps.downloadReport}
                onReviewerChange={cellProps.onReviewerChange}
              />
            </>
          )
        },
      }
    ],
    [activeReview, activeReviewRowStatusTypeConstants.PENDING, isReviewClosed]
  );
};