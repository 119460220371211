import { FormErrors, ICreateUserRoleFormValues, IEditUserRoleFormValues } from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const CREATE_USER_ROLE_VALIDATION_SCHEMA: Yup.SchemaOf<ICreateUserRoleFormValues> =
  Yup.object().shape(
    {
      role: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
    }
  );


export const EDIT_USER_ROLE_VALIDATION_SCHEMA: Yup.SchemaOf<IEditUserRoleFormValues> =
  Yup.object().shape(
    {
      role: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      competency: Yup.array().of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
    }
  );
