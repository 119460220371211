import { Button, Col, Form, Modal, Row } from "@themesberg/react-bootstrap";
import { FormField, PageTitle, PrimaryLayoutBox } from "components";
import { FormContext } from "contexts";
import { FormikHelpers, useFormik } from "formik";
import {
  FormFieldTypeConstants,
  IRouteParams,
  ISimpleMenuOption,
} from "interfaces";
import React, { useEffect, useState } from "react";
import { ObjectiveCampaignProps } from "interfaces/objective-campaign.interface";
import { Routes } from "routes";
import { objectiveLaunchValidationSchema } from "./validation";
import { getOrgUsers } from "data";
import { finalize, forkJoin } from "rxjs";
import {
  OBJECTIVE_CAMPAIGN_AUDIENCE_TYPES,
  ObjectiveCampaignTypes,
} from "teamble-constants/objective.constants";
import { useHistory, useParams } from "react-router-dom";
import {
  createObjectiveCampaign,
  getObjectiveCampaignById,
  launchObjectiveCampaign,
  updateObjectiveCampaignId,
} from "data/objective-campaign.data";
import { AxiosError } from "axios";
import { formatDate, mapAudienceType, mapCampaignUsers } from "./utils";
import Axios from "axios-observable";

const ObjectivesLaunchNewReview: React.FC<any> = () => {
  const history = useHistory();
  const { objectivesCampaignId } = useParams<IRouteParams>();
  const [users, setUsers] = React.useState<ISimpleMenuOption[]>([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [isCreateAndLaunch, setIsCreateLaunch] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [isObjectiveCampaignLoading, setIsObjectiveCampaignLoading] =
    useState(false);

  useEffect(() => {
    if (!objectivesCampaignId) return;

    setIsObjectiveCampaignLoading(true);

    forkJoin([getObjectiveCampaignById(objectivesCampaignId), getOrgUsers()])
      .pipe(finalize(() => setIsObjectiveCampaignLoading(false)))
      .subscribe({
        next: ([campaignResponse, orgUserResponse]) => {
          const usersList = orgUserResponse?.data?.data || [];
          const campaignData = campaignResponse?.data?.data?.objectivesCampaign;
          if (campaignData) {
            objectiveForm.setValues({
              title: campaignData.title || "",
              startDate: formatDate(campaignData.startDate),
              endDate: formatDate(campaignData.endDate),
              audienceType: mapAudienceType(campaignData.audience),
              users: mapCampaignUsers(campaignData.audience, usersList),
              notifyEmployees: campaignData?.notifyEmployees ?? false,
              notifyManagers: campaignData?.notifyManagers ?? false,
            });
          }
        },
        error: (error) => {
          console.error("Error fetching campaign or users:", error);
        },
      });
  }, [objectivesCampaignId]);

  const creativeObjective = (result: any, isCreateAndLaunch: boolean) => {
    !isCreateAndLaunch && setIsCreating(true);
    createObjectiveCampaign(
      result?.title,
      result?.audience,
      result?.startDate,
      result?.endDate,
      result?.notifyEmployees,
      result?.notifyManagers
    )
      .pipe(
        finalize(() => {
          setIsCreating(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          if (isCreateAndLaunch) {
            response?.data?.data?.objectivesCampaignId &&
              launchObjectiveCampaign(
                response?.data?.data?.objectivesCampaignId
              )
                .pipe(
                  finalize(() => {
                    setIsCreateLaunch(false);
                    history.replace(`/objective-campaigns/manage/${response?.data?.data?.objectivesCampaignId}`);
                  })
                )
                .subscribe({
                  next: (response) => {
                    setIsCreateLaunch(false);
                  },
                  error: (error: Axios) => {
                    setIsCreateLaunch(false);
                  },
                });
          } else {
            history.replace(
              `${Routes.ObjectiveCampaigns.path}/draft/${response?.data?.data?.objectivesCampaignId}`
            );
          }
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };

  const updateObjectiveCampaign = (result: any, isLaunch: boolean) => {
    if (!objectivesCampaignId) return;

    !isLaunch && setIsUpdating(true);

    updateObjectiveCampaignId(
      objectivesCampaignId,
      result?.title,
      result?.audience,
      result?.startDate,
      result?.endDate,
      result?.notifyEmployees,
      result?.notifyManagers
    )
      .pipe(
        finalize(() => {
          setIsUpdating(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          isLaunch && launchCampaign();
          setIsUpdating(false);
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };

  const submitObjectiveForm = async (
    values: ObjectiveCampaignProps,
    formikHelpers: FormikHelpers<ObjectiveCampaignProps>
  ) => {
    const isLaunch = currentAction === "launch";
    const isCreateAndLaunch = currentAction === "createAndLaunch";
    isLaunch && setIsLaunching(true);
    isCreateAndLaunch && setIsCreateLaunch(true);
    const result: Record<string, any> = {
      ...(values?.title && { title: values.title }),
      ...(values?.startDate && {
        startDate: new Date(values.startDate).getTime(),
      }),
      ...(values?.endDate && { endDate: new Date(values.endDate).getTime() }),
      notifyEmployees: values?.notifyEmployees ?? false,
      notifyManagers: values?.notifyManagers ?? false,
      audience: values?.audienceType?.length
        ? values.audienceType
            .map((item) => {
              switch (item.value) {
                case ObjectiveCampaignTypes.Company:
                  return { type: item.value };
                case ObjectiveCampaignTypes.Users:
                  return {
                    type: item.value,
                    userIds: values?.users?.map((user) => user.value) || [],
                  };
                default:
                  return null;
              }
            })
            .filter(Boolean) // Remove nulls
        : [],
    };
    !objectivesCampaignId
      ? creativeObjective(result, isCreateAndLaunch)
      : updateObjectiveCampaign(result, isLaunch);
  };

  const objectiveForm = useFormik<ObjectiveCampaignProps>({
    initialValues: {
      title: "",
      startDate: "",
      endDate: "",
      audienceType: [],
      users: [],
      notifyEmployees: false,
      notifyManagers: false,
    },
    validationSchema: objectiveLaunchValidationSchema,
    onSubmit: submitObjectiveForm,
    validateOnMount: true,
  });

  useEffect(() => {
    if (
      !objectiveForm.values.audienceType?.find(
        (item) => item.value === ObjectiveCampaignTypes.Users
      )
    ) {
      objectiveForm.setFieldValue("users", []);
      return;
    }

    setIsUserLoading(true);

    const subscription = getOrgUsers()
      .pipe(finalize(() => setIsUserLoading(false)))
      .subscribe((response) => {
        const users = response.data.data;
        setUsers(
          users?.map((item) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          })
        );
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [objectiveForm.values.audienceType]);

  const launchCampaign = () => {
    if (!objectivesCampaignId) return;
    launchObjectiveCampaign(objectivesCampaignId)
      .pipe(
        finalize(() => {
          setIsLaunching(false);
          history.replace(`/objective-campaigns/manage/${objectivesCampaignId}`);
        })
      )
      .subscribe({
        next: (response) => {
          setIsLaunching(false);
        },
        error: (error: Axios) => {
          console.log(error);
        },
      });
  };

  if (isObjectiveCampaignLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <PageTitle title={Routes.LaunchObjectiveCampaigns.title} />
      <PrimaryLayoutBox
        title={Routes.LaunchObjectiveCampaigns.title}
        actions={
          <>
            {objectivesCampaignId && (
              <div className="d-flex align-items-center">
                <Button
                  type="button"
                  onClick={() => {
                    setCurrentAction("update");
                    objectiveForm.handleSubmit();
                  }}
                  className="ms-2"
                  disabled={isUpdating || isLaunching}
                >
                  {isUpdating ? "Updating" : "Update Objectives"}
                </Button>
                <Button
                  onClick={() => {
                    setCurrentAction("launch");
                    objectiveForm.handleSubmit();
                  }}
                  disabled={isUpdating || isLaunching}
                  type="button"
                  className="ms-2"
                >
                  {isLaunching ? "Launching" : "Launch Campaign"}
                </Button>
              </div>
            )}

            {!objectivesCampaignId && (
              <div className="d-flex align-items-center">
                <Button
                  type="button"
                  className="ms-2"
                  disabled={isCreating || isCreateAndLaunch}
                  onClick={() => {
                    setCurrentAction("create");
                    objectiveForm.handleSubmit();
                  }}
                >
                  {isCreating ? "Creating..." : "Create Objective"}
                </Button>
                <Button
                  type="button"
                  className="ms-2"
                  disabled={isCreating || isCreateAndLaunch}
                  onClick={() => {
                    setCurrentAction("createAndLaunch");
                    objectiveForm.handleSubmit();
                  }}
                >
                  {isCreateAndLaunch
                    ? "Creating & Launching..."
                    : "Create & Launch Campaign"}
                </Button>
              </div>
            )}
          </>
        }
      >
        <div className="position-relative flex-grow-1 overflow-y-auto">
          <Form onSubmit={objectiveForm.handleSubmit}>
            <Modal.Body>
              <FormContext.Provider value={objectiveForm}>
                <Row>
                  <Col xs={12}>
                    <FormField
                      label="Title"
                      size="sm"
                      controlName="title"
                      controlId="title"
                      placeholder="Enter title..."
                      type={FormFieldTypeConstants.Text}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <FormField
                      label="Start Date"
                      controlId="startDate"
                      type={FormFieldTypeConstants.Datepicker}
                      controlName="startDate"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <FormField
                      label="End Date"
                      controlId="endDate"
                      type={FormFieldTypeConstants.Datepicker}
                      controlName="endDate"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <FormField
                      multiple
                      label="Audience"
                      controlId={`audienceType`}
                      type={FormFieldTypeConstants.Select}
                      options={OBJECTIVE_CAMPAIGN_AUDIENCE_TYPES}
                      controlName="audienceType"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    {objectiveForm.values.audienceType?.find(
                      (item) => item.value === ObjectiveCampaignTypes.Users
                    ) && (
                      <FormField
                        multiple
                        options={users}
                        label={"Users"}
                        isLoading={isUserLoading}
                        controlId={`users`}
                        type={FormFieldTypeConstants.Select}
                        controlName="users"
                      />
                    )}
                  </Col>
                  <Col xl={12} md={12} xxl={12}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "12px",
                      }}
                    >
                      <FormField
                        label="Notify Managers"
                        controlId="notifyManagersCheckbox"
                        type={FormFieldTypeConstants.Checkbox}
                        controlName="notifyManagers"
                      />
                      <FormField
                        label="Notify Employee"
                        controlId="notifyEmployeeCheckbox"
                        type={FormFieldTypeConstants.Checkbox}
                        controlName="notifyEmployees"
                      />
                    </div>
                  </Col>
                </Row>
              </FormContext.Provider>
            </Modal.Body>
          </Form>
        </div>
      </PrimaryLayoutBox>
    </>
  );
};

export default ObjectivesLaunchNewReview;
