import React from "react";
import "./AiTagButton.scss";
import PlusIcon from "../SvgReactIcons/PlusIcon";
import CloseIcon from "../SvgReactIcons/CloseIcon";

interface AiTagButtonProps {
  name: string;
  selected?: boolean;
  variant?: string;
  onClick?: (name: string) => void;
  disabled?: boolean;
}
const AiTagButton: React.FC<AiTagButtonProps> = ({
  name,
  selected,
  variant,
  onClick,
  disabled,
}) => {
  const getCssVariant = () => {
    switch (variant) {
      case "purple":
        return !selected
          ? "AiTagButton-purple-regular"
          : "AiTagButton-purple-selected";
      default:
        return !selected ? "AiTagButton-regular" : "AiTagButton-selected";
    }
  };
  return (
    <button
      onClick={() => {
        onClick?.(name);
      }}
      disabled={disabled}
      title={name}
      className={`AiTagButton ${getCssVariant()} ${
        disabled ? "AiTagButton-disabled" : ""
      }`}
    >
      {selected ? (
        <div className="close-icon">
          <CloseIcon height={5} width={5} />
        </div>
      ) : (
        <PlusIcon height={16} width={16} />
      )}
      {name}
    </button>
  );
};

export default AiTagButton;
