import {
  FormErrors,
  IReviewTemplateQuestionValidationSchema,
  IReviewTemplateValidationSchema,
} from 'interfaces';
import * as Yup from 'yup';


export const REVIEW_TITLE_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<IReviewTemplateQuestionValidationSchema['titleValue']> =
  Yup.object().shape(
    {
      text: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
    }
  ).notRequired().default(undefined);


export const REVIEW_TEXT_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<IReviewTemplateQuestionValidationSchema['textValue']> =
  Yup.object().shape(
    {
      label: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      placeholder: Yup.string(),
      required: Yup.boolean(),
    }
  ).notRequired().default(undefined);


export const REVIEW_MULTI_SELECT_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<IReviewTemplateQuestionValidationSchema['multiSelectValue']> =
  Yup.object().shape(
    {
      label: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      required: Yup.boolean(),
      commentBoxGuide: Yup.string().when(
        'commentBox',
        {
          is: true,
          then: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
          otherwise: Yup.string()
        }
      ),
      commentBox: Yup.boolean(),
      dropdown: Yup.boolean(),
      selectedOption: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
      selectedView: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }).notRequired()
    }
  ).notRequired().default(undefined);


export const REVIEW_TEMPLATE_NAME_SCHEMA: Yup.SchemaOf<IReviewTemplateValidationSchema['title']> =
  Yup.string()
    .required(FormErrors.Required)
    .defined(FormErrors.Required)
    .trim(FormErrors.Required);
