import React, { Dispatch, useEffect, useState } from "react";
import "./Specificity";
import AiToolsDescriptionBadge from "../AiToolDescriptionBadge/AiToolsDescriptionBadge";
import "./Specificity.scss";
import AiQuestionCard from "../AiQuestionCard/AiQuestionCard";
import GradientPrimaryButton from "../../../../components/GradiantPrimaryButton/GradientPrimaryButton";
import { enhanceTeambleAiFeedback } from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import { AxiosError } from "axios";
import { FeedbackData } from "modules/1-on-1s/types";

interface SpecificityProps {
  data: FeedbackData | null;
  feedbackSituation: string;
  setFeedbackSituation: Dispatch<React.SetStateAction<string>>;
  feedbackBehavior: string;
  setFeedbackBehavior: Dispatch<React.SetStateAction<string>>;
  feedbackImpact: string;
  setFeedbackImpact: Dispatch<React.SetStateAction<string>>;
  setData: Dispatch<React.SetStateAction<FeedbackData | null>>;
  feedbackValue: string;
  actionableSteps: string;
  setOpeningStatement: Dispatch<React.SetStateAction<string>>;
}
const Specificity: React.FC<SpecificityProps> = ({
  data,
  feedbackSituation,
  setFeedbackSituation,
  feedbackBehavior,
  setFeedbackBehavior,
  feedbackImpact,
  setFeedbackImpact,
  setData,
  feedbackValue,
  actionableSteps,
  setOpeningStatement,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (
      feedbackSituation ||
      feedbackBehavior ||
      feedbackImpact ||
      data?.userFeedback !== feedbackValue
    )
      setIsDisabled(false);
  }, [
    feedbackSituation,
    feedbackBehavior,
    feedbackImpact,
    data,
    feedbackValue,
  ]);
  const handleSubmit = () => {
    if (!data?.id) return;
    setIsLoading(true);
    enhanceTeambleAiFeedback({
      id: data.id,
      situation: feedbackSituation,
      behavior: feedbackBehavior,
      impact: feedbackImpact,
      feedbackText: feedbackValue,
    })
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe({
        next: (response: any) => {
          if (response?.data?.feedback) {
            setData({
              ...response?.data?.feedback,
            });
            response?.data?.feedback?.openingStatement &&
              setOpeningStatement(response?.data?.feedback?.openingStatement);
          }
          setIsLoading(false);
        },
        error: (error: AxiosError) => {
          console.log(error);
          setIsLoading(false);
        },
      });
  };

  return (
    <div className="Specificity">
      <AiToolsDescriptionBadge
        label={"AI Tools"}
        value={"Specificity"}
        desc={
          "We suggest following this best practice structure for providing a clear and specific feedback"
        }
      />
      <AiQuestionCard
        label={"Situation"}
        question={"What was the specific situation?"}
        placeholder={"Currently unclear, please provide guidance..."}
        options={data?.situationTags}
        value={feedbackSituation}
        setValue={setFeedbackSituation}
        section="specificity"
      />
      <AiQuestionCard
        label={"Behavior"}
        question={"How did this person behave?"}
        placeholder={"Currently unclear, please provide guidance..."}
        options={data?.behaviorTags}
        value={feedbackBehavior}
        setValue={setFeedbackBehavior}
        section="specificity"
      />
      <AiQuestionCard
        label={"Impact"}
        question={"What impact did it have?"}
        placeholder={"Currently unclear, please provide guidance..."}
        options={data?.impactTags}
        value={feedbackImpact}
        setValue={setFeedbackImpact}
        section="specificity"
      />
      <div className="btn-container">
        <GradientPrimaryButton
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onClick={handleSubmit}
          text={"Generate"}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default Specificity;
