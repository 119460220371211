import { Card } from '@themesberg/react-bootstrap';
import { EmptyState, Preloader } from 'components';
import { getReportsByFilters } from 'data';
import { IReportFilters, IReportTableRow } from 'interfaces';
import * as React from 'react';
import { finalize, Subscription } from 'rxjs';

import { getRequestFilters } from '../utils';
import ReportsTable from './ReportsTable';

interface IReportsTableWidgetProps {
  selectedFilters: IReportFilters;
}

const ReportsTableWidget: React.FunctionComponent<IReportsTableWidgetProps> = ({ selectedFilters }) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reportRows, setReportRows] = React.useState<IReportTableRow[] | null>(null);


  React.useEffect(
    () => {

      setReportRows(null);
      let subscription: Subscription | null = null;

      if (
        selectedFilters.reviewees?.length &&
        selectedFilters.reviewers?.length &&
        selectedFilters.reviews?.length
      ) {

        setIsLoading(true);
        const requestFilters = getRequestFilters(selectedFilters);

        subscription = getReportsByFilters(requestFilters).pipe(
          finalize(
            () => setIsLoading(false)
          )
        ).subscribe(
          (response) => {
            setReportRows(response.data.data);
          }
        );

      }

      return () => subscription?.unsubscribe();

    },
    [selectedFilters]
  );


  return (
    <Card className='mt-4 flex-grow-1 overflow-y-auto'>
      <Card.Body style={{ height: isLoading || !(reportRows && reportRows.length) ? '100px' : 'auto' }}>
        {
          isLoading ?
            (<Preloader show={isLoading} transparentBg={false} />) :
            (
              <>
                {
                  !(reportRows && reportRows.length) ?
                    (
                      <EmptyState message={<>No reports available.</>} />
                    ) :
                    (
                      <ReportsTable reportTableRows={reportRows} />
                    )
                }
              </>
            )
        }
      </Card.Body>
    </Card>
  );
};

export default ReportsTableWidget;