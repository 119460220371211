import * as React from "react";

const DropDownArrow = ({
  width = 8,
  height = 8,
  color = "#9E9FA2",
  viewBox = "0 0 8 8",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    {...props}
  >
    <path stroke={color} d="m1 2.5 3 3 3-3" />
  </svg>
);

export default DropDownArrow;
