import { FormErrors, IFeedbackTypeConfigFormValues } from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const FEEDBACK_TYPE_CONFIG_VALIDATION_SCHEMA: Yup.SchemaOf<IFeedbackTypeConfigFormValues> =
  Yup.object().shape(
    {
      feedbackType: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
        .required(FormErrors.Required)
        .nullable()
    }
  );