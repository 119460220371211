import * as React from "react";

const SearchIcon = ({
  width = 16,
  height = 16,
  color = "#C2C3C5",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    {...props}
  >
    <path
      d="M14 14L10.1941 10.1941M10.1941 10.1941C11.0627 9.32548 11.6 8.12548 11.6 6.8C11.6 4.14903 9.45097 2 6.8 2C4.14903 2 2 4.14903 2 6.8C2 9.45097 4.14903 11.6 6.8 11.6C8.12548 11.6 9.32548 11.0627 10.1941 10.1941Z"
      stroke={color}
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
