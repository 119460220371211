import * as React from 'react';

interface IDividerProps {
  isVertical?: boolean;
}

const Divider: React.FunctionComponent<IDividerProps> = (
  { isVertical }
) => {
  return <div className={`teamble-divider ${isVertical && 'vertical'}`}></div>;
};

export default Divider;
