import Axios, { AxiosObservable } from 'axios-observable';
import {
  ICreateRelationshipsRequestData,
  ICreateRelationshipsResponse,
  IDeleteRelationshipRequestData,
  IMenuOption,
  IRelationship,
  IRelationshipsResponse,
  IResponse,
} from 'interfaces';
import { map } from 'rxjs';

const getEmptyDataResponse = (response: any) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: []
    }
  };
};

export const getRelationshipAsMenuOptions = (): AxiosObservable<IResponse<IRelationshipsResponse[]>> => {

  return Axios.post<IResponse<IRelationshipsResponse[]>>(
    "/list-custom-relationship",
  )
    .pipe(
      map(
        (response) => {

          if (!response.data.data?.length) {
            return getEmptyDataResponse(response);
          }

          const options: IRelationshipsResponse[] = response.data.data.map(
            (option) => ({
              ...option,
              value: option.id,
              label: option.name,
            })
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: options
            }
          };

        }
      )
    );
};


export const getRelationshipsData = (
  relationshipId: string,
  isReport: boolean = false
): AxiosObservable<IResponse<IRelationship[]>> => {

  return Axios.post<IResponse<IRelationship[]>>(
    "/list-custom-relationship-attachment",
    { relationshipId, isReport },
    {
      responseType: isReport ? 'arraybuffer' : 'json'
    }
  )
};


export const createRelationship =
  (data: ICreateRelationshipsRequestData): AxiosObservable<IResponse<ICreateRelationshipsResponse>> => {

    return Axios.post<IResponse<ICreateRelationshipsResponse>>(
      "/create-custom-relationship-attachment", data
    )
  };


export const deleteRelationship =
  (data: IDeleteRelationshipRequestData): AxiosObservable<IResponse<void>> => {

    return Axios.post<IResponse<void>>(
      "/remove-custom-relationship-attachment", data
    )
  };


export const getUserRelations = (): AxiosObservable<IResponse<IMenuOption[]>> => {

  return Axios.post<IResponse<IMenuOption[]>>(
    "/get-user-relations", {}
  )
};