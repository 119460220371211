import { FormErrors, ICompetencyFormValues } from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const COMPETENCY_VALIDATION_SCHEMA: Yup.SchemaOf<ICompetencyFormValues> =
  Yup.object().shape(
    {
      name: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      description: Yup.string(),
      behaviours: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string()
        })
      ).notRequired(),
      roles: Yup.array().of(
        SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
      ),
    }
  );