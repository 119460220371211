import React from "react";
import "./AiToolsDescriptionBadge.scss";

interface AiToolsDescriptionBadgeProps {
  label?: string;
  value?: string;
  desc?: string;
}
const AiToolsDescriptionBadge: React.FC<AiToolsDescriptionBadgeProps> = ({
  label,
  value,
  desc,
}) => {
  return (
    <div className="AiToolsDescriptionBadge">
      <div className="labels">
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </div>
      <div className="desc">{desc}</div>
    </div>
  );
};

export default AiToolsDescriptionBadge;
