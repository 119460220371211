import { Button, Modal } from '@themesberg/react-bootstrap';
import { IModalProps, IReviewTemplateFormValues } from 'interfaces';
import * as React from 'react';

import ReviewTemplateQuestionPreview from '../components/ReviewTemplateQuestionPreview';

interface IReviewTemplatePreviewModalProps extends IModalProps {
  template: IReviewTemplateFormValues;
}

const ReviewTemplatePreviewModal: React.FunctionComponent<IReviewTemplatePreviewModalProps> = (
  { template, show, onClose }
) => {

  return (
    <Modal
      as={Modal.Dialog}
      centered
      scrollable
      show={show}
      onHide={onClose}
      size='lg'
    >
      <Modal.Body>
        {
          template.blocks.map(
            (question, index) => {
              return (
                <div
                  key={`${question?.id}`}>
                  <ReviewTemplateQuestionPreview
                    index={index}
                    question={question} />
                </div>
              );
            }
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" className="ms-auto" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReviewTemplatePreviewModal;
