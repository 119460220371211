import { CustomDropdown, CustomSelect, DataState, PageTitle, PrimaryLayoutBox } from 'components';
import { IChannelMenuOption, IMenuOption, IReviewAnalyticsResponse, IReviewAnalyticsComments, FileFormats, ISimpleMenuOption } from 'interfaces';
import * as React from 'react';
import { Routes } from 'routes';
import ReviewAnalyticsTable from 'modules/analytics/review/ReviewAnalyticsTable';
import { getReviewReportTable, getReviewReportCommentsTable, getReviewAnalyticsCampaignList, getReviewAnalyticsMembersList, downloadAnalyticsReport } from 'data';
import { finalize, forkJoin } from 'rxjs';
import { Button, Card, Col, Form, Row, Table } from '@themesberg/react-bootstrap';
import downloadjs from 'downloadjs';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DOWNLOAD_FORMATS } from 'teamble-constants';

interface IPerformanceReviewReportsProps {
}

const PerformanceReviewReports: React.FunctionComponent<IPerformanceReviewReportsProps> = (props) => {

  const [analytics, setAnalytics] = React.useState<IReviewAnalyticsResponse>({ rows: [], columns: [] } as IReviewAnalyticsResponse);
  const [otherAnalytics, setOtherAnalytics] = React.useState<IReviewAnalyticsResponse>({ rows: [], columns: [] } as IReviewAnalyticsResponse);
  const [analyticsComments, setAnalyticsComments] = React.useState<IReviewAnalyticsComments[]>([]);

  const [campaignOptions, setCampaignOptions] = React.useState<IMenuOption[]>([]);
  const [memberOptions, setMemberOptions] = React.useState<IMenuOption[]>([]);

  const [selectedFilters, setSelectedFilters] = React.useState<{ reviews: IMenuOption[], reviewee: IMenuOption }>({
    reviews: [],
    reviewee: {} as IChannelMenuOption,
  });

  const [loading, setLoading] = React.useState({
    analyticsLoading: false,
    commentsLoading: false,
    reviewsLoading: false,
    revieweeLoading: false,
    reportDownloading: false
  });

  React.useEffect(() => {
    setLoading(loading => ({ ...loading, revieweeLoading: true }));
    const subscription = getReviewAnalyticsMembersList()
      .pipe(
        finalize(() => setLoading(loading => ({ ...loading, revieweeLoading: false })))
      ).subscribe(memberResponse => {
        setMemberOptions(memberResponse.data.data);
      });

    return () => subscription.unsubscribe();
  }, []);

  React.useEffect(() => {

    if (!selectedFilters?.reviewee?.value) return;
    if (selectedFilters.reviews.length) {
      setSelectedFilters(selectedFilters => ({
        ...selectedFilters,
        reviews: []
      }));
      setAnalytics({ rows: [], columns: [] } as IReviewAnalyticsResponse);
      setOtherAnalytics({ rows: [], columns: [] } as IReviewAnalyticsResponse);
      setAnalyticsComments([]);
    }

    setLoading(loading => ({ ...loading, reviewsLoading: true }));
    const subscription = getReviewAnalyticsCampaignList(selectedFilters.reviewee.value)
      .pipe(
        finalize(() => setLoading(loading => ({ ...loading, reviewsLoading: false })))
      ).subscribe(campaignResponse => {
        setCampaignOptions(campaignResponse.data.data);
      })

    return () => subscription.unsubscribe();
  }, [selectedFilters.reviewee]);


  const analyticsPayload = {
    query: {
      filters: {
        prc: {
          type: "in",
          values: selectedFilters.reviews.map(data => data.value).filter(value => value !== 'All'),
        },
        reviewee: {
          type: "in",
          values: [selectedFilters.reviewee.value]
        }
      },
      rows: [{ type: "competency" }, { type: "behavior" }], columns: [{ type: "review" }, { type: "reviewer" }]
    }
  };

  const otherAnalyticsPayload = {
    query: {
      filters: {
        prc: {
          type: "in",
          values: selectedFilters.reviews.map(data => data.value).filter(value => value !== 'All'),
        },
        reviewee: {
          type: "in",
          values: [selectedFilters.reviewee.value]
        }
      },
      rows: [{ type: "quantitative-question" }], columns: [{ type: "review" }, { type: "reviewer" }]
    }
  };

  const commentPayload = {
    prcIds: selectedFilters.reviews.map(data => data.value),
    revieweeId: selectedFilters.reviewee.value
  }

  React.useEffect(() => {

    if (!selectedFilters.reviews.length || !selectedFilters.reviewee.value) return;

    setLoading(loading => ({ ...loading, commentsLoading: true }));


    const subscription = forkJoin(
      [
        getReviewReportTable(analyticsPayload),
        getReviewReportTable(otherAnalyticsPayload),
        getReviewReportCommentsTable(commentPayload)
      ]
    ).pipe(
      finalize(() => setLoading(loading => ({ ...loading, commentsLoading: false })))
    ).subscribe(
      ([analyticsResponse, otherAnalyticsResponse, commentResponse]) => {
        setAnalytics(analyticsResponse.data.data);
        setOtherAnalytics(otherAnalyticsResponse.data.data);
        setAnalyticsComments(commentResponse.data.data);
      }
    );

    return () => subscription.unsubscribe();

  }, [selectedFilters])


  const downloadReport = (data: ISimpleMenuOption) => {

    setLoading(loading => ({ ...loading, reportDownloading: true }));
    const reportType = data.value;

    downloadAnalyticsReport({
      queries: [analyticsPayload.query, otherAnalyticsPayload.query],
      reportType,
      ...commentPayload,
    }).pipe(
      finalize(() => {
        setLoading(loading => ({ ...loading, reportDownloading: false }));
      })
    ).subscribe(
      (response) => {
        downloadjs(
          response.data as unknown as Blob,
          reportType === "PDF" ? 'report.pdf' : 'report.xlsx',
          reportType === "PDF" ? FileFormats.PDF : FileFormats.XLSX
        );
      }
    );
  }

  return (
    <>
      <PageTitle title={Routes.PerformanceReviewReports.title} />
      <PrimaryLayoutBox
        title={Routes.PerformanceReviewReports.title}
        actions={
          <CustomDropdown
            id='review-report-download'
            title='Download'
            icon={faDownload}
            options={DOWNLOAD_FORMATS}
            disabled={loading.reportDownloading}
            onChange={downloadReport}
          />
        }
      >
        <div className={`overflow-hidden flex-grow-1 d-flex flex-column`}>
          <>
            <Card style={{ height: '60vh' }} className='overflow-hidden flex-grow-1'>
              <Card.Body className='overflow-y-auto'>
                <Row className='align-items-end'>
                  <Col>
                    <Form.Label> Reviewee  </Form.Label>
                    <CustomSelect
                      allowSelectAll
                      isSearchable
                      isLoading={loading.revieweeLoading}
                      options={memberOptions}
                      onChange={(options) => {
                        setSelectedFilters({
                          ...selectedFilters,
                          reviewee: options as IMenuOption
                        })
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label> Reviews  </Form.Label>
                    <CustomSelect
                      isMulti
                      isSearchable
                      isLoading={loading.reviewsLoading}
                      options={campaignOptions}
                      value={selectedFilters.reviews}
                      onChange={(options) => {
                        setSelectedFilters({
                          ...selectedFilters,
                          reviews: options as IMenuOption[]
                        })
                      }}
                    />
                  </Col>
                </Row>
                <DataState
                  isDataLoading={loading.analyticsLoading || loading.commentsLoading}
                  isDataAvailable={Boolean(analytics?.rows?.length)}
                  emptyStateMessage={
                    (!selectedFilters.reviews.length || !selectedFilters.reviewee.value)
                      ? 'Please select Reviews and Reviewee'
                      : 'Analytics not available!'
                  }
                >
                  <div className='mt-3'>
                    <ReviewAnalyticsTable analytics={analytics} />
                  </div>

                  <div className='mt-3'>
                    <ReviewAnalyticsTable analytics={otherAnalytics} />
                  </div>

                  <div className='mt-3'>
                    <CommentsTable comments={analyticsComments} />
                  </div>

                </DataState>
              </Card.Body>
            </Card >

          </>
        </div>
      </PrimaryLayoutBox >
    </>
  );
};

const CommentsTable: React.FunctionComponent<{ comments: IReviewAnalyticsComments[] }> = ({ comments }) => {
  return (
    <Card className='fill-height'>
      <Card.Body className='overflow-x-auto overflow-y-auto flex-grow-1 p-0 pe-2 pb-2'>
        <Table
          className='mx-auto my-0 position-relative'
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '6px',
          }}
        >
          <thead>
            <tr>
              <th>Question</th>
              <th>Reviewer</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {
              comments.map((row) => {
                return (
                  <tr>
                    <td style={{ whiteSpace: "break-spaces" }}> {row.question}</td>
                    <td style={{ whiteSpace: "break-spaces" }}> {row.reviewer}</td>
                    <td style={{ whiteSpace: "break-spaces" }}> {row.comment}</td>
                  </tr>
                )
              })
            }
          </tbody >
        </Table>
      </Card.Body>
    </Card>
  )
}

export default PerformanceReviewReports;
