import { Table, TableGlobalFilter, useTmblTable, useTmblTableData } from '@common/modules/table';
import { CustomDropdown } from 'components';
import {
  IActionTypes,
  IActiveReviewFormValues,
  IReviewAudienceActionColumnProps,
  IReviewerRevieweeInfos,
  ISimpleMenuOption,
} from 'interfaces';
import * as React from 'react';
import { REVIEW_AUDIENCE_TABLE_BULK_ACTIONS } from 'teamble-constants';

import { useReviewAudienceTableColumns } from '../hooks/useReviewAudienceTableColumns';


interface IReviewAudienceTableProps {
  audience: IReviewerRevieweeInfos[];
  onPairRemove: (index: number) => void;
  onAudienceChange: (audience: IActiveReviewFormValues['reviewerRevieweeInfos']) => void;
}


const ReviewAudienceTable: React.FunctionComponent<IReviewAudienceTableProps> = (
  { audience, onPairRemove, onAudienceChange }
) => {

  const [allRowsChecked, setAllRowsChecked] = React.useState<boolean>(false);
  const [showBulkAction, setShowBulkAction] = React.useState<boolean>(false);


  const updateIsCheckedForAllRows = (isChecked: boolean): void => {

    setAllRowsChecked(isChecked);

    const audienceClone = [...audience];
    audienceClone.forEach(
      (row) => row.isChecked = isChecked
    );

    onAudienceChange(audienceClone);
  };


  const updateIsCheckedForRow = (index: number): void => {

    const audienceClone = [...audience];
    audienceClone[index].isChecked = !audienceClone[index].isChecked;

    onAudienceChange(audienceClone);
  };


  React.useEffect(
    () => {

      if (audience.length > 0) {

        setAllRowsChecked(
          audience.every(
            (row) => row.isChecked
          )
        );

        setShowBulkAction(
          audience.some(
            (row) => row.isChecked
          )
        );

        return;
      }

      setShowBulkAction(false);
      setAllRowsChecked(false);

    },
    [audience]
  );


  const tableColumns = useReviewAudienceTableColumns();
  const tableData = useTmblTableData<IReviewerRevieweeInfos>(audience);

  const tableInstance = useTmblTable<IReviewerRevieweeInfos>(
    tableColumns,
    tableData,
    'reviewee',
  );


  const handleAudienceBulkAction = (option: ISimpleMenuOption): void => {

    switch (option.value) {

      case IActionTypes.Remove: {

        const updatedAudience = audience.filter(
          (aud) => !aud.isChecked
        )
        onAudienceChange(updatedAudience);

      }
    }
  };


  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <TableGlobalFilter
          className='w-50'
          globalFilter={tableInstance.state.globalFilter}
          setGlobalFilter={tableInstance.setGlobalFilter}
          placeholder='Search by Reviewee and Reviewer'
        />
        {
          showBulkAction &&
          <CustomDropdown
            id='audienceTableBulkActionDropdown'
            title='Bulk Actions'
            options={REVIEW_AUDIENCE_TABLE_BULK_ACTIONS}
            variant='outline-primary'
            onChange={handleAudienceBulkAction}
          />
        }
      </div>

      <Table<IReviewAudienceActionColumnProps, IReviewerRevieweeInfos>
        stickyHeader={false}
        tableInstance={tableInstance}
        headerCellProps={{
          allRowsChecked,
          updateIsCheckedForAllRows
        }}
        bodyCellProps={{
          onPairRemove,
          updateIsCheckedForRow
        }} />

    </>
  );

};

export default ReviewAudienceTable;
