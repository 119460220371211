import * as React from 'react';

interface IPrimaryLayoutBoxProps {
  title: string;
  description?: string;
  actions?: React.ReactNode
}

const PrimaryLayoutBox: React.FunctionComponent<IPrimaryLayoutBoxProps> = (
  { title, description, actions, children }
) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-start flex-wrap mb-4 flex-shrink-0">

        <div className="d-block mb-4 mb-md-0 flex-shrink-0">
          <h4> {title} </h4>
          <p className="mb-0"> {description} </p>
        </div>

        {actions}

      </div>
      {children}
    </>
  );
};

export default PrimaryLayoutBox;
