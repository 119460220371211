import { faChevronDown, faChevronUp, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Card,
  Collapse,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
} from '@themesberg/react-bootstrap';
import { CustomSelect, SelectUserPairsField } from 'components';
import { getAutoSuggestAudiencePairs, getManagerUpwardDownwardAudiencePairs, getUserDepartmentsAsMenuOptions } from 'data';
import { useApp, useTeamMemberMenuOptions } from 'hooks';
import {
  IActiveReviewFormValues,
  IMenuOption,
  IReviewAudienceFilters,
  IReviewerRevieweeInfos,
  ISimpleMenuOption,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { finalize } from 'rxjs';

import ReviewAudienceTable from './ReviewAudienceTable';
import UploadAudienceField from './UploadAudienceField';

export const reviewAudienceFilterOptions: ISimpleMenuOption[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "department",
    label: "Department"
  }
];

interface ILaunchReviewAudienceConfigWidgetProps {
  selectedChannel: ISimpleMenuOption;
  audience: IActiveReviewFormValues['reviewerRevieweeInfos'];
  onAudienceChange: (audience: IActiveReviewFormValues['reviewerRevieweeInfos']) => void;
}

enum ActiveAudienceField {
  Select = 'select',
  Upload = 'upload'
}

interface IAudienceTypeSelectGroupProps {
  loading: boolean;
  activeAudienceField: ActiveAudienceField;
  isAudienceAvailable: boolean;
  onActiveAudienceField: (activeAudienceField: ActiveAudienceField) => void;
  onClearAllClick: () => void;
  onSelfReviewClick: () => void;
  onAutoSuggestClick: () => void;
  onManagerUpwardClick: () => void;
  onManagerDownwardClick: () => void;
}


const AudienceTypeSelectGroup: React.FunctionComponent<IAudienceTypeSelectGroupProps> = (
  {
    loading, activeAudienceField, isAudienceAvailable,
    onActiveAudienceField, onSelfReviewClick, onClearAllClick,
    onAutoSuggestClick, onManagerUpwardClick, onManagerDownwardClick
  }
) => {

  const app = useApp();

  return (
    <>
      <ButtonGroup>

        <Button type='button' disabled={loading}
          variant={`outline-primary ${activeAudienceField === ActiveAudienceField.Select ? 'active' : ''}`}
          onClick={() => onActiveAudienceField(ActiveAudienceField.Select)}
        >
          Select
        </Button>

        <Button type='button' disabled={loading}
          variant={`outline-primary ${activeAudienceField === ActiveAudienceField.Upload ? 'active' : ''}`}
          onClick={() => onActiveAudienceField(ActiveAudienceField.Upload)}
        >
          Upload
        </Button>

      </ButtonGroup>

      <ButtonGroup className='ms-3'>

        <OverlayTrigger
          overlay={
            <Tooltip id="autoAudienceTooltip">
              Teamble’s AI uses data from Slack, Teams, and other connected apps to create a suggested performance review table
            </Tooltip>
          }>
          {
            ({ ref, ...triggerHandler }) => (
              <Button
                type='button'
                disabled={loading}
                variant='outline-primary' ref={ref}
                onClick={onAutoSuggestClick}
              >

                Auto
                <span className='ms-3' {...triggerHandler}>
                  <FontAwesomeIcon icon={faInfo} />
                </span>
              </Button>
            )
          }
        </OverlayTrigger>
        <>
          <Button
            type='button'
            onClick={onManagerUpwardClick}
            disabled={loading}
            variant='outline-primary'
          >
            Manager Upward
          </Button>
          <Button
            type='button'
            onClick={onManagerDownwardClick}
            disabled={loading}
            variant='outline-primary'
          >
            Manager Downward
          </Button>
        </>
        <Button
          type='button'
          onClick={onSelfReviewClick}
          disabled={loading}
          variant='outline-primary'
        >
          Self Review
        </Button>
      </ButtonGroup>

      <Button
        type='button'
        className='ms-3'
        onClick={onClearAllClick}
        disabled={loading || !isAudienceAvailable}
        variant='outline-danger'
      >
        Clear All
      </Button>
    </>
  )

};


const LaunchReviewAudienceConfigWidget: React.FunctionComponent<ILaunchReviewAudienceConfigWidgetProps> = (
  { selectedChannel, audience, onAudienceChange }
) => {

  const [loadingAudience, setLoadingAudience] = React.useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const [departmentsMenuOptions, setDepartmentsMenuOptions] = React.useState<ISimpleMenuOption[]>([]);
  const [filterOption, setFilterOption] = React.useState<ISimpleMenuOption>(reviewAudienceFilterOptions[0]);
  const [departmentFilter, setDepartmentFilter] = React.useState<ISimpleMenuOption[]>([]);

  const { memberOptions } = useTeamMemberMenuOptions(selectedChannel.value);

  React.useEffect(() => {
    setLoadingAudience(true);
    const subscription = getUserDepartmentsAsMenuOptions()
      .pipe(
        finalize(() => setLoadingAudience(false))
      )
      .subscribe(
        (response) => {
          setDepartmentsMenuOptions(response.data.data);
        }
      );
    return () => subscription.unsubscribe();

  }, []);

  React.useEffect(() => {

    if (filterOption?.value === 'all') {
      // clear all filters
      setDepartmentFilter([])
    }

  }, [filterOption]);

  const app = useApp();

  const [activeAudienceField, setActiveAudienceField] = React.useState<ActiveAudienceField>(ActiveAudienceField.Select);

  const getAppliedFilters = (): IReviewAudienceFilters => {
    return {
      departmentFilter: departmentFilter.map(data => data.value)
    }
  }

  const getDistinctDuplicateAudiencePairs = (
    currentAudiencePairs: IActiveReviewFormValues['reviewerRevieweeInfos']
  ): IActiveReviewFormValues['reviewerRevieweeInfos'] => {

    if (!currentAudiencePairs) {
      return null;
    }

    const distinctAudiencePairs: IActiveReviewFormValues['reviewerRevieweeInfos'] = [];
    const distinctAudiencePairsObject: {
      [key: string]: IReviewerRevieweeInfos
    } = {};

    currentAudiencePairs.forEach(
      (currentAudiencePair) => {

        const distinctAudiencePair = distinctAudiencePairsObject[`${currentAudiencePair.reviewee.value}_${currentAudiencePair.reviewer.value}`];

        if (!distinctAudiencePair) {
          distinctAudiencePairsObject[`${currentAudiencePair.reviewee.value}_${currentAudiencePair.reviewer.value}`] = currentAudiencePair;
          distinctAudiencePairs.push(currentAudiencePair);
        }

      }
    );

    return distinctAudiencePairs;
  };


  const addSelectedPairsToAudienceList =
    (reviewees: IMenuOption[] | null, reviewers: IMenuOption[] | null): void => {

      const newAudiencePairs: IActiveReviewFormValues['reviewerRevieweeInfos'] = audience || [];

      if (reviewees?.length && reviewers?.length) {

        reviewees.forEach(reviewee => {
          reviewers.forEach(reviewer => {
            newAudiencePairs.push(
              {
                reviewee,
                reviewer,
                isChecked: false,
              }
            );
          })
        });
      }

      const distinctAudiencePairs = getDistinctDuplicateAudiencePairs(newAudiencePairs);

      onAudienceChange(distinctAudiencePairs);
    };


  const addSelfReviewPairsToAudienceList = (): void => {

    const newAudiencePairs: IActiveReviewFormValues['reviewerRevieweeInfos'] = audience || [];

    const departmentFilterIds = departmentFilter.map(data => data.value);
    for (let index = 0; index < memberOptions.length; index++) {
      const memberOption = memberOptions[index];
      if (departmentFilterIds.length && !departmentFilterIds.includes(memberOption?.departmentId as any)) {
        continue;
      }
      newAudiencePairs.push(
        {
          reviewee: memberOption,
          reviewer: memberOption,
          isChecked: false,
        }
      );
    }
    // memberOptions.forEach(
    //   (option) => {
    //     newAudiencePairs.push(
    //       {
    //         reviewee: option,
    //         reviewer: option,
    //         isChecked: false,
    //       }
    //     );
    //   }
    // );

    const distinctAudiencePairs = getDistinctDuplicateAudiencePairs(newAudiencePairs);
    onAudienceChange(distinctAudiencePairs);
  };


  const removePairFromAudienceList = (index: number): void => {

    if (audience) {

      const audienceCopy = [...audience];
      audienceCopy.splice(index, 1);
      onAudienceChange(audienceCopy);
    }

  };


  const addAutoSuggestPairsToAudienceList = (): void => {

    setLoadingAudience(true);
    const filters = getAppliedFilters();
    getAutoSuggestAudiencePairs(app, selectedChannel.value, filters)
      .pipe(
        finalize(
          () => setLoadingAudience(false)
        )
      )
      .subscribe(
        (response) => {

          let newAudiencePairs: IActiveReviewFormValues['reviewerRevieweeInfos'] = audience || [];
          newAudiencePairs = [...newAudiencePairs, ...response.data.data];

          const distinctAudiencePairs = getDistinctDuplicateAudiencePairs(newAudiencePairs);
          onAudienceChange(distinctAudiencePairs);

        }
      )
  };


  const addManagerUpwardDownwardPairsToAudienceList = (type: 'upward' | 'downward'): void => {

    setLoadingAudience(true);
    const filters = getAppliedFilters();
    getManagerUpwardDownwardAudiencePairs(app, selectedChannel.value, type, filters)
      .pipe(
        finalize(() => setLoadingAudience(false))
      )
      .subscribe(
        (response) => {

          let newAudiencePairs: IActiveReviewFormValues['reviewerRevieweeInfos'] = audience || [];
          newAudiencePairs = [...newAudiencePairs, ...response.data.data];

          const distinctAudiencePairs = getDistinctDuplicateAudiencePairs(newAudiencePairs);
          onAudienceChange(distinctAudiencePairs);

        }
      )
  };


  const audiencePairsByFileUpload =
    (isFileUploading: boolean, audiencePairs?: IReviewerRevieweeInfos[]): void => {

      setLoadingAudience(isFileUploading);

      if (audiencePairs) {

        let newAudiencePairs: IActiveReviewFormValues['reviewerRevieweeInfos'] = audience || [];
        newAudiencePairs = [...newAudiencePairs, ...audiencePairs];

        const distinctAudiencePairs = getDistinctDuplicateAudiencePairs(newAudiencePairs);
        onAudienceChange(distinctAudiencePairs);

      }
    };


  return (
    <Card className='my-2'>

      <Card.Header
        aria-controls='launchReviewAudienceWidget'
        aria-expanded={!isCollapsed}
        className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Card.Title className='m-0' as='h6'>
          Audience
        </Card.Title>
        <Button variant='link' size='sm'>
          <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
        </Button>
      </Card.Header>

      <Collapse in={!isCollapsed}>
        <Card.Body id='launchReviewAudienceWidget'>

          <div className='mb-2 position-relative'>
            <Row>

              <Col xs={6}>
                <Form.Label> Audience Filter </Form.Label>
                <CustomSelect
                  className='mr-3'
                  options={reviewAudienceFilterOptions}
                  value={filterOption}
                  onChange={(option) => setFilterOption(option as IMenuOption)}
                />
              </Col>

              {
                filterOption?.value === 'department' && (
                  <Col xs={6}>
                    <Form.Label> Department </Form.Label>
                    <CustomSelect
                      className='mr-3'
                      isMulti
                      options={departmentsMenuOptions}
                      value={departmentFilter}
                      onChange={(options) => setDepartmentFilter(options as ISimpleMenuOption[])}
                    />
                  </Col>
                )
              }

            </Row>
          </div>

          <Card className='rounded-0 rounded-top'>
            <Card.Header className='mt-1 position-relative z-index-2'>
              <div className='d-flex align-items-center justify-content-between'>
                <h5> Audience </h5>
                <div>
                  <AudienceTypeSelectGroup
                    loading={loadingAudience}
                    isAudienceAvailable={!!audience?.length}
                    activeAudienceField={activeAudienceField}
                    onClearAllClick={() => onAudienceChange([])}
                    onActiveAudienceField={setActiveAudienceField}
                    onSelfReviewClick={addSelfReviewPairsToAudienceList}
                    onAutoSuggestClick={addAutoSuggestPairsToAudienceList}
                    onManagerUpwardClick={() => addManagerUpwardDownwardPairsToAudienceList('upward')}
                    onManagerDownwardClick={() => addManagerUpwardDownwardPairsToAudienceList('downward')} />
                </div>
              </div>

              {
                activeAudienceField === ActiveAudienceField.Select
                  ?
                  <SelectUserPairsField
                    firstSelectTitle='Reviewees'
                    secondSelectTitle='Reviewers'
                    organizationId={selectedChannel.value}
                    onAddToListClick={addSelectedPairsToAudienceList}
                  />
                  :
                  <UploadAudienceField
                    channelOrWorkspaceId={selectedChannel.value}
                    onFileUpload={audiencePairsByFileUpload}
                  />
              }
            </Card.Header>

            {
              audience?.length
                ?
                <Card.Body className='position-relative z-index-1'>
                  <ReviewAudienceTable
                    audience={audience}
                    onAudienceChange={onAudienceChange}
                    onPairRemove={removePairFromAudienceList} />
                </Card.Body>
                :
                null
            }
          </Card>

        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default LaunchReviewAudienceConfigWidget;
