import { createContext } from "react";

export interface ISurveyQuestionParentGroupContextValue {
  groupIndex: number | null;
  isGroup: boolean;
}

export const SurveyQuestionParentGroupContext = createContext<ISurveyQuestionParentGroupContextValue>({
  groupIndex: null,
  isGroup: false,
});