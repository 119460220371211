import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import { ACTION_COLUMN_CONFIG } from '@common/modules/table';
import { Member } from 'components';
import { BodyCellProps, IFeedbackForTableActionProps, IFeedbackLoopAudiencePair } from 'interfaces';
import * as React from 'react';
import { CellProps, Column } from 'react-table';


export const useFeedbackForAudiencePairColumn = (
  readOnly: boolean = true,
): readonly Column<IFeedbackLoopAudiencePair>[] => {

  return React.useMemo<readonly Column<IFeedbackLoopAudiencePair>[]>(
    () => {

      const commonColumns: readonly Column<IFeedbackLoopAudiencePair>[] = [
        {
          id: 'feedbackSender',
          Header: 'Feedback Sender',
          accessor: (row) => row.sender.name,
          Cell: (cellProps: CellProps<IFeedbackLoopAudiencePair>) => {

            const sender = cellProps.row.original.sender;

            return (
              <Member member={{
                value: sender.id,
                label: sender.name,
                imageOriginal: sender.imageOriginal,
              }} />
            )
          }
        },
        {
          Header: 'Feedback Receiver',
          accessor: (row) => row.receiver!.name,
          Cell: (cellProps: CellProps<IFeedbackLoopAudiencePair>) => {

            const receiver = cellProps.row.original.receiver;

            return (
              <Member member={{
                value: receiver!.id,
                label: receiver!.name,
                imageOriginal: receiver!.imageOriginal,
              }} />
            )
          }
        },
      ];

      const actionsColumn: Column<IFeedbackLoopAudiencePair> = {
        ...ACTION_COLUMN_CONFIG,
        Cell: (
          cellProps: BodyCellProps<IFeedbackLoopAudiencePair, void, IFeedbackForTableActionProps>
        ) => (
          <Button
            size='sm'
            variant='link'
            onClick={() => cellProps.onRemoveClick?.(cellProps.row.original)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )
      };

      return readOnly ? commonColumns : [...commonColumns, actionsColumn];
    },
    [readOnly]
  );

};