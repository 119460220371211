import { AxiosError } from 'axios';
import { AxiosObservable } from 'axios-observable';
import { EmptyState, Preloader } from 'components';
import { MetaDataContext } from 'contexts';
import { getPerformanceReviewTemplateById, getPredefinedPerformanceReviewTemplateDetail } from 'data';
import { useApp } from 'hooks';
import { IErrorResponse, IMetadata, IResponse, IReviewTemplate, IRouteParams } from 'interfaces';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import ReviewTemplateBuilderWidget from './components/ReviewTemplateBuilderWidget';


const PerformanceReviewTemplateBuilder: React.FunctionComponent<{}> = () => {

  const [reviewTemplate, setReviewTemplate] = React.useState<IReviewTemplate | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showEmptyState, setShowEmptyState] = React.useState<boolean>(false);

  const { reviewTemplateId, predefinedReviewTemplateId } = useParams<IRouteParams>();

  const metadata = React.useContext<IMetadata | null>(MetaDataContext);

  const history = useHistory();

  const app = useApp();


  React.useEffect(
    () => {

      if (reviewTemplateId || predefinedReviewTemplateId) {

        if (reviewTemplateId === 'new') {
          setShowEmptyState(false);
          setReviewTemplate(null);
          return;
        }

        setIsLoading(true);
        setReviewTemplate(null);
        let reviewTemplateData$: AxiosObservable<IResponse<IReviewTemplate>> | null = null;

        if (reviewTemplateId) {
          reviewTemplateData$ = getPerformanceReviewTemplateById(app, reviewTemplateId, metadata as IMetadata);
        }

        if (predefinedReviewTemplateId) {
          reviewTemplateData$ = getPredefinedPerformanceReviewTemplateDetail(app, predefinedReviewTemplateId, metadata as IMetadata);
        }

        if (!reviewTemplateData$) {
          return;
        }

        reviewTemplateData$.pipe(
          finalize(
            () => setIsLoading(false)
          )
        ).subscribe(
          {
            next: (response) => {

              if (response.data.data) {
                setReviewTemplate(response.data.data);
                setShowEmptyState(false);
              } else {
                setShowEmptyState(true);
              }
            },
            error: (error: AxiosError<IErrorResponse>) => {
              if (error.response?.status === 403) {
                history.replace(Routes.PerformanceReviewQuestionnaires.path);
              }
            }
          }
        );

      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewTemplateId, predefinedReviewTemplateId]
  );


  return (
    <>
      {
        isLoading ?
          (<Preloader show={isLoading} />) :
          (
            <>
              {
                showEmptyState ?
                  (
                    <EmptyState message={<>No Performance Review Questionnaires details available.</>} />
                  ) :
                  (
                    <ReviewTemplateBuilderWidget reviewTemplate={reviewTemplate} isPredefinedTemplate={!!predefinedReviewTemplateId} />
                  )
              }
            </>
          )
      }
    </>

  );
};

export default PerformanceReviewTemplateBuilder;
