import { getGroupMembersDetail, getSlackChannelUsersAsMenuOptions } from 'data';
import { useApp, useAudiencePills, useTeamMemberMenuOptions$ } from 'hooks';
import { IAudience, IMemberOption, SignInWith } from 'interfaces';
import React from 'react';
import { finalize, Subscription } from 'rxjs';


interface IOrganizationMemberOptionsResponse {
  memberOptions: IMemberOption[];
  isLoading: boolean;
}


export const useFeedbackForManuallyDefineSenderOptions = (
  isManuallyDefineEnabled: boolean,
  organizationId: string,
  feedbackLoopAudience?: IAudience
): IOrganizationMemberOptionsResponse => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [memberOptions, setMemberOptions] = React.useState<IMemberOption[]>([]);

  const audiencePills = useAudiencePills(feedbackLoopAudience);

  const channelOrWorkspaceUsersMenuOptions$ = useTeamMemberMenuOptions$(organizationId);

  // App hook
  const app = useApp();


  React.useEffect(
    () => {

      if (!audiencePills.length || !isManuallyDefineEnabled) {
        setMemberOptions([]);
        setIsLoading(false);
        return;
      }

      const getUserMemberOptions = (): IMemberOption[] => {

        const userPills = audiencePills.filter(
          (pill) => !pill.isChannel && !pill.isWorkspace && !pill.isGroup
        );

        return userPills.map(
          (pill) => ({
            value: app === SignInWith.Slack ? (pill.slackUserId as string) : (pill.msTeamsUserId as string),
            label: pill.name
          })
        );
      };


      let subscription: Subscription | undefined;

      if (audiencePills[0].isWorkspace) {

        setIsLoading(true);
        setMemberOptions([]);

        subscription = channelOrWorkspaceUsersMenuOptions$?.pipe(
          finalize(() => setIsLoading(false))
        )
          .subscribe(
            (response) => {
              setMemberOptions(response.data.data);
            }
          );
        return;
      }

      if (audiencePills[0].isChannel) {

        setIsLoading(true);
        setMemberOptions([]);

        const channelIds = audiencePills
          .filter((pill) => pill.isChannel)
          .map((pill) => pill.id);

        subscription = getSlackChannelUsersAsMenuOptions(
          organizationId,
          channelIds
        )
          .pipe(
            finalize(() => setIsLoading(false))
          )
          .subscribe(
            (response) => {
              let memOptions = response.data.data;

              const userMemOptions = getUserMemberOptions();
              if (!userMemOptions.length) {
                setMemberOptions(memOptions);
                return;
              }

              // Get Unique Member options of channels + users audience
              const memberMap = new Map<string, IMemberOption>();
              [...memOptions, ...userMemOptions].forEach(
                (member) => memberMap.set(member.value, member)
              );
              memOptions = Array.from(memberMap, ([_, value]) => value);
              setMemberOptions(memOptions);

            }
          );
        return;
      }

      if (audiencePills[0].isGroup) {

        setIsLoading(true);
        setMemberOptions([]);

        const groupIds = audiencePills.filter((pill) => pill.isGroup).map((pill) => pill.id);

        subscription = getGroupMembersDetail(
          groupIds,
          ''
        )
          .pipe(
            finalize(() => setIsLoading(false))
          )
          .subscribe(
            (response) => {
              let groupData = response.data.data;
              const memberMap = new Map<string, IMemberOption>();
              for (let index = 0; index < groupData.length; index++) {
                const { members } = groupData[index];
                for (let index = 0; index < members.length; index++) {
                  const member = members[index];
                  if (!memberMap.has(member.id)) {
                    member.value = member.id;
                    member.label = member.name;
                    memberMap.set(member.id, member as unknown as IMemberOption)
                  }
                }
              }
              // Get Unique Member options of channels + users audience
              // const memberMap = new Map<string, IMemberOption>();
              // [...memOptions, ...userMemOptions].forEach(
              //   (member) => memberMap.set(member.value, member)
              // );
              const memOptions = Array.from(memberMap, ([_, value]) => value);
              setMemberOptions(memOptions);

            }
          );
        return;
      }


      const userMemOptions = getUserMemberOptions();
      if (!userMemOptions.length) {
        setMemberOptions([]);
        return;
      }
      setMemberOptions(userMemOptions);

      return () => subscription?.unsubscribe();
    },
    [app, audiencePills, channelOrWorkspaceUsersMenuOptions$, isManuallyDefineEnabled, organizationId]
  );

  return { isLoading, memberOptions };

};