import React from 'react'

interface ITableExcludedColumnProps {
  isExcluded: boolean;
}

export const TableExcludedColumn: React.FC<ITableExcludedColumnProps> = (
  { isExcluded, children }
) => {

  return (
    <p
      className="table-cell-text m-0"
      style={{ color: isExcluded ? "#93a5be" : "inherit" }}
    >
      {children}
    </p>
  );
};
