import { IMenuOption, ISchedulerEvents, ISchedulerFrequencyConstants, ISurvey } from 'interfaces';
import moment, { DurationInputArg2, Moment } from 'moment';
import { Row } from 'react-table';
import { map, Observable, timer } from 'rxjs';
import {
  DATE_TIME_FORMAT, NUMBER_OF_SCHEDULE_EVENTS,
  NUMBER_POSTFIX,
  SCHEDULER_FREQUENCY_OPTIONS,
  WEEKDAYS
} from 'teamble-constants';

export const getDateSortingFunc = <T extends object>() => {

  return (firstRow: Row<T>, secondRow: Row<T>, columnId: string): number => {

    const firstRowValue = moment(firstRow.values[columnId], DATE_TIME_FORMAT);
    const secondRowValue = moment(secondRow.values[columnId], DATE_TIME_FORMAT);

    if (!firstRowValue.isValid() && secondRowValue.isValid()) {
      return 1;
    }

    if (firstRowValue.isValid() && !secondRowValue.isValid()) {
      return -1;
    }

    if (!firstRowValue.isValid() && !secondRowValue.isValid()) {
      return 0;
    }

    if (firstRowValue.isAfter(secondRowValue)) {
      return 1;
    }

    if (firstRowValue.isBefore(secondRowValue)) {
      return -1;
    }

    return 0;
  };

};


export const getTodayTomorrowNextDateTime = (dateTime: Moment): string => {

  const tomorrowDateTime = moment().add(1, 'days').endOf('day');
  const diffInDays = tomorrowDateTime.diff(dateTime.clone().endOf('days'), 'days');

  if (diffInDays === 1) {
    return `Today @${dateTime.format("hh:mm A")}`;
  } else if (diffInDays === 0) {
    return `Tomorrow @${dateTime.format("hh:mm A")}`;
  } else {
    return '@' + dateTime.format(DATE_TIME_FORMAT);
  }

};


export const getSurveyDuration = (surveyDetails: ISurvey | null): string | Observable<string> => {

  if (!surveyDetails) {
    return '';
  }

  if (surveyDetails.closeAfterAllSubmissions) {
    return 'Close once everyone submits';
  } else if (surveyDetails.closeSpecificTimeAt) {
    const closeDateTime = moment(surveyDetails.closeSpecificTimeAt)
      .format(DATE_TIME_FORMAT);

    return closeDateTime;
  } else if (surveyDetails.closeSchedule) {

    return timer(0, 6000).pipe(
      map(
        () => {

          let startTime = moment();
          if (surveyDetails.scheduledAt) {
            startTime = moment(surveyDetails.scheduledAt);
          }

          const closeDateTime = startTime
            .add(
              surveyDetails.closeSchedule.span,
              surveyDetails.closeSchedule.unit as DurationInputArg2
            )
            .format(DATE_TIME_FORMAT);

          return closeDateTime;
        }
      )
    );

  } else if (surveyDetails.closeScheduleTimestamp) {

    const closeDateTime = moment(surveyDetails.closeScheduleTimestamp)
      .format(DATE_TIME_FORMAT);

    return closeDateTime;

  } else {
    return 'Not set, Close manually';
  }

};


const getNthDayEvents = (
  dateTime: Date,
  monthStepSize: number
): ISchedulerEvents[] => {

  const events: ISchedulerEvents[] = [];
  const currentDateTime = new Date(dateTime.getTime());
  let nextDate = currentDateTime;

  for (let i = 0; i < NUMBER_OF_SCHEDULE_EVENTS; i++) {
    if (i === 0 && dateTime.getTime() < new Date().getTime()) {
      const todayDate = new Date();
      nextDate = new Date(todayDate.setMonth(todayDate.getMonth() + monthStepSize));
    } else if (i !== 0) {
      nextDate = new Date(nextDate.setMonth(nextDate.getMonth() + monthStepSize));
    }

    events.push(
      {
        weekday: WEEKDAYS[nextDate.getDay()],
        date: nextDate.toLocaleDateString(),
        time: moment(dateTime).format('hh:mm a'),
      }
    );

  }

  return events;
};


const getNthWeekdayEvents = (
  dateTime: Date,
  monthStepSize: number
): ISchedulerEvents[] => {

  const events: ISchedulerEvents[] = [];

  const currentDateTime = new Date(dateTime.getTime());
  const weekNumber = Math.ceil(currentDateTime.getDate() / 7);
  let nextDate = new Date(currentDateTime.getTime());

  for (let i = 0; i < NUMBER_OF_SCHEDULE_EVENTS; i++) {

    // For the first event if the selected date is less than today's date
    // then consider today's date as the date for the first event
    if (i === 0 && dateTime.getTime() < new Date().getTime()) {
      const todayDate = new Date();

      nextDate = new Date(todayDate.setMonth(todayDate.getMonth()));
      if (todayDate.getMonth() === currentDateTime.getMonth()) {
        nextDate = new Date(todayDate.setMonth(todayDate.getMonth() + monthStepSize));
      } else {
        if (todayDate.getDate() >= currentDateTime.getDate()) {
          nextDate = new Date(todayDate.setMonth(todayDate.getMonth() + monthStepSize));
        }
      }
    } else if (i !== 0) {
      nextDate = new Date(nextDate.setMonth(nextDate.getMonth() + monthStepSize));
    }

    nextDate.setDate(((weekNumber - 1) * 7) + 1);

    while (nextDate.getDay() !== currentDateTime.getDay()) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
    events.push(
      {
        weekday: WEEKDAYS[nextDate.getDay()],
        date: nextDate.toLocaleDateString(),
        time: moment(dateTime).format('hh:mm a'),
      }
    );

  }

  return events;
};


const getLastWeekdayEvents = (
  dateTime: Date,
  monthStepSize: number
): ISchedulerEvents[] => {

  const events: ISchedulerEvents[] = [];

  const currentDateTime = new Date(dateTime.getTime());
  const weekNumber = 4;
  let fourthWeekDate = new Date(currentDateTime.getTime());

  for (let i = 0; i < NUMBER_OF_SCHEDULE_EVENTS; i++) {

    if (i === 0 && dateTime.getTime() < new Date().getTime()) {

      const todayDate = new Date();

      fourthWeekDate = new Date(todayDate.setMonth(todayDate.getMonth()));
      if (todayDate.getMonth() === currentDateTime.getMonth()) {
        fourthWeekDate = new Date(todayDate.setMonth(todayDate.getMonth() + monthStepSize));
      } else {
        if (todayDate.getDate() >= currentDateTime.getDate()) {
          fourthWeekDate = new Date(todayDate.setMonth(todayDate.getMonth() + monthStepSize));
        }
      }
    } else if (i !== 0) {
      fourthWeekDate = new Date(fourthWeekDate.setMonth(fourthWeekDate.getMonth() + monthStepSize));
    }

    fourthWeekDate.setDate(((weekNumber - 1) * 7) + 1);

    while (fourthWeekDate.getDay() !== currentDateTime.getDay()) {
      fourthWeekDate.setDate(fourthWeekDate.getDate() + 1);
    }

    const tempDate = new Date(fourthWeekDate);
    let lastWeekDate = new Date(tempDate.setDate(tempDate.getDate() + 7));

    if (lastWeekDate.getMonth() !== fourthWeekDate.getMonth()) {
      lastWeekDate = fourthWeekDate;
    }

    events.push(
      {
        weekday: WEEKDAYS[lastWeekDate.getDay()],
        date: lastWeekDate.toLocaleDateString(),
        time: moment(dateTime).format('hh:mm a'),
      }
    );

  }

  return events;
};


export const getFilteredSchedulerFrequencyOptions = (
  dateTime: Date,
  weekNumber: number,
): IMenuOption[] => {

  const filteredRepeatOptions = SCHEDULER_FREQUENCY_OPTIONS.options.filter(
    (option) => {

      const currentDate = new Date(dateTime);
      const nextWeekDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
      let isLastWeekDay = false;
      const repeatConstants = SCHEDULER_FREQUENCY_OPTIONS.constants as ISchedulerFrequencyConstants;

      if (dateTime.getMonth() !== nextWeekDate.getMonth()) {
        isLastWeekDay = true;
      }

      // Do not show `Monthly 5th Weekday` as every month does not have 5 weekday
      if (
        option.value === repeatConstants.MONTHLY_NTH_WEEKDAY ||
        option.value === repeatConstants.TWO_MONTHLY_NTH_WEEKDAY
      ) {
        return !isLastWeekDay || weekNumber <= 4;
      }

      if (
        option.value === repeatConstants.MONTHLY_LAST_WEEKDAY ||
        option.value === repeatConstants.TWO_MONTHLY_LAST_WEEKDAY
      ) {
        return isLastWeekDay;
      }

      return true;
    }
  );

  return filteredRepeatOptions;

};


export const getFrequencyOptions = (
  filteredRepeatOptions: IMenuOption[],
  dateTime: Date,
  weekDayName: string,
  weekNumber: number,
): IMenuOption[] => {

  const newRepeatOptions = filteredRepeatOptions.map(
    (option) => {

      return {
        ...option,
        label: getFrequencyOptionLabel(
          option,
          dateTime,
          weekDayName,
          weekNumber,
        )
      };

    }
  );

  return newRepeatOptions;
};


export const getScheduleEvents = (
  dateTime: Date,
  frequencyValue: string,
): ISchedulerEvents[] | null => {

  if (!dateTime || !frequencyValue) {
    return null;
  }

  const events: ISchedulerEvents[] = [];
  let weekCount = 3;
  const repeatConstants = SCHEDULER_FREQUENCY_OPTIONS.constants as ISchedulerFrequencyConstants;

  if (
    frequencyValue === repeatConstants.DOES_NOT_REPEAT
  ) {

    let warning = null;
    if (dateTime.getTime() < new Date().getTime()) {
      warning = 'but this is in the past!';
    }

    events.push(
      {
        weekday: WEEKDAYS[dateTime.getDay()],
        date: dateTime.toLocaleDateString(),
        time: moment(dateTime).format('hh:mm a'),
        warning
      }
    );

  } else {

    switch (frequencyValue) {

      case repeatConstants.EVERY_DAY: {

        const currentDateTime = new Date(dateTime.getTime());
        let nextDate = currentDateTime;

        for (let i = 0; i < NUMBER_OF_SCHEDULE_EVENTS; i++) {

          if (i === 0 && dateTime.getTime() < new Date().getTime()) {
            const todayDate = new Date();
            nextDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
          } else if (i !== 0) {
            nextDate = new Date(nextDate.setDate(nextDate.getDate() + 1));
          }

          events.push(
            {
              weekday: WEEKDAYS[nextDate.getDay()],
              date: nextDate.toLocaleDateString(),
              time: moment(dateTime).format('hh:mm a'),
            }
          );
        }
        break;
      }

      case repeatConstants.MONDAY_TO_FRIDAY: {

        let i = 0;
        const currentDateTime = new Date(dateTime.getTime());
        let nextDate = currentDateTime;

        while (i < NUMBER_OF_SCHEDULE_EVENTS) {

          if ([0, 6].includes(nextDate.getDay())) {

            nextDate = new Date(nextDate.setDate(nextDate.getDate() + 1));
          } else if (i === 0 && dateTime.getTime() < new Date().getTime()) {

            const todayDate = new Date();
            nextDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
          } else if (i !== 0) {

            nextDate = new Date(nextDate.setDate(nextDate.getDate() + 1));
          }

          if (![0, 6].includes(nextDate.getDay())) {
            events.push(
              {
                weekday: WEEKDAYS[nextDate.getDay()],
                date: nextDate.toLocaleDateString(),
                time: moment(dateTime).format('hh:mm a'),
              }
            );
            i++;
          }
        }
        break;
      }

      case repeatConstants.EVERY_WEEK: {
        weekCount = 1;
        const currentDateTime = new Date(dateTime.getTime());
        let nextDate = currentDateTime;

        for (let i = 0; i < NUMBER_OF_SCHEDULE_EVENTS; i++) {

          if (i === 0 && dateTime.getTime() < new Date().getTime()) {
            const todayDate = new Date();
            nextDate = new Date(todayDate.setDate(todayDate.getDate() + (weekCount * 7)));
          } else if (i !== 0) {
            nextDate = new Date(nextDate.setDate(nextDate.getDate() + (weekCount * 7)));
          }

          events.push(
            {
              weekday: WEEKDAYS[nextDate.getDay()],
              date: nextDate.toLocaleDateString(),
              time: moment(dateTime).format('hh:mm a'),
            }
          );

        }
        break;
      }

      // @ts-ignore
      case repeatConstants.EVERY_2_WEEKS:
        weekCount = 2;

      // eslint-disable-next-line no-fallthrough
      case repeatConstants.EVERY_3_WEEKS: {

        const currentDateTime = new Date(dateTime.getTime());
        let nextDate = currentDateTime;

        for (let i = 0; i < NUMBER_OF_SCHEDULE_EVENTS; i++) {

          if (i === 0 && dateTime.getTime() < new Date().getTime()) {
            const todayDate = new Date();
            nextDate = new Date(todayDate.setDate(todayDate.getDate() + (weekCount * 7)));
          } else if (i !== 0) {
            nextDate = new Date(nextDate.setDate(nextDate.getDate() + (weekCount * 7)));
          }

          events.push(
            {
              weekday: WEEKDAYS[nextDate.getDay()],
              date: nextDate.toLocaleDateString(),
              time: moment(dateTime).format('hh:mm a'),
            }
          );

        }
        break;
      }

      case repeatConstants.MONTHLY_NTH_DAY: {

        const nthDayEvents = getNthDayEvents(
          dateTime,
          1
        );

        events.push(...nthDayEvents);
        break;
      }

      case repeatConstants.TWO_MONTHLY_NTH_DAY: {

        const nthDayEvents = getNthDayEvents(
          dateTime,
          2
        );

        events.push(...nthDayEvents);
        break;
      }

      case repeatConstants.MONTHLY_NTH_WEEKDAY: {

        const nthWeekdayEvents = getNthWeekdayEvents(
          dateTime,
          1
        );

        events.push(...nthWeekdayEvents);
        break;
      }

      case repeatConstants.TWO_MONTHLY_NTH_WEEKDAY: {

        const nthWeekdayEvents = getNthWeekdayEvents(
          dateTime,
          2
        );

        events.push(...nthWeekdayEvents);
        break;
      }

      case repeatConstants.MONTHLY_LAST_WEEKDAY: {

        const lastWeekdayEvents = getLastWeekdayEvents(
          dateTime,
          1
        );

        events.push(...lastWeekdayEvents);
        break;
      }

      case repeatConstants.TWO_MONTHLY_LAST_WEEKDAY: {

        const lastWeekdayEvents = getLastWeekdayEvents(
          dateTime,
          2
        );

        events.push(...lastWeekdayEvents);
        break;
      }

    }
  }

  return events;

};


export const getFrequencyOptionLabel = (
  option: IMenuOption,
  dateTime: Date,
  weekDayName: string,
  weekNumber: number,
): string => {

  const repeatConstants = SCHEDULER_FREQUENCY_OPTIONS.constants as ISchedulerFrequencyConstants;

  switch (option.value) {

    case repeatConstants.EVERY_DAY:
    case repeatConstants.MONDAY_TO_FRIDAY: {
      return `${option.label} ${moment(dateTime).format('hh:mm A')}`;
    }

    case repeatConstants.EVERY_WEEK:
    case repeatConstants.EVERY_2_WEEKS:
    case repeatConstants.EVERY_3_WEEKS: {
      return `${option.label} ${weekDayName}`;
    }

    case repeatConstants.MONTHLY_NTH_DAY:
    case repeatConstants.TWO_MONTHLY_NTH_DAY: {
      const date = dateTime.getDate();
      return `${option.label} ${date}`;
    }

    case repeatConstants.MONTHLY_NTH_WEEKDAY:
    case repeatConstants.TWO_MONTHLY_NTH_WEEKDAY: {
      const wNum = weekNumber as keyof typeof NUMBER_POSTFIX;
      return `${option.label} ${weekNumber}${NUMBER_POSTFIX[wNum]} ${weekDayName}`;
    }

    case repeatConstants.MONTHLY_LAST_WEEKDAY:
    case repeatConstants.TWO_MONTHLY_LAST_WEEKDAY: {
      return `${option.label} ${weekDayName}`;
    }

    default: {
      return option.label;
    }

  }
};


export const getSchedulerDateAndWeekInfo = (
  schedulerDateTime: Date
) => {

  const dateTime = schedulerDateTime;
  const weekNumber = Math.ceil(dateTime.getDate() / 7);
  const weekDayName = WEEKDAYS[dateTime.getDay()];

  return {
    dateTime,
    weekNumber,
    weekDayName,
  }
};


export const isDateRangeValid = (
  startDate: number | null | undefined,
  endDate: number | null | undefined
): boolean => {

  if (!endDate || !startDate) {
    return true;
  }

  if (endDate && startDate && endDate > startDate) {
    return true;
  }

  return false;
};