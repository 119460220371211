import { Card, Form } from '@themesberg/react-bootstrap';
import { CollapsibleCard, FormField } from 'components';
import { FormContext } from 'contexts';
import { updateFeedbackLoopNotificationPreference } from 'data';
import { useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  INotificationPreferenceConfigFormValues,
  IUpdateNotificationPreferenceRequestData,
} from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { NOTIFICATION_SETTINGS } from 'teamble-constants';
import { NOTIFICATION_PREFERENCE_CONFIG_VALIDATION_SCHEMA } from 'validation-schemas';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import { getNotificationPreferenceFormValues } from '../utils/formUtils';

interface ISendNotificationConfigWidgetProps { }

const SendNotificationConfigWidget: React.FC<ISendNotificationConfigWidgetProps> = () => {

  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Feedback Loop config hook
  const { feedbackLoop, onFeedbackLoopUpdate } = useFeedbackLoopConfig();

  // Form hook
  const notificationPreferenceForm = useFormik<INotificationPreferenceConfigFormValues>({
    initialValues: getNotificationPreferenceFormValues(feedbackLoop),
    validationSchema: NOTIFICATION_PREFERENCE_CONFIG_VALIDATION_SCHEMA,
    onSubmit: () => { }
  });


  React.useEffect(
    () => {

      if (
        !notificationPreferenceForm.isValid ||
        (
          (
            !feedbackLoop.notifications &&
            !notificationPreferenceForm.values.notificationPreference?.value
          ) ||
          notificationPreferenceForm.values.notificationPreference?.value ===
          feedbackLoop.notifications
        )
      ) {
        return;
      }

      const requestData: IUpdateNotificationPreferenceRequestData = {
        feedbackLibraryLoopId: feedbackLoop.id,
        notifications: notificationPreferenceForm.values.notificationPreference?.value || '',
      };

      setIsLoading(true);
      const subscription = updateFeedbackLoopNotificationPreference(requestData)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            onFeedbackLoopUpdate(response.data.data);
            toast.success(response.data.message);
          }
        );

      return () => subscription.unsubscribe();

    },
    [
      feedbackLoop.notifications, feedbackLoop.id, feedbackLoop.workspaceId,
      notificationPreferenceForm.isValid, notificationPreferenceForm.values.notificationPreference,
      onFeedbackLoopUpdate
    ]
  );


  return (
    <CollapsibleCard
      title='Send Notifications As'
      isLoading={isLoading}
    >

      <Card.Body>
        <Form>
          <FormContext.Provider value={notificationPreferenceForm}>

            <FormField
              label='Send Notifications as *'
              controlId={`notificationPreferenceConfigField`}
              type={FormFieldTypeConstants.Select}
              options={NOTIFICATION_SETTINGS.options}
              controlName='notificationPreference'
              isDisabled={isLoading}
            />

          </FormContext.Provider>
        </Form>
      </Card.Body>

    </CollapsibleCard>
  );
};

export default SendNotificationConfigWidget;