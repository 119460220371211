import { FormField, Preloader } from 'components';
import { FormContext } from 'contexts';
import { FormikProps } from 'formik';
import {
  FormFieldTypeConstants,
  INomination,
  INominationActionFormValues,
  NominationActions,
} from 'interfaces';
import React from 'react';
import { NOMINATION_ACTIONS } from 'teamble-constants';

interface IReviewTodoNominationActionFormProps {
  nominations: INomination[];
  nominationActionForm: FormikProps<INominationActionFormValues>;
}

const ReviewTodoNominationActionForm: React.FC<IReviewTodoNominationActionFormProps> = (
  { nominationActionForm, nominations }
) => {

  return (
    <FormContext.Provider value={nominationActionForm}>
      {
        nominationActionForm.values.nominations.length === nominations.length
          ?
          nominationActionForm.values.nominations.map(
            (_, index) => (
              <React.Fragment key={nominations[index].reviewee.id + nominations[index].reviewer.id}>

                <FormField
                  label={`Reviewee: @${nominations[index].reviewee.name} & Reviewer: @${nominations[index].reviewer.name}`}
                  type={FormFieldTypeConstants.Radio}
                  controlName={`nominations[${index}].approveStatus`}
                  controlId={`approveStatusField${index}`}
                  options={NOMINATION_ACTIONS}
                />

                {
                  nominationActionForm.values.nominations[index].approveStatus === NominationActions.Deny &&
                  <FormField
                    label='Provide a reason'
                    controlId={`reasonField${index}`}
                    type={FormFieldTypeConstants.Textarea}
                    placeholder='Reason to deny...'
                    controlName={`nominations[${index}].reason`} />
                }

              </React.Fragment>
            )
          )
          :
          <Preloader show />
      }
    </FormContext.Provider>
  );
};

export default ReviewTodoNominationActionForm;