import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Card, Collapse } from '@themesberg/react-bootstrap';
import { getNominateAudienceFromFile, updateNominateAudience } from 'data';
import { FormikProps } from 'formik';
import { useApp } from 'hooks';
import {
  IActiveReviewFormValues,
  IMenuOption,
  IMsTeamsChannelUser, IReviewNominateAudience,
  IRouteParams,
  ISimpleMenuOption,
  SignInWith
} from 'interfaces';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import ValidNominateAudienceModal from '../modals/InvalidNominateAudienceModal';
import ReviewNominationAudienceTable from './ReviewNominationAudienceTable';
import SelectAudienceField from './SelectAudienceField';
import UploadNominateAudience from './UploadNominateAudience';
import { ConfirmationModal } from 'components';

enum ActiveAudienceField {
  Select = 'select',
  Upload = 'upload'
}

interface ILaunchNominationFieldsProps {
  launchNewReviewForm: FormikProps<IActiveReviewFormValues>;
  selectedChannel: ISimpleMenuOption;
  audience: IReviewNominateAudience[] | null | undefined,
  onAudienceChange: (
    audience: IReviewNominateAudience[] | null
  ) => void;
  startNominations: () => void;
  isStartingNomination: boolean;
  isNominationInProgress: boolean;
}

interface IAudienceTypeSelectGroupProps {
  loading: boolean;
  launchNewReviewForm: FormikProps<IActiveReviewFormValues>;
  isStartingNomination: boolean;
  isNominationInProgress: boolean;
  activeAudienceField: ActiveAudienceField;
  handleStartNomination: () => void;
  handleParticipants: () => void;
  onActiveAudienceField: (activeAudienceField: ActiveAudienceField) => void;
}


const AudienceTypeSelectGroup: React.FunctionComponent<IAudienceTypeSelectGroupProps> = ({
  loading,
  activeAudienceField,
  launchNewReviewForm,
  isStartingNomination,
  isNominationInProgress,
  handleStartNomination,
  handleParticipants,
  onActiveAudienceField,
}) => {
  const { reviewId } = useParams<IRouteParams>();

  return (
    <>
      {/* Select and Upload buttons */}
      {
        !isNominationInProgress &&
        <ButtonGroup>
          <Button type='button' disabled={loading}
            variant={`outline-primary ${activeAudienceField === ActiveAudienceField.Select ? 'active' : ''}`}
            onClick={() => onActiveAudienceField(ActiveAudienceField.Select)}
          >
            Select
          </Button>

          <Button type='button' disabled={loading}
            variant={`outline-primary ${activeAudienceField === ActiveAudienceField.Upload ? 'active' : ''}`}
            onClick={() => onActiveAudienceField(ActiveAudienceField.Upload)}
          >
            Upload
          </Button>

        </ButtonGroup>
      }

      {/* Start Nomination Button */}
      {
        !!launchNewReviewForm?.values?.nominatorAudience?.verifiedEmailPairs?.length &&
        !isNominationInProgress &&
        <Button
          type="button"
          style={{ marginLeft: '15px' }}
          disabled={
            !reviewId || launchNewReviewForm.isSubmitting || isStartingNomination
          }
          variant="primary"
          onClick={handleStartNomination}
        >
          {isStartingNomination ? "Starting Nomination..." : "Start Nomination"}
        </Button>
      }

      {/* Participants button */}
      {
        isNominationInProgress &&
        <Button
          type="button"
          disabled={
            !reviewId || launchNewReviewForm.isSubmitting || isStartingNomination
          }
          variant="outline-primary"
          onClick={handleParticipants}
        >
          Participants
        </Button>
      }
    </>
  );
};



const LaunchNominationFields: React.FunctionComponent<ILaunchNominationFieldsProps> = (
  {
    selectedChannel, audience, launchNewReviewForm,
    isStartingNomination, isNominationInProgress,
    onAudienceChange, startNominations,
  }
) => {

  const [loadingAudience, setLoadingAudience] = React.useState<boolean>(false);
  const [isStartClearing, setStartClearing] = React.useState<boolean>(false);
  const [showParticipants, setShowParticipants] = React.useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const [activeAudienceField, setActiveAudienceField] = React.useState<ActiveAudienceField>(
    ActiveAudienceField.Select
  );

  const { reviewId } = useParams<IRouteParams>();
  const app = useApp();


  const removePairFromAudienceList = (index: number): void => {
    if (audience) {
      const audienceCopy = [...audience];
      const remove = audienceCopy.splice(index, 1);
      updateNominateAudienceCall(remove, audienceCopy)
    }
  };


  const updateNominateAudienceCall = (audienceToRemove: IReviewNominateAudience[], updatedAudience: IReviewNominateAudience[]) => {
    if (!reviewId) {
      return;
    }
    updateNominateAudience(reviewId, 'remove', audienceToRemove)
      .subscribe(
        () => {
          onAudienceChange(updatedAudience);
        }
      );
  }


  const audiencePairsByFileUpload = (
    isFileUploading: boolean,
    audienceUploaded?: (IMsTeamsChannelUser | IReviewNominateAudience)[]
  ): void => {
    setLoadingAudience(isFileUploading);

    if (audienceUploaded) {
      let newAudienceUploaded: IActiveReviewFormValues["nominatorAudience"] | Array<any> =
        audience || [];
      newAudienceUploaded = [...newAudienceUploaded, ...audienceUploaded];
      const ids = newAudienceUploaded.map(o => o.id)
      const distinctAudienceUploaded: IReviewNominateAudience[] = newAudienceUploaded.filter(({ id }, index) => !ids.includes(id, index + 1))
      onAudienceChange(distinctAudienceUploaded);
    }
  };


  const addSelectedPairsToAudienceList = (audienceSelected: IMenuOption[]): void => {

    if (!reviewId) {
      return;
    }

    if (audienceSelected.length) {
      let newAudienceSelected: IActiveReviewFormValues["nominatorAudience"] | Array<any> =
        audience || [];
      newAudienceSelected = [...newAudienceSelected, ...audienceSelected];
      const ids = newAudienceSelected.map(o => o.id)
      const distinctAudienceSelected: IReviewNominateAudience[] =
        newAudienceSelected.filter(({ id }, index) => !ids.includes(id, index + 1));
      updateNominateAudience(reviewId, 'add', distinctAudienceSelected).subscribe(
        () => {
          onAudienceChange(distinctAudienceSelected);
        }
      );
    }
  };


  const onClear = () => {
    setStartClearing(true)
    const formData = new FormData();
    if (app === SignInWith.Slack) {
      formData.append('workspaceId', `${selectedChannel.value}`);
      formData.append('campaignId', `${reviewId}`);
      formData.append('clear', `${true}`);
    } else {
      formData.append('channelId', `${selectedChannel.value}`);
      formData.append('campaignId', `${reviewId}`);
      formData.append('clear', `${true}`);
    }
    if (formData) {
      getNominateAudienceFromFile(formData)
        .subscribe(
          () => {
            onAudienceChange(null)
            setStartClearing(false)
          }
        );

    }
  };


  return (
    <>
      <Card className='my-2'>
        <Card.Header
          aria-controls='launchReviewNominationWidget'
          aria-expanded={!isCollapsed}
          className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Card.Title className='m-0' as='h6'>
            Nomination Audience
          </Card.Title>
          <Button variant='link' size='sm'>
            <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
          </Button>
        </Card.Header>

        <Collapse in={!isCollapsed}>
          <div>
            <Card className={`${!isNominationInProgress && "rounded-0 rounded-top round"}`}>
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="d-flex">
                    <h5> Nomination Audience </h5>
                  </div>
                  <div>
                    <AudienceTypeSelectGroup
                      launchNewReviewForm={launchNewReviewForm}
                      isStartingNomination={isStartingNomination}
                      loading={loadingAudience}
                      isNominationInProgress={isNominationInProgress}
                      activeAudienceField={activeAudienceField}
                      handleStartNomination={() => setShowConfirmationModal(true)}
                      handleParticipants={() => setShowParticipants(true)}
                      onActiveAudienceField={setActiveAudienceField}
                    />
                  </div>
                </div>

                {
                  !isNominationInProgress &&
                  <>
                    {
                      activeAudienceField === ActiveAudienceField.Select
                        ?
                        <SelectAudienceField
                          channelOrWorkspaceId={selectedChannel.value}
                          onAddToListClick={addSelectedPairsToAudienceList}
                        />
                        :
                        <UploadNominateAudience
                          reviewId={reviewId}
                          channelOrWorkspaceId={selectedChannel.value}
                          onFileUpload={audiencePairsByFileUpload}
                        />
                    }
                  </>
                }
              </Card.Body>
            </Card>

            {
              !!launchNewReviewForm?.values?.nominatorAudience?.verifiedEmailPairs?.length &&
              <ValidNominateAudienceModal
                show={showParticipants}
                title="Audience"
                subtitle="These audience are added in the list as reviewee."
                invalidAudiencePairs={launchNewReviewForm?.values?.nominatorAudience?.verifiedEmailPairs}
                onClose={() => setShowParticipants(false)} />
            }

            {
              !!audience?.length && !isNominationInProgress
                ?
                <Card className="rounded-0 rounded-bottom" style={{ zIndex: 0 }}>
                  <Card.Body>
                    <ReviewNominationAudienceTable
                      isStartClearing={isStartClearing}
                      audience={audience}
                      isNominationInProgress={isNominationInProgress}
                      loadingAudience={loadingAudience}
                      isAudienceAvailable={!!audience?.length}
                      onAudienceChange={onAudienceChange}
                      onPairRemove={removePairFromAudienceList}
                      onBulkAction={updateNominateAudienceCall}
                      onClearAllClick={onClear}
                    />
                  </Card.Body>
                </Card>
                :
                null
            }
          </div>
        </Collapse>
      </Card>

      {
        audience?.length && showConfirmationModal
          ?
          <ConfirmationModal
            title='Start Nomination'
            show={showConfirmationModal}
            onPositiveResponse={() => startNominations()}
            onCloseClick={() => setShowConfirmationModal(false)}
          >
            <p>You are about to launch nomination to {audience.length} people, please confirm.</p>
          </ConfirmationModal>
          :
          null
      }
    </>
  );
};

export default LaunchNominationFields;
