import Axios, { AxiosObservable } from 'axios-observable';
import {
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IQuestionTypeConstants,
  IResponse,
  IReviewChoiceWithValue,
  IReviewTemplate,
  IReviewTemplateDeleteResponse,
  IReviewTemplateRequestData,
  IReviewTemplateResponse,
  ISimpleMenuOption,
  SignInWith,
} from 'interfaces';
import { map } from 'rxjs';
import { toHTML } from 'slack-markdown';

export const getPerformanceReviewQuestionnaires: () => AxiosObservable<IResponse<IReviewTemplate[]>> = () => {
  return Axios.post<IResponse<IReviewTemplate[]>>('/list-performance-review-templates');
};


export const getPerformanceReviewQuestionnairesAsMenuOptions =
  (): AxiosObservable<IResponse<IMenuOption[]>> => {
    return getPerformanceReviewQuestionnaires().pipe(
      map(
        (response) => {

          if (!response.data.data?.length) {
            return {
              ...response,
              data: {
                ...response.data,
                data: []
              }
            };
          }

          const options: IMenuOption[] = response.data.data.map(
            (option) => ({
              value: option.id,
              label: option.title
            })
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: options
            }
          };

        }
      )
    );
  };


export const getPerformanceReviewTemplateById =
  (
    app: SignInWith,
    perfReviewTemplateId: string,
    metadata: IMetadata
  ): AxiosObservable<IResponse<IReviewTemplate>> => {

    return Axios.post<IResponse<IReviewTemplateResponse>>(
      '/detail-performance-review-template',
      {
        perfReviewTemplateId
      }
    ).pipe(
      map(
        (response) => {

          const reviewTemplateData = response.data.data;

          const reviewTemplate: IReviewTemplate =
            getMappedReviewTemplateData(app, reviewTemplateData, metadata as IMetadata);

          return {
            ...response,
            data: {
              ...response.data,
              data: reviewTemplate
            }
          };

        }
      )
    );
  };


export const createReviewTemplate = (data: IReviewTemplateRequestData) => {
  return Axios.post<IResponse<IReviewTemplate>>('/create-performance-review-template', data);
};


export const updateReviewTemplate = (templateId: string, data: IReviewTemplateRequestData) => {
  return Axios.post<IResponse<IReviewTemplate>>(
    '/update-performance-review-template',
    {
      perfReviewTemplateId: templateId,
      ...data
    }
  );
};

export const getCampaignLinkOptions = (templateId: string) => {
  return Axios.post<IResponse<ISimpleMenuOption[]>>(
    '/campaign-link-options',
    { templateId }
  );
};


export const getPredefinedPerformanceReviewTemplates = (): AxiosObservable<IResponse<IReviewTemplate[]>> => {
  return Axios.post<IResponse<IReviewTemplate[]>>('/list-predefined-templates');
};


export const getPredefinedPerformanceReviewTemplateDetail =
  (
    app: SignInWith,
    preDefinedTemplateId: string,
    metadata: IMetadata
  ): AxiosObservable<IResponse<IReviewTemplate>> => {

    return Axios.post<IResponse<IReviewTemplateResponse>>(
      '/detail-predefined-template',
      {
        preDefinedTemplateId
      }
    ).pipe(
      map(
        (response) => {

          const reviewTemplateData = response.data.data;

          const reviewTemplate: IReviewTemplate = getMappedReviewTemplateData(app, reviewTemplateData, metadata as IMetadata);

          return {
            ...response,
            data: {
              ...response.data,
              data: reviewTemplate
            }
          };

        }
      )
    );
  };


const getMappedReviewTemplateData =
  (
    app: SignInWith,
    reviewTemplateData: IReviewTemplateResponse,
    metadata: IMetadata
  ): IReviewTemplate => {

    const reviewTemplate: IReviewTemplate = {
      ...reviewTemplateData,
      title: reviewTemplateData.title,
      blocks: []
    };

    const questionTypeConstants: IQuestionTypeConstants =
      metadata.questionTypes.constants as IQuestionTypeConstants;

    if (reviewTemplateData.blocks?.length) {

      reviewTemplateData.blocks.forEach(
        (block) => {

          switch (block.type) {

            case questionTypeConstants.TITLE:
            case questionTypeConstants.SUBTITLE: {

              reviewTemplate.blocks.push({
                ...block,
                titleValue: {
                  text: toHTML(block.text || '', { hrefTarget: '_blank' })
                }
              });
              break;
            }

            case questionTypeConstants.SHORT_TEXT:
            case questionTypeConstants.LONG_TEXT: {

              reviewTemplate.blocks.push({
                ...block,
                textValue: {
                  label: block.label,
                  placeholder: block.placeholder,
                  required: block.required,
                }
              });
              break;
            }

            case questionTypeConstants.COMPETENCY_QUESTION:
            case questionTypeConstants.MULTI_SELECT_QUESTION: {

              const multiSelectTypeConstants =
                metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
              // let selectChoices: string = '';
              let selectChoicesWithValues: IReviewChoiceWithValue[] = [];

              if (block.family === multiSelectTypeConstants.CUSTOM_ITEMS && block.choices) {

                selectChoicesWithValues = (block.choices as string[]).map(
                  (choice) => ({
                    choice,
                    value: block.choicesValues?.[choice] || 0
                  })
                );

                // switch (app) {

                //   case SignInWith.Slack: {
                //     selectChoicesWithValues = (block.choices as string[]).map(
                //       (choice) => ({
                //         choice,
                //         value: block.choicesValues?.[choice] || 0
                //       })
                //     );
                //     break;
                //   }

                //   case SignInWith.MsTeams: {
                //     selectChoices = block.choices.join('\n');
                //     break;
                //   }

                // }
              }

              const multiSelectBlock = {
                label: block.label,
                required: block.required,
                dropdown: block.dropdown || false,
                numericValue: Boolean(block.numericValue),
                choices: selectChoicesWithValues,
                commentBox: !!block.comment,
                commentBoxGuide: block.comment || '',
                selectedOption: metadata.multiSelectQuestionOptionsTypes.options.find(
                  (option) => option.value === block.family
                ) as IMenuOption
              };

              reviewTemplate.blocks.push(
                block.type === questionTypeConstants.MULTI_SELECT_QUESTION
                  ?
                  {
                    ...block,
                    multiSelectValue: multiSelectBlock
                  }
                  :
                  {
                    ...block,
                    competencyValue: {
                      ...multiSelectBlock,
                      showBehaviours: block.showBehaviours || false,
                      showDescription: block.showDescription || false,
                      isBehavior: block.isBehavior || false,
                      isNotApplicableOption: block.isNotApplicableOption || false,
                      selectedView: metadata.selectedView.options.find(
                        (option) => option.value === block.selectedView?.value
                      ) as ISimpleMenuOption || metadata.selectedView.options[0],
                      dropdown: block?.selectedView?.value === 'dropdown',
                    }
                  }
              );
              break;
            }

            case questionTypeConstants.OBJECTIVE_QUESTION: {

              const multiSelectTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
              let selectChoicesWithValues: IReviewChoiceWithValue[] = [];

              if (block.family === multiSelectTypeConstants.CUSTOM_ITEMS && block.choices) {

                selectChoicesWithValues = (block.choices as string[]).map(
                  (choice) => ({
                    choice,
                    value: block.choicesValues?.[choice] || 0
                  })
                );

              }

              const multiSelectBlock = {
                label: block.label,
                required: block.required,
                dropdown: block.dropdown || false,
                numericValue: Boolean(block.numericValue),
                choices: selectChoicesWithValues,
                comments: block.comments || [],
                commentBox: Boolean(block.commentBox),
                commentBoxGuide: block.comment || '',
                selectedOption: metadata.multiSelectQuestionOptionsTypes.options.find(
                  (option) => option.value === block.family
                ) as IMenuOption
              };

              reviewTemplate.blocks.push({
                ...block,
                objectiveValue: {
                  ...multiSelectBlock,
                  showKPIs: block.showKPIs || false,
                  showDescription: block.showDescription || false,
                  isKPI: block.isKPI || false,
                  isNotApplicableOption: block.isNotApplicableOption || false,
                  selectedView: metadata.selectedView.options.find(
                    (option) => option.value === block.selectedView?.value
                  ) as ISimpleMenuOption || metadata.selectedView.options[0],
                  dropdown: block?.selectedView?.value === 'dropdown',
                }
              });
              break;
            }

          }
        }
      );
    }

    return reviewTemplate;

  };

export const duplicateReviewTemplate
  = (perfReviewTemplateId: string): AxiosObservable<IResponse<IReviewTemplate>> => {

    return Axios.post<IResponse<IReviewTemplate>>(
      '/duplicate-performance-review-template',
      { perfReviewTemplateId }
    );
  };


export const deleteReviewTemplate
  = (perfReviewTemplateId: string, isInitialCheck: boolean): AxiosObservable<IResponse<IReviewTemplateDeleteResponse>> => {

    return Axios.post<IResponse<IReviewTemplateDeleteResponse>>(
      '/remove-performance-review-template',
      { perfReviewTemplateId, isInitialCheck }
    );
  };