import { Card } from '@themesberg/react-bootstrap';
import { useUserProfile } from 'components';
import { ISurvey, SignInWith } from 'interfaces';
import moment from 'moment';
import * as React from 'react';
import { useSurveyAudience } from '../hooks/useSurveyAudience';
import { useSurveyDuration } from '../hooks/useSurveyDuration';
import { useSurveyInspectors } from '../hooks/useSurveyInspectors';
import TeambleLogo from './../../../assets/img/brand/logo.png';
import TeamFeedbackBanner from './../../../assets/img/banners/team_feedback_banner.png';
import { useApp } from "hooks";

interface ISurveySummaryWidgetProps {
  surveyDetails: ISurvey;
}

const SurveySummaryWidget: React.FunctionComponent<ISurveySummaryWidgetProps> = (
  { surveyDetails }
) => {

  const { userProfile } = useUserProfile();
  const surveyDuration = useSurveyDuration(surveyDetails);
  const surveyAudience = useSurveyAudience(surveyDetails);
  const surveyInspectors = useSurveyInspectors(surveyDetails);
  const app = useApp();
  return (
    <Card className='h-100'>
      <Card.Header>
        <Card.Title className='m-0'>{app === SignInWith.Slack ? "Slack Preview" : "Teams Preview"}</Card.Title>
      </Card.Header>
      <Card.Body className='bg-light px-7 d-flex align-items-center flex-grow-1 overflow-y-auto'>
        <Card className='w-100 font-family-lato'>
          <Card.Body className='d-flex'>
            <div className='flex-shrink-0'>
              {
                app === SignInWith.Slack ? (
                  <img
                    height='40'
                    width='40'
                    src={TeambleLogo}
                    className='rounded-3'
                    alt="Teamble Blue Gradient logo" />
                ) : (
                  <img
                    height='20'
                    width='40'
                    src={TeambleLogo}
                    className='teams-hexagon'
                    alt="Teamble Blue Gradient logo" />
                )
              }
            </div>
            <div className='flex-grow-1 ms-3'>
              <p className='font-family-lato lh-1'>
                {
                  app === SignInWith.Slack ? (
                    <>
                      <strong> teamble </strong>
                      <span className='slack-app-badge me-1'>APP</span>
                    </>
                  ) : (
                    <strong> Teamble </strong>
                  )
                }

                <span> {moment().format('h:mm A')} </span>
              </p>
              <div className='mt-4'>

                {
                  app !== "slack" && <img
                    width='auto'
                    src={TeamFeedbackBanner}
                    className='mb-2'
                    alt="Teamble Feedback Team Banner" />
                }
                <h5 className='font-family-lato'>
                  <span role='img' aria-label='rocket-emoji'> 🚀 </span>
                  {surveyDetails.title}
                  <span> Launched! </span>
                </h5>
                <div className='mb-3'>
                  <p className='font-family-lato m-0'>
                    Hello @{userProfile!.name} 👋
                  </p>
                  <p className='font-family-lato m-0'>
                    <strong> {surveyDetails.title} </strong> is now live! ✅
                  </p>
                  <p className='font-family-lato m-0'>
                    Please <strong> start your survey below! </strong>
                  </p>
                </div>

                <div>
                  <p className='font-family-lato m-0'>
                    <strong> From: </strong> @{userProfile!.name} at {surveyDetails?.[app === SignInWith.Slack ? "workspace" : "channel"]?.name}
                  </p>
                  <p className='font-family-lato m-0'>
                    <strong> 🏁 Closes: </strong> {surveyDuration}
                  </p>
                  <p className='font-family-lato m-0'>
                    <strong> 👨‍💻👩‍💻 Audience: </strong> {surveyAudience}
                  </p>
                  <p className='font-family-lato m-0'>
                    {
                      surveyDetails.anonymity.type === 'anonymous' ?
                        <strong> 👥 Anonymous </strong> :
                        <strong> 👤 Non Anonymous </strong>
                    }
                    | <strong> Minimum Submission count: </strong> {surveyDetails.minSubmissionCount + ' '}
                    | <strong> 🧐 Results: </strong> {surveyInspectors + ' '}
                    | <strong> Results Visibility: </strong> {surveyDetails.isLiveResults ? 'Real Time' : 'One survey is closed'}
                  </p>
                </div>

              </div>
            </div>
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  );
};

export default SurveySummaryWidget;
