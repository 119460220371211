import { Button } from '@themesberg/react-bootstrap';
import { PageTitle, Preloader, PrimaryLayoutBox } from 'components';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';

import ReviewsListCard from './components/ReviewsListCard';
// import { useOrganizationMenuOptions } from 'hooks';
// import { useQueryParam, StringParam } from 'use-query-params';


const ReviewsListPage: React.FunctionComponent = () => {

  // Refactored Not needed below "organization" selection code...

  /*
  URL Query Param to store the id of selected organization
  const [organizationId, setOrganizationId] = useQueryParam('organizationId', StringParam);

  Set the first option of organizations as the default one if no option is selected
  React.useEffect(() => {
    if (organizationId) return;
    if (!organizationOptions.length) {
      setOrganizationId(undefined);
      return;
    }
    setOrganizationId(organizationOptions[0].value);
  },
    [organizationId, organizationOptions, setOrganizationId]
  );
  if (isLoading) {
    return (
      <div className='h-100vh d-flex justify-content-center align-items-center'>
        <Preloader isIcon show={isLoading} />
      </div>
    )
  }
  */

  return (
    <>
      <PageTitle title={Routes.ReviewsListPage.title} />
      <PrimaryLayoutBox
        title={Routes.ReviewsListPage.title}
        actions={
          <Link to={Routes.LaunchReview.path}>
            <Button type='button' variant="primary" size="sm">
              Launch New
            </Button>
          </Link>
        }
      >
        <div className='flex-grow-1 position-relative overflow-hidden'>
          <ReviewsListCard />
        </div>
      </PrimaryLayoutBox>
    </>
  );
};

export default ReviewsListPage;
