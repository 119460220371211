import { Col, Row } from '@themesberg/react-bootstrap';
import { IFeedbackInsightsChartData } from 'interfaces';
import React from 'react';

import FeedbackTreemapChartWidget from './FeedbackTreemapChartWidget';
import { useCompanyConfig } from '@common/modules/companyConfig';

interface IFeedbackInsightChartsProps {
  feedbackInsightsChartData: IFeedbackInsightsChartData;
}


const FeedbackInsightCharts: React.FC<IFeedbackInsightChartsProps> = (
  { feedbackInsightsChartData }
) => {

  const companyConfig = useCompanyConfig();
  const customText = companyConfig?.customText;

  return (
    <Row>
      {
        feedbackInsightsChartData.competency?.length
          ?
          <Col xs={6}>
            <div className='my-2'>
              <FeedbackTreemapChartWidget
                chartTitle={customText?.competencies && `🏅 ${customText.competencies}` || "🏅 Competencies"}
                chartData={feedbackInsightsChartData.competency} />
            </div>
          </Col>
          :
          null
      }
      {
        feedbackInsightsChartData.strengthCompetency?.length
          ?
          <Col xs={6}>
            <div className='my-2'>
              <FeedbackTreemapChartWidget
                chartTitle="💪 Strengths"
                chartData={feedbackInsightsChartData.strengthCompetency} />
            </div>
          </Col>
          :
          null
      }
      {
        feedbackInsightsChartData.developmentCompetency?.length
          ?
          <Col xs={6}>
            <div className='my-2'>
              <FeedbackTreemapChartWidget
                chartTitle="🎯 Development Areas"
                chartData={feedbackInsightsChartData.developmentCompetency} />
            </div>
          </Col>
          :
          null
      }
    </Row>
  );
};

export default FeedbackInsightCharts;