import { List } from '@common/components';
import { Modal } from '@themesberg/react-bootstrap';
import { CustomModal, DataState } from 'components';
import { MetaDataContext } from 'contexts';
import { getPerformanceReviewTemplateById } from 'data';
import { useApp } from 'hooks';
import { IMetadata, IModalProps, IReviewTemplateQuestion } from 'interfaces';
import React, { useEffect } from 'react';
import { finalize } from 'rxjs';

import ReviewTemplateQuestionPreview from '../components/ReviewTemplateQuestionPreview';

interface ILaunchReviewTemplateQuestionPreviewModalProps extends IModalProps {
  reviewTemplateId: string
}

const LaunchReviewTemplateQuestionPreviewModal: React.FC<ILaunchReviewTemplateQuestionPreviewModalProps> = (
  { reviewTemplateId, ...rest }
) => {

  /* State hooks */
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reviewTemplateQuestions, setReviewTemplateQuestions] =
    React.useState<IReviewTemplateQuestion[]>([]);

  /* App hook */
  const app = useApp();

  /* Metadata */
  const metadata = React.useContext(MetaDataContext) as IMetadata;


  /* Fetch review template questions */
  useEffect(
    () => {

      if (!reviewTemplateId) {
        setIsLoading(false);
        setReviewTemplateQuestions([]);
        return;
      }

      setIsLoading(true);
      const subscription = getPerformanceReviewTemplateById(app, reviewTemplateId, metadata)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setReviewTemplateQuestions(response.data.data.blocks);
          }
        );

      return () => subscription.unsubscribe();
    },
    [app, metadata, reviewTemplateId]
  );


  return (
    <CustomModal
      title='Questions Preview'
      size='lg'
      {...rest}
    >
      <Modal.Body style={{ height: isLoading || !reviewTemplateQuestions.length ? 100 : '' }}>
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!reviewTemplateQuestions.length}
          emptyStateMessage='No questions available to preview!'
        >
          <List
            items={reviewTemplateQuestions}
            renderItem={
              (question, index) =>
                <ReviewTemplateQuestionPreview
                  key={question.id}
                  index={index}
                  question={question} />
            }
          />
        </DataState>
      </Modal.Body>
    </CustomModal>
  );
};

export default LaunchReviewTemplateQuestionPreviewModal;