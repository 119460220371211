import React, { Dispatch, useState } from "react";
import AiToolsDescriptionBadge from "../AiToolDescriptionBadge/AiToolsDescriptionBadge";
import "./AiFocus.scss";
import HeaderText from "../../../../components/HeaderText/HeaderText";
import AttributesBadgesCard from "../AttributesBadgesCard/AttributesBadgesCard";
import {
  CompetencyList,
  CompetencyListProps,
  FeedbackData,
} from "modules/1-on-1s/types";
import GradientPrimaryButton from "modules/1-on-1s/components/GradiantPrimaryButton/GradientPrimaryButton";
import {
  addDevelopmentCompetency,
  addStrengthCompetency,
  aiCompetency,
  removeDevelopmentCompetency,
  removeStrengthCompetency,
} from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import { AxiosError } from "axios";

interface AiFocusProps {
  data?: FeedbackData | null;
  competencyList: CompetencyList[];
  setData: Dispatch<React.SetStateAction<FeedbackData | null>>;
  strengthCompetencyList: CompetencyListProps[];
  setStrengthCompetencyList: Dispatch<
    React.SetStateAction<CompetencyListProps[]>
  >;
  developmentCompetencyList: CompetencyListProps[];
  setDevelopmentCompetencyList: Dispatch<
    React.SetStateAction<CompetencyListProps[]>
  >;
  feedbackValue: string;
}

const AiFocus: React.FC<AiFocusProps> = ({
  competencyList,
  data,
  setData,
  strengthCompetencyList,
  setStrengthCompetencyList,
  developmentCompetencyList,
  setDevelopmentCompetencyList,
  feedbackValue,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  console.log(strengthCompetencyList);
  const handleSubmit = () => {
    if (!data?.id) return;
    setIsLoading(true);
    aiCompetency(data?.id, feedbackValue)
      .pipe(
        finalize(() => {
          setIsLoading(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response?.data?.feedback) {
            console.log(response);
            setStrengthCompetencyList(
              response?.data?.feedback?.strengthCompetency ?? []
            );
            setDevelopmentCompetencyList(
              response?.data?.feedback?.developmentAreaCompetency ?? []
            );
          }
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };

  const addStrengthCompetencyList = async (
    id: string,
    competencyId: string
  ) => {
    addStrengthCompetency(id, competencyId)
      .pipe()
      .subscribe({
        next: (response: any) => {
          setData({
            ...response?.data?.feedback,
          });
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };
  const removeStrengthCompetencyList = async (
    id: string,
    competencyId: string
  ) => {
    removeStrengthCompetency(id, competencyId)
      .pipe()
      .subscribe({
        next: (response: any) => {
          setData({
            ...response?.data?.feedback,
          });
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };
  const addDevelopmentCompetencyList = async (
    id: string,
    competencyId: string
  ) => {
    addDevelopmentCompetency(id, competencyId)
      .pipe()
      .subscribe({
        next: (response: any) => {
          setData({
            ...response?.data?.feedback,
          });
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };
  const removeDevelopmentCompetencyList = async (
    id: string,
    competencyId: string
  ) => {
    removeDevelopmentCompetency(id, competencyId)
      .pipe()
      .subscribe({
        next: (response: any) => {
          setData({
            ...response?.data?.feedback,
          });
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };

  return (
    <div className="AiFocus">
      <AiToolsDescriptionBadge
        label={"AI Tools"}
        value={"Focus"}
        desc={
          "Your feedback is too critical. Try focusing on the good sides as well for better impact."
        }
      />
      {/* <div className="label-field">
        <HeaderText
          text={"Taylor’s strengths and development areas"}
          fontSize={"16px"}
          lineHeight={"16px"}
        />
        <div className="subtext">
          Use this as a reference to your content, or select for AI to add into
          context
        </div>
      </div> */}
      <AttributesBadgesCard
        labels={"Strengths"}
        options={competencyList}
        selectedList={strengthCompetencyList}
        onClick={(value) => {
          console.log(value);
          if (value) {
            setStrengthCompetencyList((prev) => {
              if (prev.find((item) => item?.id === value?.id)) {
                data?.id && removeStrengthCompetencyList(data?.id, value.id);
                return prev.filter((item) => item?.id !== value.id);
              } else {
                data?.id && addStrengthCompetencyList(data?.id, value.id);
                return [...prev, { id: value?.id, tag: "human" }];
              }
            });
          }
        }}
      />
      <AttributesBadgesCard
        labels={"Development areas"}
        options={competencyList}
        selectedList={developmentCompetencyList}
        onClick={(value) => {
          if (value) {
            if (value) {
              setDevelopmentCompetencyList((prev) => {
                if (prev.find((item) => item?.id === value?.id)) {
                  data?.id &&
                    removeDevelopmentCompetencyList(data?.id, value.id);
                  return prev.filter((item) => item?.id !== value.id);
                } else {
                  data?.id && addDevelopmentCompetencyList(data?.id, value.id);
                  return [...prev, { id: value?.id, tag: "human" }];
                }
              });
            }
          }
        }}
      />
      <div className="btn-container">
        <GradientPrimaryButton
          text={"Generate AI Competencies"}
          disabled={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AiFocus;
