import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from '@themesberg/react-bootstrap';
import { DataState, RouteWithPermission } from 'components';
import { getReviewTodosData } from 'data';
import { IReviewTodosResponse, ReviewTodoState, ReviewTodoTypes } from 'interfaces';
import React from 'react';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';

import { getReviewTodoPathName } from '../utils/utils';
import RevieweeFormWidget from './RevieweeFormWidget';
import RevieweeSummaryWidget from './RevieweeSummaryWidget';
import RevieweeTodosWidget from './RevieweeTodosWidget';
import ReviewOwnerTodoWidget from './ReviewOwnerTodoWidget';
import ReviewTodoAwaitingNominationWidget from './ReviewTodoAwaitingNominationWidget';
import ReviewTodoManagerApproveNominationWidget from './ReviewTodoManagerApproveNominationWidget';
import ReviewTodoReviewerApproveNominationWidget from './ReviewTodoReviewerApproveNominationWidget';
import { useFrameContext } from 'contexts';

interface IReviewToDosWidgetProps { }

const ReviewToDosWidget: React.FC<IReviewToDosWidgetProps> = () => {


  const frameContext = useFrameContext();
  const isActiveFrame = frameContext?.frameState?.embed;

  // State Hooks
  const [isReviewListCollapsed, setIsReviewListCollapsed] = React.useState<boolean>(isActiveFrame ? true : false);
  const [isSummaryCollapsed, setIsSummaryCollapsed] = React.useState<boolean>(isActiveFrame ? true : false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reviewTodos, setReviewTodos] = React.useState<IReviewTodosResponse>();

  // Route Hooks
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {

    setIsLoading(true);
    setReviewTodos(undefined);

    const subscription = getReviewTodosData()
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {

          response.data.data.reviewList = response.data.data.reviewList
            .filter(
              (review) => !!review.reviews.length
            )
            .map(
              (review) => {

                review.reviews = review.reviews.map(
                  (todo) => {

                    todo.type = todo.type || ReviewTodoTypes.Default;

                    if (todo.type === ReviewTodoTypes.AwaitingNominations) {
                      todo.id = review.performanceReviewCampaignId;
                    };

                    return todo;
                  }
                );

                return review;
              }
            );

          setReviewTodos(response.data.data);

        }
      );

    return () => subscription?.unsubscribe();

  }, []);



  const handleReviewTodosStatusUpdate = (reviewId: string): void => {

    if (!reviewTodos) {
      return;
    }

    const updatedReviews = reviewTodos.reviewList.map(
      (review) => {

        const updatedReviewee = review.reviews.map(
          (reviewee) => {

            if (reviewee.id === reviewId) {
              return {
                ...reviewee,
                state: ReviewTodoState.Completed
              };
            }
            return reviewee;
          }
        );

        return {
          ...review,
          reviews: updatedReviewee
        };
      }
    );

    const newReviewTodos: IReviewTodosResponse = {
      total: reviewTodos.total,
      completed: reviewTodos.completed + 1,
      percentageComplete: (reviewTodos.completed + 1) / reviewTodos.total * 100,
      reviewList: updatedReviews
    };

    setReviewTodos(newReviewTodos);
  };


  const removeRemoveFromTodos = (reviewIdToRemove: string): void => {

    if (!reviewTodos) {
      return;
    }

    let todoIndex = -1;
    const reviewIndex = reviewTodos.reviewList.findIndex(
      (reviewToUpdate) => {

        const todoToRemoveIndex = reviewToUpdate.reviews.findIndex(
          (reviewee) => {

            if (reviewee.id === reviewIdToRemove) {
              return true;
            }

            return false;
          }
        );

        if (todoToRemoveIndex >= 0) {
          todoIndex = todoToRemoveIndex;
          return true;
        }

        return false;
      }
    );

    if (todoIndex < 0 || reviewIndex < 0) {
      return;
    }

    reviewTodos.reviewList[reviewIndex].reviews.splice(todoIndex, 1);
    let pathname;

    if (reviewTodos.reviewList[reviewIndex].reviews.length < 1) {
      reviewTodos.reviewList.splice(reviewIndex, 1);

      if (reviewTodos.reviewList.length) {
        pathname = getReviewTodoPathName(reviewTodos.reviewList[0].reviews[0]);
      } else {
        pathname = Routes.ReviewToDosPage.path;
      }

    } else {
      pathname = getReviewTodoPathName(reviewTodos.reviewList[reviewIndex].reviews[0]);
    }

    setReviewTodos(reviewTodos);
    history.replace({
      pathname,
      search: location.search
    });
  };


  return (
    <DataState
      isDataLoading={isLoading}
      isDataAvailable={!!reviewTodos?.reviewList.length}
      emptyStateMessage={<> No Review To Do's available! </>}
    >
      {
        reviewTodos?.reviewList.length
          ?
          <Card className='flex-grow-1 overflow-hidden'>
            <Card.Body className='d-flex overflow-hidden'>

              <div
                className='flex-shrink-0 width-transition position-relative'
                style={{ width: isReviewListCollapsed ? 0 : 320 }}
              >
                <RevieweeTodosWidget reviewTodos={reviewTodos} />
                <Button
                  variant='gray'
                  style={{ top: '1rem' }}
                  className='z-index-2 position-absolute end-0 translate-middle--x rounded-start-circle'
                  onClick={() => setIsReviewListCollapsed(!isReviewListCollapsed)}
                >
                  <FontAwesomeIcon icon={isReviewListCollapsed ? faChevronRight : faChevronLeft} />
                </Button>
              </div>

              <Switch>

                <RouteWithPermission {...Routes.AwaitingNominationToDo}>
                  <div className='flex-grow-1 overflow-hidden'>
                    <ReviewTodoAwaitingNominationWidget
                      onNominationSubmit={removeRemoveFromTodos} />
                  </div>
                </RouteWithPermission>

                <RouteWithPermission {...Routes.ManagerApproveNominationToDo}>
                  <div className='flex-grow-1 overflow-hidden'>
                    <ReviewTodoManagerApproveNominationWidget
                      onNominationSubmit={removeRemoveFromTodos} />
                  </div>
                </RouteWithPermission>

                <RouteWithPermission {...Routes.ReviewerApproveNominationToDo}>
                  <div className='flex-grow-1 overflow-hidden'>
                    <ReviewTodoReviewerApproveNominationWidget
                      onNominationSubmit={removeRemoveFromTodos} />
                  </div>
                </RouteWithPermission>

                <RouteWithPermission {...Routes.ReviewOwnerTodo}>
                  <div className='flex-grow-1 overflow-hidden'>
                    <ReviewOwnerTodoWidget />
                  </div>
                </RouteWithPermission>

                <RouteWithPermission
                  exact={Routes.SelectedReviewToDo.exact}
                  path={Routes.SelectedReviewToDo.path}
                  permission={Routes.SelectedReviewToDo.permission}
                >
                  <div className='flex-grow-1 overflow-hidden'>
                    <RevieweeFormWidget
                      onReviewDeclined={removeRemoveFromTodos}
                      onRevieweeFormSubmit={handleReviewTodosStatusUpdate} />
                  </div>

                  {
                    (
                      <div
                        className='flex-shrink-0 width-transition position-relative'
                        style={{ width: isSummaryCollapsed ? 0 : 420 }}
                      >
                        <RevieweeSummaryWidget />
                        <Button
                          variant='gray'
                          style={{ top: '1rem' }}
                          className='z-index-2 position-absolute start-0 translate-middle-x rounded-end-circle'
                          aria-controls="example-collapse-text"
                          aria-expanded={!isSummaryCollapsed}
                          onClick={() => setIsSummaryCollapsed(!isSummaryCollapsed)}
                        >
                          <FontAwesomeIcon icon={isSummaryCollapsed ? faChevronLeft : faChevronRight} />
                        </Button>
                      </div>
                    )
                  }
                </RouteWithPermission>

                <Redirect
                  to={{
                    ...location,
                    pathname: getReviewTodoPathName(reviewTodos.reviewList[0].reviews[0])
                  }}
                />

              </Switch>

            </Card.Body>
          </Card>
          :
          null
      }
    </DataState>
  );
};

export default ReviewToDosWidget;