import { AxiosError } from 'axios';
import { AxiosObservable } from 'axios-observable';
import { EmptyState, Preloader } from 'components';
import { MetaDataContext } from 'contexts';
import { getSurveyLibraryTemplateDetails, getSurveyTemplateDetailsById } from 'data';
import { useApp } from 'hooks';
import { IErrorResponse, IMetadata, IResponse, IRouteParams, ISurvey } from 'interfaces';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import SurveyBuilderWidget from './components/SurveyBuilderWidget';


const SurveyBuilder: React.FunctionComponent<{}> = () => {

  const [survey, setSurvey] = React.useState<ISurvey | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showEmptyState, setShowEmptyState] = React.useState<boolean>(false);

  const { surveyId, surveyTemplateId } = useParams<IRouteParams>();

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const history = useHistory();

  const app = useApp();


  React.useEffect(
    () => {

      if (surveyId || surveyTemplateId) {

        setIsLoading(true);
        setSurvey(null);

        let surveySource$: AxiosObservable<IResponse<ISurvey>> | null = null;

        if (surveyTemplateId) {
          surveySource$ = getSurveyTemplateDetailsById(surveyTemplateId, metadata, app);
        }

        if (surveyId) {
          surveySource$ = getSurveyLibraryTemplateDetails(surveyId, metadata, app);
        }

        if (!surveySource$) {
          return;
        }

        const subscription = surveySource$.pipe(
          finalize(() => setIsLoading(false))
        ).subscribe(
          {
            next: (response) => {
              if (response.data.data) {
                setSurvey(response.data.data);
                setShowEmptyState(false);
              } else {
                setShowEmptyState(true);
              }
            },
            error: (error: AxiosError<IErrorResponse>) => {
              if (error.response?.status === 403) {
                history.replace(Routes.SurveyTemplates.path);
              }
            }
          }
        );


        return () => {
          if (subscription) {
            subscription.unsubscribe();
          }
        }

      }
    },
    [app, history, metadata, surveyId, surveyTemplateId]
  );


  return (
    <>
      {
        isLoading ?
          (<Preloader show={isLoading} />) :
          (
            <>
              {
                showEmptyState || !survey ?
                  (
                    <EmptyState message={<>No Survey details available.</>} />
                  ) :
                  (
                    <SurveyBuilderWidget
                      survey={survey}
                      surveyTemplateId={surveyTemplateId}
                      surveyId={surveyId}
                      onSurveyChange={setSurvey} />
                  )
              }
            </>
          )
      }
    </>

  );
};

export default SurveyBuilder;
