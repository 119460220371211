import { Card } from '@themesberg/react-bootstrap';
import React from 'react';

import FeedbackLoopGiveFeedbackModalPreviewWidget from './FeedbackLoopGiveFeedbackModalPreviewWidget';
import FeedbackLoopNotificationPreviewWidget from './FeedbackLoopNotificationPreviewWidget';

interface IFeedbackLoopPreviewWidgetProps { }

const FeedbackLoopPreviewWidget: React.FC<IFeedbackLoopPreviewWidgetProps> = () => {

  return (
    <Card className='overflow-hidden h-100'>
      <Card.Header className='flex-shrink-0'>
        <Card.Title className='m-0'>
          Preview
        </Card.Title>
      </Card.Header>

      <Card.Body className='bg-light px-7 d-flex flex-grow-1 overflow-y-auto flex-column'>
        <FeedbackLoopNotificationPreviewWidget />
        <FeedbackLoopGiveFeedbackModalPreviewWidget />
      </Card.Body>

    </Card>
  );
};

export default FeedbackLoopPreviewWidget;