import { Card, Form } from '@themesberg/react-bootstrap';
import { CollapsibleCard, FormField } from 'components';
import { FormContext } from 'contexts';
import { updateFeedbackLoopNotificationMessage } from 'data';
import { useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  INotificationMessageConfigFormValues,
  IUpdateNotificationMessageRequestData,
} from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';

interface INotificationMessageConfigProps { }

const NotificationMessageConfig: React.FC<INotificationMessageConfigProps> = () => {

  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Feedback Loop config hook
  const { feedbackLoop, onFeedbackLoopUpdate } = useFeedbackLoopConfig();

  // Form hook
  const notificationMessageForm = useFormik<INotificationMessageConfigFormValues>({
    initialValues: {
      notificationMessage: feedbackLoop.notificationMessage || ''
    },
    onSubmit: () => { }
  });


  React.useEffect(
    () => {
      // Only make an API call if user has added/updated message and removed the focus from
      // the text field
      if (

        !notificationMessageForm.dirty ||

        !notificationMessageForm.touched.notificationMessage ||

        // If there is no notification message set before as well as
        // user also has't add any message in the form
        (
          !feedbackLoop.notificationMessage &&
          !notificationMessageForm.values.notificationMessage
        ) ||

        // If user has entered the message but it is same as the already set
        // message
        notificationMessageForm.values.notificationMessage ===
        feedbackLoop.notificationMessage
      ) {
        return;
      }

      const requestData: IUpdateNotificationMessageRequestData = {
        feedbackLibraryLoopId: feedbackLoop.id,
        notificationMessage: notificationMessageForm.values.notificationMessage.trim() || '',
      };

      setIsLoading(true);
      const subscription = updateFeedbackLoopNotificationMessage(requestData)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            onFeedbackLoopUpdate(response.data.data);
            notificationMessageForm.resetForm({
              values: { notificationMessage: notificationMessageForm.values.notificationMessage },
              touched: { notificationMessage: false }
            });
            toast.success(response.data.message);
          }
        );

      return () => subscription.unsubscribe();

    },
    [
      feedbackLoop.notificationMessage, feedbackLoop.id, feedbackLoop.workspaceId,
      notificationMessageForm.values.notificationMessage, onFeedbackLoopUpdate,
      notificationMessageForm.dirty, notificationMessageForm.touched.notificationMessage
    ]
  );


  return (
    <CollapsibleCard
      title='Notification Message'
      isLoading={isLoading}
    >

      <Card.Body>
        <Form>
          <FormContext.Provider value={notificationMessageForm}>

            <FormField
              label='Notification Message'
              controlId={`notificationMessageConfigField`}
              type={FormFieldTypeConstants.Text}
              controlName='notificationMessage'
              placeholder='Enter custom notification message...'
              textValueChangeDebounceTime={350}
            />

          </FormContext.Provider>
        </Form>
      </Card.Body>

    </CollapsibleCard>
  );
};

export default NotificationMessageConfig;