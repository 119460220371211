import React from "react";
import "./AiToolSelector.scss";
import NextArrowIcon from "../SvgReactIcons/NextArrowIcon";

interface AiToolSelectorProps {
  label: string;
  value: string;
  selected: boolean;
  onClick?: () => void; // Callback function when clicked on the AiToolSelector component.
}

const AiToolSelector: React.FC<AiToolSelectorProps> = ({
  label,
  value,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={() => {
        onClick?.();
      }}
      className={`AiToolSelector ${selected ? "AiToolSelector-selected" : ""}`}
    >
      <div className="text">
        <div className="zone" />
        {label}
      </div>
      <div className="score">
        {value}
        <div className="next-icon">
          <NextArrowIcon height={14} width={50} />
        </div>
      </div>
    </div>
  );
};

export default AiToolSelector;
