import {
  FeedbackTemplateTypes,
  IFeedback,
  IFeedbackMaps,
  IMenuOption,
  IMultiSelectQuestionOptionsTypeConstants
} from 'interfaces';

import { getMultiSelectAnswerLabel, getMultiSelectAnswerValue } from './answer.utils';


export const getFeedbackTypeText = (
  feedbackType: string,
  feedbackTypeHeader: string
): string => {

  return feedbackType === 'manager_feedback'
    ?
    feedbackTypeHeader || '🧑‍💼 Manager Feedback'
    :
    feedbackType === 'note'
      ?
      '🗒️ Note'
      :
      feedbackType === 'praise'
        ?
        '🙌 Public Praise'
        :
        '💡 Feedback to Employee';
};


export const getFeedbackSenderText = (
  feedback: IFeedback
): string => {

  return feedback.anonymous
    ?
    '👤 Anonymous'
    :
    feedback.sender.name;
};


export const getFeedbackCC = (
  feedback: IFeedback
): string | undefined => {

  if (
    !feedback.receiverSlackUsers?.length &&
    !feedback.customFeedbackReceivers?.length &&
    !feedback.receiverUsers?.length
  ) {
    return;
  }

  if (feedback.receiverSlackUsers?.length) {
    return feedback.receiverSlackUsers
      .map(
        (slackUser) => slackUser.realName
      )
      .join(', ');
  }

  if (feedback.customFeedbackReceivers?.length) {
    return feedback.customFeedbackReceivers
      .map(
        (slackUser) => slackUser.name
      )
      .join(', ');
  }

  if (feedback.receiverUsers?.length) {
    return feedback.receiverUsers.join(', ');
  }
};


export const getFeedbackTagsText = (
  feedback: IFeedback,
  feedbackMaps: IFeedbackMaps | undefined,
): string | undefined => {

  if (!feedback.dimensionInfos?.length) {
    return;
  }

  return feedback.dimensionInfos
    .map(
      (dimensionInfo) => {

        const dimensionName = feedbackMaps?.dimensionsMap?.[dimensionInfo.id]?.name;
        return dimensionName || '';
      }
    )
    .join(', ');
};


const getFeedbackAnswerBlocksPreviewText = (
  feedback: IFeedback,
  multiQuestionTypeOptions: IMenuOption[],
  multiQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants
): string | undefined => {

  if (feedback.blocks?.length) {

    const answers = feedback.blocks
      .filter(
        (block) => !['title', 'sub-title'].includes(block.type || '')
      )
      .slice(0, 2);

    if (!answers.length) {
      return;
    }

    return answers.reduce(
      (previousAnswer, answer) => {

        switch (answer.type) {

          case 'short-text':
          case 'long-text': {
            return `${previousAnswer} ${answer.label}: ${answer.value || 'Not answered'}, `;
          }

          case 'choice':
          case 'competency-question': {
            const answerLabel = getMultiSelectAnswerLabel(answer);
            const answerValue = getMultiSelectAnswerValue(
              answer, multiQuestionTypeOptions, multiQuestionTypeConstants
            );
            return `${previousAnswer} ${answerLabel}: ${answerValue || 'Not answered'}, `;
          }
        }

        return '';
      },
      ''
    );

  }
};


const getSbiTemplatePreviewText = (
  feedback: IFeedback,
): string => {

  let feedbackPreviewText = '';

  if (feedback.situation_text) {
    feedbackPreviewText = feedbackPreviewText + '📍 Situation: ' + feedback.situation_text + ', '
  };

  if (feedback.behavior_text) {
    feedbackPreviewText = feedbackPreviewText + '▶️ Behavior: ' + feedback.behavior_text + ', '
  };

  if (feedback.impact_text) {
    feedbackPreviewText = feedbackPreviewText + '💡 Impact: ' + feedback.impact_text + ', '
  };

  if (feedback.sbi_nextStep_text) {
    feedbackPreviewText = feedbackPreviewText + '🔦 Next Steps: ' + feedback.sbi_nextStep_text + ', '
  };

  return feedbackPreviewText;
};


const getSadTemplatePreviewText = (
  feedback: IFeedback
): string => {

  let feedbackPreviewText = '';

  if (feedback.strength_text) {
    feedbackPreviewText = feedbackPreviewText + '💪 Strengths: ' + feedback.strength_text + ', ';
  }

  if (feedback.developmentArea_text) {
    feedbackPreviewText = feedbackPreviewText + '🎯 Development Areas: ' + feedback.developmentArea_text + ', ';
  }

  if (feedback.sd_nextStep_text) {
    feedbackPreviewText = feedbackPreviewText + '🔦 Next Steps: :' + feedback.sd_nextStep_text + ', ';
  }

  return feedbackPreviewText;
};


export const getFeedbackPreviewText = (
  feedback: IFeedback,
  multiQuestionTypeOptions: IMenuOption[],
  multiQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants
): string | undefined => {

  if (feedback.blocks?.length) {
    return getFeedbackAnswerBlocksPreviewText(
      feedback,
      multiQuestionTypeOptions,
      multiQuestionTypeConstants,
    );
  }

  switch (feedback.template_type) {

    case FeedbackTemplateTypes.SBI: {
      return getSbiTemplatePreviewText(feedback);
    }

    case FeedbackTemplateTypes.SaD: {
      return getSadTemplatePreviewText(feedback);
    }

    case FeedbackTemplateTypes.FreeText:
    default: {
      return feedback.feedback_text;
    }
  }
};