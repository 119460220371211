import Axios, { AxiosObservable } from "axios-observable";
import { GroupContext, IEditGroupRequestData, IGroup, IHookResponse, IListReducer, IListReducerAction, IResponse } from "interfaces";
import React, { useEffect } from "react";
import { listReducer } from "reducers";
import { finalize } from "rxjs";

export const useGroupsListData = (
  context: GroupContext
): IHookResponse<IGroup[], IListReducerAction<IGroup>> => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [refetchCount, setRefetchCount] = React.useState<number>(0);
  const [groupsList, dispatchGroupList] =
    React.useReducer<IListReducer<IGroup>>(listReducer, []);


  useEffect(
    () => {

      dispatchGroupList({
        type: 'SET',
        payload: []
      });
      setIsLoading(true);

      let url;

      if (context === GroupContext.MyGroups) {
        url = '/list-my-groups';
      } else if (context === GroupContext.OrganizationGroups) {
        url = '/list-teamble-team';
      } else if (GroupContext.MyTeams) {
        url = '/list-custom-relationships-with-users';
      }

      if (!url) {
        return;
      }

      const subscription = Axios.post<IResponse<IGroup[]>>(url)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            dispatchGroupList({
              type: 'SET',
              payload: response.data.data
            });
          }
        );

      return () => subscription.unsubscribe();
    },
    [context, refetchCount]
  );


  return {
    isLoading,
    data: groupsList,
    refetchData: () => setRefetchCount((refetchCount) => refetchCount + 1)
  };
};


export const useGroupsDetailsData = (id: string | undefined, context: GroupContext): IHookResponse<IGroup> => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [groupDetails, setGroupDetails] = React.useState<IGroup>();


  useEffect(
    () => {

      setGroupDetails(undefined);

      if (!id) {
        return;
      }

      setIsLoading(true);

      const subscription = Axios.post<IResponse<IGroup>>(
        context === GroupContext.MyTeams ? '/list-custom-relationship-with-users' : '/detail-teamble-team',
        context === GroupContext.MyTeams ? { relationshipId: id } : { teambleTeamId: id }
      )
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {

            response.data.data.members = response.data.data.members
              .map(
                (member) => ({
                  ...member,
                  title: {
                    id: '',
                    title: member.isTeamAdmin ? 'Admin' : ''
                  }
                })
              )
              .sort((a, b) => {
                if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) return -1;
                if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) return 1;
                return 0;
              });
            setGroupDetails(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [id]
  );


  return {
    isLoading,
    data: groupDetails
  };
};


export const editGroupData = (
  groupData: IEditGroupRequestData
): AxiosObservable<IResponse<IGroup>> => {

  return Axios.post<IResponse<IGroup>>(
    '/update-teamble-team',
    groupData
  );
};