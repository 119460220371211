
import { Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormFieldTypeConstants, IKPIWidgetBody } from 'interfaces';
import React from 'react';


function getValues(value: undefined | string = "") {
  return value || "Not Set" // "⚠️ Not Set"
}

export const KPIWidgetBody: React.FC<IKPIWidgetBody> = ({
  kpis,
  index,
  docType,
  readonly = true,
}) => {

  if (docType === 'Balance Sheet') {

    return (
      <Card.Body>
        {
          kpis.map((kpi, kpiIndex) => {
            return (
              <Card key={kpi.id} border="grey" className="p-2 mb-2">

                <Row className="d-flex justify-content-between align-items-center">
                  <Col>KPI Name: {getValues(kpi.name)} </Col>
                  <Col>Period: {getValues(kpi.period)} </Col>
                </Row>

                <Row className='d-flex justify-content-between align-items-center mt-2'>
                  <Col className='ws-pre-line'>Description: {getValues(kpi.description)} </Col>
                </Row>
              </Card>
            )
          })
        }
      </Card.Body>
    );

  }

  // Income Statment
  return (
    <Card.Body>
      {
        kpis.map((kpi, kpiIndex) => {
          return (
            <Card key={kpi.id} border="grey" className="p-2 mb-2">
              <Row className='d-flex justify-content-between align-items-center'>
                <Col>KPI Name: {getValues(kpi.name)} </Col>
                <Col>Sub Role: {getValues(kpi.subRole)} </Col>
                <Col>Client: {getValues(kpi.client)} </Col>
                <Col>Industry: {getValues(kpi.industry)} </Col>
              </Row>

              <Row className='d-flex justify-content-between align-items-center mt-2'>
                <Col className='ws-pre-line'>Description: {getValues(kpi.description)} </Col>
              </Row>

              <Row className='d-flex justify-content-between align-items-center mt-2'>
                <Col>Period: {getValues(kpi.period)} </Col>
                <Col>Target: {getValues(kpi.target)} </Col>
                {
                  readonly ? (
                    <Col>Actual: {getValues(kpi.actual)} </Col>
                  ) : (
                    <Col>
                      <div className='d-flex align-items-center'>
                        <Form.Label style={{ marginRight: "5px" }}> Actual: </Form.Label>
                        <FormField
                          minValue={0}
                          placeholder='Actual'
                          type={FormFieldTypeConstants.Number}
                          controlId={`multiSelectQuestionKPIActual-${index}-${kpiIndex}`}
                          controlName={`answers[${index}].kpis[${kpiIndex}].actual`}
                          textValueChangeDebounceTime={500}
                        />
                      </div>
                    </Col>
                  )
                }
                <Col></Col>
              </Row>

            </Card>
          )
        })
      }
    </Card.Body>
  )
};