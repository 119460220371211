import { FormikProps } from 'formik';

import { IFeedbackLoopFormValues } from './feedbackLoop.interface';
import { ISimpleMenuOption } from './menuOptions.interface';
import { IQuestionActionTypes } from './question.interface';

export interface IQuestionBuilderContext<T> {
  isTemplate: boolean;
  builderForm: FormikProps<T>;
  previewQuestionKey: number | null;
  onQuestionAdd: (option: ISimpleMenuOption) => void;
  onPreviewQuestionKeyChange: React.Dispatch<React.SetStateAction<number | null>>;
  onQuestionActionClick: (
    actionType: IQuestionActionTypes,
    index: number,
  ) => void;
  onCreateTemplateClick: () => void;
  onNextClick: () => void;
}

export type IQuestionBuilderFormType = IFeedbackLoopFormValues;

export enum IQuestionBuilderFormStatus {
  Initial = 'initial',
  Saving = 'saving',
  Redirecting = 'redirecting'
}