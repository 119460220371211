import { FormErrors } from 'interfaces';
import moment from 'moment';
import * as Yup from 'yup';

export const START_DATE_TIME_VALIDATION_SCHEMA = Yup.date()
  .test(
    'minDateToday', FormErrors.MinDateToday, (value: Date | undefined | null) => {

      if (!value) {
        return true;
      }

      if (moment(value).isSameOrAfter(moment())) {
        return true;
      }

      return false;

    }
  );

  export const ONE_ON_ONE_START_DATE_TIME_VALIDATION_SCHEMA  = Yup.date()
  .required('This field is required') 
  .test(
    'minDate48Hours',
    'The date must be at least 48 hours from now',
    (value: Date | undefined | null) => {
      if (!value) {
        return false; 
      }

      // Check if the value is at least 48 hours in the future
      if (moment(value).isSameOrAfter(moment().add(48, 'hours'))) {
        return true;
      }

      return false;
    }
  );


export const END_DATE_TIME_VALIDATION_SCHEMA = Yup.date()
  .test(
    {
      name: 'minEndDate',
      test: (value: Date | undefined | null, context) => {

        if (!value) {
          return true;
        }

        if (moment(value).isBefore(moment())) {
          return context.createError({ message: FormErrors.MinDateToday });
        }

        if (moment(value).isSameOrBefore(moment(context.parent.startDate))) {
          return context.createError({ message: FormErrors.MinEndDate });
        }

        return true;

      }
    }
  );