import { ChartDataset } from 'chart.js';
import { ICompetencyPerformanceScore } from 'interfaces';
import { COMPETENCY_COLORS } from 'teamble-constants';


export const getCompetencyColorMap = (
  compPerformanceScores: ICompetencyPerformanceScore[]
): Map<string, string> => {

  const competencyColorMap = new Map<string, string>();
  let index = 0;

  compPerformanceScores.forEach(
    (review) => {
      review.competencies.forEach(
        (competency) => {

          if (competencyColorMap.has(competency.name)) {
            return;
          }

          competencyColorMap.set(
            competency.name,
            COMPETENCY_COLORS[index % COMPETENCY_COLORS.length]
          );
          index++;

        }
      );
    }
  );

  return competencyColorMap;
};


export const getCompetencyLegends =
(compPerformanceScores: ICompetencyPerformanceScore[]): ICompetencyPerformanceScore['competencies'] => {

  const competencyColorMap = getCompetencyColorMap(compPerformanceScores);

  const competencies: ICompetencyPerformanceScore['competencies'] = [];
  Array.from(
    competencyColorMap,
    ([name, color]) => ({ name, color, value: 0 })
  ).forEach(
    (competency) => {
      competencies.push(competency);
    }
  );

  return competencies;
};


export const getCompetencyPerformanceScoreChartDatasets =
(compPerformanceScores: ICompetencyPerformanceScore[]): ChartDataset<'line'>[] => {

  const competencyColorMap = getCompetencyColorMap(compPerformanceScores);

  const datasetsMap = new Map<string, { x: number, y: number }[]>();

  compPerformanceScores.forEach(
    (compScore) => {

      compScore.competencies.forEach(
        (comp) => {

          let existingData: { x: number, y: number }[] = [];
          if (datasetsMap.has(comp.name)) {
            existingData = datasetsMap.get(comp.name) || [];
          }

          datasetsMap.set(
            comp.name,
            [
              ...existingData,
              {
                x: compScore.createdAt,
                y: comp.value
              }
            ]
          );

        }
      )

    }
  );
  const datasets = Array.from(
    datasetsMap,
    ([name, data]) => ({ name, data })
  ).map(
    (dataset) => {

      const color = competencyColorMap.get(dataset.name);
      return {
        data: dataset.data,
        backgroundColor: color,
        borderColor: color
      };

    }
  );

  return datasets;

};