import { ISurvey } from 'interfaces';
import * as React from 'react';
import { getSurveyAudienceDescription } from 'utils';

export const useSurveyAudience = (surveyDetails: ISurvey | null): string => {

  const [audience, setAudience] = React.useState<string>('');

  React.useEffect(() => {

    setAudience(
      getSurveyAudienceDescription(surveyDetails?.audience)
    );
  }, [surveyDetails]);

  return audience;
}