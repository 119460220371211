import * as React from "react";

const FilterDropdownIcon = ({
  width = 12,
  height = 12,
  color = "#C2C3C5",
  viewBox = "0 0 12 12",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    {...props}
  >
    <path
      stroke={color}
      strokeLinejoin="round"
      d="M9.5 7.125L6 10.5m0 0L2.5 7.125M6 10.5v-9"
    />
  </svg>
);

export default FilterDropdownIcon;
