import React, { useEffect, useState } from "react";
import "./OneOnOnePrepareFeedback.scss";
import HeaderText from "../../components/HeaderText/HeaderText";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import TertiaryButton from "../../components/TertiaryButton/TertiaryButton";
import TeambleAiIcon from "../../components/SvgReactIcons/TeambleAiIcon";
import { aiOneOnOneTools } from "../../constants";
import AiToolSelector from "../../components/AiToolSelector/AiToolSelector";
import CustomTextArea from "../../components/CustomTextArea/CustomTextArea";
import TextGradientSecondaryBtn from "../../components/TextGradientSecondarybtn/TextGradientSecondaryBtn";
import Specificity from "./components/Specificity/Specificity";
import AiTone from "./components/AiTone/AiTone";
import Actionability from "./components/Actionability/Actionability";
import AiFocus from "./components/AiFocus/AiFocus";
import IconButton from "../../components/IconButton/IconButton";
import BackButtonArrowIcon from "../../components/SvgReactIcons/BackButtonArrowIcon";
import { useHistory } from "react-router";
import ImageCard from "../../components/ImageCard/ImageCard";
import { finalize } from "rxjs";
import {
  getCompetencyList,
  getTeambleAiOneOnOneFeedback,
  refreshingAiScoreApi,
  submitAiFeedback,
  textModificationAgent,
} from "data/teamble-ai-one-on-one";
import { AxiosError } from "axios";
import {
  AiOneOnOneTools,
  CompetencyList,
  CompetencyListProps,
  ContextModalPosition,
  FeedbackData,
} from "modules/1-on-1s/types";
import ContextSelectionModal from "./components/ContextSelectionModal/ContextSelectionModal";
import { useParams } from "react-router-dom";

interface PrepareFeedbackParams {
  prepareAiFeedbackId: string;
}

const OneOnOnePrepareFeedback = () => {
  const { prepareAiFeedbackId } = useParams<PrepareFeedbackParams>();
  const [data, setData] = useState<FeedbackData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAITool, setSelectedAiTool] = useState<AiOneOnOneTools>(
    AiOneOnOneTools?.Specificity
  );
  const [feedbackSituation, setFeedbackSituation] = useState("");
  const [feedbackBehavior, setFeedbackBehavior] = useState("");
  const [feedbackImpact, setFeedbackImpact] = useState("");
  const [feedbackValue, setFeedbackValue] = useState("");
  const [actionableSteps, setActionableSteps] = useState("");
  const [openingStatement, setOpeningStatement] = useState("");
  const [selectedBannerImage, setSelectedBannerImage] = useState<any[]>([]);
  const [selectedText, setSelectedText] = useState("");
  const [competencyList, setCompetencyList] = useState<CompetencyList[]>([]);
  const [strengthCompetencyList, setStrengthCompetencyList] = useState<
    CompetencyListProps[]
  >([]);
  const [developmentCompetencyList, setDevelopmentCompetencyList] = useState<
    CompetencyListProps[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshingAiScore, setRefreshingAiScore] = useState(false);
  const [modalPosition, setModalPosition] = useState<ContextModalPosition>({
    top: 0,
    left: 0,
    visible: false,
  });
  const [isModifyingText, setIsModifyingText] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    if (!data?.id) return;
    setIsSubmitting(true);
    submitAiFeedback(data.id)
      .pipe(
        finalize(() => {
          setIsSubmitting(false);
        })
      )
      .subscribe({
        next: () => {
          history.push("/one-on-ones");
        },
        error: (error) => {
          console.log(error);
        },
      });
  };

  useEffect(() => {
    getTeambleAiOneOnOneFeedback(prepareAiFeedbackId)
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe({
        next: (response: any) => {
          setData({
            ...response?.data?.aiFeedback,
          });
          setOpeningStatement(
            response?.data?.aiFeedback?.openingStatement ?? ""
          );
          setFeedbackValue(response?.data?.aiFeedback?.userFeedback ?? "");
          setFeedbackSituation(response?.data?.aiFeedback?.situation ?? "");
          setFeedbackBehavior(response?.data?.aiFeedback?.impact ?? "");
          setFeedbackImpact(response?.data?.aiFeedback?.behavior ?? "");
          setIsLoading(false);
          setStrengthCompetencyList(
            response?.data?.aiFeedback?.strengthCompetency ?? []
          );
          setDevelopmentCompetencyList(
            response?.data?.aiFeedback?.developmentAreaCompetency ?? []
          );
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepareAiFeedbackId]);

  useEffect(() => {
    setFeedbackValue(data?.userFeedback ?? "");
  }, [data]);

  useEffect(() => {
    getCompetencyList()
      .pipe()
      .subscribe({
        next: (response: any) => {
          setCompetencyList(response?.data?.competencyList ?? []);
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  }, []);

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const selection = window.getSelection();
    const text = selection ? selection.toString() : "";
    if (text.trim()) {
      const viewPortWidth = window.innerWidth;
      const cardWidth = Math.min(viewPortWidth, 426);
      let left = 0;
      if (event.clientX + cardWidth > viewPortWidth) {
        left = event.clientX - cardWidth;
      } else {
        left = event.clientX;
      }
      setSelectedText(text);
      setModalPosition({
        top: event.clientY + 10,
        left: left,
        visible: true,
      });
    } else {
      setModalPosition({ ...modalPosition, visible: false });
    }
  };
  const closeModal = () => {
    setModalPosition({ ...modalPosition, visible: false });
    setSelectedText("");
  };

  const getCurrentActiveToolsSection = () => {
    switch (selectedAITool) {
      case aiOneOnOneTools?.specificity:
        return (
          <Specificity
            data={data}
            setData={setData}
            feedbackValue={feedbackValue}
            feedbackSituation={feedbackSituation}
            setFeedbackSituation={setFeedbackSituation}
            feedbackBehavior={feedbackBehavior}
            setFeedbackBehavior={setFeedbackBehavior}
            feedbackImpact={feedbackImpact}
            setFeedbackImpact={setFeedbackImpact}
            actionableSteps={actionableSteps}
            setOpeningStatement={setOpeningStatement}
          />
        );
      case aiOneOnOneTools?.tone:
        return (
          <AiTone
            data={data}
            setData={setData}
            feedbackValue={feedbackValue}
            openingStateMent={openingStatement}
            setOpeningStateMent={setOpeningStatement}
          />
        );
      case aiOneOnOneTools?.focus:
        return (
          <AiFocus
            data={data}
            competencyList={competencyList}
            strengthCompetencyList={strengthCompetencyList}
            setStrengthCompetencyList={setStrengthCompetencyList}
            developmentCompetencyList={developmentCompetencyList}
            setDevelopmentCompetencyList={setDevelopmentCompetencyList}
            feedbackValue={feedbackValue}
            setData={setData}
          />
        );
      case aiOneOnOneTools?.actionability:
        return (
          <Actionability
            data={data}
            setData={setData}
            actionableSteps={actionableSteps}
            setActionableSteps={setActionableSteps}
            selectedBannerImage={selectedBannerImage}
            setSelectedBannerImage={setSelectedBannerImage}
            feedbackValue={feedbackValue}
          />
        );
      default:
        return null;
    }
  };

  const handleRefreshAiScore = (feedbackValue: string) => {
    if (!data?.id || !feedbackValue) return;
    setRefreshingAiScore(true);
    refreshingAiScoreApi(data.id, feedbackValue)
      .pipe(
        finalize(() => {
          setRefreshingAiScore(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          setData(response?.data?.feedback);
          console.log(response);
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };

  const handleFeedbackValueGenerate = (modificationInstruction: string) => {
    if (!data?.id) return;
    setIsModifyingText(true);
    textModificationAgent(
      data.id,
      feedbackValue,
      selectedText,
      modificationInstruction
    )
      .pipe(
        finalize(() => {
          setIsModifyingText(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          setModalPosition({ ...modalPosition, visible: false });
          setData({ ...response?.data?.feedback });
          response?.data?.feedback?.userFeedback &&
            handleRefreshAiScore(response?.data?.feedback?.userFeedback);
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };

  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <div className="OneOnOnePrepareFeedback">
      <div className="Navbar">
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="header-container"
        >
          <IconButton Icon={BackButtonArrowIcon} />
          <HeaderText
            text={"Prepare feedback for upcoming 1-on-1"}
            fontSize={"30px"}
            lineHeight={"30px"}
            letterSpacing={"-0.3px"}
          />
        </div>
        <div className="btn-container">
          <TertiaryButton
            text={"Cancel"}
            onClick={() => {
              history.goBack();
            }}
          />
          <PrimaryButton
            text={"Submit"}
            onClick={handleClick}
            isLoading={isSubmitting}
            loadingText="Submitting..."
          />
        </div>
      </div>
      <div className="ai-container">
        <div className="ai-sideBar">
          <div className="ai-score">
            <div className="ai-score-card">
              <div className="icon">
                <TeambleAiIcon width={24} height={24} />
              </div>
              <div className="info">
                <div className="header">
                  <HeaderText
                    text={"Teamble AI score"}
                    fontSize={"16px"}
                    lineHeight={"16px"}
                  />
                  <div className="score">
                    <div className="numerator">
                      {data && data?.aiScore >= 0
                        ? Math.floor(data?.aiScore ?? 0)
                        : 0}
                    </div>
                    <div className="denominator">/100</div>
                  </div>
                </div>
                <div className="ai-info">
                  Your current feedback fails to address several components of a
                  best in class feedback from specificity to tone,
                  actionability, etc. Please follow the guidelines below to
                  improve the feedback, AI will guide you through it.
                </div>
              </div>
            </div>
            <div className="ai-sections">
              <AiToolSelector
                label={"Specificity"}
                value={`${
                  data?.feedbackSpecificity?.feedbackSpecificityScore ?? 0
                }%`}
                selected={selectedAITool === aiOneOnOneTools?.specificity}
                onClick={() => {
                  setSelectedAiTool(AiOneOnOneTools?.Specificity);
                }}
              />
              <AiToolSelector
                label={"Tone"}
                value={(data?.feedbackTone?.toneCategory ?? "") as string}
                selected={selectedAITool === aiOneOnOneTools?.tone}
                onClick={() => {
                  setSelectedAiTool(AiOneOnOneTools?.Tone);
                }}
              />
              <AiToolSelector
                label={"Focus"}
                value={
                  (data?.feedbackFocusCategory?.feedbackFocusCategory ??
                    "") as string
                }
                selected={selectedAITool === aiOneOnOneTools?.focus}
                onClick={() => {
                  setSelectedAiTool(AiOneOnOneTools?.Focus);
                }}
              />
              <AiToolSelector
                label={"Actionability"}
                value={`${
                  data?.feedbackActionability?.feedbackActionableScore ?? 0
                }%`}
                selected={selectedAITool === aiOneOnOneTools?.actionability}
                onClick={() => {
                  setSelectedAiTool(AiOneOnOneTools?.Actionability);
                }}
              />
            </div>
          </div>
          <div className="refresh-score">
            <TextGradientSecondaryBtn
              text={"Refresh AI score"}
              onClick={() => {
                handleRefreshAiScore(feedbackValue);
              }}
              isLoading={refreshingAiScore}
              loadingText={"Refreshing Ai Score..."}
            />
          </div>
        </div>
        <div className="prepare-feedback-container">
          <div className="sidebar-feedback">
            <div className="your-feedback">
              <HeaderText
                text={"Your feedback"}
                fontSize={"16px"}
                lineHeight={"16px"}
              />
              <div className="text-container">
                <div
                  onContextMenu={handleContextMenu}
                  className="custom-text-container"
                >
                  <CustomTextArea
                    value={feedbackValue}
                    setValue={setFeedbackValue}
                  />
                  <ContextSelectionModal
                    modalPosition={modalPosition}
                    closeModal={closeModal}
                    onSubmit={handleFeedbackValueGenerate}
                    setIsModifyingText={setIsModifyingText}
                    isModifyingText={isModifyingText}
                  />
                </div>
                <div className="image-card-container">
                  {selectedBannerImage.map((item, index) => (
                    <ImageCard data={item} isBanner={true} key={index} />
                  ))}
                </div>
              </div>
            </div>
            {getCurrentActiveToolsSection()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneOnOnePrepareFeedback;
