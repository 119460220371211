import { useEffect } from "react";

type UseOutsideClick = <T extends HTMLElement>(
  callback: () => void,
  ref: React.RefObject<T>
) => void;

const useOutsideClick: UseOutsideClick = (callback, ref) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ref]);
};

export default useOutsideClick;
