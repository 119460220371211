import { Card, Dropdown, Form } from '@themesberg/react-bootstrap';
import {
  CustomSelect,
  EmptyState,
  MultiChoiceAnswerPreview,
  PageTitle,
  Preloader,
  PrimaryLayoutBox,
  TextAnswerPreview,
} from 'components';
import { MetaDataContext } from 'contexts';
import { downloadSurveyReports, getSurveyDetails, getSurveyResult, getSurveysAsMenuOptions } from 'data';
import downloadjs from 'downloadjs';
import { useApp, useOptionsAndSelectedOption, useOrganizationMenuOptions$ } from 'hooks';
import {
  FileFormats,
  IAggregation,
  IChannelMenuOption,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  ISurveyMenuOption,
  ISurveyResponse,
  ISurveyResult,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import { StringParam, useQueryParams } from 'use-query-params';

import Logo from './../../assets/img/brand/logo.png';


interface ISurveySurveySelectProps {
  workspaceOrChannelId: string;
}

interface ISurveyReportsWidgetProps {
  surveyId: string;
}

interface ISurveyResultAggregationsProps {
  isAnonymous: boolean;
  singleBarViewEnabled: boolean;
  aggregations: IAggregation[];
}

const SurveyResultAggregations: React.FC<ISurveyResultAggregationsProps> = (
  { aggregations, isAnonymous, singleBarViewEnabled }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;


  return (
    <>
      {
        aggregations.map(
          (aggregation) => {

            switch (aggregation.type) {

              case 'text-input': {
                return (
                  <Card key={aggregation.id}>
                    <Card.Body className='p-6'>
                      <TextAnswerPreview
                        aggregation={aggregation}
                        isAnonymous={isAnonymous} />
                    </Card.Body>
                  </Card>
                );
              }

              case 'choice': {
                return (
                  <Card key={aggregation.id}>
                    <Card.Body className='p-6'>
                      <MultiChoiceAnswerPreview
                        aggregation={aggregation}
                        isAnonymous={isAnonymous}
                        singleBarViewEnabled={singleBarViewEnabled}
                        isNpsOrLikelyUnlikely={
                          [
                            multiSelectQuestionTypeConstants.NPS,
                            multiSelectQuestionTypeConstants.LIKELY_UNLIKELY
                          ].includes(aggregation.block.family || '')
                        } />
                    </Card.Body>
                  </Card>
                );
              }

              default: {
                return null;
              }

            }

          }
        )
      }
    </>
  );

};


const SurveyReportsWidget: React.FunctionComponent<ISurveyReportsWidgetProps> = (
  { surveyId }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [singleBarViewEnabled, setSingleBarViewEnabled] = React.useState<boolean>(false);

  const [surveyResult, setSurveyResult] =
    React.useState<ISurveyResult | null>(null);


  React.useEffect(
    () => {

      setIsLoading(true);
      setSurveyResult(null);

      getSurveyResult(surveyId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setSurveyResult(response.data.data);
          }
        );

    },
    [surveyId]
  );


  return (
    <div className='position-relative flex-grow-1 overflow-hidden'>
      {
        isLoading ?
          (<Preloader show={isLoading} />) :
          (
            (surveyResult && surveyResult.aggregations?.length > 0) ?
              (
                <div className='d-flex flex-column h-100'>
                  <Card className='flex-shrink-0'>
                    <Card.Header className='d-flex justify-content-between align-items-center'>
                      <div className='d-flex'>
                        <img className='rounded' width='70' height='70' src={Logo} alt='Teamble Logo' />
                        <div className='ms-3'>
                          <h3 className='fw-bolder'> {surveyResult.title} </h3>
                          <p className='m-0'>
                            <span> <strong> Respondents: </strong> {surveyResult.participantCount} </span>
                            <span className='ms-3'> <strong> Status: </strong> {surveyResult.state === 'open' ? 'Live' : 'Closed'} </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <Form.Check
                          type="switch"
                          id="surveyReportBarSwitch"
                          label="Single Bar view"
                          checked={singleBarViewEnabled}
                          onChange={() => setSingleBarViewEnabled(!singleBarViewEnabled)}
                        />
                        <Form.Text id="surveyReportBarSwitchHelpBlock" muted>
                          Only applies to multi-choice questions.
                        </Form.Text>
                      </div>
                    </Card.Header>
                  </Card>
                  <div className='flex-grow-1 overflow-y-auto'>
                    <SurveyResultAggregations
                      singleBarViewEnabled={singleBarViewEnabled}
                      aggregations={surveyResult.aggregations}
                      isAnonymous={surveyResult.anonymity.type === 'anonymous'} />
                  </div>
                </div>
              ) :
              (
                <EmptyState message={<> No result available for the selected survey. </>} />
              )
          )
      }
    </div>
  );
};


// const SurveySelect: React.FunctionComponent<ISurveySurveySelectProps> = (
//   { workspaceOrChannelId }
// ) => {

//   const {
//     isLoading,
//     options: surveyOptions,
//     selectedOption: selectedSurveyOption,
//     onSelectedOptionUpdate: setSelectedSurveyOption,
//   } = useOptionsAndSelectedOption<IMenuOption>(
//     getSurveysAsMenuOptions(workspaceOrChannelId),
//     [workspaceOrChannelId]
//   );

//   const [surveyId, setSurveyId] =
//     useQueryParam('surveyId', StringParam);


//   React.useEffect(
//     () => {

//       if (surveyId === '') {

//       }

//       if (surveyId === undefined && surveyOptions.length > 0) {
//         setSurveyId(surveyOptions[0].value);
//         return;
//       }

//       const surveyOption = surveyOptions.find(
//         (option) => option.value === surveyId
//       );
//       if (surveyOption) {
//         setSelectedSurveyOption(surveyOption);
//       }

//     },
//     [surveyId, surveyOptions, setSelectedSurveyOption, setSurveyId]
//   );


//   // React.useEffect(
//   //   () => {
//   //     if(workspaceOrChannelId) {
//   //       setIsFirstWorkOrChannelIdChange(true);
//   //     }
//   //   },
//   //   [workspaceOrChannelId]
//   // )


//   // React.useEffect(
//   //   () => {

//   //     if (!isFirstWorkOrChannelIdChange && workspaceOrChannelId && surveyOptions.length > 0) {
//   //       setSurveyIdQueryParam(surveyOptions[0]);
//   //     }

//   //   },
//   //   [workspaceOrChannelId, surveyOptions, isFirstWorkOrChannelIdChange]
//   // );


//   return (

//   );
// };


const SurveyReports: React.FunctionComponent<{}> = () => {

  const app = useApp();
  const channelOrWorkspaceMenuOptions$ = useOrganizationMenuOptions$();
  const [surveyResponse, setSurveyResponse] = React.useState<ISurveyResponse | null>(null);
  const [isSurveyLoading, setSurveyLoading] = React.useState<boolean>(false);
  const [downloadingSurveyReport, setDownloadingSurveyReport] = React.useState<boolean>(false);

  const {
    isLoading: isWorkspaceOrChannelOptionsLoading,
    options: workspaceOrChannelOptions,
    selectedOption: selectedWorkspaceOrChannelOption,
    onSelectedOptionUpdate: setSelectedWorkspaceOrChannelOption,
  } = useOptionsAndSelectedOption<IChannelMenuOption>(
    channelOrWorkspaceMenuOptions$,
    [],
  );

  const {
    isLoading: isSurveyOptionsLoading,
    options: surveyOptions,
    selectedOption: selectedSurveyOption,
    onSelectedOptionUpdate: setSelectedSurveyOption,
    onOptionsUpdate: setSurveyOptions,
  } = useOptionsAndSelectedOption<ISurveyMenuOption>(
    selectedWorkspaceOrChannelOption ?
      getSurveysAsMenuOptions(app, selectedWorkspaceOrChannelOption?.value) :
      null,
    [selectedWorkspaceOrChannelOption]
  );

  const [query, setQueryParams] = useQueryParams({
    surveyId: StringParam,
    workspaceOrChannelId: StringParam,
  });


  React.useEffect(
    () => {

      if (!query.workspaceOrChannelId && workspaceOrChannelOptions.length > 0) {
        setQueryParams({ workspaceOrChannelId: workspaceOrChannelOptions[0].value });
        return;
      }

      let workspaceOrChannelOption: IChannelMenuOption | undefined =
        workspaceOrChannelOptions.find(
          (option) => option.value === query.workspaceOrChannelId
        );

      if (workspaceOrChannelOption) {
        setSelectedWorkspaceOrChannelOption(workspaceOrChannelOption);
      }

    },
    [query.workspaceOrChannelId, setQueryParams, setSelectedWorkspaceOrChannelOption, workspaceOrChannelOptions, app]
  );


  React.useEffect(
    () => {
      if (query.surveyId === '') {
        setSelectedSurveyOption(null);
        return;
      }

      if (!query.surveyId && surveyOptions.length > 0) {
        setQueryParams({ surveyId: surveyOptions[0].value });
        return;
      }

      const surveyOption = surveyOptions.find(
        (option) => option.value === query.surveyId
      );
      if (surveyOption) {
        setSelectedSurveyOption(surveyOption);

        getSurveyDetails(query.surveyId as string)
          .pipe(
            finalize(() => {
              setSurveyLoading(false)
            })
          )
          .subscribe(
            (response) => {
              setSurveyResponse(response.data.data);
            }
          )
      }

    },
    [query.surveyId, setQueryParams, setSelectedSurveyOption, surveyOptions]
  );


  React.useEffect(
    () => {

      if (query.surveyId === '' && surveyOptions.length > 0) {
        setQueryParams({ surveyId: surveyOptions[0].value });
      }

    },
    [query.surveyId, isSurveyOptionsLoading, surveyOptions, setQueryParams]
  );


  const handleWorkspaceOrChannelChange = (option: IChannelMenuOption) => {
    setSurveyOptions([]);
    setQueryParams({ workspaceOrChannelId: option.value, surveyId: '' });
  };


  const handleSurveyChange = (option: IMenuOption) => {
    setQueryParams({ surveyId: option.value });
  };


  const handleDownloadSurveyReportAction = (type: 'PDF' | 'XLSX'): void => {

    setDownloadingSurveyReport(true);

    if (!selectedSurveyOption) {
      return;
    }

    downloadSurveyReports(selectedSurveyOption.value, type).pipe(
      finalize(() => setDownloadingSurveyReport(false))
    ).subscribe(
      (response) => {
        downloadjs(
          response.data,
          `${selectedSurveyOption.label}_${selectedSurveyOption.launchedAt}${type === 'PDF' ? '.pdf' : '.xlsx'}`,
          type === 'PDF' ? FileFormats.PDF : FileFormats.XLSX
        );
      }
    )

  };


  return (
    <>
      <PageTitle title={Routes.SurveyReports.title} />
      <PrimaryLayoutBox
        title={Routes.SurveyReports.title}
        actions={
          <CustomSelect
            className='w-50'
            options={workspaceOrChannelOptions}
            value={selectedWorkspaceOrChannelOption as IChannelMenuOption}
            onChange={(option) => handleWorkspaceOrChannelChange(option as IChannelMenuOption)}
          />
        }
      >
        <div className='position-relative h-100 d-flex flex-column overflow-hidden'>
          {
            isWorkspaceOrChannelOptionsLoading || isSurveyLoading ?
              (<Preloader show={isWorkspaceOrChannelOptionsLoading} />) :
              (
                workspaceOrChannelOptions.length > 0 && selectedWorkspaceOrChannelOption ?
                  (
                    <div className='position-relative h-100 d-flex flex-column overflow-hidden'>
                      {
                        isSurveyOptionsLoading ?
                          (<Preloader show={isSurveyOptionsLoading} />) :
                          (
                            surveyOptions.length > 0 && selectedSurveyOption ?
                              (
                                <>
                                  <div className='flex-shrink-0 d-flex justify-content-between align-items-center'>
                                    <CustomSelect
                                      isSearchable
                                      className='w-25 mb-3'
                                      options={surveyOptions}
                                      optionType='description'
                                      value={selectedSurveyOption}
                                      placeholder='Select a survey...'
                                      onChange={(option) => handleSurveyChange(option as IMenuOption)} />

                                    {
                                      (selectedSurveyOption.state === 'closed' || (surveyResponse && surveyResponse.isLiveResults)) &&
                                      <Dropdown className='mx-2'>
                                        <Dropdown.Toggle id="downloadSurveyReportDropdown" disabled={downloadingSurveyReport}>
                                          {downloadingSurveyReport ? 'Downloading...' : 'Download'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => handleDownloadSurveyReportAction('PDF')}
                                          >
                                            PDF
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => handleDownloadSurveyReportAction('XLSX')}
                                          >
                                            Data
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    }

                                  </div>
                                  <SurveyReportsWidget surveyId={selectedSurveyOption.value} />
                                </>
                              ) :
                              (
                                <EmptyState message={<> No surveys available to view the insights. </>} />
                              )
                          )
                      }
                    </div>
                  ) :
                  (
                    <EmptyState message={
                      <>
                        {
                          app === SignInWith.Slack
                            ? "Please select a Slack Workspace to view the Insights."
                            : "Please select a Group to view the Insights."
                        }
                      </>
                    } />
                  )
              )
          }
        </div>
      </PrimaryLayoutBox>
    </>
  );
};


export default SurveyReports;
