import {
  BehaviorTypeFilter,
  CompanyMembersFilter,
  CompetencyAndBehaviourFilter,
  DateRangeFilter,
  FeedbackTypeFilter
} from '@common/components';
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap';
import { PermissionCheck } from 'components';
import { UserPermissions } from 'interfaces';
import React from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';


interface IFeedbackListAdvanceFiltersWidgetProps { }

const FeedbackListAdvanceFiltersWidget: React.FC<IFeedbackListAdvanceFiltersWidgetProps> = () => {

  // Route Hooks
  const [_, setQueryParams] = useQueryParams({
    feedbackType: StringParam,
    member: StringParam,
    competency: StringParam,
    behaviour: StringParam,
    behaviourType: StringParam,
    startDate: NumberParam,
    endDate: NumberParam
  });


  const clearAllFilters = (): void => {
    setQueryParams({
      feedbackType: undefined,
      member: undefined,
      competency: undefined,
      behaviour: undefined,
      behaviourType: undefined,
      startDate: undefined,
      endDate: undefined,
    })
  };


  return (
    <Card className='bg-white rounded-0 bg-gray-200'>
      <Card.Body>

        <Row className='d-flex'>
          <Col xs={8}>
            <DateRangeFilter />
          </Col>
          <Col className='d-flex justify-content-end align-items-end'>
            <Button
              variant='outline-danger'
              onClick={clearAllFilters}
            >
              Clear All
            </Button>
          </Col>
        </Row>

        <Row className='d-flex mt-4'>
          <Col>
            <FeedbackTypeFilter />
          </Col>

          <Col>
            <PermissionCheck permission={UserPermissions.ViewFeedbackSenderFilter}>
              <CompanyMembersFilter label='Feedback Sender' />
            </PermissionCheck>
          </Col>
          <Col></Col>
        </Row>

        <Row className='d-flex mt-4'>
          <Col xs={8}>
            <CompetencyAndBehaviourFilter />
          </Col>
          <Col>
            <BehaviorTypeFilter />
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
};

export default FeedbackListAdvanceFiltersWidget;