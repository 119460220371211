import { useNameInitials } from 'hooks';
import * as React from 'react';

interface IImageWithNameInitialsProps {
  imageOriginal?: string | null;
  label: string;
  size?: 'md' | 'lg';
}

const ImageWithNameInitials: React.FunctionComponent<IImageWithNameInitialsProps> = (
  { imageOriginal, label, size = 'md' }
) => {

  const nameInitials = useNameInitials(label);


  return (
    <div
      style={{
        width: size === 'lg' ? 72 : 26,
        height: size === 'lg' ? 72 : 26,
      }}
      className='teamble-user-image-container d-inline-flex align-items-center justify-content-center lh-1' >
      {
        imageOriginal
          ?
          <img alt={label} src={imageOriginal} />
          :
          <span className={size === 'lg' ? 'fs-4' : 'fs-xs'}> {nameInitials} </span>
      }
    </div>
  );
};

export default ImageWithNameInitials;
