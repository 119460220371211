import { Card } from '@themesberg/react-bootstrap';
import { Table, useTmblTable, useTmblTableData } from '@common/modules/table';
import { DataState, FeedbackDetailsModal } from 'components';
import { downloadTeammateFeedbacks, getTeammateFeedbacks } from 'data';
import downloadjs from 'downloadjs';
import { useApp, useFeedbacksListTableColumns } from 'hooks';
import { IFeedback, IFeedbacks, IRouteParams, ITimestampsRange, Nullable } from 'interfaces';
import React from 'react';
import { finalize } from 'rxjs';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { isDateRangeValid } from 'utils';

import FeedbackListTableWidgetHeader from './FeedbackListTableWidgetHeader';
import { useParams } from 'react-router-dom';

interface IFeedbacksListTableWidgetProps {
  profileVieweeTeambleUserId: string | undefined;
}


const FeedbacksListTableWidget: React.FC<IFeedbacksListTableWidgetProps> = (
  { profileVieweeTeambleUserId }
) => {

  // State Hooks
  // Loading
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  // Feedback Data
  const [feedbacks, setFeedbacks] = React.useState<IFeedbacks>();
  const [feedbackDetailsToShow, setFeedbackDetailsToShow] = React.useState<IFeedback>();

  // App hook
  const app = useApp();

  // Route Hooks
  const [queryParams] = useQueryParams({
    search: StringParam,
    feedbackType: StringParam,
    member: StringParam,
    competency: StringParam,
    behaviour: StringParam,
    behaviourType: StringParam,
    startDate: NumberParam,
    endDate: NumberParam
  });

  const { relationshipId } = useParams<IRouteParams>();

  // Table Hooks
  const columns = useFeedbacksListTableColumns(feedbacks?.maps);
  const data = useTmblTableData<IFeedback>(feedbacks?.feedbacks || []);
  const tableInstance = useTmblTable<IFeedback>(
    columns,
    data,
    'sentAt',
    true
  );


  // Set the table global filter based on the 'search' query param
  React.useEffect(
    () => {
      tableInstance.setGlobalFilter(queryParams.search || '');
    },
    [queryParams.search]
  );


  // Set the table filter based on the 'feedbackType' query param
  React.useEffect(
    () => {
      tableInstance.setFilter('feedbackType', queryParams.feedbackType);
    },
    [queryParams.feedbackType]
  );


  // Set the table feedback sender based on the 'member' query param
  React.useEffect(
    () => {
      tableInstance.setFilter('sender', queryParams.member);
    },
    [queryParams.member]
  );


  // Set the table feedback filter based on the tag query params
  React.useEffect(
    () => {
      tableInstance.setFilter('competency', {
        competency: queryParams.competency,
        behaviour: queryParams.behaviour,
        behaviourType: queryParams.behaviourType,
      });
    },
    [queryParams.behaviour, queryParams.behaviourType, queryParams.competency]
  );


  // Get the list of feedbacks of a user
  React.useEffect(
    () => {
      if (!isDateRangeValid(queryParams.startDate, queryParams.endDate)) {
        return;
      }

      const filters: Nullable<Partial<ITimestampsRange>> = {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      };

      setIsLoading(true);

      const subscription = getTeammateFeedbacks(profileVieweeTeambleUserId, filters, relationshipId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setFeedbacks(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [app, profileVieweeTeambleUserId, queryParams.endDate, relationshipId, queryParams.startDate]
  );

  const downloadFeedbackList = (): void => {

    if (!isDateRangeValid(queryParams.startDate, queryParams.endDate)) {
      return;
    }

    const feedbackIds = tableInstance.rows.map(
      (row) => row.original.feedback_id
    );

    setIsDownloading(true);

    downloadTeammateFeedbacks(
      profileVieweeTeambleUserId,
      feedbackIds,
      new Date().getTimezoneOffset(),
      relationshipId
    )
      .pipe(
        finalize(() => setIsDownloading(false))
      )
      .subscribe(
        (response) => {
          downloadjs(response.data, 'Feedback', 'application/pdf');
        }
      );
  };


  return (
    <>
      <Card className='h-100'>

        <FeedbackListTableWidgetHeader
          isDownloadable={!!tableInstance.rows.length}
          isDownloading={isDownloading}
          onDownloadClick={downloadFeedbackList} />

        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!tableInstance.rows.length}
          emptyStateMessage={<> No feedback available! </>}
        >
          <Card.Body className='flex-grow-1 overflow-y-auto pt-0 position-relative z-index-1'>
            <Table
              hover
              tableInstance={tableInstance}
              onRowClick={
                (row) => setFeedbackDetailsToShow(row.original)
              }
            />
          </Card.Body>

        </DataState>
      </Card >
      {
        feedbackDetailsToShow && feedbacks
          ?
          <FeedbackDetailsModal
            feedback={feedbackDetailsToShow}
            feedbackMaps={feedbacks.maps}
            show={!!(feedbackDetailsToShow && feedbacks)}
            onClose={() => setFeedbackDetailsToShow(undefined)}
          />
          :
          null
      }
    </>
  );
};

export default FeedbacksListTableWidget;