import {
  AnalyticsColorTypes,
  BehaviourTypes,
  FeedbackForAudienceTypes,
  GroupFeedbackTypeFilters,
  IActionTypes,
  ICommonTableRowActions,
  IFeedbackTypes,
  IFixedMenuOption,
  IMenuOption,
  IntegrationTypes,
  ISimpleMenuOption,
  ITableRowCommonActions,
  MyReviewTabs,
  NominationActions,
  ReviewActions,
  ReviewResultCanShareBy,
  ReviewResultShareAfter,
  ReviewResultShareWith,
  SendReminderAsTypes,
  ShareReviewResultTypes,
} from 'interfaces';

export const ACTIVE_REVIEW_AUDIENCE_ACTIONS: { [key: string]: IMenuOption } = {
  view: {
    value: '1',
    label: 'View'
  },
  sendReminder: {
    value: '2',
    label: 'Send Reminder'
  },
  approveCancelRequest: {
    value: '3',
    label: 'Approve Cancel Request'
  },
  remove: {
    value: '4',
    label: 'Remove'
  },
  viewComment: {
    value: '5',
    label: 'View Comment',
  },
  share: {
    value: '6',
    label: 'Share'
  },
  reopen: {
    value: '7',
    label: 'Reopen'
  },
  download: {
    value: '8',
    label: 'Download'
  },
  changeReviewer: {
    value: '9',
    label: 'Change Reviewer'
  }
};

// export const ADD_NEW_PERFORMANCE_REVIEW_ACTIONS: IMenuOption[] = [
//   {
//     value: '1',
//     label: 'Build from scratch',
//     link: `${Routes.PerformanceReviewQuestionnaires.path}/new`
//   },
//   {
//     value: '2',
//     label: 'Use a template',
//     link: Routes.PerformanceReviewTemplates.path
//   },
// ];

export const SELECT_ALL_OPTION: IMenuOption = {
  value: 'All',
  label: 'All'
};

export const TABLE_ROW_EDIT_ACTION: ISimpleMenuOption = {
  value: ICommonTableRowActions.Edit,
  label: 'Edit'
};

export const COMMON_TABLE_ROW_ACTIONS: ISimpleMenuOption[] = [
  TABLE_ROW_EDIT_ACTION,
  {
    value: ICommonTableRowActions.Delete,
    label: 'Delete'
  }
];

export const USER_BULK_ACTION_OPTIONS: ISimpleMenuOption[] = [
  {
    value: IActionTypes.Include,
    label: 'Include'
  },
  {
    value: IActionTypes.Exclude,
    label: 'Exclude'
  }
];

export const USER_TABLE_ACTIONS: ISimpleMenuOption[] = [
  {
    value: IActionTypes.Edit,
    label: 'Edit'
  },
  ...USER_BULK_ACTION_OPTIONS
];

export const USER_TABLE_FILTER_OPTIONS: ISimpleMenuOption[] = [
  SELECT_ALL_OPTION,
  {
    value: IActionTypes.Include,
    label: 'Active'
  },
  {
    value: IActionTypes.Exclude,
    label: 'Inactive'
  }
];

export const USER_TABLE_DEACTIVATE_OPTION: ISimpleMenuOption = {
  value: IActionTypes.Deactivate,
  label: 'Deactivated'
};

export const REVIEW_SURVEY_TABLE_ROW_COMMON_ACTIONS: ISimpleMenuOption[] = [
  {
    value: ITableRowCommonActions.Duplicate,
    label: 'Duplicate'
  },
  {
    value: ITableRowCommonActions.Delete,
    label: 'Delete'
  },
];


export const TABLE_ROW_COMMON_ACTIONS: ISimpleMenuOption[] = [
  ...REVIEW_SURVEY_TABLE_ROW_COMMON_ACTIONS,
  {
    value: ITableRowCommonActions.PreviewAndLaunch,
    label: 'Preview & Launch'
  }
];


export const FEEDBACK_TYPE_LABELS = {
  [IFeedbackTypes.Direct]: 'Feedback to Employee',
  [IFeedbackTypes.Managers]: 'Feedback to Managers',
  [IFeedbackTypes.DirectAndManagers]: 'Direct Feedback + Managers',
  [IFeedbackTypes.Company]: 'Company Feedback',
};


export const FEEDBACK_TYPES: ISimpleMenuOption[] = [
  {
    value: IFeedbackTypes.Direct,
    label: FEEDBACK_TYPE_LABELS[IFeedbackTypes.Direct]
  },
  {
    value: IFeedbackTypes.Managers,
    label: FEEDBACK_TYPE_LABELS[IFeedbackTypes.Managers]
  },
  {
    value: IFeedbackTypes.DirectAndManagers,
    label: FEEDBACK_TYPE_LABELS[IFeedbackTypes.DirectAndManagers]
  },
  // {
  //   value: IFeedbackTypes.Company,
  //   label: FEEDBACK_TYPE_LABELS[IFeedbackTypes.Company]
  // },
];

export const NONE_OPTION: ISimpleMenuOption = {
  value: '',
  label: 'None'
};

export const MULTI_SELECT_QUESTION_NA_OPTION: ISimpleMenuOption = {
  value: 'N/A',
  label: 'N/A'
}

export const DOWNLOAD_FORMATS: ISimpleMenuOption[] = [
  {
    value: 'PDF',
    label: 'PDF'
  },
  {
    value: 'XLSX',
    label: 'Data'
  }
];

export const REVIEW_AUDIENCE_TABLE_BULK_ACTIONS: ISimpleMenuOption[] = [
  {
    value: IActionTypes.Remove,
    label: 'Remove'
  }
]

export const SHARE_REVIEW_RESULT_OPTIONS: ISimpleMenuOption[] = [
  {
    value: ShareReviewResultTypes.MANUAL,
    label: 'Manual'
  },
  {
    value: ShareReviewResultTypes.AUTOMATED,
    label: 'Automated'
  },
];

export const ANALYTICS_COLOR_TYPES: IMenuOption[] = [
  {
    value: AnalyticsColorTypes.Default,
    label: 'Default',
    description: 'Low (Blue) to High (Red)'
  },
  {
    value: AnalyticsColorTypes.Reverse,
    label: 'Reverse',
    description: 'Low (Red) to High (Blue)'
  }
];


const launchedByOthersOption: ISimpleMenuOption = {
  value: 'launchedByOthers',
  label: 'Launched by Others',
};


export const SURVEY_LAUNCHED_BY_FILTER_OPTIONS: ISimpleMenuOption[] = [
  {
    value: 'mySurveys',
    label: 'My Surveys',
  },
  launchedByOthersOption
];


export const REVIEW_LAUNCHED_BY_FILTER_OPTIONS: ISimpleMenuOption[] = [
  {
    value: 'myCampaigns',
    label: 'My Reviews',
  },
  launchedByOthersOption
];


export const FEEDBACK_LOOP_LAUNCHED_BY_FILTER_OPTIONS: ISimpleMenuOption[] = [
  {
    value: 'myFeedbackLoops',
    label: 'My Feedback Loops',
  },
  launchedByOthersOption
];


export const SURVEY_DURATION_TYPES: ISimpleMenuOption[] = [
  {
    value: 'manual',
    label: 'Manual',
  },
  {
    value: 'automatic',
    label: 'Select Duration',
  },
  // {
  //   value: 'onSpecificTime',
  //   label: 'Select Time',
  // },
  {
    value: 'closeAfterAllSubmits',
    label: 'Close once everyone submits',
  }
];


export const FEEDBACK_FOR_AUDIENCE_TYPE_LABELS = {
  [FeedbackForAudienceTypes.SelfSelect]: 'Self Select',
  [FeedbackForAudienceTypes.ManagerDownward]: 'Manager Downward',
  [FeedbackForAudienceTypes.ManagerUpward]: 'Manager Upward',
  [FeedbackForAudienceTypes.ThreeSixty]: '360',
  [FeedbackForAudienceTypes.ManuallyDefine]: 'Manually Define',
};


export const FEEDBACK_FOR_AUDIENCE_TYPES: IMenuOption[] = [
  {
    value: FeedbackForAudienceTypes.SelfSelect,
    label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.SelfSelect],
  },
  {
    value: 'define',
    label: 'Define',
    options: [
      {
        value: FeedbackForAudienceTypes.ManagerUpward,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManagerUpward],
      },
      {
        value: FeedbackForAudienceTypes.ManagerDownward,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManagerDownward],
      },
      {
        value: FeedbackForAudienceTypes.ThreeSixty,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ThreeSixty],
      },
      {
        value: FeedbackForAudienceTypes.ManuallyDefine,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManuallyDefine],
      },
    ]
  },
];

export const ONE_ON_ONE_FOR_AUDIENCE_TYPES: IMenuOption[] = [
  {
    value: 'define',
    label: 'Define',
    options: [
      {
        value: FeedbackForAudienceTypes.ManagerUpward,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManagerUpward],
      },
      {
        value: FeedbackForAudienceTypes.ManagerDownward,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManagerDownward],
      },
      {
        value: FeedbackForAudienceTypes.ManuallyDefine,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManuallyDefine],
      },
    ]
  },
];


export const MS_TEAMS_FEEDBACK_FOR_AUDIENCE_TYPES: IMenuOption[] = [
  {
    value: FeedbackForAudienceTypes.SelfSelect,
    label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.SelfSelect],
  },
  {
    value: 'define',
    label: 'Define',
    options: [
      // {
      //   value: FeedbackForAudienceTypes.ManagerUpward,
      //   label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManagerUpward],
      // },
      // {
      //   value: FeedbackForAudienceTypes.ManagerDownward,
      //   label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManagerDownward],
      // },
      {
        value: FeedbackForAudienceTypes.ThreeSixty,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ThreeSixty],
      },
      {
        value: FeedbackForAudienceTypes.ManuallyDefine,
        label: FEEDBACK_FOR_AUDIENCE_TYPE_LABELS[FeedbackForAudienceTypes.ManuallyDefine],
      },
    ]
  },
];


export const REVIEW_RESULT_CAN_SHARE_BY_OPTIONS: IFixedMenuOption[] = [
  {
    value: ReviewResultCanShareBy.Admin,
    label: 'Admin',
    isFixed: true,
  },
  {
    value: ReviewResultCanShareBy.Reviewer,
    label: 'Reviewer',
  },
];


export const REVIEW_RESULT_SHARE_WITH_OPTIONS: ISimpleMenuOption[] = [
  {
    value: ReviewResultShareWith.Managers,
    label: 'Managers',
  },
  {
    value: ReviewResultShareWith.Reviewees,
    label: 'Reviewees',
  },
];


export const REVIEW_RESULT_SHARE_AFTER_OPTIONS: ISimpleMenuOption[] = [
  {
    value: ReviewResultShareAfter.Closed,
    label: 'Review cycle is closed',
  },
  {
    value: ReviewResultShareAfter.Submitted,
    label: 'Review is submitted',
  },
];

const REVIEW_ACTIONS: ISimpleMenuOption[] = [
  {
    value: ReviewActions.DeleteSchedule,
    label: 'Delete Schedule',
  },
  {
    value: ReviewActions.DeleteReview,
    label: 'Delete Review',
  },
  {
    value: ReviewActions.Download,
    label: 'Download Data',
  },
  {
    value: ReviewActions.CancelReview,
    label: 'Cancel Review',
  },
  {
    value: ReviewActions.ReopenReview,
    label: 'Re-Open',
  },
];

export const SCHEDULED_REVIEW_ACTIONS: ISimpleMenuOption[] = [
  REVIEW_ACTIONS[0],
  REVIEW_ACTIONS[2],
  REVIEW_ACTIONS[3]
];

export const LIVE_REVIEW_ACTIONS: ISimpleMenuOption[] = [
  REVIEW_ACTIONS[2],
  REVIEW_ACTIONS[3]
];

export const CLOSED_REVIEW_ACTIONS: ISimpleMenuOption[] = [
  REVIEW_ACTIONS[2],
  REVIEW_ACTIONS[1],
  REVIEW_ACTIONS[4]
];

export const DRAFT_REVIEW_ACTIONS: ISimpleMenuOption[] = [
  REVIEW_ACTIONS[1]
];

export const MyReviewsTabs: ISimpleMenuOption[] = [
  {
    value: MyReviewTabs.ReviewsReceived,
    label: 'Reviews Received'
  },
  {
    value: MyReviewTabs.MyTeam,
    label: 'My Team'
  },
  {
    value: MyReviewTabs.ReviewsGiven,
    label: 'Reviews Given'
  },
  {
    value: MyReviewTabs.SharedWithMe,
    label: 'Reviews Shared with Me'
  },
];

export const IntegrationTypeLabels = {
  [IntegrationTypes.BambooHR]: 'Bamboo HR',
  [IntegrationTypes.HiBob]: 'Hi Bob',
};

export const IntegrationTypeOptions: ISimpleMenuOption[] = [
  {
    value: IntegrationTypes.BambooHR,
    label: IntegrationTypeLabels[IntegrationTypes.BambooHR]
  },
  {
    value: IntegrationTypes.HiBob,
    label: IntegrationTypeLabels[IntegrationTypes.HiBob]
  }
];

export const GROUP_FEEDBACK_TYPE_OPTIONS: ISimpleMenuOption[] = [
  {
    value: GroupFeedbackTypeFilters.AllFeedback,
    label: 'All feedback'
  },
  {
    value: GroupFeedbackTypeFilters.GroupOnly,
    label: 'Group only'
  }
];

export const NOMINATION_ACTIONS: ISimpleMenuOption[] = [
  {
    value: NominationActions.Approve,
    label: NominationActions.Approve
  },
  {
    value: NominationActions.Deny,
    label: NominationActions.Deny,
  }
];

export const BEHAVIOUR_TYPE_OPTIONS: ISimpleMenuOption[] = [
  {
    value: BehaviourTypes.Strength,
    label: 'Strength'
  },
  {
    value: BehaviourTypes.DevelopmentArea,
    label: 'Development Area'
  }
];

export const SEND_REMINDER_AS_OPTIONS: ISimpleMenuOption[] = [
  {
    value: SendReminderAsTypes.AppMessage,
    label: 'Send as App Message'
  },
  {
    value: SendReminderAsTypes.Email,
    label: 'Send as Email'
  }
];
