import { SignInWith } from "interfaces";

export interface IUserProfile {
  app: SignInWith;
  name: string;
  imageOriginal: string;
  email: string;
  isManager: boolean;
  isSuperAdmin: boolean;
  isTeamAdmin: boolean;
  permissions?: UserPermissions[];
  colorCodes: {
    bgColor: string;
    fontColor: string;
  }[];
  isRelationship: boolean;
  teambleUserId?: string;
}

export interface IUserProfileContextValue {
  userProfile: IUserProfile | null;
  setUserProfile: React.Dispatch<React.SetStateAction<IUserProfile | null>>;
}

export enum UserPermissions {
  // All
  AllUserRoleRouteAccess = "allUserRoleRouteAccess",

  // Review
  ViewReviews = "viewReviews",
  ViewReviewBuilder = "viewReviewBuilder",
  ViewManageReviews = "viewManageReviews",
  ViewReviewReports = "viewReviewReports",
  ViewReviewReportThirdLevelRow = "viewReviewReportThirdLevelRow",
  ViewTodosPage = "viewTodosPage",
  ViewMyReviewsPage = "viewMyReviewsPage",

  // Survey
  ViewSurveys = "viewSurveys",
  ViewLaunchedByOthersSurveysFilter = "viewLaunchedByOthersSurveysFilter",
  PerformSurveyTemplateActions = "performSurveyTemplateActions",
  CreateUpdateSurveyTemplate = "createUpdateSurveyTemplate",
  LockUnlockSurveyQuestion = "lockUnlockSurveyQuestion",

  // Analytics
  ViewAnalytics = "viewAnalytics",
  ViewSurveyAnalyticsOverviewTab = "viewSurveyAnalyticsOverviewTab",
  ViewSurveyAnalyticsQuestionTimelineTab = "viewSurveyAnalyticsQuestionTimelineTab",
  ViewGroupFeedbackTypeFilter = "viewGroupFeedbackTypeFilter",

  // Organization
  ViewOrganization = "viewOrganization",

  // Admins
  ViewAdmins = "viewAdmins",
  ViewSuperAdmins = "viewSuperAdmins",

  // Users
  ViewUsers = "viewUsers",
  UpdateUserManagers = "updateUserManagers",
  ViewManagees = "viewManagees",
  ViewUserStatusFilter = "viewUserStatusFilter",
  ViewUserOrganizationFilter = "viewUserOrganizationFilter",

  // Feedback Loops
  ViewFeedbackLoops = "viewFeedbackLoops",
  ViewLaunchedByOthersFeedbackLoopsFilter = "viewLaunchedByOthersFeedbackLoopsFilter",

  // Roles
  ViewRoles = "viewRoles",
  PerformRolesActions = "performRolesActions",

  // Departments
  ViewDepartments = "viewDepartments",
  PerformDepartmentActions = "performDepartmentActions",
  ViewDepartmentDetails = "viewDepartmentDetails",

  // Competencies
  ViewCompetencies = "viewCompetencies",
  PerformCompetencyActions = "performCompetencyActions",

  // Goals
  PerformGoalsAction = "performGoalsAction",

  // Relationships
  ViewRelationShipPage = "viewRelationShipPage",
  PerformanceRelationshipActions = "performanceRelationshipAction",

  // Groups
  ViewGroups = "viewGroups",
  ViewMyGroupsPage = "viewMyGroupsPage",

  // Integration
  ViewIntegrationsListPage = "viewIntegrationsListPage",

  // Settings
  ViewSettingsModal = "viewSettingsModal",

  // Feedback
  ViewFeedbackSenderFilter = "viewFeedbackSenderFilter ",

  // Member Profile
  ViewPerformanceTab = "viewPerformanceTab",
  viewAiFeedback = "viewAiFeedback",
}
