import EngagementStatusView from "modules/1-on-1s/components/EngagementStatusView/EngagementStatusView";
import HeaderText from "modules/1-on-1s/components/HeaderText/HeaderText";
import { oneOnOneDetailListProps } from "modules/1-on-1s/types";
import { convertDate, statusText } from "modules/1-on-1s/utils";
import React from "react";

interface OneOnOneDetailsCardProps {
  data: oneOnOneDetailListProps | null;
}
const OneOnOneDetailsCard: React.FC<OneOnOneDetailsCardProps> = ({ data }) => {
  return (
    <div
      style={{
        height: "fit-content",
      }}
      className="engagement"
    >
      <div className="header-container">
        <HeaderText
          text={"Details"}
          fontSize={"24px"}
          lineHeight={"24px"}
          letter-spacing={"-0.24px"}
        />
        {/* <SecondaryButton name="Reschedule" /> */}
      </div>
      <div className="info">
        <div className="status">
          <EngagementStatusView
            topic={"Meeting by"}
            value={data?.reviewerName ?? ""}
            maxWidth={"140px"}
          />
          <EngagementStatusView
            topic={"Scheduled on:"}
            value={
              convertDate(
                (data?.state !== "closed"
                  ? data?.scheduledTimestamp?.toString()
                  : data?.startAt?.toString()) || ""
              ) ?? "Not Scheduled"
            }
            maxWidth={"130px"}
          />
          <EngagementStatusView
            topic={"Repeats:"}
            value={data?.schedule?.frequency}
            maxWidth={"150px"}
          />
          <EngagementStatusView
            topic={"Status:"}
            value={statusText(data?.state ?? "")}
            maxWidth={"90px"}
          />
        </div>
        {/* <div className="graph-container">
                  <div className="info-graph">
                    <div className="graph">
                      <div
                        style={{
                          width: "70%",
                        }}
                        className="completed"
                      ></div>
                      <div
                        style={{
                          width: "20%",
                        }}
                        className="cancelled"
                      ></div>
                      <div
                        style={{
                          width: "10%",
                        }}
                        className="waiting"
                      ></div>
                    </div>
                  </div>
                  <div className="status-graph-info">
                    <div>
                      <EngagementStatusView
                        topic={"Completed"}
                        value={"2"}
                        maxWidth={"74px"}
                        color="#97989B"
                      />
                    </div>
                    <div className="info-2">
                      <EngagementStatusView
                        topic={"Canceled"}
                        value={"1"}
                        maxWidth={"64px"}
                        color="#97989B"
                      />
                      <EngagementStatusView
                        topic={"Waiting"}
                        value={"1"}
                        maxWidth={"64px"}
                        color="#97989B"
                      />
                    </div>
                  </div>
                </div> */}
      </div>
    </div>
  );
};

export default OneOnOneDetailsCard;
