import { ITab, ReviewSummaryTabTypes, UserPermissions } from "interfaces";
import { Routes } from "routes";

export const SURVEY_ANALYTICS_TABS: ITab[] = [
  {
    toLink: Routes.SurveyAnalyticsTimeline.path,
    label: 'Timeline'
  },
  // {
  //   toLink: Routes.SurveyAnalyticsOrganization.path,
  //   label: 'Organization'
  // },
  {
    toLink: Routes.SurveyAnalyticsOverview.path,
    label: 'Overview',
    permission: UserPermissions.ViewSurveyAnalyticsOverviewTab
  },
  {
    toLink: Routes.SurveyAnalyticsSentiments.path,
    label: 'Sentiments'
  },
  {
    toLink: Routes.SurveyAnalyticsQuestionTimeline.path,
    label: 'Question Timeline',
    permission: UserPermissions.ViewSurveyAnalyticsQuestionTimelineTab
  }
];


export const REVIEW_TODO_SUMMARY_TABS: ITab[] = [
  {
    toLink: ReviewSummaryTabTypes.Goals,
    label: 'Goals',
  },
  {
    toLink: ReviewSummaryTabTypes.Objectives,
    label: 'Objectives',
  },
  {
    toLink: ReviewSummaryTabTypes.Feedback,
    label: 'Feedback',
  },
  {
    toLink: ReviewSummaryTabTypes.Reviews,
    label: 'Reviews',
  }
];