import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { CustomModal, FormField, SubmitButton } from 'components';
import { FormContext } from 'contexts';
import { getCompetenciesListAsMenuOptions, performGoalsAction } from 'data';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  IGoal,
  IGoalFormValues,
  IGoalRequestData,
  IModalProps,
  ISimpleMenuOption,
} from 'interfaces';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { GOAL_VALIDATION_SCHEMA } from 'validation-schemas';


interface ICreateUpdateGoalModalProps extends IModalProps {
  goalToUpdate?: IGoal;
  profileVieweeTeambleUserId: string;
  relationshipId?: string;
  onCreateOrUpdateGoal: (goal: IGoal) => void;
}


interface ICreateUpdateGoalFormProps {
  goalToUpdate?: IGoal;
  profileVieweeTeambleUserId: string;
  relationshipId?: string;
  onClose: IModalProps['onClose'];
  onCreateOrUpdateGoal: (goal: IGoal) => void;
}

const CreateUpdateGoalForm: React.FC<ICreateUpdateGoalFormProps> = ({
  onClose,
  goalToUpdate,
  relationshipId,
  onCreateOrUpdateGoal,
  profileVieweeTeambleUserId,
}) => {

  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [competencyOptions, setCompetencyOptions] = React.useState<ISimpleMenuOption[]>([]);
  const [behaviorOptions, setBehaviorOptions] = React.useState<ISimpleMenuOption[]>([]);


  const getGoalFormRequestData = (
    formValues: IGoalFormValues, goalToUpdate?: IGoal
  ): IGoalRequestData => {

    return {
      id: goalToUpdate?.id,
      name: formValues.name,
      deadline: formValues.deadline,
      description: formValues.description || '',
      competency: formValues.competency.map((comp) => comp.value),
      behaviors: formValues.behaviors.map((beh) => beh.value)
    }

  };

  const submitGoalForm = (
    values: IGoalFormValues,
    formikHelpers: FormikHelpers<IGoalFormValues>
  ): void => {

    const requestData = getGoalFormRequestData(values, goalToUpdate);
    formikHelpers.setSubmitting(true);

    performGoalsAction({
      profileVieweeTeambleUserId,
      relationshipId,
      goals: [requestData],
      type: goalToUpdate ? 'update' : 'create'
    })
      .pipe(
        finalize(() => formikHelpers.setSubmitting(false))
      )
      .subscribe(
        (response) => {
          formikHelpers.resetForm();
          toast.success(response.data.message);
          onCreateOrUpdateGoal(response.data.data[0]);
          onClose();
        }
      );
  };


  const getGoalFormInitialValues =
    (goal?: IGoal): IGoalFormValues => {

      return {
        name: goal?.name || '',
        description: goal?.description || '',
        deadline: goal?.deadline,
        competency: [],
        behaviors: goal?.behaviors?.map(
          (beh) => ({
            value: beh.id,
            label: beh.name
          })
        ) || [],
      }

    };


  // Form Hook
  const goalForm = useFormik<IGoalFormValues>({
    initialValues: getGoalFormInitialValues(goalToUpdate),
    validate: (values => {

      const errors: FormikErrors<IGoalFormValues> = {};
      if (values?.deadline && moment(values.deadline).valueOf() <= moment().valueOf()) {
        errors.deadline = 'Deadline cannot be in the past';
      }

      return errors;
    }),
    validationSchema: GOAL_VALIDATION_SCHEMA,
    onSubmit: submitGoalForm
  });

  React.useEffect(
    () => {

      setIsLoading(true);
      setCompetencyOptions([]);
      setBehaviorOptions([]);

      const subscription = getCompetenciesListAsMenuOptions()
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {

            setCompetencyOptions(response.data.data);
            if (!goalToUpdate || !goalToUpdate.competency?.length) {
              goalForm.setFieldValue('competency', []);
              return;
            }

            const goalCompetencyIds = goalToUpdate.competency?.map(
              (comp) => comp.id
            );
            const competencies = response.data.data.filter(
              (comp) => goalCompetencyIds.includes(comp.value)
            );
            goalForm.setFieldValue('competency', competencies);

          }
        );

      return () => subscription.unsubscribe();
    },
    [goalToUpdate]
  );

  // Set behavior options and value
  React.useEffect(
    () => {

      // Setting behavior options
      if (!goalForm.values.competency.length) {
        setBehaviorOptions([]);
        goalForm.setFieldValue('behaviors', []);
        return;
      }
      const behaviors = goalForm.values.competency.flatMap(
        (comp) => comp.subOptions
      );
      setBehaviorOptions(behaviors);

      // Setting Behavior values in the form
      if (!goalToUpdate || !goalToUpdate.behaviors?.length) {
        goalForm.setFieldValue('behaviors', []);
        return;
      }
      const behaviorIds = goalToUpdate.behaviors.map((beh) => beh.id);
      const behaviorsValue = behaviors.filter(
        (beh) => behaviorIds.includes(beh.value)
      );
      goalForm.setFieldValue('behaviors', behaviorsValue);

    },
    [goalForm.values.competency, goalToUpdate]
  );

  return (
    <Form onSubmit={goalForm.handleSubmit} >
      <Modal.Body>
        <FormContext.Provider value={goalForm}>

          <FormField
            label='Goal *'
            size='sm'
            controlName='name'
            controlId='goalFormNameField'
            placeholder='Enter Goal...'
            type={FormFieldTypeConstants.Text}
          />

          <FormField
            label='Description'
            size='sm'
            controlName='description'
            controlId='goalFormDescriptionField'
            placeholder='Enter Description...'
            type={FormFieldTypeConstants.Text}
          />

          <FormField
            label='Deadline'
            controlId='deadline'
            type={FormFieldTypeConstants.Datepicker}
            controlName='deadline'
          />

          <FormField
            multiple
            size='sm'
            label='Competencies'
            isLoading={isLoading}
            placeholder='Select Competencies...'
            controlId='goalFormCompetencyField'
            type={FormFieldTypeConstants.Select}
            options={competencyOptions}
            controlName='competency' />

          <FormField
            multiple
            size='sm'
            label='Behaviors'
            isLoading={isLoading}
            placeholder='Select Behaviors...'
            controlId='goalFormBehaviorsField'
            type={FormFieldTypeConstants.Select}
            options={behaviorOptions}
            controlName='behaviors' />

        </FormContext.Provider>
      </Modal.Body>

      <Modal.Footer className="justify-content-end px-4">
        <Button type='button' variant="outline-primary" onClick={onClose}>
          Close
        </Button>
        <SubmitButton
          isSubmitting={goalForm.isSubmitting}
          text='Save'
          submittingText='Saving...'
        />
      </Modal.Footer>
    </Form>
  );
};


const CreateUpdateGoalModal: React.FC<ICreateUpdateGoalModalProps> = ({
  show,
  onClose,
  goalToUpdate,
  relationshipId,
  onCreateOrUpdateGoal,
  profileVieweeTeambleUserId,
}) => {

  return (
    <CustomModal
      title={goalToUpdate ? 'Update Goal' : 'Add New Goal'}
      show={show}
      onClose={onClose}
    >
      <CreateUpdateGoalForm
        goalToUpdate={goalToUpdate}
        relationshipId={relationshipId}
        profileVieweeTeambleUserId={profileVieweeTeambleUserId}
        onClose={onClose}
        onCreateOrUpdateGoal={onCreateOrUpdateGoal} />
    </CustomModal>
  );
};

export default CreateUpdateGoalModal;