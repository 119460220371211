import loadable from '@loadable/component';
import { NotFoundPage, Preloader } from 'components';
import SurveyInsights from 'modules/insights/components/SurveyInsights';
import SurveyInsightsDepartment from 'modules/insights/SurveyInsightsDepartment';
import PerformanceReviewReports from 'modules/reports/PerformanceReviewReports';
import PerformanceReviewReportsTable from 'modules/reports/PerformanceReviewReportsTable';
import ReviewOrSurveyLibrary from 'modules/reviewOrSurvey/ReviewOrSurveyLibrary';
import ReviewDetailsPage from 'modules/reviews/ReviewDetailsPage';
import ReviewsListPage from 'modules/reviews/ReviewsListPage';
import LaunchNewReview from 'modules/reviews/LaunchNewReview';
import PerformanceReviewTemplateBuilder from 'modules/reviews/PerformanceReviewTemplateBuilder';
import PerformanceReviewTemplates from 'modules/reviews/PerformanceReviewTemplates';
import ReviewToDosPage from 'modules/reviews/ReviewToDosPage';
import ManageSurveys from 'modules/surveys/ManageSurveys';
import SurveyAudienceBuilder from 'modules/surveys/SurveyAudienceBuilder';
import SurveyBuilder from 'modules/surveys/SurveyBuilder';
import SurveyDetails from 'modules/surveys/SurveyDetails';
import SurveyReports from 'modules/surveys/SurveyReports';
import SurveyScheduler from 'modules/surveys/SurveyScheduler';
import SurveyTemplates from 'modules/surveys/SurveyTemplates';
import UserProfilePage from 'modules/user-profile/UserProfilePage';
import AuthorizationError from 'modules/authentication/AuthorizationError';
import CompleteAuthorization from 'modules/authentication/CompleteAuthorization';
import SelectSlackWorkspace from 'modules/authentication/SelectSlackWorkspace';
import SignIn from 'modules/authentication/Signin';
import React from 'react';
import ObjectivesListPage from 'modules/objective-campaigns/ObjectivesListPage';
import ObjectivesLaunchNewReview from 'modules/objective-campaigns/ObjectivesLaunchNewReview';
import OneOnOneFeedback from "modules/1-on-1s/oneOnOneAi";
import OneOnOneDetail from "modules/1-on-1s/pages/OneOnOneDetail/OneOnOneDetail";
import OneOnOnePrepareFeedback from "modules/1-on-1s/pages/OneOnOnePrepareFeedback/OneOnOnePrepareFeedback";
import WorkFlowConfigPage from "modules/feedback-loops/pages/WorkFlowConfigPage";

const loadableOptions = { fallback: <Preloader show={true} /> };

const SurveyAnalytics = loadable(
  () => import("modules/analytics/SurveyAnalytics"),
  loadableOptions
);

const SurveyAnalyticsOverview = loadable(
  () => import("modules/analytics/SurveyAnalyticsOverview"),
  loadableOptions
);

// const ReviewAnalytics = loadable(
//   () => import('modules/analytics/review-analytics/ReviewAnalytics'), loadableOptions
// );

// const ReviewAnalyticsOverview = loadable(
//   () => import('modules/analytics/review-analytics/ReviewAnalyticsOverview'), loadableOptions
// );

const SurveyAnalyticsSentiments = loadable(
  () => import("modules/analytics/SurveyAnalyticsSentiments"),
  loadableOptions
);

const SurveyAnalyticsTimeline = loadable(
  () => import("modules/analytics/SurveyAnalyticsTimeline"),
  loadableOptions
);

const SurveyAnalyticsQuestionTimeline = loadable(
  () => import("modules/analytics/SurveyAnalyticsQuestionTimeline"),
  loadableOptions
);

const Admins = loadable(() => import("modules/team/Admins"), loadableOptions);

const TeamAdminsListWidget = loadable(
  () => import("modules/team/components/TeamAdminsListWidget"),
  loadableOptions
);

const SuperAdminsWidget = loadable(
  () => import("modules/team/components/SuperAdminsWidget"),
  loadableOptions
);

const OrganizationSettingsPage = loadable(
  () => import("modules/users/OrganizationSettingsPage"),
  loadableOptions
);

const FeedbackLoopsListPage = loadable(
  () => import("modules/feedback-loops/pages/FeedbackLoopsListPage"),
  loadableOptions
);

const FeedbackLoopTemplateTypesPage = loadable(
  () => import("modules/feedback-loops/pages/FeedbackLoopTemplateTypesPage"),
  loadableOptions
);

const FeedbackLoopTemplatesListPage = loadable(
  () => import("modules/feedback-loops/pages/FeedbackLoopTemplatesListPage"),
  loadableOptions
);

const FeedbackLoopBuilderPage = loadable(
  () => import("modules/feedback-loops/pages/FeedbackLoopBuilderPage"),
  loadableOptions
);

const FeedbackLoopConfigPage = loadable(
  () => import("modules/feedback-loops/pages/FeedbackLoopConfigPage"),
  loadableOptions
);

const ManageFeedbackLoopsPage = loadable(
  () => import("modules/feedback-loops/pages/ManageFeedbackLoopsPage"),
  loadableOptions
);

const LaunchedFeedbackLoopDetailsPage = loadable(
  () => import("modules/feedback-loops/pages/LaunchedFeedbackLoopDetailsPage"),
  loadableOptions
);

const FeedbackAnalytics = loadable(
  () => import("modules/analytics/FeedbackAnalytics"),
  loadableOptions
);

const Reports = loadable(
  () => import("modules/analytics/reports.js"),
  loadableOptions
);

const MyReviewsPage = loadable(() => import("modules/reviews/MyReviewsPage"));

const DepartmentDetailsPage = loadable(
  () => import("modules/users/DepartmentDetailsPage"),
  loadableOptions
);

const GroupDetailsPage = loadable(
  () => import("modules/users/GroupDetailsPage"),
  loadableOptions
);

const MyGroupsPage = loadable(
  () => import("modules/users/MyGroupsPage"),
  loadableOptions
);

const MyTeamPage = loadable(
  () => import("modules/my-team/MyTeamPage"),
  loadableOptions
);

const IntegrationsListPage = loadable(
  () => import("modules/integration/IntegrationsListPage"),
  loadableOptions
);

const ReviewAnalytics = loadable(
  () => import("modules/analytics/review/ReviewAnalytics"),
  loadableOptions
);

const ReviewAnalyticsOverallTab = loadable(
  () => import("modules/analytics/review/ReviewAnalyticsOverview"),
  loadableOptions
);

const ReviewAnalyticsOrganizationTab = loadable(
  () => import("modules/analytics/review/ReviewAnalyticsOrganization"),
  loadableOptions
);

const ObjectiveManagePage = loadable(
  () => import('modules/objective-campaigns/ObjectiveManagePage'), loadableOptions
);

export const ROUTE_COMPONENTS = {
  // Profile page
  MyProfilePage: UserProfilePage,

  // Relationship Team
  MyTeamPage,
  MyTeamProfilePage: UserProfilePage,

  // My Groups page
  MyGroupsPage,

  // Authentication
  Signin: SignIn,
  SlackCompleteAuthorization: CompleteAuthorization,
  MsTeamsCompleteAuthorization: CompleteAuthorization,
  SelectSlackWorkspace,
  AuthorizationError,

  // Performance Reviews
  PerformanceReviewQuestionnaires: ReviewOrSurveyLibrary,
  PerformanceReviewTemplateBuilder,
  PerformanceReviewTemplates,
  PredefinedReviewTemplateBuilder: PerformanceReviewTemplateBuilder,
  ReviewsListPage,
  ReviewDetailsPage,
  LaunchReview: LaunchNewReview,
  DraftReviewById: LaunchNewReview,
  PerformanceReviewReports,
  PerformanceReviewReportsTable,
  MyReviewsPage,

  // Review Todos
  ReviewToDosPage,

  // Surveys
  SurveyLibrary: ReviewOrSurveyLibrary,
  SurveyTemplates,
  SurveyBuilder,
  SurveyAudienceBuilder,
  SurveyScheduler,
  EditSurveyTemplate: SurveyBuilder,
  ManageSurveys,
  SurveyDetails,
  SurveyReports,
  SurveyInsights,
  SurveyInsightsDepartment,

  // Analytics
  SurveyAnalytics,
  SurveyAnalyticsTimeline,
  SurveyAnalyticsOverview,
  SurveyAnalyticsSentiments,
  SurveyAnalyticsQuestionTimeline,
  FeedbackAnalytics,

  // Review Analytics
  ReviewAnalytics,
  ReviewAnalyticsOverallTab,
  ReviewAnalyticsOrganizationTab,

  // Organization
  OrganizationSettingsPage,
  Admins,
  TeamAdminsListWidget,
  SuperAdminsWidget,
  UserProfilePage,
  DepartmentDetailsPage,
  GroupDetailsPage,

  // Feedback Loops
  FeedbackLoopsListPage,
  FeedbackLoopTemplateTypesPage,
  FeedbackLoopTemplatesListPage,
  FeedbackLoopBuilderPage,
  EditFeedbackLoopTemplate: FeedbackLoopBuilderPage,
  FeedbackLoopConfigPage,
  ManageFeedbackLoopsPage,
  LaunchedFeedbackLoopDetailsPage,
  WorkFlowConfigPage,

  Reports,

  // Integration
  IntegrationsListPage,

  //objectives-campaign
  ObjectivesListPage,
  ObjectivesLaunchNewReview,
  ObjectiveManagePage,

  // Not Found
  NotFound: NotFoundPage,
  OneOnOne: OneOnOneFeedback,
  OneOnOneDetail: OneOnOneDetail,
  OneOnOnePrepareFeedback,
};
