import React, { useState } from "react";
import "./AiTone.scss";
import AiToolsDescriptionBadge from "../AiToolDescriptionBadge/AiToolsDescriptionBadge";
import AiQuestionCard from "../AiQuestionCard/AiQuestionCard";
import GradientPrimaryButton from "../../../../components/GradiantPrimaryButton/GradientPrimaryButton";
import { FeedbackData } from "modules/1-on-1s/types";
import { mergeOpeningStatementWithUserFeedback } from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import { AxiosError } from "axios";

interface AiToneProps {
  data: FeedbackData | null;
  setData: React.Dispatch<React.SetStateAction<FeedbackData | null>>;
  feedbackValue: string;
  openingStateMent: string;
  setOpeningStateMent: React.Dispatch<React.SetStateAction<string>>;
}
const AiTone: React.FC<AiToneProps> = ({
  data,
  setData,
  feedbackValue,
  openingStateMent,
  setOpeningStateMent,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    if (!data?.id) return;
    setIsLoading(true);
    mergeOpeningStatementWithUserFeedback(
      data.id,
      openingStateMent,
      data?.userFeedback
    )
      .pipe(
        finalize(() => {
          setIsLoading(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          setData({ ...response.data.feedback });
          setIsLoading(false);
        },
        error: (error: AxiosError) => {
          console.log(error);
          setIsLoading(false);
        },
      });
  };
  return (
    <div className="AiTone">
      <AiToolsDescriptionBadge
        label={"AI Tools"}
        value={"Tone"}
        desc={
          "We have removed certain language and phrases to soften the language while remaining its assertiveness. You can select the text to change the tone of specific sections too."
        }
      />
      <AiQuestionCard
        label={"Opening statement"}
        question={"How would you start the conversation?"}
        placeholder={"Currently unclear, please provide guidance..."}
        options={[
          { tag: "Make more alarming and assertive" },
          { tag: "Soften the language" },
          { tag: "Make it positive" },
        ]}
        value={openingStateMent}
        setValue={setOpeningStateMent}
        section="aiTone"
        data={data}
      />
      <div className="btn-container">
        <GradientPrimaryButton
          text={"Generate"}
          disabled={!openingStateMent.length}
          onClick={handleSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
    </div>
  );
};

export default AiTone;
