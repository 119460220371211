import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormikProps } from 'formik';
import {
  FormFieldTypeConstants,
  IMenuOption,
  ISchedulerEvents,
  ISurveySchedulerFormValues,
} from 'interfaces';
import * as React from 'react';
import { SCHEDULER_FREQUENCY_OPTIONS, WEEKDAYS } from 'teamble-constants';
import {
  getFilteredSchedulerFrequencyOptions,
  getFrequencyOptions,
  getScheduleEvents,
} from 'utils';


interface ISurveySchedulerWidgetProps {
  isLoading: boolean;
  surveySchedulerForm: FormikProps<ISurveySchedulerFormValues>;
  onSchedulerUpdate: () => void;
  onSchedulerClear: () => void;
}

const SurveySchedulerWidget: React.FunctionComponent<ISurveySchedulerWidgetProps> = (
  { isLoading, surveySchedulerForm, onSchedulerUpdate, onSchedulerClear }
) => {

  const [surveyFrequencyOptions, setSurveyFrequencyOptions] =
    React.useState<IMenuOption[]>(SCHEDULER_FREQUENCY_OPTIONS.options);

  const [surveySchedulerEvents, setSurveySchedulerEvents] =
    React.useState<ISchedulerEvents[]>([]);


  React.useEffect(
    () => {

      if (!surveySchedulerForm.values.schedulerDateTime) {
        setSurveyFrequencyOptions([]);
        return;
      }

      const dateTime = surveySchedulerForm.values.schedulerDateTime;
      const weekNumber = Math.ceil(dateTime.getDate() / 7);
      const weekDayName = WEEKDAYS[dateTime.getDay()];

      const filteredRepeatOptions =
        getFilteredSchedulerFrequencyOptions(dateTime, weekNumber);

      const surveyFrequencyOptions =
      getFrequencyOptions(filteredRepeatOptions, dateTime, weekDayName, weekNumber);

      const schedulerFrequency = surveySchedulerForm.values.schedulerFrequency;

      if (schedulerFrequency) {
        const newFrequency = surveyFrequencyOptions.find(
          (option) => option.value === schedulerFrequency.value
        );
        surveySchedulerForm.resetForm({
          values: {
            ...surveySchedulerForm.values,
            schedulerFrequency: newFrequency || null
          }
        });
      }

      setSurveyFrequencyOptions(surveyFrequencyOptions);

    },
    [surveySchedulerForm.values.schedulerDateTime]
  );


  React.useEffect(
    () => {

      if (
        !surveySchedulerForm.values.schedulerFrequency ||
        !surveySchedulerForm.values.schedulerDateTime
      ) {
        setSurveySchedulerEvents([]);
        return;
      }

      const schedulerEvents = getScheduleEvents(
        surveySchedulerForm.values.schedulerDateTime,
        surveySchedulerForm.values.schedulerFrequency.value
      );

      setSurveySchedulerEvents(schedulerEvents || []);

    },
    [
      surveySchedulerForm.values.schedulerFrequency,
      surveySchedulerForm.values.schedulerDateTime
    ]
  );


  return (
    <Card className='my-3'>
      <Card.Header className='bg-light py-2 px-3'>
        <Form.Label className='fw-bolder mb-0'> Survey Scheduler </Form.Label>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <FormField
              label='Start'
              controlId={`surveyAudienceFormSchedulerDateTime`}
              type={FormFieldTypeConstants.Datepicker}
              controlName='schedulerDateTime' />
          </Col>
          <Col>
            {
              <FormField
                label='Frequency'
                controlId={`surveyAudienceFormSchedulerFrequency`}
                type={FormFieldTypeConstants.Select}
                options={surveyFrequencyOptions}
                controlName='schedulerFrequency' />
            }
          </Col>
        </Row>
        {
          surveySchedulerEvents.length > 0 &&
          surveySchedulerEvents.map(
            (event, index) => {
              return (
                <p
                  key={index}
                  className='m-0'
                >
                  <span className='fw-bold'>{event.weekday}, {event.date}</span> at
                  <span className='fw-bold'> {' ' + event.time} </span>
                  {
                    event.warning &&
                    <span>, {event.warning}</span>
                  }
                </p>
              );
            }
          )
        }
      </Card.Body>

      <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
        <Button
          type='button'
          size='sm'
          variant='danger'
          disabled={isLoading}
          className='me-2'
          onClick={onSchedulerClear}> Clear Schedule </Button>
        <Button
          type='button'
          size='sm'
          disabled={isLoading}
          onClick={onSchedulerUpdate}> Save </Button>
      </Card.Footer>
    </Card>
  );
};

export default SurveySchedulerWidget;
