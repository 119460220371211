import { Nav, Tab } from '@themesberg/react-bootstrap';
import {
  EmptyState,
  FeedbackInsightsWidget,
  FeedbacksListTableWidget,
  PermissionCheck,
  RouteWithPermission,
  useUserProfile,
} from 'components';
import {
  FeedbackInsightTypes,
  IFeedbackTreemapChartData,
  IRoute,
  IRouteParams,
  IUserDetailsContext,
  UserPermissions,
} from 'interfaces';
import React from 'react';
import { NavLink, Redirect, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { Routes } from 'routes';

import { useUserDetails } from '../hooks/useUserDetails';
import UserDetailsPerformanceWidget from './UserDetailsPerformanceWidget';

interface IUserDetailsTabsContainerProps {
  context: string;
}


const UserDetailsTabsContainer: React.FC<IUserDetailsTabsContainerProps> = (
  { context }
) => {

  const { userId, groupId, relationshipId } = useParams<IRouteParams>();
  const { userProfile } = useUserProfile();
  const { userDetails } = useUserDetails() as IUserDetailsContext;

  // Route Hooks
  const history = useHistory();

  const navigationBarLink: string = {
    'myProfile': '/my-profile',
    'userDetails': Routes.Users.path,
    'myTeam': Routes.MyTeamPage.path,
    'myGroups': `${Routes.MyGroupsPage.path}/${groupId}/users`,
    'myTeams': `${Routes.MyTeamPage.path}/${relationshipId}/users`
  }[context] || Routes.Users.path;

  const routeWithPermissions: IRoute = {
    'myProfile': Routes.MyProfilePage,
    'userDetails': Routes.UserDetails,
    'myTeams': Routes.MyTeamProfilePage,
    'myGroups': Routes.MyGroupUserProfilePage
  }[context] || Routes.MyProfilePage;

  const location = useLocation();


  const handleTreemapChartClick = (data: IFeedbackTreemapChartData) => {
    history.push({
      pathname: `${navigationBarLink}/${userId}/feedback`,
      search: `?competency=${data.competencyId}&behaviour=${data.behaviourId || ''}&behaviourType=${data.behaviourType || ''}`
    })
  };



  return (
    <Tab.Container>
      <div className='d-flex flex-column overflow-hidden h-100'>
        <Nav className="nav-tabs flex-shrink-0">

          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to={{
                pathname: `${navigationBarLink}/${userId}/feedback-insights`,
                search: location.search
              }}
              className="mb-sm-3 mb-md-0"
            >
              Feedback Insights
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to={{
                pathname: `${navigationBarLink}/${userId}/feedback`,
                search: location.search
              }}
              className="mb-sm-3 mb-md-0"
            >
              Feedback
            </Nav.Link>
          </Nav.Item>

          {
            userProfile?.teambleUserId !== userDetails.teambleUserId &&
            <PermissionCheck permission={UserPermissions.ViewPerformanceTab}>
              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  to={{
                    pathname: `${navigationBarLink}/${userId}/performance`,
                    search: location.search
                  }}
                  className="mb-sm-3 mb-md-0"
                >
                  Performance
                </Nav.Link>
              </Nav.Item>
            </PermissionCheck>
          }

          {/* <Nav.Item>
            <Nav.Link
              as={NavLink}
              to={Routes.UserWorkflowDetails.path}
              className="mb-sm-3 mb-md-0"
            >
              Workflows
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
        {
          userId
            ?
            <Switch>
              <RouteWithPermission
                exact
                path={`${routeWithPermissions?.path}/feedback-insights`}
                permission={routeWithPermissions?.permission}
              >
                <div className='flex-grow-1 mt-2 overflow-hidden h-100'>
                  <FeedbackInsightsWidget
                    insightType={FeedbackInsightTypes.PERSONAL}
                    teambleUserId={userDetails.teambleUserId}
                    onTreemapChartClick={handleTreemapChartClick} />
                </div>
              </RouteWithPermission>

              <RouteWithPermission
                exact
                path={`${routeWithPermissions?.path}/feedback`}
                permission={routeWithPermissions?.permission}
              >
                <div className='flex-grow-1 mt-2 overflow-hidden h-100'>
                  <FeedbacksListTableWidget
                    profileVieweeTeambleUserId={userDetails.teambleUserId} />
                </div>
              </RouteWithPermission>

              {
                userProfile?.teambleUserId !== userDetails.teambleUserId &&
                <RouteWithPermission
                  exact
                  path={`${routeWithPermissions?.path}/performance`}
                  permission={routeWithPermissions?.permission}
                >
                  <div className='flex-grow-1 mt-2 overflow-hidden'>
                    <UserDetailsPerformanceWidget />
                  </div>
                </RouteWithPermission>
              }

              <Redirect
                exact
                from={`${navigationBarLink}/${userId}`}
                to={{
                  pathname: `${navigationBarLink}/${userId}/feedback-insights`,
                  search: location.search
                }} />
            </Switch>
            :
            <EmptyState message='No User data available.' />
        }

      </div>
    </Tab.Container>
  );
};

export default UserDetailsTabsContainer;