import Axios, { AxiosObservable } from 'axios-observable';
import { ICreateIntegrationRequestData, IIntegration, IResponse } from 'interfaces';

export const getIntegrationsList = (): AxiosObservable<IResponse<IIntegration[]>> => {

  return Axios.post<IResponse<IIntegration[]>>(
    '/get-integrations',
  );
};


export const createIntegration = (
  data: ICreateIntegrationRequestData
): AxiosObservable<IResponse<IIntegration>> => {

  return Axios.post<IResponse<IIntegration>>(
    '/create-integration',
    { ...data }
  );
};