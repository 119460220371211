import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Collapse, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormFieldTypeConstants } from 'interfaces';
import React from 'react';

interface ILaunchReviewScheduleConfigWidgetProps { }

const LaunchReviewScheduleConfigWidget: React.FC<ILaunchReviewScheduleConfigWidgetProps> = () => {

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

  return (
    <Card className='my-2'>

      <Card.Header
        aria-controls='launchReviewSchedulerWidget'
        aria-expanded={!isCollapsed}
        className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Card.Title className='m-0' as='h6'>
          Scheduler
        </Card.Title>
        <Button variant='link' size='sm'>
          <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
        </Button>
      </Card.Header>

      <Collapse in={!isCollapsed}>
        <Card.Body id='launchReviewSchedulerWidget'>
          <Row>
            <Col>
              <FormField
                label='Start Date'
                controlId='startDateField'
                isClearable
                type={FormFieldTypeConstants.Datepicker}
                controlName='startDate' />
            </Col>

            <Col>
              <FormField
                label='End Date'
                controlId='endDateField'
                isClearable
                type={FormFieldTypeConstants.Datepicker}
                controlName='endDate' />
            </Col>


            <Row>
              <Col>
                <FormField
                  label='Close on end date'
                  controlId='isAutomaticCloseOnEndDate'
                  type={FormFieldTypeConstants.Checkbox}
                  controlName='isAutomaticCloseOnEndDate'
                />
              </Col>
            </Row>

          </Row>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default LaunchReviewScheduleConfigWidget;