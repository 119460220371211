import { Button, Card } from '@themesberg/react-bootstrap';
import AnswersList from 'components/AnswersList';
import { IAnswer, IFeedback, IFeedbackMaps } from 'interfaces';
import React, { useEffect } from 'react';

import FeedbackDetailsModal from './../Modals/FeedbackDetailsModal';
import FeedbackSender from './FeedbackSender';
import FeedbackTags from './FeedbackTags';
import FeedbackTextPreview from './FeedbackText';
import FeedbackType from './FeedbackType';

interface IFeedbackCardProps {
  feedback: IFeedback;
  feedbackMaps: IFeedbackMaps;
}

const FeedbackCard: React.FC<IFeedbackCardProps> = (
  { feedback, feedbackMaps }
) => {

  const [showFeedbackDetailsModal, setShowFeedbackDetailsModal] = React.useState<boolean>(false);
  const [feedbackAnswerBlocks, setFeedbackAnswerBlocks] = React.useState<IAnswer[]>([]);


  useEffect(
    () => {

      if (!feedback.blocks?.length) {
        setFeedbackAnswerBlocks([]);
        return;
      }

      const ansBlocks = feedback.blocks
        .filter(
          (block) => !['title', 'sub-title'].includes(block.type || '')
        )
        .slice(0, 2);

      setFeedbackAnswerBlocks(ansBlocks);
    },
    [feedback.blocks]
  )


  return (
    <>
      <Card className='mb-3 card-shadow'>
        <Card.Body>

          <FeedbackType
            feedbackType={feedback.feedback_type}
            feedbackTypeHeader={feedback.feedbackTypeHeader}
          />
          <FeedbackSender feedback={feedback} />
          <FeedbackTags feedback={feedback} feedbackMaps={feedbackMaps} />

          <p>
            <strong>Sent At:</strong>
            <span> {new Date(feedback.createdAt).toLocaleString()} </span>
          </p>

          <div className='d-flex'>
            <div className='me-2 bg-gray rounded-3 flex-shrink-0' style={{ width: 4 }} />
            <div className='flex-grow-1 overflow-hidden'>
              {
                feedbackAnswerBlocks.length
                  ?
                  <AnswersList answers={feedbackAnswerBlocks} />
                  :
                  <FeedbackTextPreview isPreviewOnly feedback={feedback} />
              }
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <Button
              size='sm'
              variant='outline-primary'
              onClick={() => setShowFeedbackDetailsModal(true)}
            >
              Details
            </Button>
          </div>
        </Card.Body>
      </Card>
      {
        showFeedbackDetailsModal &&
        <FeedbackDetailsModal
          feedback={feedback}
          feedbackMaps={feedbackMaps}
          show={showFeedbackDetailsModal}
          onClose={() => setShowFeedbackDetailsModal(false)}
        />
      }
    </>
  );
};

export default FeedbackCard;