import { IShareReportFormValues } from './../interfaces/reports.interface';
import * as Yup from 'yup';
import { FormErrors, IMemberOption } from 'interfaces';
import { MEMBER_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';

export const SHARE_REPORT_VALIDATION_SCHEMA:
  Yup.SchemaOf<IShareReportFormValues> = Yup.object().shape({
    shareWithUsers: Yup.array()
      .of(MEMBER_MENU_OPTION_VALIDATION_SCHEMA as Yup.SchemaOf<IMemberOption>)
      .required(FormErrors.Required)
      .min(1, FormErrors.Required)
  });