import { signInWithPlatform } from 'data';
import { IAuthContextValue, SignInWith } from 'interfaces';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';

const AuthContext = React.createContext<IAuthContextValue | null>(null);

interface IProvideAuthProps {
  children: React.ReactNode;
}

const ProvideAuth: React.FunctionComponent<IProvideAuthProps> = ({ children }) => {

  const [isSignedIn, setIsSignedIn] = React.useState<boolean>(false);
  const [isTokenChecked, setIsTokenChecked] = React.useState<boolean>(false);

  const history = useHistory();

  let continueURL = '';
  const { hostname: currentHost } = new URL(window?.location.href);
  const { hostname: configuredHost } = new URL(process.env.REACT_APP_API_URL || "")

  if (currentHost === configuredHost) {
    continueURL = window?.location?.hash?.substring(1);
  }

  React.useEffect(
    () => {
      const token = localStorage.getItem('token');
      if (token && token.length) {
        setIsSignedIn(true);
      }
      setIsTokenChecked(true);
    },
    []
  );


  const signIn = (signInWith: SignInWith): void => {

    signInWithPlatform(signInWith, continueURL).subscribe(
      (response) => {
        if (response.data.data.redirect_url) {
          window.location.replace(response.data.data.redirect_url);
        }
      }
    );

  };


  const signOut = (): void => {
    localStorage.removeItem('token');
    setIsSignedIn(false);
    history.push(Routes.Signin.path);
  };


  return (
    <AuthContext.Provider value={{ isSignedIn, signIn, signOut, setIsSignedIn }}>
      {
        isTokenChecked &&
        <>
          {children}
        </>
      }
    </AuthContext.Provider>
  );
};

export default ProvideAuth;

export const useAuth = (): IAuthContextValue => {
  return React.useContext<IAuthContextValue | null>(AuthContext) as IAuthContextValue;
};