import { ISurvey } from "interfaces";
import React from "react";
import { Subscription } from "rxjs";
import { getSurveyDuration } from "utils";

export const useSurveyDuration = (
  surveyDetails: ISurvey | null,
): string => {

  const [surveyDuration, setSurveyDuration] = React.useState<string>('');

  React.useEffect(
    () => {

      let subscription: Subscription;

      const duration$ = getSurveyDuration(surveyDetails);

      if (typeof duration$ === 'string') {
        setSurveyDuration(duration$);
      } else {
        subscription = duration$.subscribe(
          (duration: string) => {
            setSurveyDuration(duration);
          },
        );
      }

      return () => {
        subscription && subscription.unsubscribe();
      }

    },
    [surveyDetails]
  );

  return surveyDuration;

}