import { ManualSortingProps, ManualSortingPropsTypes, SortBy, SortKeyType } from "interfaces";

export const getManualSortingProps = (
  sortKey: string,
  sortBy: SortBy,
  type: ManualSortingPropsTypes
): ManualSortingProps | undefined => {

  let sortKeyType: SortKeyType;

  switch (type) {

    case ManualSortingPropsTypes.Completion:
    case ManualSortingPropsTypes.ScheduledAt:
    case ManualSortingPropsTypes.CreatedAt: {
      sortKeyType = 'number';
      break;
    }

    default: {
      sortKeyType = 'string';
      break;
    }
  }

  return {
    sortKey: type,
    isSorted: sortKey === type,
    sortBy,
    sortKeyType
  };

};