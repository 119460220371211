import Axios, { AxiosObservable } from 'axios-observable';
import { IResponse, ISlackWorkspaceUser, IAdmin, ITeambleTeam } from 'interfaces';
import { map } from 'rxjs';

import { ISlackWorkspaceAdminsResponse } from './../interfaces/channelAndWorkspace.interface';


const getMappedAdminsList = (admins: ISlackWorkspaceAdminsResponse[]): ISlackWorkspaceUser[] => {
  return admins.map(
    (admin) => ({
      id: admin.id || admin.teamble_id,
      email: admin.email,
      name: admin.name,
      realName: admin.name,
      imageOriginal: admin.image,
      teamble_id: admin.teamble_id,
    })
  )
};


export const getTeamAdmins = (): AxiosObservable<IResponse<ITeambleTeam[]>> => {
  return Axios.post<IResponse<ITeambleTeam[]>>('/list-team-admin');
};


export const getSuperAdmins = (): AxiosObservable<IResponse<IAdmin[]>> => {
  return Axios.post<IResponse<IAdmin[]>>("/list-super-admin");
};


export const updateAdmins = (
  teambleUserIds: string[],
  type: 'super_admin' | 'workspace_admin',
  teambleTeamId?: string
): AxiosObservable<IResponse<ISlackWorkspaceUser[]>> => {

  return Axios.post<IResponse<ISlackWorkspaceAdminsResponse[]>>(
    "/update-admin",
    { teambleUserIds, type, teambleTeamId }
  ).pipe(
    map(
      (response) => {

        return {
          ...response,
          data: {
            ...response.data,
            data: getMappedAdminsList(response.data.data),
          }
        }
      }
    )
  );;
}