import { AxiosObservable } from 'axios-observable';
import {
  getCompanyUsers,
  getMsTeamsCompanyMembersAsMenuOptions,
  getMsTeamsTeamMembersAsMenuOptions,
  getSlackTeamMembersAsMenuOptions,
} from 'data';
import { useApp, useOrganizationMenuOptions$ } from 'hooks';
import { IMemberOption, IResponse, SignInWith } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { concatMap, finalize, map } from 'rxjs';


interface IMemberOptionsResponse {
  memberOptions: IMemberOption[];
  isLoading: boolean;
}


export const useTeamMemberMenuOptions$ = (
  teamId: string | undefined,
  getAll: boolean = false,
  isManagee: boolean = false
): AxiosObservable<IResponse<IMemberOption[]>> | null => {

  const app = useApp();

  const [teamMemberMenuOptions$, setTeamMemberMenuOptions$] =
    useState<AxiosObservable<IResponse<IMemberOption[]>> | null>(
      () => {
        if (!teamId) {
          return null;
        }

        return app === SignInWith.Slack ?
          getSlackTeamMembersAsMenuOptions([teamId], false, getAll, isManagee) :
          getMsTeamsTeamMembersAsMenuOptions(teamId);
      }
    );


  useEffect(
    () => {

      if (!teamId) {
        setTeamMemberMenuOptions$(null);
        return;
      }

      setTeamMemberMenuOptions$(
        app === SignInWith.Slack ?
          getSlackTeamMembersAsMenuOptions([teamId], false, getAll, isManagee) :
          getMsTeamsTeamMembersAsMenuOptions(teamId)
      );
    },
    [app, teamId, getAll, isManagee]
  );

  return teamMemberMenuOptions$;
};


export const useTeamMemberMenuOptions = (
  teamId: string | undefined
): IMemberOptionsResponse => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [memberOptions, setMemberOptions] = React.useState<IMemberOption[]>([]);

  const teamMemberMenuOptions$ = useTeamMemberMenuOptions$(teamId);


  useEffect(
    () => {

      if (!teamMemberMenuOptions$) {
        setIsLoading(false);
        setMemberOptions([]);
        return;
      }

      setIsLoading(true);
      setMemberOptions([]);

      const subscription = teamMemberMenuOptions$
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setMemberOptions(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [teamMemberMenuOptions$]
  );

  return { isLoading, memberOptions };
};


export const useCompanyMemberMenuOptions =
  (): IMemberOptionsResponse => {

    // State Hooks
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [memberOptions, setMemberOptions] = React.useState<IMemberOption[]>([]);

    // App hook
    const app = useApp();

    const organizationMenuOptions$ = useOrganizationMenuOptions$();


    useEffect(
      () => {

        setIsLoading(true);
        setMemberOptions([]);

        const memberOptions$ = app === SignInWith.Slack
          ?
          organizationMenuOptions$.pipe(
            map(
              (response) =>
                response.data.data.map(
                  (option) => option.value
                )
            ),
            concatMap(
              (organizationIds) => getSlackTeamMembersAsMenuOptions(organizationIds)
            )
          )
          :
          getMsTeamsCompanyMembersAsMenuOptions();

        const subscription = memberOptions$?.pipe(
          finalize(() => setIsLoading(false))
        )
          .subscribe(
            (response) => {
              setMemberOptions(response.data.data);
            }
          );

        return () => subscription?.unsubscribe();
      },
      [app, organizationMenuOptions$]
    );

    return { isLoading, memberOptions };
  };


// new hook to fetch only company users.
export const useCompanyUsersMemberOptions = (): IMemberOptionsResponse => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [memberOptions, setMemberOptions] = React.useState<IMemberOption[]>([]);

  React.useEffect(
    () => {

      setIsLoading(true);

      const subscription = getCompanyUsers()
        .pipe(
          finalize(() => setIsLoading(false))
        ).subscribe((reviewerResponse) => {
          const reviewerOptions = reviewerResponse.data.data.map(data => ({
            value: data.id,
            label: data.name,
            email: data.email,
          }));
          setMemberOptions(reviewerOptions);
        });

      return () => subscription.unsubscribe();

    }, []);

  return { isLoading, memberOptions };

};