import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { ConfirmationModal, FormField } from 'components';
import { FormContext, SelectedWorkspaceOrChannelContext } from 'contexts';
import { getReportsByPerformanceReviewIds } from 'data';
import { FormikHelpers, useFormik } from 'formik';
import { useApp, useCompanyUsersMemberOptions, useTeamMemberMenuOptions } from 'hooks';
import {
  FormFieldTypeConstants,
  IChannelMenuOption,
  IModalProps,
  IReport,
  IReportTableRow,
  IResponse,
  IShareReportFormValues,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { SHARE_REPORT_VALIDATION_SCHEMA } from 'validation-schemas';

import { getPerformanceReviewIdsOfReportRows } from '../utils';

interface IShareReportModalProps extends IModalProps {
  reportTableRow: IReportTableRow;
  bulkReportIds?: string[]
  workspaceId?: string;
}

interface IShareReportFormProps {
  reportTableRow: IReportTableRow;
  bulkReportIds?: string[]
  workspaceId?: string;
  onClose: IModalProps['onClose'];
}


const ShareReportForm: React.FunctionComponent<IShareReportFormProps> = ({
  reportTableRow,
  onClose,
  workspaceId,
  bulkReportIds
}) => {

  // const selectedWorkspaceOrChannel = React.useContext<IChannelMenuOption>(
  //   SelectedWorkspaceOrChannelContext as React.Context<IChannelMenuOption>
  // );
  // const { memberOptions } = useTeamMemberMenuOptions(selectedWorkspaceOrChannel?.value ?? workspaceId);

  const { memberOptions, isLoading } = useCompanyUsersMemberOptions();
  const app = useApp();

  const [confimShare, setConfirmShare] = React.useState<boolean>(false);

  const shareReport = (
    values: IShareReportFormValues, formikHelpers: FormikHelpers<IShareReportFormValues>
  ) => {

    let performanceReviewIds: string[] = [];
    if (reportTableRow) {
      performanceReviewIds = getPerformanceReviewIdsOfReportRows(reportTableRow);
    } else {
      performanceReviewIds = bulkReportIds as string[];
    }

    if (!performanceReviewIds.length) {
      return;
    }

    formikHelpers.setSubmitting(true);

    getReportsByPerformanceReviewIds(
      performanceReviewIds,
      false,
      true,
      values.shareWithUsers.map((user) => user.email as string),
      undefined,
      app,
      values.shareWithUsers.map((user) => user.value as string),
      true // share to my reviews view.
    ).pipe(
      finalize(
        () => formikHelpers.setSubmitting(false)
      )
    ).subscribe(
      (response) => {
        toast.success((response.data as IResponse<IReport[]>).message);
        onClose();
      }
    );

  };


  const shareReportForm = useFormik<IShareReportFormValues>({
    initialValues: {
      shareWithUsers: []
    },
    validationSchema: SHARE_REPORT_VALIDATION_SCHEMA,
    onSubmit: shareReport
  });


  return (
    <>
      {
        !confimShare && <Form>
          <Modal.Body style={{ minHeight: 300 }}>
            <FormContext.Provider value={shareReportForm}>
              <FormField
                multiple
                size='sm'
                isLoading={isLoading}
                label='Share with *'
                controlName='shareWithUsers'
                options={memberOptions}
                placeholder='Select users to share with'
                controlId='shareReportFormUsersField'
                type={FormFieldTypeConstants.Select}
                fieldDescription={app === SignInWith.Slack ? 'Shares report via a slack message' : ''} />
            </FormContext.Provider>
          </Modal.Body>
          <Modal.Footer className="justify-content-end px-4">
            <Button type='button' variant="outline-primary" onClick={onClose}>
              Close
            </Button>
            <Button onClick={() => shareReportForm.values.shareWithUsers.length && setConfirmShare(true)}
              variant="primary" disabled={shareReportForm.isSubmitting}>
              {shareReportForm.isSubmitting ? 'Sharing...' : 'Share'}
            </Button>
          </Modal.Footer>
        </Form>
      }

      {
        confimShare &&
        <ConfirmationModal
          title='Confirm share ?'
          show={confimShare}
          onPositiveResponse={() => {
            shareReportForm.submitForm().finally(() => {
              onClose();
            })
          }}
          onCloseClick={() => setConfirmShare(false)}
        >
          <p>
            Are you sure to share the report with {shareReportForm.values.shareWithUsers.map(user => user.label).join(", ")} ?
          </p>
        </ConfirmationModal >
      }

    </>
  );

};


const ShareReportModal: React.FunctionComponent<IShareReportModalProps> = ({
  reportTableRow,
  show,
  onClose,
  workspaceId,
  bulkReportIds
}) => {

  return (
    <>
      {
        show &&
        <Modal
          as={Modal.Dialog}
          centered
          show={show}
          onHide={onClose}
          scrollable
        >

          <Modal.Header
            closeButton
            className="px-4"
          >
            <Modal.Title>
              Share Report
            </Modal.Title>
          </Modal.Header>

          <ShareReportForm workspaceId={workspaceId} onClose={onClose} reportTableRow={reportTableRow} bulkReportIds={bulkReportIds} />

        </Modal>
      }
    </>
  );
};

export default ShareReportModal;
