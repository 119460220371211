import { Table, useTmblTable, useTmblTableData } from '@common/modules/table';
import { Card } from '@themesberg/react-bootstrap';
import { DataState } from 'components';
import { getReviewScores } from 'data';
import { IReviewScore, IUserDetailsContext } from 'interfaces';
import React from 'react';
import { Subscription, finalize } from 'rxjs';
import { DelimitedArrayParam, useQueryParam } from 'use-query-params';

import { useReviewScoreTableColumns } from '../hooks/useReviewScoreTableColumns';
import MemberProfileReviewScoreCardHeader from './MemberProfileReviewScoreCardHeader';
import { useUserDetails } from '../hooks/useUserDetails';

interface IMemberProfileReviewScoreCardProps { }

const MemberProfileReviewScoreCard: React.FC<IMemberProfileReviewScoreCardProps> = () => {

  /* State hooks */
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reviewScoreList, setReviewScoreList] = React.useState<IReviewScore[]>([]);

  /* Route Hooks */
  const [reviewIdsQueryParam] = useQueryParam('reviews', DelimitedArrayParam);

  /* Table Hooks */
  const columns = useReviewScoreTableColumns(reviewScoreList);
  const data = useTmblTableData<IReviewScore>(reviewScoreList);
  const tableInstance = useTmblTable<IReviewScore>(
    columns,
    data,
    'question',
  );

  /* User Details Hook */
  const { userDetails } = useUserDetails() as IUserDetailsContext;


  // Get the score of the selected performance reviews
  React.useEffect(
    () => {

      const reviewIds = reviewIdsQueryParam?.filter((id) => !!id) as string[];

      if (!reviewIds?.length) {
        setIsLoading(false);
        setReviewScoreList([]);
        return;
      }

      let subscription: Subscription | undefined;
      // Debouncing the selection of reviewIds
      const timeoutId = setTimeout(
        () => {
          setIsLoading(true);
          subscription = getReviewScores(reviewIds, userDetails.teambleUserId as string)
            .pipe(
              finalize(() => setIsLoading(false))
            )
            .subscribe(
              (response) => {
                setReviewScoreList(response.data.data);
              }
            );
        },
        300
      );

      return () => {
        clearTimeout(timeoutId);
        subscription?.unsubscribe();
      };
    },
    [reviewIdsQueryParam, userDetails.teambleUserId]
  );


  return (
    <Card className='h-100'>
      <MemberProfileReviewScoreCardHeader />

      <Card.Body
        style={{ height: isLoading || !tableInstance.rows.length ? 100 : '' }}
        className='position-relative flex-grow-1 overflow-y-auto z-index-1'>
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!tableInstance.rows.length}
          emptyStateMessage={
            reviewIdsQueryParam?.length
              ?
              'Score is not available for the selected performance reviews!'
              :
              'Please select performance reviews to view the score!'
          }
        >
          <Table
            stickyHeader={false}
            tableInstance={tableInstance} />
        </DataState>
      </Card.Body>
    </Card>
  );
};

export default MemberProfileReviewScoreCard;