import React, { useRef, useState } from "react";
import "./AttributesBadgesCard.scss";
import HeaderText from "../../../../components/HeaderText/HeaderText";
import AiTagButton from "../../../../components/AiTagButton/AiTagButton";
import PlusIcon from "../../../../components/SvgReactIcons/PlusIcon";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import useOutsideClick from "hooks/useOutsideClick";
import { CompetencyList, CompetencyListProps } from "modules/1-on-1s/types";

interface AttributesBadgesCardProps {
  labels: string;
  options: CompetencyList[];
  selectedList: CompetencyListProps[];
  onClick?: (item?: CompetencyList) => void;
}

const AttributesBadgesCard: React.FC<AttributesBadgesCardProps> = ({
  labels,
  options,
  selectedList,
  onClick,
}) => {
  const [showOptionsCard, setShowOptionsCard] = useState(false);
  const cardRef = useRef(null);

  useOutsideClick(() => {
    setShowOptionsCard(false);
  }, cardRef);
  return (
    <div className="AttributesBadgesCard">
      <HeaderText
        text={labels}
        fontSize={"15px"}
        lineHeight={"15px"}
        letterSpacing={"-0.075px"}
      />
      <div className="badge-container">
        {options?.map((item: any, index: number) => {
          console.log(
            selectedList.find((val) => val.id === item.id),
            item.id
          );
          return (
            <AiTagButton
              name={item?.name}
              key={index}
              variant={
                selectedList.find((val) => val.id === item.id)?.tag === "ai"
                  ? "regular"
                  : "purple"
              }
              selected={!!selectedList.find((val) => val.id === item.id)}
              onClick={(name) => {
                onClick?.(options?.find((val) => val?.name === name));
              }}
            />
          );
        })}
        {/* fix this later for msteams */}
        {/* <div
                    className='plusIcon'
                    onClick={() => {
                        setShowOptionsCard(!showOptionsCard);
                    }}
                >
                    <PlusIcon />
                    {showOptionsCard && (
                        <div
                            ref={cardRef}
                            onMouseLeave={() => {
                                setShowOptionsCard(false);
                            }}
                            className='options-card'
                        >
                            <div className='header'>
                                <HeaderText
                                    text={'Strengths'}
                                    fontSize={'18px'}
                                    lineHeight={'18px'}
                                    letterSpacing={'-0.18px;'}
                                />
                                <PrimaryButton text={'Done'} />
                            </div>
                            <div className='body-container'>
                                {options?.map((item, index) => (
                                    <div className='btn-wrapper'>
                                        <AiTagButton
                                            name={item}
                                            key={index}
                                            variant={'purple'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div> */}
      </div>
    </div>
  );
};

export default AttributesBadgesCard;
