import { faInfoCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from '@themesberg/react-bootstrap';
import { CustomDropdown, FormField } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { FormikProps } from 'formik';
import { useApp } from 'hooks';
import {
  FormFieldTypeConstants, IChoiceWithValue, IMenuOption, IMetadata,
  IMultiSelectQuestionOptionsTypeConstants, ISurveyBuilderBlock,
  ISurveyBuilderFormValues,
  ISurveyGroupBlockValue,
  ISurveyQuestionTypeConstants,
  SignInWith
} from 'interfaces';
import * as React from 'react';
import { ISurveyPreviewQuestionIndexState, SurveyPreviewQuestionIndexContext } from '../contexts/SurveyPreviewQuestionIndex';
import { SurveyQuestionParentGroupContext } from '../contexts/SurveyQuestionParentGroupContext';
import { getNewSurveyQuestion } from '../utils/utils';
import SurveyQuestionsAccordion from './SurveyQuestionsAccordion';
import { SurveyInvalidGroupQuestionIndexContext } from '../contexts/SurveyInvalidGroupQuestionIndexContext';


interface ISurveyBlockProps {
  index: number;
  block: ISurveyBuilderBlock;
}


const SurveyStaticTextBlock: React.FunctionComponent<ISurveyBlockProps> = (
  { index, block }
) => {

  const app = useApp();

  const groupContext = React.useContext(SurveyQuestionParentGroupContext);

  return (
    <>
      {
        block.isLocked
          ?
          <p dangerouslySetInnerHTML={{
            __html: block.staticTextValue?.value || ''
          }}></p>
          :
          <FormField
            label=''
            controlId={`titleBlock-${index}`}
            type={app === SignInWith.Slack ? FormFieldTypeConstants.RichText : FormFieldTypeConstants.Text}
            placeholder='Enter title'
            controlName={
              groupContext.isGroup ?
                `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].staticTextValue.value` :
                `blocks[${index}].staticTextValue.value`} />
      }
    </>
  );
};


const SurveyInputTextBlock: React.FunctionComponent<ISurveyBlockProps> = (
  { index, block }
) => {

  const groupContext = React.useContext(SurveyQuestionParentGroupContext);

  return (
    <>
      {
        block.isLocked
          ?
          <p dangerouslySetInnerHTML={{
            __html: block.inputTextValue?.label || ''
          }}></p>
          :
          <>
            <FormField
              label='Question *'
              controlId={`shortTextBlockLabel-${index}`}
              type={FormFieldTypeConstants.Textarea}
              placeholder='Enter question'
              controlName={
                groupContext.isGroup ?
                  `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].inputTextValue.label` :
                  `blocks[${index}].inputTextValue.label`} />

            <FormField
              label='Answer Guidance'
              controlId={`shortTextBlockDescription-${index}`}
              type={FormFieldTypeConstants.Text}
              placeholder='Enter answer guidance'
              controlName={
                groupContext.isGroup ?
                  `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].inputTextValue.placeholder` :
                  `blocks[${index}].inputTextValue.placeholder`} />

            <FormField
              label='Required'
              controlId={`shortTextBlockRequired-${index}`}
              type={FormFieldTypeConstants.Checkbox}
              controlName={
                groupContext.isGroup ?
                  `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].inputTextValue.required` :
                  `blocks[${index}].inputTextValue.required`} />
          </>
      }
    </>
  );
};


const SurveyMultiSelectBlock: React.FunctionComponent<ISurveyBlockProps> = (
  { index, block }
) => {

  const app = useApp();

  const metadata = React.useContext(MetaDataContext) as IMetadata;
  const groupContext = React.useContext(SurveyQuestionParentGroupContext);
  const surveyForm = React.useContext(FormContext) as FormikProps<ISurveyBuilderFormValues>;

  const multiSelectQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  if (index === undefined) {
    return null;
  }


  const updateSurveyBlockWithNewChoices = (newChoices: IChoiceWithValue[]): void => {

    const updatedBlock = {
      ...block,
      multiSelectValue: {
        ...block.multiSelectValue,
        choices: newChoices
      }
    };

    let updatedBlocks;
    if (groupContext.isGroup) {

      const surveyFormBlockToUpdate = surveyForm.values.blocks[groupContext.groupIndex as number];

      const updatedGroupBlocks = {
        ...surveyFormBlockToUpdate,
        groupValue: {
          ...surveyFormBlockToUpdate.groupValue,
          questions: [
            ...surveyFormBlockToUpdate.groupValue!.questions.slice(0, index),
            updatedBlock,
            ...surveyFormBlockToUpdate.groupValue!.questions.slice(index + 1),
          ]
        },
      };

      updatedBlocks = [
        ...surveyForm.values.blocks.slice(0, groupContext.groupIndex as number),
        updatedGroupBlocks,
        ...surveyForm.values.blocks.slice((groupContext.groupIndex as number) + 1)
      ];

    } else {
      updatedBlocks = [
        ...surveyForm.values.blocks.slice(0, index),
        updatedBlock,
        ...surveyForm.values.blocks.slice(index + 1)
      ];
    }

    surveyForm.setFieldValue(
      'blocks',
      updatedBlocks,
      true
    );
  }


  const addNewChoice = () => {

    const newChoices = [
      ...block.multiSelectValue!.choices,
      {
        value: undefined,
        choice: ''
      }
    ];

    updateSurveyBlockWithNewChoices(newChoices as IChoiceWithValue[]);
  };


  const removeChoiceFromIndex = (removeIndex: number): void => {

    const newChoices = [
      ...block.multiSelectValue!.choices.slice(0, removeIndex),
      ...block.multiSelectValue!.choices.slice(removeIndex + 1)
    ];

    updateSurveyBlockWithNewChoices(newChoices as IChoiceWithValue[]);
  }


  return (
    <>
      {
        block.isLocked
          ?
          <>
            <p dangerouslySetInnerHTML={{
              __html: block.multiSelectValue?.label || ''
            }} />
            {
              block.multiSelectValue?.track
              &&
              <p className='text-info'>
                <FontAwesomeIcon icon={faInfoCircle} />
                <span> This question is locked because it is trackable. </span>
              </p>
            }
          </>
          :
          <>
            <FormField
              label='Question *'
              controlId={`multiSelectBlockLabel-${index}`}
              type={FormFieldTypeConstants.Textarea}
              placeholder='Enter question'
              controlName={
                groupContext.isGroup ?
                  `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.label` :
                  `blocks[${index}].multiSelectValue.label`} />

            <FormField
              label='Predefined Options'
              controlId={`multiSelectBlockOptions-${index}`}
              type={FormFieldTypeConstants.Select}
              options={metadata.multiSelectQuestionOptionsTypes.options.map(
                (option) => ({ value: option.value, label: option.label })
              )}
              controlName={
                groupContext.isGroup ?
                  `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.selectedOption` :
                  `blocks[${index}].multiSelectValue.selectedOption`} />

            {
              (
                block.multiSelectValue?.selectedOption.value ===
                multiSelectQuestionTypeConstants.CUSTOM_ITEMS
              ) &&
              <>
                {
                  app === SignInWith.Slack
                    ?
                    <div className='mb-2'>
                      {
                        (block.multiSelectValue.choices as IChoiceWithValue[])?.map(
                          (_, choiceIndex) => (
                            <div key={choiceIndex} className='d-flex align-items-end justify-content-between'>
                              <Row>
                                <Col>
                                  <FormField
                                    label='Choice *'
                                    placeholder='Enter choice here'
                                    type={FormFieldTypeConstants.Text}
                                    controlId={`multiSelectQuestionChoices-${index}-${choiceIndex}`}
                                    controlName={
                                      groupContext.isGroup ?
                                        `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.choices[${choiceIndex}].choice` :
                                        `blocks[${index}].multiSelectValue.choices[${choiceIndex}].choice`}
                                  />
                                </Col>
                                <Col>
                                  <FormField
                                    minValue={0}
                                    label='Choice Value *'
                                    placeholder='Enter choice value'
                                    type={FormFieldTypeConstants.Number}
                                    controlId={`multiSelectQuestionChoicesValue-${index}-${choiceIndex}`}
                                    controlName={
                                      groupContext.isGroup ?
                                        `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.choices[${choiceIndex}].value` :
                                        `blocks[${index}].multiSelectValue.choices[${choiceIndex}].value`}
                                  />
                                </Col>
                              </Row>
                              <Button
                                size='sm'
                                className={`outline-primary ms-2 mb-4 ${choiceIndex === 0 ? 'invisible' : ''}`}
                                onClick={() => removeChoiceFromIndex(choiceIndex)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </div>
                          )
                        )
                      }
                      <div className="d-flex justify-content-end">
                        <Button size='sm' onClick={addNewChoice}>
                          Add New Choice
                        </Button>
                      </div>
                    </div>
                    :
                    <FormField
                      label='Choices *'
                      controlId={`multiSelectBlockChoices-${index}`}
                      type={FormFieldTypeConstants.Textarea}
                      controlName={`blocks[${index}].multiSelectValue.choices`}
                      description='Add each choice on a new line.' />
                }
              </>
            }

            {
              app === SignInWith.Slack &&
              <>
                {
                  block.multiSelectValue?.commentBox &&
                  <FormField
                    label='Comment Box Guideline *'
                    controlId={`multiSelectQuestionCommentBox-${index}`}
                    type={FormFieldTypeConstants.Text}
                    placeholder='Enter Comment Box Guideline'
                    controlName={
                      groupContext.isGroup ?
                        `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.commentBoxGuide` :
                        `blocks[${index}].multiSelectValue.commentBoxGuide`} />
                }

                <FormField
                  label='Add Comment Box'
                  controlId={`multiSelectQuestionComment-${index}`}
                  type={FormFieldTypeConstants.Checkbox}
                  controlName={
                    groupContext.isGroup ?
                      `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.commentBox` :
                      `blocks[${index}].multiSelectValue.commentBox`} />
              </>
            }

            <FormField
              label='Required'
              controlId={`multiSelectBlockRequired-${index}`}
              type={FormFieldTypeConstants.Checkbox}
              controlName={
                groupContext.isGroup ?
                  `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.required` :
                  `blocks[${index}].multiSelectValue.required`} />

            <FormField
              label='Dropdown'
              controlId={`multiSelectBlockDropdown-${index}`}
              type={FormFieldTypeConstants.Checkbox}
              controlName={
                groupContext.isGroup ?
                  `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.dropdown` :
                  `blocks[${index}].multiSelectValue.dropdown`} />

            {
              app === SignInWith.Slack &&
              <FormField
                label='Allow multiple answers'
                controlId={`multiSelectBlockMultipleAnswers-${index}`}
                type={FormFieldTypeConstants.Checkbox}
                controlName={
                  groupContext.isGroup ?
                    `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.multipleAnswers` :
                    `blocks[${index}].multiSelectValue.multipleAnswers`} />
            }

            {
              (
                app === SignInWith.Slack ||
                block.multiSelectValue?.selectedOption.value !==
                multiSelectQuestionTypeConstants.CUSTOM_ITEMS
              ) &&
              <FormField
                label='Track'
                controlId={`multiSelectBlockTrack-${index}`}
                type={FormFieldTypeConstants.Checkbox}
                fieldDescription={
                  block.multiSelectValue?.track
                    ?
                    <p className='text-info'>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      <span> Please note that this question will be permanently locked after it is saved and changes won't be allowed. </span>
                    </p>
                    :
                    null
                }
                controlName={
                  groupContext.isGroup ?
                    `blocks[${groupContext.groupIndex}].groupValue.questions[${index}].multiSelectValue.track` :
                    `blocks[${index}].multiSelectValue.track`} />
            }
          </>
      }
    </>
  );
};


const SurveyGroupBlock: React.FunctionComponent<ISurveyBlockProps> = (
  { index, block }
) => {

  const [activeQuestionIndex, setActiveQuestionIndex] = React.useState<string>('');
  const [questionTypeOptions, setQuestionTypeOptions] = React.useState<IMenuOption[]>([]);
  const [tempQuestionIdIndex, setTempQuestionIdIndex] = React.useState<number>(block.groupValue?.questions.length || 0);

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;
  const surveyForm = React.useContext(FormContext) as FormikProps<ISurveyBuilderFormValues>;
  const previewQuestionIndexContext =
    React.useContext(SurveyPreviewQuestionIndexContext) as ISurveyPreviewQuestionIndexState;
  const invalidGroupQuestionIndexContext = React.useContext(SurveyInvalidGroupQuestionIndexContext);

  const app = useApp();


  React.useEffect(
    () => {

      const options = [...metadata.surveyQuestionTypes.options];
      options.splice(options.length - 1, 1);
      setQuestionTypeOptions(options);

    },
    [metadata.surveyQuestionTypes.options]
  );


  React.useEffect(
    () => {
      if (!invalidGroupQuestionIndexContext || invalidGroupQuestionIndexContext.index === '') {
        return;
      }

      setActiveQuestionIndex(invalidGroupQuestionIndexContext.index);
      invalidGroupQuestionIndexContext.setIndex('');
    },
    [invalidGroupQuestionIndexContext]
  );


  const updateQuestionBlock = (): void => {
    surveyForm.setFieldValue(
      'blocks',
      [
        ...surveyForm.values.blocks.slice(0, index),
        block,
        ...surveyForm.values.blocks.slice(index + 1)
      ],
      true
    );
  };


  const handleQuestionAdd = (option: IMenuOption) => {

    const questionTypeConstants: ISurveyQuestionTypeConstants =
      metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

    const newQuestion = getNewSurveyQuestion(
      (tempQuestionIdIndex + 1).toString(),
      option,
      questionTypeConstants,
      metadata.multiSelectQuestionOptionsTypes.options,
      app
    );

    setTempQuestionIdIndex(tempQuestionIdIndex + 1);

    block.groupValue!.questions.push(
      newQuestion as ISurveyGroupBlockValue['questions'][0]
    );

    updateQuestionBlock();

    let indexToSet = '';
    indexToSet = block.groupValue!.questions.length
      ?
      `group_${index}_${block.groupValue!.questions.length - 1}`
      :
      `group_${index}_0`;

    setActiveQuestionIndex(indexToSet);
    previewQuestionIndexContext.setIndex(indexToSet);

  };


  const handleAccordionToggle = (index: string) => {

    if (index === activeQuestionIndex) {

      setActiveQuestionIndex('');
      previewQuestionIndexContext.setIndex('');
    } else {
      setActiveQuestionIndex(index);
      previewQuestionIndexContext.setIndex(index);
    }

  };


  const handleQuestionUpdate = (
    questions: ISurveyBuilderBlock[],
    index: string,
    ref: React.RefObject<HTMLDivElement> | null
  ): void => {

    block.groupValue!.questions = [...questions];
    updateQuestionBlock();

    if (index !== '') {
      setActiveQuestionIndex(index);
      previewQuestionIndexContext.setIndex(index);
    }

    setTimeout(() => {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 500);

  };


  return (
    <>
      {
        block.isLocked
          ?
          <p>{block.groupValue?.label}</p>
          :
          <>
            <FormField
              label='Name *'
              placeholder='Enter group name'
              controlId={`groupLabel-${index}`}
              type={FormFieldTypeConstants.Text}
              controlName={`blocks[${index}].groupValue.label`} />
            <FormField
              label='Description'
              placeholder='Enter group description'
              controlId={`groupDescription-${index}`}
              type={FormFieldTypeConstants.Text}
              controlName={`blocks[${index}].groupValue.description`} />
            <div className='d-flex justify-content-end my-2'>
              {
                questionTypeOptions.length &&
                <CustomDropdown
                  size='sm'
                  icon={faPlus}
                  title='Add Question'
                  variant='outline-primary'
                  id='add-survey-question-to-group-dropdown'
                  options={questionTypeOptions}
                  onChange={handleQuestionAdd}
                />
              }
            </div>
            {
              block.groupValue?.questions.length
                ?
                (
                  <SurveyQuestionParentGroupContext.Provider value={
                    {
                      groupIndex: index,
                      isGroup: true,
                    }
                  }>
                    <SurveyQuestionsAccordion
                      surveyBlockTempIdIndex={tempQuestionIdIndex}
                      questions={block.groupValue?.questions}
                      activeQuestionIndex={activeQuestionIndex}
                      onAccordionToggle={handleAccordionToggle}
                      setSurveyBlockTempIdIndex={setTempQuestionIdIndex}
                      onQuestionsUpdate={handleQuestionUpdate} />
                  </SurveyQuestionParentGroupContext.Provider>
                )
                :
                (null)
            }
          </>
      }
    </>
  )
};


const SurveyBlock: React.FunctionComponent<ISurveyBlockProps> = (
  { index, block }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  let BlockComponent: React.FunctionComponent<ISurveyBlockProps> | null = null;
  const questionTypeConstants: ISurveyQuestionTypeConstants =
    metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;


  switch (block.type) {

    case questionTypeConstants.STATIC_TEXT: {
      BlockComponent = SurveyStaticTextBlock;
      break;
    }

    case questionTypeConstants.TEXT_INPUT: {
      BlockComponent = SurveyInputTextBlock;
      break;
    }

    case questionTypeConstants.CHOICE: {
      BlockComponent = SurveyMultiSelectBlock;
      break;
    }

    case questionTypeConstants.GROUP: {
      BlockComponent = SurveyGroupBlock;
      break;
    }

  }


  if (BlockComponent) {
    return (
      <BlockComponent
        index={index}
        block={block} />
    );
  }

  return null;
};

export default SurveyBlock;
