import { Button, Card } from '@themesberg/react-bootstrap';
import TeamOneToOneFeedbackBanner from 'assets/img/banners/1_1_feedback_banner.png';
import TeambleLogo from 'assets/img/brand/logo.png';
import { useApp } from 'hooks';
import { FeedbackForAudienceTypes, SignInWith } from 'interfaces';
import moment from 'moment';
import React from 'react';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import { useFeedbackForSenderReceiverPreview } from '../hooks/useFeedbackForSenderReceiverPreview';

interface IFeedbackLoopNotificationPreviewWidgetProps {
}

const FeedbackLoopNotificationPreviewWidget: React.FC<IFeedbackLoopNotificationPreviewWidgetProps> = () => {

  const { feedbackLoop, feedbackForAudiencePairs } = useFeedbackLoopConfig();

  const app = useApp();

  const [feedbackForSender, feedbackForReceivers] = useFeedbackForSenderReceiverPreview(
    feedbackLoop.feedbackFor,
    feedbackForAudiencePairs,
  );


  return (
    <Card className='w-100 font-family-lato'>
      <Card.Body className='d-flex'>

        <div className='flex-shrink-0'>
          {
            app === SignInWith.Slack
              ?
              <img
                height='40'
                width='40'
                src={TeambleLogo}
                className='rounded-3'
                alt="Teamble Blue Gradient logo" />
              :
              <img
                height='20'
                width='40'
                src={TeambleLogo}
                className='teams-hexagon'
                alt="Teamble Blue Gradient logo" />
          }
        </div>

        <div className='flex-grow-1 ms-3'>
          <p className='font-family-lato lh-1'>
            {
              app === SignInWith.Slack
                ?
                <>
                  <strong> teamble </strong>
                  <span className='slack-app-badge me-1'>APP</span>
                </>
                :
                <strong> Teamble </strong>
            }

            <span> {moment().format('h:mm A')} </span>
          </p>

          <div className='mt-4'>
            <img
              width='auto'
              src={TeamOneToOneFeedbackBanner}
              className='mb-2'
              alt="Teamble 1:1 Feedback Banner" />

            <h5 className='font-family-lato'>
              {feedbackLoop.title}
            </h5>

            <div className='mb-3'>
              <p className='font-family-lato m-0'>
                Hello @{feedbackForSender} 👋
              </p>
              <p className='font-family-lato m-0'>
                {feedbackLoop.notificationMessage}
              </p>
            </div>

          </div>

          <div>

            {
              feedbackForReceivers.length
                ?
                <p className='font-family-lato m-0'>
                  <strong>
                    <span role='img' aria-label='Feedback For'> 👥 </span> Feedback is for:
                  </strong>
                  {
                    (feedbackForReceivers as string[]).map(
                      (user, index) => (
                        <span key={user} className='ms-2'>
                          @{user}{index === feedbackForReceivers.length - 1 ? '' : ','}
                        </span>
                      )
                    )
                  }
                </p>
                :
                null
            }

            <Button
              className='mt-3'
              variant={app === SignInWith.Slack ? 'success' : 'outline-info'}
              size='sm'
            >
              <span role='img' aria-label='Feedback Bulb'> 💡 </span> Give Feedback
            </Button>
            <Button
              className='ms-2 mt-3'
              variant={app === SignInWith.Slack ? 'outline-primary' : 'outline-info'}
              size='sm'
            >
              Dismiss
            </Button>
          </div>

        </div>

      </Card.Body>
    </Card >
  );
};

export default FeedbackLoopNotificationPreviewWidget;