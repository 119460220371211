import HeaderText from "modules/1-on-1s/components/HeaderText/HeaderText";
import { convertDate } from "modules/1-on-1s/utils";
import React, { useContext, useEffect, useState } from "react";
import { oneOnOneDetailListProps } from "modules/1-on-1s/types";
import "./OneOnOnePrepSurvey.scss";
import PrimaryButton from "modules/1-on-1s/components/PrimaryButton/PrimaryButton";
import { useUserProfile } from "components";
import EditFormModal from "./EditFormModal/EditFormModal";
import { getOneOnOneDetails } from "data/teamble-ai-one-on-one";
import { MetaDataContext } from "contexts";
import { IMetadata } from "interfaces";

interface OneOnOnePrepSurveyProps {
  oneOnOneId: string;
  data: oneOnOneDetailListProps | null;
}

const OneOnOnePrepSurvey: React.FC<OneOnOnePrepSurveyProps> = ({
  data,
  oneOnOneId,
}) => {
  const { userProfile } = useUserProfile();
  const {
    multiSelectQuestionOptionsTypes: { options },
  } = useContext(MetaDataContext) as IMetadata;

  // states
  const [showEditForm, setShowEditForm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const isEditEnabled = [data?.reviewerId, data?.revieweeId].includes(
    userProfile?.teambleUserId
  );

  useEffect(() => {
    getOneOnOneDetails(oneOnOneId, true)
      .pipe()
      .subscribe({
        next: (response: any) => {
          console.log(response?.data?.data);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }, []);

  function getChoiceMappedValues(type: any, family: any, value: any) {
    if (type === "choice" && family !== "custom") {
      const familyOptions =
        options.find((option) => option.value === family)?.options || [];
      return familyOptions.find((option) => option.value == value)?.label;
    }
    return value;
  }

  if (isLoading) return <>Loading...</>;
  return (
    <>
      <div className="one-on-one-prep-container">
        <div className="header-container">
          <HeaderText
            text={"Prep survey"}
            fontSize={"24px"}
            lineHeight={"24px"}
            letterSpacing={"-0.24px"}
          />
          <div className="right-header-container">
            <div className="subtext">
              {convertDate(
                (data?.state !== "closed"
                  ? data?.scheduledTimestamp?.toString()
                  : data?.startAt?.toString()) || ""
              ) ?? "Not Scheduled"}
            </div>

            {isEditEnabled && (
              <PrimaryButton
                text="Edit"
                onClick={() => {
                  setShowEditForm(true);
                }}
              />
            )}
          </div>
        </div>

        <div className="container">
          {data?.blocks
            ?.filter((block) => !["title", "static-text"].includes(block.type))
            ?.map((block, index) => {
              return (
                <div key={index} className="question-container">
                  <div>
                    <div className="question">{block?.label}</div>

                    {data?.reviewerChannelInfo?.value[block.id]?.value && (
                      <div className="reviewer-answer">
                        <div className="reviewer">{data?.reviewerName}</div>
                        <div>-</div>
                        <div className="answer-text">
                          {getChoiceMappedValues(
                            block.type,
                            block.family,
                            data?.reviewerChannelInfo?.value[block.id]?.value
                          )}
                        </div>
                      </div>
                    )}

                    {data?.revieweeChannelInfo?.value[block.id]?.value && (
                      <div className="reviewer-answer">
                        <div className="reviewer">{data?.revieweeName}</div>
                        <div>-</div>
                        <div className="answer-text">
                          {getChoiceMappedValues(
                            block.type,
                            block.family,
                            data?.revieweeChannelInfo?.value[block.id]?.value
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {showEditForm && (
        <EditFormModal
          data={data}
          oneOnOneId={oneOnOneId}
          onClose={() => {
            setShowEditForm(false);
          }}
        />
      )}
    </>
  );
};

export default OneOnOnePrepSurvey;
