import { FormErrors, ICreateIntegrationFormValues } from 'interfaces';
import * as Yup from 'yup';
import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const CREATE_INTEGRATION_VALIDATION_SCHEMA: Yup.SchemaOf<ICreateIntegrationFormValues> =
  Yup.object().shape(
    {
      type: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
        .required(FormErrors.Required),
      domain: Yup.string()
        .required(FormErrors.Required)
        .trim(FormErrors.Required),
      apiKey: Yup.string()
        .required(FormErrors.Required)
        .trim(FormErrors.Required),
    }
  );