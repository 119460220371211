import { Badge } from '@themesberg/react-bootstrap';
import { useAudiencePills } from 'hooks';
import { IAudience } from 'interfaces';
import React from 'react';

interface IAudiencePillsProps {
  audience?: IAudience;
}

const AudiencePills: React.FC<IAudiencePillsProps> = (
  { audience }
) => {

  const audiencePills = useAudiencePills(audience);

  return (
    <div>
      {
        audiencePills.map(
          (aud) => (
            <Badge
              key={aud.id}
              pill={true}
              bg={
                aud.isWorkspace ? 'success' :
                  (aud.isChannel || aud.isGroup) ? 'info' : 'primary'}
              className='badge-lg me-1'
            >
              {aud.isWorkspace || aud.isChannel || aud.isGroup ? '' : '@ '}{aud.name}
            </Badge>
          )
        )
      }
    </div>
  );
};

export default AudiencePills;