import { IFeedbackLoopConfigContext } from 'interfaces';
import * as React from 'react';


const FeedbackLoopConfigContext = React.createContext<IFeedbackLoopConfigContext | undefined>(undefined);


const FeedbackLoopConfigProvider: React.FC<IFeedbackLoopConfigContext> = (
  { children, ...props }
) => {

  return (
    <FeedbackLoopConfigContext.Provider value={props}>
      {children}
    </FeedbackLoopConfigContext.Provider>
  )

};

const useFeedbackLoopConfig = (): IFeedbackLoopConfigContext => {
  const context = React.useContext(FeedbackLoopConfigContext);
  if (context === undefined) {
    throw new Error('useFeedbackLoopConfig must be used within a FeedbackLoopConfigProvider')
  }
  return context;
};

export { FeedbackLoopConfigProvider, useFeedbackLoopConfig };