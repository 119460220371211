import { AxiosResponse } from 'axios';
import Axios, { AxiosObservable } from 'axios-observable';
import {
  ISimpleMenuOption,
  ISurveyGroupMembersResponse,
  IActionTypes,
  IChannelMenuOption,
  IEditUserRequestData, IMemberOption, IMsTeamsChannelUser, IResponse,
  ISlackChannel,
  ISlackChannelMenuOption,
  ISlackWorkspace,
  ISlackWorkspaceUser,
  ITeamFromMsTeams, SignInWith,
  IOrgUser
} from 'interfaces';
import { map } from 'rxjs';

const getEmptyDataResponse = (response: any) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: []
    }
  };
};


export const mapMembersToMenuOptionsResponse = (
  response: AxiosResponse<IResponse<IMsTeamsChannelUser[] | ISlackWorkspaceUser[]>>
): AxiosResponse<IResponse<IMemberOption[]>> => {

  return {
    ...response,
    data: {
      ...response.data,
      data: response.data.data.map(
        (member) => ({
          value: member.id,
          label: member.name,
          teambleUserId: member.teambleUserId
        })
      )
    }
  }
};


const getMappedSlackWorkspacesAsMenuOptions = (
  response: AxiosResponse<IResponse<ISlackWorkspace[]>>
): AxiosResponse<IResponse<IChannelMenuOption[]>> => {

  if (!response.data.data?.length) {
    return getEmptyDataResponse(response);
  }

  const options: IChannelMenuOption[] = response.data.data.map(
    (option) => ({
      ...option,
      value: option.id,
      label: option.name,
    })
  );

  return {
    ...response,
    data: {
      ...response.data,
      data: options
    }
  };
};


export const getSlackWorkspaces = (): AxiosObservable<IResponse<ISlackWorkspace[]>> => {
  return Axios.post<IResponse<ISlackWorkspace[]>>("/company-workspaces");
};

export const getMsTeamsChannels =
  (getAllChannels: boolean = false): AxiosObservable<IResponse<ITeamFromMsTeams[]>> => {
    return Axios.post<IResponse<ITeamFromMsTeams[]>>(
      "/get-team-channels",
      { getAllChannels }
    );
  };


export const getSlackWorkspacesAsMenuOptions =
  (): AxiosObservable<IResponse<IChannelMenuOption[]>> => {
    return getSlackWorkspaces().pipe(
      map(
        (response) => getMappedSlackWorkspacesAsMenuOptions(response)
      )
    );
  };


export const getMsTeamsChannelsAsMenuOptions =
  (getAllChannels: boolean = false): AxiosObservable<IResponse<IChannelMenuOption[]>> => {
    return getMsTeamsChannels(getAllChannels).pipe(
      map(
        (response) => {

          if (!response.data.data?.length) {
            return getEmptyDataResponse(response);
          }

          const options: IChannelMenuOption[] = response.data.data.flatMap(
            (option) => {

              return option.channels.map(
                (channel) => {
                  return {
                    ...channel,
                    value: channel.id,
                    label: `${option.name} > ${channel.name}`,
                  }
                }
              );
            }
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: options
            }
          };

        }
      )
    );
  };


export const getSlackWorkspacesUsers = (
  workspaceIds: string[],
  withManagers: boolean = false,
  getAll: boolean = false,
  isManagee: boolean = false,
  getDeleted: boolean = false,
  isReport: null | string = null
): AxiosObservable<IResponse<ISlackWorkspaceUser[]>> => {

  return Axios.post<IResponse<ISlackWorkspaceUser[]>>(
    "/workspace-users",
    { workspaceIds, withManagers, getAll, isManagee, getDeleted, isReport },
    {
      responseType: isReport ? 'arraybuffer' : 'json'
    }
  )

};


export const getSlackTeamMembersAsMenuOptions =
  (
    workspaceIds: string[],
    withManagers: boolean = false,
    getAll: boolean = false,
    isManagee: boolean = false,
  ): AxiosObservable<IResponse<IMemberOption[]>> => {

    return getSlackWorkspacesUsers(
      workspaceIds,
      withManagers,
      getAll,
      isManagee
    ).pipe(
      map(
        (response) => {

          if (!response.data.data?.length) {
            return getEmptyDataResponse(response);
          }

          let options: IMemberOption[] = response.data.data.map(
            (option) => ({
              ...option,
              value: option.id,
              label: option.realName,
              description: option.title
            })
          );

          const usersWithTeambleId = options.filter(
            (option) => !!option.teamble_id
          );

          const usersWithoutTeambleId = options.filter(
            (option) => !option.teamble_id
          );

          // Filter duplicate user entries bcz of the multiple workspaces
          const uniqueUsers = [...usersWithTeambleId, ...usersWithoutTeambleId].filter(
            (option, index, optionsArray) => {

              const optionIndex = optionsArray.findIndex(
                (optionToFind) => optionToFind.email === option.email
              );
              return optionIndex === index;
            }
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: uniqueUsers
            }
          };

        }
      )
    )

  };


export const getMsTeamsTeamMembersAsMenuOptions =
  (channelId: string): AxiosObservable<IResponse<IMemberOption[]>> => {

    return Axios.post<IResponse<IMsTeamsChannelUser[]>>(
      "/msteams-users",
      { channelId }
    ).pipe(
      map(
        (response) => {

          if (!response.data.data?.length) {
            return getEmptyDataResponse(response);
          }

          const options: IMemberOption[] = response.data.data.map(
            (option) => ({
              ...option,
              value: option.id,
              label: option.name,
              email: option.email,
            })
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: options
            }
          };

        }
      )
    )

  };


export const getUsersAsMenuOptionByMsTeamsChannelIds =
  (channelIds: string[]): AxiosObservable<IResponse<IMemberOption[]>>[] => {

    return channelIds.map(
      (channelId) => getMsTeamsTeamMembersAsMenuOptions(channelId)
    );

  };


export const getUsersAsMenuOptionByChannelIdsOrWorkspaceIds =
  (app: SignInWith, teambleTeamIds: string[]): AxiosObservable<IResponse<IMemberOption[]>> => {

    return Axios.post<IResponse<IMemberOption[]>>(
      "/multi-slack-or-msteams-users",
      { teambleTeamIds }
    ).pipe(
      map(response => {
        if (!response.data.data?.length) {
          return getEmptyDataResponse(response);
        }
        response.data.data.map((val) => {
          val.label = app === SignInWith.Slack ? (val as any)?.realName || "" : val?.name || "";
          val.value = val?.id || "";
          return val;
        })
        return response;
      })
    )
  };


export const getSlackWorkspaceChannelsAsMenuOption =
  (workspaceId: string): AxiosObservable<IResponse<ISlackChannelMenuOption[]>> => {

    return Axios.post<IResponse<ISlackChannel[]>>(
      "/list-slack-channel",
      { workspaceId }
    ).pipe(
      map(
        (response) => {

          if (!response.data.data?.length) {
            return getEmptyDataResponse(response);
          }

          const options: ISlackChannelMenuOption[] = response.data.data.map(
            (option) => ({
              value: option.id,
              label: option.name,
              is_private: option.is_private
            })
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: options
            }
          };

        }
      )
    );
  };


export const updateMember = (data: IEditUserRequestData): AxiosObservable<IResponse<IOrgUser>> => {

  return Axios.post<IResponse<IOrgUser>>(
    "/update-org-user",
    data,
  );
};


export const getEnterpriseWorkspacesAsMenuOptions =
  (idToken: string): AxiosObservable<IResponse<IChannelMenuOption[]>> => {

    return Axios.post<IResponse<{ workspaces: ISlackWorkspace[] }>>(
      "/slack/list-available-workspaces",
      { idToken }
    )
      .pipe(
        map(
          (response) => getMappedSlackWorkspacesAsMenuOptions(
            {
              ...response,
              data: {
                ...response.data,
                data: response.data.data.workspaces
              }
            }
          )
        )
      );

  };


export const excludeIncludeUsers = (type: IActionTypes, teambleUserIds: string[]) => {
  return Axios.post<IResponse<void>>(
    "/exclude-include-org-users",
    { type, teambleUserIds }
  );
};


export const getSlackChannelUsersAsMenuOptions = (
  workspaceId: string,
  channelIds: string[]
): AxiosObservable<IResponse<IMemberOption[]>> => {

  return Axios.post<IResponse<ISlackWorkspaceUser[]>>(
    "/list-slack-channels-user",
    { workspaceId, channelIds }
  ).pipe(
    map(
      (response) => {

        if (!response.data.data?.length) {
          return getEmptyDataResponse(response);
        }

        let options: IMemberOption[] = response.data.data.map(
          (option) => ({
            ...option,
            value: option.id,
            label: option.real_name as string,
            description: option.title
          })
        );

        return {
          ...response,
          data: {
            ...response.data,
            data: options
          }
        };

      }
    )
  );

};


export const getMsTeamsCompanyMembers = (isReport: null | string = null): AxiosObservable<IResponse<IMsTeamsChannelUser[]>> => {

  return Axios.post<IResponse<IMsTeamsChannelUser[]>>(
    "/list-all-members-of-company",
    { isReport },
    {
      responseType: isReport ? 'arraybuffer' : 'json'
    }
  );
};


export const getSlackCompanyMembers =
  (): AxiosObservable<IResponse<ISlackWorkspaceUser[]>> => {

    return Axios.post<IResponse<ISlackWorkspaceUser[]>>(
      "/list-all-members-of-company",
    );
  };


export const getMsTeamsCompanyMembersAsMenuOptions =
  (): AxiosObservable<IResponse<IMemberOption[]>> => {

    return getMsTeamsCompanyMembers().pipe(
      map(
        (response) => mapMembersToMenuOptionsResponse(response)
      )
    );
  };


export const getSlackCompanyMembersAsMenuOptions =
  (): AxiosObservable<IResponse<IMemberOption[]>> => {

    return getSlackCompanyMembers().pipe(
      map(
        (response) => mapMembersToMenuOptionsResponse(response)
      )
    );

  };


export const getTeamsGroupsAsMenuOption = (): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {

  return Axios.post<IResponse<ISlackChannel[]>>(
    "/list-groups"
  ).pipe(
    map(
      (response) => {

        if (!response.data.data?.length) {
          return getEmptyDataResponse(response);
        }

        const options: ISimpleMenuOption[] = response.data.data.map(
          (option: any) => ({
            value: option.id,
            label: `${option.msteams_name} -> ${option.msteams_channel_name}`
          })
        );

        return {
          ...response,
          data: {
            ...response.data,
            data: options
          }
        };

      }
    )
  );
};

export const getGroupMembersDetail = (
  groupIds: string[],
  teambleTeamId: string
): AxiosObservable<IResponse<ISurveyGroupMembersResponse[]>> => {

  return Axios.post<IResponse<ISurveyGroupMembersResponse[]>>(
    "/list-groups-user",
    { groupIds, teambleTeamId }
  );
};
