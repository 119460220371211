export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface ITimestampsRange {
  startDate: number;
  endDate: number
}

export interface IEntityBase {
  id: string;
  title: string;
}

export enum SendReminderAsTypes {
  Email = 'email',
  AppMessage = 'appMessage'
}