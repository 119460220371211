import React from "react";
import ReactDOM from "react-dom";

import "./scss/volt.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import { setDefaultAxiosConfig } from "data";
import { setRequestInterceptors, setResponseInterceptors } from "interceptors";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./modules/home/HomePage";
import { Provider } from "react-redux";
import { store } from "store";

setDefaultAxiosConfig();
setRequestInterceptors();
setResponseInterceptors();

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <ScrollToTop />
      <ToastContainer limit={1} hideProgressBar={true} newestOnTop={true} />
      <HomePage />
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
