import { Badge, ProgressBar } from '@themesberg/react-bootstrap';
import { Progress } from 'components';
import { MetaDataContext } from 'contexts';
import {
  IAggregation,
  IMenuOption,
  IMetadata,
  IMultiChoiceAnswer,
  IMultiChoiceAnswerUser,
  IMultiSelectQuestionOptionsTypeConstants,
  IQuestionTypeConstants,
  ISimpleTeamsMenuOption,
  ITextAnswer,
} from 'interfaces';
import KPIWidget from 'modules/reviews/components/deloitte/KPIWidget';
import * as React from 'react';
import { CUSTOM_SCALE_BAR_COLORS, MULTI_SELECT_QUESTION_NA_OPTION } from 'teamble-constants';
import { getAnalyticsBarColorByChoiceType } from 'utils';

interface IAggregationProps {
  isAnonymous?: boolean;
  aggregation: IAggregation;
  singleBarViewEnabled?: boolean;
  isNpsOrLikelyUnlikely?: boolean;
}

interface IMultiChoiceAnswerProps {
  index: number;
  label: string;
  choicesLength?: number;
  responsePercent: number;
  choiceResponses: number;
}


const getQuestionLabel = (
  choice: IMenuOption | string,
  multiSelectQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants,
  family?: string,
): string => {

  if (family === multiSelectQuestionTypeConstants.CUSTOM_ITEMS) {
    return choice as string;
  }

  return (choice as IMenuOption).label || (choice as ISimpleTeamsMenuOption).header;
};


const getAnsweredUsers = (
  choice: IMenuOption | string,
  aggregation: IAggregation,
  multiSelectQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants,
): IMultiChoiceAnswerUser[] => {

  if (!aggregation.answers || !aggregation.answers.length) {
    return [];
  }

  let choiceId: string;

  if (aggregation.block.family === multiSelectQuestionTypeConstants.CUSTOM_ITEMS) {
    choiceId = choice as string;
  } else {
    choiceId = (choice as IMenuOption).value || (choice as ISimpleTeamsMenuOption).id;
  }

  const userAnswer = aggregation.answers.find(
    (answer) => (answer as IMultiChoiceAnswer).option.value === choiceId
  );

  if (userAnswer) {
    return (userAnswer as IMultiChoiceAnswer).users;
  } else {
    return [];
  }
};


const getResponsePercent = (
  choice: IMenuOption | string,
  aggregation: IAggregation,
  multiSelectQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants,
): number => {

  let responsePercent: number;
  let choiceKey: string = aggregation.block.family === multiSelectQuestionTypeConstants.CUSTOM_ITEMS ?
    choice as string :
    (choice as IMenuOption).value || (choice as ISimpleTeamsMenuOption).id;

  responsePercent = ((aggregation.counts[choiceKey] * 100) / aggregation.count);

  if (!responsePercent) {
    responsePercent = 0;
  } else {
    responsePercent = +responsePercent.toFixed(2);
  }

  return responsePercent;
};


const VerticalAnswerPreview: React.FunctionComponent<IMultiChoiceAnswerProps> = (
  { label, responsePercent, choiceResponses, choicesLength = 1 }
) => {

  return (
    <div
      className='d-flex flex-column me-3'
      style={{ width: `${100 / choicesLength}%` }}
    >

      <p className='fs-5 text-center'>
        {responsePercent}%
      </p>

      <p className='fs-5 text-center'>
        {choiceResponses} resp.
      </p>

      <div style={{
        height: '200px',
        width: '100%',
      }} className='progress align-items-end'
      >
        <div
          className='progress-bar w-100'
          style={{ height: `${responsePercent}%` }}></div>
      </div>

      <p className='fs-5 text-center'>
        {label}
      </p>
    </div>
  )
};


const HorizontalMultiChoiceAnswerPreview: React.FC<IMultiChoiceAnswerProps> = (
  { index, label, responsePercent, choiceResponses }
) => {
  return (
    <div className='d-flex mb-1'>
      <p style={{
        height: 60,
        width: 60,
        lineHeight: '60px',
      }} className='me-3 mb-0 text-center bg-gray-300 rounded'> {index + 1} </p>

      <Progress
        size='lg'
        className='flex-grow-1'
        label={label}
        value={responsePercent}
        progressLabel={
          <>
            <span className='fs-5 me-1'> {responsePercent}% </span>
            <span> / {choiceResponses} resp.</span>
          </>
        } />
    </div>
  )
};


const SingleBarView: React.FC<IAggregationProps> = (
  { aggregation }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const barColors: string[] = getAnalyticsBarColorByChoiceType(
    aggregation.block.family as string,
    multiSelectQuestionTypeConstants
  );

  return (
    <ProgressBar className='w-100' style={{ height: '28px' }}>
      {
        aggregation.block.choices?.map(
          (choice: IMenuOption | string, index: number) => {

            const responsePercent =
              getResponsePercent(choice, aggregation, multiSelectQuestionTypeConstants);

            const backgroundColor = aggregation.block.family === multiSelectQuestionTypeConstants.CUSTOM_ITEMS ?
              barColors[index % CUSTOM_SCALE_BAR_COLORS.length] :
              barColors[index];

            if (responsePercent === 0) {
              return null;
            }

            return (
              <ProgressBar
                key={(choice as IMenuOption).value || (choice as ISimpleTeamsMenuOption).id || (choice as string)}
                className='fw-bolder fs-6'
                style={{
                  backgroundColor,
                  color: '#fff',
                }}
                label={responsePercent + '%'}
                now={responsePercent} />
            );
          }
        )
      }
    </ProgressBar>
  )
};


const MultiBarPreview: React.FC<IAggregationProps> = (
  { aggregation, isNpsOrLikelyUnlikely = false, isAnonymous = false }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  return (
    <>
      {
        aggregation.block.choices?.map(
          (choice, index) => {

            let responsePercent: number;
            let choiceResponses: number;
            let choiceKey: string = aggregation.block.family === multiSelectQuestionTypeConstants.CUSTOM_ITEMS ?
              choice as string :
              (choice as IMenuOption).value || (choice as ISimpleTeamsMenuOption).id;

            responsePercent = ((aggregation.counts[choiceKey] * 100) / aggregation.count);
            choiceResponses = aggregation.counts[choiceKey];

            if (!responsePercent) {
              responsePercent = 0;
            } else {
              responsePercent = +responsePercent.toFixed(2);
            }

            return (
              <React.Fragment key={choiceKey}>
                {
                  isNpsOrLikelyUnlikely ?
                    (
                      <VerticalAnswerPreview
                        index={index}
                        label={getQuestionLabel(choice, multiSelectQuestionTypeConstants, aggregation.block.family)}
                        responsePercent={getResponsePercent(choice, aggregation, multiSelectQuestionTypeConstants)}
                        choiceResponses={choiceResponses}
                        choicesLength={aggregation.block.choices?.length} />
                    ) :
                    (
                      <HorizontalMultiChoiceAnswerPreview
                        index={index}
                        label={getQuestionLabel(choice, multiSelectQuestionTypeConstants, aggregation.block.family)}
                        responsePercent={getResponsePercent(choice, aggregation, multiSelectQuestionTypeConstants)}
                        choiceResponses={choiceResponses} />
                    )
                }
                {
                  !isAnonymous &&
                  <div className="mb-3">
                    {
                      getAnsweredUsers(choice, aggregation, multiSelectQuestionTypeConstants).map(
                        (user) => (
                          !isNpsOrLikelyUnlikely &&
                          <Badge key={user.id} pill={true} bg='primary' className='badge-lg me-1'>
                            @{user.name}
                          </Badge>
                        )
                      )
                    }
                  </div>
                }
              </React.Fragment>
            );

          }
        )
      }
    </>
  );
};


const SingleBarLegends: React.FC<IAggregationProps> = (
  { aggregation }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const barColors: string[] = getAnalyticsBarColorByChoiceType(
    aggregation.block.family as string,
    multiSelectQuestionTypeConstants
  );

  return (
    <div className="d-flex justify-content-end align-items-center mb-2">
      {
        aggregation.block.choices?.map(
          (choice, index) => {

            const backgroundColor = aggregation.block.family === multiSelectQuestionTypeConstants.CUSTOM_ITEMS ?
              barColors[index % CUSTOM_SCALE_BAR_COLORS.length] :
              barColors[index];

            return (
              <div key={backgroundColor} className='d-flex align-items-center ms-3'>
                <div style={{
                  height: '16px',
                  width: '16px',
                  borderRadius: '50%',
                  backgroundColor,
                }}></div>
                <p className='my-0 ms-2'>
                  {
                    getQuestionLabel(choice, multiSelectQuestionTypeConstants, aggregation.block.family)
                  }
                </p>
              </div>
            );
          }
        )
      }
    </div>
  );
};


const MultiChoiceAnswerComments: React.FunctionComponent<IAggregationProps> = (
  { aggregation, isAnonymous = false }
) => {

  return (
    <>
      {
        (aggregation.block.comment || aggregation.block.commentBox)
          ?
          <>
            <h5>Comments</h5>
            {
              isAnonymous
                ?
                aggregation.commentValues?.length
                  ?
                  aggregation.commentValues.map(
                    (comment, index) => (
                      <li className='ws-pre-line' key={index}>
                        {/* <p dangerouslySetInnerHTML={{ __html: toHTML(comment, { hrefTarget: '_blank' }) }}></p> */}
                        {comment}
                      </li>
                    )
                  )
                  :
                  <p><em> No comments </em></p>
                :
                aggregation.commentAnswers?.length
                  ?
                  aggregation?.commentAnswers.map(
                    (comment) => (
                      <li key={comment.name}>
                        <Badge pill={true} bg='primary' className='badge-lg ms-1'> @{comment.name}</Badge>
                        <p className='ws-pre-line'>{comment.value}</p>
                      </li>
                    )
                  )
                  :
                  <p><em> No comments </em></p>
            }
          </>
          :
          null
      }
    </>

  );

};


export const MultiChoiceAnswerPreview: React.FunctionComponent<IAggregationProps> = (
  { aggregation, singleBarViewEnabled, isNpsOrLikelyUnlikely = false, isAnonymous = false }
) => {


  const [aggregationWithChoices, setAggregationWithChoices] = React.useState<IAggregation>(aggregation);

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const multiSelectQuestionOptions = metadata.multiSelectQuestionOptionsTypes.options
  const multiSelectQuestionTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants
  const questionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  React.useEffect(
    () => {

      let aggregationChoices: string[] | IMenuOption[] = [];
      if (!aggregationWithChoices.block.choices?.length) {
        aggregationChoices = multiSelectQuestionOptions.find(
          (option) => option.value === aggregationWithChoices.block.family
        )!.options as IMenuOption[];

        if (aggregationWithChoices.block.isNotApplicableOption) {
          aggregationChoices = [
            ...aggregationChoices,
            MULTI_SELECT_QUESTION_NA_OPTION
          ];
        }
      } else {
        aggregationChoices = aggregationWithChoices.block.choices;

        if (aggregationWithChoices.block.isNotApplicableOption) {
          aggregationChoices = [
            ...aggregationChoices as string[],
            MULTI_SELECT_QUESTION_NA_OPTION.label
          ];
        }
      }

      setAggregationWithChoices({
        ...aggregationWithChoices,
        block: {
          ...aggregationWithChoices.block,
          choices: [...aggregationChoices] as string[] | IMenuOption[]
        }
      });

    },
    []
  );


  return (
    <div className='mt-4'>
      {
        aggregation.groupId &&
        <p className='fs-3'> <strong> Group: </strong> {aggregation.groupName}</p>
      }
      <h4 className='ws-pre-line'>Q: {aggregation.block.label}</h4>
      {
        (aggregation.block.family === multiSelectQuestionTypeConstants.NPS) &&
        (aggregation.NPS !== undefined || aggregation.NPS !== null) &&
        <p className="fw-bolder"> NPS: {aggregation.NPS} </p>
      }

      {
        singleBarViewEnabled &&
        !aggregation.block.multipleAnswers &&
        aggregation.count > 0 &&
        <SingleBarLegends aggregation={aggregationWithChoices} />
      }

      <div className={isNpsOrLikelyUnlikely ? 'd-flex' : ''}>
        {
          aggregation.count > 0 ?
            (
              singleBarViewEnabled && !aggregation.block.multipleAnswers
                ?
                <SingleBarView
                  aggregation={aggregationWithChoices}
                />
                :
                <MultiBarPreview
                  aggregation={aggregationWithChoices}
                  isNpsOrLikelyUnlikely={isNpsOrLikelyUnlikely}
                  isAnonymous={isAnonymous} />
            ) :
            (<i> Not Answered </i>)
        }
      </div>
      <div>
        {
          !isAnonymous &&
            (isNpsOrLikelyUnlikely || (singleBarViewEnabled && !aggregation.block.multipleAnswers)) &&
            aggregation.count > 0 &&
            aggregationWithChoices.answers
            ?
            aggregationWithChoices.answers.map(
              (answer) => {

                const multiChoiceAnswer = answer as IMultiChoiceAnswer;

                return (
                  <div key={multiChoiceAnswer.option.text.text} className="mb-3 d-flex align-items-center" >
                    <p className='m-0'> {multiChoiceAnswer.option.text.text}:{' '} </p>
                    {
                      multiChoiceAnswer.users.length > 0 ? multiChoiceAnswer.users.map(
                        (user) => (
                          <Badge key={user.id} pill={true} bg='primary' className='badge-lg ms-1'> @{user.name}</Badge>
                        )
                      ) : <i> Not Answered </i>
                    }
                  </div>
                )
              }
            )
            :
            null
        }
      </div>
      <div>
        {
          aggregation.type === questionTypeConstants.OBJECTIVE_QUESTION && (
            <>
              {
                aggregation.block.showKPIs && Boolean(aggregation.block?.kpis?.length) && (
                  <KPIWidget
                    role={(aggregation.block as any).objectiveName}
                    docType={(aggregation.block as any).docType}
                    kpis={aggregation.block.kpis || []}
                    index={0}
                  />
                )
              }
            </>
          )

        }
        <MultiChoiceAnswerComments
          aggregation={aggregation}
          isAnonymous={isAnonymous}
        />
      </div>
    </div>
  );
};


export const TextAnswerPreview: React.FunctionComponent<IAggregationProps> = (
  { aggregation, isAnonymous = false }
) => {

  return (
    <div className='mt-4'>
      {
        aggregation.groupId &&
        <p className='fs-3'> <strong> Group: </strong> {aggregation.groupName}</p>
      }
      <h4 className='ws-pre-line'>Q: {aggregation.block.label}</h4>

      {
        isAnonymous ?
          (
            aggregation.lines?.length ?
              (
                <ul>
                  {
                    aggregation.lines.map(
                      (answer, index) => (
                        <li key={index}>
                          {/* <p className='m-0' dangerouslySetInnerHTML={{ __html: toHTML(answer, { hrefTarget: '_blank' }) }}></p> */}
                          <p className='m-0' style={{ whiteSpace: 'pre-line' }}>{answer}</p>
                        </li>
                      )
                    )
                  }
                </ul>
              ) :
              (<i> Not Answered </i>)
          ) :
          (
            aggregation.answers?.length ?
              (
                <ul>
                  {
                    (aggregation.answers as ITextAnswer[]).map(
                      (answer) => (
                        <li key={answer.id}>
                          <Badge pill={true} bg='primary' className='badge-lg'> @{answer.name}</Badge>
                          {/* <p className='m-0' dangerouslySetInnerHTML={{ __html: toHTML(answer.value, { hrefTarget: '_blank' }) }}></p> */}
                          <p className='m-0' style={{ whiteSpace: 'pre-line' }}>{answer.value}</p>
                        </li>
                      )
                    )
                  }
                </ul>
              ) :
              (<i> Not Answered </i>)
          )
      }
    </div>
  );
};