import { Button, Form, Spinner } from '@themesberg/react-bootstrap';
import { ConfirmationModal, CustomSelect } from 'components';
import { useTeamMemberMenuOptions } from 'hooks';
import { IMemberOption, IMenuOption, ReviewStatus } from 'interfaces';
import * as React from 'react';
import { SELECT_ALL_OPTION } from 'teamble-constants';

interface ISelectUserPairsFieldProps {
  firstSelectTitle: string;
  secondSelectTitle: string;
  organizationId: string;
  firstSelectOptions?: IMemberOption[];
  isLoading?: boolean;
  allowSelectAll?: boolean;
  onAddToListClick: (firstUsersList: IMenuOption[] | null, secondUsersList: IMenuOption[] | null) => void;
  state?: string
}

const SelectUserPairsField: React.FunctionComponent<ISelectUserPairsFieldProps> = (
  {
    organizationId, isLoading, firstSelectTitle, secondSelectTitle, firstSelectOptions,
    onAddToListClick, allowSelectAll, state
  }
) => {

  const { memberOptions, isLoading: isMembersLoading } = useTeamMemberMenuOptions(organizationId);
  const [selectedFirstUsersList, setSelectedFirstUsersList] = React.useState<IMenuOption[] | null>([]);
  const [selectedSecondUsersList, setSelectedSecondUsersList] = React.useState<IMenuOption[] | null>([]);
  const [confirmAddAudience, setConfirmAddAudience] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      setSelectedFirstUsersList(null);
      setSelectedSecondUsersList(null);
    },
    [organizationId, firstSelectOptions]
  );


  const addSelectedPairsToAudienceList = (): void => {
    setSelectedFirstUsersList(null);
    setSelectedSecondUsersList(null);
    onAddToListClick(
      Array.isArray(selectedFirstUsersList) ? selectedFirstUsersList.filter(user => user.value !== 'All') : selectedFirstUsersList,
      Array.isArray(selectedSecondUsersList) ? selectedSecondUsersList.filter(user => user.value !== 'All') : selectedSecondUsersList,
    );
  };

  if (isLoading || isMembersLoading) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <Spinner animation="border" role="status" size='sm'>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }

  return (
    <div className='d-flex align-items-start justify-content-between mt-2'>
      <div className='d-flex flex-grow-1'>

        <div className='flex-grow-1 mr-3 w-50 position-relative'>
          <Form.Label> {firstSelectTitle}  </Form.Label>
          <CustomSelect
            isMulti
            allowSelectAll={Boolean(allowSelectAll)}
            isSearchable={true}
            optionType='user'
            options={
              Boolean(allowSelectAll)
                ? [SELECT_ALL_OPTION, ...(firstSelectOptions || memberOptions)]
                : firstSelectOptions || memberOptions
            }
            value={(selectedFirstUsersList || []) as IMenuOption[]}
            onChange={
              (users) => {
                setSelectedFirstUsersList(users as (IMenuOption[] | null));
              }
            }
          />
        </div>

        <div className='flex-grow-1 mx-3 w-50 position-relative'>
          <Form.Label> {secondSelectTitle}  </Form.Label>
          <CustomSelect
            isMulti
            optionType='user'
            allowSelectAll={Boolean(allowSelectAll)}
            isSearchable={true}
            options={
              Boolean(allowSelectAll)
                ? [SELECT_ALL_OPTION, ...memberOptions]
                : memberOptions
            }
            value={(selectedSecondUsersList || []) as IMenuOption[]}
            onChange={
              (users) => {
                setSelectedSecondUsersList(users as (IMenuOption[] | null));
              }
            }
          />

        </div>
      </div>
      <Button
        type='button'
        className='flex-shrink-0 mt-md'
        disabled={!selectedFirstUsersList?.length || !selectedSecondUsersList?.length || isLoading}
        onClick={() => {
          if (state && state === ReviewStatus.Live) {
            setConfirmAddAudience(true)
          } else {
            addSelectedPairsToAudienceList();
          }
        }}
      >
        Add to Lists
      </Button>

      {
        confirmAddAudience &&
        <ConfirmationModal
          title='Are you sure?'
          show={confirmAddAudience}
          onPositiveResponse={() => {
            addSelectedPairsToAudienceList();
            setConfirmAddAudience(false);
          }}
          onCloseClick={() => setConfirmAddAudience(false)}
        >
          <p>The selected user pairs will be added to this live review and will receive notifications.</p>
        </ConfirmationModal>
      }

    </div>
  );
};

export default SelectUserPairsField;
