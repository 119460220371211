import { IMenuOption, ISchedulerEvents, ISimpleMenuOption } from 'interfaces';
import React from 'react';
import { SCHEDULER_FREQUENCY_OPTIONS } from 'teamble-constants';
import {
  getFilteredSchedulerFrequencyOptions,
  getFrequencyOptions,
  getScheduleEvents,
  getSchedulerDateAndWeekInfo,
} from 'utils';


export const useSchedulerFrequencyOptions = (
  isValid: boolean,
  schedulerDateTime: Date | null,
): IMenuOption[] => {

  const [frequencyOptions, setFrequencyOptions] =
    React.useState<IMenuOption[]>(SCHEDULER_FREQUENCY_OPTIONS.options);

  React.useEffect(
    () => {

      if (!schedulerDateTime || !isValid) {
        setFrequencyOptions([]);
        return;
      }

      const { dateTime, weekNumber, weekDayName, } =
        getSchedulerDateAndWeekInfo(schedulerDateTime);

      const filteredRepeatOptions =
        getFilteredSchedulerFrequencyOptions(dateTime, weekNumber);

      const frequencyOpts =
        getFrequencyOptions(filteredRepeatOptions, dateTime, weekDayName, weekNumber);

      setFrequencyOptions(frequencyOpts);

    },
    [isValid, schedulerDateTime]
  );

  return frequencyOptions;
};


export const useSchedulerEvents = (
  isValid: boolean,
  schedulerDateTime: Date | null,
  schedulerFrequency: ISimpleMenuOption | null
): ISchedulerEvents[] => {

  const [schedulerEvents, setSchedulerEvents] =
    React.useState<ISchedulerEvents[]>([]);

  React.useEffect(
    () => {

      if (!schedulerFrequency || !schedulerDateTime || !isValid) {
        setSchedulerEvents([]);
        return;
      }

      const events = getScheduleEvents(
        schedulerDateTime,
        schedulerFrequency.value
      );

      setSchedulerEvents(events || []);

    },
    [schedulerFrequency, schedulerDateTime, isValid]
  );

  return schedulerEvents;

};