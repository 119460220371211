import { IQuestionBuilderContext } from 'interfaces';
import * as React from 'react';

import Question from './Question';
import QuestionBuilderForm from './QuestionBuilderForm';
import QuestionPreview from './QuestionPreview';
import QuestionPreviewWidget from './QuestionPreviewWidget';
import QuestionsAccordion from './QuestionsAccordion';
import QuestionsPreviewModal from './QuestionsPreviewModal';
import QuestionWidget from './QuestionWidget';


const QuestionBuilderContext = React.createContext<IQuestionBuilderContext<any> | undefined>(undefined);


const QuestionBuilder =
  <T,>({ children, ...props }: React.PropsWithChildren<IQuestionBuilderContext<T>>): React.ReactElement => {

    return (
      <QuestionBuilderContext.Provider value={props}>
        {children}
      </QuestionBuilderContext.Provider>
    )

  };


export const useQuestionBuilder = <T,>(): IQuestionBuilderContext<T> => {
  const context = React.useContext(QuestionBuilderContext);
  if (context === undefined) {
    throw new Error('useQuestionBuilder must be used within a QuestionBuilder')
  }
  return context;
};


export default Object.assign(
  QuestionBuilder,
  {
    Form: QuestionBuilderForm,
    Accordion: QuestionsAccordion,
    QuestionWidget: QuestionWidget,
    Question: Question,
    PreviewWidget: QuestionPreviewWidget,
    QuestionPreview: QuestionPreview,
    PreviewModal: QuestionsPreviewModal
  }
);