import { FeedbackTemplateTypes, IFeedback } from 'interfaces';
import React from 'react'

interface IFeedbackTemplateTextProps {
  feedback: IFeedback;
}

interface IFeedbackTextProps extends IFeedbackTemplateTextProps {
  isPreviewOnly?: Boolean;
}

interface ITruncatedTextPreviewProps {
  text: string;
}

const TruncatedTextPreview: React.FC<ITruncatedTextPreviewProps> = ({ text }) => {
  return (
    <>
      {
        text &&
        <p className='m-0 text-truncate'>
          {text}
        </p>
      }
    </>
  );
};


const SaDPreview: React.FC<IFeedbackTemplateTextProps> = ({ feedback }) => {
  return (
    <div className='overflow-hidden'>
      {
        feedback.strength_text &&
        <TruncatedTextPreview text={'💪 Strengths: ' + feedback.strength_text} />
      }
      {
        feedback.developmentArea_text &&
        <TruncatedTextPreview text={'🎯 Development Areas: ' + feedback.developmentArea_text} />
      }
      {
        feedback.sd_nextStep_text &&
        <TruncatedTextPreview text={'🔦 Next Steps: :' + feedback.sd_nextStep_text} />
      }
    </div>
  );
};


const SBIFeedbackPreview: React.FC<IFeedbackTemplateTextProps> = ({ feedback }) => {
  return (
    <div className='overflow-hidden'>
      {
        feedback.situation_text &&
        <TruncatedTextPreview text={'📍 Situation: ' + feedback.situation_text} />
      }
      {
        feedback.behavior_text &&
        <TruncatedTextPreview text={'▶️ Behavior: ' + feedback.behavior_text} />
      }
      {
        feedback.impact_text &&
        <TruncatedTextPreview text={'💡 Impact: ' + feedback.impact_text} />
      }
      {
        feedback.sbi_nextStep_text &&
        <TruncatedTextPreview text={'🔦 Next Steps: ' + feedback.sbi_nextStep_text} />
      }
    </div>
  );
};


const SBIFeedbackText: React.FC<IFeedbackTemplateTextProps> = ({ feedback }) => {
  return (
    <>
      {
        feedback.situation_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              <span role='img' aria-label='Feedback Situation'> 📍 </span> Situation
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.situation_text} </p>
        </div>
      }
      {
        feedback.behavior_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              <span role='img' aria-label='Feedback Behavior'> ▶️ </span> Behavior
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.behavior_text} </p>
        </div>
      }
      {
        feedback.impact_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              <span role='img' aria-label='Feedback Impact'> 💡 </span> Impact
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.impact_text} </p>
        </div>
      }
      {
        feedback.sbi_nextStep_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              <span role='img' aria-label='Feedback Next Steps'> 🔦 </span> Next Steps
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.sbi_nextStep_text} </p>
        </div>
      }
    </>
  );
};


const SaDFeedbackText: React.FC<IFeedbackTemplateTextProps> = ({ feedback }) => {
  return (
    <>
      {
        feedback.strength_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              <span role='img' aria-label='Feedback Strengths'> 💪 </span> Strengths
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.strength_text} </p>
        </div>
      }
      {
        feedback.developmentArea_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              <span role='img' aria-label='Feedback Development Areas'> 🎯 </span> Development Areas
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.developmentArea_text} </p>
        </div>
      }
      {
        feedback.sd_nextStep_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              <span role='img' aria-label='Feedback Next Steps'> 🔦 </span> Next Steps
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.sd_nextStep_text} </p>
        </div>
      }
    </>
  );
};


const FreeFeedbackText: React.FC<IFeedbackTemplateTextProps> = ({ feedback }) => {
  return (
    <>
      {
        feedback.feedback_text &&
        <div className='mb-2'>
          <p className='m-0'>
            <strong>
              Feedback
            </strong>
          </p>
          <p className='ws-pre-line'> {feedback.feedback_text} </p>
        </div>
      }
    </>
  );
};


const FeedbackText: React.FC<IFeedbackTextProps> = (
  { feedback, isPreviewOnly = false }
) => {

  switch (feedback.template_type) {

    case FeedbackTemplateTypes.SBI: {
      return isPreviewOnly
        ?
        <SBIFeedbackPreview feedback={feedback} />
        :
        <SBIFeedbackText feedback={feedback} />
    }

    case FeedbackTemplateTypes.SaD:
      return isPreviewOnly
        ?
        <SaDPreview feedback={feedback} />
        :
        <SaDFeedbackText feedback={feedback} />

    case FeedbackTemplateTypes.FreeText:
    default: {
      return isPreviewOnly
        ?
        <TruncatedTextPreview text={feedback.feedback_text} />
        :
        <FreeFeedbackText feedback={feedback} />
        ;
    }

  }
};

export default FeedbackText;