import { useAuth, useUserProfile } from 'components';
import { completeAuthorization } from 'data';
import { SignInWith } from 'interfaces';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from 'routes';


const CompleteAuthorization: React.FunctionComponent<{}> = () => {

  const { search } = useLocation();
  const history = useHistory();
  const auth = useAuth();
  const { setUserProfile } = useUserProfile();

  React.useEffect(
    () => {

      const authorizationId = new URLSearchParams(search).get('authorization');

      if (authorizationId) {

        let signInWith: SignInWith;

        if (history.location.pathname.includes('msteams')) {
          signInWith = SignInWith.MsTeams;
        } else if (history.location.pathname.includes('slack')) {
          signInWith = SignInWith.Slack;
        } else {
          history.push(Routes.Signin.path);
          return;
        }

        completeAuthorization(authorizationId, signInWith).subscribe(
          {
            next: (response) => {

              if (response.data.data.idToken) {
                localStorage.setItem('token', response.data.data.idToken);
                auth.setIsSignedIn(true);
                setUserProfile(response.data.data.profileDetail);
              }

              if (response.data.data.continueURL) {
                history.push(response.data.data.continueURL);
              }

            },
            error: () => {
              history.push(Routes.AuthorizationError.path);
            }
          }
        );

      } else {
        history.push(Routes.Signin.path);
      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return null;
};

export default CompleteAuthorization;
