import * as React from 'react';
import EmptyState from './EmptyState';
import Preloader from './Preloader';

interface IDataStateProps {
  isDataLoading: boolean;
  isDataAvailable: boolean;
  children: React.ReactNode;
  emptyStateMessage: React.ReactNode;
}

const DataState: React.FunctionComponent<IDataStateProps> = (
  { isDataLoading, isDataAvailable, emptyStateMessage, children }
) => {
  return (
    <>
      {
        isDataLoading
          ?
          <Preloader show={isDataLoading} />
          :
          isDataAvailable
            ?
            <> {children} </>
            :
            <EmptyState message={emptyStateMessage} />
      }
    </>
  );
};

export default DataState;
