import React from "react";
import "./IconButton.scss";
import { useHistory } from "react-router";

interface IconButtonProps {
  Icon: React.FC<any>;
  previousPath?: string; // Optional fallback path
}

const IconButton: React.FC<IconButtonProps> = ({ Icon, previousPath }) => {
  const history = useHistory();
  return (
    <div
      className="IconButton"
      onClick={() => {
        console.log(history);
        if (!previousPath) {
          history.goBack();
        } else {
          history.push(previousPath);
        }
      }}
    >
      <Icon />
    </div>
  );
};

export default IconButton;
