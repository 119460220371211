import { Col, Form, Row } from '@themesberg/react-bootstrap';
import { DateTimePicker } from 'components';
import moment from 'moment';
import React from 'react'
import { NumberParam, useQueryParams } from 'use-query-params';

interface IDateRangeFilterProps { }

const DateRangeFilter: React.FC<IDateRangeFilterProps> = () => {

  // Route Hooks
  const [queryParams, setQueryParams] = useQueryParams({
    startDate: NumberParam,
    endDate: NumberParam,
  });


  React.useEffect(
    () => {

      if (
        queryParams.startDate && queryParams.endDate &&
        queryParams.endDate <= queryParams.startDate
      ) {
        setQueryParams({
          endDate: undefined
        });
      }

    },
    [queryParams.endDate, queryParams.startDate, setQueryParams]
  );


  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Start Date</Form.Label>
          <DateTimePicker
            value={queryParams.startDate ? moment(queryParams.startDate) : null}
            onChange={(date) => setQueryParams({ startDate: +(date as moment.Moment).format('x') })}
            onClearClick={() => setQueryParams({ startDate: null })}
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group>
          <Form.Label>End Date</Form.Label>
          <DateTimePicker
            value={queryParams.endDate ? moment(queryParams.endDate) : null}
            minDateTime={queryParams.startDate ? moment(queryParams.startDate) : null}
            onChange={(date) => setQueryParams({ endDate: +(date as moment.Moment).format('x') })}
            onClearClick={() => setQueryParams({ endDate: null })}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DateRangeFilter;