import { FormErrors, ICreateRelationshipsFormValues } from 'interfaces';
import { MEMBER_MENU_OPTION_VALIDATION_SCHEMA } from 'validation-schemas';
import * as Yup from 'yup';


export const CREATE_RELATIONSHIP_VALIDATION_SCHEMA: Yup.SchemaOf<ICreateRelationshipsFormValues> =
  Yup.object().shape(
    {
      from: Yup.array(MEMBER_MENU_OPTION_VALIDATION_SCHEMA)
        .required(FormErrors.Required)
        .min(1, FormErrors.Required),
      to: Yup.array(MEMBER_MENU_OPTION_VALIDATION_SCHEMA)
        .required(FormErrors.Required)
        .min(1, FormErrors.Required),
    }
  );