import React, { useEffect, useState } from "react";
import HeaderText from "./components/HeaderText/HeaderText";
import "./oneOnOneAi.scss";
import TabButton from "./components/TabButton/TabButton";
import SearchIcon from "./components/SvgReactIcons/SearchIcon";
import TableListManagerView from "./components/TableListManagerView/TableListManagerView";
import TextGradientPrimaryButton from "./components/TextGradientPrimaryButton/TextGradientPrimaryButton";
import SortingDropdown from "./components/SortingDropdownBtn/SortingDropdown";
import TeambleAiIcon from "./components/SvgReactIcons/TeambleAiIcon";
import RatingStar from "./components/SvgReactIcons/RatingStar";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TeambleAiIconWhite from "./components/SvgReactIcons/TeambleAiIconWhite";
import IconButton from "./components/IconButton/IconButton";
import BackButtonArrowIcon from "./components/SvgReactIcons/BackButtonArrowIcon";
import { MeetingStatus, oneOnOneDetailListProps } from "./types";
import { oneOnOneDetailsList } from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import { useSelector } from "react-redux";
import { RootState } from "store";
import PrimaryButton from "./components/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";

const OneOnOneFeedback = () => {
  const [meetingStatus, setMeetingStatus] = useState<MeetingStatus>(
    MeetingStatus.Upcoming
  );

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<oneOnOneDetailListProps[]>([]);
  const [isSearchActive, setSearchActive] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const view = useSelector((state: RootState) => state.globalSettings.view);

  useEffect(() => {
    setIsLoading(true);
    oneOnOneDetailsList(view === "ADMIN" ? true : false)
      .pipe(
        finalize(() => {
          setIsLoading(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          setData(response?.data?.oneOnOnes ?? []);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }, [view]);

  if (isLoading) {
    return <>Loading....</>;
  }

  return (
    <div className="oneOnOneAi">
      <div className="nav-container">
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <IconButton Icon={BackButtonArrowIcon} />
          <HeaderText text="1-on-1s" />
        </div>
        {view === "ADMIN" && (
          <Link to={`/workflows/library/template-types/one-on-one`}>
            <PrimaryButton text="New 1-on-1" />
          </Link>
        )}
      </div>
      <div className="meetingListContainer">
        <HeaderText
          text={view === "ADMIN" ? "All 1 on 1 sessions" : "Your 1-on-1 Sessions"}
          fontSize={"24px"}
          lineHeight={"24px"}
          letterSpacing={"-0.24px"}
        />
        {/* <div className="overview-container">
          <div className="overview">
            <div className="header">
              <HeaderText
                text={"Engagement overview"}
                fontSize={"24px"}
                lineHeight={"24px"}
                letterSpacing={"-0.24px"}
              />
              <SortingDropdown text={"Last 3 months"} />
            </div>
            <div className="sub">
              <div className="score">
                <div className="aiScore">
                  <div className="aiScore-header">AI score</div>
                  <div className="aiScore-container">
                    <TeambleAiIcon height={24} width={24} />
                    <div className="aiScore-value">80</div>
                    <div className="aiScore-increment">-32%</div>
                  </div>
                </div>
                <div className="aiScore">
                  <div className="aiScore-header">Engagement score</div>
                  <div className="aiScore-container">
                    <div style={{ width: 24, height: 24 }}>
                      <CircularProgressbar
                        value={66}
                        strokeWidth={10}
                        styles={buildStyles({
                          pathColor: "#09B96E",
                          trailColor: "#e0e0e0",
                        })}
                      />
                    </div>
                    <div className="aiScore-value">64.5%</div>
                    <div
                      style={{
                        color: "#09B96E",
                      }}
                      className="aiScore-increment"
                    >
                      +4%
                    </div>
                  </div>
                </div>
                <div className="aiScore">
                  <div className="aiScore-header">Average rating</div>
                  <div className="aiScore-container">
                    <RatingStar height={24} width={24} />
                    <div className="aiScore-value">4.2/5</div>
                    <div
                      style={{
                        color: "#09B96E",
                      }}
                      className="aiScore-increment"
                    >
                      +12%
                    </div>
                  </div>
                </div>
              </div>
              <div className="graph-container">
                <div className="graph-label">
                  <TeambleAiIconWhite height={24} width={24} />
                  <div className="labels">
                    <div className="label-tag">
                      <div className="header-text">Specificity</div>
                      <HeaderText
                        text={"24%"}
                        fontSize={"16px"}
                        lineHeight={"16px"}
                      />
                    </div>
                    <div className="label-tag">
                      <div className="header-text">Tone</div>
                      <HeaderText
                        text={"Friendly"}
                        fontSize={"16px"}
                        lineHeight={"16px"}
                      />
                    </div>
                    <div className="label-tag">
                      <div className="header-text">Focus</div>
                      <HeaderText
                        text={"Critique"}
                        fontSize={"16px"}
                        lineHeight={"16px"}
                      />
                    </div>
                    <div className="label-tag">
                      <div className="header-text">Actionability</div>
                      <HeaderText
                        text={"5%"}
                        fontSize={"16px"}
                        lineHeight={"16px"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bar-graph">
                  <div className="container-graph">
                    <div
                      className="bar"
                      style={{
                        width: "50%",
                        backgroundColor: "#09B96E",
                      }}
                    >
                      <div className="bar-label">
                        <div className="label">Completed</div>
                        <HeaderText
                          text={"31"}
                          fontSize={"16px"}
                          lineHeight={"16px"}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "25%",
                        backgroundColor: "#F8845F",
                      }}
                      className="bar"
                    >
                      <div className="bar-label">
                        <div className="label">Canceled</div>
                        <HeaderText
                          text={"13"}
                          fontSize={"16px"}
                          lineHeight={"16px"}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "25%",
                        backgroundColor: "#FFC096",
                      }}
                      className="bar"
                    >
                      <div className="bar-label">
                        <div className="label">Waiting</div>
                        <HeaderText
                          text={"4"}
                          fontSize={"16px"}
                          lineHeight={"16px"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="summary">
            <div className="header">
              <HeaderText
                text={"AI summary"}
                fontSize={"24px"}
                lineHeight={"24px"}
                letterSpacing={"-0.24px"}
              />
              <TextGradientPrimaryButton text={"Tell me more"} />
            </div>
            <div className="paragraph">
              Over the course of multiple meetings, we gathered valuable
              feedback from team members, with an overall score of 80/100. This
              reflects a largely positive sentiment, with employees feeling
              supported and aligned with their roles and goals. Below is a
              breakdown of the key insights and areas of focus moving forward.
              Key Strengths: Communication: Employees consistently highlighted
              clear and open communication during the 1-on-1s, noting that they
              feel heard and that their concerns are addressed promptly. •
              Support & Guidance: Several team members appreciated the
              individualized attention, with comments about feeling supported in
              their professional development and career goals. Feedback
              indicated that the meetings were effective in offering actionable
              advice and next steps. • Goal Alignment: Many employees reported a
              strong understanding of their objectives and how their work
              contributes to the company’s goals, which boosted morale and
              focus. Areas for Improvement: • More Frequent Check-ins: A
              recurring theme was the desire for more frequent 1-on-1s,
              particularly for team members working on longer-term projects. A
              few employees mentioned they would benefit from shorter, more
              regular touchpoints to maintain alignment. • More Specific
              Feedback: While overall feedback was positive, some employees
              requested more specific, constructive criticism to help them grow
              in their roles. They felt that having a clearer understanding of
              what to improve would accelerate their development. Next Steps: •
              Increase Frequency of 1-on-1s: Based on employee feedback,
              consider increasing the frequency of these meetings, especially
              for high-priority projects or during times of rapid change. •
              Provide Structured, Actionable Feedback: In future 1-on-1s, focus
              on giving more detailed feedback, helping employees pinpoint
              specific areas for improvement along with actionable steps.
            </div>
          </div>
        </div> */}
        <div className="meetingListTabsContainer">
          <div className="tabs">
            <TabButton
              name={MeetingStatus?.Upcoming}
              currentStatus={meetingStatus}
              setActive={setMeetingStatus}
            />
            <TabButton
              name={MeetingStatus?.Past}
              currentStatus={meetingStatus}
              setActive={setMeetingStatus}
            />
          </div>
          <div className="filters">
            {/* <DownloadIcon className="cursor-pointer" width={30} height={30} />
            <FilterIcon className="cursor-pointer" width={30} height={30} /> */}
            {isSearchActive ? (
              <input
                className="global-search"
                placeholder="search"
                value={globalSearch}
                onChange={(e) => {
                  setGlobalSearch(e.target.value);
                }}
              />
            ) : (
              <SearchIcon
                className="cursor-pointer"
                width={30}
                height={30}
                onClick={() => {
                  setSearchActive(true);
                }}
              />
            )}
          </div>
        </div>
        <div className="tableContainer">
          {data && (
            <TableListManagerView
              data={data}
              meetingStatus={meetingStatus}
              globalSearch={globalSearch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OneOnOneFeedback;
