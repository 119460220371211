import { AxiosError } from 'axios';
import { ConfirmationModal, DataState, PageTitle, PrimaryLayoutBox } from 'components';
import { finalizeReview, getActiveReviewById, sendRemindersToAllAudiencePairs } from 'data';
import {
  ActiveReviewModalToShow,
  ActiveReviewUpdateStatus,
  IActiveReview,
  IErrorResponse,
  IReminderFormValues,
  IRouteParams,
} from 'interfaces';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { finalize } from 'rxjs';

import ReviewDetailsCard from './components/ReviewDetailsCard';
import ReviewDetailsPageActions from './components/ReviewDetailsPageActions';
import ReminderNoteModal from './modals/ReminderNoteModal';
import { getReminderRequestData } from './utils/utils';
import { Tab, Row, Col, Nav } from '@themesberg/react-bootstrap';
import ReviewAnalyticsOverview from './ReviewStatusAnalytics';

const ReviewDetailsPage: React.FunctionComponent = () => {

  /* State hooks */
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [activeReview, setActiveReview] = React.useState<IActiveReview>();
  const [reviewUpdateState, setReviewUpdateState] = React.useState<ActiveReviewUpdateStatus>();
  const [modalToShow, setModalToShow] = React.useState<ActiveReviewModalToShow>();

  /* Route hooks */
  const { activeReviewId } = useParams<IRouteParams>();
  const history = useHistory();


  /* Fetch Review data */
  const getActiveReviewData = React.useCallback(
    (): void => {

      if (!activeReviewId) {
        setIsLoading(false);
        setActiveReview(undefined);
        return;
      }

      setIsLoading(true);
      getActiveReviewById(activeReviewId)
        .pipe(
          finalize(
            () => setIsLoading(false)
          )
        ).subscribe(
          {
            next: (response) => setActiveReview(response.data.data),
            error: (error: AxiosError<IErrorResponse>) => {
              if (error.response?.status === 403) {
                history.replace(Routes.ReviewsListPage.path);
              }
            }
          }
        );
    },
    [activeReviewId, history]
  );


  /* Fetch Review data for the first time */
  React.useEffect(
    () => getActiveReviewData(),
    [getActiveReviewData]
  );


  /* Finalize the review after user confirmation */
  const finalizeActiveReview = (): void => {

    setReviewUpdateState('finalizing');

    finalizeReview(activeReview!.id).pipe(
      finalize(
        () => {
          setReviewUpdateState(undefined);
          history.push(Routes.ReviewsListPage.path);
        }
      )
    ).subscribe(
      (response) => {
        toast.success(response.data.message);
      }
    )
  };


  /* Send Reminder to all the reviewers remaining to fill up the review */
  const sendRemindersToAll = (
    reminderNoteFormValues: IReminderFormValues
  ): void => {

    setModalToShow(undefined);
    setReviewUpdateState('sendingReminders');
    const requestData = getReminderRequestData(reminderNoteFormValues);

    sendRemindersToAllAudiencePairs(
      requestData,
      activeReview!.id
    ).pipe(
      finalize(
        () => setReviewUpdateState(undefined)
      )
    ).subscribe(
      (response) => {
        toast.success(response.data.message);
      }
    )
  };

  const reminderExcludeState = [
    "reviewer-declined",
    "nomination-manager-declined",
    "nomination-reviewer-declined",
    "completed",
  ];

  return (
    <DataState
      isDataLoading={isLoading}
      isDataAvailable={!!activeReview}
      emptyStateMessage='No review details available!'
    >
      {
        activeReview &&
        <>
          <PageTitle title={activeReview.title} />
          <PrimaryLayoutBox
            title={activeReview.title}
            actions={
              activeReview.state === 'Live' &&
              <ReviewDetailsPageActions
                reviewUpdateState={reviewUpdateState}
                onActionClick={setModalToShow}
              />
            }
          >
            <ReviewDetailsCard
              activeReview={activeReview}
              onActiveReviewUpdate={getActiveReviewData} />
          </PrimaryLayoutBox>

          {
            modalToShow === 'reminderNote' &&
            <ReminderNoteModal
              show={modalToShow === 'reminderNote'}
              onSubmit={sendRemindersToAll}
              audienceLen={
                (
                  activeReview.reviewerRevieweeInfos.length
                    ? activeReview.reviewerRevieweeInfos.filter(pair => !reminderExcludeState.includes(pair?.state || "")).length
                    : 0
                )
              }
              onClose={() => setModalToShow(undefined)} />
          }

          {
            modalToShow === 'finalizeReview' &&
            <ConfirmationModal
              title='Finalize Review'
              show={modalToShow === 'finalizeReview'}
              onPositiveResponse={finalizeActiveReview}
              onCloseClick={() => setModalToShow(undefined)}
            >
              <p>
                Are you sure to finalize the review <strong>{activeReview.title}</strong>?
              </p>
            </ConfirmationModal>
          }
        </>
      }
    </DataState>
  );
};

// Multipe review tabs for single queryParam review.
const ReviewDetailsTabs = () => {

  // Added state to trigger
  const [currentTab, setCurrentTab] = React.useState("overview");

  return (
    <Tab.Container mountOnEnter defaultActiveKey={currentTab}>
      <Nav className="nav-tabs">
        <Nav.Item>
          <Nav.Link onClick={() => (setCurrentTab("overview"))} eventKey="overview" className="mb-sm-3 mb-md-0">
            Overview
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link onClick={() => (setCurrentTab("status-analytics"))} eventKey="status-analytics" className="mb-sm-3 mb-md-0">
            Analytics
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content className='flex-grow-1 overflow-hidden'>
        <Tab.Pane eventKey="overview" className="h-100 overflow-hidden py-4">
          <ReviewDetailsPage />
        </Tab.Pane>
        {
          currentTab === "status-analytics" && (
            <Tab.Pane eventKey="status-analytics" className="h-100 overflow-hidden py-4">
              <ReviewAnalyticsOverview />
            </Tab.Pane>
          )
        }
      </Tab.Content>
    </Tab.Container >
  )
};

export default ReviewDetailsTabs;
