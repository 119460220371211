import { GoalsList } from '@common/components';
import { Card } from '@themesberg/react-bootstrap';
import { DataState } from 'components';
import { getRevieweeGoals } from 'data';
import { IGoal } from 'interfaces';
import React, { useEffect } from 'react';
import { finalize } from 'rxjs';

interface IRevieweeGoalsListCardProps {
  perfReviewId: string;
  revieweeUserId: string;
}

const RevieweeGoalsListCard: React.FC<IRevieweeGoalsListCardProps> = (
  { revieweeUserId, perfReviewId }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [goalsList, setGoalsList] = React.useState<IGoal[]>([]);


  useEffect(
    () => {

      if (!revieweeUserId || !perfReviewId) {
        setIsLoading(false);
        setGoalsList([]);
        return;
      }

      setIsLoading(true);
      const subscription = getRevieweeGoals(revieweeUserId, perfReviewId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setGoalsList(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [perfReviewId, revieweeUserId]
  );


  return (
    <Card className='overflow-hidden h-100'>
      <Card.Body
        className='h-100 position-relative overflow-y-auto'
        style={{ height: !goalsList.length || isLoading ? 100 : '' }}
      >
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!goalsList.length}
          emptyStateMessage='No goals set!'
        >
          <GoalsList goalsList={goalsList} />
        </DataState>
      </Card.Body>
    </Card>
  );
};

export default RevieweeGoalsListCard;