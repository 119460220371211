import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import IconButton from "../../components/IconButton/IconButton";
import BackButtonArrowIcon from "../../components/SvgReactIcons/BackButtonArrowIcon";
import HeaderText from "../../components/HeaderText/HeaderText";
import TeambleAiIcon from "../../components/SvgReactIcons/TeambleAiIcon";
import AiTagButton from "../../components/AiTagButton/AiTagButton";
import CraftFeedBackModal from "../../components/CraftFeedbackModal/CraftFeedBackModal";
import TextGradientSecondaryBtn from "../../components/TextGradientSecondarybtn/TextGradientSecondaryBtn";
import { io, Socket } from "socket.io-client";
import Markdown from "markdown-to-jsx";
import BouncingDotsLoader from "modules/1-on-1s/components/BouncingLoader/BouncingLoader";
import "./OneOnOneDetail.scss";
import { useHistory, useParams } from "react-router-dom";
import {
  getAiFeedbackByOneOnOneId,
  getOneOnOne,
  getTeambleAIGoalsApi,
} from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import { FeedbackData, oneOnOneDetailListProps } from "modules/1-on-1s/types";
import { useUserProfile } from "components";
import AiFeedbackContainer from "./Components/AiFeedbackContainer/AiFeedback";
import OneOnOneDetailsCard from "./Components/OneonOneDetailsCard/OneOnOneDetailsCard";
import OneOnOnePrepSurvey from "./Components/OneOnOnePrepSurvey/OneOnOnePrepSurvey";
import OneOnOneObjectivesCard from "./Components/OneOnOneObjectivesCard/OneOnOneObjectivesCard";
import { getUserObjective } from "data";
import { AxiosError } from "axios";
import { IObjectiveValues, SignInWith } from "interfaces";
import OneOnOneNotes from "./Components/OneOnOneNotes/OneOnOneNotes";
import { useApp } from "hooks";
import DropDownArrow from "modules/1-on-1s/components/SvgReactIcons/DropdownDownArrow";

interface ChatMessage {
  humanMessage: string;
  aiMessage?: string;
  id: string;
}

interface ChatState {
  chatId: string | null;
  messages: ChatMessage[];
}

interface NewChatMessageData {
  chatId: string;
  inputMessage: string;
  messageId: string;
}

interface AIResponseChunkData {
  chatId: string;
  messageId: string;
  chunk: string;
}

interface AIResponseCompleteData {
  status: boolean;
}

interface InputMetadata {
  profileId: string;
}

const defaultMessage: ChatState = {
  chatId: null,
  messages: [],
};

interface DefaultIdProps {
  name: string;
  id: string;
}

type OneOnOneParams = {
  oneOnOneId: string;
};

const OneOnOneDetail = () => {
  const { userProfile } = useUserProfile();
  const app = useApp();
  const history = useHistory();
  const { oneOnOneId } = useParams<OneOnOneParams>();
  const [showCraftFeedbackModal, setShowCraftFeedbackModal] = useState(false);
  const [isChatEnabled, setIsChatEnabled] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [chatSessionActive, setChatSessionActive] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<oneOnOneDetailListProps | null>(null);
  const [aiFeedback, setAiFeedback] = useState<FeedbackData | null>(null);
  const [isObjectivesLoading, setIsObjectivesLoading] = useState(true);
  const [revieweeObjectives, setRevieweeObjectives] = useState<
    IObjectiveValues[]
  >([]);
  const [defaultId, setDefaultId] = useState<DefaultIdProps | null>(null);
  const isEditFlowAllowed = useMemo(() => {
    return !aiFeedback && data?.reviewerId === userProfile?.teambleUserId;
  }, [aiFeedback, userProfile?.teambleUserId, data?.reviewerId]);
  const isReviewer =
    data && userProfile && data?.reviewerId === userProfile?.teambleUserId;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [currentMessage, setCurrentMessage] =
    useState<ChatState>(defaultMessage);

  useEffect(() => {
    scrollToBottom();
  }, [currentMessage, isChatEnabled]);
  const onModalClose = () => {
    setShowCraftFeedbackModal(false);
  };

  useEffect(() => {
    if (!data?.revieweeId) return;
    if (app === SignInWith.MsTeams) {
      getTeambleAIGoalsApi(data?.revieweeId)
        .pipe(
          finalize(() => {
            setIsObjectivesLoading(false);
          })
        )
        .subscribe({
          next: (response: any) => {
            const goals = response?.data?.data;
            setRevieweeObjectives(goals ?? []);
          },
          error: (error: AxiosError) => {
            console.log(error);
          },
        });
    } else {
      getUserObjective(data?.revieweeId)
        .pipe(
          finalize(() => {
            setIsObjectivesLoading(false);
          })
        )
        .subscribe({
          next: (response: any) => {
            setRevieweeObjectives(response?.data?.data ?? []);
          },
          error: (error: AxiosError) => {
            console.log(error);
          },
        });
    }
  }, [data?.revieweeId, app]);

  useEffect(() => {
    const url = new URL(process.env.REACT_APP_API_URL ?? "");
    const socketUrl: string = url?.origin;
    if (!socketUrl) return;
    const newSocket = io(socketUrl, {
      transports: ["websocket"],
      auth: { token: localStorage.getItem("token") },
      path: "/api/teamble-ai/socket.io",
    });
    setSocket(newSocket);
    // Handle new chat message
    newSocket.on("new_chat_message", handleNewChatMessage);
    // Handle input message
    newSocket.on("input_message", handleInputMessage);
    // Handle AI response chunk
    newSocket.on("ai_response_chunk", handleAIResponseChunk);
    // Handle AI response completion
    newSocket.on("ai_response_complete", handleAIResponseComplete);
    return () => {
      newSocket.disconnect();
    };
  }, [chatSessionActive]);

  const handleNewChatMessage = (data: NewChatMessageData) => {
    setCurrentMessage({
      chatId: data.chatId,
      messages: [{ humanMessage: data.inputMessage, id: data.messageId }],
    });
  };

  const handleInputMessage = (data: NewChatMessageData) => {
    setCurrentMessage((prev: ChatState) => {
      if (prev?.chatId === data?.chatId) {
        return {
          ...prev,
          messages: [
            ...prev.messages,
            { humanMessage: data.inputMessage, id: data.messageId },
          ],
        };
      }
      return prev;
    });
  };

  const handleAIResponseChunk = (data: AIResponseChunkData) => {
    setCurrentMessage((prev: any) => {
      const updatedMessages = prev.messages.map((message: any) =>
        message.id === data.messageId
          ? { ...message, aiMessage: (message.aiMessage || "") + data.chunk }
          : message
      );
      return { ...prev, messages: updatedMessages };
    });
  };

  const handleAIResponseComplete = (data: AIResponseCompleteData) => {
    if (data?.status) {
      setIsSendingMessage(false);
    }
  };

  const handleChatWithAi = useCallback(
    async (item) => {
      // this will be take care later
      const profileId = defaultId?.id || data?.revieweeId;
      const inputMetaData = {
        profileId,
      };

      if (socket && item.trim() && !isSendingMessage) {
        socket.emit("ai_chat_message", {
          id: currentMessage?.chatId ?? null,
          inputMessage: item,
          inputMetaData,
        });
      }
    },
    [socket, currentMessage, isSendingMessage, data, defaultId]
  );

  const toggleAiChat = () => {
    if (defaultId?.id === data?.revieweeId) {
      setDefaultId({
        name: data?.reviewerName || "",
        id: data?.reviewerId || "",
      });
    } else {
      setDefaultId({
        name: data?.revieweeName || "",
        id: data?.revieweeId || "",
      });
    }
  };

  useLayoutEffect(() => {
    if (!oneOnOneId) {
      history.goBack();
      return;
    }
    setIsLoading(true);
    getOneOnOne(oneOnOneId)
      .pipe(
        finalize(() => {
          setIsLoading(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          setData(response?.data?.oneOnOne);
          setDefaultId({
            name: response?.data?.oneOnOne?.revieweeName || "",
            id: response?.data?.oneOnOne?.revieweeId || "",
          });
        },
        error: (error) => {
          console.log(error);
          history.goBack();
        },
      });
  }, [oneOnOneId]);

  useLayoutEffect(() => {
    if (!oneOnOneId) {
      console.warn("Invalid oneOnOneId");
      return;
    }
    getAiFeedbackByOneOnOneId(oneOnOneId)
      .pipe()
      .subscribe({
        next: (response: any) => {
          setAiFeedback(response?.data?.feedback);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }, [oneOnOneId]);



  if (isLoading || isObjectivesLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <div className="OneOnOneDetail">
        <div className="OneOnOneDetail-navbar">
          <IconButton Icon={BackButtonArrowIcon} previousPath="/one-on-ones/" />
          <HeaderText
            text={data?.revieweeName ?? ""}
            fontSize={"30px"}
            letterSpacing={"-0.3px"}
          />
        </div>
        <div className="OneOnOneDetail-container">
          <div className="notes-container">
            {(aiFeedback?.isSubmitted || isReviewer) && (
              <AiFeedbackContainer aiFeedback={aiFeedback} data={data} />
            )}

            <OneOnOneDetailsCard data={data} />
            {data?.id && (
              <OneOnOnePrepSurvey data={data} oneOnOneId={data.id} />
            )}
            <OneOnOneNotes data={data} />
            <OneOnOneObjectivesCard revieweeObjectives={revieweeObjectives} />
          </div>
          {((isReviewer &&  isEditFlowAllowed) || userProfile?.isSuperAdmin) && (
            <div
              className="aiAssistant"
              onFocus={() => setChatSessionActive(true)}
              onBlur={() => setChatSessionActive(false)}
            >
              <div className="header">
                <div className="assistant-header">
                  <TeambleAiIcon height={30} width={30} />
                  <HeaderText
                    text={"AI Assistant"}
                    fontSize={"24px"}
                    line-height={"24px"}
                    letter-spacing={"-0.24px"}
                  />
                </div>
                {defaultId && userProfile?.isSuperAdmin && (
                  <div
                    title={defaultId?.name}
                    onClick={toggleAiChat}
                    className="view"
                  >
                    <div className="name">{defaultId?.name}</div>
                    <DropDownArrow width={12} height={12} color="#9E9FA2" />
                  </div>
                )}
              </div>
              <div className="aiAssistant-body">
                <div
                  style={{
                    height: isEditFlowAllowed ? "calc(100% - 76px)" : "100%",
                  }}
                  className="chat"
                >
                  {!isChatEnabled ? (
                    <>
                      {userProfile?.isSuperAdmin && (
                        <AiTagButton
                          name={"Analyze performance review campaigns"}
                          disabled={isSendingMessage}
                          onClick={(name) => {
                            handleChatWithAi(name);
                            setIsChatEnabled(true);
                            setIsSendingMessage(true);
                          }}
                        />
                      )}
                      {userProfile?.isSuperAdmin && (
                        <AiTagButton
                          name={"Analyze employee feedback"}
                          disabled={isSendingMessage}
                          onClick={(name) => {
                            handleChatWithAi(name);
                            setIsChatEnabled(true);
                            setIsSendingMessage(true);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <div className="chat-container">
                      <div className="ai-chat-container">
                        {currentMessage?.messages?.map((item, index) => (
                          <div className="message-box" key={index}>
                            <Markdown>{item?.aiMessage ?? ""}</Markdown>
                          </div>
                        ))}
                        {isSendingMessage && <BouncingDotsLoader />}
                        <div ref={messagesEndRef} />
                      </div>
                      <div className="ai-cards-container">
                        <div className="ai-cards">
                          {userProfile?.isSuperAdmin && (
                            <AiTagButton
                              name={"Analyze performance review campaigns"}
                              disabled={isSendingMessage}
                              onClick={(name) => {
                                setIsChatEnabled(true);
                                handleChatWithAi(name);
                                setIsSendingMessage(true);
                              }}
                            />
                          )}

                          {userProfile?.isSuperAdmin && (
                            <AiTagButton
                              name={"Analyze employee feedback"}
                              disabled={isSendingMessage}
                              onClick={(name) => {
                                setIsChatEnabled(true);
                                handleChatWithAi(name);
                                setIsSendingMessage(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {isEditFlowAllowed && (
                  <div className="craft-feedback-container">
                    <TextGradientSecondaryBtn
                      onClick={() => {
                        setShowCraftFeedbackModal(true);
                      }}
                      text={"Help me craft feedback"}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {showCraftFeedbackModal && (
        <CraftFeedBackModal close={onModalClose} oneOnOneId={oneOnOneId} />
      )}
    </>
  );
};

export default OneOnOneDetail;
