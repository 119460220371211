import { Col, Container, Row } from '@themesberg/react-bootstrap';
import { CustomSelect, EmptyState, Preloader, useAuth, useUserProfile } from 'components';
import { completeEnterpriseGridAuthorization, getEnterpriseWorkspacesAsMenuOptions } from 'data';
import { useOptionsAndSelectedOption } from 'hooks';
import { IChannelMenuOption } from 'interfaces';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { StringParam, useQueryParam } from 'use-query-params';

interface ISelectSlackWorkspaceProps {
}

const SelectSlackWorkspace: React.FunctionComponent<ISelectSlackWorkspaceProps> = () => {

  const auth = useAuth();

  const { setUserProfile } = useUserProfile();

  const history = useHistory();
  const [idToken] = useQueryParam('idToken', StringParam);
  const [authorizationId] = useQueryParam('authorization', StringParam);

  const {
    isLoading: isDataLoading,
    options: workspaceOptions,
    selectedOption: selectedWorkspaceOption,
  } = useOptionsAndSelectedOption<IChannelMenuOption>(
    getEnterpriseWorkspacesAsMenuOptions(idToken as string),
    [],
    false,
    false
  );


  const completeAuthorization = (option: IChannelMenuOption): void => {


    if (!authorizationId || !idToken) {
      history.push(Routes.Signin.path);
    }

    completeEnterpriseGridAuthorization(
      authorizationId as string,
      idToken as string,
      option.value
    ).subscribe(
      {
        next: (response) => {

          if (response.data.data.type === 'redirect') {
            window.location.assign(response.data.data.redirectUrl);
            return;
          }

          if (response.data.data.idToken) {
            localStorage.setItem('token', response.data.data.idToken);
            auth.setIsSignedIn(true);
            setUserProfile(response.data.data.profileDetail);
          }
        },
        error: () => {
          history.push(Routes.AuthorizationError.path);
        }
      }
    );

  };


  return (
    <main className="h-100 d-flex align-items-center justify-content-center">
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">

                <div className="text-center mb-3">
                  <img
                    src="https://static.teamble.com/logos/teamble-logo--gradient.svg"
                    alt="Teamble - Gradient logo"
                    width="200" />
                </div>

                <div
                  className="position-relative"
                  style={{
                    height: isDataLoading || !workspaceOptions?.length ? '170px' : 'auto'
                  }}
                >
                  {
                    isDataLoading
                      ?
                      <Preloader show={isDataLoading} />
                      :
                      workspaceOptions?.length
                        ?
                        <>
                          <div className="text-center text-md-center mb-4 mt-md-0">
                            <h3 className="mb-0">Select Primary Workspace</h3>
                          </div>

                          <div>
                            <CustomSelect
                              isSearchable
                              options={workspaceOptions}
                              placeholder='Select a primary workspace...'
                              value={selectedWorkspaceOption || undefined}
                              onChange={(option) => completeAuthorization(option as IChannelMenuOption)} />
                          </div>
                        </>
                        :
                        <EmptyState
                          message={
                            <>
                              <h5>No workspaces available</h5>
                              <p>You should be a member of at least one workspace that has Teamble installed.</p>
                            </>
                          }
                        />
                  }
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default SelectSlackWorkspace;
