import React from "react";
import "./SecondaryButton.scss";

interface SecondaryButtonProps {
  name: string;
  onClick?: () => void;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ name, onClick }) => {
  return (
    <button onClick={() => onClick?.()} className="SecondaryButton">
      {name}
    </button>
  );
};

export default SecondaryButton;
