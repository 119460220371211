import { IChannelMenuOption, IMemberOption, ISimpleMenuOption } from "./menuOptions.interface";

export interface ISurveyDepartmentInsight {
  id: string;
  question: string;
  departments: ISurveyDepartment[];
}

export interface ISurveyDepartment {
  name: string;
  count: number;
  value: string;
  color: string;
}

// export interface ISurveyTimelineInsight {
//   count: number;
//   value: string;
//   createdAt: number;
//   closedAt: number;
// }

export interface IFeedbackInsights {
  membershipId?: string,
  teambleTeamIds?: string[]
}

export interface StartEndDates {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export interface IFeedbackLeaderBoardUser {
  user_name: {
    name: string;
    title: string;
    img: string,
  },
  feedback_sent: number;
  feedback_requested: number;
  feedback_received: number;
  praise_sent: number;
  survey_submitted: number;
  total: number;
}

export interface ICompetencyPerformanceScore {
  campaignId: string;
  campaignName: string;
  createdAt: number,
  competencies: {
    name: string;
    value: number;
    color: string;
  }[];
}

export enum GroupFeedbackTypeFilters {
  AllFeedback = 'all_feedback',
  GroupOnly = 'group',
  User = 'user'
}