import React, { Dispatch, SetStateAction } from "react";
import "./GradientPrimaryButton.scss";

interface GradientPrimaryButtonProps {
  text: string;
  onClick?: Function;
  disabled?: boolean;
  isLoading?: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  loadingText?: string;
}
const GradientPrimaryButton: React.FC<GradientPrimaryButtonProps> = ({
  text,
  onClick,
  disabled,
  isLoading,
  setIsLoading,
  loadingText = "Generating...",
}) => {
  return (
    <button
      onClick={() => {
        onClick?.();
      }}
      className={`GradientPrimaryButton ${
        disabled ? "GradientPrimaryButton-disabled" : ""
      }`}
    >
      {isLoading ? loadingText : text}
    </button>
  );
};

export default GradientPrimaryButton;
