import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import { IModalProps, IReviewNominateAudience } from 'interfaces';
import * as React from 'react';


interface IInvalidPairsTableProps {
  invalidAudiencePairs:  Array<IReviewNominateAudience> | null;
  title?: string;
  subtitle?: string;
}

interface IInvalidNominateAudienceModalProps extends IModalProps, IInvalidPairsTableProps {
}


const InvalidPairsTable: React.FunctionComponent<IInvalidPairsTableProps> = (
  { invalidAudiencePairs }
) => {

  return (
    <Table className="table-centered table-nowrap rounded mb-0 position-relative">
      <thead>
        <tr>
          <th className="position-sticky top-0">Reviewee</th>
        </tr>
      </thead>
      <tbody>
        {
          invalidAudiencePairs?.map(
            (invalidAudiencePair) => {
              return (
                <tr key={`${invalidAudiencePair!.email}`}>
                  <td className="border-0">
                    {invalidAudiencePair!.email}
                  </td>
                </tr>
              );
            }
          )
        }
      </tbody>
    </Table>
  );
};


const InvalidNominateAudienceModal: React.FunctionComponent<IInvalidNominateAudienceModalProps> =
  ({ invalidAudiencePairs, show, onClose, title='', subtitle = '' }) => {
    

    return (
      <Modal
        as={Modal.Dialog}
        centered
        show={show}
        onHide={onClose}
        size='lg'
      >
        <Modal.Header className='flex-wrap'>
          <Modal.Title className='w-100'>{ title || 'Invalid Audience' }</Modal.Title>
          <p>
        {subtitle || 'These audience are not added in the list as reviewee does not belong to the selected workspace.'}          </p>
        </Modal.Header>
        <Modal.Body>
          <InvalidPairsTable invalidAudiencePairs={invalidAudiencePairs} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className="ms-auto" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

export default InvalidNominateAudienceModal;
