import React from "react";
import { useParams } from "react-router-dom";
import FeedbackLoopConfigPage from "./FeedbackLoopConfigPage";
import OneOnOneConfigPage from "./one-on-one/OneOnOneConfigPage";
import { IFeedbackLoopTemplateTypes, IRouteParams } from "interfaces";

const WorkFlowConfigPage = () => {

  const { templateType } = useParams<IRouteParams>();

  const templateComponents: Record<string, React.FC> = {
    [IFeedbackLoopTemplateTypes.Feedback]: FeedbackLoopConfigPage,
    [IFeedbackLoopTemplateTypes.OneOnOne]: OneOnOneConfigPage,
  };

  const WorkflowComponent = templateComponents[templateType ?? ""];

  if (WorkflowComponent) {
    return <WorkflowComponent />
  }

  return (
    <div>Workflow not found</div>
  )

};

export default WorkFlowConfigPage;
