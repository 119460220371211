import { IUserProfile, SignInWith, UserPermissions } from 'interfaces';


export const getUserProfileWithPermissions = (userProfile: IUserProfile): IUserProfile => {

  let permissions: UserPermissions[] = [];
  const isAppSlack = userProfile.app === SignInWith.Slack;
  const isAppTeams = userProfile.app === SignInWith.MsTeams;
  const isSuperAdmin = userProfile.isSuperAdmin;
  const isTeamAdmin = userProfile.isTeamAdmin;
  const isManager = userProfile.isManager;
  const isAdmin = isSuperAdmin || isTeamAdmin;
  const isRelationshipsPage = !!userProfile?.isRelationship;

  const permissionsWithFlag: { [key in UserPermissions]: boolean } = {
    // All
    [UserPermissions.AllUserRoleRouteAccess]: true,

    // Review
    [UserPermissions.ViewReviews]: isAppSlack || isAppTeams || isSuperAdmin,
    [UserPermissions.ViewReviewBuilder]: isAdmin,
    [UserPermissions.ViewManageReviews]: isAdmin,
    [UserPermissions.ViewReviewReports]: isAdmin || isManager,
    [UserPermissions.ViewReviewReportThirdLevelRow]: isAdmin,
    // [UserPermissions.ViewUserPerformanceReviewInsights]: isAppSlack &&
    // (isSuperAdmin || (!isTeamAdmin && isManager) || (!isTeamAdmin && !isManager)),
    [UserPermissions.ViewTodosPage]: isAppSlack || isAppTeams,
    [UserPermissions.ViewMyReviewsPage]: isAppSlack || isAppTeams,

    // Survey
    [UserPermissions.ViewSurveys]: isAdmin,
    [UserPermissions.ViewLaunchedByOthersSurveysFilter]: isSuperAdmin,
    [UserPermissions.PerformSurveyTemplateActions]: isSuperAdmin,
    [UserPermissions.CreateUpdateSurveyTemplate]: isSuperAdmin,
    [UserPermissions.LockUnlockSurveyQuestion]: isAppSlack && isSuperAdmin,

    // Analytics
    [UserPermissions.ViewAnalytics]: isAdmin,
    [UserPermissions.ViewSurveyAnalyticsOverviewTab]: isAppSlack && isAdmin,
    [UserPermissions.ViewSurveyAnalyticsQuestionTimelineTab]: isAppSlack && isAdmin,
    [UserPermissions.ViewGroupFeedbackTypeFilter]: !isAppSlack && isAdmin,

    // Organization
    [UserPermissions.ViewOrganization]: isSuperAdmin,

    // Admins
    [UserPermissions.ViewAdmins]: isAdmin,
    [UserPermissions.ViewSuperAdmins]: isSuperAdmin,

    // Users
    [UserPermissions.ViewUsers]: isSuperAdmin,
    [UserPermissions.UpdateUserManagers]: isSuperAdmin,
    [UserPermissions.ViewManagees]: isAppSlack && isManager,
    [UserPermissions.ViewUserStatusFilter]: isAppSlack,
    [UserPermissions.ViewUserOrganizationFilter]: isAppSlack,

    // Roles
    [UserPermissions.ViewRoles]: isSuperAdmin,
    [UserPermissions.PerformRolesActions]: isSuperAdmin,

    // Departments
    [UserPermissions.ViewDepartments]: isSuperAdmin,
    [UserPermissions.PerformDepartmentActions]: isSuperAdmin,
    [UserPermissions.ViewDepartmentDetails]: !isAppSlack && isSuperAdmin,

    // Competencies
    [UserPermissions.ViewCompetencies]: isSuperAdmin,
    [UserPermissions.PerformCompetencyActions]: isSuperAdmin,

    // Goals
    [UserPermissions.PerformGoalsAction]: isAppSlack &&
      ((isSuperAdmin || (!isTeamAdmin && isManager) || (!isTeamAdmin && !isManager)
        || (isTeamAdmin && isManager))),

    // Relationships
    [UserPermissions.ViewRelationShipPage]: isSuperAdmin && isRelationshipsPage,
    [UserPermissions.PerformanceRelationshipActions]: isSuperAdmin && isRelationshipsPage,

    // Groups
    [UserPermissions.ViewGroups]: !isAppSlack && isSuperAdmin,
    [UserPermissions.ViewMyGroupsPage]: !isAppSlack && isTeamAdmin,

    // Feedback Loops
    [UserPermissions.ViewFeedbackLoops]: (isAppSlack && isAdmin) || (!isAppSlack && isSuperAdmin),
    [UserPermissions.ViewLaunchedByOthersFeedbackLoopsFilter]: isSuperAdmin,

    // Integration
    [UserPermissions.ViewIntegrationsListPage]: isSuperAdmin,

    // Settings
    [UserPermissions.ViewSettingsModal]: !isAppSlack && isSuperAdmin,

    // Feedback
    [UserPermissions.ViewFeedbackSenderFilter]: !isAppSlack,

    // Member Profile
    [UserPermissions.ViewPerformanceTab]: isAppSlack && (isSuperAdmin),
    [UserPermissions.viewAiFeedback]:isAppSlack||isAppTeams
  };

  permissions = Object.entries(permissionsWithFlag)
    .filter(
      ([_, value]) => value
    )
    .map(
      ([permission]) => permission as UserPermissions
    );

  return {
    ...userProfile,
    permissions
  };

};