import { Form } from '@themesberg/react-bootstrap';
import { useCompanyMemberMenuOptions } from 'hooks';
import { IMemberOption } from 'interfaces';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { getSelectedOptionByQueryParam } from 'utils';
import { CustomSelect } from 'components';
import { NONE_OPTION } from 'teamble-constants';

interface ICompanyMembersFilterProps {
  label: string;
}

const CompanyMembersFilter: React.FC<ICompanyMembersFilterProps> = (
  { label }
) => {

  // Options states
  const { isLoading, memberOptions } = useCompanyMemberMenuOptions();

  // Route Hooks
  const [member, setMember] = useQueryParam('member', StringParam);

  // Selected Options by query params
  const selectedMemberOption = getSelectedOptionByQueryParam(
    member, memberOptions, 'teambleUserId'
  );


  return (
    <div>
      <Form.Label>{label}</Form.Label>
      <CustomSelect
        isSearchable
        isLoading={isLoading}
        options={[NONE_OPTION, ...memberOptions]}
        value={selectedMemberOption}
        onChange={(option) => {
          setMember((option as IMemberOption).teambleUserId);
        }}
      />
    </div>
  );
};

export default CompanyMembersFilter;