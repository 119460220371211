import { IChannelsAudience, IGroupsAudience, IUsersAudience } from "interfaces";
import React from "react";

export const useIsChannelInAudience = (audience: (IUsersAudience | IChannelsAudience | IGroupsAudience)[] | undefined): boolean => {

  const [isChannelInAudience, setIsChannelInAudience] = React.useState<boolean>(false);

  React.useEffect(
    () => {

      const channelAudience = audience?.some(
        (aud) => aud.type === 'channels'
      ) || false;

      setIsChannelInAudience(channelAudience);
    },
    [audience]
  );


  return isChannelInAudience;
};