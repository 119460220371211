import Axios, { AxiosObservable } from 'axios-observable';
import {
  IManagerAddReviewersReqData,
  IManagerAddReviewersResponse,
  INominationActionRequestData,
  IResponse,
  IRevieweeReview,
  IReviewNominationActionData,
  IReviewOwnerTodoNominationData,
  ISubmitRevieweeTodosRequestData,
} from 'interfaces';

export const submitRevieweeTodos = (
  data: ISubmitRevieweeTodosRequestData
): AxiosObservable<IResponse<void>> => {

  return Axios.post<IResponse<void>>(
    "/submit-reviewee-nomination",
    { ...data }
  );
};


export const getReviewManagerApproveNominationData =
  (campaignId: string, revieweeId: string): AxiosObservable<IResponse<IReviewNominationActionData>> => {

    return Axios.post<IResponse<IReviewNominationActionData>>(
      "/detail-manager-nomination-todos",
      {
        campaignId, revieweeId
      }
    );
  };


export const submitManagerTodos = (
  data: INominationActionRequestData
): AxiosObservable<IResponse<void>> => {

  return Axios.post<IResponse<void>>(
    "/submit-manager-nomination",
    { ...data }
  );
};


export const getReviewReviewerApproveNominationData =
  (campaignId: string): AxiosObservable<IResponse<IReviewNominationActionData>> => {

    return Axios.post<IResponse<IReviewNominationActionData>>(
      "/detail-reviewer-nomination-todos",
      { campaignId }
    );
  };


export const submitReviewerTodos = (
  data: INominationActionRequestData
): AxiosObservable<IResponse<void>> => {

  return Axios.post<IResponse<void>>(
    "/submit-reviewer-nomination",
    { ...data }
  );
};


export const getReviewOwnerTodoNominationData =
  (reviewId: string, revieweeId: string): AxiosObservable<IResponse<IReviewOwnerTodoNominationData>> => {

    return Axios.post<IResponse<IReviewOwnerTodoNominationData>>(
      "/company-workspaces",
      {
        reviewId, revieweeId
      }
    );
  };


export const addReviewersNominationByManager =
  (data: IManagerAddReviewersReqData): AxiosObservable<IResponse<IManagerAddReviewersResponse>> => {

    return Axios.post<IResponse<IManagerAddReviewersResponse>>(
      "/submit-reviewers-by-manager",
      { ...data }
    );
  };


export const getRevieweePerformanceReviews = (
  PReviewId: string
): AxiosObservable<IResponse<IRevieweeReview[]>> => {

  return Axios.post<IResponse<IRevieweeReview[]>>(
    "/list-todo-reviews",
    { PReviewId }
  );
};