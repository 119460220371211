import { IMsTeamsChannelUser, ISlackWorkspaceUser } from "interfaces";

export const getManagerTo = (
  managesTo: ISlackWorkspaceUser[] | IMsTeamsChannelUser[] | undefined
): string => {

  return managesTo?.length
    ?
    managesTo?.map((managesTo) => managesTo.name).join(', ')
    :
    'Not Set'
};