import { If, ReviewReportsPreviewModal } from '@common/components';
import { Button, Card } from '@themesberg/react-bootstrap';
import { Member } from 'components';
import { IRevieweeReview } from 'interfaces';
import React from 'react';

interface IRevieweeReviewCardProps {
  review: IRevieweeReview;
}

const RevieweeReviewCard: React.FC<IRevieweeReviewCardProps> = (
  { review }
) => {

  /* State Hooks */
  const [showReviewReportsPreviewModal, setShowReviewReportsPreviewModal] = React.useState<boolean>(false);


  return (
    <>
      <Card className='card-shadow'>
        <Card.Body className='d-flex justify-content-between align-items-start'>
          <div>
            <h5>{review.title}</h5>
            <Member member={{
              value: review.reviewerName,
              label: review.reviewerName
            }} />
          </div>
          <Button
            size='sm'
            variant='outline-primary'
            onClick={() => setShowReviewReportsPreviewModal(true)}
          >
            View
          </Button>
        </Card.Body>
      </Card>

      <If condition={showReviewReportsPreviewModal}>
        <ReviewReportsPreviewModal
          show={showReviewReportsPreviewModal}
          reviewIds={[review.id]}
          onClose={() => setShowReviewReportsPreviewModal(false)}
        />
      </If>
    </>
  );
};

export default RevieweeReviewCard;