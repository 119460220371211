import { Spinner } from '@themesberg/react-bootstrap';
import * as React from 'react';

interface IPreloaderProps {
  show: boolean;
  isIcon?: boolean;
  transparentBg?: boolean;
}

const Preloader: React.FunctionComponent<IPreloaderProps> = (
  { show, isIcon = false, transparentBg = true }
) => {
  return (
    <>
      {
        isIcon ?
          (
            <Spinner animation="border" role="status" size='sm'>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <div
              className={`preloader flex-column justify-content-center align-items-center ${show ? "" : "show"} ${transparentBg ? 'transparent' : ''}`}
            >
              <div className='loader-ring'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default Preloader;
