import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CustomDropdown, Divider, PrimaryLayoutBox } from 'components';
import { MetaDataContext } from 'contexts';
import { createReviewTemplate, updateReviewTemplate } from 'data';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { useApp } from 'hooks';
import { IMenuOption, IMetadata, IReviewTemplate, IReviewTemplateFormValues } from 'interfaces';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { finalize } from 'rxjs';

import { getReviewFormValidationSchema, getReviewTemplateQuestionToAdd, getReviewTemplateRequestData } from '../utils/utils';
import ReviewTemplateForm from './ReviewTemplateForm';
import ReviewTemplateQuestionPreviewWidget from './ReviewTemplateQuestionPreviewWidget';


interface IReviewTemplateBuilderWidgetProps {
  reviewTemplate: IReviewTemplate | null;
  isPredefinedTemplate?: boolean;
}

const ReviewTemplateBuilderWidget: React.FunctionComponent<IReviewTemplateBuilderWidgetProps> = (
  { reviewTemplate, isPredefinedTemplate = false }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const [activeQuestionIndex, setActiveQuestionIndex] = React.useState<number | null>(null);

  const history = useHistory();

  const app = useApp();


  const getInitialReviewFormValues: () => IReviewTemplateFormValues = () => {
    return {
      title: '',
      blocks: []
    };
  };


  const handleReviewTemplateFormSubmit =
    (values: IReviewTemplateFormValues, formikHelpers: FormikHelpers<IReviewTemplateFormValues>): void => {

      let submitForm$ = null;

      const requestData = getReviewTemplateRequestData(metadata as IMetadata, values, app);
      formikHelpers.setSubmitting(true);

      if (!isPredefinedTemplate && reviewTemplate && reviewTemplate.id) {
        submitForm$ = updateReviewTemplate(reviewTemplate.id, requestData);
      } else {
        submitForm$ = createReviewTemplate(requestData);
      }

      submitForm$.pipe(
        finalize(
          () => formikHelpers.setSubmitting(false)
        )
      ).subscribe(
        {
          next: (response) => {
            toast.success(response.data.message);
            formikHelpers.resetForm({ values });
            history.push(Routes.PerformanceReviewQuestionnaires.path);
          }
        }
      );
    };


  const reviewForm: FormikProps<IReviewTemplateFormValues> =
    useFormik<IReviewTemplateFormValues>({
      initialValues: getInitialReviewFormValues(),
      validationSchema: getReviewFormValidationSchema(metadata, app),
      onSubmit: handleReviewTemplateFormSubmit,
    });


  React.useEffect(
    () => {

      if (reviewTemplate) {

        let reviewFormValue = reviewTemplate;
        if (!reviewTemplate.blocks?.length) {
          reviewFormValue['blocks'] = [];
        }
        reviewForm.resetForm({ values: reviewFormValue });
        setActiveQuestionIndex(reviewTemplate.blocks?.length ? 0 : null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewTemplate]
  );


  const handleQuestionAdd = (option: IMenuOption) => {

    const questionBlock = getReviewTemplateQuestionToAdd(
      metadata as IMetadata,
      option,
      reviewForm.values.blocks,
      app
    );

    reviewForm.setFieldValue(
      'blocks',
      [
        ...reviewForm.values.blocks,
        { ...questionBlock }
      ],
      true
    );

    setActiveQuestionIndex(
      reviewForm.values.blocks.length || 0
    );

  };


  return (
    <>
      <div className='d-flex overflow-hidden flex-grow-1'>
        <div className='h-100 d-flex flex-column flex-grow-1 flex-basis-33 pt-1 mw-33 flex-shrink-0'>

          <PrimaryLayoutBox
            title={Routes.PerformanceReviewTemplateBuilder.title}
            description='Build your performance review questionnaire here'
            actions={
              <>
                {
                  metadata && metadata.questionTypes &&
                  <CustomDropdown
                    id='add-question-dropdown'
                    variant='outline-primary'
                    title='Add Question'
                    options={metadata.questionTypes.options}
                    icon={faPlus}
                    onChange={handleQuestionAdd}
                  />
                }
              </>
            }
          >
            <ReviewTemplateForm
              reviewForm={reviewForm}
              activeQuestionIndex={activeQuestionIndex}
              onActiveQuestionIndexChange={setActiveQuestionIndex} />
          </PrimaryLayoutBox>

        </div>

        <Divider isVertical={true} />

        <div className='h-100 d-flex flex-column flex-grow-1 pt-1 overflow-hidden'>
          {
            <PrimaryLayoutBox
              title='Preview'
              description='Preview your questionnaire here'
            >
              {
                reviewForm.values.blocks?.length ?
                  (
                    <ReviewTemplateQuestionPreviewWidget
                      reviewForm={reviewForm}
                      activeQuestionIndex={activeQuestionIndex} />
                  ) :
                  (null)
              }
            </PrimaryLayoutBox>
          }
        </div>
      </div>
      {/* <Prompt
        when={reviewForm.dirty}
        message='You have unsaved changes. Are you sure to leave the page?' /> */}
    </>
  );
};

export default ReviewTemplateBuilderWidget;