import { FormErrors, IGoalFormValues } from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const GOAL_VALIDATION_SCHEMA: Yup.SchemaOf<IGoalFormValues> =
  Yup.object().shape(
    {
      name: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      description: Yup.string(),
      deadline: Yup.string().optional(),
      competency: Yup.array().of(
        SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.shape({
          subOptions: Yup.array().of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
        })
      ),
      behaviors: Yup.array().of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA),
    }
  );