import { Button, Card } from '@themesberg/react-bootstrap';
import { IMember } from 'interfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import EmptyState from './EmptyState';
import Member from './Member';

interface IMembersListWidgetProps {
  memberDetailsPath: string;
  members: IMember[] | undefined;
}


const MembersListWidget: React.FC<IMembersListWidgetProps> = (
  { members, memberDetailsPath }
) => {

  return (
    <Card style={{ maxHeight: 415 }}>

      <Card.Header>
        <Card.Title className='m-0'>
          Members
        </Card.Title>
      </Card.Header>

      <Card.Body
        className='position-relative overflow-y-auto'
        style={{ height: members?.length ? 'auto' : 100 }}
      >
        {
          members?.length
            ?
            <>
              {
                members.map(
                  (member) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center my-2" key={member.id}>
                        <Member member={{
                          value: member.id,
                          label: member.name,
                          imageOriginal: member.imageOriginal,
                          description: member.title?.title
                        }} />
                        <Link to={`${memberDetailsPath}/${member.teambleUserId}`}>
                          <Button variant='outline-primary' size='sm'> View </Button>
                        </Link>
                      </div>
                    )
                  }
                )
              }
            </>
            :
            <EmptyState message={<>No members available!</>} />
        }
      </Card.Body>
    </Card>
  );
};

export default MembersListWidget;