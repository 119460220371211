import {
  EmptyState,
  GoalsWidget,
  PageTitle,
  Preloader,
  PrimaryLayoutBox,
  useUserProfile,
  ObjectiveWidget
} from 'components';
import { getTeammateProfile } from 'data';
import { IMemberWithRelationships, IRouteParams } from 'interfaces';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import { StringParam, useQueryParam } from 'use-query-params';

import UserDetailsTabsContainer from './components/UserDetailsTabsContainer';
import UserDetailsWidget from './components/UserDetailsWidget';
import { UserDetailsContext } from './contexts/UserDetailsContext';
import { useCompanyConfig } from '@common/modules/companyConfig/hooks/useCompanyConfig';

interface IUserProfilePageProps {
  context: string;
}

const UserProfilePage: React.FC<IUserProfilePageProps> = (
  { context }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userDetails, setUserDetails] = React.useState<IMemberWithRelationships | null>(null);
  const [pageTitle, setPageTitle] = React.useState<string>('');
  const { userProfile } = useUserProfile();
  const { userId, relationshipId } = useParams<IRouteParams>();

  const companyConfig = useCompanyConfig();


  // const [relationshipId] = useQueryParam('relationshipId', StringParam);


  React.useEffect(
    () => {

      if (!userId) {
        return;
      }

      setIsLoading(true);
      setUserDetails(null);

      const subscription = getTeammateProfile(userId, relationshipId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setUserDetails(response.data.data);
          }
        );

      return () => subscription.unsubscribe();

    },
    [relationshipId, userId]
  );


  React.useEffect(
    () => {
      //check logged in user with selected user
      if (
        (userProfile?.teambleUserId && (userProfile.teambleUserId === userDetails?.teambleUserId))
      ) {
        setPageTitle(Routes.MyProfilePage.title);
      } else {
        setPageTitle(Routes.UserDetails.title);
      }

    },
    [userDetails, userProfile]
  );



  return (
    <>
      <PageTitle title={
        `${userDetails ? userDetails.name : ''} | ${pageTitle}`
      } />
      <PrimaryLayoutBox title={pageTitle}>
        {
          isLoading
            ?
            <Preloader show={isLoading} />
            :
            <>
              {
                userDetails
                  ?
                  <UserDetailsContext.Provider value={{ userDetails, onUserDetailsUpdate: setUserDetails }}>
                    <div className="d-flex flex-grow-1 overflow-hidden">
                      <div style={{ width: 440 }} className='flex-shrink-0 d-flex flex-column'>
                        <div className='flex-shrink-0'>
                          <UserDetailsWidget />
                        </div>
                        {
                          (userDetails?.teambleUserId) &&
                          <div className='flex-grow-1 overflow-hidden'>
                            {/* {
                              companyConfig.useObjectivesInWebAdmin
                                ? <ObjectiveWidget profileVieweeTeambleUserId={userDetails?.teambleUserId} relationshipId={relationshipId} />
                                : <GoalsWidget profileVieweeTeambleUserId={userDetails?.teambleUserId} relationshipId={relationshipId} />
                            } */}
                             <GoalsWidget profileVieweeTeambleUserId={userDetails?.teambleUserId} relationshipId={relationshipId} />
                          </div>
                        }
                      </div>
                      <div className="flex-grow-1 ms-2 overflow-hidden">
                        <UserDetailsTabsContainer context={context} />
                      </div>
                    </div>
                  </UserDetailsContext.Provider>
                  :
                  <EmptyState message={<> User Profile not found! </>} />
              }
            </>
        }
      </PrimaryLayoutBox>

    </>
  );
};

export default UserProfilePage;