import { List, Spacing } from '@common/components';
import { Card } from '@themesberg/react-bootstrap';
import { DataState } from 'components';
import { getRevieweePerformanceReviews } from 'data';
import { IRevieweeReview } from 'interfaces';
import React, { useEffect } from 'react';
import { finalize } from 'rxjs';
import RevieweeReviewCard from './RevieweeReviewCard';

interface IRevieweeReviewsListCardProps {
  reviewId: string;
}

const RevieweeReviewsListCard: React.FC<IRevieweeReviewsListCardProps> = (
  { reviewId }
) => {

  /* State hooks */
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reviewsList, setReviewsList] = React.useState<IRevieweeReview[]>([]);


  /* Fetch the list of reviews about reviewee */
  useEffect(
    () => {

      if (!reviewId) {
        setIsLoading(false);
        setReviewsList([]);
        return;
      }

      setIsLoading(true);
      const subscription = getRevieweePerformanceReviews(reviewId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setReviewsList(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [reviewId]
  );


  return (
    <Card className='overflow-hidden h-100'>
      <Card.Body
        className='h-100 position-relative overflow-y-auto'
        style={{ height: !reviewsList.length || isLoading ? 100 : '' }}
      >
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!reviewsList.length}
          emptyStateMessage='No reviews available!'
        >
          <List
            items={reviewsList}
            renderItem={
              (review) =>
                <Spacing key={review.id} spacing='mb-2'>
                  <RevieweeReviewCard review={review} />
                </Spacing>
            } />
        </DataState>
      </Card.Body>
    </Card>
  );
};

export default RevieweeReviewsListCard;