import Axios, { AxiosObservable } from 'axios-observable';
import {
  IChoiceWithValue,
  ICreateOrUpdateSurveyTemplateRequestData,
  ICreateSurveyFromScratchRequestData,
  IGroupedSurveyTemplates,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IResponse,
  ISimpleTemplateMenuOption,
  ISurvey,
  ISurveyAudienceRequestData,
  ISurveyBuilderBlock,
  ISurveyCustomChoiceValue,
  ISurveyDurationRequestData,
  ISurveyGroupBlockValue,
  ISurveyMenuOption,
  ISurveyQuestionTypeConstants,
  ISurveyResponse,
  ISurveyResponseDataBlock,
  ISurveyResult,
  ISurveySchedulerRequestData,
  ISurveyTemplate,
  ITemplateGroupType,
  IUpdateSurveyLibraryTemplateRequestData,
  IUserProfile,
  SignInWith,
} from 'interfaces';
import moment from 'moment';
import { map } from 'rxjs';
import { toHTML } from 'slack-markdown';
import {
  DATE_TIME_FORMAT
} from 'teamble-constants';
import { getSurveyAudienceDescription } from 'utils';


const getNonGroupSurveyBlockData = (
  block: ISurveyResponseDataBlock,
  metaData: IMetadata,
  app: SignInWith
): ISurveyBuilderBlock | undefined => {

  const questionTypeConstants: ISurveyQuestionTypeConstants =
    metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

  switch (block.type) {

    case questionTypeConstants.STATIC_TEXT: {

      return {
        ...block,
        staticTextValue: {
          value: toHTML(block.text || '', { hrefTarget: '_blank' })
        }
      };

    }

    case questionTypeConstants.TEXT_INPUT: {

      return {
        ...block,
        inputTextValue: {
          label: block.label,
          placeholder: block.placeholder,
          required: block.required,
        }
      };

    }

    case questionTypeConstants.CHOICE: {

      const multiSelectTypeConstants =
        metaData.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

      let selectChoices: string = '';
      let selectChoicesWithValues: IChoiceWithValue[] = [];

      if (block.family === multiSelectTypeConstants.CUSTOM_ITEMS && block.choices) {

        switch (app) {

          case SignInWith.Slack: {
            selectChoicesWithValues = block.choices.map(
              (choice) => ({
                choice,
                value: (block.choicesValues as ISurveyCustomChoiceValue)[choice]
              })
            );
            break;
          }

          case SignInWith.MsTeams: {
            selectChoices = block.choices.join('\n');
            break;
          }
        }
      }

      return {
        ...block,
        multiSelectValue: {
          label: block.label,
          required: block.required,
          dropdown: block.dropdown || false,
          choices: app === SignInWith.Slack ? selectChoicesWithValues : selectChoices,
          commentBox: !!block.comment,
          commentBoxGuide: block.comment || '',
          track: block.track || false,
          multipleAnswers: block.multipleAnswers || false,
          selectedOption: metaData.multiSelectQuestionOptionsTypes.options.find(
            (option) => option.value === block.family
          ) as IMenuOption
        }
      };

    }

  }
};


const getGroupSurveyBlockData = (
  block: ISurveyResponseDataBlock,
  metaData: IMetadata,
  app: SignInWith
): ISurveyBuilderBlock | undefined => {

  const questionTypeConstants: ISurveyQuestionTypeConstants =
    metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

  return {
    ...block,
    type: questionTypeConstants.GROUP || '',
    groupValue: {
      label: block.label,
      description: block.description,
      questions: block.questions?.map(
        (question) => getNonGroupSurveyBlockData(question, metaData, app) as ISurveyBuilderBlock
      ),
    } as ISurveyGroupBlockValue
  }

};


const getMappedSurveyData = (
  surveyData: ISurveyResponse,
  metadata: IMetadata,
  app: SignInWith
): ISurvey => {

  const survey: ISurvey = {
    ...surveyData,
    blocks: []
  };

  if (surveyData.blocks?.length) {

    surveyData.blocks.forEach(
      (block) => {

        let responseBlock;
        if (block.isGroup) {
          responseBlock =
            getGroupSurveyBlockData(block, metadata, app) as ISurveyBuilderBlock;
        } else {
          responseBlock =
            getNonGroupSurveyBlockData(block, metadata, app) as ISurveyBuilderBlock;
        }

        survey.blocks.push(responseBlock);

      }
    );
  }

  return survey;
};


export const getSurveysAsMenuOptions =
  (app: IUserProfile['app'], workspaceOrChannelId: string): AxiosObservable<IResponse<ISurveyMenuOption[]>> => {

    return Axios.post<IResponse<ISurvey[]>>(
      '/surveys',
      app === SignInWith.Slack ? { workspaceId: workspaceOrChannelId } : { channelId: workspaceOrChannelId }
    ).pipe(
      map(
        (response) => {

          const options: IMenuOption[] = response.data.data.map(
            (option) => {

              const launchedAt = moment(option.createdAt, 'x').local().format(DATE_TIME_FORMAT);
              const audience = getSurveyAudienceDescription(option.audience);

              return {
                value: option.id,
                label: option.title,
                state: option.state,
                description: `Launched At: ${launchedAt}, Audience: ${audience}`,
                launchedAt: launchedAt
              }
            }
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: options
            }
          };

        }
      )
    );
  };


export const getSurveyResult =
  (surveyId: string): AxiosObservable<IResponse<ISurveyResult>> => {

    return Axios.post<IResponse<ISurveyResult>>(
      "/survey-results",
      { surveyId }
    );
  };

export const getSurveyLibrary =
  (): AxiosObservable<IResponse<ISurvey[]>> => {

    return Axios.post<IResponse<ISurvey[]>>(
      "/list-survey-library-template",
      {
        page: 1
      }
    );
  };


export const duplicateSurvey =
  (surveyTemplateId: string): AxiosObservable<IResponse<ISurvey>> => {

    return Axios.post<IResponse<ISurvey>>(
      "/duplicate-survey-library-template",
      { surveyTemplateId }
    );
  };


export const deleteSurvey
  = (surveyTemplateId: string, isInitialCheck: boolean): AxiosObservable<IResponse<void>> => {

    return Axios.post<IResponse<void>>(
      "/remove-survey-library-template",
      { surveyTemplateId, isInitialCheck }
    );
  };


export const getPredefinedSurveyTemplates = (): AxiosObservable<IResponse<ISurveyTemplate[]>> => {
  return Axios.post<IResponse<ISurveyTemplate[]>>("/list-predefined-template");
};


export const getGroupedPredefinedSurveyTemplates = (): AxiosObservable<IResponse<IGroupedSurveyTemplates[]>> => {
  return getPredefinedSurveyTemplates().pipe(
    map(
      (response) => {

        const groupedSurveyTemplates: IGroupedSurveyTemplates[] = [];
        const groupedSurveyTemplatesObj: {
          [key: string]: ISurveyTemplate[]
        } = {} as any;

        response.data.data.forEach(
          (surveyTemplate) => {

            if (groupedSurveyTemplatesObj[surveyTemplate.group]) {
              groupedSurveyTemplatesObj[surveyTemplate.group].push(surveyTemplate);
            } else {
              groupedSurveyTemplatesObj[surveyTemplate.group] = [surveyTemplate];
            }

          }
        );

        Object.keys(groupedSurveyTemplatesObj).forEach(
          (group) => {

            groupedSurveyTemplates.push({
              group,
              templates: groupedSurveyTemplatesObj[group]
            });

          }
        );

        return {
          ...response,
          data: {
            ...response.data,
            data: groupedSurveyTemplates
          }
        };
      }
    )
  );
};


export const getCustomSurveyTemplates = (
  isDeleted: boolean = false
): AxiosObservable<IResponse<ISurveyTemplate[]>> => {
  return Axios.post<IResponse<ISurveyTemplate[]>>(
    "/list-custom-survey-template",
    { isDeleted }
  );
};


export const getSurveyTemplatesAsMenuOptions = (
  templateType: ITemplateGroupType
): AxiosObservable<IResponse<ISimpleTemplateMenuOption[]>> => {

  const templateSource$ = templateType === 'custom' ?
    getCustomSurveyTemplates(true) :
    getPredefinedSurveyTemplates();

  return templateSource$.pipe(
    map(
      (response) => {

        const templateOptions: ISimpleTemplateMenuOption[] = response.data.data.map(
          (template) => ({
            value: template.id,
            label: template.title,
            type: templateType
          })
        )

        return {
          ...response,
          data: {
            ...response.data,
            data: templateOptions
          }
        };

      }
    )
  );
};


export const duplicateCustomSurveyTemplate = (templateId: string): AxiosObservable<IResponse<ISurveyTemplate>> => {
  return Axios.post<IResponse<ISurveyTemplate>>(
    "/duplicate-custom-survey-template",
    { templateId }
  );
}


export const deleteCustomSurveyTemplate = (templateId: string): AxiosObservable<IResponse<void>> => {
  return Axios.post<IResponse<void>>(
    "/remove-custom-survey-template",
    { templateId }
  );
};


export const createSurveyLibraryTemplateFromExistedTemplate =
  (templateId: string, type: 'predefined' | 'custom', workspaceOrChannel: string, app: string): AxiosObservable<IResponse<ISurvey>> => {

    return Axios.post<IResponse<ISurvey>>(
      "/create-survey-library-template-from-existed-template",
      { templateId, type, [app === SignInWith.Slack ? "workspaceId" : "channelId"]: workspaceOrChannel }
    );

  };


export const getSurveyTemplateDetailsById = (
  templateId: string,
  metadata: IMetadata,
  app: SignInWith
): AxiosObservable<IResponse<ISurvey>> => {

  return Axios.post<IResponse<ISurveyResponse>>(
    "/detail-custom-survey-template",
    { templateId }
  ).pipe(
    map(
      (response) => ({
        ...response,
        data: {
          ...response.data,
          data: getMappedSurveyData(response.data.data, metadata, app)
        }
      })
    )
  );
};


export const saveSurveyLibraryAudience =
  (data: Partial<ISurveyAudienceRequestData>): AxiosObservable<IResponse<ISurvey>> => {
    return Axios.post<IResponse<{ surveyTemplate: ISurvey }>>(
      "/save-survey-library-template-audience",
      data
    ).pipe(
      map(
        (response) => {
          return {
            ...response,
            data: {
              ...response.data,
              data: response.data.data.surveyTemplate
            }
          };
        }
      )
    );
  };


export const setSurveyLibraryTemplateDuration =
  (data: Partial<ISurveyDurationRequestData>): AxiosObservable<IResponse<ISurvey>> => {
    return Axios.post<IResponse<ISurvey>>(
      "/set-survey-library-template-duration",
      data
    );
  };


export const setSurveyLibraryTemplateSchedule =
  (data: ISurveySchedulerRequestData): AxiosObservable<IResponse<ISurvey>> => {
    return Axios.post<IResponse<ISurvey>>(
      "/set-survey-library-template-schedule",
      data
    );
  };


export const removeSurveyLibraryTemplateSchedule =
  (surveyTemplateId: string): AxiosObservable<IResponse<ISurvey>> => {
    return Axios.post<IResponse<{ surveyTemplate: ISurvey }>>(
      "/remove-survey-library-template-schedule",
      { surveyTemplateId }
    ).pipe(
      map(
        (response) => {
          return {
            ...response,
            data: {
              ...response.data,
              data: response.data.data.surveyTemplate
            }
          };
        }
      )
    );
  };


export const launchSurvey =
  (surveyTemplateId: string): AxiosObservable<IResponse<void>> => {
    return Axios.post<IResponse<void>>(
      "/launch-survey",
      { surveyTemplateId }
    );
  }


export const createSurveyFromScratch =
  (data: ICreateSurveyFromScratchRequestData): AxiosObservable<IResponse<ISurvey>> => {
    return Axios.post<IResponse<ISurvey>>(
      "/save-survey-library-template-question-blocks",
      data
    );
  };


export const updateSurveyTemplate = (
  data: ICreateOrUpdateSurveyTemplateRequestData,
  metadata: IMetadata,
  app: SignInWith
): AxiosObservable<IResponse<ISurvey>> => {

  return Axios.post<IResponse<ISurveyResponse>>(
    "/update-custom-survey-template",
    data
  ).pipe(
    map(
      (response) => ({
        ...response,
        data: {
          ...response.data,
          data: getMappedSurveyData(response.data.data, metadata, app)
        }
      })
    )
  );
}


export const createSurveyTemplate = (
  data: ICreateOrUpdateSurveyTemplateRequestData,
  metadata: IMetadata,
  app: SignInWith
): AxiosObservable<IResponse<ISurvey>> => {

  return Axios.post<IResponse<ISurveyResponse>>(
    "/create-custom-survey-template",
    data
  ).pipe(
    map(
      (response) => ({
        ...response,
        data: {
          ...response.data,
          data: getMappedSurveyData(response.data.data, metadata, app)
        }
      })
    )
  );
};


export const getSurveyLibraryTemplateDetails = (
  templateId: string,
  metadata: IMetadata,
  app: SignInWith
): AxiosObservable<IResponse<ISurvey>> => {

  return Axios.post<IResponse<ISurveyResponse>>(
    "/detail-survey-library-template",
    { templateId }
  ).pipe(
    map(
      (response) => ({
        ...response,
        data: {
          ...response.data,
          data: getMappedSurveyData(response.data.data, metadata, app)
        }
      })
    )
  );
};


export const updateSurveyLibraryTemplate = (
  data: IUpdateSurveyLibraryTemplateRequestData,
  metadata: IMetadata,
  app: SignInWith
): AxiosObservable<IResponse<ISurvey>> => {

  return Axios.post<IResponse<ISurveyResponse>>(
    "/update-survey-library-template-question-blocks",
    data
  ).pipe(
    map(
      (response) => ({
        ...response,
        data: {
          ...response.data,
          data: getMappedSurveyData(response.data.data, metadata, app)
        }
      })
    )
  );
};


export const getSurveysByFilters =
  (state: string, filterBy: string): AxiosObservable<IResponse<ISurveyResponse[]>> => {

    return Axios.post<IResponse<ISurveyResponse[]>>(
      "/list-surveys",
      { state, filterBy }
    );
  };


export const getSurveyDetails =
  (surveyId: string): AxiosObservable<IResponse<ISurveyResponse>> => {

    return Axios.post<IResponse<ISurveyResponse>>(
      "/detail-survey",
      { surveyId }
    );
  };


export const closeSurvey =
  (surveyId: string): AxiosObservable<IResponse<void>> => {
    return Axios.post<IResponse<void>>(
      "/close-survey",
      { surveyId }
    );
  };


export const sendSurveyReminder =
  (surveyId: string): AxiosObservable<IResponse<void>> => {
    return Axios.post<IResponse<void>>(
      "/send-survey-reminder",
      { surveyId }
    );
  };


export const getAnalyticsSurveysAsMenuOptions = (
  templateId: string,
  type: string,
  app: SignInWith,
  isQuestionTimeline: boolean = false
): AxiosObservable<IResponse<IMenuOption[]>> => {

  return Axios.post<IResponse<ISurvey[]>>(
    "/survey/analytics/list-library-template",
    { templateId, type, isQuestionTimeline }
  ).pipe(
    map(
      (response) => {

        const surveyTemplatesOptions: IMenuOption[] =
          response.data.data.map(
            (template) => ({
              value: template.id,
              label: template.title,
              description:
                app === SignInWith.Slack
                  ?
                  `Workspace: ${template.workspace?.name}, Created: ${moment(template.createdAt, 'x').local().format(DATE_TIME_FORMAT)}`
                  :
                  `Group: ${template.channel?.name}, Created: ${moment(template.createdAt, 'x').local().format(DATE_TIME_FORMAT)}`
            })
          );

        return {
          ...response,
          data: {
            ...response.data,
            data: surveyTemplatesOptions
          }
        };

      }
    )
  );
};


export const downloadSurveyReports =
  (surveyId: string, type: 'PDF' | 'XLSX'): AxiosObservable<Blob> => {

    return Axios.post<Blob>(
      "/survey-results-download",
      { surveyId, type },
      {
        responseType: 'arraybuffer'
      }
    );
  };