import { FormErrors, IEditGroupFormValues, ISimpleMenuOption } from 'interfaces';
import * as Yup from 'yup';
import { MEMBER_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const editGroupValidationSchema: Yup.SchemaOf<IEditGroupFormValues> =
  Yup.object().shape(
    {
      members: Yup.array().of(MEMBER_MENU_OPTION_VALIDATION_SCHEMA)
        .required(FormErrors.Required)
        .min(1, FormErrors.Required),
      admins: Yup.array().of(MEMBER_MENU_OPTION_VALIDATION_SCHEMA)
        .required(FormErrors.Required)
        .min(1, FormErrors.Required)
        .test(
          'adminMembersMatch',
          FormErrors.GroupAdminsFromMembers,
          (admins, context) => {

            if (!admins?.length) {
              return true;
            }
            const memberIds: string[] = context.parent.members
              .map((member: ISimpleMenuOption) => member.value);

            return admins.every(
              (admin) => memberIds.includes(admin.value as string)
            );

          }
        ),
    }
  );