import {
  IFormQuestionValue,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestion,
  IMultiSelectQuestionOptionsTypeConstants,
  IQuestion,
  IQuestionActionTypes,
  IQuestionTypeConstants,
  ISimpleMenuOption,
  ITextQuestion,
  ITitleQuestion,
} from 'interfaces';
import { toHTML } from 'slack-markdown';

import { getTurndownServiceInstance } from './formatter.utils';


export const getMultiSelectChoices = (choiceString: string | undefined): Partial<IMenuOption>[] | null => {

  if (!choiceString) {
    return null;
  }

  const options: Partial<IMenuOption>[] =
    choiceString
      .split('\n')
      .filter((c) => !!c)
      .map(
        (c) => ({ label: c })
      );

  return options;
}


export const mapQuestionsToFormValues = (
  questions: IQuestion[],
  metadata: IMetadata
): IFormQuestionValue[] => {

  if (!questions.length) {
    return [];
  }

  const questionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;
  const formQuestionValues: IFormQuestionValue[] = [];

  questions.forEach(
    (question) => {

      const questionMeta = {
        tempId: question.id,
        type: question.type,
        typeTitle: question.typeTitle
      };

      switch (question.type) {

        case questionTypeConstants.TITLE:
        case questionTypeConstants.SUBTITLE: {

          const que = question as ITitleQuestion;
          formQuestionValues.push({
            ...questionMeta,
            titleValue: {
              text: toHTML(que.text || '', { hrefTarget: '_blank' })
            }
          });
          break;
        }

        case questionTypeConstants.SHORT_TEXT:
        case questionTypeConstants.LONG_TEXT: {

          const que = question as ITextQuestion;
          formQuestionValues.push({
            ...questionMeta,
            textValue: {
              label: que.label,
              placeholder: que.placeholder,
              required: que.required,
            }
          });
          break;
        }

        case questionTypeConstants.MULTI_SELECT_QUESTION: {

          const que = question as IMultiSelectQuestion;

          const multiSelectTypeConstants =
            metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

          const selectedOption = metadata.multiSelectQuestionOptionsTypes.options.find(
            (option) => option.value === que.family
          ) as IMenuOption;

          let selectChoices: string = '';

          if (que.family === multiSelectTypeConstants.CUSTOM_ITEMS && que.choices?.length) {
            selectChoices = que.choices.join('\n');
          }

          formQuestionValues.push({
            ...questionMeta,
            multiSelectValue: {
              label: que.label || '',
              required: que.required || false,
              dropdown: que.dropdown || false,
              choices: selectChoices,
              enableCommentBox: !!que.comment,
              comment: que.comment || '',
              selectedOption
            }
          });
          break;
        }

      }

    }
  );

  return formQuestionValues;
};


export const getNewQuestion = (
  tempId: string,
  option: ISimpleMenuOption,
  metadata: IMetadata,
): IFormQuestionValue => {

  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  let question: IFormQuestionValue = {
    tempId,
    type: option.value,
    typeTitle: option.label
  }

  switch (option.value) {

    case questionTypeConstants.TITLE:
    case questionTypeConstants.SUBTITLE: {

      question = {
        ...question,
        titleValue: { text: '' }
      };
      break;
    }

    case questionTypeConstants.SHORT_TEXT:
    case questionTypeConstants.LONG_TEXT: {

      question = {
        ...question,
        textValue: {
          label: '',
          placeholder: 'Type your answer here...',
          required: false,
        }
      };
      break;
    }

    case questionTypeConstants.MULTI_SELECT_QUESTION: {

      const multiSelectOptions = metadata.multiSelectQuestionOptionsTypes.options;

      question = {
        ...question,
        multiSelectValue: {
          label: '',
          choices: '',
          enableCommentBox: false,
          comment: '',
          dropdown: false,
          required: false,
          selectedOption: multiSelectOptions[0],
        }
      }
      break;
    }
  }

  return question;

};


export const getQuestionsAndPreviewKeyAfterAction = (
  questions: IFormQuestionValue[],
  actionType: IQuestionActionTypes,
  index: number,
  activePreviewKey: number | null,
  tempId: number,
): { questions: IFormQuestionValue[], previewKey?: number | null } => {

  switch (actionType) {

    case 'delete': {

      let newPreviewKey: typeof activePreviewKey = null;

      if (activePreviewKey !== null) {
        if (index === questions.length - 1) {
          newPreviewKey = index - 1;
        } else {
          newPreviewKey = index;
        }
      }

      return {
        questions: questions.filter((_, qIndex) => qIndex !== index),
        previewKey: newPreviewKey
      };
    }

    case 'duplicate': {

      const newQuestion: IFormQuestionValue = {
        ...questions[index],
        tempId: `question${tempId + 1}`
      };

      return {
        questions: [
          ...questions.slice(0, index + 1),
          newQuestion,
          ...questions.slice(index + 1, questions.length)
        ],
        previewKey: index + 1
      };
    }

    case 'moveUp': {

      // Moving first question up
      if (index === 0) {
        return { questions };
      }

      const questionsCopy = [...questions];
      const questionToMoveUp = questionsCopy.splice(index, 1)[0];
      questionsCopy.splice(index - 1, 0, questionToMoveUp);
      return {
        questions: questionsCopy,
        previewKey: index - 1
      };
    }

    case 'moveDown': {

      // Moving last question down
      if (index === questions.length - 1) {
        return { questions };
      }

      const questionsCopy = [...questions];
      const questionToMoveDown = questionsCopy.splice(index, 1)[0];
      questionsCopy.splice(index + 1, 0, questionToMoveDown);
      return {
        questions: questionsCopy,
        previewKey: index + 1
      };
    }

    default: {
      return { questions };
    }
  }


};


export const mapFormValuesToQuestions = (
  questionValues: IFormQuestionValue[],
  metadata: IMetadata
): IQuestion[] => {

  if (!questionValues.length) {
    return [];
  }

  const questionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  const questions: IQuestion[] = [];
  const turndownService = getTurndownServiceInstance();

  questionValues.forEach(
    (questionValue) => {

      switch (questionValue.type) {

        case questionTypeConstants.TITLE:
        case questionTypeConstants.SUBTITLE: {

          questions.push({
            id: questionValue.tempId.includes('question') ? '' : questionValue.tempId,
            type: questionValue.type,
            text: turndownService.turndown(questionValue.titleValue?.text || ''),
            typeTitle: questionValue.typeTitle,
          });
          break;
        }

        case questionTypeConstants.SHORT_TEXT:
        case questionTypeConstants.LONG_TEXT: {

          questions.push({
            id: questionValue.tempId.includes('question') ? '' : questionValue.tempId,
            type: questionValue.type,
            typeTitle: questionValue.typeTitle,
            label: questionValue.textValue?.label || '',
            placeholder: questionValue.textValue?.placeholder || '',
            required: questionValue.textValue?.required || false
          });
          break;
        }

        case questionTypeConstants.MULTI_SELECT_QUESTION: {

          const multiSelectTypeConstants =
            metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
          let choices: string[] = [];

          if (
            questionValue.multiSelectValue?.selectedOption.value === multiSelectTypeConstants.CUSTOM_ITEMS
          ) {
            const choicesArray = getMultiSelectChoices(questionValue.multiSelectValue?.choices);

            if (choicesArray) {
              choices = choicesArray.map(
                (choice) => choice.label as string
              );
            }
          }

          questions.push({
            id: questionValue.tempId.includes('question') ? '' : questionValue.tempId,
            type: questionValue.type,
            typeTitle: questionValue.typeTitle,
            label: questionValue.multiSelectValue?.label || '',
            family: questionValue.multiSelectValue?.selectedOption.value || '',
            comment: questionValue.multiSelectValue?.enableCommentBox ? questionValue.multiSelectValue?.comment : '',
            required: questionValue.multiSelectValue?.required || false,
            dropdown: questionValue.multiSelectValue?.dropdown || false,
            choices
          });
          break;
        }
      }

    }
  );

  return questions;

};


export const getQuestionTypeLabel = (
  family: string,
  multiSelectQuestionTypes: ISimpleMenuOption[]
): string => {

  const questionType = multiSelectQuestionTypes.find(
    (type) => type.value === family
  );

  return questionType?.label || '';
};