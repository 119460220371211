import { IMember } from './member.interface';
import { IMemberOption, ISimpleMenuOption } from './menuOptions.interface';

export interface IGroup {
  id: string;
  name: string;
  isCustom: boolean;
  description: string;
  isTeamAdmin: boolean;
  msteams_name: string;
  teambleTeamId: string;
  msteams_channel_name: string;
  msteamsChannelId: string;
  members: IGroupMember[];
}


export interface IGroupMember {
  id: string;
  name: string;
  isTeamAdmin: boolean;
  teambleUserId: string;
  title: IMember['title'];
}


export interface IEditGroupFormValues {
  members: IMemberOption[];
  admins: IMemberOption[];
}

export interface IEditGroupRequestData {
  teambleTeamId: string;
  name: string;
  description: string;
  userIds: string[];
  admins: string[];
}

export enum GroupContext {
  MyGroups = 'myGroups',
  MyTeams = 'myTeams',
  OrganizationGroups = 'organizationGroups',
}

export interface IGroupActionColumnProps {
  onGroupActionSelect: (selectedAction: ISimpleMenuOption, group: IGroup) => void;
}