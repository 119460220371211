import {
  IChannelMenuOption,
  IListReducerAction,
  IMsTeamsChannel,
  ISimpleMenuOption,
  ISimpleSlackWorkspace,
  ISlackWorkspaceUser,
} from "interfaces";

import { IAudience } from "./audience.interface";
import {
  IFormQuestionValue,
  IFormQuestionValueValidationSchema,
  IQuestion,
} from "./question.interface";
import { ISchedule } from "./schedule.interface";
import { ITemplateMeta } from "./template.interface";

export interface IFeedbackLoop extends ITemplateMeta {
  schedule?: ISchedule;
  scheduledAt?: string;
  workspaceId: string;
  channelId: string;
  feedbackType?: IFeedbackTypes;
  feedbackTypeHeader?: string;
  notifications?: string;
  notificationMessage?: string;
  audience?: IAudience;
  anonymity?: { type: string };
  feedbackFor?: FeedbackForAudienceTypes;
  senderReceiverInfos?: IFeedbackLoopAudiencePair[];
  workspace?: ISimpleSlackWorkspace;
  channel?: IMsTeamsChannel;
  hideCompetencySelector?: boolean;
  isAutomated: boolean | undefined;
  workflowType: string;
  ttl?: number
}

export interface ILaunchedFeedbackLoop extends IFeedbackLoop {
  templateName: string;
  createdBy: {
    id: string;
    name: string;
  };
  completion: string;
}

export enum IFeedbackLoopTemplateTypes {
  Feedback = "feedback",
  OneOnOne = "one-on-one",
}

export interface ICreateFeedbackLoopFormValues {
  workspaceOrChannel: IChannelMenuOption | null;
}

export interface IFeedbackLoopFormValues {
  title: string;
  hideCompetencySelector?: boolean;
  questions: IFormQuestionValue[];
}

export interface IFeedbackLoopFormValidationSchema {
  title: string;
  questions: IFormQuestionValueValidationSchema[];
}

export interface IUpdateFeedbackLoopRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  title: string;
  hideCompetencySelector?: boolean;
  blocks: IQuestion[];
}

export interface IFeedbackLoopConfigContext {
  feedbackLoop: IFeedbackLoop;
  audienceUpdatedCount: number;
  feedbackForAudiencePairs: IFeedbackLoopAudiencePair[] | undefined;
  isFeedbackForAudiencePairsLoading: boolean;
  onAudienceUpdatedCount: React.Dispatch<React.SetStateAction<number>>;
  onFeedbackLoopUpdate: React.Dispatch<
    React.SetStateAction<IFeedbackLoop | null>
  >;
}

export interface IFeedbackLoopContext {
  isLoading: boolean;
  feedbackLoop: ILaunchedFeedbackLoop | undefined;
  updateFeedbackLoop: React.Dispatch<
    React.SetStateAction<ILaunchedFeedbackLoop | undefined>
  >;
}

export interface IFeedbackTypeConfigFormValues {
  feedbackType: ISimpleMenuOption | null;
}

export interface IUpdateFeedbackTypeConfigRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  feedbackType: string;
}

export interface IUpdateFeedbackLoopSchedulerRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  timezone: number;
  timestamp: number;
  frequency: string;
}

export interface IRequestTimeoutFormValues {
  ttl: Date | undefined;
}

export interface IRequestTimeoutRequestData {
  feedbackLibraryLoopId: string;
  ttl: number | undefined;
  timezone: number;
}

export enum IFeedbackTypes {
  Direct = "direct-feedback",
  Managers = "direct-manager-feedback",
  DirectAndManagers = "direct-plus-manager-feedback",
  Company = "company-feedback",
}

export interface INotificationPreferenceConfigFormValues {
  notificationPreference: ISimpleMenuOption | null;
}

export interface INotificationMessageConfigFormValues {
  notificationMessage: string;
}

export interface IUpdateFeedbackLoopRequestMetaData {
  feedbackLibraryLoopId: string;
}

export interface IUpdateNotificationPreferenceRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  notifications: string;
}

export interface IUpdateNotificationMessageRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  notificationMessage: string;
}

export interface IAudienceConfigFormValues {
  audienceType: ISimpleMenuOption | null;
  selectedAudienceUsers: ISimpleMenuOption[];
  selectedOrganizations: ISimpleMenuOption[];
  selectedGroups: ISimpleMenuOption[];
}

export interface IUpdateAudienceRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  audienceType: string;
  selectedAudienceUserIds?: string[];
  selectedChannels?: {
    id: string;
    name: string;
  }[];
  selectedGroups?: string[];
}

export interface IFeedbackForConfigFormValues {
  feedbackFor: ISimpleMenuOption | null;
  isAnonymous?: boolean;
}

export interface IUpdateAnonymityRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  anonymity: string;
}

export enum FeedbackForAudienceTypes {
  SelfSelect = "self-select",
  ManagerUpward = "manager-upward",
  ManagerDownward = "manager-downward",
  ThreeSixty = "360",
  ManuallyDefine = "manually-define",
}

export interface IFeedbackLoopInspectorPair {
  sender: string;
  receiver: string;
}

export interface IFeedbackLoopSenderReceiverInfosRequestData {
  sender: { userId: string };
  receiver?: { userId: string };
}

export interface IUpdateFeedbackForRequestData
  extends IUpdateFeedbackLoopRequestMetaData {
  feedbackFor: FeedbackForAudienceTypes;
  senderReceiverInfos?: IFeedbackLoopSenderReceiverInfosRequestData[];
}

export interface IFeedbackLoopAudiencePair {
  sender: ISlackWorkspaceUser;
  receiver?: ISlackWorkspaceUser;
  state?: string;
  comment?: string;
}

export interface IFeedbackLoopSenderReceiverInfosResponse {
  unVerifiedEmailPairs: IFeedbackLoopInspectorPair[];
  verifiedEmailPairs: IFeedbackLoopAudiencePair[];
}

export interface IFeedbackForTableActionProps {
  onRemoveClick?: (audiencePair: IFeedbackLoopAudiencePair) => void;
}

export interface IFeedbackLoopTableActionProps {
  audiencePairActions: (
    audiencePair: IFeedbackLoopAudiencePair,
    action: string
  ) => void;
}

export interface IFeedbackLoopLibraryTableActionProps {
  onFeedbackLoopsListUpdate: React.Dispatch<IListReducerAction<IFeedbackLoop>>;
}
