import * as React from 'react';

const NextArrowIcon = ({ width = 8, height = 8, ...props }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 8 8'
        {...props}
    >
        <path stroke='currentColor' d='m2.5 7 3-3-3-3' />
    </svg>
);

export default NextArrowIcon;
