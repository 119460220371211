import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from '@themesberg/react-bootstrap';
import Axios, { AxiosObservable } from 'axios-observable';
import {
  DataState,
  FeedbackInsightCharts,
  FeedbackInsightFilters,
  FeedbackInsightLeaderBoardWidget,
  FeedbackInsightOverviewDataCards,
  useUserProfile,
} from 'components';
import { FeedbackInsightsContext } from 'contexts';
import {
  getFeedbackInsightsCompetency,
  getFeedbackInsightsLeaderboard,
  getFeedbackInsightsOverview,
} from 'data';
import { useApp } from 'hooks';
import {
  FeedbackInsightTypes,
  GroupFeedbackTypeFilters,
  IFeedbackAnalyticsRequestData,
  IFeedbackInsightsChartData,
  IFeedbackInsightsOverview,
  IFeedbackLeaderBoardUser,
  IFeedbackTreemapChartData,
  IGroup,
  IMenuOption,
  IResponse,
  ISimpleMenuOption,
  SignInWith,
  StartEndDates,
} from 'interfaces';
import moment from 'moment';
import React from 'react';
import { finalize, forkJoin } from 'rxjs';
import {
  GROUP_FEEDBACK_TYPE_OPTIONS,
  SELECT_ALL_OPTION,
} from 'teamble-constants';
import { getGroupName, getInsightsFor } from 'utils';
import downloadjs from 'downloadjs';


interface IFeedbackInsightsWidgetProps {
  insightType: FeedbackInsightTypes;
  groupId?: string;
  departmentId?: string;
  teambleUserId?: string;
  teambleTeamId?: string;
  relationshipId?: string;
  onTreemapChartClick?: (data: IFeedbackTreemapChartData) => void;
}

const FeedbackInsightsWidget: React.FC<IFeedbackInsightsWidgetProps> = (
  {
    insightType, teambleUserId, departmentId, groupId, teambleTeamId, relationshipId,
    onTreemapChartClick
  }
) => {

  // App hook
  const app = useApp();

  // State Hooks
  // Loading
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);
  const [isOrganizationOptionsLoading, setIsOrganizationOptionsLoading] = React.useState<boolean>(false);

  // Filters
  const [workspaceOrChannelOptions, setWorkspaceOrChannelOptions] = React.useState<IMenuOption[]>([]);
  const [allOrganizationOptionIds, setAllOrganizationOptionIds] = React.useState<string[]>([]);
  const [selectedWorkspaceOrChannelOptions, setSelectedWorkspaceOrChannelOptions] = React.useState<IMenuOption[]>([]);
  const [startEndDates, setStartEndDates] = React.useState<StartEndDates>(
    {
      startDate: moment().subtract(3, 'months'),
      endDate: moment()
    }
  );
  const [selectedGroupFeedbackTypeOption, setSelectedGroupFeedbackTypeOption] = React.useState<ISimpleMenuOption>(
    app === SignInWith.Slack || insightType !== FeedbackInsightTypes.CHANNEL_OR_WORKSPACE
      ?
      GROUP_FEEDBACK_TYPE_OPTIONS[1]
      :
      GROUP_FEEDBACK_TYPE_OPTIONS[0]
  );

  // Insights data
  const [feedbackOverviewInsights, setFeedbackOverviewInsights] = React.useState<IFeedbackInsightsOverview | null>(null);
  const [feedbackInsightsChartData, setFeedbackInsightsChartData] = React.useState<IFeedbackInsightsChartData | null>(null);
  const [feedbackLeaderBoardInsights, setFeedbackLeaderBoardInsights] = React.useState<IFeedbackLeaderBoardUser[]>([]);

  // User-Profile Hook
  const { userProfile } = useUserProfile();


  React.useEffect(
    () => {

      if (insightType === FeedbackInsightTypes.DEPARTMENT) {
        return;
      }

      setIsOrganizationOptionsLoading(true);

      const subscription = Axios.post<IResponse<IGroup[]>>(
        "/list-teamble-team-for-analytics",
        { insightsFor: selectedGroupFeedbackTypeOption.value }
      )
        .pipe(
          finalize(() => setIsOrganizationOptionsLoading(false))
        )
        .subscribe(
          (response) => {

            // const filteredOptions = userProfile?.isSuperAdmin
            //   ?
            //   response.data.data
            //   :
            //   response.data.data
            //     .filter(
            //       (data) => {

            //         if (userProfile?.isTeamAdmin && data.isTeamAdmin) {
            //           return true;
            //         }

            //         return false;
            //       }
            //     );

            const options = response.data.data
              .map(
                data => {
                  let label = app === SignInWith.MsTeams
                    ?
                    getGroupName(data)
                    :
                    data.name;

                  return { value: data.teambleTeamId, label };
                }
              );

            const allOrgOptionIds = response.data.data.map(
              (data) => data.teambleTeamId
            );

            setAllOrganizationOptionIds(allOrgOptionIds);
            setWorkspaceOrChannelOptions([SELECT_ALL_OPTION, ...options]);
            setSelectedWorkspaceOrChannelOptions([SELECT_ALL_OPTION, ...options]);
          }
        );

      return () => subscription.unsubscribe();
    },
    [app, insightType, selectedGroupFeedbackTypeOption.value, userProfile]
  );


  React.useEffect(
    () => {

      const selectedWorkspaceOrChannelIds = selectedWorkspaceOrChannelOptions
        .map(val => val.value)
        .filter((val) => val !== SELECT_ALL_OPTION.value);
      const startDate = startEndDates.startDate.format("YYYY-MM-DD");
      const endDate = startEndDates.endDate.format("YYYY-MM-DD");

      if (
        !startDate ||
        !endDate ||
        (
          insightType === FeedbackInsightTypes.PERSONAL &&
          (!teambleUserId || !selectedWorkspaceOrChannelIds.length)
        ) ||
        (
          insightType === FeedbackInsightTypes.DEPARTMENT && !departmentId
        ) ||
        (
          insightType === FeedbackInsightTypes.CHANNEL_OR_WORKSPACE && (!groupId && !relationshipId)
        )
      ) {
        return;
      }

      setIsDataLoading(true);
      setFeedbackOverviewInsights(null);
      setFeedbackInsightsChartData(null);
      setFeedbackLeaderBoardInsights([]);

      let teambleTeamIds: string[] = [];
      if (insightType === FeedbackInsightTypes.PERSONAL) {
        teambleTeamIds = selectedWorkspaceOrChannelIds;
      }
      if (insightType === FeedbackInsightTypes.CHANNEL_OR_WORKSPACE) {
        teambleTeamIds = groupId ? [groupId] : [];
      }

      const insightsFor = getInsightsFor(insightType, selectedGroupFeedbackTypeOption.value);

      const requestData: IFeedbackAnalyticsRequestData = {
        insightsFor,
        startDate,
        endDate,
        relationshipId,
        teambleUserId: teambleUserId || null,
        departmentIds: departmentId ? [departmentId] : [],
        selectedGroups: insightsFor === GroupFeedbackTypeFilters.AllFeedback ?
          teambleTeamId && [teambleTeamId as string] || [] : [],
        teambleTeamIds: insightsFor === GroupFeedbackTypeFilters.AllFeedback ?
          allOrganizationOptionIds : teambleTeamIds,
      };

      const forkArray: AxiosObservable<IResponse<any> | Blob>[] = [
        getFeedbackInsightsCompetency(requestData),
        getFeedbackInsightsOverview(requestData),
      ];

      if (insightType !== FeedbackInsightTypes.PERSONAL) {
        forkArray.push(
          getFeedbackInsightsLeaderboard({
            ...requestData,
            teambleUserId: null,
          })
        );
      }

      const subscription = forkJoin([...forkArray])
        .pipe(
          finalize(() => setIsDataLoading(false))
        )
        .subscribe(
          ([competencyResponse, overviewResponse, leaderBoardResponse]) => {
            setFeedbackOverviewInsights((overviewResponse.data as IResponse<IFeedbackInsightsOverview>).data);
            setFeedbackInsightsChartData((competencyResponse.data as IResponse<IFeedbackInsightsChartData>).data);
            if (insightType !== FeedbackInsightTypes.PERSONAL) {
              setFeedbackLeaderBoardInsights((leaderBoardResponse.data as IResponse<IFeedbackLeaderBoardUser[]>).data);
            }
          }
        )

      return () => subscription.unsubscribe();

    },
    [
      departmentId, groupId, insightType, selectedGroupFeedbackTypeOption,
      selectedWorkspaceOrChannelOptions, startEndDates, teambleUserId, allOrganizationOptionIds
    ]
  );


  const downloadFeedbackOverviewInsights = (): void => {

    setIsDownloading(true);

    const insightsFor = getInsightsFor(
      insightType,
      selectedGroupFeedbackTypeOption.value
    );

    const selectedWorkspaceOrChannelIds = selectedWorkspaceOrChannelOptions
      .map(val => val.value)
      .filter((val) => val !== SELECT_ALL_OPTION.value);

    const startDate = startEndDates.startDate.format("YYYY-MM-DD");
    const endDate = startEndDates.endDate.format("YYYY-MM-DD");

    let teambleTeamIds: string[] = [];
    if (insightType === FeedbackInsightTypes.PERSONAL) {
      teambleTeamIds = selectedWorkspaceOrChannelIds;
    }
    if (insightType === FeedbackInsightTypes.CHANNEL_OR_WORKSPACE) {
      teambleTeamIds = groupId ? [groupId] : [];
    }

    const requestData: IFeedbackAnalyticsRequestData = {
      insightsFor,
      startDate,
      endDate,
      relationshipId,
      teambleUserId: teambleUserId || null,
      departmentIds: departmentId ? [departmentId] : [],
      selectedGroups: insightsFor === GroupFeedbackTypeFilters.AllFeedback ?
        [teambleTeamId as string] : [],
      teambleTeamIds: insightsFor === GroupFeedbackTypeFilters.AllFeedback ?
        allOrganizationOptionIds : teambleTeamIds,
      isReport: true,
    };

    getFeedbackInsightsOverview(requestData).pipe(
      finalize(() => setIsDownloading(false))
    ).subscribe(
      (response) => {
        downloadjs((response.data as Blob), 'Insights', 'application/pdf');
      }
    );
  };


  return (
    <Card className='h-100 overflow-hidden'>

      <Card.Header className='flex-shrink-0'>
        <Card.Title>
          Feedback Insights
        </Card.Title>

        <div className='d-flex justify-content-between align-items-start'>
          <div className="flex-grow-1">
            <FeedbackInsightFilters
              insightType={insightType}
              startEndDates={startEndDates}
              isLoading={isOrganizationOptionsLoading}
              workspaceOrChannelOptions={workspaceOrChannelOptions}
              selectedGroupFeedbackTypeOption={selectedGroupFeedbackTypeOption}
              selectedWorkspaceOrChannelOptions={selectedWorkspaceOrChannelOptions}
              onDatesChange={setStartEndDates}
              onSelectedGroupFeedbackTypeOption={setSelectedGroupFeedbackTypeOption}
              onSelectedWorkspaceOrChannelOptionsChange={setSelectedWorkspaceOrChannelOptions}
            />
          </div>
          {
            insightType === FeedbackInsightTypes.PERSONAL &&
            (feedbackOverviewInsights || feedbackInsightsChartData) &&
            <Button
              className='flex-shrink-0 ms-3'
              disabled={isDownloading}
              onClick={downloadFeedbackOverviewInsights}
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className='ms-1'> {isDownloading ? 'Downloading...' : 'Download'} </span>
            </Button>
          }
        </div>
      </Card.Header>

      <Card.Body className='position-relative flex-grow-1 overflow-y-auto'>
        <DataState
          isDataLoading={isDataLoading || isOrganizationOptionsLoading}
          isDataAvailable={
            !!(feedbackOverviewInsights || feedbackInsightsChartData ||
              (insightType !== FeedbackInsightTypes.PERSONAL && feedbackLeaderBoardInsights.length))
          }
          emptyStateMessage={<>No Feedback Analytics data available!</>}
        >
          {
            feedbackOverviewInsights &&
            <div className='my-1'>
              <FeedbackInsightOverviewDataCards
                insightType={insightType}
                feedbackOverviewInsights={feedbackOverviewInsights} />
            </div>
          }
          {
            feedbackInsightsChartData &&
            <FeedbackInsightsContext.Provider value={{ handleTreemapChartClick: onTreemapChartClick || null }}>
              <div className='my-3'>
                <FeedbackInsightCharts
                  feedbackInsightsChartData={feedbackInsightsChartData} />
              </div>
            </FeedbackInsightsContext.Provider>
          }
          {
            insightType !== FeedbackInsightTypes.PERSONAL && feedbackLeaderBoardInsights.length
              ?
              <div className='my-3'>
                <FeedbackInsightLeaderBoardWidget leadershipBoard={feedbackLeaderBoardInsights} />
              </div>
              :
              null
          }
        </DataState>
      </Card.Body>

    </Card>
  );
};

export default FeedbackInsightsWidget;