import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { AudiencePills, CollapsibleCard, FormField } from 'components';
import { FormContext } from 'contexts';
import { getTeamsGroupsAsMenuOption, updateFeedbackLoopAudience } from 'data';
import { useFormik } from 'formik';
import { useApp, useTeamMemberMenuOptions, useSlackChannelMenuOptions } from 'hooks';
import {
  FormFieldTypeConstants,
  IAudienceConfigFormValues,
  IChannelMenuOption,
  IFeedbackLoop,
  IGroupsAudience,
  ISimpleMenuOption,
  IMemberOption,
  IUpdateAudienceRequestData,
  SignInWith,
} from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize, Subscription } from 'rxjs';
import { MSTEAMS_AUDIENCE_TYPES, SLACK_AUDIENCE_TYPES } from 'teamble-constants';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import { useAudience } from '../hooks/useAudience';
import { useAudienceChange } from '../hooks/useAudienceChange';

interface IAudienceConfigWidgetProps { }

const AudienceConfigWidget: React.FC<IAudienceConfigWidgetProps> = () => {

  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [teamsGroups, setTeamsGroups] = React.useState<ISimpleMenuOption[]>([]);

  const app = useApp();

  // Feedback Loop config hook
  const {
    feedbackLoop, audienceUpdatedCount,
    onFeedbackLoopUpdate, onAudienceUpdatedCount
  } = useFeedbackLoopConfig();

  // Get organization users
  const {
    isLoading: isMembersLoading,
    memberOptions
  } = useTeamMemberMenuOptions(
    app === SignInWith.Slack ? feedbackLoop.workspaceId : feedbackLoop.channelId
  );

  // Get slack channels
  const {
    isLoading: isOrganizationsLoading,
    slackChannelOptions
  } = useSlackChannelMenuOptions(feedbackLoop.workspaceId);

  // Get already available User Audience to set in the Form Values
  const usersAudience = useAudience<IMemberOption>('users', memberOptions, feedbackLoop, app);

  // Get already available Organization Audience to set in the Form Values
  const organizationsAudience = useAudience<IChannelMenuOption>('channels', slackChannelOptions, feedbackLoop, app);

  // Get already available Group Audience to set in the Form Values
  const groupsAudience = useAudience<IChannelMenuOption>('groups', teamsGroups, feedbackLoop, app);

  // Form hook
  const audienceConfigForm = useFormik<IAudienceConfigFormValues>({
    initialValues: {
      audienceType: null,
      selectedAudienceUsers: [],
      selectedOrganizations: [],
      selectedGroups: []
    },
    onSubmit: () => { }
  });

  // Hook to check if the value is changed compared to already available feedback loop audience
  const isAudienceChange = useAudienceChange(
    feedbackLoop,
    audienceConfigForm,
  );

  // Set already available User Audience to the form value
  React.useEffect(
    () => {
      audienceConfigForm.setFieldValue('selectedAudienceUsers', usersAudience);
    },
    [usersAudience]
  );


  // Set already available Organization Audience to the form value
  React.useEffect(
    () => {
      audienceConfigForm.setFieldValue('selectedOrganizations', organizationsAudience);
    },
    [organizationsAudience]
  );


  // Set already available Group Audience to the form value
  React.useEffect(
    () => {
      audienceConfigForm.setFieldValue('selectedGroups', groupsAudience);
    },
    [groupsAudience]
  );

  const updateAudience = (isClear: boolean = false): Subscription => {

    const requestData: IUpdateAudienceRequestData = {
      feedbackLibraryLoopId: feedbackLoop.id,
      audienceType: isClear ? 'clear' : audienceConfigForm.values.audienceType!.value,
      selectedAudienceUserIds: audienceConfigForm.values.selectedAudienceUsers
        .map((user) => user.value),
      selectedChannels: audienceConfigForm.values.selectedOrganizations
        .map((channel) => ({ id: channel.value, name: channel.label })),
      selectedGroups: audienceConfigForm.values.selectedGroups
        .map((group) => group.value)
    };

    setIsLoading(true);
    return updateFeedbackLoopAudience(requestData)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {

          if (isClear) {
            audienceConfigForm.resetForm();
          }
          onFeedbackLoopUpdate(response.data.data);
          onAudienceUpdatedCount(audienceUpdatedCount + 1);
          toast.success(response.data.message);
        }
      );
  };


  React.useEffect(
    () => {

      if (!isAudienceChange) {
        return;
      }

      const subscription = updateAudience();

      return () => subscription.unsubscribe();
    },
    [
      audienceConfigForm.values.audienceType,
      audienceConfigForm.values.selectedAudienceUsers,
      audienceConfigForm.values.selectedOrganizations,
      audienceConfigForm.values.selectedGroups,
      feedbackLoop.id,
      isAudienceChange,
      audienceUpdatedCount,
      onFeedbackLoopUpdate
    ]
  );


  const getTeamsGroupsAsMenuOptions = (feedbackLoop: IFeedbackLoop, groupOptions: IMemberOption[]): ISimpleMenuOption[] => {
    const selectedGroupIds: string[] = [];
    (feedbackLoop.audience as unknown as IGroupsAudience[]).forEach(
      (option) => {
        switch (option.type) {
          case 'groups': {
            selectedGroupIds.push(
              ...option.groups.map(
                (group) => group.channelInfo.msteams.groupId
              )
            );
            break;
          }
        }
      }
    );

    const groups = groupOptions.filter((group) => selectedGroupIds.includes(group.value));
    return groups;
  };

  // Fetch Teams Groups to be selected for audience
  React.useEffect(
    () => {

      if (
        audienceConfigForm.values.audienceType?.value !== 'groups' ||
        app !== SignInWith.MsTeams
      ) {
        return;
      }

      const subscription = getTeamsGroupsAsMenuOption()
        .subscribe(
          (groupsResponse) => {
            setTeamsGroups(groupsResponse.data.data);
            audienceConfigForm.setFieldValue('selectedGroups', getTeamsGroupsAsMenuOptions(feedbackLoop, groupsResponse.data.data))
          }
        );

      return () => subscription.unsubscribe();

    },
    [app, audienceConfigForm.values.audienceType]
  );

  return (
    <CollapsibleCard
      title='Audience'
      isLoading={isLoading || isOrganizationsLoading || isMembersLoading}
    >
      <Card.Body>
        <Form>
          <FormContext.Provider value={audienceConfigForm}>
            <Row>
              <Col>
                <FormField
                  controlId='audienceFormAudienceType'
                  type={FormFieldTypeConstants.Select}
                  options={app === SignInWith.Slack ? SLACK_AUDIENCE_TYPES : MSTEAMS_AUDIENCE_TYPES}
                  controlName='audienceType' />
              </Col>
              <Col>
                {
                  audienceConfigForm.values.audienceType?.value === 'users' &&
                  <FormField
                    multiple
                    options={memberOptions}
                    isLoading={isMembersLoading}
                    controlId={`AudienceFormAudienceUsers`}
                    type={FormFieldTypeConstants.Select}
                    controlName='selectedAudienceUsers' />
                }
                {
                  audienceConfigForm.values.audienceType?.value === 'channels' &&
                  <FormField
                    multiple
                    options={slackChannelOptions}
                    optionType='slackChannel'
                    isLoading={isOrganizationsLoading}
                    controlId={`AudienceFormAudienceChannels`}
                    type={FormFieldTypeConstants.Select}
                    controlName='selectedOrganizations' />
                }
                {
                  audienceConfigForm.values.audienceType?.value === 'groups' &&
                  <FormField
                    multiple
                    controlId={`feedbackLoopAudienceFormAudienceGroups`}
                    type={FormFieldTypeConstants.Select}
                    optionType='default'
                    options={teamsGroups}
                    controlName='selectedGroups'
                  />
                }
              </Col>
            </Row>
          </FormContext.Provider>
        </Form>
        <AudiencePills audience={feedbackLoop.audience} />
      </Card.Body>
      <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
        <Button
          size='sm'
          type='button'
          variant='danger'
          disabled={isLoading}
          onClick={() => updateAudience(true)}> Clear </Button>
      </Card.Footer>
    </CollapsibleCard>
  );
};

export default AudienceConfigWidget;