import React, { Dispatch, SetStateAction } from "react";
import "./CustomTextArea.scss";

interface CustomTextAreaProps {
  placeholder?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  value?: string;
  isLoading?: boolean;
}
const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  placeholder,
  setValue,
  value,
  isLoading,
}: any) => {
  return (
    <textarea
      onChange={(e) => {
        setValue && setValue(e.target.value);
      }}
      value={isLoading ? "" : value ?? ""}
      className="CustomTextArea"
      placeholder={isLoading ? "Generating..." : placeholder}
      disabled={isLoading}
    />
  );
};

export default CustomTextArea;
