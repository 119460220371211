import {
  FeedbackForAudienceTypes,
  FormErrors,
  IAudienceConfigFormValues,
  IFeedbackForConfigFormValues,
  IFeedbackLoop,
  IFeedbackLoopFormValidationSchema,
  IFeedbackLoopFormValues,
  IFeedbackTypeConfigFormValues,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IMultiSelectQuestionValue,
  INotificationMessageConfigFormValues,
  INotificationPreferenceConfigFormValues,
  ISchedulerFormValues,
  ITemplate,
} from 'interfaces';
import {
  FEEDBACK_FOR_AUDIENCE_TYPES,
  FEEDBACK_TYPES,
  NOTIFICATION_SETTINGS,
  SCHEDULER_FREQUENCY_OPTIONS,
} from 'teamble-constants';
import { mapQuestionsToFormValues } from 'utils';
import {
  MULTI_SELECT_QUESTION_VALIDATION_SCHEMA,
  QUESTION_META_VALIDATION_SCHEMA,
  TEXT_QUESTION_VALIDATION_SCHEMA,
  TITLE_QUESTION_VALIDATION_SCHEMA,
} from 'validation-schemas';
import * as Yup from 'yup';


export const getFeedbackLoopFormValues =
  (
    feedbackLoopData: IFeedbackLoop | ITemplate | null,
    metadata: IMetadata
  ): IFeedbackLoopFormValues => {

    return {
      title: feedbackLoopData?.title || '',
      hideCompetencySelector: Boolean(feedbackLoopData?.hideCompetencySelector),
      questions: feedbackLoopData?.blocks?.length ?
        mapQuestionsToFormValues(feedbackLoopData.blocks, metadata) : [],
    };

  };


export const getFeedbackLoopFormValidationSchema = (
  metadata: IMetadata
): Yup.SchemaOf<IFeedbackLoopFormValidationSchema> => {

  const multiSelectQuestionValidationSchema = MULTI_SELECT_QUESTION_VALIDATION_SCHEMA;

  const updatedMultiSelectChoicesSchema: Yup.SchemaOf<IMultiSelectQuestionValue> =
    multiSelectQuestionValidationSchema.shape({
      choices: Yup.string().when(
        'selectedOption',
        {
          is: (selectedOption: IMenuOption) =>
            selectedOption.value ===
            (metadata?.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants).CUSTOM_ITEMS,
          then: Yup.string().required(FormErrors.Required),
          otherwise: Yup.string()
        }
      )
    }).notRequired().default(undefined);

  return Yup.object({
    title: Yup.string()
      .required(FormErrors.Required)
      .defined(FormErrors.Required)
      .trim(FormErrors.Required),
    questions: Yup.array()
      .of(
        QUESTION_META_VALIDATION_SCHEMA.shape({
          titleValue: TITLE_QUESTION_VALIDATION_SCHEMA,
          textValue: TEXT_QUESTION_VALIDATION_SCHEMA,
          multiSelectValue: updatedMultiSelectChoicesSchema
        })
      )
      .min(1, FormErrors.Required)
  });

};


export const getFeedbackTypeConfigFormValues = (
  feedbackLoop: IFeedbackLoop
): IFeedbackTypeConfigFormValues => {

  if (!feedbackLoop.feedbackType) {
    return {
      feedbackType: null
    };
  }

  const feedbackType = FEEDBACK_TYPES.find(
    (fType) => fType.value === feedbackLoop.feedbackType
  );

  return {
    feedbackType: feedbackType || null
  };
};


export const getFeedbackLoopSchedulerFormValues = (
  feedbackLoop: IFeedbackLoop
): ISchedulerFormValues => {

  if (!feedbackLoop.schedule) {
    return {
      schedulerDateTime: null,
      schedulerFrequency: null
    }
  }

  const schedulerFrequency = SCHEDULER_FREQUENCY_OPTIONS.options.find(
    (option) => option.value === feedbackLoop.schedule?.frequency
  ) || null;

  const schedulerDateTime = feedbackLoop.schedule.timestamp
    ?
    new Date(feedbackLoop.schedule.timestamp)
    :
    null;


  return {
    schedulerFrequency,
    schedulerDateTime
  };
};


export const getNotificationPreferenceFormValues = (
  feedbackLoop: IFeedbackLoop
): INotificationPreferenceConfigFormValues => {

  if (!feedbackLoop.notifications) {
    return {
      notificationPreference: null
    };
  }

  const notificationPreference = NOTIFICATION_SETTINGS.options.find(
    (nSetting) => nSetting.value === feedbackLoop.notifications
  );

  return {
    notificationPreference: notificationPreference || null
  };
};


export const getFeedbackForConfigFormValues = (
  feedbackLoop: IFeedbackLoop
): IFeedbackForConfigFormValues => {

  const isAnonymous = feedbackLoop.anonymity?.type ?
    feedbackLoop.anonymity.type === 'anonymous' : undefined;

  let feedbackFor: IFeedbackForConfigFormValues['feedbackFor'] = null;

  if (!feedbackLoop.feedbackFor) {
    return {
      isAnonymous,
      feedbackFor
    };
  }

  if (feedbackLoop.feedbackFor === FeedbackForAudienceTypes.SelfSelect) {
    feedbackFor = FEEDBACK_FOR_AUDIENCE_TYPES[0];
  } else {
    const selectedFeedbackFor = FEEDBACK_FOR_AUDIENCE_TYPES[1].options?.find(
      (option) => option.value === feedbackLoop.feedbackFor
    );
    feedbackFor = selectedFeedbackFor || null;
  }

  return {
    isAnonymous,
    feedbackFor
  };
};