import { faClone, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from '@themesberg/react-bootstrap';
import {
  ConfirmationModal,
  EmptyState,
  PageTitle,
  PermissionCheck,
  Preloader,
  PrimaryLayoutBox,
} from 'components';
import {
  deleteCustomSurveyTemplate,
  duplicateCustomSurveyTemplate,
  getCustomSurveyTemplates,
  getGroupedPredefinedSurveyTemplates,
} from 'data';
import { IGroupedSurveyTemplates, ISurveyTemplate, UserPermissions } from 'interfaces';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import { BUILD_FROM_SCRATCH_SURVEY_TEMPLATE } from 'teamble-constants';

import CreateNewSurveyModal from './modals/CreateNewSurveyModal';


interface ISurveyTemplateProps {
  template: ISurveyTemplate;
}

interface ICustomSurveyTemplateActionsProps extends ISurveyTemplateProps {
  onDelete: () => void;
  onDuplicate: (template: ISurveyTemplate) => void;
}


const CustomSurveyTemplateActions: React.FunctionComponent<ICustomSurveyTemplateActionsProps> = (
  { template, onDuplicate, onDelete }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const [showCreateSurveyModal, setShowCreateSurveyModal] = React.useState<boolean>(false);

  const duplicateTemplate = (templateId: string): void => {

    setIsLoading(true);

    duplicateCustomSurveyTemplate(templateId).pipe(
      finalize(() => setIsLoading(false))
    ).subscribe(
      (response) => {
        onDuplicate(response.data.data);
      }
    );
  };


  const deleteTemplate = (templateId: string): void => {

    setIsLoading(true);
    setShowConfirmationModal(false);

    deleteCustomSurveyTemplate(templateId).pipe(
      finalize(() => setIsLoading(false))
    ).subscribe(
      () => {
        onDelete();
      }
    );
  };


  return (
    <>
      <div className='position-relative'>
        {
          isLoading ?
            (
              <Button
                disabled
                variant='link'
                size='sm'
              >
                <Preloader isIcon show={isLoading} />
              </Button>
            ) :
            (
              <>
                <Button
                  variant='outline-primary'
                  size='sm'
                  onClick={() => setShowCreateSurveyModal(true)}
                >
                  Select
                </Button>
                <PermissionCheck permission={UserPermissions.PerformSurveyTemplateActions}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`${template.id}Edit`}>
                        Edit
                      </Tooltip>
                    }>
                    <Link to={`${Routes.SurveyTemplates.path}/${template.id}`} >
                      <Button
                        variant='link'
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </Link>
                  </OverlayTrigger>

                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`${template.id}Duplicate`}>
                        Duplicate
                      </Tooltip>
                    }>
                    <Button
                      variant='link'
                      size='sm'
                      onClick={(e) => {
                        e.stopPropagation();
                        duplicateTemplate(template.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faClone} />
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`${template.id}Delete`}>
                        Delete
                      </Tooltip>
                    }>
                    <Button
                      variant='link'
                      size='sm'
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowConfirmationModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </OverlayTrigger>
                </PermissionCheck>
              </>
            )
        }
      </div>
      {
        showConfirmationModal &&
        <ConfirmationModal
          title='Delete Survey Template'
          show={showConfirmationModal}
          onPositiveResponse={() => deleteTemplate(template.id)}
          onCloseClick={() => setShowConfirmationModal(false)}
        >
          <p>
            Are you sure to permanently delete the survey template <strong>{template.title}</strong>?
          </p>
        </ConfirmationModal>
      }
      {
        showCreateSurveyModal &&
        <CreateNewSurveyModal
          show={showCreateSurveyModal}
          type='custom'
          templateId={template.id}
          onClose={() => setShowCreateSurveyModal(false)}
        />
      }
    </>
  )
};


const CustomSurveyTemplate: React.FunctionComponent<ICustomSurveyTemplateActionsProps> = (
  { template, onDuplicate, onDelete }
) => {

  return (
    <div className='mb-3 d-flex'>
      <div className='flex-grow-1'>
        <p className='m-0'> <strong> {template.title} </strong> </p>
        <p className='m-0'> {template.desc}  </p>
      </div>
      <div className="flex-shrink-0">
        <CustomSurveyTemplateActions
          template={template}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
        />
      </div>
    </div>
  );

};


const PredefinedSurveyTemplate: React.FunctionComponent<ISurveyTemplateProps> = (
  { template }
) => {

  const [showCreateSurveyModal, setShowCreateSurveyModal] = React.useState<boolean>(false);
  const [isBuildFromScratch, setIsBuildFromScratch] = React.useState<boolean>(false);


  return (
    <>
      <div className='mb-3 d-flex'>
        <div className='flex-grow-1'>
          <p className='m-0'> <strong> {template.title} </strong> </p>
          <p className='m-0'> {template.desc}  </p>
        </div>
        <div className="flex-shrink-0">
          <Button
            variant='outline-primary'
            size='sm'
            onClick={() => {
              setIsBuildFromScratch(template.id === 'build-from-scratch');
              setShowCreateSurveyModal(true);
            }}
          >
            Select
          </Button>
        </div>
      </div>
      {
        showCreateSurveyModal &&
        <CreateNewSurveyModal
          isBuildFromScratch={isBuildFromScratch}
          show={showCreateSurveyModal}
          type='predefined'
          templateId={template.id}
          onClose={() => setShowCreateSurveyModal(false)}
        />
      }
    </>
  );

};


const CustomTemplatesWidget: React.FunctionComponent<{}> = () => {

  const [customTemplates, setCustomTemplates] = React.useState<ISurveyTemplate[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  React.useEffect(
    () => {

      setIsLoading(true);
      setCustomTemplates([]);

      getCustomSurveyTemplates()
        .pipe(
          finalize(
            () => setIsLoading(false)
          )
        )
        .subscribe(
          (response) => {
            setCustomTemplates(response.data.data);
          }
        );
    },
    []
  );


  const duplicateTemplate = (template: ISurveyTemplate, index: number): void => {
    const customTemplatesCopy = customTemplates.slice(0);
    customTemplatesCopy.splice(index + 1, 0, template);
    setCustomTemplates(customTemplatesCopy);
  };


  const deleteTemplate = (index: number): void => {

    const customTemplatesCopy = customTemplates.slice(0);
    customTemplatesCopy.splice(index, 1);
    setCustomTemplates(customTemplatesCopy);
  };


  return (
    <Card className='h-100'>
      <Card.Header>
        <Card.Title className='m-0'> Custom Templates </Card.Title>
      </Card.Header>
      <Card.Body className='position-relative overflow-y-auto'>
        {
          isLoading ?
            (<Preloader show={isLoading} />) :
            (
              <>
                {
                  !customTemplates.length ?
                    (
                      <EmptyState message={<>No Custom Templates found.</>} />
                    ) :
                    (
                      <>
                        {
                          customTemplates.map(
                            (template, index) => (
                              <CustomSurveyTemplate
                                key={template.id}
                                template={template}
                                onDelete={() => { deleteTemplate(index) }}
                                onDuplicate={(newTemplate) => duplicateTemplate(newTemplate, index)} />
                            )
                          )
                        }
                      </>
                    )
                }
              </>
            )
        }
      </Card.Body>
    </Card>
  )

};


const PredefinedTemplatesWidget: React.FunctionComponent<{}> = () => {

  const [groupedTemplates, setGroupedTemplates] = React.useState<IGroupedSurveyTemplates[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  React.useEffect(
    () => {

      setIsLoading(true);
      setGroupedTemplates([]);

      getGroupedPredefinedSurveyTemplates()
        .pipe(
          finalize(
            () => setIsLoading(false)
          )
        )
        .subscribe(
          (response) => {
            setGroupedTemplates([
              BUILD_FROM_SCRATCH_SURVEY_TEMPLATE as IGroupedSurveyTemplates,
              ...response.data.data
            ]);
          }
        );
    },
    []
  );


  const getFormattedGroupName = (groupKey: string): string => {

    const descriptionMap = {
      company: 'Company level surveys (admins only)',
      personal: 'Personal Surveys',
      team: 'Team Surveys',
    };

    return descriptionMap[groupKey as keyof typeof descriptionMap];
  };



  return (
    <Card className='h-100'>
      <Card.Header>
        <Card.Title className='m-0'> Use Templates </Card.Title>
      </Card.Header>
      <Card.Body className='position-relative overflow-y-auto'>
        {
          isLoading ?
            (<Preloader show={isLoading} />) :
            (
              <>
                {
                  !groupedTemplates.length ?
                    (
                      <EmptyState message={<>No Predefined Templates found.</>} />
                    ) :
                    (
                      <>
                        {
                          groupedTemplates.map(
                            (groupedTemplate) => {
                              return (
                                <div key={groupedTemplate.group} className='mb-4'>
                                  <h5> {getFormattedGroupName(groupedTemplate.group)} </h5>
                                  {
                                    groupedTemplate.templates.map(
                                      (template) => (
                                        <PredefinedSurveyTemplate
                                          key={template.id}
                                          template={template} />
                                      )
                                    )
                                  }
                                </div>
                              )
                            }
                          )
                        }
                      </>
                    )
                }
              </>
            )
        }
      </Card.Body>
    </Card>
  )
};


const SurveyTemplates: React.FunctionComponent<{}> = () => {

  return (
    <>
      <PageTitle title={Routes.SurveyTemplates.title} />
      <PrimaryLayoutBox title={Routes.SurveyTemplates.title}>
        <Row className='flex-grow-1 overflow-hidden'>
          <Col xs={6} className='h-100' >
            <PredefinedTemplatesWidget />
          </Col>
          <Col xs={6} className='h-100' >
            <CustomTemplatesWidget />
          </Col>
        </Row>
      </PrimaryLayoutBox>
    </>
  );
};

export default SurveyTemplates;
