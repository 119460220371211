import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { ConfirmationModal, FormField } from 'components';
import { FormContext } from 'contexts';
import { useFormik } from 'formik';
import { FormFieldTypeConstants, IModalProps, IReasonModalFormValues } from 'interfaces';
import * as React from 'react';

interface IReasonModalModalProps extends IModalProps {
  isSubmitting: boolean;
  onSubmit: (values: IReasonModalFormValues) => void;
}

interface IReasonModalFormProps {
  isSubmitting: boolean;
  onClose: IModalProps['onClose'];
  onSubmit: IReasonModalModalProps['onSubmit'];
}


const ReasonModalForm: React.FunctionComponent<IReasonModalFormProps> =
  ({ isSubmitting, onClose, onSubmit }) => {


    const [showDeclineConfirmationModal, setShowDeclineConfirmationModal] = React.useState<boolean>(false);

    const ReasonModalForm = useFormik<IReasonModalFormValues>({
      initialValues: {
        reason: ''
      },
      onSubmit: onSubmit
    });


    return (
      <>
        {!showDeclineConfirmationModal && <Form>
          <Modal.Body>
            <FormContext.Provider value={ReasonModalForm}>
              <FormField
                size='lg'
                label='Reason'
                placeholder='Please explain the reason for declining this review.'
                controlId='ReasonModalFormField'
                type={FormFieldTypeConstants.Textarea}
                controlName='reason' />
            </FormContext.Provider>
          </Modal.Body>
          <Modal.Footer className="justify-content-end px-4">
            <Button disabled={isSubmitting} type='button' variant="outline-primary" onClick={onClose}>
              Close
            </Button>
            <Button disabled={isSubmitting} onClick={() => {
              setShowDeclineConfirmationModal(true);
            }} variant="primary">
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Modal.Footer>
        </Form>}

        <ConfirmationModal
          title='Decline Review'
          submittingText='Submitting...'
          positiveText='Decline'
          isSubmitting={isSubmitting}
          show={showDeclineConfirmationModal}
          closeOnUserResponse={false}
          onPositiveResponse={() => ReasonModalForm.handleSubmit()}
          onNegativeResponse={() => setShowDeclineConfirmationModal(false)}
          onCloseClick={() => setShowDeclineConfirmationModal(false)}
        >
          <p>
            Are you sure you want to decline this review?
          </p>
          <i>It will be removed from your to-do list and you won’t be able to access it later.</i>
        </ConfirmationModal>

      </>
    );

  };


const ReasonModalModal: React.FunctionComponent<IReasonModalModalProps> = ({
  show, isSubmitting, onClose, onSubmit
}) => {
  return (

    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={onClose}
    >

      <Modal.Header closeButton >
        <Modal.Title> Add Reason </Modal.Title>
      </Modal.Header>

      <ReasonModalForm isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit} />

    </Modal>

  );
};

export default ReasonModalModal;
