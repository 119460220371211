import { Form } from '@themesberg/react-bootstrap';
import React from 'react'
import { CustomSelect } from 'components';
import { BEHAVIOUR_TYPE_OPTIONS, NONE_OPTION } from 'teamble-constants';
import { StringParam, useQueryParam } from 'use-query-params';
import { getSelectedOptionByQueryParam } from 'utils';
import { ISimpleMenuOption } from 'interfaces';

interface IBehaviourTypeFilterProps { }

const BehaviourTypeFilter: React.FC<IBehaviourTypeFilterProps> = () => {

  // Route Hooks
  const [behaviourType, setBehaviourType] = useQueryParam('behaviourType', StringParam);

  // Selected Options by query params
  const selectedBehaviourTypeOption = getSelectedOptionByQueryParam(
    behaviourType, BEHAVIOUR_TYPE_OPTIONS
  );

  return (
    <div>
      <Form.Label>Behaviour Type</Form.Label>
      <CustomSelect
        isSearchable
        options={[NONE_OPTION, ...BEHAVIOUR_TYPE_OPTIONS]}
        value={selectedBehaviourTypeOption}
        onChange={(option) => {
          setBehaviourType((option as ISimpleMenuOption).value);
        }}
      />
    </div>
  );
};

export default BehaviourTypeFilter;