import { FeedbackForAudienceTypes, FormErrors, IFeedbackLoop, IFeedbackTypes } from 'interfaces';
import React, { useEffect } from 'react';
import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';

export const useFeedbackLoopLaunchErrors = (
  feedbackLoop: IFeedbackLoop,
): string => {

  const [feedbackLoopError, setFeedbackLoopError] = React.useState<string>('');

  // Feedback Loop config hook
  const { feedbackForAudiencePairs } = useFeedbackLoopConfig();


  useEffect(
    () => {

      // Question blocks
      if (!feedbackLoop.blocks?.length) {
        setFeedbackLoopError(FormErrors.QuestionsRequired);
        return;
      }

      // Feedback Type
      if (!feedbackLoop.feedbackType) {
        setFeedbackLoopError(FormErrors.FeedbackLoopFeedbackTypeRequired);
        return;
      }

      // Audience
      if (!feedbackLoop.audience?.length) {
        setFeedbackLoopError(FormErrors.FeedbackLoopAudienceRequired);
        return;
      }

      // Feedback For
      if (
        feedbackLoop.feedbackType !== IFeedbackTypes.Company &&
        (
          !feedbackLoop.feedbackFor ||
          (
            feedbackLoop.feedbackFor === FeedbackForAudienceTypes.ManuallyDefine &&
            !feedbackLoop.senderReceiverInfos?.length
          )||
          (
            feedbackLoop.feedbackFor === FeedbackForAudienceTypes.ThreeSixty &&
            !feedbackForAudiencePairs?.length
          )
        )
      ) {
        setFeedbackLoopError(FormErrors.FeedbackLoopFeedbackForRequired);
        return;
      }

      // Notification Preference
      if (!feedbackLoop.notifications) {
        setFeedbackLoopError(FormErrors.FeedbackLoopNotificationPreferenceRequired);
        return;
      }

      setFeedbackLoopError('');

    },
    [
      feedbackLoop.audience,
      feedbackLoop.blocks,
      feedbackLoop.feedbackFor,
      feedbackLoop.feedbackType,
      feedbackLoop.notifications,
      feedbackLoop.senderReceiverInfos,
      feedbackForAudiencePairs
    ]
  );

  return feedbackLoopError;
};