import { FormErrors, IReminderFormValues } from 'interfaces';
import * as Yup from 'yup';


export const REMINDER_VALIDATION_SCHEMA: Yup.SchemaOf<IReminderFormValues> =
  Yup.object().shape(
    {
      note: Yup.string().defined().default(''),
      sendAs: Yup.array().of(
        Yup.string().defined()
      ).min(1, FormErrors.Required)
    }
  );