import React from "react";
import "./TextGradientPrimaryButton.scss";

interface TextGradientPrimaryButtonProps {
  text: string;
  onClick?: () => void;
  width?: string;
}

const TextGradientPrimaryButton: React.FC<TextGradientPrimaryButtonProps> = ({
  text,
  onClick,
  width,
}) => {
  return (
    <div
      style={{
        width,
      }}
      className="TextGradientPrimaryButton"
      onClick={() => {
        onClick?.();
      }}
    >
      <span className="craft-feedback-radiant-text">{text}</span>
    </div>
  );
};

export default TextGradientPrimaryButton;
