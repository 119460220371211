import * as React from "react";

const TeambleAiIcon = ({ width = 24, height = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
    style={{
      borderRadius: "100%",
    }}
    {...props}
  >
    <rect
      width={width}
      height={height}
      fill="url(#a)"
      rx={
        isNaN(Math.min(width, height) / 2) ? "0" : Math.min(width, height) / 2
      }
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.687 7.188c1-1.829 3.626-1.829 4.627 0l.653 1.195c.15.274.376.5.65.65l1.196.654c1.828 1 1.828 3.626 0 4.627l-1.195.653c-.275.15-.5.376-.65.65l-.655 1.196c-1 1.828-3.626 1.828-4.626 0l-.654-1.195c-.15-.275-.376-.5-.65-.65l-1.195-.655c-1.829-1-1.829-3.626 0-4.626l1.195-.654c.274-.15.5-.376.65-.65l.654-1.195Zm3.75.48c-.622-1.135-2.252-1.135-2.873 0l-.653 1.195A2.637 2.637 0 0 1 8.863 9.91l-1.195.653c-1.135.621-1.135 2.251 0 2.872l1.195.654c.442.242.806.605 1.048 1.048l.653 1.195c.621 1.135 2.251 1.135 2.872 0l.654-1.195a2.637 2.637 0 0 1 1.048-1.048l1.195-.654c1.135-.62 1.135-2.25 0-2.872l-1.195-.653a2.637 2.637 0 0 1-1.048-1.048l-.654-1.195Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.495 7.015c.795-2.417 4.214-2.417 5.01 0l.355 1.081c.162.494.55.881 1.043 1.044l1.081.355c2.418.795 2.418 4.214 0 5.01l-1.08.355c-.494.162-.882.55-1.044 1.043l-.356 1.081c-.795 2.418-4.214 2.418-5.009 0l-.355-1.08a1.637 1.637 0 0 0-1.044-1.044l-1.08-.356c-2.418-.795-2.418-4.214 0-5.009l1.08-.355c.494-.163.881-.55 1.044-1.044l.355-1.08Zm4.06.312c-.494-1.5-2.617-1.5-3.11 0l-.356 1.082a2.637 2.637 0 0 1-1.68 1.68l-1.082.356c-1.5.493-1.5 2.616 0 3.11l1.082.355c.795.262 1.419.886 1.68 1.681l.356 1.081c.493 1.5 2.616 1.5 3.11 0l.355-1.081a2.637 2.637 0 0 1 1.681-1.681l1.081-.355c1.5-.494 1.5-2.617 0-3.11l-1.081-.356a2.637 2.637 0 0 1-1.681-1.68l-.355-1.082Z"
      clipRule="evenodd"
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(45 .53 -1.28) scale(58.3363)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6463" />
        <stop offset={0.239} stopColor="#FF468B" />
        <stop offset={0.499} stopColor="#B724FF" />
        <stop offset={0.801} stopColor="#336BFF" />
        <stop offset={1} stopColor="#0090FF" />
      </radialGradient>
    </defs>
  </svg>
);

export default TeambleAiIcon;
