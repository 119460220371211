import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Collapse, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormikProps } from 'formik';
import { useApp } from 'hooks';
import {
  FormFieldTypeConstants,
  IActiveReviewFormValues,
  IChannelMenuOption,
  IMenuOption,
  ISimpleMenuOption,
  SignInWith,
} from 'interfaces';
import React from 'react';

import LaunchReviewTemplateQuestionPreviewModal from '../modals/LaunchReviewTemplateQuestionPreviewModal';
import { finalize } from 'rxjs';
import { getCampaignLinkOptions } from 'data';

interface ILaunchReviewBasicConfigWidgetProps {
  launchNewReviewForm: FormikProps<IActiveReviewFormValues>;
  reviewTemplates: IMenuOption[] | null;
  channelOptions: IChannelMenuOption[] | null;
}

const LaunchReviewBasicConfigWidget: React.FC<ILaunchReviewBasicConfigWidgetProps> = (
  { launchNewReviewForm, reviewTemplates, channelOptions }
) => {

  /* State hooks */
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
  const [showQuestionPreviewModal, setShowQuestionPreviewModal] = React.useState<boolean>(false);

  /* App hook */
  const app = useApp();

  const [campaignLinkOptions, setCampaignLinkOptions] = React.useState<ISimpleMenuOption[]>([])
  const [campaignLinkOptionsLoading, setCampaignLinkOptionsLoading] = React.useState<Boolean>(false)

  React.useEffect(() => {

    const templateId = launchNewReviewForm?.values?.reviewTemplate?.value;
    if (!templateId) {
      return;
    };

    setCampaignLinkOptionsLoading(true);
    getCampaignLinkOptions(templateId)
      .pipe(finalize(() => setCampaignLinkOptionsLoading(false)))
      .subscribe((response) => {
        setCampaignLinkOptions(response.data.data);
      });

  }, [launchNewReviewForm?.values?.reviewTemplate?.value])

  return (
    <>
      <Card className='my-2'>

        <Card.Header
          aria-controls='launchReviewBasicConfigWidget'
          aria-expanded={!isCollapsed}
          className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Card.Title className='m-0' as='h6'>
            Basic Configurations
          </Card.Title>
          <div>
            {
              launchNewReviewForm.values.reviewTemplate?.value &&
              <Button
                size='sm'
                variant='outline-primary'
                onClick={(e) => {
                  e.stopPropagation(); // To stop closing of the Basic Configurations card
                  setShowQuestionPreviewModal(true);
                }}
              >
                Questions Preview
              </Button>
            }
            <Button variant='link' size='sm'>
              <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
            </Button>
          </div>
        </Card.Header>

        <Collapse in={!isCollapsed}>
          <Card.Body id='launchReviewBasicConfigWidget'>
            <Row>
              <Col xs='6'>
                <FormField
                  placeholder='Enter review name'
                  label='Review Name *'
                  controlId='reviewTitleField'
                  type={FormFieldTypeConstants.Text}
                  controlName='title'
                  textValueChangeDebounceTime={350}
                />
              </Col>

              <Col xs='6'>
                <FormField
                  label='Review Template *'
                  controlId='selectReviewTemplateField'
                  type={FormFieldTypeConstants.Select}
                  options={reviewTemplates || []}
                  controlName='reviewTemplate'
                  placeholder='Select review template'
                  onChange={() => launchNewReviewForm.setFieldValue('multiCampaign', null)}
                />
              </Col>

            </Row>

            <Row>

              <Col xs='6'>
                <FormField
                  label={app === SignInWith.Slack ? 'Slack Workspace *' : 'Group *'}
                  controlId='selectWorkspaceField'
                  type={FormFieldTypeConstants.Select}
                  options={channelOptions || []}
                  controlName='channel'
                  placeholder={app === SignInWith.Slack ? 'Select slack workspace' : 'Select group'}
                  onChange={() => launchNewReviewForm.setFieldValue('reviewerRevieweeInfos', null)}
                />
              </Col>

              <Col xs='6'>
                {
                  Boolean(launchNewReviewForm.values.reviewTemplate) && (
                    <FormField
                      label="Link Campaigns"
                      multiple
                      controlId='multiCampaignField'
                      type={FormFieldTypeConstants.Select}
                      isLoading={Boolean(campaignLinkOptionsLoading)}
                      options={campaignLinkOptions || []}
                      controlName='multiCampaign'
                      placeholder="Select Campaigns"
                    />
                  )
                }
              </Col>

            </Row>

            <Row>
              <Col>
                <FormField
                  label='Anonymous'
                  controlId='anonymousField'
                  type={FormFieldTypeConstants.Checkbox}
                  controlName='anonymous' />
              </Col>
            </Row>
          </Card.Body>
        </Collapse>

      </Card >

      {
        launchNewReviewForm.values.reviewTemplate?.value && showQuestionPreviewModal &&
        <LaunchReviewTemplateQuestionPreviewModal
          reviewTemplateId={launchNewReviewForm.values.reviewTemplate.value}
          show={showQuestionPreviewModal}
          onClose={() => setShowQuestionPreviewModal(false)} />
      }

    </>
  );
};

export default LaunchReviewBasicConfigWidget;