import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ViewMode = "ADMIN" | "USER";

interface GlobalSettingsState {
  view: ViewMode;
}

const initialState: GlobalSettingsState = {
  view: "USER",
};

const globalSettingsSlice = createSlice({
  name: "globalSettings",
  initialState,
  reducers: {
    toggleView: (state) => {
      state.view = state.view === "USER" ? "ADMIN" : "USER";
    },
  },
});

export const { toggleView } = globalSettingsSlice.actions;

export default globalSettingsSlice.reducer;
