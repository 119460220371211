import { Card } from '@themesberg/react-bootstrap';
import { ISimpleMenuOption } from 'interfaces';
import React from 'react';

interface IFeedbackInsightOverviewDataCardProps extends ISimpleMenuOption { }

const FeedbackInsightOverviewDataCard: React.FC<IFeedbackInsightOverviewDataCardProps> = (
  { label, value }
) => {

  return (
    <Card className='bg-gray-300 border-0 h-100'>
      <Card.Body
        className='d-flex flex-column justify-content-between align-items-center text-center px-2'
      >
        <p className="fs-7 fw-semibold">{label}</p>
        <p className="fs-4 text-secondary m-0">{value}</p>
      </Card.Body>
    </Card>
  );
};

export default FeedbackInsightOverviewDataCard;