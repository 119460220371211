import { getRelationshipAsMenuOptions } from "data";
import { IHookResponse, IRelationshipsResponse } from "interfaces";
import React, { useEffect } from "react";
import { finalize } from "rxjs";

export const useRelationshipsMenuOptions = (): IHookResponse<IRelationshipsResponse[]> => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [relationshipsOptions, setRelationshipsOptions] =
    React.useState<IRelationshipsResponse[]>([]);

  useEffect(
    () => {
      setIsLoading(true);
      setRelationshipsOptions([]);

      const subscription = getRelationshipAsMenuOptions()
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe((response) => {
          setRelationshipsOptions(response.data.data);
        });

      return () => subscription.unsubscribe();
    },
    []
  );

  return {
    isLoading,
    data: relationshipsOptions,
  };
};
