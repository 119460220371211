import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormContext } from 'contexts';
import { FormikProps } from 'formik';
import { useApp } from 'hooks';
import {
  FormFieldTypeConstants,
  IRouteParams,
  ISimpleMenuOption,
  ISurvey,
  ISurveyAudience,
  ISurveyAudienceBuilderFormValues,
  ISurveyInspector,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Routes } from 'routes';
import {
  MSTEAMS_AUDIENCE_TYPES,
  SLACK_AUDIENCE_TYPES,
  SURVEY_INSPECTOR_TYPES,
  SURVEY_RESULT_OPTIONS,
} from 'teamble-constants';

import SurveyAudience from './SurveyAudience';
import SurveyInspectors from './SurveyInspectors';
import SurveyGroupsDetailsModal from "./SurveyGroupsDetailsModal";
import { If } from '@common/components';

interface ISurveyAudienceBuilderFormProps {
  isLoading: boolean;
  slackChannels: ISimpleMenuOption[];
  teamsGroups: ISimpleMenuOption[];
  surveyDetails: ISurvey;
  surveyAudience: ISurveyAudience[];
  surveyInspectors: ISurveyInspector[];
  workspaceOrChannelUsers: ISimpleMenuOption[];
  surveyAudienceBuilderForm: FormikProps<ISurveyAudienceBuilderFormValues>;
  onAudienceUpdate: (isClear?: boolean) => void;
  onInspectorUpdate: (isClear?: boolean) => void;
  onAnonymityUpdate: () => void;
  onViewResultsUpdate: () => void;
  onSaveAndNextClick: () => void;
}


const SurveyAudienceBuilderForm: React.FunctionComponent<ISurveyAudienceBuilderFormProps> = (
  {
    isLoading, surveyAudienceBuilderForm, slackChannels, workspaceOrChannelUsers, teamsGroups,
    surveyAudience, surveyInspectors, surveyDetails,
    onSaveAndNextClick, onAudienceUpdate, onInspectorUpdate,
    onAnonymityUpdate, onViewResultsUpdate
  }
) => {

  const [showGroupDetailsModal, setShowGroupDetailsModal] = React.useState<boolean>(false);

  const { surveyId } = useParams<IRouteParams>();
  const app = useApp();
  return (
    <Card className='h-100'>
      <Card.Header>
        <Card.Title className='m-0'>Configure</Card.Title>
      </Card.Header>
      <Card.Body className='overflow-y-auto'>
        <Form>
          <FormContext.Provider value={surveyAudienceBuilderForm}>

            <Card>
              <Card.Header className='bg-light py-2 px-3'>
                <Form.Label className='fw-bolder mb-0'> Audience </Form.Label>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <FormField
                      controlId={`surveyAudienceFormAudienceType`}
                      type={FormFieldTypeConstants.Select}
                      options={app === SignInWith.Slack ? SLACK_AUDIENCE_TYPES : MSTEAMS_AUDIENCE_TYPES}
                      controlName='audienceType' />
                  </Col>
                  <Col>
                    {
                      surveyAudienceBuilderForm.values.audienceType?.value === 'users' &&
                      <FormField
                        multiple
                        controlId={`surveyAudienceFormAudienceUsers`}
                        type={FormFieldTypeConstants.Select}
                        options={workspaceOrChannelUsers}
                        controlName='selectedAudienceUsers' />
                    }

                    {
                      surveyAudienceBuilderForm.values.audienceType?.value === 'channels' &&
                      <FormField
                        multiple
                        controlId={`surveyAudienceFormAudienceChannels`}
                        type={FormFieldTypeConstants.Select}
                        optionType='slackChannel'
                        options={slackChannels}
                        controlName='selectedSlackChannels' />
                    }

                    {
                      surveyAudienceBuilderForm.values.audienceType?.value === 'groups' &&
                      <FormField
                        multiple
                        controlId={`surveyAudienceFormAudienceGroups`}
                        type={FormFieldTypeConstants.Select}
                        optionType='default'
                        options={teamsGroups}
                        controlName='selectedGroups' />
                    }

                  </Col>
                </Row>
                <SurveyAudience surveyAudience={surveyAudience} />
              </Card.Body>
              <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
                <Button
                  size='sm'
                  type='button'
                  variant='danger'
                  className='me-2'
                  disabled={isLoading}
                  onClick={() => onAudienceUpdate(true)}> Clear </Button>

                {
                  Boolean(surveyAudienceBuilderForm.values?.selectedGroups?.length) && (
                    <Button
                      size='sm'
                      type='button'
                      variant='outline-success'
                      className='me-2'
                      disabled={isLoading}
                      onClick={() => setShowGroupDetailsModal(true)}>
                      Show Group Details
                    </Button>
                  )
                }

                <Button
                  size='sm'
                  type='button'
                  disabled={isLoading}
                  onClick={() => onAudienceUpdate()}> Save </Button>
              </Card.Footer>
            </Card>

            <Card className='my-3'>
              <Card.Header className='bg-light py-2 px-3'>
                <Form.Label className='fw-bolder mb-0'> Result Viewers </Form.Label>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <FormField
                      controlId={`surveyAudienceFormInspectorType`}
                      type={FormFieldTypeConstants.Select}
                      options={SURVEY_INSPECTOR_TYPES}
                      controlName='inspectorType' />
                  </Col>
                  <Col>
                    {
                      surveyAudienceBuilderForm.values.inspectorType?.value === 'users' &&
                      <FormField
                        multiple
                        controlId={`surveyAudienceFormAudienceUsers`}
                        type={FormFieldTypeConstants.Select}
                        options={workspaceOrChannelUsers}
                        controlName='selectedInspectorsUsers' />
                    }
                  </Col>
                </Row>
                <SurveyInspectors surveyInspectors={surveyInspectors} />
              </Card.Body>
              <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
                <Button
                  size='sm'
                  type='button'
                  variant='danger'
                  className='me-2'
                  disabled={isLoading}
                  onClick={() => onInspectorUpdate(true)}> Clear </Button>
                <Button
                  type='button'
                  size='sm'
                  disabled={isLoading}
                  onClick={() => onInspectorUpdate()}> Save </Button>
              </Card.Footer>
            </Card>

            <Card className='my-3'>
              <Card.Header className='bg-light py-2 px-3'>
                <Form.Label className='fw-bolder mb-0'> Anonymity </Form.Label>
              </Card.Header>
              <Card.Body>
                <FormField
                  label='Anonymous'
                  controlId='anonymousField'
                  type={FormFieldTypeConstants.Checkbox}
                  controlName='isAnonymous' />
                {
                  surveyAudienceBuilderForm.values.isAnonymous &&
                  <div className='w-25'>
                    <FormField
                      label='Minimum Submission Count'
                      controlId='surveyAudienceMinimumSubmissionCount'
                      type={FormFieldTypeConstants.Number}
                      minValue={0}
                      controlName='minSubmissionCount' />
                  </div>
                }
              </Card.Body>
              <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
                <Button
                  type='button'
                  size='sm'
                  disabled={isLoading}
                  onClick={onAnonymityUpdate}> Save </Button>
              </Card.Footer>
            </Card>

            <Card className='my-3'>
              <Card.Header className='bg-light py-2 px-3'>
                <Form.Label className='fw-bolder mb-0'> View Survey Result </Form.Label>
              </Card.Header>
              <Card.Body>
                <FormField
                  size='lg'
                  type={FormFieldTypeConstants.Radio}
                  controlName='liveResults'
                  options={SURVEY_RESULT_OPTIONS} />
              </Card.Body>
              <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
                <Button
                  type='button'
                  size='sm'
                  disabled={isLoading}
                  onClick={onViewResultsUpdate}> Save </Button>
              </Card.Footer>
            </Card>

          </FormContext.Provider>
        </Form>
      </Card.Body>
      {
        !!surveyId &&
        <Card.Footer className='d-flex justify-content-end'>
          <Link to={`${Routes.SurveyLibrary.path}/${surveyId}`}>
            <Button
              className='ms-3'
              variant="outline-success"
              type="button"
            >
              Previous
            </Button>
          </Link>
          <Button
            type="button"
            className='ms-3'
            variant="success"
            disabled={isLoading}
            onClick={onSaveAndNextClick}
          >
            {isLoading ? 'Saving...' : 'Save & Next'}
          </Button>
        </Card.Footer>
      }

      <If condition={showGroupDetailsModal}>
        <SurveyGroupsDetailsModal
          show={showGroupDetailsModal}
          teambleTeamId={surveyDetails.teamId}
          groupIds={surveyAudienceBuilderForm.values?.selectedGroups?.map(data => data.value)}
          onClose={() => setShowGroupDetailsModal(false)}
        />
      </If>

    </Card>
  );
};

export default SurveyAudienceBuilderForm;
