import React from 'react';
import { getFeedbackTypeText } from 'utils';

interface IFeedbackTypeProps {
  feedbackType: string;
  feedbackTypeHeader: string;
  feedbackTypeTitle?: string;
}

const FeedbackType: React.FC<IFeedbackTypeProps> = (
  { feedbackType, feedbackTypeHeader, feedbackTypeTitle }
) => {

  const feedbackTypeText = feedbackTypeTitle || getFeedbackTypeText(feedbackType, feedbackTypeHeader);

  return (
    <p> {feedbackTypeText} </p>
  );
};

export default FeedbackType;