import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { Divider, SubmitButton } from 'components';
import FormField from 'components/FormComponents/FormField';
import { useUserProfile } from 'components/ProvideUserProfile';
import { FormContext } from 'contexts';
import { FormikErrors } from 'formik';
import {
  FormErrors,
  FormFieldTypeConstants,
  IFeedbackLoopTemplateTypes,
  IQuestionBuilderFormStatus,
  IQuestionBuilderFormType,
  IRouteParams,
  SignInWith,
} from 'interfaces';
import * as React from 'react';

import QuestionBuilder, { useQuestionBuilder } from './QuestionBuilder';
import { useCompanyConfig } from '@common/modules/companyConfig';
import { useApp } from 'hooks';
import { useParams } from 'react-router-dom';

interface IQuestionBuilderFormProps {
}

const QuestionBuilderForm: React.FunctionComponent<IQuestionBuilderFormProps> = () => {

  const {
    builderForm, isTemplate, previewQuestionKey,
    onCreateTemplateClick, onNextClick, onPreviewQuestionKeyChange
  } = useQuestionBuilder<IQuestionBuilderFormType>();

  const cardBodyRef = React.useRef<HTMLDivElement>(null);
  const { userProfile } = useUserProfile();
  const companyConfig = useCompanyConfig();
  const competencyTitle = companyConfig?.customText?.competency || "Competency";
  const {templateType} = useParams<IRouteParams>()
  const app = useApp();
  const isSlackApp = app === SignInWith.Slack;

  React.useEffect(() => {
    if (previewQuestionKey === null) {
      cardBodyRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

  }, [previewQuestionKey]);


  const scrollToFormErrorField = (): void => {

    if (builderForm.errors.title) {
      cardBodyRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }

    if (!(builderForm.values.questions.length && builderForm.errors.questions?.length)) {
      return;
    }

    const errorIndex = ((builderForm.errors.questions) as FormikErrors<IQuestionBuilderFormType>[])
      .findIndex((error) => error && Object.keys(error).length);

    if (errorIndex === -1) {
      return;
    }
    onPreviewQuestionKeyChange(errorIndex);
  };


  const handleFormSubmissionAction = (
    action: 'template' | 'submit' | 'next',
    event?: React.FormEvent<HTMLFormElement>
  ): void => {

    event?.preventDefault();

    if (!Object.keys(builderForm.errors).length) {
      switch (action) {

        case 'next': {
          onNextClick();
          break;
        }

        case 'template': {
          onCreateTemplateClick();
          break;
        }

        case 'submit': {
          builderForm.handleSubmit(event);
          break;
        }
      }
      return;
    }

    builderForm.handleSubmit(event);
    scrollToFormErrorField();
  };


  return (
    <Card className='flex-grow-1 overflow-hidden'>
      <Form
        className='h-100 d-flex flex-column'
        onSubmit={(e) => handleFormSubmissionAction('submit', e)}
      >

        <Card.Body ref={cardBodyRef} className='overflow-y-auto flex-grow-1 overflow-x-hidden'>
          <FormContext.Provider value={builderForm}>
            <FormField
              label='Name *'
              controlId='builderName'
              type={FormFieldTypeConstants.Text}
              placeholder='Enter Name...'
              controlName='title'
            />

            {
              isSlackApp && (
                <FormField
                  label={`Hide ${competencyTitle} Selector`}
                  controlId='hideCompetencySelector'
                  type={FormFieldTypeConstants.Checkbox}
                  controlName='hideCompetencySelector'
                />
              )
            }

            <Divider />
            {
              builderForm.values.questions?.length
                ?
                <QuestionBuilder.Accordion />
                :
                builderForm.submitCount > 0
                  ?
                  <Form.Control.Feedback type="invalid" className='d-block'>
                    {FormErrors.QuestionsRequired}
                  </Form.Control.Feedback>
                  :
                  null
            }
          </FormContext.Provider>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end flex-shrink-0'>

          {
            userProfile!.isSuperAdmin &&  templateType===IFeedbackLoopTemplateTypes?.Feedback &&
            <Button
              className='ms-3'
              variant="outline-primary"
              type="button"
              disabled={builderForm.status !== IQuestionBuilderFormStatus.Initial}
              onClick={() => handleFormSubmissionAction('template')}
            >
              {isTemplate ? 'Update Template' : 'Create Template'}
            </Button>
          }

          <SubmitButton
            text='Save'
            className='ms-3'
            submittingText='Saving...'
            disabled={builderForm.status !== IQuestionBuilderFormStatus.Initial}
            isSubmitting={builderForm.status === IQuestionBuilderFormStatus.Saving}
          />

          {
            !isTemplate &&
            <Button
              type="button"
              className='ms-3'
              variant="success"
              disabled={builderForm.status !== IQuestionBuilderFormStatus.Initial}
              onClick={() => handleFormSubmissionAction('next')}
            >
              {
                builderForm.status === IQuestionBuilderFormStatus.Redirecting ?
                  'Redirecting...' : 'Next'
              }
            </Button>
          }

        </Card.Footer>

      </Form>
    </Card>
  );
};

export default QuestionBuilderForm;
