import {
  FormErrors,
  IActiveReview,
  IActiveReviewAudience,
  IActiveReviewFormValues,
  IAnswer,
  ILaunchReviewRequestData,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IQuestionTypeConstants,
  IReminderFormValues,
  IReminderRequestData,
  IReviewAnalyticsCell,
  IReviewAnalyticsColumn,
  IReviewAnalyticsComputedCell,
  IReviewAnalyticsRow,
  IReviewAnalyticsViewCell,
  IReviewChoiceWithValue,
  IReviewCommentsWithValue,
  IReviewerRevieweeInfosRequestData,
  IReviewTemplateCompetencyQuestionValue,
  IReviewTemplateFormValues,
  IReviewTemplateObjectiveQuestionValue,
  IReviewTemplateQuestion,
  IReviewTemplateQuestionValidationSchema,
  IReviewTemplateRequestData,
  IReviewTemplateRequestDataMultiSelectBlock,
  IReviewTemplateValidationSchema,
  IReviewTodoRevieweeItem,
  IUserProfile,
  ReviewResultCanShareBy,
  ReviewResultShareAfter,
  ReviewResultShareWith,
  ReviewTodoTypes,
  SendReminderAsTypes,
  ShareReviewResultTypes,
  SignInWith,
} from 'interfaces';
import moment from 'moment';
import React from 'react'
import { Routes } from 'routes';
import { DATE_TIME_FORMAT, MULTI_SELECT_QUESTION_NA_OPTION } from 'teamble-constants';
import { getTurndownServiceInstance } from 'utils';
import {
  REVIEW_MULTI_SELECT_QUESTION_VALIDATION_SCHEMA,
  REVIEW_TEMPLATE_NAME_SCHEMA,
  SIMPLE_MENU_OPTION_VALIDATION_SCHEMA,
  TEXT_QUESTION_VALIDATION_SCHEMA,
  TITLE_QUESTION_VALIDATION_SCHEMA,
} from 'validation-schemas';
import * as Yup from 'yup';


export const getMultiSelectChoices = (
  app: SignInWith,
  choices?: IReviewChoiceWithValue[] | string,
): Partial<IMenuOption>[] | null => {

  if (!choices?.length) {
    return null;
  }

  const options: Partial<IMenuOption>[] = (choices as IReviewChoiceWithValue[]).map(
    (choice: IReviewChoiceWithValue) => {
      return {
        value: choice.choice,
        label: choice.choice,
      };
    }
  );

  // switch (app) {

  //   case SignInWith.Slack: {
  //     options = (choices as IReviewChoiceWithValue[]).map(
  //       (choice: IReviewChoiceWithValue) => {
  //         return {
  //           value: choice.choice,
  //           label: choice.choice,
  //         };
  //       }
  //     );
  //     break;
  //   }

  //   case SignInWith.MsTeams: {
  //     options = (choices as string)
  //       .split('\n')
  //       .filter((c) => !!c)
  //       .map(
  //         (c) => ({
  //           label: c
  //         })
  //       );
  //     break;
  //   }

  // }

  return options;
};


export const getMultiSelectChoicesWithValues = (
  app: SignInWith,
  choicesWithValue?: IReviewChoiceWithValue[] | string,
): {
  choices: string[],
  choicesValues: IReviewTemplateRequestDataMultiSelectBlock['choicesValues']
} | null => {

  if (!choicesWithValue?.length) {
    return null;
  }

  // common base.
  choicesWithValue = choicesWithValue as IReviewChoiceWithValue[];
  const choices = choicesWithValue?.map(choice => choice.choice) || [];
  const choicesValues: IReviewTemplateRequestDataMultiSelectBlock['choicesValues'] = {};

  choicesWithValue?.forEach(
    (choice) => {
      choicesValues[choice.choice] = choice.value;
    }
  );

  return {
    choices,
    choicesValues,
  };

  // switch (app) {

  //   case SignInWith.Slack: {

  //     choicesWithValue = choicesWithValue as IReviewChoiceWithValue[];
  //     const choices = choicesWithValue?.map(choice => choice.choice) || [];
  //     const choicesValues: IReviewTemplateRequestDataMultiSelectBlock['choicesValues'] = {};

  //     choicesWithValue?.forEach(
  //       (choice) => {
  //         choicesValues[choice.choice] = choice.value;
  //       }
  //     );

  //     return {
  //       choices,
  //       choicesValues,
  //     };
  //   }


  //   case SignInWith.MsTeams: {
  //     const choices =
  //       (choicesWithValue as string)
  //         .split('\n')
  //         .filter((c) => !!c);

  //     return {
  //       choices,
  //       choicesValues: {}
  //     };
  //   }


  //   default: {
  //     return null;
  //   }

  // }

}


export const getRequestDataOfUpdateUsersInActiveReview = (
  audiencePairs: Partial<IActiveReviewAudience>[]
): IReviewerRevieweeInfosRequestData[] => {

  return audiencePairs?.map(
    (pair) => ({
      revieweeUserId: (pair.reviewee as IMenuOption).value,
      reviewerUserId: (pair.reviewer as IMenuOption).value
    })
  );

};


export const getDistinctDuplicateAudiencePairs = (
  selectedAudiencePairs: Partial<IActiveReviewAudience>[],
  activeReviewAudiencePairs: IActiveReviewAudience[]
): Partial<IActiveReviewAudience>[] | null => {

  if (!selectedAudiencePairs?.length) {
    return null;
  }

  const distinctAudiencePairs: Partial<IActiveReviewAudience>[] = [];
  const activeReviewAudiencePairsObject: {
    [key: string]: IActiveReviewAudience
  } = {};

  activeReviewAudiencePairs.forEach(
    (activeReviewAudience) => {

      activeReviewAudiencePairsObject[`${activeReviewAudience.reviewee.value}_${activeReviewAudience.reviewer.value}`] =
        activeReviewAudience;

    }
  );

  selectedAudiencePairs.forEach(
    (selectedAudiencePair) => {

      const key = `${selectedAudiencePair?.reviewee?.value}_${selectedAudiencePair?.reviewer?.value}`;
      if (!(key in activeReviewAudiencePairsObject)) {
        distinctAudiencePairs.push(selectedAudiencePair);
      }

    }
  );

  return distinctAudiencePairs;
};


const getUtcTimestamp = (localDateTime: Date): number => {
  return +moment(localDateTime).utc().format('x');
};


export const getActiveReviewDates =
  (activeReview: IActiveReview | null): string[] => {


    if (!activeReview) {
      return ['', ''];
    }

    const dates: string[] = [];

    if (activeReview.startDate) {
      dates.push(moment(activeReview.startDate, 'x').local().format(DATE_TIME_FORMAT));
    } else if (activeReview.launchedAt) {
      dates.push(moment(activeReview.launchedAt, 'x').local().format(DATE_TIME_FORMAT));
    } else {
      dates.push('');
    }

    if (activeReview.closeSchedule?.timestamp) {
      dates.push(moment(activeReview.closeSchedule.timestamp, 'x').local().format(DATE_TIME_FORMAT));
    } else if (activeReview.closedAt) {
      dates.push(moment(activeReview.closedAt, 'x').local().format(DATE_TIME_FORMAT));
    } else {
      dates.push('');
    }

    return dates;
  }


export const getLaunchReviewRequestData =
  (app: IUserProfile['app'], values: IActiveReviewFormValues): ILaunchReviewRequestData | null => {

    if (
      values.channel &&
      values.reviewTemplate &&
      (values.reviewerRevieweeInfos || values?.nominatorAudience)
    ) {

      const reviewerRevieweeInfos: ILaunchReviewRequestData['reviewerRevieweeInfos'] =
        values?.reviewerRevieweeInfos?.map(
          (audiencePair) => ({
            revieweeUserId: audiencePair?.reviewee?.value,
            reviewerUserId: audiencePair?.reviewer?.value
          })
        );

      const canReviewerShareResult = values.canShareResult.find(
        (option) => option.value === ReviewResultCanShareBy.Reviewer
      );

      const requestData: ILaunchReviewRequestData = {
        title: values.title,
        type: values.anonymous ? 'anonymous' : 'non-anonymous',
        perfReviewTemplateId: values.reviewTemplate.value,
        startDate: values.startDate ? getUtcTimestamp(values.startDate) : null,
        endDate: values.endDate ? getUtcTimestamp(values.endDate) : null,
        companyId: values.channel.companyId as string,
        resultSharingConfig: {
          isAutomatic: values.shareWithType === ShareReviewResultTypes.AUTOMATED,
          isManual: values.shareWithType === ShareReviewResultTypes.MANUAL,
          isManualToReviewer: values.shareWithType === ShareReviewResultTypes.MANUAL && !!canReviewerShareResult,
          shareResultWith: values.shareResultWith.map(option => option.value),
          isAutomaticOnSubmitReview: values.shareWithType === ShareReviewResultTypes.AUTOMATED &&
            values.shareResultAfter?.value === ReviewResultShareAfter.Submitted,
          isAutomaticOnReviewClose: values.shareWithType === ShareReviewResultTypes.AUTOMATED &&
            values.shareResultAfter?.value === ReviewResultShareAfter.Closed,
        },
        ...(reviewerRevieweeInfos?.length ? { reviewerRevieweeInfos } : { reviewerRevieweeInfos: [] }),
        isAutomaticCloseOnEndDate: values.isAutomaticCloseOnEndDate,
      }

      if (values.multiCampaign?.length) {
        requestData.multiCampaign = values.multiCampaign.map(option => option.value)
      }

      if (app === SignInWith.Slack) {
        requestData.workspaceId = values.channel.value;
      } else if (app === SignInWith.MsTeams) {
        requestData.channelId = values.channel.value;
      }

      return requestData;
    }

    return null;

  };


export const getSaveReviewRequestData =
  (app: IUserProfile['app'], values: IActiveReviewFormValues): Partial<ILaunchReviewRequestData> | null => {

    if (values.title && values.channel) {

      const reviewerRevieweeInfos: ILaunchReviewRequestData['reviewerRevieweeInfos'] | undefined =
        values.reviewerRevieweeInfos?.map(
          (audiencePair) => ({
            revieweeUserId: audiencePair.reviewee.value,
            reviewerUserId: audiencePair.reviewer.value
          })
        );

      const canReviewerShareResult = values.canShareResult.find(
        (option) => option.value === ReviewResultCanShareBy.Reviewer
      );

      const requestData: Partial<ILaunchReviewRequestData> = {
        title: values.title,
        type: values.anonymous ? 'anonymous' : 'non-anonymous',
        perfReviewTemplateId: values.reviewTemplate?.value,
        startDate: values.startDate ? getUtcTimestamp(values.startDate) : null,
        endDate: values.endDate ? getUtcTimestamp(values.endDate) : null,
        companyId: values.channel?.companyId,
        resultSharingConfig: {
          isAutomatic: values.shareWithType === ShareReviewResultTypes.AUTOMATED,
          isManual: values.shareWithType === ShareReviewResultTypes.MANUAL,
          shareResultWith: values.shareResultWith.map(option => option.value),
          isManualToReviewer: values.shareWithType === ShareReviewResultTypes.MANUAL && !!canReviewerShareResult,
          isAutomaticOnSubmitReview: values.shareWithType === ShareReviewResultTypes.AUTOMATED &&
            values.shareResultAfter?.value === ReviewResultShareAfter.Submitted,
          isAutomaticOnReviewClose: values.shareWithType === ShareReviewResultTypes.AUTOMATED &&
            values.shareResultAfter?.value === ReviewResultShareAfter.Closed,
        },
        reviewerRevieweeInfos,
        isAutomaticCloseOnEndDate: values?.isAutomaticCloseOnEndDate
      };

      if (values.multiCampaign?.length) {
        requestData.multiCampaign = values.multiCampaign.map(option => option.value)
      }

      if (app === SignInWith.Slack) {
        requestData.workspaceId = values.channel?.value;
      } else if (app === SignInWith.MsTeams) {
        requestData.channelId = values.channel?.value;
      }

      return requestData;
    }

    return null;

  };


export const getReviewTemplateRequestData = (
  metadata: IMetadata,
  formValues: IReviewTemplateFormValues,
  app: SignInWith
): IReviewTemplateRequestData => {

  const requestData: IReviewTemplateRequestData = {
    title: formValues.title,
    blocks: []
  };

  if (!metadata) {
    return requestData;
  }

  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  const turndownService = getTurndownServiceInstance();

  formValues.blocks.forEach(
    (block) => {
      switch (block.type) {

        case questionTypeConstants.TITLE:
        case questionTypeConstants.SUBTITLE: {

          requestData.blocks.push({
            id: block.id,
            type: block.type,
            text: turndownService.turndown(block.titleValue?.text || ''),
            typeTitle: block.typeTitle,
          });
          break;
        }

        case questionTypeConstants.SHORT_TEXT:
        case questionTypeConstants.LONG_TEXT: {

          requestData.blocks.push({
            id: block.id,
            type: block.type,
            typeTitle: block.typeTitle,
            ...block.textValue
          });
          break;
        }

        case questionTypeConstants.MULTI_SELECT_QUESTION:
        case questionTypeConstants.COMPETENCY_QUESTION: {

          const multiSelectTypeConstants =
            metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
          let choices: string[] = [];
          let choicesValues: IReviewTemplateRequestDataMultiSelectBlock['choicesValues'] = {};

          const controlKey = block.type === questionTypeConstants.MULTI_SELECT_QUESTION ? 'multiSelectValue' : 'competencyValue';

          if (block[controlKey]?.selectedOption.value === multiSelectTypeConstants.CUSTOM_ITEMS) {
            const choicesWithValue = getMultiSelectChoicesWithValues(
              app,
              block[controlKey]?.choices
            );

            choices = choicesWithValue?.choices || [];
            choicesValues = choicesWithValue?.choicesValues || {};

            if (!block[controlKey]?.numericValue) {
              let index = 0;
              for (const key of Object.keys(choicesValues)) {
                choicesValues[key] = index;
                index += 1;
              }
            }
          }

          const multiSelectBlock = {
            id: block.id,
            type: block.type,
            typeTitle: block.typeTitle,
            label: block[controlKey]?.label,
            family: block[controlKey]?.selectedOption.value,
            comment: block[controlKey]?.commentBox ? block[controlKey]?.commentBoxGuide : '',
            required: block[controlKey]?.required,
            dropdown: block[controlKey]?.dropdown,
            numericValue: block[controlKey]?.selectedOption.value === multiSelectTypeConstants.CUSTOM_ITEMS
              ? Boolean(block[controlKey]?.numericValue)
              : undefined,
            choices,
            choicesValues,
          };

          requestData.blocks.push(
            block.type === questionTypeConstants.MULTI_SELECT_QUESTION
              ?
              multiSelectBlock
              :
              {
                ...multiSelectBlock,
                showBehaviours: block.competencyValue?.showBehaviours,
                showDescription: block.competencyValue?.showDescription,
                isBehavior: block.competencyValue?.isBehavior,
                isNotApplicableOption: (block.competencyValue as IReviewTemplateCompetencyQuestionValue)?.isNotApplicableOption,
                selectedView: block?.competencyValue?.selectedView,
              }
          );
          break;
        }

        case questionTypeConstants.OBJECTIVE_QUESTION: {

          const multiSelectTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
          let choices: string[] = [];
          let choicesValues: IReviewTemplateRequestDataMultiSelectBlock['choicesValues'] = {};

          const controlKey = 'objectiveValue';

          if (block[controlKey]?.selectedOption.value === multiSelectTypeConstants.CUSTOM_ITEMS) {
            const choicesWithValue = getMultiSelectChoicesWithValues(
              app,
              block[controlKey]?.choices
            );

            choices = choicesWithValue?.choices || [];
            choicesValues = choicesWithValue?.choicesValues || {};

            if (!block[controlKey]?.numericValue) {
              let index = 0;
              for (const key of Object.keys(choicesValues)) {
                choicesValues[key] = index;
                index += 1;
              }
            }
          }

          const multiSelectBlock = {
            id: block.id,
            type: block.type,
            typeTitle: block.typeTitle,
            label: block[controlKey]?.label,
            family: block[controlKey]?.selectedOption.value,
            comments: block[controlKey]?.comments,
            comment: block[controlKey]?.commentBox ? block[controlKey]?.commentBoxGuide : '',
            commentBox: block[controlKey]?.commentBox,
            required: block[controlKey]?.required,
            dropdown: block[controlKey]?.dropdown,
            numericValue: block[controlKey]?.selectedOption.value === multiSelectTypeConstants.CUSTOM_ITEMS
              ? Boolean(block[controlKey]?.numericValue)
              : undefined,
            choices,
            choicesValues,
          };

          requestData.blocks.push({
            ...multiSelectBlock,
            showKPIs: block.objectiveValue?.showKPIs,
            showDescription: block.objectiveValue?.showDescription,
            isKPI: block.objectiveValue?.isKPI,
            isNotApplicableOption: (block.objectiveValue as IReviewTemplateObjectiveQuestionValue)?.isNotApplicableOption,
            selectedView: block?.objectiveValue?.selectedView,
          });

          break;
        }
      }
    }
  );

  return requestData;

}


export const getReviewTemplateQuestionToAdd = (
  metadata: IMetadata,
  option: IMenuOption,
  blocks: IReviewTemplateQuestion[],
  app: SignInWith
) => {

  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  switch (option.value) {

    case questionTypeConstants.TITLE:
    case questionTypeConstants.SUBTITLE: {

      return {
        tempId: (blocks.length + 1),
        type: option.value,
        typeTitle: option.label,
        titleValue: { text: '' }
      };

    }

    case questionTypeConstants.SHORT_TEXT:
    case questionTypeConstants.LONG_TEXT: {

      return {
        tempId: blocks.length + 1,
        type: option.value,
        typeTitle: option.label,
        textValue: {
          label: '',
          placeholder: 'Type your answer here',
          required: false,
        }
      };
    }

    case questionTypeConstants.MULTI_SELECT_QUESTION:
    case questionTypeConstants.COMPETENCY_QUESTION: {

      const multiSelectOptions = metadata.multiSelectQuestionOptionsTypes.options;
      const selectedView = metadata.selectedView.options;
      const controlKey = option.value === questionTypeConstants.MULTI_SELECT_QUESTION ? 'multiSelectValue' : 'competencyValue';

      const multiSelectBlock = {
        tempId: blocks.length + 1,
        type: option.value,
        typeTitle: option.label,
        [controlKey]: {
          label: '',
          choices: [
            {
              choice: '',
              value: 0
            }
          ],
          commentBoxGuide: '',
          commentBox: false,
          dropdown: false,
          required: false,
          selectedOption: multiSelectOptions[0],
        }
      };

      return option.value === questionTypeConstants.MULTI_SELECT_QUESTION
        ?
        multiSelectBlock
        :
        {
          ...multiSelectBlock,
          competencyValue: {
            ...(multiSelectBlock.competencyValue as IReviewTemplateCompetencyQuestionValue),
            showBehaviours: false,
            showDescription: false,
            isBehavior: false,
            isNotApplicableOption: false,
            selectedView: selectedView[0],
          }
        }
    }

    case questionTypeConstants.OBJECTIVE_QUESTION: {

      const multiSelectOptions = metadata.multiSelectQuestionOptionsTypes.options;
      const selectedView = metadata.selectedView.options;
      const controlKey = 'objectiveValue';

      const multiSelectBlock = {
        tempId: blocks.length + 1,
        type: option.value,
        typeTitle: option.label,
        [controlKey]: {
          label: '',
          choices: [
            {
              choice: '',
              value: 0
            }
          ],
          comments: [] as IReviewCommentsWithValue[],
          commentBoxGuide: '',
          commentBox: false,
          dropdown: false,
          required: false,
          selectedOption: multiSelectOptions[0],
        }
      };

      return {
        ...multiSelectBlock,
        objectiveValue: {
          ...(multiSelectBlock.objectiveValue as IReviewTemplateObjectiveQuestionValue),
          showBehaviours: false,
          showDescription: false,
          isBehavior: false,
          isNotApplicableOption: false,
          selectedView: selectedView[0],
        }
      }

    }
  }

};


export const getReviewFormValidationSchema =
  (metadata: IMetadata, app: SignInWith): Yup.SchemaOf<IReviewTemplateValidationSchema> => {

    const multiSelectQuestionValidationSchema = REVIEW_MULTI_SELECT_QUESTION_VALIDATION_SCHEMA;

    const updatedMultiSelectChoicesSchema: Yup.SchemaOf<IReviewTemplateQuestionValidationSchema['multiSelectValue']> =
      // app === SignInWith.Slack
      //   ?
      multiSelectQuestionValidationSchema.shape({
        choices: Yup.array().when(
          'selectedOption',
          {
            is: (selectedOption: IMenuOption) =>
              selectedOption.value ===
              (metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants).CUSTOM_ITEMS,
            then: Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.number().required(FormErrors.Required).min(0, FormErrors.MinValue0),
                  choice: Yup.string().required(FormErrors.Required),
                })
              )
              .required(FormErrors.Required)
              .min(1, FormErrors.Required),
            otherwise: Yup.array()
          }
        )
      }).notRequired().default(undefined)
    // :
    // multiSelectQuestionValidationSchema.shape({
    //   choices: Yup.string().when(
    //     'selectedOption',
    //     {
    //       is: (selectedOption: IMenuOption) =>
    //         selectedOption.value ===
    //         (metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants).CUSTOM_ITEMS,
    //       then: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
    //       otherwise: Yup.string()
    //     }
    //   )
    // }).notRequired().default(undefined);

    const competencyQuestionValidationSchema: Yup.SchemaOf<IReviewTemplateQuestionValidationSchema['competencyValue']> =
      updatedMultiSelectChoicesSchema.shape({
        showBehaviours: Yup.boolean().defined(),
        showDescription: Yup.boolean().defined(),
        isBehavior: Yup.boolean().defined(),
        isNotApplicableOption: Yup.boolean().defined(),
        selectedView: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA,
      })

    const objectiveQuestionValidationSchema: Yup.SchemaOf<IReviewTemplateQuestionValidationSchema['objectiveValue']> =
      updatedMultiSelectChoicesSchema.shape({
        commentBoxGuide: Yup.string().notRequired(),
        showKPIs: Yup.boolean().default(false),
        showDescription: Yup.boolean().default(false),
        comments: Yup.array(),
        isKPI: Yup.boolean().default(false),
        isNotApplicableOption: Yup.boolean().defined(),
        selectedView: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA,
      })

    const reviewTemplateFormValidationSchema: Yup.SchemaOf<IReviewTemplateValidationSchema> =
      Yup.object({
        title: REVIEW_TEMPLATE_NAME_SCHEMA,
        blocks: Yup.array().of(
          Yup.object().shape({
            titleValue: TITLE_QUESTION_VALIDATION_SCHEMA,
            textValue: TEXT_QUESTION_VALIDATION_SCHEMA,
            multiSelectValue: updatedMultiSelectChoicesSchema,
            competencyValue: competencyQuestionValidationSchema,
            objectiveValue: objectiveQuestionValidationSchema
          })
        )
      });

    return reviewTemplateFormValidationSchema;
  };


export const getMappedRevieweeAnswers = (
  answers: IAnswer[],
  metadata: IMetadata
) => {

  const multiSelectQuestionTypesConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const multiSelectQuestionTypes =
    metadata.multiSelectQuestionOptionsTypes.options;

  return answers.map(
    (answer) => {

      if (['choice', 'competency-question', 'objective-question'].includes(answer.type as string)) {

        if (answer.family === multiSelectQuestionTypesConstants.CUSTOM_ITEMS) {
          return {
            ...answer,
            selected_option:
              answer.dropdown
                ?
                answer.multiSelectValue
                  ?
                  {
                    text: answer.multiSelectValue.label,
                    value: answer.multiSelectValue.value
                  }
                  :
                  undefined
                :
                answer.value
                  ?
                  {
                    text: answer.value,
                    value: answer.value
                  }
                  :
                  undefined,
            value: answer.commentValue || undefined
          }
        } else {

          const builtInChoices = multiSelectQuestionTypes.find(
            (option) => option.value === answer.family
          );

          if (!builtInChoices) {
            return answer;
          }

          let selectedChoice: IMenuOption | undefined;
          const answerValue = answer.dropdown ? answer.multiSelectValue?.value : answer.value;
          if (answer?.selectedView?.value === 'dropdown') {
            answer.dropdown = true;
          }

          if (answer.isNotApplicableOption && answerValue === MULTI_SELECT_QUESTION_NA_OPTION.value) {
            selectedChoice = MULTI_SELECT_QUESTION_NA_OPTION;
          } else {
            selectedChoice = builtInChoices.options?.find(
              (choice) => {
                return answer.dropdown
                  ?
                  choice.value === answer.multiSelectValue?.value
                  :
                  choice.label === answer.value
              }
            );
          }

          if (!selectedChoice) {
            return {
              ...answer,
              value: answer.commentValue || undefined
            };
          }

          return {
            ...answer,
            selected_option: answer.dropdown
              ?
              answer.multiSelectValue
                ?
                {
                  value: selectedChoice.value,
                  text: selectedChoice.label,
                }
                :
                undefined
              :
              answer.value
                ?
                {
                  value: selectedChoice.value,
                  text: selectedChoice.label,
                }
                :
                undefined,
            value: answer.commentValue || undefined
          }
        }

      }

      return answer;

    }
  );

};

export function isQuestionSameAsAbove(answers: any, index: any) {
  if (!answers?.length) {
    return false;
  }
  const currentQuestion = answers[index]?.id
  const previousQuestion = answers[index - 1]?.id;

  return currentQuestion?.substr(0, currentQuestion?.indexOf('/')) === previousQuestion?.substr(0, previousQuestion?.indexOf('/'));
}


export const getReviewTodoPathName = (review: IReviewTodoRevieweeItem): string => {

  switch (review.type) {

    case ReviewTodoTypes.AwaitingNominations: {
      return `${Routes.ReviewToDosPage.path}/awaiting-nomination/${review.id}`;
    }

    case ReviewTodoTypes.ManagerApproval: {
      return `${Routes.ReviewToDosPage.path}/manager-approve-nomination/${review.campingId}/${review.revieweeId}`;
    }

    case ReviewTodoTypes.ReviewerApproval: {
      return `${Routes.ReviewToDosPage.path}/reviewer-approve-nomination/${review.campingId}`;
    }

    case ReviewTodoTypes.Default:
    default: {
      return `${Routes.ReviewToDosPage.path}/${review.id}`;
    }
  }

};


export const getReviewTodoRowTitle = (review: IReviewTodoRevieweeItem): string => {

  switch (review.type) {

    case ReviewTodoTypes.AwaitingNominations: {
      return 'Nominate Peer Reviewers';
    }

    case ReviewTodoTypes.ManagerApproval: {
      return `Manager Approval (${review.reviewee.name})`;
    }

    case ReviewTodoTypes.ReviewerApproval: {
      return 'Reviewer Approval';
    }

    case ReviewTodoTypes.Default:
    default: {
      return review.revieweeName;
    }
  }
}


export const getReminderRequestData = (
  formValues: IReminderFormValues
): IReminderRequestData => {

  return {
    note: formValues.note,
    sendAsEmail: formValues.sendAs.includes(SendReminderAsTypes.Email),
    sendAsAppMessage: formValues.sendAs.includes(SendReminderAsTypes.AppMessage),
  };
};


export const getAnalyticsRows = (rows: IReviewAnalyticsRow[], columns: IReviewAnalyticsColumn[]): IReviewAnalyticsComputedCell[] => {

  const finalRows: IReviewAnalyticsComputedCell[] = [];

  const undefinedRow = {
    sum: 0,
    count: 0,
    skipped: 0,
    value: 0 || null,
    color: '#1D1C1D',
    backgroundColor: '#E6B6C8'
  };

  rows.forEach(row => {
    const { cells, ...rest } = row || {};
    const finalRow: IReviewAnalyticsCell[] = [];
    columns.forEach(column => {
      const cell = cells?.[column?.id];
      if (cell?.value) {
        cell.value = +cell.value.toFixed(2);
      }
      finalRow.push(cell || undefinedRow);
    });
    finalRows.push({ ...rest, cells: finalRow });
  })

  return finalRows;
};

export const getAnalyticsRow = (row: IReviewAnalyticsViewCell) => {
  const span: { rowSpan?: number, colSpan?: number } = {};

  if (row.colSpan || row?.span) {
    span.colSpan = row.colSpan || row.span;
  }
  if (row.rowSpan) {
    span.rowSpan = row.rowSpan
  }

  let cellValue: any = '';
  let className = '';
  if (row.type === "empty") {
    cellValue = ''
    className = 'bg-white border-1';
  } else if (row.type === "col-header") {
    cellValue = row.text;
    className = 'bg-white fw-bold border-1 py-3 fs-6';
  } else if (row.type === "row-header") {
    cellValue = row.text;
    className = 'bg-white border-1';
  } else if (row.type === "label") {
    className = 'py-3 fs-6 fw-bold border-1';
    cellValue = row.text;
  } else if (row.type === "data") {
    cellValue = row.value || '';
    className = 'bg-white border-1 text-center';
  } else {
    cellValue = ''
    className = 'bg-white border-1';
  }

  return (
    <td
      {...span}
      className={className}
      style={{ whiteSpace: "break-spaces" }}
    >
      {cellValue}
    </td>
  )
}