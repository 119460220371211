import {
  FormErrors,
  ISurveyBuilderBlockValidationSchema,
  ISurveyBuilderFormValidationSchema,
  ISurveyGroupBlockValueValidationSchema,
} from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const STATIC_TEXT_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<ISurveyBuilderBlockValidationSchema['staticTextValue']> =
  Yup.object().shape(
    {
      value: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
    }
  ).notRequired().default(undefined);


export const INPUT_TEXT_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<ISurveyBuilderBlockValidationSchema['inputTextValue']> =
  Yup.object().shape(
    {
      label: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      placeholder: Yup.string(),
      required: Yup.boolean(),
    }
  ).notRequired().default(undefined);


export const SURVEY_MULTI_SELECT_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<ISurveyBuilderBlockValidationSchema['multiSelectValue']> =
  Yup.object().shape(
    {
      label: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      required: Yup.boolean(),
      commentBoxGuide: Yup.string().when(
        'commentBox',
        {
          is: true,
          then: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
          otherwise: Yup.string()
        }
      ),
      commentBox: Yup.boolean(),
      dropdown: Yup.boolean(),
      track: Yup.boolean(),
      multipleAnswers: Yup.boolean(),
      selectedOption: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA,
    }
  ).notRequired().default(undefined);


export const SURVEY_GROUP_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<ISurveyGroupBlockValueValidationSchema> =
  Yup.object().shape(
    {
      label: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      description: Yup.string(),
    }
  ).notRequired().default(undefined);


export const SURVEY_NAME_SCHEMA: Yup.SchemaOf<ISurveyBuilderFormValidationSchema['title']> =
  Yup.string()
    .required(FormErrors.Required)
    .defined(FormErrors.Required)
    .trim(FormErrors.Required);
