import { Cell } from "react-table";
import { ISimpleMenuOption } from "./menuOptions.interface";
import { IUserRole } from "./userRole.interface";

export interface IBehaviour {
  id: string;
  name: string;
}

export interface ICompetency {
  id: string;
  name: string;
  description: string;
  behaviours: IBehaviour[];
  roles: IUserRole[];
  dimensionId: string;
  tag: string;
  value: number;
  avg: string;
}

export interface ICompetencyReducerAction {
  type: 'SET_COMPETENCIES' | 'CREATE_COMPETENCY' | 'DELETE_COMPETENCY' | 'UPDATE_COMPETENCY';
  payload: ICompetency | ICompetency[];
  index?: number;
}

export interface ICompetencyFormValues {
  name: string;
  description?: string;
  behaviours: IBehaviour[];
  roles: ISimpleMenuOption[];
}

export interface ICompetencyRequestData extends
  Omit<ICompetencyFormValues, 'roles' | 'behaviours'> {
  roles: string[];
  behaviours: IBehaviour[] | string[];
}

export interface ICompetencyActionColumnProps {
  deletingCompetency: ICompetency | null;
  onEditClick: (Competency: ICompetency) => void;
  onDeleteClick: (Competency: ICompetency) => void;
}

export enum BehaviourTypes {
  Strength = 'strength',
  DevelopmentArea = 'developmentArea',
}