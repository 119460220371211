import { useUserProfile } from 'components/ProvideUserProfile';
import { UserPermissions } from 'interfaces';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Routes } from 'routes';

interface IRouteWithPermissionProps {
  path: string;
  exact?: boolean;
  permission: UserPermissions;
}

const RouteWithPermission: React.FC<IRouteWithPermissionProps> = (
  { children, permission, ...rest }
) => {

  const { userProfile } = useUserProfile();

  return (
    <Route
      {...rest}
      render={
        () => {

          return (
            <>
              {
                userProfile?.permissions?.includes(permission)
                  ?
                  <>
                    {children}
                  </>
                  :
                  <Redirect to={Routes.HomePage.path} />
              }
            </>
          )

        }
      }
    />
  );
};

export default RouteWithPermission;