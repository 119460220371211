import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@themesberg/react-bootstrap';
import { CustomDropdown, CustomModal } from 'components';
import { MetaDataContext } from 'contexts';
import { sendNominationReminder, sendRemindersToOneReviewer } from 'data';
import { useApp } from 'hooks';
import {
  IActiveReview,
  IActiveReviewAudience,
  IActiveReviewRowStatusTypeConstants,
  IMenuOption,
  IMetadata,
  IReminderFormValues,
  SignInWith,
} from 'interfaces';
import ShareReportModal from 'modules/reports/modals/ShareReportModal';
import React from 'react';
import { toast } from 'react-toastify';
import { ACTIVE_REVIEW_AUDIENCE_ACTIONS } from 'teamble-constants';

import ReminderNoteModal from '../modals/ReminderNoteModal';
import { getReminderRequestData } from '../utils/utils';

interface IActiveReviewAudienceActionsProps {
  audience: IActiveReviewAudience;
  isReviewClosed: boolean;
  activeReview?: IActiveReview;
  onShowResult: () => void;
  onRemoveAudiencePair: (audience: IActiveReviewAudience) => void;
  onAudiencePairReopen: (audience: IActiveReviewAudience) => void;
  onReviewerChange: (audience: IActiveReviewAudience) => void;
  downloadReport: (perfReviewId: string) => void;
}

const ActiveReviewAudienceActions: React.FunctionComponent<IActiveReviewAudienceActionsProps> = ({
  audience,
  activeReview,
  onShowResult,
  isReviewClosed,
  downloadReport,
  onReviewerChange,
  onAudiencePairReopen,
  onRemoveAudiencePair,
}) => {
  const [actionOptions, setActionOptions] = React.useState<IMenuOption[] | null>(null);
  const [showReminderNoteModal, setShowReminderNoteModal] = React.useState<boolean>(false);
  const [showReasonBox, setShowReasonBox] = React.useState<boolean>(false);
  const [showShareReportModal, setShowShareReportModal] = React.useState<boolean>(false);

  const app = useApp();

  const metaData = React.useContext(MetaDataContext) as IMetadata;
  const activeReviewRowStatusTypeConstants =
    metaData.activeReviewRowStatusTypes.constants as IActiveReviewRowStatusTypeConstants;


  React.useEffect(
    () => {

      if (audience.state === activeReviewRowStatusTypeConstants.PENDING) {
        audience.state = 'review-in-progress';
      }

      switch (audience.state) {

        case activeReviewRowStatusTypeConstants.COMPLETED: {

          const audActions = [
            ACTIVE_REVIEW_AUDIENCE_ACTIONS.view,
          ];
          if (!isReviewClosed) {
            audActions.push(
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.reopen,
            )
          }

          audActions.push(
            ACTIVE_REVIEW_AUDIENCE_ACTIONS.download,
          )

          if (app === SignInWith.Slack) {
            setActionOptions([
              ...audActions,
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.share,
            ]);
          } else {
            setActionOptions(audActions);
          }
          break;
        }

        case activeReviewRowStatusTypeConstants.NOMINATED:
        case activeReviewRowStatusTypeConstants.REVIEW_PROGRESS:
        case activeReviewRowStatusTypeConstants.AWAITING_NOMINATION:
        case activeReviewRowStatusTypeConstants.NOMINATION_MANAGER_APPROVED:
        case activeReviewRowStatusTypeConstants.PENDING: {
          if (!isReviewClosed) {
            setActionOptions([
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.remove,
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.sendReminder,
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.changeReviewer
            ]);
          }
          break;
        }
        case activeReviewRowStatusTypeConstants.STARTED: {
          if (!isReviewClosed) {
            setActionOptions([
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.view,
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.remove,
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.sendReminder,
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.changeReviewer
            ]);
          }
          break;
        }
        case activeReviewRowStatusTypeConstants.REVIEWER_DECLINED: {
          if (!isReviewClosed) {
            setActionOptions([
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.reopen,
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.viewComment
            ])
          }
          break
        }
        case activeReviewRowStatusTypeConstants.NOMINATION_REVIEWER_DECLINED:
        case activeReviewRowStatusTypeConstants.NOMINATION_MANAGER_DECLINED: {
          if (!isReviewClosed) {
            setActionOptions([
              ACTIVE_REVIEW_AUDIENCE_ACTIONS.viewComment,
            ]);
          }
          break;
        }

      }
    },
    [activeReviewRowStatusTypeConstants, app, audience, isReviewClosed, metaData.activeReviewRowStatusTypes.constants]
  );


  const sendRemindersToReviewer = (
    reminderNoteFormValues: IReminderFormValues
  ): void => {

    setShowReminderNoteModal(false);
    const requestData = getReminderRequestData(reminderNoteFormValues);

    let reminder$ = !audience?.fromNominations
      ?
      sendRemindersToOneReviewer(requestData, audience.id)
      :
      sendNominationReminder(
        requestData,
        audience?.id,
        audience?.state as string
      );

    reminder$.subscribe(
      (response) => {
        toast.success(response.data.message);
      }
    );
  };


  const handleActiveReviewActionChange = (selectedOption: IMenuOption): void => {

    switch (selectedOption.value) {
      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.view.value: {
        onShowResult();
        break;
      }

      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.sendReminder.value: {
        setShowReminderNoteModal(true);
        break;
      }

      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.remove.value: {
        onRemoveAudiencePair(audience);
        break;
      }

      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.viewComment.value: {
        setShowReasonBox(true);
        break;
      }

      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.share.value: {
        setShowShareReportModal(true);
        break;
      }

      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.reopen.value: {
        onAudiencePairReopen(audience);
        break;
      }

      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.download.value: {
        downloadReport(audience.id);
        break;
      }

      case ACTIVE_REVIEW_AUDIENCE_ACTIONS.changeReviewer.value: {
        onReviewerChange(audience);
        break;
      }
    }
  };


  return (
    <>
      {
        actionOptions &&
        <CustomDropdown
          id={`activeReviewAction${audience.id}`}
          icon={faEllipsisV}
          variant='link'
          size='sm'
          options={actionOptions}
          onChange={handleActiveReviewActionChange}
        />
      }
      {
        showReminderNoteModal &&
        <ReminderNoteModal
          show={showReminderNoteModal}
          audienceLen={1}
          onSubmit={sendRemindersToReviewer}
          onClose={() => setShowReminderNoteModal(false)} />
      }

      {
        showShareReportModal &&
        <ShareReportModal
          show={showShareReportModal}
          workspaceId={activeReview?.workspaceId}
          reportTableRow={{ ...audience, perfReviewId: audience?.id } as any}
          onClose={() => setShowShareReportModal(false)} />
      }

      <CustomModal
        title={'Reason'}
        show={showReasonBox}
        onClose={() => setShowReasonBox(false)}
      >
        <Card.Body style={{ minHeight: 150 }}>
          {
            audience.data?.comment
              ?
              <>
                <strong>
                  Comment by {
                    audience.state === activeReviewRowStatusTypeConstants.REVIEWER_DECLINED
                      ?
                      audience.reviewer.name
                      :
                      audience.data?.author
                  }
                </strong>
                <p>
                  {audience.data.comment}
                </p>
              </>
              :
              <em>Reason not given</em>
          }
        </Card.Body>
      </CustomModal>
    </>
  );

};

export default ActiveReviewAudienceActions