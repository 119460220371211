import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormContext } from 'contexts';
import { FormikProps } from 'formik';
import { useIsChannelInAudience } from 'hooks';
import {
  FormFieldTypeConstants,
  IRouteParams,
  ISurvey,
  ISurveySchedulerFormValues
} from 'interfaces';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  NOTIFICATION_SETTINGS,
  SCHEDULER_FREQUENCY_OPTIONS, SURVEY_DURATION_SPAN_OPTIONS,
  SURVEY_DURATION_TYPES,
  SURVEY_DURATION_UNIT_OPTIONS
} from 'teamble-constants';

import { useSurveyDuration } from '../hooks/useSurveyDuration';
import SurveyNotificationSettingWidget from './SurveyNotificationSettingWidget';
import SurveySchedulerWidget from './SurveySchedulerWidget';


interface ISurveySchedulerFormProps {
  isLoading: boolean;
  surveyDetails: ISurvey | null;
  surveySchedulerForm: FormikProps<ISurveySchedulerFormValues>;
  onSaveClick: () => void;
  onLaunchSurvey: () => void;
  onDurationUpdate: () => void;
  onSchedulerClear: () => void;
  onSchedulerUpdate: () => void;
  onNotificationUpdate: () => void;
}

interface ISurveyLaunchWidgetProps extends ISurveySchedulerFormProps {
}


const SurveySchedulerForm: React.FunctionComponent<ISurveySchedulerFormProps> = (
  {
    isLoading, surveyDetails, surveySchedulerForm,
    onDurationUpdate, onSchedulerUpdate, onSchedulerClear, onNotificationUpdate
  }
) => {

  const surveyDuration = useSurveyDuration(surveyDetails);
  const isChannelInSurveyAudience = useIsChannelInAudience(surveyDetails?.audience);

  const getFormValuesWithDurationValues =
    (survey: ISurvey, initialValues: ISurveySchedulerFormValues): ISurveySchedulerFormValues => {

      let formValues = { ...initialValues };

      if (survey.closeAfterAllSubmissions) {

        formValues.durationType = SURVEY_DURATION_TYPES[2];

      } else if (survey.closeSpecificTimeAt) {

        formValues.durationType = SURVEY_DURATION_TYPES[2];
        formValues.closeSpecificTimeAt = survey.closeSpecificTimeAt ? new Date(survey.closeSpecificTimeAt) : null;

      } else if (survey.closeSchedule) {

        const durationSpan = SURVEY_DURATION_SPAN_OPTIONS.find(
          (option) => option.value === survey.closeSchedule.span.toString()
        ) || null;

        const durationUnit = SURVEY_DURATION_UNIT_OPTIONS.find(
          (option) => option.value === survey.closeSchedule.unit
        ) || null;

        formValues = {
          ...formValues,
          durationType: SURVEY_DURATION_TYPES[1],
          durationSpan,
          durationUnit
        };

      } else {
        formValues.durationType = SURVEY_DURATION_TYPES[0];
      }

      return formValues;
    };


  const getFormValuesWithSchedulerValues =
    (survey: ISurvey, initialValues: ISurveySchedulerFormValues): ISurveySchedulerFormValues => {

      let formValues = { ...initialValues };

      const schedulerFrequency = SCHEDULER_FREQUENCY_OPTIONS.options.find(
        (option) => option.value === survey.schedule.frequency
      ) || null;

      formValues = {
        ...formValues,
        schedulerFrequency,
        schedulerDateTime: survey.schedule.timestamp ? new Date(survey.schedule.timestamp) : null
      };

      return formValues;
    };


  const getFormValuesWithNotificationValues =
    (survey: ISurvey, initialValues: ISurveySchedulerFormValues): ISurveySchedulerFormValues => {

      let formValues = { ...initialValues };

      const notificationSetting = NOTIFICATION_SETTINGS.options.find(
        (option) => option.value === survey.notifications
      );

      if (!notificationSetting) {
        return formValues;
      }

      formValues = {
        ...formValues,
        notificationSetting,
      };

      return formValues;
    };


  // set the close duration
  React.useEffect(
    () => {

      if (!surveyDetails) {
        return;
      }

      const durationValues = getFormValuesWithDurationValues(surveyDetails, surveySchedulerForm.values);

      if (!surveyDetails.schedule) {
        surveySchedulerForm.resetForm({ values: durationValues });
        return;
      }

      const schedulerValues = getFormValuesWithSchedulerValues(surveyDetails, durationValues);
      const notificationValues = getFormValuesWithNotificationValues(surveyDetails, schedulerValues);

      surveySchedulerForm.resetForm({ values: notificationValues });

    },
    [surveyDetails]
  );


  return (
    <Form>
      <FormContext.Provider value={surveySchedulerForm}>

        <Card>
          <Card.Header className='bg-light py-2 px-3'>
            <Form.Label className='fw-bolder mb-0'> Survey Duration </Form.Label>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <FormField
                  label='Close Survey'
                  controlId='surveySchedulerFormDurationType'
                  type={FormFieldTypeConstants.Select}
                  options={SURVEY_DURATION_TYPES}
                  controlName='durationType' />
              </Col>
            </Row>
            {
              surveySchedulerForm.values.durationType?.value === 'automatic' &&
              <Row>
                <Col>
                  <FormField
                    label='Duration Span'
                    controlId='surveySchedulerFormDurationSpan'
                    type={FormFieldTypeConstants.Select}
                    options={SURVEY_DURATION_SPAN_OPTIONS}
                    controlName='durationSpan' />
                </Col>
                <Col>
                  <FormField
                    label='Duration Unit'
                    controlId='surveySchedulerFormDurationUNIT'
                    type={FormFieldTypeConstants.Select}
                    options={SURVEY_DURATION_UNIT_OPTIONS}
                    controlName='durationUnit' />
                </Col>
              </Row>
            }
            {/* {
              surveySchedulerForm.values.durationType?.value === 'onSpecificTime' &&
              <Row>
                <Col xs={6}>
                  <FormField
                    label='Close at'
                    controlId='surveySchedulerFormCloseDateTime'
                    type={FormFieldTypeConstants.Datepicker}
                    controlName='closeSpecificTimeAt' />
                </Col>
              </Row>
            } */}
            {
              surveyDuration &&
              <>
                <span> <strong> Survey Duration: </strong> </span>
                <span> {surveyDuration} </span>
              </>
            }
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
            <Button
              type='button'
              size='sm'
              disabled={isLoading}
              onClick={onDurationUpdate}> Save </Button>
          </Card.Footer>
        </Card>

        <SurveySchedulerWidget
          isLoading={isLoading}
          surveySchedulerForm={surveySchedulerForm}
          onSchedulerClear={onSchedulerClear}
          onSchedulerUpdate={onSchedulerUpdate} />

        {
          isChannelInSurveyAudience &&
          <SurveyNotificationSettingWidget
            isLoading={isLoading}
            onNotificationUpdate={onNotificationUpdate} />
        }

      </FormContext.Provider>
    </Form>
  );
};


const SurveyLaunchWidget: React.FunctionComponent<ISurveyLaunchWidgetProps> = (props) => {

  const [surveyErrorMessage, setSurveyErrorMessage] = React.useState<string | null>(null);

  const { surveyId } = useParams<IRouteParams>();


  React.useEffect(
    () => {

      if (!props.surveyDetails) {
        setSurveyErrorMessage(null);
        return;
      }

      if (!props.surveyDetails.blocks.length) {
        setSurveyErrorMessage('Please add at least one question to the survey.');
        return;
      }

      if (!props.surveyDetails.audience.length) {
        setSurveyErrorMessage('Please set the survey audience.');
        return;
      }

      if (!props.surveyDetails.inspectors.length) {
        setSurveyErrorMessage('Please set the survey result viewers.');
        return;
      }

    },
    [props.surveyDetails]
  );


  return (
    <Card className='h-100'>

      <Card.Header>
        <Card.Title className='m-0'>Launch</Card.Title>
      </Card.Header>

      <Card.Body className='overflow-y-auto'>
        <SurveySchedulerForm {...props} />
      </Card.Body>
      <Card.Footer className='d-flex justify-content-between align-items-center'>
        <Form.Control.Feedback type="invalid" className={surveyErrorMessage ? 'd-block m-0' : ''}>
          {surveyErrorMessage}
        </Form.Control.Feedback>
        <div className='d-flex flex-shrink-0 ms-auto'>
          {
            !!surveyId &&
            <Link to={`/surveys/survey-library/${surveyId}/survey-audience`}>
              <Button
                className='ms-3'
                variant="outline-success"
                type="button"
              >
                Previous
              </Button>
            </Link>
          }
          <Button
            type='button'
            className='ms-3'
            disabled={props.isLoading}
            onClick={props.onSaveClick}
          >
            Save
          </Button>
          <Button
            className='ms-3'
            type='button'
            disabled={!!(props.isLoading || surveyErrorMessage)}
            onClick={props.onLaunchSurvey}
          >
            Launch Now
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default SurveyLaunchWidget;
