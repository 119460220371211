import { FormErrors, INotificationPreferenceConfigFormValues } from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const NOTIFICATION_PREFERENCE_CONFIG_VALIDATION_SCHEMA: Yup.SchemaOf<INotificationPreferenceConfigFormValues> =
  Yup.object().shape(
    {
      notificationPreference: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
        .required(FormErrors.Required)
        .nullable()
    }
  );