import { Accordion } from '@themesberg/react-bootstrap';
import dragula from 'dragula';
import { IFormQuestionValue, IQuestionBuilderFormType } from 'interfaces';
import * as React from 'react';

import { useQuestionBuilder } from './QuestionBuilder';
import QuestionWidget from './QuestionWidget';

interface IQuestionsAccordionProps {
}

const QuestionsAccordion: React.FunctionComponent<IQuestionsAccordionProps> = () => {

  const {
    previewQuestionKey, builderForm,
    onPreviewQuestionKeyChange
  } = useQuestionBuilder<IQuestionBuilderFormType>();

  const dragulaContainerRef = React.useRef<HTMLDivElement | null>(null);


  React.useEffect(
    () => {

      let drake: dragula.Drake;
      const updateDraggedBlockIndex = (element: HTMLDivElement): void => {
        let dataIndex = element.getAttribute('data-index');
        if (dataIndex !== null) {

          const sourceIndex = +dataIndex;
          const targetIndex = Array.prototype.indexOf.call(dragulaContainerRef.current?.children, element);

          if (sourceIndex === targetIndex) {
            return;
          }

          const questionsCopy: IFormQuestionValue[] = [...builderForm.values.questions];
          const questionToMove = questionsCopy.splice(+sourceIndex, 1)[0];
          questionsCopy.splice(targetIndex, 0, questionToMove);
          builderForm.setFieldValue('questions', questionsCopy);

          if (previewQuestionKey !== targetIndex) {
            onPreviewQuestionKeyChange(targetIndex);
          }
        }
      };

      if (dragulaContainerRef.current) {
        drake = dragula([dragulaContainerRef.current]);
        drake.on('dragend', (element) => {
          updateDraggedBlockIndex(element as HTMLDivElement);
        });
      }

      return () => {
        if (drake) {
          drake.destroy();
        }
      };
    },
    [builderForm, previewQuestionKey]
  );


  return (
    <Accordion activeKey={previewQuestionKey?.toString()}>
      <div ref={dragulaContainerRef}>
        {
          builderForm.values.questions.map(
            (question, index) => {
              return (
                <div
                  data-index={index}
                  key={question.tempId}
                >
                  <QuestionWidget
                    index={index}
                    question={question}
                  />
                </div>
              );
            }
          )
        }
      </div>
    </Accordion>
  );
};

export default QuestionsAccordion;
