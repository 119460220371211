import * as React from 'react';
import { Row, TableInstance } from 'react-table';


interface ITableBodyProps {
  rows: TableInstance<any>['rows'];
  bodyCellProps?: any;
  prepareRow: TableInstance<any>['prepareRow'];
  getTableBodyProps: TableInstance<any>['getTableBodyProps'];
  onRowClick?: (row: Row<any>) => void;
}


export const TableBody: React.FunctionComponent<ITableBodyProps> = (
  { rows, bodyCellProps, onRowClick, prepareRow, getTableBodyProps, }
) => {

  return (
    <tbody {...getTableBodyProps()}>
      {
        rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <tr
                className={`position-relative ${onRowClick ? 'cursor-pointer' : ''}`}
                {...row.getRowProps()}
                onClick={() => onRowClick?.(row)}
              >
                <td>{i + 1}</td>
                {
                  row.cells.map(
                    (cell) => {

                      return (
                        <td
                          style={{ width: cell.column.cellWidth }}
                          className={`align-middle py-2 ${cell.column.cellClassName || ''}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell', bodyCellProps)}
                        </td>
                      )
                    }
                  )
                }
              </tr>
            );
          }
        )
      }
    </tbody>
  );
};
