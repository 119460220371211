import { ContextModalPosition } from "modules/1-on-1s/types";
import React, { Dispatch, useRef, useState } from "react";
import "./ContextSelectionModal.scss";
import CustomTextArea from "modules/1-on-1s/components/CustomTextArea/CustomTextArea";
import AiTagButton from "modules/1-on-1s/components/AiTagButton/AiTagButton";
import TertiaryButton from "modules/1-on-1s/components/TertiaryButton/TertiaryButton";
import GradientPrimaryButton from "modules/1-on-1s/components/GradiantPrimaryButton/GradientPrimaryButton";
import useOutsideClick from "hooks/useOutsideClick";
import { boolean } from "yup";

interface ContextSelectionModalProps {
  modalPosition: ContextModalPosition;
  closeModal: () => void;
  onSubmit: (value: string) => void;
  setIsModifyingText: Dispatch<boolean>;
  isModifyingText: boolean;
}

const ContextSelectionModal: React.FC<ContextSelectionModalProps> = ({
  modalPosition,
  closeModal,
  onSubmit,
  isModifyingText,
}) => {
  const [value, setValue] = useState("");
  const modalRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(() => {
    modalPosition.visible && closeModal();
    setValue("");
  }, modalRef);
  return (
    <div
      ref={modalRef}
      className="ContextSelectionModal"
      style={{
        position: "fixed",
        top: modalPosition.top,
        left: modalPosition.left,
        display: !modalPosition.visible ? "none" : "block",
      }}
    >
      <div className="text-area-container">
        <div className="input-container">
          <CustomTextArea
            value={value}
            setValue={setValue}
            placeholder="How would you like to change this?"
          />
        </div>
        <div className="button-container">
          <AiTagButton
            name={"Expand on this"}
            onClick={(name) => {
              setValue(name);
            }}
          />
          <AiTagButton
            name={"Make more assertive"}
            onClick={(name) => {
              setValue(name);
            }}
          />
        </div>
      </div>
      <div className="submit-container">
        <TertiaryButton
          text={"Cancel"}
          onClick={() => {
            closeModal();
            setValue("");
          }}
        />
        <GradientPrimaryButton
          text={"Generate"}
          isLoading={isModifyingText}
          onClick={() => {
            onSubmit?.(value);
          }}
        />
      </div>
    </div>
  );
};

export default ContextSelectionModal;
