import { ISurvey } from "interfaces";
import React from "react";

export const useSurveyInspectors = (surveyDetails: ISurvey | null): string => {

  const [inspectors, setInspectors] = React.useState<string>('');

  React.useEffect(() => {

    if (!(surveyDetails?.inspectors?.length)) {
      setInspectors('Not Set');
      return;
    }

    const audienceAsInspectors = surveyDetails.inspectors.find(
      (inspectors) => inspectors.type === 'audience'
    );
    if (audienceAsInspectors) {
      setInspectors('Audience');
      return;
    }

    const usersAsInspectors= surveyDetails.inspectors.find(
      (inspectors) => inspectors.type === 'users'
    );
    if(usersAsInspectors) {
      setInspectors(`Shared only with selected users`);
      return;
    }

  }, [surveyDetails]);

  return inspectors;
}