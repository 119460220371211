import { Card } from '@themesberg/react-bootstrap';
import { ChartDataset } from 'chart.js';
import { EmptyState, Preloader } from 'components';
import { getTeammateCompetencyPerformanceScore } from 'data';
import { ICompetencyPerformanceScore, IUserDetailsContext } from 'interfaces';
import React from 'react';
import { finalize } from 'rxjs';

import { useUserDetails } from '../hooks/useUserDetails';
import { getCompetencyLegends, getCompetencyPerformanceScoreChartDatasets } from '../utils/utils';
import UserCompetencyPerformanceChart from './UserCompetencyPerformanceChart';

interface IUserCompetencyPerformanceWidgetProps { }

const UserCompetencyPerformanceWidget: React.FC<IUserCompetencyPerformanceWidgetProps> = () => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [competencyChartDatasets, setCompetencyChartDatasets] = React.useState<ChartDataset<'line'>[]>([]);
  const [competencyLegends, setCompetencyLegends] = React.useState<ICompetencyPerformanceScore['competencies']>([]);

  const { userDetails } = useUserDetails() as IUserDetailsContext;


  React.useEffect(
    () => {

      setCompetencyLegends([]);
      setCompetencyChartDatasets([]);

      if (!userDetails.teambleUserId) {
        return;
      }

      setIsLoading(true);

      const subscription =
        getTeammateCompetencyPerformanceScore(userDetails.teambleUserId)
          .pipe(
            finalize(() => setIsLoading(false))
          )
          .subscribe(
            (response) => {
              const compLegends = getCompetencyLegends(response.data.data);
              const competencyChartDatasets =
                getCompetencyPerformanceScoreChartDatasets(response.data.data);

              setCompetencyLegends(compLegends);
              setCompetencyChartDatasets(competencyChartDatasets);
            }
          );

      return () => subscription.unsubscribe();
    },
    [userDetails.teambleUserId]
  );


  return (
    <Card>

      <Card.Header className='d-flex justify-content-between align-items-start'>

        <div className='flex-shrink-0'>
          <Card.Subtitle className='fw-normal fs-5'>
            Scores by competencies
          </Card.Subtitle>
          <Card.Title className='m-0'>
            Performance Scores
          </Card.Title>
        </div>

        <div className='flex-grow-1 ms-4 d-flex flex-wrap justify-content-end'>
          {
            competencyLegends.map(
              (competency) => (
                <div key={competency.name} className='me-3 d-flex align-items-center flex-shrink-0'>
                  <span className='p-2 rounded-circle' style={{ backgroundColor: competency.color }} ></span>
                  <span className='ms-1'> {competency.name} </span>
                </div>
              )
            )
          }
        </div>

      </Card.Header>

      <Card.Body
        className='position-relative'
        style={{
          minHeight: !isLoading && competencyLegends.length ? 300 : '',
          height: isLoading || !competencyLegends.length ? 100 : ''
        }}
      >
        {
          isLoading
            ?
            <Preloader show={isLoading} />
            :
            <>
              {
                competencyLegends.length
                  ?
                  <UserCompetencyPerformanceChart competencyChartDatasets={competencyChartDatasets} />
                  :
                  <EmptyState message={<> No Performance Scores available! </>} />
              }
            </>
        }
      </Card.Body>
    </Card>
  );
};

export default UserCompetencyPerformanceWidget;