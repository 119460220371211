import { Nav, Tab } from '@themesberg/react-bootstrap';
import {
  CustomSelect,
  EmptyState,
  PageTitle,
  Preloader,
  PrimaryLayoutBox,
  RouteWithPermission,
} from 'components';
import { getMsTeamsChannelsAsMenuOptions, getSlackWorkspacesAsMenuOptions } from 'data';
import { useApp, useOptionsAndSelectedOption } from 'hooks';
import { IChannelMenuOption, SignInWith } from 'interfaces';
import * as React from 'react';
import { NavLink, Redirect, Switch } from 'react-router-dom';
import { Routes } from 'routes';

import SurveyInsightsDepartment from '../SurveyInsightsDepartment';


interface ISurveyInsightsProps { }

const SurveyInsights: React.FunctionComponent<ISurveyInsightsProps> = () => {

  const app = useApp();

  const {
    isLoading,
    options: workspaceOrChannelOptions,
    selectedOption: selectedWorkspaceOrChannelOption,
    onSelectedOptionUpdate: setSelectedWorkspaceOrChannelOption,
  } = useOptionsAndSelectedOption<IChannelMenuOption>(
    app === SignInWith.Slack ? getSlackWorkspacesAsMenuOptions() : getMsTeamsChannelsAsMenuOptions()
  );

  // const { search } = useLocation();


  return (
    <>
      <PageTitle title={Routes.SurveyInsights.title} />
      <PrimaryLayoutBox
        title={Routes.SurveyInsights.title}
        actions={
          <CustomSelect
            className='w-50'
            options={workspaceOrChannelOptions}
            value={selectedWorkspaceOrChannelOption as IChannelMenuOption}
            onChange={(option) => setSelectedWorkspaceOrChannelOption(option as IChannelMenuOption)}
          />
        }
      >

        {
          isLoading ?
            (<Preloader show={isLoading} />) :
            (
              <Tab.Container>
                <div className='d-flex flex-column overflow-hidden flex-grow-1'>
                  <Nav className="nav-tabs flex-shrink-0">
                    <Nav.Item>
                      <Nav.Link
                        as={NavLink}
                        // to={`${Routes.SurveyInsightsDepartment.path}${search}`}
                        to={Routes.SurveyInsightsDepartment.path}
                        className="mb-sm-3 mb-md-0"
                      >
                        Department
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {
                    selectedWorkspaceOrChannelOption ?
                      (
                        <Switch>

                          <RouteWithPermission {...Routes.SurveyInsightsDepartment}>
                            <div className='flex-grow-1 overflow-hidden mt-3'>
                              <SurveyInsightsDepartment
                                workspaceOrChannelId={selectedWorkspaceOrChannelOption.value} />
                            </div>
                          </RouteWithPermission>

                          <Redirect from={`${Routes.SurveyInsights.path}`} to={`${Routes.SurveyInsightsDepartment.path}`} />
                        </Switch>
                      ) :
                      (
                        <EmptyState message={
                          <>
                            {
                              app === SignInWith.Slack
                                ? "Please select a Slack Workspace to view the Insights."
                                : "Please select a Group to view the Insights."
                            }
                          </>
                        } />
                      )
                  }

                </div>
              </Tab.Container>
            )
        }

      </PrimaryLayoutBox>
    </>
  );
};

export default SurveyInsights;
