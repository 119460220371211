import { IFeedback } from 'interfaces';
import React from 'react';
import { getFeedbackCC, getFeedbackSenderText } from 'utils';

interface IFeedbackSenderProps {
  feedback: IFeedback;
}

const FeedbackSender: React.FC<IFeedbackSenderProps> = (
  { feedback }
) => {

  const feedbackSender = getFeedbackSenderText(feedback);
  const feedbackCC = getFeedbackCC(feedback);


  return (
    <>
      <p>
        <strong>From: </strong>
        <span> {feedbackSender} </span>
      </p>
      {
        feedbackCC
          ?
          <p>
            <strong>CC: </strong>
            {feedbackCC}
          </p>
          :
          null
      }

    </>
  );
};

export default FeedbackSender;