import { ACTION_COLUMN_CONFIG } from '@common/modules/table';
import { useApp } from 'hooks';
import {
  BodyCellProps,
  IReviewOrSurvey,
  IReviewOrSurveyActionColumnProps,
  IReviewOrSurveyType,
  ISurvey,
  SignInWith,
} from 'interfaces';
import moment from 'moment';
import React from 'react';
import { Column } from 'react-table';
import { DATE_TIME_FORMAT } from 'teamble-constants';
import { getDateSortingFunc, getTodayTomorrowNextDateTime } from 'utils';

import ReviewOrSurveyRowActions from '../components/ReviewOrSurveyRowActions';

export const useReviewOrSurveyLibraryTableColumns = (
  context: IReviewOrSurvey
): readonly Column<IReviewOrSurveyType>[] => {

  const dateSortingFunc = React.useMemo(
    () => getDateSortingFunc(),
    []
  );

  // App hook
  const app = useApp();


  return React.useMemo<readonly Column<IReviewOrSurveyType>[]>(
    () => {

      const columns = [
        {
          Header: 'Name',
          accessor: 'title',
        },
        {
          id: 'createdAt',
          Header: 'Created At',
          accessor: (row: IReviewOrSurveyType) => moment(row.createdAt).format(DATE_TIME_FORMAT),
          sortType: dateSortingFunc,
        },
        {
          ...ACTION_COLUMN_CONFIG,
          Cell: (
            cellProps: BodyCellProps<IReviewOrSurveyType, string, IReviewOrSurveyActionColumnProps>
          ) => {

            return (
              <ReviewOrSurveyRowActions
                context={context}
                reviewOrSurveyData={cellProps.row.original}
                onDuplicate={(data) => cellProps.duplicateRow(data, cellProps.row.index)}
                onDelete={() => cellProps.deleteRow(cellProps.row.index)} />
            );

          }
        }
      ];

      if (context === 'surveys') {

        columns.splice(
          1, 0,
          {
            Header: app === SignInWith.Slack ? 'Workspace' : 'Group',
            cellClassName: 'table-cell-text',
            accessor: (row: ISurvey) => {
              if (app === SignInWith.Slack && row?.workspace?.name) {
                return row.workspace.name;
              } else if (app === SignInWith.MsTeams && row?.channel?.name) {
                return row.channel.name;
              } else {
                return 'Not Available';
              }
            },
          } as any
        );

        columns.splice(
          2, 0,
          {
            Header: 'Scheduled At',
            accessor: (row: ISurvey) => {

              return row.scheduledAt
                ?
                getTodayTomorrowNextDateTime(moment(row.scheduledAt))
                :
                'Not Available';
            },
          } as any
        );

      }

      return columns as readonly Column<IReviewOrSurveyType>[];
    },
    [app, context, dateSortingFunc]
  );
};