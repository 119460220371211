import { ISimpleMenuOption } from 'interfaces';
export interface IIntegration {
  id: string;
  type: string;
  apiKey: string;
  domain: string;
}


export interface ICreateIntegrationFormValues extends Omit<IIntegration, 'id' | 'type'> {
  type: ISimpleMenuOption;
}

export type ICreateIntegrationRequestData = Omit<IIntegration, 'id'>;

export enum IntegrationTypes {
  BambooHR = 'bamboohr',
  HiBob = 'hiBob'
}