import { Accordion } from '@themesberg/react-bootstrap';
import { MetaDataContext } from 'contexts';
import dragula from 'dragula';
import { IMetadata, ISurveyBuilderBlock, ISurveyQuestionTypeConstants } from 'interfaces';
import * as React from 'react';
import { SurveyQuestionParentGroupContext } from '../contexts/SurveyQuestionParentGroupContext';
import SurveyQuestionWidget from './SurveyQuestionWidget';
import { getQuestionAccordionIndex } from '../utils/utils';

interface SurveyIQuestionsAccordionProps {
  questions: ISurveyBuilderBlock[];
  activeQuestionIndex: string;
  surveyBlockTempIdIndex: number;
  setSurveyBlockTempIdIndex: React.Dispatch<React.SetStateAction<number>>;
  onQuestionsUpdate: (questions: ISurveyBuilderBlock[], index: string, ref: React.RefObject<HTMLDivElement> | null) => void;
  onAccordionToggle: (index: string) => void;
}

const SurveyQuestionsAccordion: React.FunctionComponent<SurveyIQuestionsAccordionProps> = (
  {
    questions, activeQuestionIndex, surveyBlockTempIdIndex,
    onAccordionToggle, onQuestionsUpdate, setSurveyBlockTempIdIndex
  }
) => {

  const [elRefs, setElRefs] = React.useState<React.RefObject<HTMLDivElement>[]>([]);

  const dragulaContainerRef = React.useRef<HTMLDivElement | null>(null);

  const metaData = React.useContext(MetaDataContext) as IMetadata;
  const groupContext = React.useContext(SurveyQuestionParentGroupContext);


  React.useEffect(
    () => {

      setElRefs(
        (elementRefs) =>
          Array(questions.length)
            .fill(0)
            .map((_, i) => elementRefs[i] || React.createRef<HTMLDivElement>()),
      );

    },
    [questions]
  );


  React.useEffect(
    () => {

      let drake: dragula.Drake;

      const updateDraggedBlockIndex = (element: HTMLDivElement): void => {
        let dataIndex = element.getAttribute('data-index');

        if (dataIndex === null) {
          return;
        }

        const sourceIndex = +dataIndex;
        const targetIndex = Array.prototype.indexOf.call(dragulaContainerRef.current?.children, element);

        if (sourceIndex === targetIndex) {
          return;
        }

        const questionsCopy: ISurveyBuilderBlock[] = [...questions];
        const questionToMove = questionsCopy.splice(+sourceIndex, 1)[0];
        questionsCopy.splice(targetIndex, 0, questionToMove);

        const targetAccordionIndex = getQuestionAccordionIndex(
          targetIndex,
          questionToMove.type,
          metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
          groupContext
        );
        if (activeQuestionIndex !== targetAccordionIndex) {
          onQuestionsUpdate(questionsCopy, targetAccordionIndex, elRefs[targetIndex]);
        }
      };

      const isMoveElement = (element?: Element): boolean => {
        let dataIndex = element?.getAttribute('data-index');

        if (dataIndex !== null && dataIndex !== undefined) {
          const sourceIndex = +dataIndex;
          return !questions[sourceIndex].isLocked;
        }

        return false;
      }

      if (dragulaContainerRef.current) {
        drake = dragula(
          [dragulaContainerRef.current],
          {
            moves: isMoveElement,
          }
        );
        drake.on('dragend', (element) => {
          updateDraggedBlockIndex(element as HTMLDivElement);
        });
      }

      return () => {
        if (drake) {
          drake.destroy();
        }
      };
    },
    [questions, activeQuestionIndex, elRefs, metaData.surveyQuestionTypes.constants, groupContext]
  );


  const toggleAccordionByIndex = (index: number, questionType: string): void => {

    const accordionIndex = getQuestionAccordionIndex(
      index,
      questionType,
      metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
      groupContext
    );
    onAccordionToggle(accordionIndex);
  };


  const handleQuestionRemove = (index: number) => {

    const questionsCopy = [...questions];
    const elRefsCopy = [...elRefs];

    questionsCopy.splice(index, 1);
    elRefsCopy.splice(index, 1);

    setElRefs(elRefsCopy);

    if (index === questions.length - 1) {

      let accordionIndex = ''
      let elRef = null
      if (index !== 0) {
        accordionIndex = getQuestionAccordionIndex(
          index - 1,
          questionsCopy[index - 1].type,
          metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
          groupContext
        );
        elRef = elRefs[index - 1];
      }
      onQuestionsUpdate(questionsCopy, accordionIndex, elRef);

    } else {

      const accordionIndex = getQuestionAccordionIndex(
        index,
        questionsCopy[index].type,
        metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
        groupContext
      );
      onQuestionsUpdate(questionsCopy, accordionIndex, elRefs[index]);

    }

  };


  const handleQuestionDuplicate = (index: number) => {

    const questionsCopy = [...questions];
    const elRefsCopy = [...elRefs];

    questionsCopy.splice(
      index + 1, 0,
      {
        ...questionsCopy[index],
        id: '',
        tempId: (surveyBlockTempIdIndex + 1).toString()
      }
    );
    elRefsCopy.splice(
      index + 1, 0,
      React.createRef<HTMLDivElement>()
    );

    setSurveyBlockTempIdIndex(surveyBlockTempIdIndex + 1);
    setElRefs(elRefsCopy);

    const accordionIndex = getQuestionAccordionIndex(
      index + 1,
      questionsCopy[index + 1].type,
      metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
      groupContext
    );
    onQuestionsUpdate(questionsCopy, accordionIndex, elRefsCopy[index + 1]);
  };


  const handleQuestionMoveUp = (index: number) => {

    if (index !== 0) {
      const questionsCopy = [...questions];
      const questionToMoveUp = questionsCopy.splice(index, 1)[0];
      questionsCopy.splice(index - 1, 0, questionToMoveUp);
      const accordionIndex = getQuestionAccordionIndex(
        index - 1,
        questionsCopy[index - 1].type,
        metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
        groupContext
      );
      onQuestionsUpdate(questionsCopy, accordionIndex, elRefs[index - 1]);

    }
  };


  const handleQuestionMoveDown = (index: number) => {

    if (index !== (questions.length - 1)) {
      const questionsCopy = [...questions];
      const questionToMoveDown = questionsCopy.splice(index, 1)[0];
      questionsCopy.splice(index + 1, 0, questionToMoveDown);
      const accordionIndex = getQuestionAccordionIndex(
        index + 1,
        questionsCopy[index + 1].type,
        metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
        groupContext
      );
      onQuestionsUpdate(questionsCopy, accordionIndex, elRefs[index + 1]);
    }
  };


  const handleQuestionLockUnlock = (isLocked: boolean, index: number) => {
    const questionsCopy = [...questions];
    questionsCopy[index].isLocked = isLocked;
    const accordionIndex = getQuestionAccordionIndex(
      index,
      questionsCopy[index].type,
      metaData.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
      groupContext
    );

    if (activeQuestionIndex === accordionIndex) {
      onQuestionsUpdate(questionsCopy, '', null);
    } else {
      onQuestionsUpdate(questionsCopy, accordionIndex, null);
    }
  }


  return (
    <Accordion activeKey={activeQuestionIndex}>
      <div ref={dragulaContainerRef}>
        {
          questions.map(
            (question, index) => {
              return (
                <div
                  data-index={index}
                  ref={elRefs[index]}
                  key={question.id || question.tempId}
                >
                  <SurveyQuestionWidget
                    index={index}
                    question={question}
                    onRemove={() => { handleQuestionRemove(index); }}
                    onMoveUp={() => { handleQuestionMoveUp(index); }}
                    onMoveDown={() => { handleQuestionMoveDown(index); }}
                    onDuplicate={() => { handleQuestionDuplicate(index); }}
                    onHeaderClick={() => { toggleAccordionByIndex(index, questions[index].type); }}
                    onLockUnlock={(isLocked) => { handleQuestionLockUnlock(isLocked, index); }}
                  />
                </div>
              );
            }
          )
        }
      </div>
    </Accordion>
  );
};

export default SurveyQuestionsAccordion;
