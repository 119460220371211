import { FormErrors, IEditSuperAdminsFormValues, IMemberOption } from 'interfaces';
import * as Yup from 'yup';
import { MEMBER_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const EDIT_SUPER_ADMINS_VALIDATION_SCHEMA: Yup.SchemaOf<IEditSuperAdminsFormValues> =
  Yup.object().shape(
    {
      superAdmins: Yup.array()
        .of(MEMBER_MENU_OPTION_VALIDATION_SCHEMA as Yup.SchemaOf<IMemberOption>)
        .required(FormErrors.Required)
        .min(1, FormErrors.Required),
    }
  );
