import { CustomSelect, PageTitle, PrimaryLayoutBox } from 'components';
import { useOrganizationMenuOptions } from 'hooks';
import { IChannelMenuOption } from 'interfaces';
import React from 'react';
import { Routes } from 'routes';
import { StringParam, useQueryParam } from 'use-query-params';

import ReviewToDosWidget from './components/ReviewToDosWidget';

interface IReviewToDosPageProps { }

const ReviewToDosPage: React.FC<IReviewToDosPageProps> = () => {

  return (
    <>
      <PageTitle title={Routes.ReviewToDosPage.title} />
      {/* <PrimaryLayoutBox title={Routes.ReviewToDosPage.title} > */}
      <ReviewToDosWidget />
      {/* </PrimaryLayoutBox> */}
    </>
  );
};

export default ReviewToDosPage;