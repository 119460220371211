import React, { useCallback, useRef, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import Markdown from "markdown-to-jsx";
import SendIcon from "ReactSvgIcons/SendIcon";
import RefreshSvgIcon from "ReactSvgIcons/RefreshIcon";
import CloseSvgIcon from "ReactSvgIcons/CloseSvgIcon";
import OuterAiChatIcon from "ReactSvgIcons/OuterAiChatIcon";
import InnerChatIcon from "ReactSvgIcons/InnerChatIcon";

// Define types for the chat messages and socket events
interface ChatMessage {
  humanMessage: string;
  aiMessage?: string;
  id: string;
}

interface ChatState {
  chatId: string | null;
  messages: ChatMessage[];
}

interface NewChatMessageData {
  chatId: string;
  inputMessage: string;
  messageId: string;
}

interface AIResponseChunkData {
  chatId: string;
  messageId: string;
  chunk: string;
}

interface AIResponseCompleteData {
  status: boolean;
}

interface InputMetadata {
  profileId: string;
}

const defaultMessage: ChatState = {
  chatId: null,
  messages: [],
};

const defaultPrompts = [
  "Analyze performance review campaigns",
  "Analyze employee feedback",
  "Whose profile am I viewing?",
];

const AiChat = () => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [socketTrigger, setSocketTrigger] = useState(false);
  const [currentMessage, setCurrentMessage] =
    useState<ChatState>(defaultMessage);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [currentMessage, isChatOpen, isSendingMessage]);

  const toggleChat = (): void => {
    setIsChatOpen(!isChatOpen);
  };

  function getProfileUrl() {
    const url = window.location.href;
    const regex = /\/users\/([^/]+)\//;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1]; // This will return the UUID
    } else {
      return null;
    }
  }

  // Setup Socket connection
  useEffect(() => {
    if (!isChatOpen) return;
    const url = new URL(process.env.REACT_APP_API_URL ?? "");
    const socketUrl: string = url?.origin;
    if (!socketUrl) return;
    const newSocket = io(socketUrl, {
      transports: ["websocket"],
      auth: { token: localStorage.getItem("token") },
      path: "/api/teamble-ai/socket.io",
    });
    setSocket(newSocket);
    // Handle new chat message
    newSocket.on("new_chat_message", handleNewChatMessage);
    // Handle input message
    newSocket.on("input_message", handleInputMessage);
    // Handle AI response chunk
    newSocket.on("ai_response_chunk", handleAIResponseChunk);
    // Handle AI response completion
    newSocket.on("ai_response_complete", handleAIResponseComplete);
    return () => {
      newSocket.disconnect();
    };
  }, [isChatOpen, socketTrigger]);

  // Handler functions
  const handleNewChatMessage = (data: NewChatMessageData) => {
    setCurrentMessage({
      chatId: data.chatId,
      messages: [{ humanMessage: data.inputMessage, id: data.messageId }],
    });
  };

  useEffect(() => {
    isChatOpen && setIsSendingMessage(false);
  }, [isChatOpen]);

  const handleInputMessage = (data: NewChatMessageData) => {
    setCurrentMessage((prev: ChatState) => {
      if (prev?.chatId === data?.chatId) {
        return {
          ...prev,
          messages: [
            ...prev.messages,
            { humanMessage: data.inputMessage, id: data.messageId },
          ],
        };
      }
      return prev;
    });
  };

  const handleAIResponseChunk = (data: AIResponseChunkData) => {
    setCurrentMessage((prev: any) => {
      const updatedMessages = prev.messages.map((message: any) =>
        message.id === data.messageId
          ? { ...message, aiMessage: (message.aiMessage || "") + data.chunk }
          : message
      );
      return { ...prev, messages: updatedMessages };
    });
  };

  const handleAIResponseComplete = (data: AIResponseCompleteData) => {
    if (data?.status) {
      setIsSendingMessage(false);
    }
  };

  // Send message to AI
  const handleChatWithAi = useCallback(
    async (item) => {
      const profileId = getProfileUrl();
      const inputMetaData = {
        profileId,
      };

      if (socket && item.trim() && !isSendingMessage) {
        socket.emit("ai_chat_message", {
          id: currentMessage?.chatId ?? null,
          inputMessage: item,
          inputMetaData,
        });
        setIsSendingMessage(true);
        setInputMessage("");
      }
    },
    [
      isSendingMessage,
      socket,
      currentMessage,
      setIsSendingMessage,
      setInputMessage,
    ]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !event.shiftKey) {
        inputMessage && handleChatWithAi(inputMessage);
      } else if (event.key === "Escape") {
        setIsChatOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [socket, inputMessage, currentMessage, handleChatWithAi]);

  const handleRefreshChat = () => {
    setCurrentMessage(defaultMessage);
    setIsSendingMessage(false);
  };

  const onAbort = () => {
    socket && socket.emit("abort", {});
  };

  const handleDefaultPrompt = (item: string) => {
    setInputMessage((prev) => {
      const newMessage = item; // The new input message
      handleChatWithAi(item); // Pass the new message to the function after the state is set
      return newMessage; // Return the new state value
    });
  };

  return (
    <div className={`AiChat ${isChatOpen ? "ai-chat-expanded" : ""}`}>
      {isChatOpen ? (
        <div
          className="chat_message"
          onFocus={() => {
            socket?.disconnected && setSocketTrigger((prev) => !prev);
          }}
        >
          {/* <div className="chat_sideBar">
            <button
              className="btn-primary btn-create"
              onClick={handleCreateClick}
            >
              New Chat +
            </button>
          </div> */}
          <div className="chat_content">
            <div className="chat_header">
              <div className="chat_header_title">Teamble AI</div>
              <div className="chat_header_close">
                <RefreshSvgIcon
                  className="refresh-icon"
                  height={18}
                  width={20}
                  onClick={handleRefreshChat}
                />
                <CloseSvgIcon
                  className="close-icon"
                  height={22}
                  width={24}
                  onClick={toggleChat}
                />
              </div>
            </div>
            <div className="chat_body">
              <div className="chat_body_messages">
                {currentMessage?.messages.map((message, index) => (
                  <div className="chat-content" key={index}>
                    {message.humanMessage && (
                      <div className="humanMessage">
                        <Markdown>{message.humanMessage}</Markdown>
                      </div>
                    )}
                    {message.aiMessage && (
                      <div className="aiMessage">
                        <Markdown>{message.aiMessage}</Markdown>
                      </div>
                    )}
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="chat_body_prompts">
                {defaultPrompts.map((item, index) => (
                  <button
                    key={index}
                    className="prompt-items"
                    disabled={isSendingMessage}
                    onClick={() => {
                      handleDefaultPrompt(item);
                    }}
                  >
                    {item}
                  </button>
                ))}
              </div>
              <div className="chat_body_input">
                <textarea
                  className="chat_body_input_text"
                  style={{
                    cursor: isSendingMessage ? "not-allowed" : "default",
                  }}
                  disabled={isSendingMessage}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder="Ask Teamble AI a question..."
                />
                {isSendingMessage ? (
                  <div className="onAbort" onClick={onAbort}>
                    <div className="circle"></div>
                  </div>
                ) : (
                  <SendIcon
                    color="#596ee3"
                    height={30}
                    width={30}
                    style={{
                      cursor: isSendingMessage ? "not-allowed" : "pointer",
                    }}
                    onClick={() => {
                      inputMessage && handleChatWithAi(inputMessage);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={toggleChat} className="chat-icon">
          <OuterAiChatIcon className="outer-icon" />
          <InnerChatIcon className="inner-icon" />
        </div>
      )}
    </div>
  );
};

export default AiChat;
