import { FormErrors, IFeedbackForConfigFormValues } from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';

export const FEEDBACK_FOR_VALIDATION_SCHEMA: Yup.SchemaOf<IFeedbackForConfigFormValues> =
  Yup.object().shape(
    {
      feedbackFor: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
        .required(FormErrors.Required)
        .nullable(),
      isAnonymous: Yup.boolean().default(undefined)
    }
  );