import { Card, Form } from '@themesberg/react-bootstrap';
import { FormContext } from 'contexts';
import { FormikProps } from 'formik';
import { useApp } from 'hooks';
import { IActiveReviewFormValues, IChannelMenuOption, IMenuOption, IRouteParams, ISimpleMenuOption, SignInWith } from 'interfaces';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import LaunchNominationFields from './LaunchNominationField';

import LaunchReviewAudienceConfigWidget from './LaunchReviewAudienceConfigWidget';
import LaunchReviewBasicConfigWidget from './LaunchReviewBasicConfigWidget';
import LaunchReviewScheduleConfigWidget from './LaunchReviewScheduleConfigWidget';
import LaunchReviewShareResultConfig from './LaunchReviewShareResultConfig';

interface ILaunchReviewFormProps {
  launchNewReviewForm: FormikProps<IActiveReviewFormValues>;
  reviewTemplates: IMenuOption[] | null;
  channelOptions: IChannelMenuOption[] | null;
  relationshipsOptions: ISimpleMenuOption[]
  startNominations: () => void;
  isStartingNomination: boolean;
  isNominationInProgress: boolean;
}


const SelectChannelMessage: React.FunctionComponent<{}> = () => {

  const app = useApp();

  return (
    <p className='text-center fs-3'>
      Please select {app === 'slack' ? 'a slack workspace' : 'a group'} to select the Audience
    </p>
  )
};


const LaunchReviewForm: React.FunctionComponent<ILaunchReviewFormProps> = ({
  launchNewReviewForm,
  reviewTemplates,
  channelOptions,
  startNominations,
  isStartingNomination,
  isNominationInProgress,
  relationshipsOptions
}) => {

  const app = useApp();
  const { reviewId } = useParams<IRouteParams>();


  return (
    <div className='overflow-y-auto flex-grow-1'>
      <FormContext.Provider value={launchNewReviewForm}>
        <Form>

          <LaunchReviewBasicConfigWidget
            launchNewReviewForm={launchNewReviewForm}
            reviewTemplates={reviewTemplates}
            channelOptions={channelOptions}
          />

          <LaunchReviewShareResultConfig
            relationshipsOptions={relationshipsOptions}
            launchNewReviewForm={launchNewReviewForm}
          />

          <LaunchReviewScheduleConfigWidget />

          {
            !launchNewReviewForm.values.channel
              ?
              <SelectChannelMessage />
              :
              <>
                {
                  app === SignInWith.Slack && reviewId &&
                  <div className='position-relative z-index-2'>
                    <LaunchNominationFields
                      isNominationInProgress={isNominationInProgress}
                      launchNewReviewForm={launchNewReviewForm}
                      selectedChannel={launchNewReviewForm.values.channel}
                      audience={launchNewReviewForm?.values?.nominatorAudience?.verifiedEmailPairs}
                      startNominations={startNominations}
                      isStartingNomination={isStartingNomination}
                      onAudienceChange={(audience) => launchNewReviewForm.setFieldValue('nominatorAudience.verifiedEmailPairs', audience || [])} />
                  </div>
                }
                <div className='position-relative z-index-1'>
                  <LaunchReviewAudienceConfigWidget
                    selectedChannel={launchNewReviewForm.values.channel}
                    audience={launchNewReviewForm.values.reviewerRevieweeInfos}
                    onAudienceChange={(audience) => launchNewReviewForm.setFieldValue('reviewerRevieweeInfos', audience)} />
                </div>
              </>
          }

        </Form>
      </FormContext.Provider>
    </div>
  );
};

export default LaunchReviewForm;
