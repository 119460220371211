import { Button, Card } from '@themesberg/react-bootstrap';
import { IQuestionBuilderFormType } from 'interfaces';
import React from 'react';

import QuestionBuilder, { useQuestionBuilder } from './QuestionBuilder';

interface IQuestionPreviewWidgetProps { }

const QuestionPreviewWidget: React.FC<IQuestionPreviewWidgetProps> = () => {

  const {
    previewQuestionKey,
    builderForm,
  } = useQuestionBuilder<IQuestionBuilderFormType>();

  const [showPreviewModal, setShowPreviewModal] = React.useState<boolean>(false);


  return (
    <>
      <Card className='flex-grow-1 overflow-hidden'>

        <Card.Header className='flex-shrink-0'>
          <Card.Title className='m-0'> Preview </Card.Title>
        </Card.Header>

        <Card.Body className='overflow-y-auto d-flex justify-content-center align-items-center'>
          <div className='w-75'>
            {
              previewQuestionKey !== null &&
              <QuestionBuilder.QuestionPreview
                question={builderForm.values.questions[previewQuestionKey]} />
            }
          </div>
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end flex-shrink-0'>
          <Button
            variant="outline-primary"
            type="button"
          onClick={() => setShowPreviewModal(true)}
          >
            Full Preview
          </Button>
        </Card.Footer>
      </Card>
      <QuestionBuilder.PreviewModal
        show={showPreviewModal}
        onClose={() => setShowPreviewModal(false)} />
    </>
  );
};

export default QuestionPreviewWidget;