import { FeedbackForAudienceTypes, IFeedbackLoopAudiencePair } from "interfaces";
import React, { useEffect } from "react";

export const useFeedbackForSenderReceiverPreview = (
  feedbackFor?: FeedbackForAudienceTypes,
  feedbackForAudiencePairs?: IFeedbackLoopAudiencePair[],
): (string | string[])[] => {

  const [feedbackForReceivers, setFeedbackForReceivers] = React.useState<string[]>([]);
  const [feedbackForSender, setFeedbackForSender] = React.useState<string>('');


  useEffect(
    () => {

      if (
        // If Feedback-For is not configured yet
        !feedbackFor ||

        // If Feedback-For is Self-Select there is no pairs to be displayed
        feedbackFor === FeedbackForAudienceTypes.SelfSelect ||

        // If Feedback-For is any automatic option but there is no automatic pairs available
        !feedbackForAudiencePairs?.length
      ) {
        setFeedbackForReceivers([]);
        setFeedbackForSender('');
        return;
      }

      // Get a Feedback-Sender with more than 1 receivers
      const feedbackSenderMap = new Map<string, string[]>();
      feedbackForAudiencePairs.forEach(
        (info) => {

          const receivers = feedbackSenderMap
            .get(`${info.sender.userId}_${info.sender.name}`) || [];

          feedbackSenderMap.set(
            `${info.sender.userId}_${info.sender.name}`,
            [
              ...receivers,
              info.receiver!.name
            ]
          );
        }
      );

      for (const feedbackSender of feedbackSenderMap) {
        if (feedbackSender[1].length > 1) {
          setFeedbackForReceivers(feedbackSender[1]);
          setFeedbackForSender(feedbackSender[0].split('_')[1]);
          return;
        }
      }

      const feedbackForUsersList = Array.from(feedbackSenderMap)[0];
      setFeedbackForSender(feedbackForUsersList[0].split('_')[1]);
      setFeedbackForReceivers(feedbackForUsersList[1]);

    },
    [feedbackForAudiencePairs, feedbackFor]
  );


  return [feedbackForSender, feedbackForReceivers];

};