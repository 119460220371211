import { ProgressBar } from '@themesberg/react-bootstrap';
import { PROGRESS_COLUMN_CONFIG } from '@common/modules/table';
import { useApp } from 'hooks';
import { ISurvey, ISurveyStateConstants, SignInWith } from 'interfaces';
import moment from 'moment';
import * as React from 'react';
import { CellProps, Column } from 'react-table';
import { DATE_TIME_FORMAT, SURVEY_STATE_FILTERS } from 'teamble-constants';
import { getDateSortingFunc, getSurveyDuration } from 'utils';


const getOrganizationText = (app: SignInWith, survey: ISurvey): string => {
  if (app === SignInWith.Slack && survey?.workspace?.name) {
    return survey.workspace.name;
  } else if (app === SignInWith.MsTeams && survey?.channel?.name) {
    return survey.channel.name;
  } else {
    return 'Not Available';
  }
};


const getCloseAtText = (stateFilter: string, survey: ISurvey): string => {
  if (stateFilter === (SURVEY_STATE_FILTERS.constants as ISurveyStateConstants).OPEN) {
    return getSurveyDuration(survey) as string;
  }

  if (stateFilter === (SURVEY_STATE_FILTERS.constants as ISurveyStateConstants).CLOSED) {
    return survey.closedAt
      ?
      moment(survey.closedAt, 'x').local().format(DATE_TIME_FORMAT)
      :
      'Not Available';
  }

  return 'Not Available';
};



export const useSurveysListTableColumns = (
  stateFilter: string,
) => {

  const dateSortingFunc = React.useMemo(
    () => getDateSortingFunc<ISurvey>(),
    []
  );

  const app = useApp();


  return React.useMemo<readonly Column<ISurvey>[]>(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Template',
        cellClassName: 'table-cell-text',
        accessor: (row) => row.templateName || 'Not Available',
      },
      {
        Header: 'Launched By',
        cellClassName: 'table-cell-text',
        accessor: (row) => row.createdBy ? row.createdBy.name : 'Not Available',
      },
      {
        Header: app === SignInWith.Slack ? 'Workspace' : 'Group',
        cellClassName: 'table-cell-text',
        accessor: (row) => getOrganizationText(app, row),
      },
      {
        id: 'launchedAt',
        Header: 'Launched At',
        accessor: (row) => {
          return row.createdAt
            ?
            moment(row.createdAt, 'x').local().format(DATE_TIME_FORMAT)
            :
            'Not Available';
        },
        sortType: dateSortingFunc,
      },
      {
        Header: stateFilter === (SURVEY_STATE_FILTERS.constants as ISurveyStateConstants).OPEN
          ? 'Closes At' : 'Closed At',
        accessor: (row) => getCloseAtText(stateFilter, row),
        sortType: dateSortingFunc,
      },
      {
        ...PROGRESS_COLUMN_CONFIG,
        accessor: (row) => `${row.progress}%`,
        Cell: (cellProps: CellProps<ISurvey, string>) => {

          return (
            <div className="d-flex align-items-center">
              <small className="fw-bold">{cellProps.value}</small>
              <ProgressBar variant="primary" className="progress-lg mb-0 ms-3" now={cellProps.row.original.progress} />
            </div>
          )
        },
      },
    ],
    [app, stateFilter, dateSortingFunc]
  );

};