import { ISimpleMenuOption, IMemberOption, IChannelMenuOption, IFixedMenuOption } from 'interfaces';
import * as Yup from 'yup';

export const SIMPLE_MENU_OPTION_VALIDATION_SCHEMA: Yup.SchemaOf<ISimpleMenuOption> =
  Yup.object().shape({
    value: Yup.string().defined(),
    label: Yup.string().defined(),
  });

export const ONE_ON_ONE_SIMPLE_MENU_OPTION_VALIDATION_SCHEMA: Yup.SchemaOf<ISimpleMenuOption> =
  Yup.object().shape({
    value: Yup.string().required().defined(),
    label: Yup.string().required().defined(),
  });

export const SIMPLE_MENU_OPTION_VALIDATION_SCHEMA_NOT_REQUIRED: Yup.SchemaOf<any> =
  Yup.object().shape({
    value: Yup.string().nullable().notRequired(),
    label: Yup.string().nullable().notRequired(),
  });

export const MEMBER_MENU_OPTION_VALIDATION_SCHEMA: Yup.SchemaOf<IMemberOption> =
  (SIMPLE_MENU_OPTION_VALIDATION_SCHEMA_NOT_REQUIRED as any).shape({
    description: Yup.string(),
    imageOriginal: Yup.string(),
    email: Yup.string(),
    membershipId: Yup.string(),
    teambleUserId: Yup.string(),
    teamble_id: Yup.string(),
    name: Yup.string(),
    id: Yup.string(),
  });

export const CHANNEL_MENU_OPTION_VALIDATION_SCHEMA: Yup.SchemaOf<Omit<IChannelMenuOption, 'options'>> =
  SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.shape({
    companyId: Yup.string(),
    teamId: Yup.string(),
    teambleTeamId: Yup.string(),
  });

export const FIXED_MENU_OPTION_VALIDATION_SCHEMA: Yup.SchemaOf<IFixedMenuOption> =
  SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.shape({
    isFixed: Yup.boolean(),
  });