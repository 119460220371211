import moment from "moment";
import { DATE_TIME_FORMAT } from "teamble-constants";

export const mapAudienceType = (
  audience: Array<{ type: string }> = []
): Array<{ label: string; value: string }> => {
  return audience.map((item) => ({
    label: item.type,
    value: item.type,
  }));
};

export const mapCampaignUsers = (
  audience: Array<any> = [],
  usersList: Array<{ id: string; name: string }> = []
): Array<{ label: string; value: string }> => {
  const userAudience = audience.find((item) => item.type === "users");
  const userIds = userAudience?.userIds || [];
  return userIds.map((userId: string) => {
    const user = usersList.find((user) => user.id === userId);
    return {
      label: user?.name || "",
      value: userId,
    };
  });
};

export const formatDate = (date: number | undefined): string => {
  return date ? moment(date).format(DATE_TIME_FORMAT) : "";
};
