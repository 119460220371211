import { Card, Form } from '@themesberg/react-bootstrap';
import { CollapsibleCard, FormField, SelectUserPairsField } from 'components';
import { FormContext } from 'contexts';
import { updateFeedbackLoopAnonymity, updateFeedbackLoopFeedbackFor } from 'data';
import { useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  FeedbackForAudienceTypes,
  FormFieldTypeConstants,
  IFeedbackForConfigFormValues,
  IFeedbackLoopAudiencePair, IFeedbackLoopSenderReceiverInfosRequestData,
  IFeedbackLoopTemplateTypes,
  IMemberOption,
  IMenuOption,
  IRouteParams,
  IUpdateAnonymityRequestData,
  IUpdateFeedbackForRequestData,
  SignInWith
} from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { FEEDBACK_FOR_AUDIENCE_TYPES, MS_TEAMS_FEEDBACK_FOR_AUDIENCE_TYPES, ONE_ON_ONE_FOR_AUDIENCE_TYPES } from 'teamble-constants';
import { getDistinctAudiencePairs, replaceOptionsText } from 'utils';
import { FEEDBACK_FOR_VALIDATION_SCHEMA } from 'validation-schemas';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import {
  useFeedbackForManuallyDefineSenderOptions
} from '../hooks/useFeedbackForManuallyDefineSenderOptions';
import { getFeedbackForConfigFormValues } from '../utils/formUtils';
import FeedbackForUploadPairsField from './FeedbackForUploadPairsField';
import FeedbackLoopManuallyDefineActions from './FeedbackLoopManuallyDefineActions';
import FeedbackLoopSenderReceiverWidget from './FeedbackLoopSenderReceiverWidget';
import { useCompanyConfig } from '@common/modules/companyConfig';
import { useParams } from 'react-router-dom';

interface IFeedbackForConfigWidgetProps { }

enum ActiveSelectField {
  Select = 'select',
  Upload = 'upload'
}

const FeedbackForConfigWidget: React.FC<IFeedbackForConfigWidgetProps> = () => {
  const { templateType } = useParams<IRouteParams>();
  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [activeSelectField, setActiveSelectField] = React.useState<ActiveSelectField>(ActiveSelectField.Select);
  const [feedbackForAudienceTypes, setFeedbackForAudienceTypes] = React.useState<IMenuOption[]>([]);

  // App hook
  const app = useApp();
  const companyConfig = useCompanyConfig();
  const customOptions = Object.values(companyConfig.feedbackFor || {}).map(customOption => ({ value: customOption.value, label: customOption.header }));

  // Feedback Loop config hook
  const { feedbackLoop, audienceUpdatedCount, onFeedbackLoopUpdate, onAudienceUpdatedCount } = useFeedbackLoopConfig();

  // Form hook

  const formInitialValues = getFeedbackForConfigFormValues(feedbackLoop);

  if (customOptions.map(d => d.value).includes(feedbackLoop.feedbackFor as string)) {
    const custom = customOptions.find(d => d.value === feedbackLoop.feedbackFor);
    if (custom) {
      formInitialValues.feedbackFor = custom;
    }
  }

  const feedbackForConfigForm = useFormik<IFeedbackForConfigFormValues>({
    initialValues: formInitialValues,
    validationSchema: FEEDBACK_FOR_VALIDATION_SCHEMA,
    onSubmit: () => { }
  });

  const { memberOptions } = useFeedbackForManuallyDefineSenderOptions(
    feedbackForConfigForm.values.feedbackFor?.value === FeedbackForAudienceTypes.ManuallyDefine,
    app === SignInWith.Slack ? feedbackLoop.workspaceId : feedbackLoop.channelId,
    feedbackLoop.audience
  );


  // Auto save the anonymity configuration on form value changes
  React.useEffect(
    () => {

      const isAnonymityChanged = (): boolean => {
        return (
          (
            // if anonymity is not already set and form-anonymity value is also not null
            // then the anonymity is changed
            !feedbackLoop.anonymity?.type && feedbackForConfigForm.values.isAnonymous !== undefined
          ) ||
          (
            // if anonymity is already set but form-anonymity value is different than already set value
            // then the anonymity is changed
            (feedbackForConfigForm.values.isAnonymous && feedbackLoop.anonymity?.type === 'non-anonymous') ||
            (!feedbackForConfigForm.values.isAnonymous && feedbackLoop.anonymity?.type === 'anonymous')
          )
        )
      };

      if (!isAnonymityChanged()) {
        return;
      }

      const requestData: IUpdateAnonymityRequestData = {
        feedbackLibraryLoopId: feedbackLoop.id,
        anonymity: feedbackForConfigForm.values.isAnonymous ? 'anonymous' : 'non-anonymous',
      };

      setIsLoading(true);
      const subscription = updateFeedbackLoopAnonymity(requestData)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            onFeedbackLoopUpdate(response.data.data);
            toast.success(response.data.message);
          }
        );

      return () => subscription.unsubscribe();
    },
    [
      feedbackForConfigForm.values.isAnonymous,
      feedbackLoop.anonymity,
      feedbackLoop.id,
      feedbackLoop.workspaceId,
      onFeedbackLoopUpdate
    ]
  );


  // Auto save the feedback-for configuration on form value changes
  React.useEffect(
    () => {

      const isFeedbackForValueChanged = (): boolean => {
        return !!(
          // if feedbackFor in form is valid
          (!feedbackForConfigForm.errors.feedbackFor) &&
          (
            // if feedbackFor is not already set and form-feedbackFor value is also not null
            // then the feedbackFor is changed
            (!feedbackLoop.feedbackFor && feedbackForConfigForm.values.feedbackFor !== null) ||

            // if feedbackFor is already set but form-feedbackFor value is different than already set value
            // then the feedbackFor is changed
            (feedbackLoop.feedbackFor !== feedbackForConfigForm.values.feedbackFor?.value)
          )
        )
      };

      if (!isFeedbackForValueChanged()) {
        return;
      }

      const requestData: IUpdateFeedbackForRequestData = {
        feedbackLibraryLoopId: feedbackLoop.id,
        feedbackFor: feedbackForConfigForm.values.feedbackFor!.value as FeedbackForAudienceTypes
      };

      setIsLoading(true);
      const subscription = updateFeedbackLoopFeedbackFor(requestData)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            onFeedbackLoopUpdate(response.data.data);
            toast.success(response.data.message);
          }
        );

      return () => subscription.unsubscribe();
    },
    [
      feedbackForConfigForm.errors.feedbackFor, feedbackForConfigForm.values.feedbackFor,
      feedbackLoop.feedbackFor, feedbackLoop.id, feedbackLoop.workspaceId, onFeedbackLoopUpdate
    ]
  );


  // Update the feedback-for audience types depending on the app
  // Slack - All the options
  // MS Teams - Exclude manager options
  React.useEffect(
    () => {
      if(templateType === IFeedbackLoopTemplateTypes?.OneOnOne){
        setFeedbackForAudienceTypes(ONE_ON_ONE_FOR_AUDIENCE_TYPES)
        return;
      }
      if (app === SignInWith.Slack) {
        setFeedbackForAudienceTypes(
          companyConfig?.defaultRelationshipName
            ? replaceOptionsText(FEEDBACK_FOR_AUDIENCE_TYPES, "Manager", companyConfig?.defaultRelationshipName)
            : FEEDBACK_FOR_AUDIENCE_TYPES
        );
        return;
      }

      if (app === SignInWith.MsTeams) {
        const options = [...MS_TEAMS_FEEDBACK_FOR_AUDIENCE_TYPES];
        if (companyConfig.feedbackFor) {
          options.forEach(data => {
            if (data.value === 'define') {
              data.options = [...new Map([...(data.options || []), ...customOptions].map(item => [item.value, item])).values()]
            }
          })
        }
        setFeedbackForAudienceTypes(options);
      }
    },
    [app, templateType]
  );


  const updateManualAudiencePairs = (senderReceiverInfos: IFeedbackLoopSenderReceiverInfosRequestData[]): void => {
    const requestData: IUpdateFeedbackForRequestData = {
      feedbackLibraryLoopId: feedbackLoop.id,
      feedbackFor: feedbackForConfigForm.values.feedbackFor!.value as FeedbackForAudienceTypes,
      senderReceiverInfos,
    };

    setIsLoading(true);
    updateFeedbackLoopFeedbackFor(requestData)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          onFeedbackLoopUpdate(response.data.data);
          onAudienceUpdatedCount(audienceUpdatedCount + 1);
          toast.success(response.data.message);
        }
      );
  };


  const addManuallySelectedAudience = (
    firstUsersList: IMemberOption[] | null, secondUsersList: IMemberOption[] | null
  ): void => {

    if (!firstUsersList?.length || !secondUsersList?.length) {
      return;
    }

    const senderReceiverInfos: IFeedbackLoopSenderReceiverInfosRequestData[] = [];
    firstUsersList.forEach(
      (firstUser) => {

        secondUsersList.forEach(
          (secondUser) => {

            senderReceiverInfos.push({
              sender: { userId: firstUser.value },
              receiver: { userId: secondUser.value }
            });
          }
        )
      }
    );

    if (!senderReceiverInfos.length) {
      return;
    }

    const currentAudiencePairs: IFeedbackLoopSenderReceiverInfosRequestData[] = feedbackLoop.senderReceiverInfos?.map(
      (info) => ({
        sender: { userId: info.sender.userId as string },
        receiver: { userId: info.receiver!.userId as string }
      })
    ) || [];

    const distinctPairs = getDistinctAudiencePairs([...currentAudiencePairs, ...senderReceiverInfos]);
    updateManualAudiencePairs(distinctPairs);
  };


  const addAudiencePairsByFileUpload =
    (isFileUploading: boolean, audiencePairs?: IFeedbackLoopAudiencePair[]): void => {

      setIsLoading(isFileUploading);

      if (audiencePairs) {

        let newAudiencePairs: IFeedbackLoopAudiencePair[] = feedbackLoop.senderReceiverInfos || [];
        newAudiencePairs = [...newAudiencePairs, ...audiencePairs];

        const pairsToFilter: IFeedbackLoopSenderReceiverInfosRequestData[] = newAudiencePairs.map(
          (info) => ({
            sender: { userId: info.sender.userId as string },
            receiver: { userId: info.receiver!.userId as string }
          })
        ) || [];

        const distinctAudiencePairs = getDistinctAudiencePairs(pairsToFilter);
        updateManualAudiencePairs(distinctAudiencePairs);

      }
    };


  const handleSingleAudiencePairRemove = (
    audiencePair: IFeedbackLoopAudiencePair
  ): void => {

    const senderReceiverInfos: IUpdateFeedbackForRequestData['senderReceiverInfos'] = feedbackLoop
      .senderReceiverInfos?.filter(
        (pair) => pair.sender.userId !== audiencePair.sender.userId || pair.receiver!.userId !== audiencePair.receiver!.userId
      )
      .map(
        (pair) => ({
          sender: { userId: pair.sender.userId as string },
          receiver: { userId: pair.receiver!.userId as string },
        })
      );

    if (!senderReceiverInfos) {
      return;
    }

    updateManualAudiencePairs(senderReceiverInfos);
  };


  return (
    <CollapsibleCard
      title='Feedback is For'
      isLoading={isLoading}
    >
      <Card.Body>
        <Form>
          <FormContext.Provider value={feedbackForConfigForm}>
            {templateType === IFeedbackLoopTemplateTypes.Feedback && <FormField
              label='Anonymous'
              controlId='anonymousField'
              type={FormFieldTypeConstants.Checkbox}
              controlName='isAnonymous' />}


            <div className='w-50 position-relative z-index-10'>
              <FormField
                label='Feedback is for *'
                placeholder='Select Audience type...'
                controlId='feedbackForAudienceTypeField'
                type={FormFieldTypeConstants.Select}
                options={feedbackForAudienceTypes}
                controlName='feedbackFor'
                isDisabled={isLoading} />
            </div>

            {
              feedbackForConfigForm.values.feedbackFor?.value === FeedbackForAudienceTypes.ManuallyDefine &&
                feedbackLoop.audience?.length
                ?
                <Card className='mb-3'>
                  <Card.Body>
                    <FeedbackLoopManuallyDefineActions
                      isLoading={isLoading}
                      activeSelectField={activeSelectField}
                      isAudiencePairsAvailable={!!feedbackLoop.senderReceiverInfos?.length}
                      onActiveSelectFieldChange={setActiveSelectField}
                      onClearAllClick={() => updateManualAudiencePairs([])}
                    />

                    {
                      activeSelectField === ActiveSelectField.Select &&
                      <SelectUserPairsField
                        allowSelectAll={true}
                        isLoading={isLoading}
                        firstSelectOptions={memberOptions}
                        firstSelectTitle='Feedback Senders'
                        secondSelectTitle='Feedback Receivers'
                        organizationId={app === SignInWith.Slack ? feedbackLoop.workspaceId : feedbackLoop.channelId}
                        onAddToListClick={addManuallySelectedAudience}
                      />
                    }

                    {
                      activeSelectField === ActiveSelectField.Upload &&
                      <FeedbackForUploadPairsField
                        feedbackLoopLibraryId={feedbackLoop.id}
                        onFileUpload={addAudiencePairsByFileUpload}
                      />
                    }
                  </Card.Body>
                </Card>
                :
                null
            }

            {
              feedbackForConfigForm.values.feedbackFor?.value !== FeedbackForAudienceTypes.SelfSelect &&
              <FeedbackLoopSenderReceiverWidget
                feedbackForValue={feedbackForConfigForm.values.feedbackFor}
                isFeedbackLoopAudience={!!feedbackLoop.audience?.length}
                isFeedbackForUpdating={isLoading}
                onFeedbackLoopAudiencePairRemove={handleSingleAudiencePairRemove}
              />
            }

          </FormContext.Provider>
        </Form>
      </Card.Body>
    </CollapsibleCard>
  );
};

export default FeedbackForConfigWidget;