import { Axios, AxiosObservable } from 'axios-observable';
import {
  ICompleteSlackAuthorizationResponseData,
  IResponse,
  ISignInResponseData,
  SignInWith,
} from 'interfaces';
import { map } from 'rxjs';
import { getUserProfileWithPermissions } from 'utils';


export const signInWithPlatform = (signInWith: SignInWith, continueURL: string): AxiosObservable<IResponse<ISignInResponseData>> => {
  return Axios.post<IResponse<ISignInResponseData>>(
    signInWith === SignInWith.Slack ? "/signin-with-slack" : "/signin-with-msteams",
    { continueURL }
  );
};


export const completeAuthorization =
  (
    authorizationId: string,
    signInWith: SignInWith
  ): AxiosObservable<IResponse<ICompleteSlackAuthorizationResponseData>> => {

    return Axios.post<IResponse<ICompleteSlackAuthorizationResponseData>>(
      signInWith === SignInWith.Slack ? "/complete-slack-authorization" : "/complete-msteams-authorization",
      { authorizationId }
    ).pipe(
      map(
        (response) => {
          response.data.data.profileDetail = getUserProfileWithPermissions(
            response.data.data.profileDetail
          );
          return response;
        }
      )
    );

  };


export const completeEnterpriseGridAuthorization =
  (
    authorizationId: string,
    idToken: string,
    workspaceId: string
  ): AxiosObservable<IResponse<ICompleteSlackAuthorizationResponseData>> => {

    return Axios.post<IResponse<ICompleteSlackAuthorizationResponseData>>(
      "/complete-slack-authorization-2",
      {
        authorizationId,
        idToken,
        workspaceId,
      }
    ).pipe(
      map(
        (response) => {
          response.data.data.profileDetail = getUserProfileWithPermissions(
            response.data.data.profileDetail
          );
          return response;
        }
      )
    );

  };