import * as React from "react";

const DottedRightArrow = ({
  width = 20,
  height = 20,
  color = "#C2C3C5",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    {...props}
  >
    <path
      fill={color}
      d="M6 16.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM8.5 14a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM11 11.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM13.5 9a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM6 3.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.5 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM11 8.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
    <path fill={color} d="M13.5 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
  </svg>
);

export default DottedRightArrow;
