import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormFieldTypeConstants } from 'interfaces';
import * as React from 'react';
import { NOTIFICATION_SETTINGS } from 'teamble-constants';

interface ISurveyNotificationSettingWidgetProps {
  isLoading: boolean;
  onNotificationUpdate: () => void;
}

const SurveyNotificationSettingWidget: React.FunctionComponent<ISurveyNotificationSettingWidgetProps> = (
  { isLoading, onNotificationUpdate }
) => {

  return (
    <Card>
      <Card.Header className='bg-light py-2 px-3'>
        <Form.Label className='fw-bolder mb-0'> Send Survey as </Form.Label>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={6}>
            <FormField
              label='Send Survey as'
              controlId='surveyNotificationSetting'
              type={FormFieldTypeConstants.Select}
              options={NOTIFICATION_SETTINGS.options}
              controlName='notificationSetting' />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
        <Button
          size='sm'
          type='button'
          disabled={isLoading}
          onClick={onNotificationUpdate}> Save </Button>
      </Card.Footer>
    </Card>
  );
};

export default SurveyNotificationSettingWidget;
