import { Card } from '@themesberg/react-bootstrap';
import { DataState, Divider } from 'components';
import { getUserObjective } from 'data';
import { IObjectiveValues } from 'interfaces';
import React, { useEffect } from 'react';
import { finalize } from 'rxjs';

interface IRevieweeGoalsListCardProps {
  perfReviewId: string;
  revieweeUserId: string;
}

const RevieweeObjectiveListCard: React.FC<IRevieweeGoalsListCardProps> = (
  { revieweeUserId, perfReviewId }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [objectiveList, setObjectiveList] = React.useState<IObjectiveValues[]>([]);

  useEffect(
    () => {

      if (!revieweeUserId || !perfReviewId) {
        setIsLoading(false);
        setObjectiveList([]);
        return;
      }

      setIsLoading(true);
      const subscription = getUserObjective(revieweeUserId, null, perfReviewId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setObjectiveList(response.data.data);
          }
        );
      return () => subscription.unsubscribe();
    },
    [perfReviewId, revieweeUserId]
  );

  return (
    <Card className='overflow-hidden h-100'>
      <Card.Body
        className='h-100 position-relative overflow-y-auto'
        style={{ height: !objectiveList.length || isLoading ? 100 : '' }}
      >
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!objectiveList.length}
          emptyStateMessage='No Objectives set!'
        >
          <ul className='list-unstyled m-0'>
            {
              objectiveList.map(
                (objective) => (
                  <li key={objective.id} className='mb-2'>
                    <div className='d-flex justify-content-between align-items-start'>
                      <div className='flex-grow-1 mw-0'>
                        <p className='fw-bolder mb-1'> {objective.name} </p>
                        <p className='m-0'> {objective.description} </p>
                      </div>
                    </div>
                    <Divider />
                  </li>
                )
              )
            }
          </ul>
        </DataState>
      </Card.Body>
    </Card>
  );
};

export default RevieweeObjectiveListCard;