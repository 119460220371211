import { IMenuOption } from 'interfaces';

export const getSelectedOptionByQueryParam = <T extends IMenuOption>(
  queryParamValue: string | null | undefined,
  options: T[] | undefined,
  valueKey: keyof T = 'value',
  isNested: boolean = false
): T | null => {

  if (!queryParamValue || !options?.length) {
    return null;
  }

  let selectedOption: T | undefined;

  if (isNested) {

    outerLoop:
    for (const option of options) {
      if (!option.options?.length) {
        continue;
      }
      for (const subOption of option.options) {
        if (subOption[valueKey as keyof IMenuOption] === queryParamValue) {
          selectedOption = subOption as T;
          break outerLoop;
        }
      }
    }

  } else {
    selectedOption = options.find(
      (option) => option[valueKey] as unknown as string === queryParamValue
    );
  }

  if (!selectedOption) {
    return null;
  }

  return selectedOption;
};


export const getMultipleSelectedOptionsByQueryParam = <T extends IMenuOption>(
  queryParamValue: (string | null)[] | null | undefined,
  options: T[] | undefined,
  valueKey: keyof T = 'value'
): T[] => {

  if (!queryParamValue?.length || !options?.length) {
    return [];
  }

  const selectedOptions = options.filter(
    (option) => queryParamValue.includes(option[valueKey] as unknown as string)
  );

  if (!selectedOptions.length) {
    return [];
  }

  return selectedOptions;
};