import { CellProps, HeaderProps } from 'react-table';

export type BodyCellProps<D extends object, V = any, C = void> = CellProps<D, V> & C;

export type HeaderCellProps<D extends object, C = void> =
  React.PropsWithChildren<HeaderProps<D>> & C;

export type SortBy = 'DESC' | 'ASC';

export type SortKeyType = 'string' | 'date' | 'number';

export enum ManualSortingPropsTypes {
  Title = 'title',
  Frequency = 'frequency',
  CreatedAt = 'createdAt',
  Completion = 'completion',
  ScheduledAt = 'scheduledAt',
  ChannelName = 'channel.name',
  TemplateName = 'templateName',
  LaunchedBy = 'createdBy.name',
  WorkspaceName = 'workspace.name',
}

export interface ManualSortingProps {
  sortBy: SortBy;
  sortKey: ManualSortingPropsTypes;
  isSorted: boolean;
  sortKeyType: SortKeyType;
}

export interface ITableRowCheckState {
  updateIsCheckedForRow: (index: number) => void;
}

export interface ITableCheckState {
  allRowsChecked: boolean;
  updateIsCheckedForAllRows: (isChecked: boolean) => void;
}