import Axios, { AxiosObservable } from 'axios-observable';
import {
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IResponse,
  ISimpleMenuOption,
  ISurveyAnalyticOverviewRow,
  ISurveyAnalyticOverviewRowChoice,
  ISurveyAnalyticsOverviewQuestion,
  ISurveyAnalyticsOverviewResponseRow,
  ISurveyAnalyticsSentimentsResponseRow,
  ISurveyAnalyticsTimelineResponseRow,
  ISurveyAnalyticsTimelineRow,
  ISurveyQuestionAnalyticsPoint,
  ISurveyTimelineQuestionOption,
  ISurveyTimelineQuestionResponse,
} from 'interfaces';
import { map } from 'rxjs';
import {
  CUSTOM_SCALE_BAR_COLORS
} from 'teamble-constants';
import { getAnalyticsBarColorByChoiceType, getQuestionTypeLabel } from 'utils';


const getSurveyAnalyticsOverviewRowChoices =
  (
    row: ISurveyAnalyticsOverviewQuestion,
    metaData: IMetadata
  ): ISurveyAnalyticOverviewRowChoice[] => {

    const rowChoices: ISurveyAnalyticOverviewRowChoice[] = [];
    const multiChoiceQuestionTypes = metaData.multiSelectQuestionOptionsTypes.options;
    const multiChoiceQuestionConstants =
      metaData.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

    if (row.count === 0) {
      return rowChoices;
    }

    let choices: ISimpleMenuOption[] | string[];

    if (row.family === multiChoiceQuestionConstants.CUSTOM_ITEMS) {

      choices = row.choices as string[];
    } else {

      const questionType = multiChoiceQuestionTypes.find(
        (option) => option.value === row.family
      );

      if (!questionType) {
        return rowChoices;
      }

      choices = questionType.options as ISimpleMenuOption[];
    }

    const barColors: string[] = getAnalyticsBarColorByChoiceType(
      row.family,
      multiChoiceQuestionConstants
    );

    choices.forEach(
      (choice, index) => {

        const choiceColors = {
          color: '#fff',
          backgroundColor: row.family === multiChoiceQuestionConstants.CUSTOM_ITEMS ?
            barColors[index % CUSTOM_SCALE_BAR_COLORS.length] :
            barColors[index]
        };

        if (row.family === multiChoiceQuestionConstants.CUSTOM_ITEMS) {

          const customChoice = choice as string;
          if (!row.counts || row.counts[customChoice] === 0) {
            return;
          }

          rowChoices.push({
            id: customChoice,
            title: customChoice,
            percentCount: +(row.counts[customChoice] / row.count * 100).toFixed(2),
            ...choiceColors
          });

        } else {

          const choiceOption = choice as ISimpleMenuOption;
          if (!row.counts || row.counts[choiceOption.value] === 0) {
            return;
          }

          rowChoices.push({
            id: choiceOption.value,
            title: choiceOption.label,
            percentCount: +(row.counts[choiceOption.value] / row.count * 100).toFixed(2),
            ...choiceColors
          });

        }

      }
    );

    return rowChoices;

  };



export const getSurveyAnalyticsTimelineChartData =
  (
    surveyTemplateIds: string[],
    isWeekView: boolean,
    isReport: string = '',
  ): AxiosObservable<IResponse<ISurveyAnalyticsTimelineRow[]> | Blob> => {

    return Axios.post<IResponse<ISurveyAnalyticsTimelineResponseRow[]> | Blob>(
      "/survey-analytics-timeline-chart-data",
      {
        surveyTemplateIds,
        isReport,
        viewType: isWeekView ? 'week' : 'month'
      },
      {
        responseType: isReport ? 'arraybuffer' : 'json'
      }
    ).pipe(
      map(
        (response) => {

          if (isReport) {
            return {
              ...response,
              data: response.data as Blob
            };
          }

          const rowData: ISurveyAnalyticsTimelineRow[] = [];

          ((response.data as IResponse<ISurveyAnalyticsTimelineResponseRow[]>).data).forEach(
            (row, index) => {
              if (row.isGroup) {

                rowData.push({
                  ...row,
                  id: row.title + index,
                  isInsideGroup: false,
                  months: row?.months?.map(
                    (month) => ({
                      ...month,
                      backgroundColor: month.color,
                      color: month.fontColor
                    })
                  ) || []
                });

                row.questions.forEach(
                  (question) => {
                    rowData.push({
                      ...question,
                      months: question?.months?.map(
                        (month) => ({
                          ...month,
                          backgroundColor: month.color,
                          color: month.fontColor
                        })
                      ) || [],
                      isGroup: false,
                      isInsideGroup: true
                    });
                  }
                );

              } else {
                rowData.push({
                  ...row,
                  id: row.id as string + index,
                  months: row?.months?.map(
                    (month) => ({
                      ...month,
                      backgroundColor: month.color,
                      color: month.fontColor
                    })
                  ) || [],
                  isInsideGroup: false
                });
              }
            }
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: rowData
            }
          };

        }
      )
    );
  };


export const getSurveyAnalyticsOverviewData =
  (
    libraryIds: string[],
    metaData: IMetadata,
  ): AxiosObservable<IResponse<ISurveyAnalyticOverviewRow[]>> => {

    return Axios.post<IResponse<ISurveyAnalyticsOverviewResponseRow[]>>(
      "/survey-analytics-overview",
      { libraryIds },
    ).pipe(
      map(
        (response) => {

          const rowData: ISurveyAnalyticOverviewRow[] = [];

          response.data.data.forEach(
            (row) => {

              if (row.isGroup) {

                rowData.push(
                  {
                    ...row,
                    id: row.id as string,
                    title: row.group,
                    isInsideGroup: false,
                    choices: getSurveyAnalyticsOverviewRowChoices(row, metaData)
                  }
                );

                row.questions.forEach(
                  (question) => {

                    rowData.push(
                      {
                        ...question,
                        id: question.id as string,
                        title: question.label,
                        isGroup: false,
                        isInsideGroup: true,
                        choices: getSurveyAnalyticsOverviewRowChoices(question, metaData)
                      }
                    );

                  }
                );

              } else {

                rowData.push(
                  {
                    ...row,
                    id: row.id as string,
                    title: row.label,
                    isInsideGroup: false,
                    choices: getSurveyAnalyticsOverviewRowChoices(row, metaData)
                  }
                );

              }

            }
          );

          return {
            ...response,
            data: {
              ...response.data,
              data: rowData
            }
          };

        }
      )
    )

  };


export const getSurveyAnalyticsSentimentsData =
  (libraryIds: string[]): AxiosObservable<IResponse<ISurveyAnalyticsSentimentsResponseRow[]>> => {

    return Axios.post<IResponse<ISurveyAnalyticsSentimentsResponseRow[]>>(
      "/survey-analytics-sentiments",
      { libraryIds },
    )
  };


export const getSurveyAnalyticsTimelineQuestions = (
  surveyTemplateIds: string[],
  multiSelectQuestionTypes: ISimpleMenuOption[]
): AxiosObservable<IResponse<ISurveyTimelineQuestionOption[]>> => {

  return Axios.post<IResponse<ISurveyTimelineQuestionResponse[]>>(
    "/survey/analytics/list-timeline-survey-question",
    { surveyTemplateIds },
  )
    .pipe(
      map(
        (response) => ({
          ...response,
          data: {
            ...response.data,
            data: response.data.data.map(
              (question) => ({
                ...question,
                value: question.id,
                description: getQuestionTypeLabel(question.family, multiSelectQuestionTypes)
              })
            )
          }
        })
      )
    );
};


export const getSurveyAnalyticsQuestionTimelineData = (
  question: ISurveyTimelineQuestionOption
): AxiosObservable<IResponse<ISurveyQuestionAnalyticsPoint[]>> => {

  return Axios.post<IResponse<ISurveyQuestionAnalyticsPoint[]>>(
    "/timeline-chart-data",
    { ...question, blockId: question.id },
  );

};