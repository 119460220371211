import {
  IReportFilters,
  IReportRequestFilters,
  IReportTableRow,
  IUserProfile,
  SignInWith,
} from 'interfaces';
import { SELECT_ALL_OPTION } from 'teamble-constants';

export const getRequestFilters = (reportFilters: IReportFilters): IReportRequestFilters => {

  let requestFilters: IReportRequestFilters = {
    reviewees: [],
    reviewers: [],
    reviews: [],
    departments: []
  };

  Object.keys(reportFilters).forEach(
    (key) => {

      const keyAs = key as keyof IReportFilters;
      const optionIds = reportFilters[keyAs]
        .map((option) => option.value)
        .filter((value) => value !== SELECT_ALL_OPTION.value);

      requestFilters[keyAs] = optionIds;
    }
  );

  return requestFilters;
};


export const getPerformanceReviewIdsOfReportRows =
  (reportTableRow: IReportTableRow): string[] => {

    if (reportTableRow.perfReviewId) {
      return [reportTableRow.perfReviewId];
    }

    const childIds: string[] = [];

    reportTableRow?.children?.forEach(
      (childTablerow) => {
        childIds.push(
          ...getPerformanceReviewIdsOfReportRows(childTablerow)
        );
      }
    );

    return childIds;

  };