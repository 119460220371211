import React from "react";


export const useNameInitials = (name: string): string | null => {

  const [nameInitials, setNameInitials] = React.useState<string | null>(null);

  React.useEffect(
    () => {

      if (!name) {
        setNameInitials(null);
        return;
      }

      const [firstName, lastName] = name.split(' ');
      setNameInitials(`${firstName.charAt(0).toUpperCase()}${lastName ? lastName.charAt(0).toUpperCase() : ''}`);

    },
    [name]
  );

  return nameInitials;
};