import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { FormikProps } from 'formik';
import {
  FormFieldTypeConstants,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IQuestionTypeConstants,
  IReviewChoiceWithValue,
  IReviewCommentsWithValue,
  IReviewTemplateFormValues,
  IReviewTemplateQuestion,
} from 'interfaces';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';


interface IReviewTemplateQuestionProps {
  index: number;
  question: IReviewTemplateQuestion;
}

interface IMultiSelectQuestionChoicesProps extends IReviewTemplateQuestionProps {
  controlNamePrefix: string;
  isCompetencyQuestion?: boolean;
  isObjectiveQuestion?: boolean;
}

const ReviewTemplateTitleQuestion: React.FunctionComponent<IReviewTemplateQuestionProps> = (
  { index }
) => {

  return (
    <FormField
      label=''
      controlId={`titleQuestion-${index}`}
      type={FormFieldTypeConstants.RichText}
      placeholder='Enter title'
      controlName={`blocks[${index}].titleValue.text`} />
  );
};


const ReviewTemplateTextQuestion: React.FunctionComponent<IReviewTemplateQuestionProps> = (
  { index }
) => {

  return (
    <>
      <FormField
        label='Question *'
        controlId={`shortTextQuestionLabel-${index}`}
        type={FormFieldTypeConstants.Textarea}
        placeholder='Enter question'
        controlName={`blocks[${index}].textValue.label`} />

      <FormField
        label='Answer Guidance'
        controlId={`shortTextQuestionDescription-${index}`}
        type={FormFieldTypeConstants.Text}
        placeholder='Enter answer guidance'
        controlName={`blocks[${index}].textValue.placeholder`} />

      <FormField
        label='Required'
        controlId={`shortTextQuestionRequired-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`blocks[${index}].textValue.required`} />
    </>
  );
};


const MultiSelectQuestionChoices: React.FunctionComponent<IMultiSelectQuestionChoicesProps> = (
  { question, index, controlNamePrefix, isCompetencyQuestion = false, isObjectiveQuestion = false }
) => {

  const form = React.useContext(FormContext) as FormikProps<IReviewTemplateFormValues>;

  const valueType = isObjectiveQuestion
    ? 'objectiveValue'
    : isCompetencyQuestion
      ? 'competencyValue'
      : 'multiSelectValue';

  const updateSurveyBlockWithNewChoices = (newChoices: IReviewChoiceWithValue[]): void => {

    const updatedBlock = {
      ...question,
      [valueType]: {
        ...question[valueType],
        choices: newChoices
      }
    };

    const updatedBlocks = [
      ...form.values.blocks.slice(0, index),
      updatedBlock,
      ...form.values.blocks.slice(index + 1)
    ];

    form.setFieldValue(
      'blocks',
      updatedBlocks,
      true
    );
  }


  const addNewChoice = () => {

    const newChoices = [
      ...question[valueType]!.choices,
      {
        value: 0,
        choice: ''
      }
    ];

    updateSurveyBlockWithNewChoices(newChoices as IReviewChoiceWithValue[]);
  };


  const removeChoiceFromIndex = (removeIndex: number): void => {

    const newChoices = [
      ...question[valueType]!.choices.slice(0, removeIndex),
      ...question[valueType]!.choices.slice(removeIndex + 1)
    ];

    updateSurveyBlockWithNewChoices(newChoices as IReviewChoiceWithValue[]);
  }


  return (
    <div className='mb-2'>
      {
        (question[valueType]!.choices as IReviewChoiceWithValue[])?.map(
          (_, choiceIndex) => (
            <div key={choiceIndex} className='d-flex align-items-start justify-content-between'>
              <Row>
                <Col>
                  <FormField
                    label='Choice *'
                    placeholder='Enter choice here'
                    type={FormFieldTypeConstants.Text}
                    controlId={`multiSelectQuestionChoices-${index}-${choiceIndex}`}
                    controlName={`${controlNamePrefix}.choices[${choiceIndex}].choice`}
                  />
                </Col>

                {Boolean(question[valueType]?.numericValue) && (
                  <Col>
                    <FormField
                      minValue={0}
                      label='Choice Value *'
                      placeholder='Enter choice value'
                      type={FormFieldTypeConstants.Number}
                      controlId={`multiSelectQuestionChoicesValue-${index}-${choiceIndex}`}
                      controlName={`${controlNamePrefix}.choices[${choiceIndex}].value`}
                    />
                  </Col>
                )}

              </Row>
              <Button
                size='sm'
                className={`outline-primary ms-2 mt-md ${choiceIndex === 0 ? 'invisible' : ''}`}
                onClick={() => removeChoiceFromIndex(choiceIndex)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          )
        )
      }
      <div className="d-flex justify-content-end">
        <Button size='sm' onClick={addNewChoice}>
          Add New Choice
        </Button>
      </div>
    </div>
  )

};


const ReviewTemplateMultiSelectQuestion: React.FunctionComponent<IReviewTemplateQuestionProps> = (
  { index, question }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;
  const [controlNamePrefix, setControlNamePrefix] = React.useState('');

  const questionTypeConstants: IQuestionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;


  React.useEffect(
    () => {

      if (index === undefined) {
        setControlNamePrefix('');
        return;
      }

      if (question.type === questionTypeConstants.MULTI_SELECT_QUESTION) {
        setControlNamePrefix(`blocks[${index}].multiSelectValue`);
        return;
      }

      if (question.type === questionTypeConstants.COMPETENCY_QUESTION) {
        setControlNamePrefix(`blocks[${index}].competencyValue`);
        return;
      }

      if (question.type === questionTypeConstants.OBJECTIVE_QUESTION) {
        setControlNamePrefix(`blocks[${index}].objectiveValue`);
        return;
      }

    },
    [index, question.type, questionTypeConstants]
  );


  if (index === undefined || !controlNamePrefix) {
    return null;
  }

  return (
    <>

      <FormField
        label='Question *'
        controlId={`multiSelectQuestionLabel-${index}`}
        type={FormFieldTypeConstants.Textarea}
        placeholder='Enter question'
        controlName={`${controlNamePrefix}.label`}
      />

      <FormField
        label='Predefined Options'
        controlId={`multiSelectQuestionOptions-${index}`}
        type={FormFieldTypeConstants.Select}
        options={metadata?.multiSelectQuestionOptionsTypes?.options.map(
          (option) => ({ value: option.value, label: option.label })
        )}
        controlName={`${controlNamePrefix}.selectedOption`}
      />

      {
        question.type === questionTypeConstants.MULTI_SELECT_QUESTION && (
          <MultiSelectQuestion
            index={index}
            question={question}
            controlNamePrefix={controlNamePrefix}
          />
        )
      }

      {
        question.type === questionTypeConstants.COMPETENCY_QUESTION && (
          <CompetencyQuestion
            index={index}
            question={question}
            controlNamePrefix={controlNamePrefix}
          />
        )
      }

      {
        question.type === questionTypeConstants.OBJECTIVE_QUESTION && (
          <ObjectiveQuestion
            index={index}
            question={question}
            controlNamePrefix={controlNamePrefix}
          />
        )
      }

    </>

  );
};


const ReviewTemplateQuestion: React.FunctionComponent<IReviewTemplateQuestionProps> = (
  { index, question }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  let QuestionComponent: React.FunctionComponent<IReviewTemplateQuestionProps> | null = null;
  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  switch (question.type) {

    case questionTypeConstants.TITLE:
    case questionTypeConstants.SUBTITLE: {
      QuestionComponent = ReviewTemplateTitleQuestion;
      break;
    }

    case questionTypeConstants.LONG_TEXT:
    case questionTypeConstants.SHORT_TEXT: {
      QuestionComponent = ReviewTemplateTextQuestion;
      break;
    }

    case questionTypeConstants.COMPETENCY_QUESTION:
    case questionTypeConstants.OBJECTIVE_QUESTION:
    case questionTypeConstants.MULTI_SELECT_QUESTION: {
      QuestionComponent = ReviewTemplateMultiSelectQuestion;
      break;
    }

  }

  if (QuestionComponent) {
    return (
      <QuestionComponent
        index={index}
        question={question}
      />
    );
  }

  return null;
};

export default ReviewTemplateQuestion;


const MultiSelectQuestion: React.FunctionComponent<IMultiSelectQuestionChoicesProps> = (
  { question, index, controlNamePrefix }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const questionTypeConstants: IQuestionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  const isCustomItemQuestion = (
    (question.type === questionTypeConstants.MULTI_SELECT_QUESTION) &&
    (question.multiSelectValue?.selectedOption.value === multiSelectQuestionTypeConstants.CUSTOM_ITEMS)
  )

  return (
    <>

      {isCustomItemQuestion && (
        <FormField
          label='Assign Numeric Values'
          controlId={`multiSelectNumericValue-${index}`}
          type={FormFieldTypeConstants.Checkbox}
          controlName={`${controlNamePrefix}.numericValue`}
        />
      )}

      {
        question.multiSelectValue?.selectedOption.value === multiSelectQuestionTypeConstants.CUSTOM_ITEMS && (
          <MultiSelectQuestionChoices
            question={question}
            index={index}
            controlNamePrefix={controlNamePrefix}
          />
        )
      }

      {
        question.multiSelectValue?.commentBox && (
          <FormField
            label='Comment Box Guideline *'
            controlId={`multiSelectQuestionCommentBox-${index}`}
            type={FormFieldTypeConstants.Text}
            placeholder='Enter Comment Box Guideline'
            controlName={`${controlNamePrefix}.commentBoxGuide`}
          />
        )
      }

      <FormField
        label='Add Comment Box'
        controlId={`multiSelectQuestionComment-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.commentBox`}
      />

      <FormField
        label='Required'
        controlId={`multiSelectQuestionRequired-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.required`}
      />

      <FormField
        label='Dropdown'
        controlId={`multiSelectQuestionDropdown-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.dropdown`}
      />

    </>
  )

}

const CompetencyQuestion: React.FunctionComponent<IMultiSelectQuestionChoicesProps> = (
  { question, index, controlNamePrefix }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const questionTypeConstants: IQuestionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  const isCustomItemQuestion = (
    (question.type === questionTypeConstants.COMPETENCY_QUESTION) &&
    (question.competencyValue?.selectedOption.value === multiSelectQuestionTypeConstants.CUSTOM_ITEMS)
  )

  return (
    <>

      {isCustomItemQuestion && (
        <FormField
          label='Assign Numeric Values'
          controlId={`multiSelectNumericValue-${index}`}
          type={FormFieldTypeConstants.Checkbox}
          controlName={`${controlNamePrefix}.numericValue`}
        />
      )}

      {
        question.competencyValue?.selectedOption.value === multiSelectQuestionTypeConstants.CUSTOM_ITEMS && (
          <MultiSelectQuestionChoices
            index={index}
            question={question}
            isCompetencyQuestion={true}
            controlNamePrefix={controlNamePrefix}
          />
        )
      }

      <FormField
        label='Select View'
        controlId={`multiSelectQuestionOptions-${index}`}
        type={FormFieldTypeConstants.Select}
        options={metadata?.selectedView?.options.map(
          (option) => ({ value: option.value, label: option.label })
        )}
        controlName={`${controlNamePrefix}.selectedView`}
      />

      <FormField
        label='Show Competency Description'
        controlId={`multiSelectCompetencyDescription-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.showDescription`}
      />

      <FormField
        label='Show Competency Behaviours'
        controlId={`multiSelectCompetencyBehaviours-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.showBehaviours`}
      />

      <FormField
        label='Set as Behaviour Question'
        controlId={`multiSelectCompetencyBehavioursQuestion-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.isBehavior`}
      />

      <FormField
        label='Add N/A Option'
        controlId={`multiSelectQuestionNAOption-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.isNotApplicableOption`}
      />

      <FormField
        label='Add Comment Box'
        controlId={`multiSelectQuestionComment-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.commentBox`}
      />

      <FormField
        label='Required'
        controlId={`multiSelectQuestionRequired-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.required`}
      />

      {
        question.type !== questionTypeConstants.COMPETENCY_QUESTION &&
        <FormField
          label='Dropdown'
          controlId={`multiSelectQuestionDropdown-${index}`}
          type={FormFieldTypeConstants.Checkbox}
          controlName={`${controlNamePrefix}.dropdown`}
        />
      }

      {
        question.competencyValue?.commentBox && (
          <FormField
            label='Comment Box Guideline *'
            controlId={`multiSelectQuestionCommentBox-${index}`}
            type={FormFieldTypeConstants.Text}
            placeholder='Enter Comment Box Guideline'
            controlName={`${controlNamePrefix}.commentBoxGuide`}
          />
        )
      }

    </>
  )

}

const ObjectiveQuestion: React.FunctionComponent<IMultiSelectQuestionChoicesProps> = (
  { question, index, controlNamePrefix }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const questionTypeConstants: IQuestionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  const isCustomItemQuestion = (
    (question.type === questionTypeConstants.OBJECTIVE_QUESTION) &&
    (question.objectiveValue?.selectedOption.value === multiSelectQuestionTypeConstants.CUSTOM_ITEMS)
  )

  const form = React.useContext(FormContext) as FormikProps<IReviewTemplateFormValues>;

  const valueType = 'objectiveValue';

  const updateSurveyBlockWithNewComments = (newComments: IReviewCommentsWithValue[]): void => {

    const updatedBlock = {
      ...question,
      [valueType]: {
        ...question[valueType],
        comments: newComments
      }
    };

    const updatedBlocks = [
      ...form.values.blocks.slice(0, index),
      updatedBlock,
      ...form.values.blocks.slice(index + 1)
    ];

    form.setFieldValue(
      'blocks',
      updatedBlocks,
      true
    );
  }

  const addNewComment = () => {

    const newChoices = [
      ...question[valueType]!.comments,
      {
        id: uuidv4().split("-")[0],
        title: '',
        value: ''
      }
    ];

    updateSurveyBlockWithNewComments(newChoices as IReviewCommentsWithValue[]);
  };


  const removeCommentFromIndex = (removeIndex: number): void => {

    const newChoices = [
      ...question[valueType]!.comments.slice(0, removeIndex),
      ...question[valueType]!.comments.slice(removeIndex + 1)
    ];

    updateSurveyBlockWithNewComments(newChoices as IReviewCommentsWithValue[]);
  }

  return (
    <>

      {isCustomItemQuestion && (
        <FormField
          label='Assign Numeric Values'
          controlId={`multiSelectNumericValue-${index}`}
          type={FormFieldTypeConstants.Checkbox}
          controlName={`${controlNamePrefix}.numericValue`}
        />
      )}

      {
        question.objectiveValue?.selectedOption.value === multiSelectQuestionTypeConstants.CUSTOM_ITEMS && (
          <MultiSelectQuestionChoices
            index={index}
            question={question}
            isObjectiveQuestion={true}
            controlNamePrefix={controlNamePrefix}
          />
        )
      }

      <FormField
        label='Select View'
        controlId={`multiSelectQuestionOptions-${index}`}
        type={FormFieldTypeConstants.Select}
        options={metadata?.selectedView?.options.map(
          (option) => ({ value: option.value, label: option.label })
        )}
        controlName={`${controlNamePrefix}.selectedView`}
      />

      {/* <FormField
        label='Show Role Description'
        controlId={`multiSelectCompetencyDescription-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.showDescription`}
      /> */}

      <FormField
        label='Show KPIs'
        controlId={`multiSelectObjectiveKPIs-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.showKPIs`}
      />

      <FormField
        label='Set as KPI Question'
        controlId={`multiSelectObjectiveKPIsQuestion-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.isKPI`}
      />

      <FormField
        label='Add N/A Option'
        controlId={`multiSelectQuestionNAOption-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.isNotApplicableOption`}
      />

      <FormField
        label='Add Comment Box'
        controlId={`multiSelectQuestionComment-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.commentBox`}
      />

      <FormField
        label='Required'
        controlId={`multiSelectQuestionRequired-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`${controlNamePrefix}.required`}
      />

      {/* {
        question.type !== questionTypeConstants.COMPETENCY_QUESTION &&
        <FormField
          label='Dropdown'
          controlId={`multiSelectQuestionDropdown-${index}`}
          type={FormFieldTypeConstants.Checkbox}
          controlName={`${controlNamePrefix}.dropdown`}
        />
      } */}


      {
        question.objectiveValue?.commentBox && (
          <>
            {
              question.objectiveValue?.comments?.map((comment, index) => {
                return (
                  <div key={index} className='d-flex align-items-start justify-content-between'>
                    <div className="flex-grow-1">
                      <FormField
                        label={`${index + 1}. Comment Box Guideline`}
                        controlId={`multiSelectQuestionCommentBox-${index}`}
                        type={FormFieldTypeConstants.Text}
                        placeholder={`${index + 1}. Enter Comment Box Guideline`}
                        controlName={`${controlNamePrefix}.comments[${index}].title`}
                      />
                    </div>
                    <Button
                      size='sm'
                      className={`outline-primary ms-2 mt-md ${index === 0 ? 'invisible' : ''}`}
                      onClick={() => removeCommentFromIndex(index)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                )
              })
            }
            <div className="d-flex justify-content-end">
              <Button size='sm' onClick={addNewComment}>
                Add New Comment
              </Button>
            </div>
          </>
        )
      }

    </>
  )

}

