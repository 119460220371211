import { ISimpleMenuOption } from './menuOptions.interface';


export interface ISettingsFormValues {
  selectedColorTypes: ISimpleMenuOption | null;
}

export enum AnalyticsColorTypes {
  Default = 'default',
  Reverse = 'reverse'
}