import {
  FeedbackInsightTypes,
  GroupFeedbackTypeFilters,
  IChannelMenuOption,
  IFeedbackAnalyticsRequestData,
  IMemberOption,
  IMultiSelectQuestionOptionsTypeConstants,
  IReviewAnalyticsViewFilterFormValues,
  ISimpleMenuOption,
  StartEndDates,
} from 'interfaces';
import {
  CUSTOM_SCALE_BAR_COLORS,
  SCALE_11_BAR_COLORS,
  SCALE_2_BAR_COLORS,
  SCALE_3_BAR_COLORS,
  SCALE_4_BAR_COLORS,
  SCALE_5_BAR_COLORS,
  SELECT_ALL_OPTION,
} from 'teamble-constants';



export const getAnalyticsBarColorByChoiceType =
  (
    type: string,
    typeConstants: IMultiSelectQuestionOptionsTypeConstants
  ): string[] => {

    switch (type) {

      case typeConstants.YES_NO:
      case typeConstants.TRUE_FALSE:
      case typeConstants.CORRECT_INCORRECT:
      case typeConstants.AGREE_DISAGREE_2: {
        return SCALE_2_BAR_COLORS;
      }

      case typeConstants.AGREE_DISAGREE_3: {
        return SCALE_3_BAR_COLORS;
      }

      case typeConstants.AGREE_DISAGREE_4: {
        return SCALE_4_BAR_COLORS;
      }

      case typeConstants.SCORE_1_5: {
        return SCALE_5_BAR_COLORS.slice().reverse();
      }

      case typeConstants.AGREE_DISAGREE_5: {
        return SCALE_5_BAR_COLORS;
      }

      case typeConstants.NPS:
      case typeConstants.LIKELY_UNLIKELY: {
        return SCALE_11_BAR_COLORS;
      }

      case typeConstants.CUSTOM_ITEMS: {
        return CUSTOM_SCALE_BAR_COLORS;
      }

      default: {
        return [];
      }

    }

  };


export const getInsightsFor = (insightType: string, groupFeedbackType: string = ''): string => {

  return insightType === FeedbackInsightTypes.PERSONAL
    ?
    GroupFeedbackTypeFilters.User
    :
    groupFeedbackType
};


export const getFeedbackAnalyticsRequestData = (
  insightType: string,
  insightsFor: string | undefined,
  selectedOrganizations: IChannelMenuOption[],
  organizationIds: string[],
  selectedOrganizationUser: IMemberOption,
  startEndDates: StartEndDates,
  selectedDepartments: ISimpleMenuOption[],
  isReport?: boolean | string,
): IFeedbackAnalyticsRequestData => {

  const teambleUserId = insightType === FeedbackInsightTypes.PERSONAL && selectedOrganizationUser
    ? selectedOrganizationUser.teambleUserId as string : null;

  const selectedGroups = selectedOrganizations
    ?.filter(option => option.value !== SELECT_ALL_OPTION?.value)
    ?.map(val => val.value);

  const teambleTeamIds = insightType === FeedbackInsightTypes.PERSONAL ||
    (
      (
        insightType === FeedbackInsightTypes.DEPARTMENT ||
        insightType === FeedbackInsightTypes.CHANNEL_OR_WORKSPACE
      ) &&
      insightsFor === GroupFeedbackTypeFilters.GroupOnly
    )
    ?
    selectedGroups
    :
    organizationIds;

  const departmentIds = insightType === FeedbackInsightTypes.DEPARTMENT
    ?
    selectedDepartments
      .map((val) => val.value)
      ?.filter(o => o !== SELECT_ALL_OPTION?.value)
    :
    [];

  const selectedGroupsForRequest = insightType === FeedbackInsightTypes.PERSONAL ||
    (
      (
        insightType === FeedbackInsightTypes.DEPARTMENT ||
        insightType === FeedbackInsightTypes.CHANNEL_OR_WORKSPACE
      ) &&
      insightsFor === GroupFeedbackTypeFilters.AllFeedback
    )
    ?
    selectedGroups
    :
    [];


  return {
    teambleUserId,
    teambleTeamIds,
    startDate: startEndDates.startDate.format("YYYY-MM-DD"),
    endDate: startEndDates.endDate.format("YYYY-MM-DD"),
    departmentIds,
    insightsFor,
    selectedGroups: selectedGroupsForRequest,
    isReport
  };

};

export const buildViewQuery = (values: IReviewAnalyticsViewFilterFormValues) => {

  // reviewee values
  const { reviewee, revieweeDepartments, revieweeGroups, revieweeRoles } = values;

  //reviewer values
  const { reviewer, reviewerDepartments, reviewerGroups, reviewerRoles } = values;

  // general
  const { rows, columns, rowsGroupBy, columnsGroupBy, prc, prcQuestions, dataSelector } = values;

  const rowsArray = [];
  const columnsArray = [];

  if (rows?.value) {
    rowsArray.push({ type: rows.value })
  }
  if (rowsGroupBy?.value) {
    rowsArray.push({ type: rowsGroupBy.value })
  }
  if (columns?.value) {
    columnsArray.push({ type: columns.value })
  }
  if (columnsGroupBy?.value) {
    columnsArray.push({ type: columnsGroupBy.value })
  }

  const query = {
    rows: rowsArray,
    columns: columnsArray,
    filters: {}
  };

  const filters: { [key: string]: { type: string, values: string[] } } = {};

  // general filters
  if (values?.prc?.length) {
    filters["prc"] = { type: "in", values: prc.map(d => d.value) }
  }

  if (values?.prcQuestions?.length) {
    filters["prc.questions"] = { type: "in", values: prcQuestions.map(d => d.value) }
  }

  dataSelector.forEach((data, index) => {

    // reviewee filters
    if (data.value === "reviewee" && values?.reviewee?.length) {
      filters[data.value] = { type: "in", values: reviewee.map(d => d.value) }
    }
    if (data.value === "reviewee.department" && values?.revieweeDepartments?.length) {
      filters[data.value] = { type: "in", values: revieweeDepartments.map(d => d.value) }
    }
    if (data.value === "reviewee.role" && values?.revieweeRoles?.length) {
      filters[data.value] = { type: "in", values: revieweeRoles.map(d => d.value) }
    }
    if (data.value === "reviewee.group" && values?.revieweeGroups?.length) {
      filters[data.value] = { type: "in", values: revieweeGroups.map(d => d.value) }
    }

    // reviewer filters
    if (data.value === "reviewer" && values?.reviewer?.length) {
      filters[data.value] = { type: "in", values: reviewer.map(d => d.value) }
    }
    if (data.value === "reviewer.department" && values?.reviewerDepartments?.length) {
      filters[data.value] = { type: "in", values: reviewerDepartments.map(d => d.value) }
    }
    if (data.value === "reviewer.role" && values?.reviewerRoles?.length) {
      filters[data.value] = { type: "in", values: reviewerRoles.map(d => d.value) }
    }
    if (data.value === "reviewer.group" && values?.reviewerGroups?.length) {
      filters[data.value] = { type: "in", values: reviewerGroups.map(d => d.value) }
    }
  });

  query.filters = filters;

  return query;

}