import { Spinner } from '@themesberg/react-bootstrap';
import React, { createContext, useContext, ReactNode } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

interface IFrameProps {
  embed: boolean,
  redirect: string;
}

interface FrameContextType {
  frameState: IFrameProps;
  setFrameState: React.Dispatch<React.SetStateAction<IFrameProps>>;
  postFrameMessage: (message: string) => void;
}

const FrameContext = createContext<FrameContextType | undefined>(undefined);

export const FrameProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [frameState, setFrameState] = React.useState<IFrameProps>({ embed: false, redirect: "" });
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  React.useEffect(() => {

    // setting external token
    const externalToken = query.get('token');

    if (externalToken) {
      localStorage.setItem("token", externalToken);
      setFrameState({ ...frameState, embed: true });
    }

    if (search) {
      const redirect = search?.split('redirect=')?.[1];
      if (redirect) {
        history.replace(redirect);
      }
    }

    setTimeout(() => setLoading(false), 1000);

  }, []);

  function postFrameMessage(_message: string) {
    console.log("called");
    const message = { type: 'FROM_CHILD', data: null };
    window.parent.postMessage(message, process.env.REACT_APP_TEAMS_DOMAIN as string);
    console.log("called 2", message);
  }

  if (loading) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <Spinner animation="border" role="status" size='sm'>
          <span className="visually-hidden">Loading Teamble...</span>
        </Spinner>
      </div>
    )
  }

  return (
    <FrameContext.Provider value={{ frameState, setFrameState, postFrameMessage }}>
      {children}
    </FrameContext.Provider>
  );
};


export const useFrameContext = () => {
  const context = useContext(FrameContext);
  if (!context) {
    throw new Error('useFrameContext must be used within a FrameProvider');
  }
  return context;
};