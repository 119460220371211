import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faClone,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, Card, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { MetaDataContext } from 'contexts';
import {
  IFormQuestionValue,
  IMetadata,
  IQuestionBuilderFormType,
  IQuestionTypeConstants,
} from 'interfaces';
import * as React from 'react';

import Question from './Question';
import { useQuestionBuilder } from './QuestionBuilder';

interface IQuestionWidgetProps {
  index: number;
  question: IFormQuestionValue;
}

const QuestionWidget: React.FunctionComponent<IQuestionWidgetProps> = (
  { index, question }
) => {

  const [subTitle, setSubTitle] = React.useState<string>('');

  const subtitleRef = React.useRef<HTMLDivElement>(null);
  const questionRef = React.useRef<HTMLDivElement>(null);

  const metadata = React.useContext(MetaDataContext) as IMetadata;
  const questionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  const {
    previewQuestionKey,
    onQuestionActionClick,
    onPreviewQuestionKeyChange
  } = useQuestionBuilder<IQuestionBuilderFormType>();


  React.useEffect(
    () => {

      switch (question.type) {

        case questionTypeConstants.TITLE:
        case questionTypeConstants.SUBTITLE: {
          setSubTitle(question.titleValue?.text || '');
          break;
        }

        case questionTypeConstants.LONG_TEXT:
        case questionTypeConstants.SHORT_TEXT: {
          setSubTitle(question.textValue?.label || '');
          break;
        }

        case questionTypeConstants.MULTI_SELECT_QUESTION: {
          setSubTitle(question.multiSelectValue?.label || '');
          break;
        }

      }
    },
    [question, questionTypeConstants]
  );


  React.useEffect(
    () => {
      if (previewQuestionKey === null) {
        return;
      }

      let timeoutId: ReturnType<typeof setTimeout>;
      if (previewQuestionKey === index) {

        timeoutId = setTimeout(() => {
          questionRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 500);
      }

      return () => {
        clearTimeout(timeoutId);
      }
    },
    [index, previewQuestionKey]
  );



  const handleHeaderClick = (): void => {

    if (previewQuestionKey === index) {
      onPreviewQuestionKeyChange(null);
    } else {
      onPreviewQuestionKeyChange(index);
    }

  };


  return (
    <div ref={questionRef}>
      <div
        ref={subtitleRef}
        className='d-none'
        dangerouslySetInnerHTML={{ __html: subTitle }}>
      </div>
      <Card className='mb-3'>

        <Card.Header
          className='d-flex justify-content-between align-items-center cursor-pointer bg-light py-2 px-3'
          onClick={handleHeaderClick}
        >
          <div
            className='d-flex align-items-center flex-grow-1'
            style={{ maxWidth: 'calc(100% - 150px)' }}
          >
            <Card.Title className='m-0 me-3 fs-6 fw-bolder'> {question.typeTitle} </Card.Title>
            <Card.Subtitle className='m-0 text-truncate fw-normal' style={{
              maxWidth: 'calc(100% - 95px)',
            }}>
              {subtitleRef.current ? subtitleRef.current.innerText : ''}
            </Card.Subtitle>
          </div>

          <div className='d-flex flex-nowrap'>

            <OverlayTrigger
              overlay={
                <Tooltip id='moveDownTooltip'>Move Down</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onQuestionActionClick('moveDown', index);
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='move-up-tooltip'>Move Up</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onQuestionActionClick('moveUp', index);
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='duplicate-tooltip'>Duplicate</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onQuestionActionClick('duplicate', index);
                }}
              >
                <FontAwesomeIcon icon={faClone} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='delete-tooltip'>Delete</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onQuestionActionClick('delete', index);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='collapse-expand-tooltip'>
                  {previewQuestionKey === index ? 'Collapse' : 'Expand'}
                </Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                className='ms-1'>
                <FontAwesomeIcon icon={previewQuestionKey === index ? faChevronUp : faChevronDown} />
              </Button>
            </OverlayTrigger>

          </div>
        </Card.Header>
        <Accordion.Collapse eventKey={index.toString()}>
          <Card.Body>
            <Question
              index={index}
              question={question} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default QuestionWidget;
