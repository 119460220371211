import React, { useState } from "react";
import "./CraftFeedBackModal.scss";
import HeaderText from "../HeaderText/HeaderText";
import CloseIcon from "../SvgReactIcons/CloseIcon";
import CustomTextArea from "../CustomTextArea/CustomTextArea";
import LoaderAiIcon from "../SvgReactIcons/LoaderAiIcon";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import GradientPrimaryButton from "../GradiantPrimaryButton/GradientPrimaryButton";
import { createTeambleAiOneOnOneFeedback } from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";

interface CraftFeedBackModalProps {
  close: () => void;
  oneOnOneId: string;
}

const CraftFeedBackModal: React.FC<CraftFeedBackModalProps> = ({
  close,
  oneOnOneId,
}: any) => {
  const [generating, setGenerating] = useState(false);
  const [value, setValue] = useState("");
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.key === "Escape") {
        close?.();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenerate = () => {
    if (!value || !oneOnOneId) return;
    setGenerating(true);
    createTeambleAiOneOnOneFeedback(value, oneOnOneId)
      .pipe(finalize(() => setGenerating(false)))
      .subscribe((response: any) => {
        history.push(
          `${location.pathname}/prepare-feedback/${response?.data?.aiFeedback?.id}`
        );
        setGenerating(false);
      });
  };
  return (
    <div className="CraftFeedBackModal">
      <div className="CraftFeedBack">
        <div className="header-container">
          <HeaderText
            text={"Help me craft feedback"}
            fontSize={"24px"}
            lineHeight={"24px"}
            letterSpacing={"-0.24px"}
          />
          <div className="close">
            <CloseIcon
              onClick={() => {
                close?.();
              }}
            />
          </div>
        </div>
        {generating ? (
          <div className="generating-loader">
            <LoaderAiIcon />
            <div className="loader-text">Generating...</div>
          </div>
        ) : (
          <div className="craft-feedback">
            <div className="feedback-text">
              <div className="feedback-text-area">
                <CustomTextArea
                  setValue={setValue}
                  value={value}
                  placeholder={
                    "Anything specific you would like to start with"
                  }
                />
              </div>
              <div className="tab-buttons">
                {/* <AiTagButton
                                    name={'Draft me a first version'}
                                />
                                <AiTagButton name={'Write about Taylor'} /> */}
              </div>
            </div>
            <div className="generate-feedback-container">
              <GradientPrimaryButton
                onClick={handleGenerate}
                text="Generate feedback"
                disabled={value.length===0}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CraftFeedBackModal;
