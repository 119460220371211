import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { CollapsibleCard, FormField } from 'components';
import { FormContext } from 'contexts';
import { updateFeedbackLoopRequestTtl } from 'data';
import { useFormik } from 'formik';
import { FormFieldTypeConstants, IRequestTimeoutFormValues, IRequestTimeoutRequestData } from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { SCHEDULER_VALIDATION_SCHEMA } from 'validation-schemas';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import moment from 'moment';

interface IFeedbackLoopSchedulerWidgetProps { }


const FeedbackLoopRequestTimeoutWidget: React.FC<IFeedbackLoopSchedulerWidgetProps> = () => {

  // State Hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Feedback Loop Data
  const { feedbackLoop, onFeedbackLoopUpdate } = useFeedbackLoopConfig();

  // Form Hook
  const requestTimeoutConfigForm = useFormik<IRequestTimeoutFormValues>({
    initialValues: {
      ttl: feedbackLoop.ttl ? new Date(feedbackLoop.ttl) : new Date(moment().add(2, 'weeks').valueOf())
    },
    onSubmit: () => { }
  });


  // Make an API call to update the feedback loop scheduler.
  React.useEffect(() => {

    if (!requestTimeoutConfigForm.values.ttl) {
      return;
    }

    const requestData: IRequestTimeoutRequestData = {
      feedbackLibraryLoopId: feedbackLoop.id,
      ttl: moment(requestTimeoutConfigForm.values.ttl).utc().valueOf(),
      timezone: -(new Date().getTimezoneOffset() * 60 * 1000),
    };

    setIsLoading(true);
    const subscription = updateFeedbackLoopRequestTtl(requestData)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          onFeedbackLoopUpdate(response.data.data);
          toast.success(response.data.message);
        }
      );

    return () => subscription.unsubscribe();

  }, [
    feedbackLoop.id,
    requestTimeoutConfigForm.isValid,
    requestTimeoutConfigForm.values,
    onFeedbackLoopUpdate
  ]);


  const handleScheduleClear = (): void => {

    setIsLoading(true);
    updateFeedbackLoopRequestTtl({
      feedbackLibraryLoopId: feedbackLoop.id,
      ttl: undefined,
      timezone: -(new Date().getTimezoneOffset() * 60 * 1000),
    })
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          onFeedbackLoopUpdate(response.data.data);
          requestTimeoutConfigForm.setValues({
            ttl: undefined,
          });
          requestTimeoutConfigForm.setTouched({
            ttl: false,
          });
        }
      );

  }


  return (
    <CollapsibleCard
      title='Deadline'
      isLoading={isLoading}
    >
      <Form>
        <FormContext.Provider value={requestTimeoutConfigForm}>
          <Card.Body>
            <p>All the feedback request will time-out in specified time</p>
            <Row>
              <Col>
                <FormField
                  label='Select Deadline'
                  isDisabled={isLoading}
                  controlId={`ttl`}
                  type={FormFieldTypeConstants.Datepicker}
                  controlName='ttl'
                />
              </Col>
              <Col>

              </Col>
            </Row>

          </Card.Body>

          <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
            <Button
              type='button'
              size='sm'
              variant='danger'
              disabled={isLoading}
              className='me-2'
              onClick={handleScheduleClear}> Clear Schedule </Button>
          </Card.Footer>
        </FormContext.Provider>
      </Form>
    </CollapsibleCard>
  );
};

export default FeedbackLoopRequestTimeoutWidget;