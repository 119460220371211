import React from "react";
import "./TertiaryButton.scss";

interface TertiaryButtonProps {
  text: string;
  onClick?: () => void;
}
const TertiaryButton: React.FC<TertiaryButtonProps> = ({ text, onClick }) => {
  return (
    <button
      onClick={() => {
        onClick?.();
      }}
      className="TertiaryButton"
    >
      {text}
    </button>
  );
};

export default TertiaryButton;
