import { PageTitle, PrimaryLayoutBox } from 'components';
import { IReportFilters } from 'interfaces';
import * as React from 'react';
import { Routes } from 'routes';

import FiltersCard from './components/FiltersCard';
import ReportsTableWidget from './components/ReportsTableWidget';

interface IPerformanceReviewReportsProps {
}

const PerformanceReviewReports: React.FunctionComponent<IPerformanceReviewReportsProps> = (props) => {

  const [selectedFilters, setSelectedFilters] = React.useState<IReportFilters>({
    reviews: [],
    reviewers: [],
    reviewees: [],
    departments: []
  });

  return (
    <>
      <PageTitle title={Routes.PerformanceReviewReports.title} />
      <PrimaryLayoutBox title={Routes.PerformanceReviewReports.title}>
        <div className={`overflow-hidden flex-grow-1 d-flex flex-column `}>
          <FiltersCard
            selectedFilters={selectedFilters}
            onSelectedFiltersChange={setSelectedFilters}
          />
          <ReportsTableWidget
            selectedFilters={selectedFilters}
          />
        </div>
      </PrimaryLayoutBox >
    </>
  );
};

export default PerformanceReviewReports;
