import { Col, Row } from '@themesberg/react-bootstrap';
import { DataState, PageTitle, PrimaryLayoutBox } from 'components';
import { useFeedbackForAudiencePair } from 'data';
import { IFeedbackLoop, IRouteParams } from 'interfaces';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Routes } from 'routes';

import FeedbackLoopConfigWidget from '../components/FeedbackLoopConfigWidget';
import FeedbackLoopPreviewWidget from '../components/FeedbackLoopPreviewWidget';
import { FeedbackLoopConfigProvider } from '../contexts/feedbackLoopConfigContext';
import { useFeedbackLoopBuilderData } from '../hooks/useFeedbackLoopBuilderData';

interface IFeedbackLoopConfigPageProps { }

const FeedbackLoopConfigPage: React.FC<IFeedbackLoopConfigPageProps> = () => {

  const [audienceUpdatedCount, setAudienceUpdatedCount] = React.useState<number>(0);
  const { feedbackLibraryLoopId } = useParams<IRouteParams>();

  // Data hooks
  const {
    isLoading, feedbackLoop, showEmptyState,
    onFeedbackLoopUpdate
  } = useFeedbackLoopBuilderData(undefined, feedbackLibraryLoopId);


  const {
    isLoading: isFeedbackForAudiencePairsLoading,
    data: feedbackForAudiencePairs
  } = useFeedbackForAudiencePair(
    feedbackLoop?.id,
    feedbackLoop?.feedbackFor,
    feedbackLoop?.feedbackType,
    audienceUpdatedCount,
  );


  return (
    <>
      <PageTitle title={Routes.FeedbackLoopConfigPage.title} />
      <PrimaryLayoutBox
        title={Routes.FeedbackLoopConfigPage.title}
        description='Configure your feedback loop here'
      >
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!(!showEmptyState && feedbackLoop)}
          emptyStateMessage={<>No Feedback Loop details available.</>}
        >
          <FeedbackLoopConfigProvider
            feedbackLoop={feedbackLoop as IFeedbackLoop}
            audienceUpdatedCount={audienceUpdatedCount}
            feedbackForAudiencePairs={feedbackForAudiencePairs}
            isFeedbackForAudiencePairsLoading={isFeedbackForAudiencePairsLoading}
            onFeedbackLoopUpdate={onFeedbackLoopUpdate}
            onAudienceUpdatedCount={setAudienceUpdatedCount}
          >
            <Row className='overflow-hidden'>
              <Col className='h-100'>
                <FeedbackLoopConfigWidget />
              </Col>
              <Col className='h-100'>
                <FeedbackLoopPreviewWidget />
              </Col>
            </Row>
          </FeedbackLoopConfigProvider>
        </DataState>
      </PrimaryLayoutBox>
    </>
  );
};

export default FeedbackLoopConfigPage;