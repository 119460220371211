import { Divider, EmptyState, FormField, Preloader } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { getCompetenciesList, getObjectiveList } from 'data';
import { Col, Form } from '@themesberg/react-bootstrap';
import { useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  FormErrors,
  FormFieldTypeConstants,
  ICompetency,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IObjectiveQuestion,
  IQuestionTypeConstants,
  IReviewTemplateCompetencyQuestionValue,
  IReviewTemplateQuestion,
  ISimpleMenuOption,
} from 'interfaces';
import * as React from 'react';
import { finalize } from 'rxjs';
import { MULTI_SELECT_QUESTION_NA_OPTION } from 'teamble-constants';
import * as Yup from 'yup';
import { ComptencyQuestionsContext } from '../context/ReviewComptencyQuestionContext';
import { getMultiSelectChoices } from '../utils/utils';
import KPIWidget from './deloitte/KPIWidget';


interface IReviewTemplateQuestionPreviewProps {
  question: IReviewTemplateQuestion;
  index?: number | string;
}

interface IReviewTemplateMultiSelectQuestionPreviewProps extends IReviewTemplateQuestionPreviewProps {
  competency?: ICompetency;
  objective?: IObjectiveQuestion;
  labelPostFix?: string;
  isMatrix?: boolean;
}

interface IReviewTemplateBehaviorQuestionPreviewProps extends IReviewTemplateQuestionPreviewProps {
  competencies?: ICompetency[];
  objectives?: IObjectiveQuestion[];
  isMatrix: boolean;
}

interface ITextAnswer {
  required?: boolean;
  [key: string]: string | boolean | undefined;
}

interface IMultiSelectAnswer {
  required?: boolean;
  commentBoxText?: string;
  commentBox?: boolean;
  dropdown?: boolean;
  [key: string]: ISimpleMenuOption | string | boolean | undefined | null;
}

const ReviewTemplateTitleQuestionPreview: React.FunctionComponent<IReviewTemplateQuestionPreviewProps> = (
  { question }
) => {

  return (
    question.titleValue ?
      <p dangerouslySetInnerHTML={{ __html: question.titleValue.text }}></p>
      : null
  );
};


const ReviewTemplateTextQuestionPreview: React.FunctionComponent<IReviewTemplateQuestionPreviewProps> = (
  { question, index }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  const validationSchema: Yup.SchemaOf<ITextAnswer> = Yup.object(
    {
      ['answer' + index]: Yup.string()
        .when(
          'required', {
          is: true,
          then: Yup.string().required(FormErrors.Required),
          otherwise: Yup.string()
        }),
      required: Yup.boolean(),
    }
  );

  const textForm = useFormik<ITextAnswer>({
    initialValues: {
      ['answer' + index]: '',
      required: question.textValue?.required
    },
    validationSchema,
    onSubmit: () => { }
  });


  React.useEffect(
    () => {
      textForm.setFieldValue('required', question.textValue?.required);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question]
  );


  return (
    <FormContext.Provider value={textForm}>
      <FormField
        size='lg'
        label={`${`${question.textValue?.label}:`}${question.textValue?.label && question?.textValue?.required ? '' : ' (optional)'}` || ''}
        controlId={`titleQuestion-${question.textValue?.label}`}
        type={question.type === questionTypeConstants.SHORT_TEXT ? FormFieldTypeConstants.Text : FormFieldTypeConstants.Textarea}
        controlName={`answer${index}`}
        placeholder={question.textValue?.placeholder} />
    </FormContext.Provider>
  );
};


const ReviewTemplateCompetencyQuestionDescription: React.FunctionComponent<IReviewTemplateMultiSelectQuestionPreviewProps> = (
  { question, competency }
) => {

  return (
    <>
      {
        !question.competencyValue?.isBehavior ?
          <>
            {
              question.competencyValue?.showDescription && competency
                ?
                <p> {competency.description} </p>
                :
                null
            }
            {
              question.competencyValue?.showBehaviours && competency?.behaviours.length
                ?
                <ul className='ps-3'>
                  {
                    competency.behaviours.map(
                      (beh) => (
                        <li key={beh.id}>{beh.name}</li>
                      )
                    )
                  }
                </ul>
                :
                null
            }
          </>
          :
          null
      }
    </>
  );

};

const ReviewTemplateQuestionDescription: React.FunctionComponent<IReviewTemplateMultiSelectQuestionPreviewProps> = (
  { question, competency, objective }
) => {

  let descriptionComponent = null;

  switch (question.type) {
    case 'competency-question':
      if (question?.competencyValue?.showBehaviours && competency?.behaviours?.length) {
        descriptionComponent = (
          <ul className='ps-3'>
            {
              competency.behaviours.map(
                (beh) => (
                  <li key={beh?.id}>{beh?.name}</li>
                )
              )
            }
          </ul>
        )
      }
      break;

    case 'objective-question':

      if (question?.objectiveValue?.showKPIs && objective?.kpis.length) {

        let role = '';

        if (objective.name) {
          role += objective.name;
          if ((objective as any).weight) {
            role += ` | ${(objective as any).weight}%`;
          }
        }

        descriptionComponent = (
          <KPIWidget
            role={role}
            docType={(objective as any).docType}
            kpis={objective?.kpis}
            index={0}
          />
        )
      }

      break;

    default:
      break;
  }

  return (descriptionComponent);

}


const ReviewTemplateMultiSelectQuestionPreview: React.FunctionComponent<IReviewTemplateMultiSelectQuestionPreviewProps> = (
  { question, competency, objective, index = 0, labelPostFix, isMatrix }
) => {

  const app = useApp();
  const isCompetency = question?.type === 'competency-question';

  const [choices, setChoices] = React.useState<Partial<IMenuOption>[] | null>(null);

  const metadata = React.useContext(MetaDataContext) as IMetadata;
  const ComptencyQuestions = React.useContext(ComptencyQuestionsContext);

  const multiSelectChoicesConstants: IMultiSelectQuestionOptionsTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const questionTypeConstants: IQuestionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  let controlKey = '' as 'multiSelectValue' | 'competencyValue' | 'objectiveValue'

  switch (question.type) {

    case questionTypeConstants.MULTI_SELECT_QUESTION:
      controlKey = 'multiSelectValue'
      break;

    case questionTypeConstants.COMPETENCY_QUESTION:
      controlKey = 'competencyValue'
      break;

    case questionTypeConstants.OBJECTIVE_QUESTION:
      controlKey = 'objectiveValue'
      break;

    default:
      break;
  }

  const validationSchema: Yup.SchemaOf<IMultiSelectAnswer> = Yup.object(
    {
      ['radioAnswer' + index]: Yup.string()
        .when(
          'required', {
          is: true,
          then: Yup.string().required(FormErrors.Required),
          otherwise: Yup.string()
        }),

      ['dropdownAnswer' + index]: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string().defined(),
      }).nullable()
        .when(
          'required', {
          is: true,
          then: Yup.object().shape({
            value: Yup.string(),
            label: Yup.string().defined(),
          }).nullable().required(FormErrors.Required),
          otherwise: Yup.object().shape({
            value: Yup.string(),
            label: Yup.string().defined(),
          }).nullable()
        }),

      commentBoxText: Yup.string()
        .when(
          'commentBox', {
          is: true,
          then: Yup.string().required(FormErrors.Required),
          otherwise: Yup.string()
        }),
      commentBox: Yup.boolean(),
      required: Yup.boolean(),
      dropdown: Yup.boolean(),
    }
  );

  const multiSelectForm = useFormik<IMultiSelectAnswer>({
    initialValues: {
      ['radioAnswer' + index]: '',
      ['dropdownAnswer' + index]: null,
      commentBoxText: '',
      required: question[controlKey]?.required,
      commentBox: question[controlKey]?.commentBox,
      numericValue: Boolean(question[controlKey]?.numericValue)
    },
    validationSchema,
    onSubmit: () => { }
  });


  // Set dependent form field
  React.useEffect(
    () => {

      const isNotApplicableOption = (question.type === questionTypeConstants.COMPETENCY_QUESTION || question.type === questionTypeConstants.OBJECTIVE_QUESTION) ?
        (question[controlKey] as IReviewTemplateCompetencyQuestionValue)?.isNotApplicableOption :
        false;

      if (multiSelectChoicesConstants && question[controlKey]?.selectedOption.value === multiSelectChoicesConstants.CUSTOM_ITEMS) {

        const options: Partial<IMenuOption>[] | null = getMultiSelectChoices(app, question[controlKey]?.choices)

        setChoices(
          isNotApplicableOption
            ?
            options
              ?
              [...options, MULTI_SELECT_QUESTION_NA_OPTION]
              :
              null
            :
            options
        );

      } else {

        const options = metadata?.multiSelectQuestionOptionsTypes.options.find(
          (option) => option.value === question[controlKey]?.selectedOption.value
        );

        if (options && options.options) {
          setChoices(
            isNotApplicableOption
              ?
              [...options.options, MULTI_SELECT_QUESTION_NA_OPTION]
              :
              options.options
          );
        }
      }

      ComptencyQuestions?.setCompetencyDetails({ choices, ...multiSelectForm?.values })
      multiSelectForm.setFieldValue('required', question[controlKey]?.required);
      multiSelectForm.setFieldValue('commentBox', question[controlKey]?.commentBox);
      multiSelectForm.setFieldValue('radioAnswer' + index, '');
      multiSelectForm.setFieldValue('dropdownAnswer' + index, null);

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [app, controlKey, index, metadata, multiSelectChoicesConstants, question]
  );

  React.useEffect(() => {
    if (choices?.length)
      ComptencyQuestions?.setCompetencyDetails({
        choices,
        ...multiSelectForm?.values,
        dropdown: question[controlKey]?.dropdown
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices, question[controlKey]?.dropdown])

  const labelDropdown = `${!isCompetency ? `${question[controlKey]?.label}:` : ''} ${labelPostFix ? `${labelPostFix}:` : ''} ${!isMatrix ? question[controlKey]?.required ? '' : '(optional)' : ''}`;
  const labelNonDropdown = `${isMatrix ? "" : `${!isCompetency ? question[controlKey]?.label : ''}` || ''}${!isMatrix && !isCompetency ? ':' : ''} ${labelPostFix ? `${labelPostFix}:` : ''} ${!isMatrix ? question[controlKey]?.required ? '' : '(optional)' : ''} `

  return (
    <FormContext.Provider value={multiSelectForm}>
      <>
        {
          (
            (!isCompetency && question[controlKey]?.dropdown) ||
            (question[controlKey] as any)?.selectedView?.value === 'dropdown') ?
            (
              <FormField
                size='lg'
                description={
                  <ReviewTemplateQuestionDescription
                    question={question}
                    competency={competency}
                    objective={objective}
                    index={index}
                  />
                }
                label={labelDropdown}
                controlId='multiSelectQuestionField'
                type={FormFieldTypeConstants.Select}
                controlName={`dropdownAnswer${index}`}
                placeholder='Pick an option'
                options={choices || []} />
            ) :
            (
              <FormField
                size='lg'
                description={
                  <ReviewTemplateQuestionDescription
                    question={question}
                    competency={competency}
                    objective={objective}
                    index={index}
                  />
                }
                label={labelNonDropdown}
                type={FormFieldTypeConstants.Radio}
                controlName={`answer${index}`}
                isRadioMatrixView={isMatrix}
                options={choices || []} />
            )
        }
      </>
      {
        (question.type === questionTypeConstants.MULTI_SELECT_QUESTION || question.type === questionTypeConstants.COMPETENCY_QUESTION) && question[controlKey]?.commentBox &&
        <FormField
          size='lg'
          label={''}
          placeholder={question[controlKey]?.commentBoxGuide}
          controlId='multiSelectQuestionComment'
          type={FormFieldTypeConstants.Textarea}
          controlName={`commentBoxText`} />
      }
      {
        question?.objectiveValue?.commentBox && Boolean(question?.objectiveValue?.comments) && (
          question.objectiveValue.comments.map((comment, commentIndex) => {
            return (
              <FormField
                label='Comment'
                placeholder={comment.title}
                controlId={`multiSelectQuestionComment-${comment.id}`}
                type={FormFieldTypeConstants.Textarea}
                controlName={`answers[${index}].comments[${commentIndex}].value`}
                textValueChangeDebounceTime={350}
              />
            )
          })
        )
      }
    </FormContext.Provider>
  );
};


const ReviewTemplateBehaviorQuestionPreview: React.FunctionComponent<IReviewTemplateBehaviorQuestionPreviewProps> = (
  { question, index, competencies, objectives, isMatrix }
) => {

  if (question.type === "competency-question") {
    return (
      <>
        {
          competencies?.flatMap(
            (competency) => (
              <React.Fragment key={competency?.id}>
                <h5>{competency.name}</h5>
                {
                  question.competencyValue?.showDescription && competency
                    ?
                    <p style={isMatrix ? { minWidth: 300, width: 300 } : {}}> {competency.description} </p>
                    :
                    null
                }
                {
                  competency.behaviours.map(
                    (beh, i) => (
                      <ReviewTemplateMultiSelectQuestionPreview
                        key={`${competency.id}${beh.id}${i}`}
                        index={`${competency.id}${i}`}
                        question={question}
                        competency={competency}
                        labelPostFix={beh.name}
                        isMatrix={isMatrix}
                      />
                    )
                  )
                }
                <Divider />
              </React.Fragment>
            )
          )
        }
      </>
    );

  } else if (question.type === "objective-question") {
    return (
      <>
        {
          objectives?.map(
            (objective) => (
              <React.Fragment key={objective?.name}>
                <h5>{objective.name}</h5>
                {
                  objective?.kpis?.map(
                    (kpi, i) => (
                      <ReviewTemplateMultiSelectQuestionPreview
                        key={`${objective.name}${kpi.name}${i}`}
                        index={`${objective.name}${i}`}
                        question={question}
                        objective={objective}
                        labelPostFix={kpi.name}
                        isMatrix={isMatrix}
                      />
                    )
                  )
                }
                <Divider />
              </React.Fragment>
            )
          )
        }
      </>
    );
  }

  return null;
};



const ReviewTemplateCompetencyQuestionPreview: React.FunctionComponent<IReviewTemplateQuestionPreviewProps> = (
  { question, index }
) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const isMatrix = question?.competencyValue?.selectedView?.value === 'matrix';
  const isCompetency = question?.type === 'competency-question';

  const [competenciesList, setCompetenciesList] = React.useState<ICompetency[]>([]);
  const [competencyDetails, setCompetencyDetails] = React.useState<any>(null);

  const CompetencyHeader = React.useCallback(() => {
    return (
      <>
        {isMatrix &&
          <Col className='w-100 d-flex bg-white' style={{ top: '-20px', zIndex: 0 }}>
            <Form.Label style={{ minWidth: 300, width: 300 }} className={`fw-bolder ws-pre-line mr-2 text-start pt-2`}>{`${`${question?.competencyValue?.label}:`} ${question?.competencyValue?.required ? '' : '(optional)'}`}</Form.Label>
            {
              competencyDetails?.choices?.map((c: any, index: number) => (
                <Form.Label key={`${index}${c}`} style={{ minWidth: 150, width: 150 }} className={`fw-bolder ws-pre-line pt-2 mr-2 text-center bg-white`}>{c?.label || ' '}</Form.Label>
              ))
            }
          </Col>
        }
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competencyDetails, isMatrix, question])

  React.useEffect(
    () => {

      setIsLoading(true);
      const sub = getCompetenciesList()
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setCompetenciesList(response.data.data.slice(0, 5));
          }
        );

      return () => sub.unsubscribe();
    },
    []
  )

  return (
    <ComptencyQuestionsContext.Provider value={{ competencyDetails, setCompetencyDetails }}>
      {
        isLoading
          ?
          <Preloader show={isLoading} />
          :
          competenciesList.length > 0
            ?
            question.competencyValue?.isBehavior
              ?
              <>
                <CompetencyHeader />
                {
                  (isCompetency && !isMatrix) &&
                  <h5 style={isMatrix ? { minWidth: 300, width: 300 } : {}}>{question?.competencyValue?.label}:</h5>
                }
                <ReviewTemplateBehaviorQuestionPreview
                  index={index}
                  question={question}
                  competencies={competenciesList}
                  isMatrix={isMatrix}
                />
              </>

              :
              <>
                <CompetencyHeader />
                {
                  (isCompetency && !isMatrix) &&
                  <h5 style={isMatrix ? { minWidth: 300, width: 300 } : {}}>{question?.competencyValue?.label}:</h5>
                }
                {
                  competenciesList.map(
                    (competency, i) => (
                      <ReviewTemplateMultiSelectQuestionPreview
                        key={competency.id}
                        index={i}
                        question={question}
                        competency={competency}
                        labelPostFix={competency.name}
                        isMatrix={isMatrix}
                      />
                    )
                  )
                }
              </>
            :
            <EmptyState message={
              <>
                No competencies available.
                <br />
                Add a new one!
              </>
            } />
      }
    </ComptencyQuestionsContext.Provider>
  );
};

const ReviewTemplateObjectiveQuestionPreview: React.FunctionComponent<IReviewTemplateQuestionPreviewProps> = (
  { question, index }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const isMatrix = question?.objectiveValue?.selectedView?.value === 'matrix';
  const [objectives, setObjectives] = React.useState<IObjectiveQuestion[]>([]);
  const [competencyDetails, setCompetencyDetails] = React.useState<any>(null);

  const ObjectiveHeader = React.useCallback(() => {
    return (
      <>
        {isMatrix &&
          <Col className='w-100 d-flex bg-white' style={{ top: '-20px', zIndex: 0 }}>
            <Form.Label
              style={{ minWidth: 300, width: 300 }}
              className={`fw-bolder ws-pre-line mr-2 text-start pt-2`}
            >
              {`${`${question?.objectiveValue?.label}:`} ${question?.objectiveValue?.required ? '' : '(optional)'}`}
            </Form.Label>
            {
              competencyDetails?.choices?.map((c: any, index: number) => (
                <Form.Label
                  key={`${index}${c}`}
                  style={{ minWidth: 150, width: 150 }}
                  className={`fw-bolder ws-pre-line pt-2 mr-2 text-center bg-white`}
                >
                  {c?.label || ' '}
                </Form.Label>
              ))
            }
          </Col>
        }
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competencyDetails, isMatrix, question])

  React.useEffect(
    () => {

      setIsLoading(true);
      const sub = getObjectiveList()
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setObjectives(response.data.data.slice(0, 5));
          }
        );

      return () => sub.unsubscribe();
    },
    []
  )

  return (
    <ComptencyQuestionsContext.Provider value={{ competencyDetails, setCompetencyDetails }}>
      {
        isLoading
          ?
          <Preloader show={isLoading} />
          :
          objectives.length > 0
            ?
            question.objectiveValue?.isKPI
              ?
              <>
                <ObjectiveHeader />
                {
                  (!isMatrix) &&
                  <h5 style={isMatrix ? { minWidth: 300, width: 300 } : {}}>{question?.objectiveValue?.label}:</h5>
                }
                <ReviewTemplateBehaviorQuestionPreview
                  index={index}
                  question={question}
                  objectives={objectives}
                  isMatrix={isMatrix}
                />
              </>

              :
              <>
                <ObjectiveHeader />
                {
                  (!isMatrix) &&
                  <h5 style={isMatrix ? { minWidth: 300, width: 300 } : {}}>{question?.objectiveValue?.label}:</h5>
                }
                {
                  objectives.map(
                    (objective, i) => (
                      <ReviewTemplateMultiSelectQuestionPreview
                        key={objective.name}
                        index={i}
                        question={question}
                        objective={objective}
                        labelPostFix={objective.name}
                        isMatrix={isMatrix}
                      />
                    )
                  )
                }
              </>
            :
            <EmptyState message={
              <>
                No objective available.
                <br />
                Add a new one!
              </>
            } />
      }
    </ComptencyQuestionsContext.Provider>
  );
};

const ReviewTemplateQuestionPreview: React.FunctionComponent<Partial<IReviewTemplateQuestionPreviewProps>> = (
  { question, index }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext);

  if (!metadata || !question) {
    return null;
  }

  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;


  switch (question.type) {

    case questionTypeConstants.TITLE:
    case questionTypeConstants.SUBTITLE: {
      return (
        <ReviewTemplateTitleQuestionPreview question={question} />
      );
    }

    case questionTypeConstants.SHORT_TEXT:
    case questionTypeConstants.LONG_TEXT: {
      return (
        <ReviewTemplateTextQuestionPreview
          question={question}
          index={index} />
      );
    }

    case questionTypeConstants.MULTI_SELECT_QUESTION: {
      return (
        <ReviewTemplateMultiSelectQuestionPreview
          question={question}
          index={index} />
      );
    }

    case questionTypeConstants.COMPETENCY_QUESTION: {
      return (
        <ReviewTemplateCompetencyQuestionPreview
          question={question}
          index={index}
        />
      );
    }

    case questionTypeConstants.OBJECTIVE_QUESTION: {
      return (
        <ReviewTemplateObjectiveQuestionPreview
          question={question}
          index={index}
        />
      );
    }

  }

  return null;
};

export default ReviewTemplateQuestionPreview;