import { EmptyState, PageTitle, Preloader, PrimaryLayoutBox } from 'components';
import { MetaDataContext } from 'contexts';
import {
  getMsTeamsTeamMembersAsMenuOptions,
  getSlackTeamMembersAsMenuOptions,
  getSlackWorkspaceChannelsAsMenuOption,
  getSurveyLibraryTemplateDetails,
  saveSurveyLibraryAudience,
  getTeamsGroupsAsMenuOption
} from 'data';
import { FormikErrors, useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  FormErrors,
  IMetadata,
  IRouteParams,
  ISimpleMenuOption,
  ISurvey,
  ISurveyAudience,
  ISurveyAudienceBuilderFormValues,
  ISurveyAudienceRequestData,
  ISurveyInspector,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { finalize, tap } from 'rxjs';
import { SURVEY_AUDIENCE_BUILDER_VALIDATION_SCHEMA } from 'validation-schemas';

import SurveyAudienceBuilderForm from './components/SurveyAudienceBuilderForm';
import {
  getSurveyAudienceBuilderFormInitialValues,
  getSurveyAudienceFlatArray,
  getSurveyChannelsAsMenuOptions,
  getSurveyInspectorsFlatArray,
  getSurveyUsersAsMenuOptions,
  getTeamsGroupsAsMenuOptions,
} from './utils/utils';

interface ISurveyAudienceBuilderProps {
}

const SurveyAudienceBuilder: React.FunctionComponent<ISurveyAudienceBuilderProps> = () => {

  const app = useApp();

  const [workspaceOrChannelUsers, setWorkspaceOrChannelUsers] = React.useState<ISimpleMenuOption[]>([]);

  const [slackChannels, setSlackChannels] = React.useState<ISimpleMenuOption[]>([]);

  const [teamsGroups, setTeamsGroups] = React.useState<ISimpleMenuOption[]>([]);

  const [surveyAudience, setSurveyAudience] = React.useState<ISurveyAudience[]>([]);
  const [surveyInspectors, setSurveyInspectors] = React.useState<ISurveyInspector[]>([]);

  const [isSurveyUpdating, setIsSurveyUpdating] = React.useState<boolean>(false);
  const [dataLoadingCount, setDataLoadingCount] = React.useState<number>(0);
  const [surveyDetails, setSurveyDetails] = React.useState<ISurvey | null>(null);

  const { surveyId } = useParams<IRouteParams>();

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const history = useHistory();

  const surveyAudienceBuilderForm = useFormik<ISurveyAudienceBuilderFormValues>({
    initialValues: getSurveyAudienceBuilderFormInitialValues(),
    validationSchema: SURVEY_AUDIENCE_BUILDER_VALIDATION_SCHEMA,
    validate: (values) => {

      const errors: FormikErrors<ISurveyAudienceBuilderFormValues> = {};

      if (
        !surveyAudience[0]?.isWorkspace &&
        values.minSubmissionCount !== undefined &&
        values.isAnonymous && values.selectedAudienceUsers.length > 0 &&
        values.minSubmissionCount > values.selectedAudienceUsers.length
      ) {
        errors.minSubmissionCount = FormErrors.MaxSubmissionCount;
      }

      if (
        !surveyAudience[0]?.isWorkspace &&
        values.minSubmissionCount !== undefined &&
        values.audienceType?.value === 'users' &&
        values.minSubmissionCount > values.selectedAudienceUsers.length
      ) {
        errors.selectedAudienceUsers = FormErrors.MaxSelectedAudienceUsers;
      }

      return errors;
    },
    validateOnBlur: false,
    onSubmit: () => { },
  });


  const resetSurveyAudienceBuilderForm = (newValues?: Partial<ISurveyAudienceBuilderFormValues>) => {
    surveyAudienceBuilderForm.resetForm({
      values: {
        ...surveyAudienceBuilderForm.values,
        ...newValues,
      }
    });
  };


  React.useEffect(
    () => {

      if (!surveyId) {
        setSurveyDetails(null);
        return;
      }

      setDataLoadingCount((count) => count + 1);

      getSurveyLibraryTemplateDetails(surveyId, metadata, app)
        .pipe(
          finalize(() => setDataLoadingCount((count) => count - 1))
        )
        .subscribe(
          (surveyResponse) => {

            const surveyData = surveyResponse.data.data;

            setSurveyDetails(surveyData);
            setSurveyAudience(
              getSurveyAudienceFlatArray(surveyData)
            );
            setSurveyInspectors(
              getSurveyInspectorsFlatArray(surveyData)
            );
            resetSurveyAudienceBuilderForm({
              isAnonymous: surveyData.anonymity.type === 'anonymous',
              liveResults: surveyData.isLiveResults ? 'Real Time' : 'Once survey is closed',
              minSubmissionCount: surveyData.minSubmissionCount || 0,
            });
          }
        );

    },
    [app, metadata, surveyId]
  );


  // Fetch Slack channels to be selected for audience
  React.useEffect(
    () => {

      if (
        !surveyDetails ||
        surveyAudienceBuilderForm.values.audienceType?.value !== 'channels' ||
        slackChannels.length ||
        app !== SignInWith.Slack
      ) {
        return;
      }

      const subscription = getSlackWorkspaceChannelsAsMenuOption(surveyDetails.workspaceId)
        .subscribe(
          (channelsResponse) => {
            setSlackChannels(channelsResponse.data.data);
            resetSurveyAudienceBuilderForm({
              selectedSlackChannels: getSurveyChannelsAsMenuOptions(surveyDetails, channelsResponse.data.data)
            });
          }
        );

      return () => subscription.unsubscribe();

    },
    [app, slackChannels.length, surveyAudienceBuilderForm.values.audienceType, surveyDetails]
  );


  // Fetch Teams Groups to be selected for audience
  React.useEffect(
    () => {

      if (
        !surveyDetails ||
        surveyAudienceBuilderForm.values.audienceType?.value !== 'groups' ||
        teamsGroups.length ||
        app !== SignInWith.MsTeams
      ) {
        return;
      }

      const subscription = getTeamsGroupsAsMenuOption()
        .subscribe(
          (groupsResponse) => {
            setTeamsGroups(groupsResponse.data.data);
            resetSurveyAudienceBuilderForm({
              selectedGroups: getTeamsGroupsAsMenuOptions(surveyDetails, groupsResponse.data.data)
            });
          }
        );

      return () => subscription.unsubscribe();

    },
    [app, teamsGroups.length, surveyAudienceBuilderForm.values.audienceType, surveyDetails]
  );

  // Fetch Members to be selected for audience
  React.useEffect(
    () => {

      if (
        !surveyDetails ||
        workspaceOrChannelUsers.length ||
        (
          surveyAudienceBuilderForm.values.audienceType?.value !== 'users' &&
          surveyAudienceBuilderForm.values.inspectorType?.value !== 'users'
        )
      ) {
        return;
      }

      const users$ = app === SignInWith.Slack
        ?
        getSlackTeamMembersAsMenuOptions([surveyDetails.workspaceId])
        :
        getMsTeamsTeamMembersAsMenuOptions(surveyDetails.channelId);

      const subscription = users$
        .subscribe(
          (usersResponse) => {
            setWorkspaceOrChannelUsers(usersResponse.data.data);

            resetSurveyAudienceBuilderForm({
              selectedAudienceUsers:
                getSurveyUsersAsMenuOptions('audience', surveyDetails, usersResponse.data.data, app),
              selectedInspectorsUsers:
                getSurveyUsersAsMenuOptions('inspectors', surveyDetails, usersResponse.data.data, app),
            });
          }
        );

      return () => subscription.unsubscribe();

    },
    [
      app, surveyAudienceBuilderForm.values.audienceType,
      surveyAudienceBuilderForm.values.inspectorType, surveyDetails,
      workspaceOrChannelUsers.length
    ]
  );


  const handleAudienceUpdate = (isClear?: boolean) => {

    if (!surveyAudienceBuilderForm.values.audienceType && !isClear) {
      surveyAudienceBuilderForm.setFieldTouched('audienceType', true);
      surveyAudienceBuilderForm.setFieldError('audienceType', FormErrors.Required);
      return;
    }

    const saveSurveyLibraryAudience$ = saveSurveyLibraryAudience({
      surveyTemplateId: surveyId,
      workspaceId: surveyDetails!.workspaceId,
      channelId: surveyDetails!.channelId,
      audienceType: isClear ? 'clear' : surveyAudienceBuilderForm.values.audienceType!.value,
      selectedChannels: surveyAudienceBuilderForm.values.selectedSlackChannels.map(
        (channel) => ({ id: channel.value, name: channel.label })
      ),
      selectedGroups: surveyAudienceBuilderForm.values.selectedGroups.map(
        (group) => group.value
      ),
      selectedAudienceUserIds: surveyAudienceBuilderForm.values.selectedAudienceUsers.map(
        (user) => user.value
      )
    })
      .pipe(
        finalize(() => setIsSurveyUpdating(false)),
        tap(
          (response) => {
            toast.success(response.data.message);
            resetSurveyAudienceBuilderForm({
              audienceType: null
            });
            surveyAudienceBuilderForm.setTouched({});
            setSurveyAudience(
              getSurveyAudienceFlatArray(response.data.data)
            )
          }
        )
      );

    if (isClear) {
      setIsSurveyUpdating(true);
      saveSurveyLibraryAudience$
        .subscribe(
          () => {
            resetSurveyAudienceBuilderForm({
              audienceType: null,
              selectedAudienceUsers: [],
              selectedSlackChannels: [],
              selectedGroups: []
            });
          }
        );
      return;
    }

    switch (surveyAudienceBuilderForm.values.audienceType!.value) {

      case 'team':
      case 'workspace': {
        setIsSurveyUpdating(true);
        saveSurveyLibraryAudience$.subscribe();
        break;
      }


      case 'channels': {

        if (surveyAudienceBuilderForm.values.selectedSlackChannels.length === 0) {
          surveyAudienceBuilderForm.setFieldTouched('selectedSlackChannels', true);
          surveyAudienceBuilderForm.setFieldError('selectedSlackChannels', FormErrors.Required);
          return;
        }

        setIsSurveyUpdating(true);
        saveSurveyLibraryAudience$
          .subscribe(
            (response) => {
              resetSurveyAudienceBuilderForm({
                selectedSlackChannels:
                  getSurveyChannelsAsMenuOptions(response.data.data, slackChannels)
              });
            }
          );
        break;
      }


      case 'users': {

        if (surveyAudienceBuilderForm.values.selectedAudienceUsers.length === 0) {
          surveyAudienceBuilderForm.setFieldTouched('selectedAudienceUsers', true);
          surveyAudienceBuilderForm.setFieldError('selectedAudienceUsers', FormErrors.Required);
          return;
        }

        setIsSurveyUpdating(true);
        saveSurveyLibraryAudience$
          .subscribe(
            (response) => {
              resetSurveyAudienceBuilderForm({
                selectedAudienceUsers:
                  getSurveyUsersAsMenuOptions('audience', response.data.data, workspaceOrChannelUsers, app)
              });
            }
          );
        break;
      }

      case 'groups': {

        if (surveyAudienceBuilderForm.values.selectedGroups.length === 0) {
          surveyAudienceBuilderForm.setFieldTouched('selectedGroups', true);
          surveyAudienceBuilderForm.setFieldError('selectedGroups', FormErrors.Required);
          return;
        }

        setIsSurveyUpdating(true);
        saveSurveyLibraryAudience$
          .subscribe(
            (response) => {
              resetSurveyAudienceBuilderForm({
                selectedGroups:
                  getTeamsGroupsAsMenuOptions(response.data.data, teamsGroups)
              });
            }
          );
        break;
      }

    }
  };


  const handleInspectorUpdate = (isClear?: boolean) => {

    if (!surveyAudienceBuilderForm.values.inspectorType && !isClear) {
      surveyAudienceBuilderForm.setFieldTouched('inspectorType', true);
      surveyAudienceBuilderForm.setFieldError('inspectorType', FormErrors.Required);
      return;
    }

    const saveSurveyLibraryAudience$ = saveSurveyLibraryAudience({
      surveyTemplateId: surveyId,
      workspaceId: surveyDetails!.workspaceId,
      channelId: surveyDetails!.channelId,
      inspectorType: isClear ? 'clear' : surveyAudienceBuilderForm.values.inspectorType!.value,
      selectedInspectorsUserIds: surveyAudienceBuilderForm.values.selectedInspectorsUsers.map(
        (user) => user.value
      )
    }).pipe(
      finalize(() => setIsSurveyUpdating(false)),
      tap(
        (response) => {
          toast.success(response.data.message);
          resetSurveyAudienceBuilderForm({
            inspectorType: null
          });
          surveyAudienceBuilderForm.setTouched({});
          setSurveyInspectors(
            getSurveyInspectorsFlatArray(response.data.data)
          )
        }
      )
    );

    if (isClear) {
      setIsSurveyUpdating(true);
      saveSurveyLibraryAudience$
        .subscribe(
          () => {
            resetSurveyAudienceBuilderForm({
              inspectorType: null,
              selectedInspectorsUsers: []
            });
          }
        );
      return;
    }

    switch (surveyAudienceBuilderForm.values.inspectorType!.value) {

      case 'audience': {
        setIsSurveyUpdating(true);
        saveSurveyLibraryAudience$.subscribe();
        break;
      }

      case 'users': {

        if (surveyAudienceBuilderForm.values.selectedInspectorsUsers.length === 0) {
          surveyAudienceBuilderForm.setFieldTouched('selectedInspectorsUsers', true);
          surveyAudienceBuilderForm.setFieldError('selectedInspectorsUsers', FormErrors.Required);
          return;
        }

        setIsSurveyUpdating(true);
        saveSurveyLibraryAudience$
          .subscribe(
            (response) => {
              resetSurveyAudienceBuilderForm({
                selectedInspectorsUsers:
                  getSurveyUsersAsMenuOptions('inspectors', response.data.data, workspaceOrChannelUsers, app)
              });
            }
          );
        break;
      }

    }

  };


  const handleAnonymityUpdate = () => {
    setIsSurveyUpdating(true);

    saveSurveyLibraryAudience({
      surveyTemplateId: surveyId,
      workspaceId: surveyDetails!.workspaceId,
      channelId: surveyDetails!.channelId,
      anonymity: surveyAudienceBuilderForm.values.isAnonymous ? 'anonymous' : 'non-anonymous',
      minSubmissionCount: surveyAudienceBuilderForm.values.minSubmissionCount
    })
      .pipe(
        finalize(() => setIsSurveyUpdating(false))
      )
      .subscribe(
        (response) => {
          resetSurveyAudienceBuilderForm();
          toast.success(response.data.message);
        }
      );
  };


  const handleViewResultsUpdate = () => {
    setIsSurveyUpdating(true);

    saveSurveyLibraryAudience({
      surveyTemplateId: surveyId,
      workspaceId: surveyDetails!.workspaceId,
      channelId: surveyDetails!.channelId,
      isLiveResults: surveyAudienceBuilderForm.values.liveResults === 'Real Time',
    })
      .pipe(
        finalize(() => setIsSurveyUpdating(false))
      )
      .subscribe(
        (response) => {
          resetSurveyAudienceBuilderForm();
          toast.success(response.data.message);
        }
      );
  };


  const handleSaveAndNextClick = () => {

    if (!surveyAudienceBuilderForm.isValid) {
      surveyAudienceBuilderForm.submitForm();
      return;
    }

    setIsSurveyUpdating(true);

    let requestData: Partial<ISurveyAudienceRequestData> = {
      surveyTemplateId: surveyId,
      workspaceId: surveyDetails!.workspaceId,
      channelId: surveyDetails!.channelId,
      anonymity: surveyAudienceBuilderForm.values.isAnonymous ? 'anonymous' : 'non-anonymous',
      minSubmissionCount: surveyAudienceBuilderForm.values.minSubmissionCount,
      isLiveResults: surveyAudienceBuilderForm.values.liveResults === 'Real Time',
    };

    if (surveyAudienceBuilderForm.values.audienceType) {
      requestData = {
        ...requestData,
        audienceType: surveyAudienceBuilderForm.values.audienceType.value,
        selectedChannels: surveyAudienceBuilderForm.values.selectedSlackChannels.map(
          (channel) => ({ id: channel.value, name: channel.label })
        ),
        selectedGroups: surveyAudienceBuilderForm.values.selectedGroups.map(
          (group) => group.value
        ),
        selectedAudienceUserIds: surveyAudienceBuilderForm.values.selectedAudienceUsers.map(
          (user) => user.value
        )
      };
    }

    if (surveyAudienceBuilderForm.values.inspectorType) {
      requestData = {
        ...requestData,
        inspectorType: surveyAudienceBuilderForm.values.inspectorType.value,
        selectedInspectorsUserIds: surveyAudienceBuilderForm.values.selectedInspectorsUsers.map(
          (user) => user.value
        )
      };
    }

    saveSurveyLibraryAudience(requestData)
      .pipe(
        finalize(() => setIsSurveyUpdating(false))
      )
      .subscribe(
        (response) => {
          resetSurveyAudienceBuilderForm();
          toast.success(response.data.message);
          history.push(`/surveys/survey-library/${surveyId}/survey-audience/survey-scheduler`);
        }
      );
  };


  return (
    <>
      <PageTitle title={Routes.SurveyAudienceBuilder.title} />
      <PrimaryLayoutBox title={Routes.SurveyAudienceBuilder.title}>
        <div className='position-relative h-100 overflow-hidden'>
          {
            dataLoadingCount ?
              (<Preloader show={!!dataLoadingCount} />) :
              (
                surveyDetails ?
                  (
                    <SurveyAudienceBuilderForm
                      isLoading={isSurveyUpdating}
                      slackChannels={slackChannels}
                      teamsGroups={teamsGroups}
                      surveyDetails={surveyDetails}
                      surveyAudience={surveyAudience}
                      surveyInspectors={surveyInspectors}
                      workspaceOrChannelUsers={workspaceOrChannelUsers}
                      surveyAudienceBuilderForm={surveyAudienceBuilderForm}
                      onAudienceUpdate={handleAudienceUpdate}
                      onInspectorUpdate={handleInspectorUpdate}
                      onAnonymityUpdate={handleAnonymityUpdate}
                      onSaveAndNextClick={handleSaveAndNextClick}
                      onViewResultsUpdate={handleViewResultsUpdate}
                    />
                  ) :
                  (
                    <EmptyState message={<> No Survey Data available. </>} />
                  )
              )
          }
        </div>
      </PrimaryLayoutBox>
      <Prompt
        when={surveyAudienceBuilderForm.dirty}
        message='You have unsaved changes. Are you sure to leave the page?' />
    </>
  );
};

export default SurveyAudienceBuilder;
