import { IReviewScore } from "interfaces";
import moment from "moment";
import React from "react";
import { Column } from "react-table";
import { DATE_TIME_FORMAT } from "teamble-constants";

export const useReviewScoreTableColumns = (
  reviewScoreList: IReviewScore[]
): readonly Column<IReviewScore>[] => {

  return React.useMemo<readonly Column<IReviewScore>[]>(
    () => {

      if (!reviewScoreList.length) {
        return [];
      }
      // Create dynamic number of columns based on the reviews selected
      const reviewColumns: readonly Column<IReviewScore>[] =
        reviewScoreList[0].campaigns
          .map(
            (campaign, index) => ({
              Header: `${campaign.title}\n${moment(campaign.createdAt).format(DATE_TIME_FORMAT)}`,
              accessor: (row) => row.campaigns[index].value,
              cellClassName: 'text-center ws-pre'
            })
          );

      return [
        {
          id: 'question',
          Header: 'Question',
          accessor: (row) => {
            if (row.behaviorName) {
              return `${row.label}: ${row.behaviorName}`;
            }
            if (row.competencyName) {
              return `${row.label}: ${row.competencyName}`;
            }
            return row.label;
          },
          cellWidth: '500px',
          cellClassName: 'table-cell-text'
        },
        ...reviewColumns
      ];

    },
    [reviewScoreList]
  );

};