import {
  Navbar,
  Preloader,
  ProvideAuth,
  ProvideUserProfile,
  useAuth,
  useUserProfile,
} from "components";
import { MetaDataContext } from "contexts";
import { getCompanyConfig, getMetadata, getUserProfile } from "data";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Routes } from "routes";
import { finalize, forkJoin } from "rxjs";
import { QueryParamProvider } from "use-query-params";
import { getSurveyQuestionTypesMetaData } from "utils";

import Sidebar from "./components/Sidebar";
import { DEFAULT_COMPANY_CONFIG } from "teamble-constants";
import { CompanyConfigContext } from "@common/modules/companyConfig";
import AiChat from "components/AiChat";
import { FrameProvider } from 'contexts';


const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={!loaded} transparentBg={false} />{" "}
          <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSignedInRedirect = ({ component: Component, ...rest }) => {
  const auth = useAuth();

  const [loaded, setLoaded] = useState(true);

  const { userProfile, setUserProfile } = useUserProfile();

  useEffect(() => {
    if (!auth.isSignedIn) {
      setLoaded(true);
      return;
    }

    setLoaded(false);
    getUserProfile()
      .pipe(finalize(() => setLoaded(true)))
      .subscribe({
        next: (userProfileResponse) => {
          setUserProfile(userProfileResponse.data.data);
        },
        error: (error) => {
          if (
            error.response.data.code === "Unauthorized" ||
            error.response.status === 401
          ) {
            auth.signOut();
          }
        },
      });
  }, [auth.isSignedIn]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {auth.isSignedIn ? (
            <>
              {loaded ? (
                <>
                  {userProfile ? (
                    <Redirect
                      push
                      to={`/my-profile/${userProfile.teambleUserId}`}
                    />
                  ) : null}
                </>
              ) : (
                <Preloader show={!loaded} transparentBg={false} />
              )}
            </>
          ) : (
            <Component {...props} />
          )}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({
  component: Component,
  context,
  permission,
  ...rest
}) => {
  const [metadata, setMetadata] = useState(null);
  const [companyConfig, setCompanyConfig] = useState();
  const [loaded, setLoaded] = useState(false);
  const [toggle, setToggle] = useState(false);
  const location = useLocation();
  const auth = useAuth();
  const { userProfile, setUserProfile } = useUserProfile();
  const [showAiChat, setShowAiChat] = useState(false);

  useEffect(() => {
    if (!auth.isSignedIn) {
      return;
    }

    forkJoin([getCompanyConfig(), getMetadata(), getUserProfile()])
      .pipe(finalize(() => setLoaded(true)))
      .subscribe({
        next: ([
          companyConfigResponse,
          metadataResponse,
          userProfileResponse,
        ]) => {
          const surveyQuestionTypes = getSurveyQuestionTypesMetaData(
            userProfileResponse.data.data.app
          );

          setMetadata({
            ...metadataResponse.data.data,
            surveyQuestionTypes,
            questionTypes: {
              constants: {
                ...metadataResponse.data.data.questionTypes.constants,
                COMPETENCY_QUESTION: "competency-question",
                OBJECTIVE_QUESTION: "objective-question",
              },
              options: [
                ...metadataResponse.data.data.questionTypes.options,
                {
                  value: "competency-question",
                  label: "Competency Question",
                },
                {
                  value: "objective-question",
                  label: "Objective Question",
                },
              ],
            },
          });

          setUserProfile(userProfileResponse.data.data);
          setCompanyConfig({
            ...DEFAULT_COMPANY_CONFIG,
            ...companyConfigResponse.data.data,
          });
        },
        error: (error) => {
          if (
            error.response.data.code === "Unauthorized" ||
            error.response.status === 401
          ) {
            auth.signOut();
          }
        },
      });
  }, [auth.isSignedIn]);

  useEffect(() => {
    const url = window.location.href;
    if (
      url.includes("/my-team/default-relationship/users/") ||
      url.includes("/organization/settings/users/") ||
      url.includes("/my-profile/")
    ) {
      setShowAiChat(true);
    } else {
      setShowAiChat(false);
    }
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {auth.isSignedIn ? (
              loaded ? (
                userProfile?.permissions?.includes(permission) ? (
                  <MetaDataContext.Provider value={metadata}>
                    <CompanyConfigContext.Provider value={companyConfig}>
                      {companyConfig && (
                        <>
                          <Sidebar toggle={toggle} />
                          {metadata && (
                            <main className="content vh-100 py-3 overflow-hidden d-flex flex-column position-relative">
                              <Navbar
                                routeProps={props}
                                setToggle={setToggle}
                              />
                              <Component context={context} {...props} />
                              {companyConfig?.enableTeambleAi &&
                                userProfile?.isSuperAdmin &&
                                showAiChat && <AiChat />}
                            </main>
                          )}
                        </>
                      )}
                    </CompanyConfigContext.Provider>
                  </MetaDataContext.Provider>
                ) : (
                  <Redirect to={Routes.HomePage.path} />
                )
              ) : (
                <Preloader show={!loaded} transparentBg={false} />
              )
            ) : (
              <Redirect to={Routes.Signin.path} />
            )}
          </>
        );
      }}
    />
  );
};

export default () => (
  <FrameProvider>
    <ProvideAuth>
      <ProvideUserProfile>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            {/* Authentication */}
            <RouteWithLoader {...Routes.AuthorizationError} />
            <RouteWithSignedInRedirect {...Routes.Signin} />
            <RouteWithSignedInRedirect {...Routes.SlackCompleteAuthorization} />
            <RouteWithSignedInRedirect {...Routes.MsTeamsCompleteAuthorization} />
            <RouteWithSignedInRedirect {...Routes.SelectSlackWorkspace} />

            {/* Profile Page */}
            <RouteWithSidebar {...Routes.MyProfilePage} />

            {/* Relationship Team */}
            <RouteWithSidebar {...Routes.MyTeamPage} />
            <RouteWithSidebar {...Routes.MyTeamProfilePage} />
            <RouteWithSidebar {...Routes.MyTeamDetailsPage} />

            {/* My Groups */}
            <RouteWithSidebar {...Routes.MyGroupsPage} />
            <RouteWithSidebar {...Routes.MyGroupUserProfilePage} />
            <RouteWithSidebar {...Routes.MyGroupDetailsPage} />

            {/* MyReviews Page */}
            <RouteWithSidebar exact {...Routes.MyReviewsPage} />

            {/* Performance Reviews */}
            <RouteWithSidebar exact {...Routes.PerformanceReviewQuestionnaires} />
            <RouteWithSidebar exact {...Routes.PerformanceReviewTemplates} />
            <RouteWithSidebar
              exact
              {...Routes.PerformanceReviewTemplateBuilder}
            />
            <RouteWithSidebar exact {...Routes.PredefinedReviewTemplateBuilder} />
            <RouteWithSidebar exact {...Routes.ReviewsListPage} />
            <RouteWithSidebar exact {...Routes.LaunchReview} />
            <RouteWithSidebar exact {...Routes.DraftReviewById} />
            <RouteWithSidebar exact {...Routes.ReviewDetailsPage} />
            <RouteWithSidebar exact {...Routes.PerformanceReviewReports} />
            <RouteWithSidebar exact {...Routes.PerformanceReviewReportsTable} />
            <RouteWithSidebar {...Routes.ReviewToDosPage} />

            {/* Objective campaigns */}
            <RouteWithSidebar exact {...Routes.ObjectiveCampaigns} />
            <RouteWithSidebar exact {...Routes.LaunchObjectiveCampaigns} />
            <RouteWithSidebar exact {...Routes.ManageObjectiveCampaign} />
            <RouteWithSidebar exact {...Routes.ObjectiveCampaignsDetailsPage}/>

            {/* Surveys */}
            <RouteWithSidebar exact {...Routes.SurveyLibrary} />
            <RouteWithSidebar exact {...Routes.SurveyTemplates} />
            <RouteWithSidebar exact {...Routes.SurveyBuilder} />
            <RouteWithSidebar exact {...Routes.EditSurveyTemplate} />
            <RouteWithSidebar exact {...Routes.SurveyAudienceBuilder} />
            <RouteWithSidebar exact {...Routes.SurveyScheduler} />
            <RouteWithSidebar exact {...Routes.ManageSurveys} />
            <RouteWithSidebar exact {...Routes.SurveyDetails} />
            <RouteWithSidebar exact {...Routes.SurveyReports} />
            <RouteWithSidebar {...Routes.SurveyInsights} />

            {/* Analytics */}
            <RouteWithSidebar {...Routes.SurveyAnalytics} />
            <RouteWithSidebar {...Routes.FeedbackAnalytics} />
            <RouteWithSidebar {...Routes.ReviewAnalytics} />
            <RouteWithSidebar {...Routes.Reports} />

            {/* Feedback Loops */}
            <RouteWithSidebar {...Routes.FeedbackLoopsListPage} />
            <RouteWithSidebar {...Routes.FeedbackLoopTemplateTypesPage} />
            <RouteWithSidebar {...Routes.FeedbackLoopTemplatesListPage} />
            <RouteWithSidebar {...Routes.FeedbackLoopBuilderPage} />
            <RouteWithSidebar {...Routes.EditFeedbackLoopTemplate} />
            <RouteWithSidebar {...Routes.FeedbackLoopConfigPage} />
            <RouteWithSidebar {...Routes.ManageFeedbackLoopsPage} />
            <RouteWithSidebar {...Routes.LaunchedFeedbackLoopDetailsPage} />

            {/* Users & Roles */}
            <RouteWithSidebar {...Routes.Admins} />
            <RouteWithSidebar {...Routes.UserDetails} />
            <RouteWithSidebar {...Routes.DepartmentDetailsPage} />
            <RouteWithSidebar {...Routes.GroupDetailsPage} />
            <RouteWithSidebar {...Routes.OrganizationSettingsPage} />

            {/* Integration */}
            <RouteWithSidebar {...Routes.IntegrationsListPage} />

            {/* Home page */}
            <Redirect exact from={Routes.HomePage.path} to={Routes.Signin.path} />

            
            {/*one on one feedback*/}
            <RouteWithSidebar {...Routes.OneOnOneFeedbackAi} />
            <RouteWithSidebar {...Routes.OneOnOneFeedbackAiDetail} />
            <RouteWithSidebar {...Routes.OneOnOnePrepareFeedback} exact />

            <RouteWithLoader {...Routes.NotFound} />

            {/* Redirect to not found page if route does not match */}
            <Redirect to={Routes.NotFound.path} />
          </Switch>
        </QueryParamProvider>
      </ProvideUserProfile>
    </ProvideAuth>
  </FrameProvider>
);
