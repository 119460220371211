import { GroupContext, IRoutes, UserPermissions } from "interfaces";

import { ROUTE_COMPONENTS } from "./routeComponents";

export const Routes: IRoutes = {
  // Home Page
  HomePage: {
    path: "/",
    title: "Teamble",
    permission: UserPermissions.AllUserRoleRouteAccess,
  },

  // Profile page
  MyProfilePage: {
    path: "/my-profile/:userId",
    title: "My Profile",
    component: ROUTE_COMPONENTS.MyProfilePage,
    context: "myProfile",
    permission: UserPermissions.AllUserRoleRouteAccess,
  },

  MyProfileFeedbackInsightsTab: {
    path: "/my-profile/:userId/feedback-insights",
    title: "My Profile",
    permission: UserPermissions.AllUserRoleRouteAccess,
    context: "myProfile",
  },
  MyProfileFeedbacksTab: {
    path: "/my-profile/:userId/feedback",
    title: "My Profile",
    permission: UserPermissions.AllUserRoleRouteAccess,
    context: "myProfile",
  },

  // Relationship Team
  MyTeamPage: {
    path: "/my-team",
    title: "My Team",
    component: ROUTE_COMPONENTS.MyTeamPage,
    permission: UserPermissions.AllUserRoleRouteAccess,
    exact: true,
  },
  MyTeamDetailsPage: {
    path: "/my-team/:relationshipId",
    title: "Team Details",
    component: ROUTE_COMPONENTS.GroupDetailsPage,
    permission: UserPermissions.AllUserRoleRouteAccess,
    context: GroupContext.MyTeams,
  },
  MyTeamProfilePage: {
    path: "/my-team/:relationshipId/users/:userId",
    title: "User Details",
    component: ROUTE_COMPONENTS.MyTeamProfilePage,
    permission: UserPermissions.AllUserRoleRouteAccess,
    context: GroupContext.MyTeams,
  },
  MyTeamFeedbackInsightsTab: {
    path: "/my-team/:relationshipId/users/:userId/feedback-insights",
    title: "User Details",
    permission: UserPermissions.AllUserRoleRouteAccess,
  },
  MyTeamFeedbackTab: {
    path: "/my-team/:relationshipId/feedback-insights",
    title: "User Details",
    permission: UserPermissions.AllUserRoleRouteAccess,
  },

  // My Groups page
  MyGroupsPage: {
    path: "/my-groups",
    title: "My Groups",
    component: ROUTE_COMPONENTS.MyGroupsPage,
    permission: UserPermissions.ViewMyGroupsPage,
    exact: true,
  },
  MyGroupDetailsPage: {
    path: "/my-groups/:groupId",
    title: "Group Details",
    component: ROUTE_COMPONENTS.GroupDetailsPage,
    permission: UserPermissions.ViewMyGroupsPage,
    context: GroupContext.MyGroups,
  },
  MyGroupUserProfilePage: {
    path: "/my-groups/:groupId/users/:userId",
    title: "User Details",
    permission: UserPermissions.ViewMyGroupsPage,
    component: ROUTE_COMPONENTS.UserProfilePage,
    context: "myGroups",
  },
  MyGroupUserFeedbackInsightsTab: {
    path: "/my-groups/:groupId/users/:userId/feedback-insights",
    title: "",
    permission: UserPermissions.ViewMyGroupsPage,
  },
  MyGroupUserFeedbacksTab: {
    path: "/my-groups/:groupId/users/:userId/feedback",
    title: "",
    permission: UserPermissions.ViewMyGroupsPage,
  },
  MyGroupFeedbackTab: {
    path: "/my-groups/:groupId/feedback-insights",
    title: "Group Details",
    permission: UserPermissions.ViewMyGroupsPage,
  },

  // Authentication
  Signin: {
    path: "/sign-in",
    title: "Sign In",
    component: ROUTE_COMPONENTS.Signin,
    exact: true,
    permission: UserPermissions.AllUserRoleRouteAccess,
  },
  SlackCompleteAuthorization: {
    path: "/slack/complete-authorization",
    title: "Complete Authorization",
    component: ROUTE_COMPONENTS.SlackCompleteAuthorization,
    exact: true,
    permission: UserPermissions.AllUserRoleRouteAccess,
  },
  MsTeamsCompleteAuthorization: {
    path: "/msteams/complete-authorization",
    title: "Complete Authorization",
    component: ROUTE_COMPONENTS.MsTeamsCompleteAuthorization,
    exact: true,
    permission: UserPermissions.AllUserRoleRouteAccess,
  },
  SelectSlackWorkspace: {
    path: "/slack/select-slack-workspace",
    title: "Select Slack Workspace",
    component: ROUTE_COMPONENTS.SelectSlackWorkspace,
    exact: true,
    permission: UserPermissions.AllUserRoleRouteAccess,
  },
  AuthorizationError: {
    path: "/auth-error",
    title: "Authorization Error",
    component: ROUTE_COMPONENTS.AuthorizationError,
    exact: true,
    permission: UserPermissions.AllUserRoleRouteAccess,
  },

  // Performance Reviews
  PerformanceReviewQuestionnaires: {
    path: "/reviews/review-questionnaires",
    title: "Performance Review Questionnaires",
    component: ROUTE_COMPONENTS.PerformanceReviewQuestionnaires,
    exact: true,
    permission: UserPermissions.ViewReviewBuilder,
    context: "reviews",
  },
  PerformanceReviewTemplateBuilder: {
    path: "/reviews/review-questionnaires/:reviewTemplateId",
    title: "Review Builder",
    component: ROUTE_COMPONENTS.PerformanceReviewTemplateBuilder,
    exact: true,
    permission: UserPermissions.ViewReviewBuilder,
  },
  PerformanceReviewTemplates: {
    path: "/reviews/review-questionnaires/add-new-questionnaire",
    title: "Add New Questionnaire",
    component: ROUTE_COMPONENTS.PerformanceReviewTemplates,
    exact: true,
    permission: UserPermissions.ViewReviewBuilder,
  },
  PredefinedReviewTemplateBuilder: {
    path: "/reviews/review-questionnaires/add-new-questionnaire/:predefinedReviewTemplateId",
    title: "Review Builder",
    component: ROUTE_COMPONENTS.PredefinedReviewTemplateBuilder,
    exact: true,
    permission: UserPermissions.ViewReviewBuilder,
  },
  ReviewsListPage: {
    path: "/reviews/manage-reviews",
    title: "Reviews",
    component: ROUTE_COMPONENTS.ReviewsListPage,
    exact: true,
    permission: UserPermissions.ViewManageReviews,
  },
  ReviewDetailsPage: {
    path: "/reviews/manage-reviews/:activeReviewId",
    title: "Review Details",
    component: ROUTE_COMPONENTS.ReviewDetailsPage,
    exact: true,
    permission: UserPermissions.ViewManageReviews,
  },
  LaunchReview: {
    path: "/reviews/manage-reviews/launch-review",
    title: "Launch New Review",
    component: ROUTE_COMPONENTS.LaunchReview,
    exact: true,
    permission: UserPermissions.ViewManageReviews,
  },
  DraftReviewById: {
    path: "/reviews/manage-reviews/draft/:reviewId",
    title: "Update Draft Review",
    component: ROUTE_COMPONENTS.DraftReviewById,
    exact: true,
    permission: UserPermissions.ViewManageReviews,
  },
  PerformanceReviewReports: {
    path: "/reviews/reports",
    title: "Performance Review Reports",
    component: ROUTE_COMPONENTS.PerformanceReviewReports,
    exact: true,
    permission: UserPermissions.ViewReviewReports,
  },
  PerformanceReviewReportsTable: {
    path: "/reviews/reports-table",
    title: "Performance Review Reports Table",
    component: ROUTE_COMPONENTS.PerformanceReviewReportsTable,
    exact: true,
    permission: UserPermissions.ViewReviewReports,
  },
  MyReviewsPage: {
    path: "/reviews/my-reviews",
    title: "My Reviews",
    component: ROUTE_COMPONENTS.MyReviewsPage,
    exact: true,
    permission: UserPermissions.ViewMyReviewsPage,
  },
	ObjectiveCampaigns: {
		path: "/objective-campaigns",
		title: "Objective Campaigns",
		component: ROUTE_COMPONENTS.ObjectivesListPage,
		exact: true,
		permission: UserPermissions.ViewManageReviews,
	},
	ObjectiveCampaignsDetailsPage: {
		path: "/objective-campaigns/draft/:objectivesCampaignId",
		title: "Objective Campaigns",
		component:ROUTE_COMPONENTS.ObjectivesLaunchNewReview,
		exact: true,
		permission: UserPermissions.ViewManageReviews,
	},
	LaunchObjectiveCampaigns: {
		path: "/objective-campaigns/launch-review",
		title: "Launch Objective Campaigns",
		component: ROUTE_COMPONENTS.ObjectivesLaunchNewReview,
		exact: true,
		permission: UserPermissions.ViewManageReviews,
	},
	ManageObjectiveCampaign: {
		path: "/objective-campaigns/manage/:objectivesCampaignId",
		title: "Objective Campaigns",
		component: ROUTE_COMPONENTS.ObjectiveManagePage,
		exact: true,
		permission: UserPermissions.ViewManageReviews,
	},
  // Review Todos
  ReviewToDosPage: {
    path: "/reviews/todos",
    title: "To Do's",
    component: ROUTE_COMPONENTS.ReviewToDosPage,
    permission: UserPermissions.ViewTodosPage,
  },
  SelectedReviewToDo: {
    path: "/reviews/todos/:reviewId",
    title: "", // No title set as this route should not be added to Breadcrumb
    exact: true,
    permission: UserPermissions.ViewTodosPage,
  },
  AwaitingNominationToDo: {
    path: "/reviews/todos/awaiting-nomination/:reviewId",
    title: "", // No title set as this route should not be added to Breadcrumb
    exact: true,
    permission: UserPermissions.ViewTodosPage,
  },
  ManagerApproveNominationToDo: {
    path: "/reviews/todos/manager-approve-nomination/:reviewId/:revieweeId",
    title: "", // No title set as this route should not be added to Breadcrumb
    exact: true,
    permission: UserPermissions.ViewTodosPage,
  },
  ReviewerApproveNominationToDo: {
    path: "/reviews/todos/reviewer-approve-nomination/:reviewId",
    title: "", // No title set as this route should not be added to Breadcrumb
    exact: true,
    permission: UserPermissions.ViewTodosPage,
  },
  ReviewOwnerTodo: {
    path: "/reviews/todos/owner-todo/:reviewId/:revieweeId",
    title: "", // No title set as this route should not be added to Breadcrumb
    exact: true,
    permission: UserPermissions.ViewTodosPage,
  },

  // Surveys
  SurveyLibrary: {
    path: "/surveys/survey-library",
    title: "Survey Library",
    component: ROUTE_COMPONENTS.SurveyLibrary,
    exact: true,
    context: "surveys",
    permission: UserPermissions.ViewSurveys,
  },
  SurveyTemplates: {
    path: "/surveys/survey-library/add-new-survey",
    title: "Add New Survey",
    component: ROUTE_COMPONENTS.SurveyTemplates,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  SurveyBuilder: {
    path: "/surveys/survey-library/:surveyId",
    title: "Survey Builder",
    component: ROUTE_COMPONENTS.SurveyBuilder,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  SurveyAudienceBuilder: {
    path: "/surveys/survey-library/:surveyId/survey-audience",
    title: "Survey Audience",
    component: ROUTE_COMPONENTS.SurveyAudienceBuilder,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  SurveyScheduler: {
    path: "/surveys/survey-library/:surveyId/survey-audience/survey-scheduler",
    title: "Survey Scheduler",
    component: ROUTE_COMPONENTS.SurveyScheduler,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  EditSurveyTemplate: {
    path: "/surveys/survey-library/add-new-survey/:surveyTemplateId",
    title: "Edit Survey Template",
    component: ROUTE_COMPONENTS.EditSurveyTemplate,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  ManageSurveys: {
    path: "/surveys/manage-surveys",
    title: "Manage Surveys",
    component: ROUTE_COMPONENTS.ManageSurveys,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  SurveyDetails: {
    path: "/surveys/manage-surveys/:surveyId",
    title: "Survey Details",
    component: ROUTE_COMPONENTS.SurveyDetails,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  SurveyReports: {
    path: "/surveys/reports",
    title: "Survey Reports",
    component: ROUTE_COMPONENTS.SurveyReports,
    exact: true,
    permission: UserPermissions.ViewSurveys,
  },
  SurveyInsights: {
    path: "/surveys/survey-insights",
    title: "Survey Insights",
    component: ROUTE_COMPONENTS.SurveyInsights,
    permission: UserPermissions.ViewSurveys,
  },
  SurveyInsightsDepartment: {
    path: "/surveys/survey-insights/department",
    title: "Department",
    permission: UserPermissions.ViewSurveys,
  },

  // Organization
  OrganizationSettingsPage: {
    path: "/organization/settings",
    title: "Users",
    component: ROUTE_COMPONENTS.OrganizationSettingsPage,
    permission: UserPermissions.ViewUsers,
  },
  Admins: {
    path: "/organization/admins",
    title: "Admins",
    component: ROUTE_COMPONENTS.Admins,
    permission: UserPermissions.ViewOrganization,
  },
  TeamAdminsListWidget: {
    path: "/organization/admins/admins-list/",
    title: "Admins",
    permission: UserPermissions.ViewAdmins,
  },
  SuperAdminsWidget: {
    path: "/organization/admins/super-admins-list",
    title: "Super Admins",
    permission: UserPermissions.ViewSuperAdmins,
  },
  Users: {
    path: "/organization/settings/users",
    title: "Users",
    permission: UserPermissions.ViewUsers,
  },
  UserDetails: {
    path: "/organization/settings/users/:userId",
    title: "User Details",
    permission: UserPermissions.ViewUsers,
    context: "userDetails",
    component: ROUTE_COMPONENTS.UserProfilePage,
  },
  UserDetailsFeedbackInsightsTab: {
    path: "/organization/settings/users/:userId/feedback-insights",
    title: "User Details",
    permission: UserPermissions.ViewUsers,
    context: "userDetails",
  },
  UserDetailsFeedbacksTab: {
    path: "/organization/settings/users/:userId/feedback",
    title: "User Details",
    permission: UserPermissions.ViewUsers,
    context: "userDetails",
  },
  UserDetailsPerformanceTab: {
    path: "/organization/settings/users/:userId/performance",
    title: "User Details",
    permission: UserPermissions.ViewPerformanceTab,
    context: "userDetails",
  },
  UserRoles: {
    path: "/organization/settings/roles",
    title: "Roles",
    permission: UserPermissions.ViewRoles,
  },
  UserDepartments: {
    path: "/organization/settings/departments",
    title: "Departments",
    permission: UserPermissions.ViewDepartments,
  },
  DepartmentDetailsPage: {
    path: "/organization/settings/departments/:departmentId",
    title: "Department Details",
    permission: UserPermissions.ViewDepartmentDetails,
    component: ROUTE_COMPONENTS.DepartmentDetailsPage,
  },
  DepartmentFeedbackTab: {
    path: "/organization/settings/departments/:departmentId/feedback-insights",
    title: "Department Details",
    permission: UserPermissions.ViewDepartmentDetails,
  },
  Competencies: {
    path: "/organization/settings/competencies",
    title: "Competencies",
    permission: UserPermissions.ViewCompetencies,
  },
  GroupsTab: {
    path: "/organization/settings/groups",
    title: "Groups",
    permission: UserPermissions.ViewGroups,
  },
  GroupDetailsPage: {
    path: "/organization/settings/groups/:groupId",
    title: "Group Details",
    permission: UserPermissions.ViewGroups,
    component: ROUTE_COMPONENTS.GroupDetailsPage,
    context: GroupContext.OrganizationGroups,
  },
  GroupFeedbackTab: {
    path: "/organization/settings/groups/:groupId/feedback-insights",
    title: "Group Details",
    permission: UserPermissions.ViewGroups,
  },
  Relationships: {
    path: "/organization/settings/relationships",
    title: "Relationships",
    permission: UserPermissions.ViewRelationShipPage,
  },

  // Analytics
  SurveyAnalytics: {
    path: "/analytics/survey-analytics",
    title: "Survey Analytics",
    component: ROUTE_COMPONENTS.SurveyAnalytics,
    permission: UserPermissions.ViewAnalytics,
  },
  SurveyAnalyticsTimeline: {
    path: "/analytics/survey-analytics/timeline",
    title: "Survey Analytics | Timeline",
    permission: UserPermissions.ViewAnalytics,
  },
  SurveyAnalyticsOverview: {
    path: "/analytics/survey-analytics/overview",
    title: "Survey Analytics | Overview",
    permission: UserPermissions.ViewSurveyAnalyticsOverviewTab,
  },
  SurveyAnalyticsSentiments: {
    path: "/analytics/survey-analytics/sentiments",
    title: "Survey Analytics | Sentiments",
    permission: UserPermissions.ViewAnalytics,
  },
  SurveyAnalyticsQuestionTimeline: {
    path: "/analytics/survey-analytics/question-timeline",
    title: "Survey Analytics | Question Timeline",
    permission: UserPermissions.ViewSurveyAnalyticsQuestionTimelineTab,
  },
  FeedbackAnalytics: {
    path: "/analytics/feedback-analytics",
    title: "Feedback Analytics",
    component: ROUTE_COMPONENTS.FeedbackAnalytics,
    permission: UserPermissions.ViewAnalytics,
  },
  FeedbackAnalyticsOverview: {
    path: "/analytics/feedback-analytics/overview",
    title: "Feedback Analytic | Overview",
    permission: UserPermissions.ViewAnalytics,
  },
  FeedbackAnalyticsLeaderBoard: {
    path: "/analytics/feedback-analytics/leader-board",
    title: "Feedback Analytics | Leader Board",
    permission: UserPermissions.ViewAnalytics,
  },

  // Review Analytics
  ReviewAnalytics: {
    path: "/analytics/review-analytics",
    title: "Reviews Reporting",
    component: ROUTE_COMPONENTS.ReviewAnalytics,
    permission: UserPermissions.ViewAnalytics,
  },
  ReviewAnalyticsOverview: {
    path: "/analytics/review-analytics/overview",
    title: "Review Analytics Overview",
    permission: UserPermissions.ViewAnalytics,
  },
  ReviewAnalyticsOrganization: {
    path: "/analytics/review-analytics/organization",
    title: "Review Analytics Organization",
    permission: UserPermissions.ViewAnalytics,
  },
  // // Performance Insights
  // PerformanceInsights: {
  //   path: "/performance-insights",
  //   title: "",
  // },
  // PerformanceInsightsOverView: {
  //   path: "/performance-insights/overview",
  //   title: "",
  // },
  // PerformanceInsightsMatrix: {
  //   path: "/performance-insights/matrix",
  //   title: "",
  // },

  Reports: {
    path: "/analytics/reports",
    title: "Reports",
    component: ROUTE_COMPONENTS.Reports,
    permission: UserPermissions.ViewAnalytics,
  },

  // Workflows
  FeedbackLoopsListPage: {
    path: "/workflows/library",
    title: "Workflows",
    component: ROUTE_COMPONENTS.FeedbackLoopsListPage,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },
  FeedbackLoopTemplateTypesPage: {
    path: "/workflows/library/template-types",
    title: "Workflow Templates",
    component: ROUTE_COMPONENTS.FeedbackLoopTemplateTypesPage,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },
  FeedbackLoopTemplatesListPage: {
    path: "/workflows/library/template-types/:templateType",
    title: "Add New Workflow",
    component: ROUTE_COMPONENTS.FeedbackLoopTemplatesListPage,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },
  FeedbackLoopBuilderPage: {
    path: "/workflows/library/:templateType/:feedbackLibraryLoopId",
    title: "Workflow Builder",
    component: ROUTE_COMPONENTS.FeedbackLoopBuilderPage,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },
  EditFeedbackLoopTemplate: {
    path: "/workflows/library/template-types/:templateType/:templateId",
    title: "Edit Workflow Template",
    component: ROUTE_COMPONENTS.EditFeedbackLoopTemplate,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },
  FeedbackLoopConfigPage: {
    path: "/workflows/library/:templateType/:feedbackLibraryLoopId/config",
    title: "Configure Workflow",
    component: ROUTE_COMPONENTS.WorkFlowConfigPage,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },
  ManageFeedbackLoopsPage: {
    path: "/workflows/manage-feedback-loops",
    title: "Manage Workflows",
    component: ROUTE_COMPONENTS.ManageFeedbackLoopsPage,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },
  LaunchedFeedbackLoopDetailsPage: {
    path: "/workflows/manage-feedback-loops/:feedbackLoopId",
    title: "Workflows Details",
    component: ROUTE_COMPONENTS.LaunchedFeedbackLoopDetailsPage,
    exact: true,
    permission: UserPermissions.ViewFeedbackLoops,
  },

  // Integration
  IntegrationsListPage: {
    path: "/integrations",
    title: "Integrations",
    component: ROUTE_COMPONENTS.IntegrationsListPage,
    exact: true,
    permission: UserPermissions.ViewIntegrationsListPage,
  },

  // OneOnOneAiFeedback
  OneOnOneFeedbackAi: {
    path: "/one-on-ones",
    title: "1-on-1s",
    component: ROUTE_COMPONENTS.OneOnOne,
    permission: UserPermissions.viewAiFeedback,
    exact: true,
  },
  OneOnOneFeedbackAiDetail: {
    path: "/one-on-ones/:oneOnOneId",
    title: "OneOnOneAiDetails",
    component: ROUTE_COMPONENTS.OneOnOneDetail,
    permission: UserPermissions.viewAiFeedback,
    exact: true,
  },
  OneOnOnePrepareFeedback: {
    path: "/one-on-ones/:oneOnOneId/prepare-feedback/:prepareAiFeedbackId",
    title: "PrepareAiFeedback",
    component: ROUTE_COMPONENTS.OneOnOnePrepareFeedback,
    permission: UserPermissions.viewAiFeedback,
    exact: true,
  },

  // Not Found
  NotFound: {
    path: "/404",
    title: "Page Not Found",
    component: ROUTE_COMPONENTS.NotFound,
    exact: true,
    permission: UserPermissions.AllUserRoleRouteAccess,
  },
};
