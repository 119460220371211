import { BehaviourTypes } from './competency.interface';
import {
  IAnswer,
  IChannelMenuOption,
  IMemberOption,
  IMsTeamsChannelUser,
  ISimpleMenuOption,
  ISlackWorkspaceUser,
  StartEndDates
} from 'interfaces';

interface IDimensionInfo {
  id: string;
  focusAreas?: {
    id: string;
    sug: boolean;
    str: boolean;
    suggestions?: { id: string }[];
  }[];
}

interface IFeedbackParticipant {
  name: string;
  user_id: string;
}

export interface IFeedback {
  subType: string;
  createdAt: number;
  blocks?: IAnswer[];
  anonymous: boolean;
  impact_text: string;
  feedback_id: string;
  feedback_text: string;
  strength_text: string;
  behavior_text: string;
  feedback_type: string;
  template_type: string;
  feedbackType?: {
    id: string;
    title: string
  };
  situation_text: string;
  sd_nextStep_text: string;
  sbi_nextStep_text: string;
  feedbackTypeHeader: string;
  developmentArea_text: string;
  sender: IFeedbackParticipant;
  receiver: IFeedbackParticipant;
  dimensionInfos?: IDimensionInfo[];
  receiverSlackUsers?: ISlackWorkspaceUser[];
  receiverUsers?: string[];
  customFeedbackReceivers?: IMsTeamsChannelUser[];
}


interface IDimensionsMap {
  id: string;
  name: string;
  description: string;
}

interface IFocusAreasMap {
  id: string;
  name: string;
}

interface ISuggestionsMap {
  id: string;
  text: string;
  type: string;
  desc: string;
  url: string;
  title: string
  focusAreaId: string;
}

export interface IFeedbackMaps {
  dimensionsMap: {
    [key: string]: IDimensionsMap;
  };
  focusAreasMap: {
    [key: string]: IFocusAreasMap;
  };
  suggestionsMap: {
    [key: string]: ISuggestionsMap
  }
}

export interface IFeedbacks {
  maps: IFeedbackMaps;
  feedbacks: IFeedback[];
}

export enum FeedbackTemplateTypes {
  FreeText = 'freeText',
  SBI = 'SBI',
  SaD = 'SaD',
}

export interface IFeedbackAnalyticsRequestData {
  endDate: string;
  startDate: string;
  departmentIds: string[];
  selectedGroups: string[];
  teambleTeamIds: string[];
  teambleUserId: string | null;
  relationshipId?: string;
  insightsFor: string | undefined;
  isReport?: boolean | string;
}

export interface IFeedbackInsightsPersonalOverview {
  totalFeedbackSent: number;
  totalFeedbackReceived: number;
  totalFeedbackRequested: number;
  totalPraiseSent: number;
  totalPraiseReceived: number;
  totalSurveysSubmitted: number;
}

export interface IFeedbackInsightsNonPersonalOverview {
  totalFeedbackCount: number;
  totalFeedbackRequestCount: number;
  totalPublicPraise: number;
  totalSurveySubmittedCount: number;
  userParticipatedCount: number;
};

export type IFeedbackInsightsOverview =
  IFeedbackInsightsPersonalOverview | IFeedbackInsightsNonPersonalOverview;

export enum FeedbackInsightTypes {
  PERSONAL = 'personalInsights',
  CHANNEL_OR_WORKSPACE = 'workspaceOrChannelInsights',
  DEPARTMENT = 'departmentInsights',
  RELATIONSHIP = 'relationshipInsights'
}

export interface IFeedbackTreemapChartData {
  category: string;
  Details: number;
  backgroundColor: string;
  competencyId: string;
  behaviourId?: string;
  behaviourType?: BehaviourTypes;
}

export interface IFeedbackInsightsCompetencyOrBehaviourChartDataResponse {
  id?: string;
  name: string;
  avg: string;
  dimensionId: string;
  title?: string;
}

export interface IFeedbackInsightsCompetencyOrBehaviourChartData extends
  IFeedbackInsightsCompetencyOrBehaviourChartDataResponse {
  behaviourType?: BehaviourTypes;
}

export interface IFeedbackInsightsChartData {
  competency: IFeedbackInsightsCompetencyOrBehaviourChartData[];
  strengthCompetency: IFeedbackInsightsCompetencyOrBehaviourChartData[];
  developmentCompetency: IFeedbackInsightsCompetencyOrBehaviourChartData[];
}

export interface IFeedbackInsightsChartDataResponse {
  competency: IFeedbackInsightsCompetencyOrBehaviourChartDataResponse[];
  strengthCompetency: IFeedbackInsightsCompetencyOrBehaviourChartDataResponse[];
  developmentCompetency: IFeedbackInsightsCompetencyOrBehaviourChartDataResponse[];
}

export interface IFeedbackAnalyticsFilters {
  startEndDate: StartEndDates,
  insightType: ISimpleMenuOption,
  selectedDepartments: ISimpleMenuOption[];
  allOrganizationOptionIds?: string[];
  selectedChannelOrWorkspaceUser: IMemberOption;
  selectedChannelOrWorkspaces: IChannelMenuOption[];
  selectedGroupFeedbackTypeOption: ISimpleMenuOption | undefined;
}

export interface IFeedbackAnalyticsFiltersProps extends Omit<IFeedbackAnalyticsFilters, 'allOrganizationOptionIds'> {
  loading: boolean;
  workspaceOrChannelOptions: IChannelMenuOption[];
  setStartEndDate: (date: StartEndDates) => void,
  setSelectedChannelOrWorkspaces: (option: IChannelMenuOption[]) => void,
  setInsightType: (options: ISimpleMenuOption) => void,
  setSelectedChannelOrWorkspaceUser: (options: IMemberOption) => void;
  onSelectedGroupFeedbackTypeOption: (option: ISimpleMenuOption) => void;
  setLoading: (val: boolean) => void;
  setSelectedDepartments: (options: ISimpleMenuOption[]) => void;
}

export interface IFeedbackType {
  id: string;
  title: string;
  isEnabled: boolean;
}

export interface IFeedbackTagFilters {
  competency?: string;
  behaviour?: string;
  behaviourType?: string;
}

export interface IFeedbackInsightsContext {
  handleTreemapChartClick: ((data: IFeedbackTreemapChartData) => void) | null;
}