import { ObjectiveCampaignListProps } from "interfaces/objective-campaign.interface";
import { useMemo } from "react";
import { Column } from "react-table";

export const useObjectiveListColumns = (): readonly Column<ObjectiveCampaignListProps>[] => {

  const formatDate = (timestamp?: number) => {
    if (!timestamp) return "Not Scheduled";
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long", // Full month name
      day: "numeric",
    });
  };

  return useMemo<readonly Column<ObjectiveCampaignListProps>[]>(() => {
    return [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "State",
        accessor: "state",
      },
    ];
    /* Title Column */
  }, []);
};
