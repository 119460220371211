import { InvalidPairsModal } from '@common/components';
import { faFileDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormContext } from 'contexts';
import { getFeedbackForPairsFromFile } from 'data';
import { FormikHelpers, useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  FileFormats,
  FormFieldTypeConstants,
  IAudienceUploadFormValues,
  IFeedbackLoopAudiencePair,
  IFeedbackLoopInspectorPair,
  SignInWith,
} from 'interfaces';
import React from 'react';
import { finalize } from 'rxjs';
import { AUDIENCE_UPLOAD_VALIDATION_SCHEMA } from 'validation-schemas';

interface IFeedbackForUploadPairsFieldProps {
  feedbackLoopLibraryId: string;
  onFileUpload: (isUploading: boolean, audiencePairs?: IFeedbackLoopAudiencePair[]) => void;
}

const FeedbackForUploadPairsField: React.FC<IFeedbackForUploadPairsFieldProps> = (
  { feedbackLoopLibraryId, onFileUpload }
) => {

  const [invalidAudiencePairs, setInvalidAudiencePairs] = React.useState<IFeedbackLoopInspectorPair[] | null>(null);
  const [showInvalidAudiencePairsModal, setShowInvalidAudiencePairsModal] = React.useState<boolean>(false);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  /* App hook */
  const app = useApp();


  const getFileUploadFormData =
    (values: IAudienceUploadFormValues): FormData | null => {

      if (!values.file) {
        return null;
      }

      const formData = new FormData();
      formData.append('file', values.file);
      formData.append('feedbackLibraryLoopId', feedbackLoopLibraryId);

      return formData;
    };


  const submitFileUploadForm = (
    values: IAudienceUploadFormValues,
    formikHelpers: FormikHelpers<IAudienceUploadFormValues>
  ) => {

    const formData = getFileUploadFormData(values);

    if (formData) {
      formikHelpers.setSubmitting(true);
      onFileUpload(true);

      getFeedbackForPairsFromFile(formData)
        .pipe(
          finalize(
            () => {
              formikHelpers.setSubmitting(false);
              onFileUpload(false);
            }
          )
        )
        .subscribe(
          (response) => {

            const newAudiencePairs = response.data.data.verifiedEmailPairs;
            const invalidAudiencePairs = response.data.data.unVerifiedEmailPairs;

            if (newAudiencePairs.length) {
              onFileUpload(false, newAudiencePairs);
            }

            if (invalidAudiencePairs.length) {
              setShowInvalidAudiencePairsModal(true);
              setInvalidAudiencePairs(invalidAudiencePairs)
            }

            formikHelpers.setValues({
              file: ''
            });
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            formikHelpers.setTouched({
              file: false
            });

          }
        );

    }

  };


  const fileUploadForm = useFormik<IAudienceUploadFormValues>({
    initialValues: {
      file: ''
    },
    validationSchema: AUDIENCE_UPLOAD_VALIDATION_SCHEMA,
    onSubmit: submitFileUploadForm
  });


  const downloadSampleFile = () => {

    window.open(
      'https://static.teamble.com/sample-files/Teamble_senders-receivers.xlsx',
      '_blank'
    );

  };


  return (
    <>
      <FormContext.Provider value={fileUploadForm}>
        <div className='d-flex align-items-start mt-3'>
          <div className='flex-grow-1'>
            <FormField
              label=''
              ref={fileInputRef}
              type={FormFieldTypeConstants.File}
              controlName='file'
              controlId='uploadAudienceField'
              fieldDescription='Supported file formats are .xlsx and .csv'
              acceptedFileFormats={[FileFormats.CSV, FileFormats.XLSX, FileFormats.XLS]} />
          </div>
          <Button
            variant='outline-primary'
            disabled={fileUploadForm.isSubmitting}
            className='flex-shrink-0 ms-3'
            onClick={downloadSampleFile}
          >
            <FontAwesomeIcon icon={faFileDownload} className='me-1' />
            Download Sample file
          </Button>
          <Button
            disabled={fileUploadForm.isSubmitting}
            className='flex-shrink-0 ms-3'
            onClick={() => fileUploadForm.submitForm()}
          >
            <FontAwesomeIcon icon={faUpload} className='me-1' />
            {fileUploadForm.isSubmitting ? 'Uploading...' : 'Upload'}
          </Button>
        </div>
      </FormContext.Provider>
      {
        invalidAudiencePairs?.length
          ?
          <InvalidPairsModal
            description={
              'These pairs are not added in the list as either reviewee or reviewer does not' +
                ' belong to the selected ' + (app === SignInWith.Slack ? 'workspace.' : 'group.')
            }
            firstColumnName='Feedback Senders'
            secondColumnName='Feedback Receivers'
            show={showInvalidAudiencePairsModal}
            invalidPairs={
              invalidAudiencePairs.map(
                ({ sender, receiver }) => ({
                  firstMemberName: sender, secondMemberName: receiver
                })
              )
            }
            onClose={() => setShowInvalidAudiencePairsModal(false)} />
          :
          null
      }
    </>
  );
};

export default FeedbackForUploadPairsField;