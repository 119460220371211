import { Button, Modal } from '@themesberg/react-bootstrap';
import { IModalProps, ISurveyBuilderFormValues } from 'interfaces';
import * as React from 'react';
import SurveyQuestionPreview from '../components/SurveyQuestionPreview';

interface ISurveyPreviewModalProps extends IModalProps {
  survey: ISurveyBuilderFormValues;
}

const SurveyPreviewModal: React.FunctionComponent<ISurveyPreviewModalProps> = (
  { survey, show, onClose }
) => {
  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={onClose}
      size='lg'
    >
      <div className='p-3'>
        <Modal.Body>
          {
            survey.blocks.map(
              (question, index) => {
                return (
                  <SurveyQuestionPreview
                    key={index}
                    index={index}
                    question={question}
                    showAllGroupQuestions />
                );
              }
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className="ms-auto" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SurveyPreviewModal;
