import Axios, { AxiosObservable } from "axios-observable";
import { IDepartment, IHookResponse, IResponse, ISimpleMenuOption, ISlackWorkspaceUser } from "interfaces";
import React from "react";
import { finalize, map } from 'rxjs';

export const getUserDepartmentList = (): AxiosObservable<IResponse<IDepartment[]>> => {
  return Axios.post<IResponse<IDepartment[]>>('/list-departments');
};

export const getUserDepartmentsAsMenuOptions = (): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {

  return getUserDepartmentList().pipe(
    map(
      (response) => {
        return {
          ...response,
          data: {
            ...response.data,
            data: response.data.data.map(
              (option) => ({
                ...option,
                value: option.id,
                label: option.name,
              })
            )
          }

        };
      }
    )
  );

};

export const createDepartment = (name: string): AxiosObservable<IResponse<IDepartment>> => {
  return Axios.post<IResponse<IDepartment>>("/create-department", { name });
}

export const updateDepartment = (id: string, name: string): AxiosObservable<IResponse<IDepartment>> => {
  return Axios.post<IResponse<IDepartment>>("/update-department", { name, id });
}

export const deleteDepartmentRequest =
  (id: string, initialCheck: boolean): AxiosObservable<IResponse<ISlackWorkspaceUser[]>> => {
    return Axios.post<IResponse<ISlackWorkspaceUser[]>>("/remove-department", { id, initialCheck });
  };


export const getDepartmentDetails = (
  departmentId: string
): AxiosObservable<IResponse<IDepartment>> => {

  return Axios.post<IResponse<IDepartment>>(
    "/detail-department",
    { id: departmentId }
  )
    .pipe(
      map(
        (response) => {

          response.data.data.members = response.data.data.members.map(
            (member) => (
              {
                ...member,
                teambleUserId: member.id
              }
            )
          );

          return response;
        }
      )
    );
};


export const useDepartmentDetailsData = (
  departmentId: string | undefined
): IHookResponse<IDepartment> => {

  const [departmentDetails, setDepartmentDetails] = React.useState<IDepartment>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  React.useEffect(
    () => {

      setDepartmentDetails(undefined);

      if (!departmentId) {
        return;
      }

      setIsLoading(true);
      const subscription = getDepartmentDetails(departmentId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {

            setDepartmentDetails(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [departmentId]
  );


  return {
    isLoading,
    data: departmentDetails,
  };

};