import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Collapse, Row } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormikProps } from 'formik';
import { FormFieldTypeConstants, IActiveReviewFormValues, IRelationshipsResponse, ISimpleMenuOption, ShareReviewResultTypes } from 'interfaces';
import React from 'react';
import { REVIEW_RESULT_CAN_SHARE_BY_OPTIONS, REVIEW_RESULT_SHARE_AFTER_OPTIONS, REVIEW_RESULT_SHARE_WITH_OPTIONS, SHARE_REVIEW_RESULT_OPTIONS } from 'teamble-constants';

interface ILaunchReviewShareResultConfigProps {
  launchNewReviewForm: FormikProps<IActiveReviewFormValues>;
  relationshipsOptions: ISimpleMenuOption[]
}

const LaunchReviewShareResultConfig: React.FC<ILaunchReviewShareResultConfigProps> = ({
  launchNewReviewForm,
  relationshipsOptions
}) => {

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

  return (
    <Card className='my-2'>

      <Card.Header
        aria-controls='launchReviewShareResultConfigWidget'
        aria-expanded={!isCollapsed}
        className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Card.Title className='m-0' as='h6'>
          Share Result
        </Card.Title>
        <Button variant='link' size='sm'>
          <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
        </Button>
      </Card.Header>

      <Collapse in={!isCollapsed}>
        <Card.Body id='launchReviewShareResultConfigWidget'>
          <FormField
            size='lg'
            controlName='shareWithType'
            controlId='shareWithTypeFieldManual'
            type={FormFieldTypeConstants.Radio}
            options={[SHARE_REVIEW_RESULT_OPTIONS[0]]} />

          {
            launchNewReviewForm.values.shareWithType === ShareReviewResultTypes.MANUAL &&
            <div className='w-50'>
              <FormField
                multiple
                size='lg'
                label='Can be shared by'
                controlName='canShareResult'
                controlId='canShareResultField'
                type={FormFieldTypeConstants.Select}
                options={REVIEW_RESULT_CAN_SHARE_BY_OPTIONS}
              />
            </div>
          }

          <FormField
            size='lg'
            controlName='shareWithType'
            controlId='shareWithTypeFieldAutomated'
            type={FormFieldTypeConstants.Radio}
            options={[SHARE_REVIEW_RESULT_OPTIONS[1]]} />

          {
            launchNewReviewForm.values.shareWithType === ShareReviewResultTypes.AUTOMATED &&
            <Row>
              <Col>
                <FormField
                  multiple
                  size='lg'
                  label='Share with *'
                  controlName='shareResultWith'
                  controlId='shareResultWithField'
                  type={FormFieldTypeConstants.Select}
                  options={[...relationshipsOptions as IRelationshipsResponse[], { label: "Reviewees", value: "reviewees" }]}
                />
              </Col>

              <Col>
                <FormField
                  size='lg'
                  label='Share after *'
                  controlName='shareResultAfter'
                  controlId='shareResultAfterField'
                  type={FormFieldTypeConstants.Select}
                  options={REVIEW_RESULT_SHARE_AFTER_OPTIONS}
                />
              </Col>
            </Row>
          }

        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default LaunchReviewShareResultConfig;