import { Button } from '@themesberg/react-bootstrap';
import { ActiveReviewModalToShow, ActiveReviewUpdateStatus } from 'interfaces';
import React from 'react'

interface IReviewDetailsPageActionsProps {
  reviewUpdateState: ActiveReviewUpdateStatus | undefined;
  onActionClick: (action: ActiveReviewModalToShow) => void;
}

const ReviewDetailsPageActions: React.FC<IReviewDetailsPageActionsProps> = (
  { reviewUpdateState, onActionClick }
) => {

  return (
    <div>
      <Button
        type='button'
        size='sm'
        className='ms-3'
        variant='secondary'
        disabled={!!reviewUpdateState}
        onClick={() => onActionClick('reminderNote')}
      >
        {
          reviewUpdateState === 'sendingReminders' ?
            'Sending Reminders...' : 'Send Reminders'
        }
      </Button>

      <Button
        type='button'
        size='sm'
        className='ms-3'
        disabled={!!reviewUpdateState}
        onClick={() => onActionClick('finalizeReview')}
      >
        {
          reviewUpdateState === 'finalizing' ?
            'Finalizing Review...' : 'Finalize Review'
        }
      </Button>
    </div>
  );
};

export default ReviewDetailsPageActions;