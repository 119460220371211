import React from "react";
import "./OneOnOneObjectivesCard.scss";
import HeaderText from "modules/1-on-1s/components/HeaderText/HeaderText";
import { IObjectiveValues, SignInWith } from "interfaces";
import { useApp } from "hooks";

interface OneOnOneObjectivesCardProps {
  revieweeObjectives: IObjectiveValues[];
}

const OneOnOneObjectivesCard: React.FC<OneOnOneObjectivesCardProps> = ({
  revieweeObjectives,
}) => {
  const app = useApp();
  const getKpiValuePairs = (data: {
    [key: string]: { description: string | null | undefined };
  }): string[] =>
    Object.values(data)
      .filter((entry) => entry?.description)
      .map((entry) => entry.description ?? "");

  return (
    <div className="OneOnOneObjectivesCard">
      <div className="header-container">
        <HeaderText
          text={app === SignInWith.Slack ? "Objectives" : "Goals"}
          fontSize={"24px"}
          lineHeight={"24px"}
          letterSpacing={"-0.24px"}
        />
      </div>
      <div className="container">
        {revieweeObjectives?.length === 0 && (
          <div className="no-notes">No objectives set for the reviewee</div>
        )}
        {revieweeObjectives?.map((item, index) => (
          <div key={index} className="questions">
            <div className="information">
              {item?.name && (
                <HeaderText
                  text={item?.name}
                  fontSize={"18px"}
                  lineHeight={"18px"}
                />
              )}
              {item?.description && (
                <div className="subtext">{item?.description}</div>
              )}
            </div>
            {item?.keyValues &&
              getKpiValuePairs(item?.keyValues).length > 0 && (
                <div className="kpis">
                  <HeaderText
                    text={"KPIs"}
                    fontSize={"14px"}
                    lineHeight={"14px"}
                  />
                  <div className="kpis-container">
                    {getKpiValuePairs(item?.keyValues)?.map((kpi, index) => (
                      <div className="kpi-name" key={index}>
                        {kpi}
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OneOnOneObjectivesCard;
