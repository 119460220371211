import React, { Dispatch, useState } from "react";
import "./Actionability.scss";
import AiQuestionCard from "../AiQuestionCard/AiQuestionCard";
import AiToolsDescriptionBadge from "../AiToolDescriptionBadge/AiToolsDescriptionBadge";

import GradientPrimaryButton from "../../../../components/GradiantPrimaryButton/GradientPrimaryButton";
import HeaderText from "../../../../components/HeaderText/HeaderText";
import ImageCard from "../../../../components/ImageCard/ImageCard";
import bannerImageOne from "assets/img/Image1.png";
import bannerImageTwo from "assets/img/Image2.png";
import bannerImageThree from "assets/img/Image3.png";
import bannerImageFour from "assets/img/Image4.png";
import { FeedbackData } from "modules/1-on-1s/types";
import { addActionabilityToUserFeedback } from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import { AxiosError } from "axios";

const actionabilityCard = [
  {
    id: 1,
    image: bannerImageOne,
    headerText: "Teamble Sales Academy: Crafting Powerful demos",
    subtext: "Top tips on crafting powerful sales demos convert.",
  },
  {
    id: 2,
    image: bannerImageTwo,
    headerText: "Discovery and Demo Craftsmanship 101",
    subtext: "5 Micro learning videos; 10 Smart nudges; 2 Quizes",
  },
  {
    id: 3,
    image: bannerImageThree,
    headerText: "Discovery and Demo Craftsmanship 101",
    subtext: "Expert advice on how to make demos",
  },
  {
    id: 4,
    image: bannerImageFour,
    headerText: "How to communicate with clients",
    subtext:
      "Client communication is one of the most important aspects of the job!",
  },
];

interface ActionabilityProps {
  data: FeedbackData | null;
  setData: Dispatch<React.SetStateAction<FeedbackData | null>>;
  actionableSteps: string;
  setActionableSteps: Dispatch<React.SetStateAction<string>>;
  selectedBannerImage: any[];
  setSelectedBannerImage: Dispatch<React.SetStateAction<any[]>>;
  feedbackValue: string;
}

const Actionability: React.FC<ActionabilityProps> = ({
  data,
  setData,
  actionableSteps,
  setActionableSteps,
  selectedBannerImage,
  setSelectedBannerImage,
  feedbackValue,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    if (!actionableSteps || !feedbackValue || !data?.id) return;
    setIsLoading(true);
    addActionabilityToUserFeedback(data.id, feedbackValue, actionableSteps)
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe({
        next: (response: any) => {
          setData({ ...response.data.feedback });
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  };

  return (
    <div className="Actionability">
      <AiToolsDescriptionBadge
        label={"AI Tools"}
        value={"Actionability"}
        desc={"Best in class feedback should have actionable next steps.."}
      />
      <AiQuestionCard
        label={"Suggestions & Next steps"}
        question={"How do you want to proceed"}
        placeholder={"Currently unclear, please provide guidance..."}
        options={data?.actionableSteps ?? []}
        value={actionableSteps}
        setValue={setActionableSteps}
        section="actionability"
      />
      {process.env.NODE_ENV === "development" && (
        <div className="learning-section">
          <HeaderText text={"Suggested learning content"} fontSize={"16px"} />
          <div className="learning-cards-container">
            {actionabilityCard.map((item, index) => (
              <ImageCard
                data={item}
                key={index}
                setSelectedBannerImage={setSelectedBannerImage}
                isSelected={selectedBannerImage?.find(
                  (val: any) => val.id === item?.id
                )}
              />
            ))}
          </div>
        </div>
      )}

      <div className="btn-container">
        <GradientPrimaryButton
          text={"Generate"}
          disabled={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default Actionability;
