import { Card } from '@themesberg/react-bootstrap';
import ProfileCover from 'assets/img/user_profile_widget_bg.png';
import { ImageWithNameInitials } from 'components';
import { IUserDetailsContext } from 'interfaces';
import React from 'react';

import { useUserDetails } from '../hooks/useUserDetails';
import UserManagersAccordion from './UserManagersAccordion';

interface IUserDetailsWidgetProps { }

const UserDetailsWidget: React.FC<IUserDetailsWidgetProps> = () => {

  const { userDetails } = useUserDetails() as IUserDetailsContext;


  return (
    <Card border="light" className="p-0 mb-4">
      <div style={{ backgroundImage: `url(${ProfileCover})`, height: 110 }} className="profile-cover rounded-top flex-shrink-0" />
      <Card.Body className='flex-grow-0 text-center'>
        <div className='mt-n6'>
          <ImageWithNameInitials
            size='lg'
            label={userDetails.name}
            imageOriginal={userDetails.imageOriginal}
          />
        </div>
        <Card.Title>{userDetails.name}</Card.Title>
        {
          userDetails.role &&
          <p>
            <strong>Role: </strong>
            <span>{userDetails.role.name}</span>
          </p>
        }
        {
          userDetails.department &&
          <p>
            <strong>Department: </strong>
            <span>{userDetails.department.name}</span>
          </p>
        }

        {
          userDetails.relationshipsData?.length
            ?
            userDetails.relationshipsData.map(
              (relationship) => (
                <p key={relationship.relationshipId}>
                  <strong>{relationship.name}: </strong>
                  <span>
                    {
                      relationship.attachments.map(
                        (attachment) => attachment.from
                      ).join(', ')
                    }
                  </span>
                </p>
              )
            )
            :
            null
        }
      </Card.Body>
      <Card.Footer>
        <UserManagersAccordion />
      </Card.Footer>
    </Card>
  );
};

export default UserDetailsWidget;