import { IGroup } from "interfaces";

export const getGroupName = (group: IGroup): string => {

  if (group.isCustom) {
    return group.name;
  }

  if (group?.msteams_name) {
    return `${group.msteams_name}${group.msteams_channel_name ? ' -> ' + group.msteams_channel_name : ''}`
  }

  return group.name;

};


export const getGroupNameInitials = (group: IGroup): string => {

  if (!group.isCustom && (group.msteams_channel_name || group.msteams_name)) {
    let firstCharacter = group?.msteams_name?.[0]?.toUpperCase() || '';
    let secondCharacter = group?.msteams_channel_name?.[0]?.toUpperCase() || group?.msteams_name?.[1]?.toUpperCase() || '';
    return firstCharacter + secondCharacter;
  }

  return group.name
    .split(' ')
    .map((name) => name.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('')

}