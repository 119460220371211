import {
  FormErrors,
  IAwaitingNominationFormValues,
  IAddReviewersFormValues,
  INominationActionFormValues
} from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const AWAITING_NOMINATION_VALIDATION_SCHEMA: Yup.SchemaOf<IAwaitingNominationFormValues> =
  Yup.object().shape(
    {
      nominees: Yup
        .array()
        .of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
        .required(FormErrors.Required)
        .min(1, FormErrors.Required),
    }
  );


export const NOMINATION_ACTION_VALIDATION_SCHEMA: Yup.SchemaOf<INominationActionFormValues> =
  Yup.object().shape(
    {
      nominations: Yup.array().of(
        Yup.object().shape(
          {
            approveStatus: Yup.string()
              .required(FormErrors.Required),
            reason: Yup.string()
          }
        )
      )
    }
  );


export const ADD_REVIEWERS_VALIDATION_SCHEMA: Yup.SchemaOf<IAddReviewersFormValues> =
  Yup.object().shape(
    {
      reviewers: Yup
        .array()
        .of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
        .required(FormErrors.Required)
        .min(1, FormErrors.Required),
    }
  );