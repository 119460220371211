import { Button, Card, Form } from "@themesberg/react-bootstrap";
import { AxiosResponse } from "axios";
import { ConfirmationModal, PermissionErrorToast } from "components";
import { launchFeedbackLoop } from "data";
import { useApp, useIsChannelInAudience } from "hooks";
import {
  IFeedbackLoop,
  IFeedbackTypes,
  IResponse,
  PermissionErrorTypes,
  SignInWith,
} from "interfaces";
import AudienceConfigWidget from "modules/feedback-loops/components/AudienceConfigWidget";
import FeedbackForConfigWidget from "modules/feedback-loops/components/FeedbackForConfigWidget";
import FeedbackLoopRequestTimeoutWidget from "modules/feedback-loops/components/FeedbackLoopRequestTimeout";
import FeedbackLoopSchedulerWidget from "modules/feedback-loops/components/FeedbackLoopSchedulerWidget";
import FeedbackTypeConfigWidget from "modules/feedback-loops/components/FeedbackTypeConfigWidget";
import NotificationMessageConfig from "modules/feedback-loops/components/NotificationMessageConfig";
import SendNotificationConfigWidget from "modules/feedback-loops/components/SendNotificationConfigWidget";
import { useFeedbackLoopConfig } from "modules/feedback-loops/contexts/feedbackLoopConfigContext";
import { useFeedbackLoopLaunchErrors } from "modules/feedback-loops/hooks/useFeedbackLoopLaunchErrors";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Routes } from "routes";
import { finalize } from "rxjs";



interface IFeedbackLoopConfigWidgetProps {}

const OneOnOneConfigWidgetPage: React.FC<
  IFeedbackLoopConfigWidgetProps
> = () => {
  const [launchCount, setLaunchCount] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState<boolean>(false);

  const app = useApp();

  // Feedback Loop config hook
  const { feedbackLoop } = useFeedbackLoopConfig();

  const launchError = useFeedbackLoopLaunchErrors(feedbackLoop);

  const history = useHistory();

  const isChannelInAudience = useIsChannelInAudience(feedbackLoop.audience);

  const handleLaunchedFeedbackLoop = (
    response: AxiosResponse<IResponse<IFeedbackLoop>>
  ): void => {
    if (response.data.errorInfo?.length) {
      toast.error(
        <PermissionErrorToast
          errorInfos={response.data.errorInfo}
          permissionErrorType={PermissionErrorTypes.LaunchFeedbackLoop}
        />,
        {
          autoClose: 10000,
          icon: false,
        }
      );
    } else {
      toast.success(response.data.message);
    }
    history.push(Routes.ManageFeedbackLoopsPage.path);
  };

  const handleLaunchFeedbackLoop = (isInitial: boolean): void => {
    setLaunchCount(launchCount + 1);

    if (launchError) {
      return;
    }

    if (isInitial) {
      setShowConfirmationModal(true);
      return;
    }

    setIsLoading(true);

    launchFeedbackLoop(feedbackLoop.id)
      .pipe(
        finalize(() => {
          setIsLoading(false);
          setShowConfirmationModal(false);
        })
      )
      .subscribe((response) => {
        handleLaunchedFeedbackLoop(response);
      });
  };

  return (
    <>
      <Card className="overflow-hidden h-100">
        <Card.Header className="flex-shrink-0">
          <Card.Title className="m-0">Configurations</Card.Title>
        </Card.Header>

        <Card.Body className="flex-grow-1 overflow-y-auto">
          
          <div className="my-3">
            <AudienceConfigWidget />
          </div>
          {feedbackLoop.feedbackType !== IFeedbackTypes.Company && (
            <div className="my-3">
              <FeedbackForConfigWidget />
            </div>
          )}
          <div className="my-3">
            <FeedbackLoopSchedulerWidget />
          </div>

          {/* <div className="my-3">
            <FeedbackLoopRequestTimeoutWidget />
          </div> */}

          {app === SignInWith.Slack && isChannelInAudience && (
            <div className="my-3">
              <SendNotificationConfigWidget />
            </div>
          )}
          <div className="my-3">
            <NotificationMessageConfig />
          </div>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-between align-items-center flex-shrink-0">
          <div>
            {launchError && launchCount > 0 ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {launchError}
              </Form.Control.Feedback>
            ) : null}
          </div>
          <div>
            {/* <Link
              to={`${Routes.FeedbackLoopsListPage.path}/${feedbackLoop.id}`}
            > */}
              <Button
              onClick={()=>{history.goBack()}}
              variant="outline-success">Previous</Button>
            {/* </Link> */}
            <Button
              className="ms-2"
              onClick={() => handleLaunchFeedbackLoop(true)}
              disabled={isLoading}
            >
              {isLoading ? "Launching..." : "Launch Now"}
            </Button>
          </div>
        </Card.Footer>
      </Card>
      <ConfirmationModal
        show={showConfirmationModal}
        title="Launch Feedback Loop"
        isSubmitting={isLoading}
        submittingText="Launching..."
        positiveText="Launch"
        closeOnUserResponse={false}
        onCloseClick={() => setShowConfirmationModal(false)}
        onNegativeResponse={() => setShowConfirmationModal(false)}
        onPositiveResponse={() => handleLaunchFeedbackLoop(false)}
      >
        <p>
          Are you sure to launch the feedback loop{" "}
          <strong>{feedbackLoop.title}</strong>?
        </p>
      </ConfirmationModal>
    </>
  );
};

export default OneOnOneConfigWidgetPage;
