import { IPermissionErrorInfo, PermissionErrorTypes } from 'interfaces';
import * as React from 'react';

interface IPermissionErrorToastProps {
  errorInfos: IPermissionErrorInfo[];
  permissionErrorType: PermissionErrorTypes
}

const PermissionErrorToast: React.FunctionComponent<IPermissionErrorToastProps> = (
  { errorInfos, permissionErrorType }
) => {

  const [userNames, setUserNames] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [solution, setSolution] = React.useState('');


  React.useEffect(
    () => {

      const names = errorInfos.map(
        (errorUserInfo) => errorUserInfo.name
      ).join(', ');

      setReason(
        errorInfos[0]?.errorCode === 'BotDisabledByAdmin' ?
          'Your Microsoft Teams admin disabled notifications from the teamble app.' :
          'These users blocked notifications from apps.'
      );

      setSolution(
        errorInfos[0]?.errorCode === 'BotDisabledByAdmin' ?
          '👉 Please contact your Microsoft Teams admin or IT department to enable teamble app notifications.' :
          '👉 Please ping your colleagues to enable notifications from the teamble app. '
      );

      setUserNames(names);

    },
    [errorInfos]
  );


  return (

    <>
      <p>
        {`⚠️ ${userNames} did not receive your ${permissionErrorType}.`}
      </p>
      <p>
        {`💡 Reason: ${reason}`}
      </p>
      <p>
        {solution}
      </p>
      {
        errorInfos[0]?.errorCode !== 'BotDisabledByAdmin' &&
        <p>
          Go to Chat &gt; find teamble app bot &gt; click the … menu &gt; select Unblock bot conversation
        </p>
      }
    </>

  )
};

export default PermissionErrorToast;
