import { Table, useTmblTable, useTmblTableData } from '@common/modules/table';
import { IActiveReview, IActiveReviewAudience, IActiveReviewAudienceActionColumnProps } from 'interfaces';
import * as React from 'react';

import { useActiveReviewAudienceTableColumns } from '../hooks/useActiveReviewAudienceTableColumns';
import ViewReviewResultModal from '../modals/ViewReviewResultModal';

interface IActiveReviewAudienceTableProps {
  reviewerRevieweeInfos: IActiveReviewAudience[];
  isReviewClosed: boolean;
  activeReview: IActiveReview;
  onRemoveAudiencePair: (audience: IActiveReviewAudience) => void;
  onAudiencePairReopen: (audience: IActiveReviewAudience) => void;
  onReviewerChange: (audience: IActiveReviewAudience) => void;
  downloadReport: (perfReviewId: string) => void;
}


const ActiveReviewAudienceTable: React.FunctionComponent<IActiveReviewAudienceTableProps> = ({
  activeReview,
  downloadReport,
  isReviewClosed,
  onReviewerChange,
  onRemoveAudiencePair,
  onAudiencePairReopen,
  reviewerRevieweeInfos,
}) => {

  const [showResultId, setShowResultId] = React.useState<string>('');

  const tableColumns = useActiveReviewAudienceTableColumns(
    isReviewClosed,
    activeReview
  );
  const tableData = useTmblTableData<IActiveReviewAudience>(reviewerRevieweeInfos);

  const tableInstance = useTmblTable<IActiveReviewAudience>(
    tableColumns,
    tableData,
    'reviewee'
  );


  return (
    <>

      <Table<IActiveReviewAudienceActionColumnProps, IActiveReviewAudience>
        stickyHeader={false}
        tableInstance={tableInstance}
        bodyCellProps={{
          onShowResultClick: setShowResultId,
          onRemoveAudiencePair,
          onAudiencePairReopen,
          onReviewerChange,
          downloadReport
        }}
      />

      {
        showResultId &&
        <ViewReviewResultModal
          perfReviewId={showResultId}
          show={!!showResultId}
          onClose={() => setShowResultId('')} />
      }
    </>
  );
};

export default ActiveReviewAudienceTable;
