import * as React from 'react';

const CloseIcon = ({
    width = 12,
    height = 12,
    stroke = 'currentColor',
    ...props
}) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 12 12'
        {...props}
    >
        <g clipPath='url(#a)'>
            <path stroke={stroke} d='m1 1 10 10m0-10L1 11' />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 0h12v12H0z' />
            </clipPath>
        </defs>
    </svg>
);

export default CloseIcon;
