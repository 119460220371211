import { FormikProps } from 'formik';
import { useApp } from 'hooks';
import {
  IAudienceConfigFormValues,
  IAudienceType,
  IChannelsAudience,
  IFeedbackLoop,
  IGroupsAudience,
  IUsersAudience,
  SignInWith
} from 'interfaces';
import React from 'react';


export const useAudienceChange = (
  feedbackLoop: IFeedbackLoop,
  audienceConfigForm: FormikProps<IAudienceConfigFormValues>,
): boolean => {

  const [isAudienceChange, setIsAudienceChange] = React.useState<boolean>(false);

  const app = useApp();


  React.useEffect(
    () => {

      // Check if slack `Entire Workspace` option is already selected as audience
      if (app === SignInWith.Slack && audienceConfigForm.values.audienceType?.value === IAudienceType.Workspace) {

        const isWorkspaceAudience = !!feedbackLoop.audience?.find(
          (aud) => aud.type === IAudienceType.Workspace
        );

        if (!isWorkspaceAudience) {
          setIsAudienceChange(true);
          return;
        }
      }

      // Check if MS Teams `Entire Team` option is already selected as audience
      if (app === SignInWith.MsTeams && audienceConfigForm.values.audienceType?.value === IAudienceType.Team) {

        const isTeamAudience = !!feedbackLoop.audience?.find(
          (aud) => aud.type === IAudienceType.Team
        );

        if (!isTeamAudience) {
          setIsAudienceChange(true);
          return;
        }
      }

      // Check if MS Teams `Entire Team` option is already selected as audience
      if (app === SignInWith.MsTeams && audienceConfigForm.values.audienceType?.value === IAudienceType.Groups) {

        const feedbackLoopChannelAudience = feedbackLoop.audience?.find((aud) => aud.type === IAudienceType.Groups) as IGroupsAudience | undefined;

        if (!feedbackLoopChannelAudience?.groups.length && audienceConfigForm.values.selectedGroups.length) {
          setIsAudienceChange(true);
          return;
        }

        if (feedbackLoopChannelAudience?.groups.length !== audienceConfigForm.values.selectedGroups.length) {
          setIsAudienceChange(true);
          return;
        }

      }

      if (audienceConfigForm.values.audienceType?.value === IAudienceType.Channels) {

        const feedbackLoopChannelAudience = feedbackLoop.audience?.find(
          (aud) => aud.type === IAudienceType.Channels
        ) as IChannelsAudience | undefined;

        if (!feedbackLoopChannelAudience?.channels.length) {

          if (audienceConfigForm.values.selectedOrganizations.length) {
            setIsAudienceChange(true);
            return;
          }

          setIsAudienceChange(false);
          return;
        }

        if (
          feedbackLoopChannelAudience.channels.length !==
          audienceConfigForm.values.selectedOrganizations.length
        ) {
          setIsAudienceChange(true);
          return;
        }

        const feedbackLoopChannelAudienceIds = feedbackLoopChannelAudience.channels.map(
          (channel) => channel.channelInfo.slack.channelId
        );

        const distinctOrgOptions = audienceConfigForm.values.selectedOrganizations.filter(
          (orgOption) => !feedbackLoopChannelAudienceIds.includes(orgOption.value)
        );

        if (distinctOrgOptions.length) {
          setIsAudienceChange(true);
          return;
        }
      }

      if (audienceConfigForm.values.audienceType?.value === IAudienceType.Users) {

        const feedbackLoopUserAudience = feedbackLoop.audience?.find(
          (aud) => aud.type === IAudienceType.Users
        ) as IUsersAudience | undefined;

        if (!feedbackLoopUserAudience?.users.length) {

          if (audienceConfigForm.values.selectedAudienceUsers.length) {
            setIsAudienceChange(true);
            return;
          }

          setIsAudienceChange(false);
          return;
        }

        if (
          feedbackLoopUserAudience.users.length !==
          audienceConfigForm.values.selectedAudienceUsers.length
        ) {
          setIsAudienceChange(true);
          return;
        }

        const feedbackLoopUserAudienceIds = feedbackLoopUserAudience.users.map(
          (user) => user.channelInfo[app].userId
        );

        const distinctUserOptions = audienceConfigForm.values.selectedAudienceUsers.filter(
          (userOption) => !feedbackLoopUserAudienceIds.includes(userOption.value)
        );

        if (distinctUserOptions.length) {
          setIsAudienceChange(true);
          return;
        }
      }

      // Default audience change is false
      setIsAudienceChange(false);

    },
    [
      app,
      audienceConfigForm.values,
      feedbackLoop.audience
    ]
  );


  return isAudienceChange;
};