import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { createSurveyTemplate, updateSurveyTemplate } from 'data';
import { FormikHelpers, useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  FormFieldTypeConstants,
  ICreateOrUpdateSurveyTemplateFormValues,
  ICreateOrUpdateSurveyTemplateRequestData,
  IMetadata,
  IModalProps,
  ISurvey,
  ISurveyBuilderBlock,
  ITemplateVisibilityConstants,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { TEMPLATE_VISIBILITY_METADATA } from 'teamble-constants';
import { CREATE_UPDATE_SURVEY_TEMPLATE_VALIDATION_SCHEMA } from 'validation-schemas';

import { getSurveyTemplateRequestBlocks } from '../utils/utils';

interface ICreateUpdateSurveyTemplateModalProps extends IModalProps {
  templateId?: string;
  surveyId?: string;
  blocks: ISurveyBuilderBlock[];
  survey: ISurvey;
  onSurveyChange: (survey: ISurvey) => void;
}

const CreateUpdateSurveyTemplateModal: React.FunctionComponent<ICreateUpdateSurveyTemplateModalProps> = (
  { show, templateId, surveyId, survey, blocks, onClose, onSurveyChange }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;
  const app = useApp();
  const surveyTemplateVisibilityConstants =
    TEMPLATE_VISIBILITY_METADATA.constants as ITemplateVisibilityConstants;


  const submitSurveyTemplateForm = (
    values: ICreateOrUpdateSurveyTemplateFormValues, formikHelpers: FormikHelpers<ICreateOrUpdateSurveyTemplateFormValues>
  ): void => {

    formikHelpers.setSubmitting(true);

    const requestData: ICreateOrUpdateSurveyTemplateRequestData = {
      title: values.title,
      desc: values.desc,
      [app === SignInWith.Slack ? "workspaceId" : "channelId"]: app === SignInWith.Slack ? survey.workspaceId : survey.channelId,
      visibleToEveryone: values.visibility === surveyTemplateVisibilityConstants.EVERYONE,
      visibleToWorkspaceAdmin: values.visibility === surveyTemplateVisibilityConstants.ADMINS,
      blocks: getSurveyTemplateRequestBlocks(blocks, metadata, app)
    };

    const surveyTemplateSource$ =
      templateId
        ?
        updateSurveyTemplate(
          { templateId, ...requestData },
          metadata,
          app
        )
        :
        createSurveyTemplate(
          { surveyTemplateId: surveyId, ...requestData },
          metadata,
          app
        )

    surveyTemplateSource$
      .pipe(
        finalize(() => formikHelpers.setSubmitting(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          if (templateId) {
            onSurveyChange({
              ...response.data.data,
              title: values.title
            });
          } else {
            onSurveyChange(response.data.data);
          }
          onClose();
        }
      );

  };


  const createOrUpdateSurveyTemplateForm = useFormik<ICreateOrUpdateSurveyTemplateFormValues>(
    {
      initialValues: {
        title: survey.title,
        desc: survey.desc,
        visibility: (survey.visibleToEveryone && surveyTemplateVisibilityConstants.EVERYONE) ||
          (survey.visibleToWorkspaceAdmin && surveyTemplateVisibilityConstants.ADMINS) ||
          surveyTemplateVisibilityConstants.SUPER_ADMIN,
      },
      validationSchema: CREATE_UPDATE_SURVEY_TEMPLATE_VALIDATION_SCHEMA,
      onSubmit: submitSurveyTemplateForm
    }
  );

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          {templateId ? 'Update' : 'Create'} Survey Template
        </Modal.Title>
      </Modal.Header>
      <FormContext.Provider value={createOrUpdateSurveyTemplateForm}>
        <Form onSubmit={createOrUpdateSurveyTemplateForm.handleSubmit}>
          <Modal.Body>

            <FormField
              placeholder='Enter survey template name'
              label='Survey Template Name *'
              controlId='templateTitleField'
              type={FormFieldTypeConstants.Text}
              controlName='title' />

            <FormField
              placeholder='Enter description'
              label='Description'
              controlId='templateDescriptionField'
              type={FormFieldTypeConstants.Text}
              controlName='desc' />

            <FormField
              type={FormFieldTypeConstants.Radio}
              controlName='visibility'
              options={TEMPLATE_VISIBILITY_METADATA.options} />

          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-end'>
            <Button type='button' variant="outline-primary" onClick={onClose}>
              Close
            </Button>
            <Button type='submit' disabled={createOrUpdateSurveyTemplateForm.isSubmitting}>
              {
                createOrUpdateSurveyTemplateForm.isSubmitting ?
                  templateId ? 'Updating Template...' : 'Creating Template...' :
                  templateId ? 'Update Template' : 'Create Template'
              }
            </Button>
          </Modal.Footer>
        </Form>
      </FormContext.Provider>
    </Modal>
  );
};

export default CreateUpdateSurveyTemplateModal;
