import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import React from 'react';

import Preloader from './Preloader';

interface ICollapsibleCardProps {
  title: string;
  isLoading?: boolean;
}

const CollapsibleCard: React.FC<ICollapsibleCardProps> = (
  { title, isLoading = false, children }
) => {

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  return (
    <Card>

      <Card.Header
        className='d-flex justify-content-between align-items-center cursor-pointer bg-light py-2 px-3'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Card.Title className='m-0 me-3 fs-6 fw-bolder flex-grow-1'>
          {title}
        </Card.Title>

        <div className='flex-shrink-0'>
          {
            isLoading &&
            <Preloader isIcon show={isLoading} />
          }
          <OverlayTrigger
            overlay={
              <Tooltip id={`collapsibleCardTooltip${title}`}>
                {isCollapsed ? 'Expand' : 'Collapse'}
              </Tooltip>
            }>
            <Button
              variant='link'
              size='sm'
              className='ms-1'>
              <FontAwesomeIcon
                icon={isCollapsed ? faChevronDown : faChevronUp} />
            </Button>
          </OverlayTrigger>
        </div>
      </Card.Header>

      <Collapse in={!isCollapsed}>
        <div>
          {children}
        </div>
      </Collapse>

    </Card>
  );
};

export default CollapsibleCard;