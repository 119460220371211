import { Divider, MultiChoiceAnswerPreview, TextAnswerPreview } from 'components';
import { MetaDataContext } from 'contexts';
import {
  IAggregation,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IQuestionTypeConstants,
  IReport,
} from 'interfaces';
import * as React from 'react';


interface IReviewReportsPreviewModalBodyProps {
  report: IReport;
}

interface IReportAggregationsProps {
  aggregations: IAggregation[];
  isAnonymous: boolean;
}


const ReportAggregations: React.FunctionComponent<IReportAggregationsProps> = (
  { aggregations, isAnonymous }
) => {

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const questionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;
  const multiSelectQuestionTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;


  return (
    <>
      {
        aggregations.map(
          (aggregation) => {
            switch (aggregation.type) {

              case questionTypeConstants.SHORT_TEXT:
              case questionTypeConstants.LONG_TEXT: {
                return (
                  <React.Fragment key={aggregation.id}>
                    <TextAnswerPreview
                      aggregation={aggregation}
                      isAnonymous={isAnonymous}
                    />
                  </React.Fragment>
                );
              }

              case questionTypeConstants.MULTI_SELECT_QUESTION:
              case questionTypeConstants.OBJECTIVE_QUESTION:
              case questionTypeConstants.COMPETENCY_QUESTION: {

                return (
                  <React.Fragment key={aggregation.id}>
                    <MultiChoiceAnswerPreview
                      aggregation={aggregation}
                      isNpsOrLikelyUnlikely={
                        [
                          multiSelectQuestionTypeConstants.NPS,
                          multiSelectQuestionTypeConstants.LIKELY_UNLIKELY
                        ].includes(aggregation.block.family || '')
                      }
                      isAnonymous={isAnonymous}
                    />
                  </React.Fragment>
                );

              }

              case questionTypeConstants.TITLE:
              case questionTypeConstants.SUBTITLE:
              default: {
                return null;
              }

            }
          }
        )
      }
    </>
  );

};


const ReviewReportsPreviewModalBody: React.FunctionComponent<IReviewReportsPreviewModalBodyProps> = (
  { report }
) => {

  return (
    <div className="pb-3">
      <h3 className="text-center"> Review: {report.title} </h3>
      <h5> Reviewee: {report.revieweeName} </h5>
      {
        report.anonymity === 'non-anonymous' &&
        <h5> Reviewer(s): {report.reviewerName} </h5>
      }
      <p> Total Participants: {report.participantCount} </p>
      <ReportAggregations
        aggregations={report.aggregations}
        isAnonymous={report.anonymity === 'anonymous'} />
      <Divider />
    </div>
  );
}

export default ReviewReportsPreviewModalBody;