import {
  FormErrors,
  IFormQuestionValueMeta,
  IMultiSelectQuestionValue,
  ITextQuestionValue,
  ITitleQuestionValue,
} from 'interfaces';
import * as Yup from 'yup';

export const QUESTION_META_VALIDATION_SCHEMA: Yup.SchemaOf<IFormQuestionValueMeta> =
  Yup.object().shape(
    {
      tempId: Yup.string().defined(),
      type: Yup.string().defined(),
      typeTitle: Yup.string()
    }
  );

export const TITLE_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<ITitleQuestionValue> =
  Yup.object().shape(
    {
      text: Yup.string().required(FormErrors.Required),
    }
  ).notRequired().default(undefined);


export const TEXT_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<ITextQuestionValue> =
  Yup.object().shape(
    {
      label: Yup.string().required(FormErrors.Required),
      placeholder: Yup.string(),
      required: Yup.boolean(),
    }
  ).notRequired().default(undefined);

export const MULTI_SELECT_QUESTION_VALIDATION_SCHEMA: Yup.SchemaOf<IMultiSelectQuestionValue> =
  Yup.object().shape(
    {
      label: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
      required: Yup.boolean(),
      comment: Yup.string().when(
        'enableCommentBox',
        {
          is: true,
          then: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
          otherwise: Yup.string()
        }
      ),
      enableCommentBox: Yup.boolean(),
      dropdown: Yup.boolean(),
      selectedOption: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    }
  ).notRequired().default(undefined);