import {
  FormErrors,
  IMultiSelectQuestionPreviewFormValues,
  ITextQuestionPreviewFormValues,
} from 'interfaces';
import * as Yup from 'yup';

export const TEXT_QUESTION_PREVIEW_VALIDATION_SCHEMA: Yup.SchemaOf<ITextQuestionPreviewFormValues> = Yup.object(
  {
    answer: Yup.string()
      .when(
        'required', {
        is: true,
        then: Yup.string().required(FormErrors.Required),
        otherwise: Yup.string()
      })
      .default(''),
    required: Yup.boolean(),
  }
);

export const MULTI_SELECT_QUESTION_PREVIEW_VALIDATION_SCHEMA: Yup.SchemaOf<IMultiSelectQuestionPreviewFormValues> = Yup.object(
  {
    radioAnswer: Yup.string()
      .when(
        'required', {
        is: true,
        then: Yup.string().required(FormErrors.Required),
        otherwise: Yup.string()
      })
      .default(''),

    dropdownAnswer: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string().defined(),
    }).nullable()
      .when(
        'required', {
        is: true,
        then: Yup.object().shape({
          value: Yup.string(),
          label: Yup.string().defined(),
        }).nullable().required(FormErrors.Required),
        otherwise: Yup.object().shape({
          value: Yup.string(),
          label: Yup.string().defined(),
        }).nullable()
      }),

    commentBoxText: Yup.string()
      .when(
        'commentBox', {
        is: true,
        then: Yup.string().required(FormErrors.Required),
        otherwise: Yup.string()
      }),

    commentBox: Yup.boolean(),
    required: Yup.boolean(),
    dropdown: Yup.boolean(),
  }
);