import { Button, Card, Table } from '@themesberg/react-bootstrap';
import { Preloader, EmptyState, PageTitle, PrimaryLayoutBox, ConfirmationModal, PermissionErrorToast } from 'components';
import { closeSurvey, getSurveyDetails, sendSurveyReminder } from 'data';
import { IRouteParams, ISurveyAudience, ISurveyInspector, ISurveyResponse, SignInWith, PermissionErrorTypes } from 'interfaces';
import * as React from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import SurveyAudience from './components/SurveyAudience';
import SurveyInspectors from './components/SurveyInspectors';
import { useSurveyDuration } from './hooks/useSurveyDuration';
import { getSurveyAudienceFlatArray, getSurveyInspectorsFlatArray } from './utils/utils';
import { useApp } from 'hooks';
interface ISurveyDetailsProps {
}

interface ISurveyDetailsWidgetProps {
  survey: ISurveyResponse;
}


const SurveyDetailsWidget: React.FunctionComponent<ISurveyDetailsWidgetProps> = (
  { survey }
) => {

  const [isClosingReview, setIsClosingReview] = React.useState<boolean>(false);
  const [isSendingReminders, setIsSendingReminders] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const [sendReimderConfirmation, setShowSendReminderConfirmation] = React.useState<boolean>(false);


  const surveyDuration = useSurveyDuration(survey);
  const [surveyAudience, setSurveyAudience] = React.useState<ISurveyAudience[]>([]);
  const [surveyInspectors, setSurveyInspectors] = React.useState<ISurveyInspector[]>([]);

  const history = useHistory();
  const app = useApp();

  React.useEffect(
    () => {
      setSurveyAudience(
        getSurveyAudienceFlatArray(survey)
      );
      setSurveyInspectors(
        getSurveyInspectorsFlatArray(survey)
      );
    },
    [survey]
  )


  const handleCloseSurvey = (): void => {

    setIsClosingReview(true);

    closeSurvey(survey.id)
      .pipe(
        finalize(
          () => setIsClosingReview(false)
        )
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          history.go(0);
        }
      );

  };


  const handleSendReminders = (): void => {

    setIsSendingReminders(true);

    sendSurveyReminder(survey.id)
      .pipe(
        finalize(
          () => setIsSendingReminders(false)
        )
      )
      .subscribe(
        (response) => {
          if (response.data.errorInfo?.length) {
            toast.error(
              <PermissionErrorToast
                errorInfos={response.data.errorInfo}
                permissionErrorType={PermissionErrorTypes.SurveyReminder}
              />,
              {
                autoClose: 10000,
                icon: false
              }
            );
          } else {
            toast.success(response.data.message);
          }
        }
      );
  };


  return (
    <>
      <PageTitle title={survey.title} />
      <PrimaryLayoutBox
        title={survey.title}
        actions={
          <div>
            {
              !(
                survey.anonymity.type === 'anonymous' &&
                (survey.minSubmissionCount && survey.submittedCount < survey.minSubmissionCount)
              ) &&
              (
                survey.state === "closed"
                  ? <Link to={`${Routes.SurveyReports.path}?workspaceOrChannelId=${app === SignInWith.Slack ? survey.workspaceId : survey.channelId}&surveyId=${survey.id}`}>
                    <Button
                      type='button'
                      size='sm'
                      className='ms-3'
                      variant='outline-primary'
                    >
                      View Result
                    </Button>
                  </Link>
                  : (survey.state === "open" && survey.isLiveResults)
                    ? <Link to={`${Routes.SurveyReports.path}?workspaceOrChannelId=${app === SignInWith.Slack ? survey.workspaceId : survey.channelId}&surveyId=${survey.id}`}>
                      <Button
                        type='button'
                        size='sm'
                        className='ms-3'
                        variant='outline-primary'
                      >
                        View Result
                      </Button>
                    </Link>
                    : null
              )
            }

            {
              survey.state === 'open' ?
                (
                  <>
                    {
                      survey.progress !== 100 &&
                      <Button
                        type='button'
                        size='sm'
                        className='ms-3'
                        variant='secondary'
                        disabled={isClosingReview || isSendingReminders}
                        onClick={() => setShowSendReminderConfirmation(true)}
                      >
                        {isSendingReminders ? 'Sending Reminders...' : 'Send Reminders'}
                      </Button>
                    }
                    <Button
                      type='button'
                      size='sm'
                      className='ms-3'
                      disabled={isClosingReview || isSendingReminders}
                      onClick={() => setShowConfirmationModal(true)}
                    >
                      {isClosingReview ? 'Closing Survey...' : 'Close Survey'}
                    </Button>
                  </>
                ) : (null)
            }
          </div>
        }
      >
        <Card className='overflow-hidden flex-grow-1'>
          <Card.Body className='overflow-y-auto'>
            <Table>
              <tbody>
                <tr>
                  <td className='fw-bold'> Launched By: </td>
                  <td> {survey.createdBy.name} </td>
                </tr>
                <tr>
                  <td className='fw-bold'> Closes: </td>
                  <td> {surveyDuration} </td>
                </tr>
                <tr>
                  <td className='fw-bold'> Audience: </td>
                  <td>
                    <SurveyAudience surveyAudience={surveyAudience} />
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'> Result Viewers: </td>
                  <td>
                    <SurveyInspectors surveyInspectors={surveyInspectors} />
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'> Completed: </td>
                  <td>
                    {survey.submittedCount || 0}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'> To Complete: </td>
                  <td>
                    {survey.launchCount - (survey.submittedCount || 0)}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'> Anonymity: </td>
                  <td>
                    {survey.anonymity.type === 'anonymous' ? 'Anonymous' : 'Non Anonymous'}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'> Minimum Submission count: </td>
                  <td>
                    {survey.minSubmissionCount || 0}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'> Result Visibility: </td>
                  <td>
                    {survey.isLiveResults ? 'Real Time' : 'Once survey is closed'}
                  </td>
                </tr>
              </tbody>

            </Table>
          </Card.Body>
        </Card>
      </PrimaryLayoutBox>

      <ConfirmationModal
        title='Close Survey'
        show={showConfirmationModal}
        onPositiveResponse={handleCloseSurvey}
        onCloseClick={() => setShowConfirmationModal(false)}
      >
        <p>
          Are you sure to close the survey <strong>{survey.title}</strong>?
        </p>
        {
          (
            survey.anonymity.type === 'anonymous' &&
            (survey.minSubmissionCount && survey.submittedCount < survey.minSubmissionCount)
          )
            ?
            <p>
              <em>
                ⚠️ Results of this anonymous survey will not be shared since the number of participants has not met the required minimum number.
              </em>
            </p>
            :
            null
        }
      </ConfirmationModal>

      <ConfirmationModal
        title='Send Reminders'
        show={sendReimderConfirmation}
        onPositiveResponse={handleSendReminders}
        onCloseClick={() => setShowSendReminderConfirmation(false)}
      >
        <p>
          <em>
            ⚠️ You are about to send a reminder to {survey.launchCount - (survey.submittedCount || 0)} users, Please confirm
          </em>
        </p>
      </ConfirmationModal>
    </>
  );
};


const SurveyDetails: React.FunctionComponent<ISurveyDetailsProps> = () => {

  const [surveyResponse, setSurveyResponse] = React.useState<ISurveyResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { surveyId } = useParams<IRouteParams>();

  const history = useHistory();


  React.useEffect(
    () => {

      if (!surveyId) {
        return;
      }

      setIsLoading(true);
      setSurveyResponse(null);

      getSurveyDetails(surveyId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          {
            next: (response) => {
              setSurveyResponse(response.data.data);
            },
            error: (error) => {
              if (error.response?.status === 403) {
                history.replace(Routes.ManageSurveys.path);
              }
            }
          }
        )

    },
    [history, surveyId]
  )


  return (
    <>
      {
        isLoading ?
          (<Preloader show={isLoading} />) :
          (
            <>
              {
                surveyResponse ?
                  (
                    <SurveyDetailsWidget
                      survey={surveyResponse} />
                  ) :
                  (
                    <EmptyState message={<>No Survey details available.</>} />
                  )
              }
            </>
          )
      }
    </>
  );
};

export default SurveyDetails;
