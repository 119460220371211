import { Breadcrumb } from '@themesberg/react-bootstrap';
import { IRoute, IRouteParams } from 'interfaces';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Routes } from 'routes';

interface IBreadcrumbsProps {
  routeProps: RouteComponentProps;
}

const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = (
  { routeProps }
) => {

  const [crumbs, setCrumbs] = React.useState<IRoute[]>([]);


  React.useEffect(
    () => {

      if (!routeProps) {
        return;
      }

      const newCrumbs = Object.keys(Routes)
        .filter(
          (routeKey) => {
            const keyAs = routeKey as keyof typeof Routes;
            if(keyAs === 'HomePage' || !Routes[keyAs].title) {
              return false;
            }
            return routeProps.match.path.includes(Routes[keyAs].path);
          }
        ).map(
          (routeKey) => {

            let newPath = '';
            const keyAs = routeKey as keyof typeof Routes;
            const path = Routes[keyAs].path;
            const paramKeys = Object.keys(routeProps.match.params);

            if (paramKeys.length) {

              newPath = paramKeys.reduce(
                (path, param) => path.replace(
                  `:${param}`, (routeProps.match.params as IRouteParams)[param as keyof IRouteParams] as string
                ), path
              );

            } else {
              newPath = path;
            }

            return ({
              ...Routes[keyAs],
              path: newPath,
            })
          }
        );

      setCrumbs(newCrumbs);

    },
    [routeProps]
  );


  return (
    crumbs.length ?

      (
        <Breadcrumb listProps={{ className: "breadcrumb-transparent text-primary m-0" }}>
          {
            crumbs.map(
              (crumb, index) => (
                <Breadcrumb.Item
                  key={crumb.path}
                  linkAs={Link}
                  linkProps={{
                    to: crumb.path,
                    className: "text-primary",
                  }}
                  active={index === crumbs.length - 1}
                >
                  {crumb.title}
                </Breadcrumb.Item>
              )
            )
          }
        </Breadcrumb>
      ) : null

  );
};

export default Breadcrumbs;
