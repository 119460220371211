import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import Logo from 'assets/img/brand/gradient-teamble-logo.png';
import { CustomModal } from 'components';
import { getGroupMembersDetail } from 'data';
import { IModalProps, ISurveyGroupMembersResponse } from 'interfaces';
import * as React from 'react';
import { finalize } from 'rxjs';

interface ISurveyGroupsPreviewModalProps extends IModalProps {
  groupIds: string[];
  teambleTeamId: string;
}


const SurveyGroupsPreviewModal: React.FC<ISurveyGroupsPreviewModalProps> = ({
  show,
  onClose,
  groupIds,
  teambleTeamId
}) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [groupMemberDetails, setGroupMemberDetails] = React.useState<ISurveyGroupMembersResponse[]>([]);

  React.useEffect(
    () => {

      if (!groupIds.length) {
        return;
      }

      setIsLoading(true);
      setGroupMemberDetails([]);

      const subscription = getGroupMembersDetail(groupIds, teambleTeamId).pipe(
        finalize(
          () => setIsLoading(false)
        )
      ).subscribe(
        (response) => {
          setGroupMemberDetails(response.data.data);
        }
      );

      return () => subscription.unsubscribe();
    },
    [groupIds]
  );


  return (
    <CustomModal
      show={show}
      onClose={onClose}
      size='xl'
      title={<p className="fw-bold mt-2">Group Details</p>}
    >
      <Modal.Body
        className="px-5"
        style={{ height: isLoading || !(groupMemberDetails && groupMemberDetails.length) ? '100px' : 'auto' }}
      >

        <Table className="table-centered table-nowrap rounded mb-0 position-relative">
          <thead>
            <tr>
              <th>Group Name</th>
              <th>Group Members</th>
            </tr>
          </thead>
          <tbody>
            {
              groupMemberDetails.map(
                (groupMemberDetail) => {
                  return (
                    <tr key={groupMemberDetail.id}>
                      <td className='p-3'>
                        <p>{`${groupMemberDetail.msteams_name} -> ${groupMemberDetail.msteams_channel_name}`}</p>
                      </td>
                      <td className='p-3'>
                        <div className='d-flex flex-wrap'>
                          {groupMemberDetail?.members?.map((user, index) => (
                            <p
                              key={`${user.email}-${user.name}-${index}`}
                              className={user.isValid ? '' : 'line-through'}
                              style={{ marginRight: "5px" }}
                            >
                              {user.name}{index === groupMemberDetail.members.length - 1 ? '' : ', '}
                            </p>
                          ))}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>


      </Modal.Body>

      <Modal.Footer className="d-flex" style={{ justifyContent: "space-between" }}>
        <div>
          {
            Boolean(groupMemberDetails.length) && (
              <p className='ml-3' >Note: Please add the striked user to the respective channels to receive the survey in Microsoft Teams.</p>
            )
          }
        </div>
        <Button variant="outline-primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>

    </CustomModal>
  )
};

export default SurveyGroupsPreviewModal;