import { Form } from '@themesberg/react-bootstrap';
import { MetaDataContext } from 'contexts';
import { IAnswer, IMetadata, IMultiSelectQuestionOptionsTypeConstants } from 'interfaces';
import { RevieweeFormCompetencyQuestionDescription, RevieweeFormQuestionDescription } from 'modules/reviews/components/RevieweeFormQuestion';
import React, { useContext } from 'react';
import { getMultiSelectAnswerLabel, getMultiSelectAnswerValue } from 'utils';


interface IAnswersListProps {
  answers: IAnswer[];
}

interface IAnswerProps {
  answer: IAnswer;
}


const TextAnswer: React.FC<IAnswerProps> = (
  { answer }
) => {
  return (
    <div>
      <h5> {answer.label} </h5>
      {
        answer.value
          ?
          <p className='ws-pre-line'> {answer.value} </p>
          :
          <p><i> Not Answered </i></p>
      }
    </div>
  );
};


const MultiSelectAnswer: React.FC<IAnswerProps> = (
  { answer }
) => {

  const metadata = useContext(MetaDataContext) as IMetadata;

  const multiQuestionTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
  const multiQuestionTypeOptions = metadata.multiSelectQuestionOptionsTypes.options;

  const answerValue = getMultiSelectAnswerValue(answer, multiQuestionTypeOptions, multiQuestionTypeConstants);
  const answerLabel = getMultiSelectAnswerLabel(answer);

  const isObjectiveQuestion = answer.type === 'objective-question';

  return (
    <div>
      <h5>
        <hr />

        {answerLabel}

        <Form.Text muted style={{ fontSize: "15px" }}>
          <RevieweeFormQuestionDescription answer={answer} />
        </Form.Text>

      </h5>

      <p>
        <li> {isObjectiveQuestion ? "Score: " : ""} {answerValue || "Not Answered "} </li>
      </p>

      {
        answer.commentValue &&
        <>
          <p className='m-0 fw-bold'>Comment</p>
          <p className='ws-pre-line'>{answer.commentValue}</p>
        </>
      }

      {
        answer.comments && answer.comments.map((comment) => {
          return (
            <div key={comment.id}>
              <p className='mb-0'> <strong> {comment.title || "Comment:"} </strong> </p>
              <p className='mb-1'> {comment.value || "n/a"} </p>
            </div>
          )
        })
      }
    </div>
  );
};


const Answer: React.FC<IAnswerProps> = (
  { answer }
) => {

  let AnswerComponent = null;

  switch (answer.type) {

    case 'short-text':
    case 'long-text': {
      AnswerComponent = TextAnswer;
      break;
    }

    case 'choice':
    case 'objective-question':
    case 'competency-question': {
      AnswerComponent = MultiSelectAnswer;
      break;
    }

  }

  return AnswerComponent
    ?
    <AnswerComponent answer={answer} />
    :
    null;
};


const AnswersList: React.FC<IAnswersListProps> = (
  { answers }
) => {

  return (
    <>
      {
        answers.map(
          (answer) => (
            <div
              key={answer.id}
              className='mb-4'
            >
              <Answer answer={answer} />
            </div>
          )
        )
      }
    </>
  );
};

export default AnswersList;