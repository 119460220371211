import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faClone,
  faLock,
  faTrash,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
} from '@themesberg/react-bootstrap';
import { PermissionCheck } from 'components';
import { MetaDataContext } from 'contexts';
import {
  IMetadata,
  ISurveyBuilderBlock,
  ISurveyQuestionTypeConstants,
  UserPermissions,
} from 'interfaces';
import * as React from 'react';

import { SurveyQuestionParentGroupContext } from '../contexts/SurveyQuestionParentGroupContext';
import SurveyBlock from './SurveyBlock';

interface ISurveyQuestionWidgetProps {
  question: ISurveyBuilderBlock;
  index: number;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onDuplicate: () => void;
  onHeaderClick: () => void;
  onLockUnlock: (isLocked: boolean) => void;
}

const SurveyQuestionWidget: React.FunctionComponent<ISurveyQuestionWidgetProps> = (
  {
    question, index,
    onHeaderClick, onRemove, onDuplicate, onMoveUp, onMoveDown, onLockUnlock,
  }
) => {

  const { activeEventKey } = React.useContext(AccordionContext);
  const groupContext = React.useContext(SurveyQuestionParentGroupContext);
  const metadata = React.useContext<IMetadata>(MetaDataContext as React.Context<IMetadata>);

  const [subTitle, setSubTitle] = React.useState<string>('');
  const [eventKey, setEventKey] = React.useState<string>('');

  const questionTypeConstants: ISurveyQuestionTypeConstants =
    metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

  const subtitleRef = React.useRef<HTMLDivElement>(null);
  const questionRef = React.useRef<HTMLDivElement>(null);


  React.useEffect(
    () => {
      switch (question.type) {

        case questionTypeConstants.STATIC_TEXT: {
          setSubTitle(question.staticTextValue?.value || '');
          break;
        }

        case questionTypeConstants.TEXT_INPUT: {
          setSubTitle(question.inputTextValue?.label || '');
          break;
        }

        case questionTypeConstants.CHOICE: {
          setSubTitle(question.multiSelectValue?.label || '');
          break;
        }

        case questionTypeConstants.GROUP: {
          setSubTitle(question.groupValue?.label || '');
          break;
        }

      }
    },
    [question, questionTypeConstants]
  );


  React.useEffect(
    () => {

      let questionEventKey = '';

      if (question.type === questionTypeConstants.GROUP) {
        questionEventKey = `group_${index}_0`;
      } else {
        if (groupContext.isGroup) {
          questionEventKey = `group_${groupContext.groupIndex}_${index}`
        } else {
          questionEventKey = `nonGroup_${index}`;
        }
      }

      setEventKey(questionEventKey);

    },
    [groupContext, index, question, questionTypeConstants.GROUP]
  );


  React.useEffect(
    () => {
      if (!activeEventKey) {
        return;
      }

      let timeoutId: ReturnType<typeof setTimeout>;
      if (activeEventKey === eventKey) {

        timeoutId = setTimeout(() => {
          questionRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 500);
      }

      return () => clearTimeout(timeoutId);
    },
    [activeEventKey, eventKey]
  );


  return (
    <div ref={questionRef}>
      <div
        ref={subtitleRef}
        className='d-none'
        dangerouslySetInnerHTML={{ __html: subTitle }}>
      </div>

      <Card className='mb-3'>

        <Card.Header
          className={
            `d-flex justify-content-between align-items-center cursor-pointer py-2 px-3
            ${question.type === questionTypeConstants.GROUP ? 'bg-dark text-white' : 'bg-light'} `
          }
          onClick={onHeaderClick}
        >
          <div
            className='d-flex align-items-center overflow-hidden'
          >
            <Card.Title className='m-0 me-3 fs-6 fw-bolder flex-shrink-0'> {question.typeTitle} </Card.Title>
            <Card.Subtitle className='m-0 text-truncate fw-normal'>
              {subtitleRef.current ? subtitleRef.current.innerText : ''}
            </Card.Subtitle>
          </div>

          <div className='d-flex flex-nowrap'>

            {
              !question.isLocked &&
              <>
                <OverlayTrigger
                  overlay={
                    <Tooltip id='move-down-tooltip'>Move Down</Tooltip>
                  }>
                  <Button
                    variant='link'
                    size='sm'
                    className={question.type === questionTypeConstants.GROUP ? 'text-white' : ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      onMoveDown();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id='move-up-tooltip'>Move Up</Tooltip>
                  }>
                  <Button
                    variant='link'
                    size='sm'
                    className={question.type === questionTypeConstants.GROUP ? 'text-white' : ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      onMoveUp();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id='duplicate-tooltip'>Duplicate</Tooltip>
                  }>
                  <Button
                    variant='link'
                    size='sm'
                    className={question.type === questionTypeConstants.GROUP ? 'text-white' : ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDuplicate();
                    }}
                  >
                    <FontAwesomeIcon icon={faClone} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id='delete-tooltip'>Delete</Tooltip>
                  }>
                  <Button
                    variant='link'
                    size='sm'
                    className={question.type === questionTypeConstants.GROUP ? 'text-white' : ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove();
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </OverlayTrigger>
              </>
            }

            {
              !question.multiSelectValue?.track &&
              <PermissionCheck permission={UserPermissions.LockUnlockSurveyQuestion}>
                <OverlayTrigger
                  overlay={
                    <Tooltip id='lock-unlock-tooltip'>{question.isLocked ? 'Unlock' : 'Lock'}</Tooltip>
                  }>
                  <Button
                    variant='link'
                    size='sm'
                    className={question.type === questionTypeConstants.GROUP ? 'text-white' : ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      onLockUnlock(!question.isLocked);
                    }}
                  >
                    <FontAwesomeIcon icon={question.isLocked ? faUnlock : faLock} />
                  </Button>
                </OverlayTrigger>
              </PermissionCheck>
            }

            <OverlayTrigger
              overlay={
                <Tooltip id='collapse-expand-tooltip'>
                  {activeEventKey === eventKey ? 'Collapse' : 'Expand'}
                </Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                className={`ms-1 ${question.type === questionTypeConstants.GROUP ? 'text-white' : ''}`}
              >
                <FontAwesomeIcon icon={activeEventKey === eventKey ? faChevronUp : faChevronDown} />
              </Button>
            </OverlayTrigger>

          </div>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>
            <SurveyBlock
              index={index}
              block={question} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default SurveyQuestionWidget;
