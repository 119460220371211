import { Card } from '@themesberg/react-bootstrap';
import Chart from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { FeedbackInsightsContext } from 'contexts';
import {
  IFeedbackInsightsCompetencyOrBehaviourChartData,
  IFeedbackTreemapChartData
} from 'interfaces';
import * as React from 'react';
import {
  FEEDBACK_CHART_COLORS,
  FEEDBACK_TREEMAP_CHART_DATA_CONFIG,
  FEEDBACK_TREEMAP_CHART_OPTIONS
} from 'teamble-constants';

Chart.defaults.responsive = false;
Chart.register(TreemapController, TreemapElement);


interface IFeedbackTreemapChartWidgetProps {
  chartTitle: string;
  chartData: IFeedbackInsightsCompetencyOrBehaviourChartData[];
}


const getFeedbackTreemapChartData = (chartData: IFeedbackInsightsCompetencyOrBehaviourChartData[]): IFeedbackTreemapChartData[] => {

  return chartData.map((data, index) => ({
    category: data.title ? `${data.name} (${data.title})` : data.name,
    Details: parseInt(data.avg || '0'),
    backgroundColor: FEEDBACK_CHART_COLORS[index % (FEEDBACK_CHART_COLORS.length - 1)],
    competencyId: data.dimensionId,
    behaviourId: data.id,
    behaviourType: data.behaviourType
  }));

};


const FeedbackTreemapChartWidget: React.FC<IFeedbackTreemapChartWidgetProps> = (
  { chartData, chartTitle }
) => {

  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  // Context Hooks
  const feedbackInsightsContext = React.useContext(FeedbackInsightsContext);


  React.useEffect(
    () => {

      const context = canvasRef.current?.getContext('2d');
      if (!context) {
        return;
      }

      const groupedData = getFeedbackTreemapChartData(chartData);

      const canvasChart = new Chart(
        context,
        {
          type: 'treemap',
          data: {
            datasets: [{
              data: groupedData,
              ...FEEDBACK_TREEMAP_CHART_DATA_CONFIG,
            }]
          },
          options: {
            ...FEEDBACK_TREEMAP_CHART_OPTIONS,
            onClick(event, _, chart) {

              const elements = chart.getElementsAtEventForMode(
                event as any,
                'nearest',
                { intersect: true },
                true
              );

              if (!elements.length) {
                return;
              }

              const eventElement = elements[0];
              const elementData = (
                chart.data.datasets[eventElement.datasetIndex]
                  .data[eventElement.index] as any
              )._data.children[0] as IFeedbackTreemapChartData;

              feedbackInsightsContext?.handleTreemapChartClick?.(elementData);

            },
          }
        }
      );

      return () => canvasChart.destroy();

    },
    [chartData]
  );

  return (
    <Card>
      <Card.Header className='bg-light p-2'>
        <Card.Title className='m-0 fs-6 fw-bold'>
          {chartTitle}
        </Card.Title>
      </Card.Header>
      <Card.Body style={{ height: 500 }} className='py-5 px-2'>
        <canvas ref={canvasRef}></canvas>
      </Card.Body>
    </Card>
  );
};

export default FeedbackTreemapChartWidget;