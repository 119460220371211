import HeaderText from "modules/1-on-1s/components/HeaderText/HeaderText";
import React, { useEffect, useState } from "react";
import { oneOnOneDetailListProps } from "modules/1-on-1s/types";
import "./OneOnOneNotes.scss";
import { useUserProfile } from "components";
import PrimaryButton from "modules/1-on-1s/components/PrimaryButton/PrimaryButton";
import { submitOneOnOne } from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import SecondaryButton from "modules/1-on-1s/components/SecondaryButton/SecondaryButton";

interface OneOnOneNotes {
  data: oneOnOneDetailListProps | null;
}
const renderNotes = (
  name: string,
  publicNotes: string[],
  privateNotes: string[],
  isOwner: boolean
) => (
  <div className="render-notes">
    <div className="header">{name}</div>
    <div className="sub-header">Public notes</div>
    <ul>
      {publicNotes?.length > 0 ? (
        publicNotes.map((item, index) => <li key={index}>{item}</li>)
      ) : (
        <li>No public notes</li>
      )}
    </ul>
    {isOwner && (
      <>
        <div className="sub-header">Private Notes</div>
        <ul>
          {privateNotes?.length > 0 ? (
            privateNotes.map((item, index) => <li key={index}>{item}</li>)
          ) : (
            <li>No private notes</li>
          )}
        </ul>
      </>
    )}
  </div>
);

const OneOnOneNotes: React.FC<OneOnOneNotes> = ({ data }) => {
  const { userProfile } = useUserProfile();
  const [sharedNotes, setSharedNotes] = useState("");
  const [selfNotes, setSelfNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const hideNotes =
    !data ||
    [
      data.revieweeChannelInfo?.selfNotes,
      data.revieweeChannelInfo?.sharedNotes,
      data.reviewerChannelInfo?.selfNotes,
      data.reviewerChannelInfo?.sharedNotes,
    ].every((notes) => !notes?.length);
  const reviewerName = data?.reviewerName || "Reviewer";
  const revieweeName = data?.revieweeName || "Reviewee";
  const isReviewerEdit = data?.reviewerId === userProfile?.teambleUserId;
  const isReviweeEdit = data?.revieweeId === userProfile?.teambleUserId;
  const isEditEnabled = [data?.reviewerId, data?.revieweeId].includes(
    userProfile?.teambleUserId
  );

  const onSubmit = () => {
    if ((!sharedNotes && !selfNotes) || !data?.id) return;
    const notes = {
      selfNotes,
      sharedNotes,
    };
    setIsLoading(true);
    submitOneOnOne(data.id, [], notes)
      .pipe(
        finalize(() => {
          setIsLoading(false);
          window.location.reload();
        })
      )
      .subscribe({
        next: (response: any) => {},
        error: (error) => {
          console.log(error);
        },
      });
  };

  useEffect(() => {
    if (isReviewerEdit) {
      setSelfNotes(data?.reviewerChannelInfo?.selfNotes?.join("\n") ?? "");
      setSharedNotes(data?.reviewerChannelInfo?.sharedNotes?.join("\n") ?? "");
    } else if (isReviweeEdit) {
      setSelfNotes(data?.revieweeChannelInfo?.selfNotes?.join("\n") ?? "");
      setSharedNotes(data?.revieweeChannelInfo?.sharedNotes?.join("\n") ?? "");
    }
  }, [data, isEdit, isReviewerEdit, isReviweeEdit]);

  return (
    <div className="OneOnOneNotes-container">
      <div className="header-container">
        <HeaderText
          text={"Notes"}
          fontSize={"24px"}
          lineHeight={"24px"}
          letterSpacing={"-0.24px"}
        />
        {isEditEnabled && (
          <PrimaryButton
            text="Edit"
            onClick={() => {
              setIsEdit(true);
            }}
          />
        )}
      </div>
      <div className="container">
        {hideNotes || isEdit ? (
          <>
            {isEditEnabled && (
              <div className="notes-form">
                <div className="note">
                  <label className="label">Public Notes</label>
                  <textarea
                    value={sharedNotes}
                    className="notes-area"
                    placeholder="Enter your public notes here"
                    onChange={(e) => setSharedNotes(e.target.value)}
                  />
                </div>
                <div className="note">
                  <label>Private Notes</label>
                  <textarea
                    value={selfNotes}
                    className="notes-area"
                    placeholder="Enter your private notes here"
                    onChange={(e) => setSelfNotes(e.target.value)}
                  />
                </div>
                <div className="submit-note">
                  <SecondaryButton
                    name="Cancel"
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  />
                  <PrimaryButton
                    text="Submit"
                    onClick={onSubmit}
                    isLoading={isLoading}
                    loadingText="Submitting"
                  />
                </div>
              </div>
            )}
            {!isEditEnabled && (
              <div className="no-notes">No notes exist for this user.</div>
            )}
          </>
        ) : (
          <>
            {renderNotes(
              reviewerName,
              data?.reviewerChannelInfo?.sharedNotes || [],
              data?.reviewerChannelInfo?.selfNotes || [],
              data?.reviewerId === userProfile?.teambleUserId
            )}
            {renderNotes(
              revieweeName,
              data?.revieweeChannelInfo?.sharedNotes || [],
              data?.revieweeChannelInfo?.selfNotes || [],
              data?.revieweeId === userProfile?.teambleUserId
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OneOnOneNotes;
