import { Button, Form } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormContext } from 'contexts';
import { addReviewersNominationByManager } from 'data';
import { useFormik } from 'formik';
import { useTeamMemberMenuOptions } from 'hooks';
import {
  FormFieldTypeConstants,
  IAddReviewersFormValues,
  IManagerAddReviewersReqData,
  INomination,
  IRouteParams,
} from 'interfaces';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { StringParam, useQueryParam } from 'use-query-params';
import { ADD_REVIEWERS_VALIDATION_SCHEMA } from 'validation-schemas';


interface IReviewTodoManagerAddReviewersFormProps {
  revieweeName: string;
  revieweeSlackId: string;
  onValidPairsAdd: (validPairs: INomination[]) => void;
}

const ReviewTodoManagerAddReviewersForm: React.FC<IReviewTodoManagerAddReviewersFormProps> = (
  { revieweeName, revieweeSlackId, onValidPairsAdd }
) => {

  const [invalidNominationPairs, setInvalidNominationPairs] =
    React.useState<INomination[]>([]);

  const [organizationId] = useQueryParam('organizationId', StringParam);
  const { reviewId } = useParams<IRouteParams>();

  const {
    isLoading,
    memberOptions
  } = useTeamMemberMenuOptions(organizationId || '');

  const managerAddReviewersForm = useFormik<IAddReviewersFormValues>(
    {
      initialValues: {
        reviewers: []
      },
      validationSchema: ADD_REVIEWERS_VALIDATION_SCHEMA,
      onSubmit(values, formikHelpers) {

        if (!reviewId) {
          return;
        }

        const reqData: IManagerAddReviewersReqData = {
          campaignId: reviewId,
          reviewers: values.reviewers.map((reviewer) => reviewer.value),
          revieweeSlackId,
        }

        addReviewersNominationByManager(reqData)
          .pipe(
            finalize(() => formikHelpers.setSubmitting(false))
          )
          .subscribe(
            (response) => {
              toast.success(response.data.message);
              formikHelpers.resetForm();
              if (response.data.data.existingPairs.length) {
                setInvalidNominationPairs(response.data.data.existingPairs);
              }
              if (response.data.data.nonExistingPairs.length) {
                onValidPairsAdd(response.data.data.nonExistingPairs);
              }
            }
          );

      },
    }
  );


  return (
    <FormContext.Provider value={managerAddReviewersForm}>

      {
        invalidNominationPairs.length
          ?
          <>
            <Form.Control.Feedback type="invalid" className='d-block'>
              Following pair(s) already exists
            </Form.Control.Feedback>
            {
              invalidNominationPairs.map(
                (pair) => (
                  <p key={pair.reviewee.id + pair.reviewer.id}>
                    <strong> Reviewee: </strong> @{pair.reviewee.name},
                    <strong> Reviewer: </strong> @{pair.reviewer.name}
                  </p>
                )
              )
            }
          </>
          :
          null
      }

      <FormField
        multiple
        size='sm'
        type={FormFieldTypeConstants.Select}
        isLoading={isLoading}
        options={memberOptions}
        optionType='user'
        controlName='reviewers'
        placeholder='Select reviewer(s)...'
        label={`Add other individuals as reviewers for @${revieweeName}`}
        controlId='managerAddReviewersFormReviewersField'
      />

      <div className='d-flex justify-content-end'>
        <Button
          size='sm'
          variant='outline-primary'
          disabled={managerAddReviewersForm.isSubmitting}
          onClick={() => managerAddReviewersForm.submitForm()}
        >
          {managerAddReviewersForm.isSubmitting ? 'Adding...' : 'Add Now'}
        </Button>
      </div>

    </FormContext.Provider>
  );
};

export default ReviewTodoManagerAddReviewersForm;