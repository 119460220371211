import { GoalsList } from '@common/components';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card } from '@themesberg/react-bootstrap';
import {
  ConfirmationModal,
  CreateUpdateGoalModal,
  ApprovalGoalModal,
  Divider,
  EmptyState,
  Preloader,
  useUserProfile,
} from 'components';
import { getRevieweeGoals, getTeammateGoals, performGoalsAction } from 'data';
import { useApp } from 'hooks';
import { IGoal, IUserGoalReducerAction, SignInWith, UserPermissions } from 'interfaces';
import React from 'react';
import { finalize } from 'rxjs';
import { StringParam, useQueryParam } from 'use-query-params';


interface IGoalsWidgetProps {
  profileVieweeTeambleUserId?: string;
  relationshipId?: string;
}


export const userGoalsReducer = (state: IGoal[], action: IUserGoalReducerAction): IGoal[] => {

  switch (action.type) {

    case 'SET_USER_GOALS': {
      return action.payload as IGoal[];
    }

    case 'CREATE_USER_GOAL': {
      return [...state, action.payload as IGoal];
    }

    case 'UPDATE_USER_GOAL': {
      return state.map(userGoal => {
        if (userGoal.id === (action.payload as IGoal).id) {
          return action.payload as IGoal;
        }
        return userGoal;
      });
    }

    case 'DELETE_USER_GOAL': {
      return state.filter(userGoal => userGoal.id !== (action.payload as IGoal).id);
    }

    default:
      return state;
  }
};


const GoalsWidget: React.FC<IGoalsWidgetProps> = ({ profileVieweeTeambleUserId, relationshipId }) => {

  const app = useApp();
  const isMsTeams = app === SignInWith.MsTeams;
  // State Hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userGoals, dispatchUserGoals] = React.useReducer(userGoalsReducer, []);
  const [goalToDelete, setGoalToDelete] = React.useState<IGoal | null>(null);
  const [goalToApprove, setGoalToApprove] = React.useState<IGoal | null>(null);
  const [goalToUpdate, setGoalToUpdate] = React.useState<IGoal | null>(null);
  const [showCreateGoalModal, setShowCreateGoalModal] = React.useState<boolean>(false);

  const { userProfile } = useUserProfile();

  // Route Hook
  // const [relationshipId] = useQueryParam('relationshipId', StringParam);


  React.useEffect(
    () => {

      if (!profileVieweeTeambleUserId) {
        return;
      }

      setIsLoading(true);
      const subscription = getTeammateGoals(profileVieweeTeambleUserId, relationshipId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            dispatchUserGoals({
              type: 'SET_USER_GOALS',
              payload: response.data.data
            });
          }
        );

      return () => subscription.unsubscribe();
    },
    [profileVieweeTeambleUserId, relationshipId]
  );


  const handleUserGoalRemove = (goal: IGoal, isInitial: boolean): void => {

    if (!profileVieweeTeambleUserId) {
      return;
    }

    if (isInitial) {
      setGoalToDelete(goal);
      return;
    }

    setIsLoading(true);

    performGoalsAction(
      {
        type: 'remove',
        goals: [
          { id: goal.id }
        ],
        profileVieweeTeambleUserId,
        relationshipId
      }
    )
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        () => {
          dispatchUserGoals({
            type: 'DELETE_USER_GOAL',
            payload: goal
          })
        }
      );

  };


  return (
    <>
      <Card border="light" className="p-0 mb-4 mh-100">
        <Card.Header className='d-flex justify-content-between align-items-center flex-shrink-0'>
          <Card.Title className='m-0'>
            Goals
          </Card.Title>
          {
            !isMsTeams &&
            (
              userProfile?.teambleUserId === profileVieweeTeambleUserId ||
              userProfile?.permissions?.includes(UserPermissions.PerformGoalsAction)
            ) &&
            <Button size='sm' onClick={() => setShowCreateGoalModal(true)}>
              Add New
            </Button>
          }
        </Card.Header>
        <Card.Body
          className='position-relative flex-grow-1 overflow-y-auto'
          style={{ height: !userGoals.length || isLoading ? 100 : '' }}
        >
          {
            isLoading
              ?
              <Preloader show={isLoading} />
              :
              <>
                {
                  userGoals.length
                    ?
                    <GoalsList
                      goalsList={userGoals}
                      showGoalActions={
                        !isMsTeams &&
                        (
                          userProfile?.teambleUserId === profileVieweeTeambleUserId ||
                          userProfile?.permissions?.includes(UserPermissions.PerformGoalsAction)
                        )
                      }
                      onEditGoalClick={(goal) => setGoalToUpdate(goal)}
                      onApproveGoalClick={(goal) => setGoalToApprove(goal)}
                      onDeleteGoalClick={(goal) => handleUserGoalRemove(goal, true)} />
                    :
                    <EmptyState message={<>No Goals set!</>} />
                }
              </>
          }
        </Card.Body>
      </Card>
      {
        goalToDelete &&
        <ConfirmationModal
          title='Delete Goal'
          show={!!goalToDelete}
          onPositiveResponse={() => handleUserGoalRemove(goalToDelete, false)}
          onCloseClick={() => setGoalToDelete(null)}
        >
          <p>
            Are you sure to permanently delete the goal <strong>{goalToDelete.name}</strong>?
          </p>

        </ConfirmationModal>
      }
      {
        (goalToUpdate || showCreateGoalModal) && profileVieweeTeambleUserId &&
        <CreateUpdateGoalModal
          relationshipId={relationshipId}
          profileVieweeTeambleUserId={profileVieweeTeambleUserId}
          show={(!!goalToUpdate) || showCreateGoalModal}
          goalToUpdate={goalToUpdate || undefined}
          onClose={() => {
            setGoalToUpdate(null);
            setShowCreateGoalModal(false);
          }}
          onCreateOrUpdateGoal={
            (goal) => {
              dispatchUserGoals({
                type: goalToUpdate ? 'UPDATE_USER_GOAL' : 'CREATE_USER_GOAL',
                payload: goal
              });
            }
          } />
      }
      {
        (goalToApprove) && profileVieweeTeambleUserId &&
        <ApprovalGoalModal
          relationshipId={relationshipId}
          profileVieweeTeambleUserId={profileVieweeTeambleUserId}
          show={(!!goalToApprove)}
          goalToUpdate={goalToApprove}
          onClose={() => {
            setGoalToApprove(null);
          }}
          onCreateOrUpdateGoal={
            (goal) => {
              dispatchUserGoals({
                type: 'UPDATE_USER_GOAL',
                payload: goal
              });
            }
          } />
      }
    </>
  );
};

export default GoalsWidget;