import { FeedbackListAdvanceFiltersWidget } from '@common/components';
import { faChevronDown, faChevronUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse } from '@themesberg/react-bootstrap';
import { TableGlobalFilter } from '@common/modules/table';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

interface IFeedbackListTableWidgetHeaderProps {
  isDownloadable: boolean;
  isDownloading: boolean;
  onDownloadClick: () => void;
}

const FeedbackListTableWidgetHeader: React.FC<IFeedbackListTableWidgetHeaderProps> = (
  { isDownloadable, isDownloading, onDownloadClick }
) => {

  // State Hooks
  const [isAdvancedViewActive, setIsAdvancedViewActive] = React.useState<boolean>(false);

  // Route Hooks
  const [search, setSearch] = useQueryParam('search', StringParam);


  return (
    <Card.Header className='flex-shrink-0 position-relative z-index-2'>

      <div className='d-flex justify-content-between align-items-center'>
        <TableGlobalFilter
          className='w-25'
          globalFilter={search}
          setGlobalFilter={setSearch} />

        <div className='d-flex align-items-center'>
          <Button
            variant='outline-primary'
            onClick={() => setIsAdvancedViewActive(!isAdvancedViewActive)}
          >
            <span>Advanced View</span>
            <span className='ms-2'>
              <FontAwesomeIcon icon={isAdvancedViewActive ? faChevronUp : faChevronDown} />
            </span>
          </Button>

          {
            isDownloadable &&
            <Button
              onClick={onDownloadClick}
              className='ms-2'
              disabled={isDownloading}
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className='ms-2'>
                {isDownloading ? 'Downloading...' : 'Download'}
              </span>
            </Button>
          }
        </div>
      </div>

      <Collapse in={isAdvancedViewActive}>
        <div className='mt-3 mx-n4 mb-n4'>
          <FeedbackListAdvanceFiltersWidget />
        </div>
      </Collapse>

    </Card.Header>
  );
};

export default FeedbackListTableWidgetHeader;