import {
  faChartArea,
  faClipboardList,
  faLayerGroup,
  faPoll,
  faRecycle,
  faSpinner,
  faUser,
  faUsers,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { useCompanyConfig } from "@common/modules/companyConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Badge,
  Button,
  Image,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";
import { ImageWithNameInitials, PermissionCheck } from "components";
import { useUserProfile } from "components";
import { useApp } from "hooks";
import { SignInWith, UserPermissions } from "interfaces";
import React, { useEffect } from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Routes } from "routes";
import SimpleBar from "simplebar-react";
import PeopleGroupIcon from "assets/img/icons/people-group.svg";
import temableShort from "assets/img/temable-close.svg";
import teambleLogoBig from "assets/img/teambleLogoBig.svg";
import SettingsModal from "../modals/SettingsModal";
import PeopleBigGroup from "assets/img/peopleGroup.svg";
import ObjectivesIcon from "assets/img/Objectives.svg";
import OneOnOne from "assets/img/1-on-1.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleView } from "features/globalSettingsSlice";
import DropDownArrow from "modules/1-on-1s/components/SvgReactIcons/DropdownDownArrow";

// import { faBook, faBoxOpen, faChartPie, faCog, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket } from "@fortawesome/free-solid-svg-icons";
export default (props = {}) => {
  const { toggle } = props;
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const [contracted, setContracted] = useState("");
  const [showUserDetailsCardRoute, setShowUserDetailsCardRoute] =
    useState(false);

  const events = isMobile
    ? {}
    : {
      onMouseEnter: () => !toggle && setContracted(""),
      onMouseLeave: () => !toggle && setContracted("contracted"),
    };

  const onCollapse = () => setShow(!show);
  const app = useApp();
  const companyConfig = useCompanyConfig();
  const { userProfile } = useUserProfile();
  const [showSettingsModal, setShowSettingsModal] = React.useState(false);

  React.useEffect(() => {
    setContracted(() => (contracted ? "" : "contracted"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);
  useEffect(() => {
    const pathToCheck = "/one-on-ones";
    setShowUserDetailsCardRoute(location.pathname.startsWith(pathToCheck));
  }, [location.pathname]);

  const view = useSelector((state) => state.globalSettings.view);
  const dispatch = useDispatch();
  const showUserDetailsCard =
    !contracted && userProfile.isSuperAdmin && showUserDetailsCardRoute;

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              {icon ? (
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
              ) : null}
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      imgH,
      imgW,
      exact = false,
      linkClassNames,
      linkAsButton = false,
      onClick,
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : linkClassNames;
    // const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external
      ? { href: link }
      : link
        ? { as: NavLink, to: link }
        : linkAsButton
          ? { as: "div" }
          : {};

    return (
      <Nav.Item onClick={() => setShow(false)}>
        <Nav.Link
          exact={linkAsButton ? "" : exact}
          {...linkProps}
          target={target}
          className={`${classNames} cursor-pointer`}
          onClick={onClick || null}
        >
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={imgW || 20}
                height={imgH || 20}
                className="sidebar-icon svg-icon"
              />
            ) : null}
            {!show && contracted && !icon && !image ? (
              <span className="sidebar-text-contracted"> &bull; </span>
            ) : null}
            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.HomePage.path}>
          <Image
            src="https://static.teamble.com/Logos/alternate-logo-white.svg"
            className="navbar-brand-light"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          {...events}
          className={`${contracted} collapse ${showClass} sidebar d-md-block text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div
              style={{
                maxHeight: showUserDetailsCard ? "calc(100vh - 143px)" : "100%",
              }}
              className="sidebar-items"
            >
              {/* <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                    <div className="d-flex align-items-center">
                      <div className="user-avatar lg-avatar me-4">
                        <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                      </div>
                      <div className="d-block">
                        <h6>Hi, Jane</h6>
                        <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                        </Button>
                      </div>
                    </div>
                    <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                      <FontAwesomeIcon icon={faTimes} />
                    </Nav.Link>
                  </div> */}
              <Nav className="flex-column pt-3 pt-md-0">
                <NavItem
                  exact
                  imgW={contracted ? 45 : 120}
                  imgH={contracted ? 45 : 20}
                  link={Routes.HomePage.path}
                  linkClassNames={contracted ? "p-0" : ""}
                  image={contracted ? temableShort : teambleLogoBig}
                />

                <NavItem
                  title="Profile"
                  icon={faUser}
                  link={`/my-profile/${userProfile.teambleUserId}`}
                />

                <NavItem
                  title="My Team"
                  image={PeopleBigGroup}
                  link={Routes.MyTeamPage.path}
                />

                <PermissionCheck permission={UserPermissions.ViewMyGroupsPage}>
                  <NavItem
                    title="My Groups"
                    icon={faLayerGroup}
                    link={Routes.MyGroupsPage.path}
                  />
                </PermissionCheck>

                <PermissionCheck permission={UserPermissions.ViewReviews}>
                  <CollapsableNavItem
                    eventKey="reviews"
                    title="Reviews"
                    icon={faClipboardList}
                  >
                    <PermissionCheck
                      permission={UserPermissions.ViewReviewBuilder}
                    >
                      <NavItem
                        title="Builder"
                        link={Routes.PerformanceReviewQuestionnaires.path}
                      />
                    </PermissionCheck>
                    <PermissionCheck
                      permission={UserPermissions.ViewManageReviews}
                    >
                      <NavItem
                        title="Manage"
                        link={Routes.ReviewsListPage.path}
                      />
                    </PermissionCheck>
                    <PermissionCheck
                      permission={UserPermissions.ViewReviewReports}
                    >
                      <NavItem
                        title="Reports"
                        link={Routes.PerformanceReviewReports.path}
                      />
                    </PermissionCheck>
                    <PermissionCheck
                      permission={UserPermissions.ViewSuperAdmins}
                    >
                      <NavItem
                        title="Report Table"
                        link={Routes.PerformanceReviewReportsTable.path}
                      />
                    </PermissionCheck>
                    <PermissionCheck permission={UserPermissions.ViewTodosPage}>
                      <NavItem
                        title="To Do's"
                        link={Routes.ReviewToDosPage.path}
                      />
                    </PermissionCheck>
                    <PermissionCheck
                      permission={UserPermissions.ViewMyReviewsPage}
                    >
                      <NavItem
                        title="My Reviews"
                        link={Routes.MyReviewsPage.path}
                      />
                    </PermissionCheck>
                  </CollapsableNavItem>
                </PermissionCheck>
                <NavItem
                title="Objectives"
                image={ObjectivesIcon}
                link={Routes.ObjectiveCampaigns.path}
              />
                <PermissionCheck permission={UserPermissions.ViewSurveys}>
                  <CollapsableNavItem
                    eventKey="surveys"
                    title="Surveys"
                    icon={faPoll}
                  >
                    <NavItem title="Builder" link={Routes.SurveyLibrary.path} />
                    <NavItem title="Manage" link={Routes.ManageSurveys.path} />
                    <NavItem title="Reports" link={Routes.SurveyReports.path} />
                    <NavItem
                      title="Insights"
                      link={Routes.SurveyInsights.path}
                    />
                  </CollapsableNavItem>
                </PermissionCheck>

                <PermissionCheck permission={UserPermissions.ViewFeedbackLoops}>
                  <CollapsableNavItem
                    title="Workflows"
                    icon={faSpinner}
                    eventKey="feedback-loops"
                  >
                    <NavItem
                      title="Builder"
                      link={Routes.FeedbackLoopsListPage.path}
                    />
                    <NavItem
                      title="Manage"
                      link={Routes.ManageFeedbackLoopsPage.path}
                    />
                  </CollapsableNavItem>
                </PermissionCheck>

                <PermissionCheck permission={UserPermissions.ViewAnalytics}>
                  <CollapsableNavItem
                    eventKey="analytics"
                    title="Analytics"
                    icon={faChartArea}
                  >
                    <NavItem
                      title="Survey"
                      link={Routes.SurveyAnalytics.path}
                    />
                    <NavItem
                      title="Feedback"
                      link={Routes.FeedbackAnalytics.path}
                    />
                    {app === "slack" && companyConfig?.reviewAnalytics && (
                      <NavItem
                        title="Reviews"
                        link={Routes.ReviewAnalytics.path}
                      />
                    )}
                    <NavItem title="Reports" link={Routes.Reports.path} />
                  </CollapsableNavItem>
                </PermissionCheck>

                <PermissionCheck permission={UserPermissions.ViewOrganization}>
                  <CollapsableNavItem
                    eventKey="organization"
                    title="Organization"
                    icon={faUsers}
                  >
                    <PermissionCheck permission={UserPermissions.ViewAdmins}>
                      <NavItem title="Admins" link={Routes.Admins.path} />
                    </PermissionCheck>
                    <PermissionCheck permission={UserPermissions.ViewUsers}>
                      <NavItem title="Users" link={Routes.Users.path} />
                    </PermissionCheck>
                  </CollapsableNavItem>
                </PermissionCheck>

                {/* Integrations */}
                <PermissionCheck
                  permission={UserPermissions.ViewIntegrationsListPage}
                >
                  <NavItem
                    title="Integrations"
                    icon={faRecycle}
                    link={Routes.IntegrationsListPage.path}
                  />
                </PermissionCheck>

                <PermissionCheck permission={UserPermissions.ViewSettingsModal}>
                  <NavItem
                    linkAsButton
                    title="Settings"
                    icon={faWrench}
                    onClick={() => setShowSettingsModal(true)}
                  />
                </PermissionCheck>
                <NavItem
                  title="1-on-1s"
                  link={`/one-on-ones`}
                  image={OneOnOne}
                />

                {/* <CollapsableNavItem eventKey="insights" title="Insights" icon={faChartLine} >
                <NavItem title="Surveys" link={Routes.SurveyInsights.path} />
                <NavItem title="Performance" link={Routes.PerformanceInsights.path} />
              </CollapsableNavItem> */}
              </Nav>
            </div>

            {showUserDetailsCard && (
              <div
                className="sidebar-admin-view-card"
                style={{
                  height: "127px",
                  marginBottom: "8px",
                  marginTop: "8px",
                  color: "black",
                }}
              >
                <div title={userProfile?.name} className="user-view">
                  <ImageWithNameInitials
                    size="md"
                    label={userProfile.name}
                    imageOriginal={userProfile.imageOriginal}
                  />
                  <div className="name">{userProfile?.name}</div>
                </div>
                <div className="toggle-view">
                  <div onClick={() => dispatch(toggleView())} className="view">
                    {view === "USER" ? "User View" : "Admin view"}{" "}
                    <DropDownArrow width={12} height={12} color="#9E9FA2" />
                  </div>
                  <div className="subtext">
                    ©Teamble {new Date().getFullYear()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </SimpleBar>
      </CSSTransition>
      {showSettingsModal && userProfile.isSuperAdmin && (
        <SettingsModal
          show={showSettingsModal}
          onClose={() => setShowSettingsModal(false)}
        />
      )}
    </>
  );
};
