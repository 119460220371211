import { Divider, FormField } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  FormErrors, FormFieldTypeConstants, IChoiceWithValue, IMenuOption,
  IMetadata, IMultiSelectQuestionOptionsTypeConstants,
  ISimpleMenuOption, ISurveyBuilderBlock, ISurveyQuestionTypeConstants, SignInWith
} from 'interfaces';
import * as React from 'react';
import * as Yup from 'yup';


interface ISurveyQuestionPreviewProps {
  index?: number;
  question: ISurveyBuilderBlock;
  showAllGroupQuestions?: boolean;
  groupQuestion?: ISurveyBuilderBlock;
  questionTypeConstants?: ISurveyQuestionTypeConstants;
}

interface ITextAnswer {
  required?: boolean;
  [key: string]: string | boolean | undefined;
}

interface IMultiSelectAnswer {
  required?: boolean;
  dropdown?: boolean;
  commentBox?: boolean;
  commentBoxText?: string;
  [key: string]: ISimpleMenuOption | string | boolean | undefined | null | string[];
}


const StaticTextQuestionPreview: React.FunctionComponent<ISurveyQuestionPreviewProps> = (
  { question }
) => {

  return (
    question.staticTextValue ?
      <p dangerouslySetInnerHTML={{ __html: question.staticTextValue.value }}></p>
      : null
  );
};


const SurveyInputTextQuestionPreview: React.FunctionComponent<ISurveyQuestionPreviewProps> = (
  { question, index }
) => {

  const validationSchema: Yup.SchemaOf<ITextAnswer> = Yup.object(
    {
      ['answer' + index]: Yup.string()
        .when(
          'required', {
          is: true,
          then: Yup.string().required(FormErrors.Required),
          otherwise: Yup.string()
        }),
      required: Yup.boolean(),
    }
  );

  const textForm = useFormik<ITextAnswer>({
    initialValues: {
      ['answer' + index]: '',
      required: question.inputTextValue?.required
    },
    validationSchema,
    onSubmit: () => { }
  });


  React.useEffect(
    () => {
      textForm.setFieldValue('required', question.inputTextValue?.required);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question]
  );


  return (
    <FormContext.Provider value={textForm}>
      <FormField
        size='lg'
        label={question.inputTextValue?.label}
        controlId={`titleQuestion-${question.inputTextValue?.label}`}
        type={FormFieldTypeConstants.Text}
        controlName={`answer${index}`}
        placeholder={question.inputTextValue?.placeholder} />
    </FormContext.Provider>
  );
};


const SurveyMultiSelectQuestionPreview: React.FunctionComponent<ISurveyQuestionPreviewProps> = (
  { question, index = 0 }
) => {

  const app = useApp();

  const [choices, setChoices] = React.useState<Partial<IMenuOption>[] | null>(null);

  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const multiSelectChoicesConstants: IMultiSelectQuestionOptionsTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;


  // const validationSchema: Yup.SchemaOf<IMultiSelectAnswer> = Yup.object(
  //   {
  //     ['radioAnswer' + index]: Yup.string()
  //       .when(
  //         'required', {
  //         is: true,
  //         then: Yup.string().required(FormErrors.Required),
  //         otherwise: Yup.string()
  //       }),
  //     ['dropdownAnswer' + index]: Yup.object().shape({
  //       value: Yup.string(),
  //       label: Yup.string().defined(),
  //     }).nullable()
  //       .when(
  //         'required', {
  //         is: true,
  //         then: Yup.object().shape({
  //           value: Yup.string(),
  //           label: Yup.string().defined(),
  //         }).nullable().required(FormErrors.Required),
  //         otherwise: Yup.object().shape({
  //           value: Yup.string(),
  //           label: Yup.string().defined(),
  //         }).nullable()
  //       }),
  //     commentBoxText: Yup.string()
  //       .when(
  //         'commentBox', {
  //         is: true,
  //         then: Yup.string().required(FormErrors.Required),
  //         otherwise: Yup.string()
  //       }),
  //     commentBox: Yup.boolean(),
  //     required: Yup.boolean(),
  //     dropdown: Yup.boolean(),
  //   }
  // );

  const multiSelectForm = useFormik<IMultiSelectAnswer>({
    initialValues: {
      ['radioAnswer' + index]: '',
      ['dropdownAnswer' + index]: null,
      ['checkboxGroupAnswer' + index]: [],
      commentBoxText: '',
      required: question.multiSelectValue?.required,
      commentBox: question.multiSelectValue?.commentBox,
    },
    // validationSchema,
    onSubmit: () => { }
  });


  const getMultiSelectChoices = (choices?: IChoiceWithValue[] | string): Partial<IMenuOption>[] | null => {

    if (!choices?.length) {
      return null;
    }

    let options: Partial<IMenuOption>[] = [];

    switch (app) {

      case SignInWith.Slack: {
        options = (choices as IChoiceWithValue[]).map(
          (choice: IChoiceWithValue) => {
            return {
              value: choice.choice,
              label: choice.choice,
            };
          }
        );
        break;
      }

      case SignInWith.MsTeams: {
        options = (choices as string)
          .split('\n')
          .filter((c) => !!c)
          .map(
            (c) => ({
              label: c
            })
          );
        break;
      }

    }

    return options;
  }


  React.useEffect(
    () => {
      if (
        multiSelectChoicesConstants &&
        question.multiSelectValue?.selectedOption.value === multiSelectChoicesConstants.CUSTOM_ITEMS
      ) {

        const options: Partial<IMenuOption>[] | null =
          getMultiSelectChoices(question.multiSelectValue?.choices)
        setChoices(options);

      } else {

        const options = metadata?.multiSelectQuestionOptionsTypes.options.find(
          (option) => option.value === question.multiSelectValue?.selectedOption.value
        );
        if (options && options.options) {
          setChoices(options.options);
        }

      }

      multiSelectForm.setFieldValue('required', question.multiSelectValue?.required);
      if (app === SignInWith.Slack) {
        multiSelectForm.setFieldValue('commentBox', question.multiSelectValue?.commentBox);
      }
      multiSelectForm.setFieldValue('radioAnswer' + index, '');
      multiSelectForm.setFieldValue('dropdownAnswer' + index, null);

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question]
  );



  return (
    <FormContext.Provider value={multiSelectForm}>
      {
        question.multiSelectValue?.dropdown ?
          <FormField
            size='lg'
            label={question.multiSelectValue?.label || ''}
            fieldDescription={question.multiSelectValue?.multipleAnswers ? 'Select as many as you like' : ''}
            multiple={question.multiSelectValue.multipleAnswers}
            controlId='multiSelectQuestionField'
            type={FormFieldTypeConstants.Select}
            controlName={`dropdownAnswer${index}`}
            placeholder='Pick an option'
            options={choices || []} />
          :
          question.multiSelectValue?.multipleAnswers
            ?
            <FormField
              size='lg'
              label={question.multiSelectValue?.label || ''}
              fieldDescription='Select as many as you like'
              type={FormFieldTypeConstants.CheckboxGroup}
              controlName={`checkboxGroupAnswer${index}`}
              options={choices || []} />
            :
            <FormField
              size='lg'
              label={question.multiSelectValue?.label || ''}
              type={FormFieldTypeConstants.Radio}
              controlName={`radioAnswer${index}`}
              options={choices || []} />
      }
      {
        (app === SignInWith.Slack && question.multiSelectValue?.commentBox) &&
        <FormField
          size='lg'
          label={''}
          placeholder={question.multiSelectValue?.commentBoxGuide}
          controlId='multiSelectQuestionComment'
          type={FormFieldTypeConstants.Textarea}
          controlName={`commentBoxText`} />
      }
    </FormContext.Provider>
  );
};


const NonGroupQuestionPreview: React.FC<Partial<ISurveyQuestionPreviewProps>> = (
  { question, index }
) => {
  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const questionTypeConstants: ISurveyQuestionTypeConstants =
    metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

  let PreviewComponent = null;

  switch (question?.type) {

    case questionTypeConstants.STATIC_TEXT: {
      PreviewComponent = StaticTextQuestionPreview;
      break;
    }

    case questionTypeConstants.TEXT_INPUT: {
      PreviewComponent = SurveyInputTextQuestionPreview;
      break;
    }

    case questionTypeConstants.CHOICE: {
      PreviewComponent = SurveyMultiSelectQuestionPreview;
      break;
    }
  }

  if (PreviewComponent) {
    return (
      <PreviewComponent
        index={index}
        question={question as ISurveyBuilderBlock}
        questionTypeConstants={questionTypeConstants} />
    )
  }

  return null;
};


const GroupQuestionPreview: React.FC<ISurveyQuestionPreviewProps> = (
  { question, groupQuestion, showAllGroupQuestions, index }
) => {
  return (
    <>
      {
        showAllGroupQuestions &&
        <Divider />
      }
      <h5>{question.groupValue!.label}</h5>
      <p>{question.groupValue!.description}</p>
      {
        showAllGroupQuestions
          ?
          <>
            {
              question.groupValue!.questions.map(
                (groupQue) => (
                  <NonGroupQuestionPreview
                    index={index}
                    question={groupQue} />
                )
              )
            }
            <Divider />
          </>
          :
          <NonGroupQuestionPreview
            index={index}
            question={groupQuestion} />
      }
    </>
  )

};


const SurveyQuestionPreview: React.FunctionComponent<Partial<ISurveyQuestionPreviewProps>> = (
  { question, groupQuestion, showAllGroupQuestions = false, index }
) => {

  if (!question) {
    return null;
  }

  if (question.groupValue) {
    return (
      <GroupQuestionPreview
        index={index}
        question={question}
        groupQuestion={groupQuestion}
        showAllGroupQuestions={showAllGroupQuestions} />
    );
  } else {
    return (
      <NonGroupQuestionPreview
        index={index}
        question={question} />
    );
  }
};

export default SurveyQuestionPreview;
