export enum MeetingStatus {
  Upcoming = "Upcoming",
  Past = "Past",
  Archived = "Archived",
}

export enum OneOnOneStatus {
  Preparation = "prep",
  Open = "open",
  Session = "session",
  Closed = "closed",
}

export enum AiOneOnOneTools {
  Specificity = "specificity",
  Tone = "tone",
  Focus = "focus",
  Actionability = "actionability",
}

export interface ImpactTag {
  expanded: string;
  tag: string;
}

export interface SituationTag {
  expanded: string;
  tag: string;
}

export interface BehaviorTag {
  expanded: string;
  tag: string;
}

export interface FeedbackSpecificity {
  feedbackSpecificityScore: number;
}

export interface FeedbackFocusCategory {
  feedbackFocusCategory: string;
  feedbackFocusCategoryScore: number;
}

export interface FeedbackActionability {
  feedbackActionableScore: number;
}

export interface FeedbackTone {
  toneCategory: string;
  toneScore: number;
}
export interface TagOptionsProps {
  expanded?: string;
  tag?: string;
}

export interface CompetencyListProps {
  id: string;
  tag: "ai" | "human";
}

export interface FeedbackData {
  impact: string;
  behavior: string;
  impactTags: TagOptionsProps[];
  userFeedback: string;
  feedbackSpecificity: FeedbackSpecificity;
  aiScore: number;
  feedbackFocusCategory: FeedbackFocusCategory;
  iv: number;
  situation: string;
  feedbackActionability: FeedbackActionability;
  situationTags: TagOptionsProps[];
  id: string;
  feedbackTone: FeedbackTone;
  behaviorTags: TagOptionsProps[];
  developmentAreaCompetency: CompetencyListProps[];
  strengthCompetency: CompetencyListProps[];
  companyId: string;
  createdAt: number;
  oneOnOneId: string;
  revieweeId: string;
  reviewerId: string;
  teambleUserId: string;
  openingStatement: string;
  actionableSteps: TagOptionsProps[];
  isSubmitted: boolean;
}

export type FocusCategoryProps = "Critique" | "Balanced" | "Constructive";

export interface ContextModalPosition {
  top: number;
  left: number;
  visible: boolean;
}

export interface CompetencyList {
  companyId: string;
  createdAt: number;
  id: string;
  isDeleted: boolean;
  iv: number;
  name: string;
  tag: string;
  updatedAt: number;
}

export interface oneOnOneDetailListProps {
  blocks: Block[];
  app: string;
  revieweeChannelInfo: ChannelInfo;
  reviewerChannelInfo: ChannelInfo;
  time: string;
  revieweeMembershipIdState: string;
  state: string;
  teambleCompanyIdState: string;
  revieweeId: string;
  startDate: string;
  startAt: number;
  revieweeMembershipId: string;
  id: string;
  reviewerMembershipId: string;
  teambleCompanyId: string;
  reviewerId: string;
  createdAt: number;
  reviewerMembershipIdState: string;
  closeAt: number;
  iv: number;
  teambleTeamId: string;
  schedule: Schedule;
  updatedAt: number;
  teambleTeamIdState: string;
  meetingDuration: number;
  reviewerName: string;
  revieweeName: string;
  scheduledTimestamp: string;
}

export interface Block {
  id: string;
  label: string;
  choices?: string[];
  placeholder?: string;
  family?: string;
  type: string;
}

export interface ChannelInfo {
  sharedNotes: any[];
  value: Record<string, any>;
  msteams: MsTeamsInfo;
  selfNotes: any[];
}

interface MsTeamsInfo {
  tenantId: string;
  userId: string;
}

interface Schedule {
  prepTimeStamp: number;
  endTimestamp: number;
  tz: number;
  prepSchedule: string;
  frequency: string;
}
