import { Col, Row } from '@themesberg/react-bootstrap';
import { CustomSelect, PermissionCheck } from 'components';
import { useApp } from 'hooks';
import {
  FeedbackInsightTypes,
  IChannelMenuOption,
  ISimpleMenuOption,
  SignInWith,
  StartEndDates,
  UserPermissions,
} from 'interfaces';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datetime';
import { DATE_FORMAT, GROUP_FEEDBACK_TYPE_OPTIONS } from 'teamble-constants';

interface IFeedbackInsightFiltersProps {
  isLoading: boolean;
  insightType: string;
  startEndDates: StartEndDates;
  workspaceOrChannelOptions: IChannelMenuOption[];
  selectedWorkspaceOrChannelOptions: IChannelMenuOption[];
  selectedGroupFeedbackTypeOption: ISimpleMenuOption | undefined;
  onDatesChange: (dates: StartEndDates) => void;
  onSelectedGroupFeedbackTypeOption: (option: ISimpleMenuOption) => void;
  onSelectedWorkspaceOrChannelOptionsChange: (options: IChannelMenuOption[]) => void;
}

const FeedbackInsightFilters: React.FC<IFeedbackInsightFiltersProps> = (
  {
    isLoading,
    insightType,
    startEndDates,
    workspaceOrChannelOptions,
    selectedGroupFeedbackTypeOption,
    selectedWorkspaceOrChannelOptions,
    onDatesChange,
    onSelectedGroupFeedbackTypeOption,
    onSelectedWorkspaceOrChannelOptionsChange
  }
) => {
  const app = useApp();
  const isMsTeams = app === SignInWith.MsTeams;

  return (
    <Row className='flex-wrap'>

      {
        insightType === FeedbackInsightTypes.PERSONAL &&
        <Col xs={4}>
          <CustomSelect
            isMulti
            isNested={isMsTeams}
            allowSelectAll
            optionType='checkbox'
            placeholder={app === SignInWith.Slack ? 'Select Workspaces...' : 'Select Groups...'}
            isLoading={isLoading}
            options={workspaceOrChannelOptions}
            value={selectedWorkspaceOrChannelOptions}
            onChange={
              (options) => onSelectedWorkspaceOrChannelOptionsChange(options as IChannelMenuOption[])
            }
          />
        </Col>
      }

      {
        insightType === FeedbackInsightTypes.CHANNEL_OR_WORKSPACE &&
        <PermissionCheck permission={UserPermissions.ViewGroupFeedbackTypeFilter}>
          <Col xs={4}>
            <CustomSelect
              placeholder='Select feedback type...'
              options={GROUP_FEEDBACK_TYPE_OPTIONS}
              value={selectedGroupFeedbackTypeOption}
              onChange={
                (option) => onSelectedGroupFeedbackTypeOption(option as ISimpleMenuOption)
              }
            />
          </Col>
        </PermissionCheck>
      }

      <Col xs={4}>
        <DatePicker
          dateFormat={DATE_FORMAT}
          closeOnSelect
          inputProps={{ readOnly: true }}
          timeFormat={false}
          value={startEndDates.startDate}
          onChange={(date) => {
            onDatesChange({
              ...startEndDates,
              startDate: moment(date)
            })
          }}
        />
      </Col>

      <Col xs={4}>
        <DatePicker
          dateFormat={DATE_FORMAT}
          closeOnSelect
          inputProps={{ readOnly: true }}
          timeFormat={false}
          value={startEndDates.endDate}
          onChange={(date) => {
            onDatesChange({
              ...startEndDates,
              endDate: moment(date)
            })
          }}
        />
      </Col>
    </Row>
  );
};

export default FeedbackInsightFilters;