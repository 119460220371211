import Axios, { AxiosObservable } from 'axios-observable';
import { IEditUserRoleRequestData, IResponse, ISimpleMenuOption, ISlackWorkspaceUser, IUserRole } from 'interfaces';
import { map } from 'rxjs';

export const getUserRolesList = (): AxiosObservable<IResponse<IUserRole[]>> => {
  return Axios.post<IResponse<IUserRole[]>>('/list-roles');
};

export const getUserRolesAsMenuOptions = (): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {

  return getUserRolesList().pipe(
    map(
      (response) => {
        return {
          ...response,
          data: {
            ...response.data,
            data: response.data.data.map(
              (option) => ({
                ...option,
                value: option.id,
                label: option.role,
              })
            )
          }

        };
      }
    )
  );

};

export const createUserRole = (role: string): AxiosObservable<IResponse<IUserRole>> => {
  return Axios.post<IResponse<IUserRole>>('/create-role', { role });
}

export const updateUserRole = (id: string, data: IEditUserRoleRequestData): AxiosObservable<IResponse<IUserRole>> => {
  return Axios.post<IResponse<IUserRole>>("/update-role", { id, ...data });
}

export const deleteUserRoleRequest =
  (id: string, initialCheck: boolean): AxiosObservable<IResponse<ISlackWorkspaceUser[]>> => {
    return Axios.post<IResponse<ISlackWorkspaceUser[]>>("/remove-role", { id, initialCheck });
  };