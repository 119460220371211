import { Table, useTmblTable, useTmblTableData } from '@common/modules/table';
import { IFeedbackForTableActionProps, IFeedbackLoopAudiencePair } from 'interfaces';
import React from 'react';

import { useFeedbackForAudiencePairColumn } from '../hooks/useFeedbackForAudiencePair';

interface IFeedbackLoopSenderReceiverTableProps {
  readOnly?: boolean;
  feedbackForAudiencePair: IFeedbackLoopAudiencePair[] | undefined,
  onFeedbackLoopAudiencePairRemove?: (audiencePair: IFeedbackLoopAudiencePair) => void;
}

const FeedbackLibraryLoopSenderReceiverTable: React.FC<IFeedbackLoopSenderReceiverTableProps> = ({
  readOnly = true,
  feedbackForAudiencePair,
  onFeedbackLoopAudiencePairRemove
}) => {

  const columns = useFeedbackForAudiencePairColumn(readOnly);

  const data = useTmblTableData<IFeedbackLoopAudiencePair>(feedbackForAudiencePair);

  const tableInstance = useTmblTable<IFeedbackLoopAudiencePair>(
    columns,
    data,
    'feedbackSender'
  );

  return (
    <Table<IFeedbackForTableActionProps, IFeedbackLoopAudiencePair>
      stickyHeader={false}
      tableInstance={tableInstance}
      bodyCellProps={
        { onRemoveClick: onFeedbackLoopAudiencePairRemove }
      }
    />
  );
};

export default FeedbackLibraryLoopSenderReceiverTable;