import { END_DATE_TIME_VALIDATION_SCHEMA, START_DATE_TIME_VALIDATION_SCHEMA } from './dateTimeValidationSchema';
import { FormErrors, ShareReviewResultTypes } from 'interfaces';
import {
  IActiveReviewFormValues,
  IChannelMenuOption,
  IMemberOption,
  ISimpleMenuOption,
} from 'interfaces';
import * as Yup from 'yup';

import {
  CHANNEL_MENU_OPTION_VALIDATION_SCHEMA,
  FIXED_MENU_OPTION_VALIDATION_SCHEMA,
  MEMBER_MENU_OPTION_VALIDATION_SCHEMA,
  SIMPLE_MENU_OPTION_VALIDATION_SCHEMA,
} from './simple-menu-option.validation-schema';

export const LAUNCH_NEW_REVIEW_VALIDATION_SCHEMA: Yup.SchemaOf<IActiveReviewFormValues> =
  Yup.object().shape({
    title: Yup.string()
      .required(FormErrors.Required)
      .trim(FormErrors.Required),

    channel: (CHANNEL_MENU_OPTION_VALIDATION_SCHEMA as Yup.SchemaOf<IChannelMenuOption>)
      .nullable()
      .required(FormErrors.Required),

    reviewTemplate: (SIMPLE_MENU_OPTION_VALIDATION_SCHEMA as Yup.SchemaOf<ISimpleMenuOption>)
      .nullable()
      .required(FormErrors.Required),

    startDate: START_DATE_TIME_VALIDATION_SCHEMA.defined().nullable(),
    endDate: END_DATE_TIME_VALIDATION_SCHEMA.defined().nullable(),

    anonymous: Yup.boolean().defined(),

    shareWithType: Yup.string().defined(),
    canShareResult: Yup.array().of(
      FIXED_MENU_OPTION_VALIDATION_SCHEMA
    ).min(1, FormErrors.Required),

    shareResultWith: Yup.array().of(
      SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
    ).when(
      'shareWithType',
      {
        is: ShareReviewResultTypes.AUTOMATED,
        then: Yup.array().of(
          SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
        ).min(1, FormErrors.Required)
      }
    ),

    shareResultAfter: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA
      .nullable()
      .when(
        'shareWithType',
        {
          is: ShareReviewResultTypes.AUTOMATED,
          then: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.nullable().required(FormErrors.Required),
        }
      ),

    reviewerRevieweeInfos: Yup.array().of(
      Yup.object().shape({
        reviewee: (MEMBER_MENU_OPTION_VALIDATION_SCHEMA as Yup.SchemaOf<IMemberOption>).defined(),
        reviewer: (MEMBER_MENU_OPTION_VALIDATION_SCHEMA as Yup.SchemaOf<IMemberOption>).notRequired(),
      })
    ).nullable().notRequired(),

    nominatorAudience:
      Yup.object().shape({
        verifiedEmailPairs: Yup.array().of(Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
          email: Yup.string(),
          title: Yup.string(),
          imageOriginal: Yup.string(),
        })).notRequired(),
        unVerifiedEmailPairs: Yup.array().of(Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
          email: Yup.string(),
          title: Yup.string(),
          imageOriginal: Yup.string(),
        })).notRequired(),
      }).nullable().notRequired(),

    isAutomaticCloseOnEndDate: Yup.boolean().defined(),
    multiCampaign: Yup.array().notRequired().nullable().default([])
  });