import Axios, { AxiosObservable } from 'axios-observable';
import { IResponse, ICampaignCompletionStatusResponse, IReviewAnalyticsResponse, ISimpleMenuOption, IReviewAnalyticsView, IReviewAnalyticsViewQuery } from 'interfaces';
import { map } from 'rxjs';

export const getCampaignListForCompletionStatus = (): AxiosObservable<IResponse<any>> => {
  return Axios.post<IResponse<any>>(
    "/performance-review/analytics/list-campaign-for-completion-status",
  );
};


const colorCode: any = {
  "Awaiting Nomination": "#1F6E8C",
  "Nominated": "#7BCBF9",
  "Manager Approved": "#23A8E5",
  "Manager Rejected": "#B43C61",
  "Nomination Nominee Declined": "#DA537B",
  "Reviewer Rejected": "#DA537B",
  "Review In Progress": "#E48E44",
  "Started": "#E48E44",
  "Not Started": "#C2C3C5",
  "Review Completed": "#00B383"
}

interface rows {
  count: number,
  counts: {
    "Awaiting Nomination": number
    "Nominated": number
    "Manager Approved": number
    "Manager Rejected": number
    "Reviewer Rejected": number
    "Nomination Nominee Declined": number,
    "Review Inprogress": number
    "Review Completed": number
  },
}

export const generateChoices = (row: rows) => {
  const count = row?.count || 0;
  const choices = [];
  for (const [key, value] of Object.entries(row.counts)) {
    value > 0 && choices.push({
      id: Date.now(),
      title: key,
      percentCount: +((value / count) * 100).toFixed(2),
      color: "#fff",
      backgroundColor: colorCode[key]
    })
  }
  return choices;
}

export const getCampaignCompletionStatus = (data: { PRCampaignId: string }): AxiosObservable<IResponse<ICampaignCompletionStatusResponse>> => {
  return Axios.post<IResponse<ICampaignCompletionStatusResponse>>(
    "/performance-review/analytics/completion-status",
    data
  )
};

export const downloadCampaignCompletionStatus = (data: {
  reportType: string,
  PRCampaignId: string,
}): AxiosObservable<Blob> => {
  return Axios.post<Blob>(
    "/performance-review/analytics/completion-status",
    { isReport: true, ...data },
    {
      responseType: "arraybuffer"
    }
  )
};

export const getReviewAnalyticsByTabs = (data: { viewId?: string }): AxiosObservable<IResponse<IReviewAnalyticsResponse>> => {
  return Axios.post<IResponse<IReviewAnalyticsResponse>>(
    '/performance-review/analytics/render-view',
    data
  )
};


export const getReviewAnalyticsCampaignList = (revieweeId?: string): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {
  return Axios.post<IResponse<ISimpleMenuOption[]>>(
    "/performance-review/analytics/list-campaigns",
    { revieweeId }
  ).pipe(
    map((response) => {
      let data = response.data.data as unknown as { id: string, title: string }[];
      return {
        ...response,
        data: {
          ...response.data,
          data: data.map(({ id, title }) => ({ value: id, label: title }))
        }
      };

    })
  )
};

export const getReviewAnalyticsDepartmentList = (): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {
  return Axios.post<IResponse<ISimpleMenuOption[]>>(
    "/performance-review/analytics/list-departments"
  ).pipe(
    map((response) => {
      let data = response.data.data as unknown as { id: string, title: string }[];
      return {
        ...response,
        data: {
          ...response.data,
          data: data.map(({ id, title }) => ({ value: id, label: title }))
        }
      };

    })
  )
};

export const getReviewAnalyticsRoleList = (): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {
  return Axios.post<IResponse<ISimpleMenuOption[]>>(
    "/performance-review/analytics/list-roles"
  ).pipe(
    map((response) => {
      let data = response.data.data as unknown as { id: string, title: string }[];
      return {
        ...response,
        data: {
          ...response.data,
          data: data.map(({ id, title }) => ({ value: id, label: title }))
        }
      };
    })
  )
};

export const getReviewAnalyticsGroupList = (): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {
  return Axios.post<IResponse<ISimpleMenuOption[]>>(
    "/performance-review/analytics/list-groups"
  ).pipe(
    map((response) => {
      let data = response.data.data as unknown as { id: string, title: string }[];
      return {
        ...response,
        data: {
          ...response.data,
          data: data.map(({ id, title }) => ({ value: id, label: title }))
        }
      };
    })
  )
};

export const getReviewAnalyticsMembersList = (): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {
  return Axios.post<IResponse<ISimpleMenuOption[]>>(
    "/performance-review/analytics/list-users"
  ).pipe(
    map((response) => {
      let data = response.data.data as unknown as { id: string, title: string }[];
      return {
        ...response,
        data: {
          ...response.data,
          data: data.map(({ id, title }) => ({ value: id, label: title }))
        }
      };
    })
  )
};


export const getReviewAnalyticsView = (viewId: string): AxiosObservable<IResponse<IReviewAnalyticsView>> => {
  return Axios.post<IResponse<IReviewAnalyticsView>>(
    "/performance-review/analytics/get-view",
    { viewId }
  )
};

export const updateReviewAnalyticsView = (viewId: string, query: IReviewAnalyticsViewQuery): AxiosObservable<IResponse<IReviewAnalyticsView>> => {
  return Axios.post<IResponse<IReviewAnalyticsView>>(
    "/performance-review/analytics/update-view",
    { viewId, query }
  )
};