import { Modal, ModalProps } from '@themesberg/react-bootstrap';
import { IModalProps } from 'interfaces';
import * as React from 'react';

interface ICustomModalProps extends IModalProps {
  title: React.ReactNode;
  size?: ModalProps['size'];
  scrollable?: boolean;
}

const CustomModal: React.FunctionComponent<ICustomModalProps> = (
  { show, title, children, size, onClose, scrollable= true }
) => {
  return (
    <>
      {
        show &&
        <Modal
          as={Modal.Dialog}
          centered
          show={show}
          onHide={onClose}
          size={size}
          scrollable={scrollable}
        >
          <Modal.Header
            closeButton
            className="px-4"
          >
            <Modal.Title>
              {title}
            </Modal.Title>
          </Modal.Header>
          {children}
        </Modal>
      }
    </>
  )
};

export default CustomModal;
