import { Table, useTmblTable, useTmblTableData } from "@common/modules/table";
import { IActiveReviewActionColumnProps } from "interfaces";
import React from "react";
import { ObjectiveCampaignListProps } from "interfaces/objective-campaign.interface";
import { useObjectiveListColumns } from "../hooks/useObjectiveListColumns";
import { useHistory } from "react-router-dom";

interface IReviewsListTableProps {
  objectiveList: ObjectiveCampaignListProps[];
}

const ObjectiveListTable: React.FC<IReviewsListTableProps> = ({
  objectiveList,
}) => {
  const history = useHistory();
  const tableColumns = useObjectiveListColumns();
  const tableData = useTmblTableData<ObjectiveCampaignListProps>(objectiveList);
  const tableInstance = useTmblTable<ObjectiveCampaignListProps>(
    tableColumns,
    tableData,
    "startDate",
    true
  );

  const handleObjectiveRowClick = (
    objective: ObjectiveCampaignListProps
  ): void => {
    console.log(objective);
    if (objective.state === "draft") {
      history.push(`/objective-campaigns/draft/${objective.id}`);
    } else {
      history.push(`/objective-campaigns/manage/${objective.id}`);
    }
  };

  return (
    <>
      <Table<IActiveReviewActionColumnProps, ObjectiveCampaignListProps>
        hover
        tableInstance={tableInstance}
        onRowClick={(row) => handleObjectiveRowClick(row.original)}
      />
    </>
  );
};

export default ObjectiveListTable;
