import { Button } from "@themesberg/react-bootstrap";
import { PageTitle, PrimaryLayoutBox } from "components";
import * as React from "react";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import ObjectiveListCard from "./components/ObjectiveListCard";

const ObjectivesListPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle title={Routes.ObjectiveCampaigns.title} />
      <PrimaryLayoutBox
        title={Routes.ObjectiveCampaigns.title}
        actions={
          <Link to={Routes.LaunchObjectiveCampaigns.path}>
            <Button type="button" variant="primary" size="sm">
              Add New
            </Button>
          </Link>
        }
      >
        <div className="flex-grow-1 position-relative overflow-hidden">
          <ObjectiveListCard />
        </div>
      </PrimaryLayoutBox>
    </>
  );
};

export default ObjectivesListPage;
