import { Button, Modal } from '@themesberg/react-bootstrap';
import CustomModal from 'components/CustomModal';
import { IModalProps, IQuestionBuilderFormType } from 'interfaces';
import React from 'react';

import QuestionBuilder, { useQuestionBuilder } from './QuestionBuilder';

interface IQuestionsPreviewModalProps extends IModalProps { }

const QuestionsPreviewModal: React.FC<IQuestionsPreviewModalProps> = (
  { show, onClose }
) => {

  const { builderForm } = useQuestionBuilder<IQuestionBuilderFormType>();

  return (
    <CustomModal
      show={show}
      title='Preview'
      onClose={onClose}
      size='lg'
    >
      <Modal.Body>
        {
          builderForm.values.questions.map(
            (question, index) => {
              return (
                <QuestionBuilder.QuestionPreview
                  key={index}
                  question={question} />
              );
            }
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" className="ms-auto" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </CustomModal>
  );
};

export default QuestionsPreviewModal;