import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseButton, Form, InputGroup } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react';
import Datetime from 'react-datetime';
import { DATE_TIME_FORMAT } from "teamble-constants";

interface IDateTimePickerProps {
  value: moment.Moment | null;
  isClearable?: boolean;
  minDateTime?: moment.Moment | null;
  onChange: ((value: moment.Moment | string) => void) | undefined;
  onClearClick?: () => void;
}

const DateTimePicker: React.FC<IDateTimePickerProps> = (
  { value, isClearable = true, minDateTime = null, onChange, onClearClick }
) => {

  return (
    <Datetime
      value={value || undefined}
      closeOnSelect
      isValidDate={(currentDate) => minDateTime ? moment(currentDate).isAfter(minDateTime) : true}
      onChange={onChange}
      renderInput={(_, openCalendar, closeCalendar) => (
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </InputGroup.Text>
          <Form.Control
            readOnly
            type="text"
            value={value ? moment(value).format(DATE_TIME_FORMAT) : ""}
            placeholder={DATE_TIME_FORMAT}
            onFocus={openCalendar as any}
            onChange={() => { closeCalendar() }} />
          {
            isClearable &&
            <InputGroup.Text
              className='cursor-pointer fs-5'
              onClick={onClearClick}
            >
              <CloseButton style={{ height: '0.1rem', width: '0.1rem' }} />
            </InputGroup.Text>
          }
        </InputGroup>
      )}
    />
  );
};

export default DateTimePicker;