import Axios, { AxiosObservable } from "axios-observable";
import { IFeedbackLoopTemplateTypes, IResponse, ITemplate } from "interfaces";

export const getPredefinedTemplatesList = (
  templateType: IFeedbackLoopTemplateTypes
): AxiosObservable<IResponse<ITemplate[]>> => {
  let url = "";
  switch (templateType) {
    case IFeedbackLoopTemplateTypes.Feedback:
      url = "list-predefined-feedback-template";
      break;
    case IFeedbackLoopTemplateTypes.OneOnOne:
      url = "list-predefined-template";
      break;
    default:
      url = "";
  }
  return Axios.post<IResponse<ITemplate[]>>(url);
};

export const getCustomTemplatesList = (
  templateType: IFeedbackLoopTemplateTypes
): AxiosObservable<IResponse<ITemplate[]>> => {
  const url =
    templateType === IFeedbackLoopTemplateTypes.Feedback ||
    templateType === IFeedbackLoopTemplateTypes?.OneOnOne
      ? "list-custom-feedback-template"
      : "";

  return Axios.post<IResponse<ITemplate[]>>(url);
};
