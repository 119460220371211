import * as React from 'react';

interface IEmptyStateProps {
  message: React.ReactNode;
}

const EmptyState: React.FunctionComponent<IEmptyStateProps> = (
  { message }
) => {
  return (
    <div className='h-50 d-flex justify-content-center align-items-center'>
      <h4
        style={{
          maxWidth: '30rem'
        }}
        className='text-muted text-center m-0'
      >
        {message}
      </h4>
    </div>
  );
};

export default EmptyState;
