import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse } from '@themesberg/react-bootstrap';
import { IKPIWidgetProps } from 'interfaces';
import React from 'react';
import { KPIWidgetBody } from './KPIBody';


const KPIWidget: React.FC<IKPIWidgetProps> = ({
  role,
  kpis,
  index,
  docType,
  readonly = true,
  collapsed = false
}) => {

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(collapsed);

  let titleHeader = null;

  if (role) {

    titleHeader = role;

  } else if (kpis.length) {

    titleHeader = kpis.length > 1
      ? `${kpis.length} KPIs: ${kpis.map(kpi => kpi.name).join(", ")}`
      : `${kpis.map(kpi => kpi.name).join(", ")}`

  }


  return (
    <Card className='my-2'>
      <Card.Header
        aria-expanded={!isCollapsed}
        className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Card.Title className='m-0' as='h6'>
          {titleHeader}
        </Card.Title>
        <Button variant='link' size='sm'>
          <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
        </Button>
      </Card.Header>

      <Collapse in={!isCollapsed}>
        <Card.Body>
          <KPIWidgetBody
            role={role}
            docType={docType}
            readonly={readonly}
            kpis={kpis} index={index || 0}
          />
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default KPIWidget;