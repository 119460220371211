import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { ConfirmationModal, FormField } from 'components';
import { FormContext } from 'contexts';
import { submitRevieweeTodos } from 'data';
import { useFormik } from 'formik';
import { useCompanyUsersMemberOptions } from 'hooks';
import {
  FormFieldTypeConstants,
  IAwaitingNominationFormValues,
  IRouteParams,
  ISubmitRevieweeTodosRequestData
} from 'interfaces';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { StringParam, useQueryParam } from 'use-query-params';
import { AWAITING_NOMINATION_VALIDATION_SCHEMA } from 'validation-schemas';

interface IReviewTodoAwaitingNominationWidgetProps {
  onNominationSubmit: (reviewId: string) => void
}

const ReviewTodoAwaitingNominationWidget: React.FC<IReviewTodoAwaitingNominationWidgetProps> = (
  { onNominationSubmit }
) => {

  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);

  const { reviewId } = useParams<IRouteParams>();

  const {
    isLoading,
    memberOptions
  } = useCompanyUsersMemberOptions();

  const awaitingNominationForm = useFormik<IAwaitingNominationFormValues>({
    initialValues: {
      nominees: []
    },
    validationSchema: AWAITING_NOMINATION_VALIDATION_SCHEMA,
    validateOnMount: true,
    onSubmit(values, formikHelpers) {

      if (!reviewId) {
        return;
      }

      const requestData: ISubmitRevieweeTodosRequestData = {
        reviewers: values.nominees.map((nominee) => nominee.value),
        campaignId: reviewId
      };

      submitRevieweeTodos(requestData)
        .pipe(
          finalize(() => formikHelpers.setSubmitting(false))
        )
        .subscribe(
          (response) => {

            toast.success(response.data.message);
            setShowConfirmationModal(false);
            onNominationSubmit(reviewId);
          }
        );
    }
  });


  useEffect(
    () => {
      awaitingNominationForm.setFieldValue('nominees', [], true);
      awaitingNominationForm.setFieldTouched('nominees', false, true);
    },
    [reviewId]
  );


  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    awaitingNominationForm.validateForm().then(
      (errors) => {
        if (Object.keys(errors).length) {
          awaitingNominationForm.handleSubmit(event);
        } else {
          setShowConfirmationModal(true);
        }
      }
    );
  };


  return (
    <>
      <Card className='h-100 overflow-hidden'>

        <Card.Header className='flex-shrink-0'>
          <Card.Title className='m-0 fw-bold fs-4'>
            Choose Reviewers
          </Card.Title>
        </Card.Header>

        <Form
          className='d-flex overflow-hidden flex-column flex-grow-1'
          onSubmit={handleFormSubmit}
        >
          <FormContext.Provider value={awaitingNominationForm}>

            <Card.Body className='flex-grow-1 overflow-y-auto'>
              <Card.Subtitle className='fs-5'>
                Nominate your reviewers
              </Card.Subtitle>

              <FormField
                multiple
                size='sm'
                type={FormFieldTypeConstants.Select}
                isLoading={isLoading}
                fieldDescription='Your manager will be given the chance to confirm, add to or remove any of your choices. And the folks you nominated will also be given the choice to opt-out.'
                options={memberOptions}
                optionType='user'
                controlName='nominees'
                placeholder='Select nominee(s)...'
                label='Choose nominee(s) from whom you want a review'
                controlId='awaitingNominationFormNomineesField'
              />
            </Card.Body>

            <Card.Footer className='d-flex justify-content-end flex-shrink-0 '>
              <Button
                variant="primary"
                type="submit"
                disabled={awaitingNominationForm.isSubmitting}
              >
                {awaitingNominationForm.isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Card.Footer>

          </FormContext.Provider>
        </Form>

      </Card>
      <ConfirmationModal
        title='Submit nominations'
        submittingText='Submitting...'
        positiveText='Submit'
        isSubmitting={awaitingNominationForm.isSubmitting}
        show={showConfirmationModal}
        closeOnUserResponse={false}
        onPositiveResponse={() => {
          awaitingNominationForm.submitForm();
        }}
        onNegativeResponse={() => setShowConfirmationModal(false)}
        onCloseClick={() => setShowConfirmationModal(false)}
      >
        <p>
          Are you sure you want to submit the nominations?
        </p>
        <i>Once you submit you can't edit your nominations.</i>
      </ConfirmationModal>
    </>
  );
};

export default ReviewTodoAwaitingNominationWidget;
