import React from "react";
import "./imageCard.scss";
import HeaderText from "../HeaderText/HeaderText";
const ImageCard = ({
  data,
  setSelectedBannerImage,
  isSelected,
  isBanner,
}: any) => {
  return (
    <div className="ImageCard">
      <img className="img-card" src={data?.image} alt="Teamble banner images" />
      <div className="image-card-info">
        <div>
          <HeaderText
            text={data?.headerText}
            fontSize={"16px"}
            lineHeight={"16px"}
            letterSpacing={"-0.16px"}
          />
          <div className="subtext">{data?.subtext}</div>
        </div>
        <div>
          {!isBanner && (
            <div
              className="add-video"
              onClick={() => {
                setSelectedBannerImage?.((prev: any) => {
                  return isSelected
                    ? prev.filter((item: any) => item.id !== data?.id)
                    : [...prev, data];
                });
              }}
            >
              {isSelected ? "Remove video" : "Add Video"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
