import { FileFormats, IAudienceUploadFormValues, ISimpleMenuOption } from 'interfaces';
import { FormErrors } from 'interfaces';
import * as Yup from 'yup';

export const AUDIENCE_UPLOAD_VALIDATION_SCHEMA: Yup.SchemaOf<IAudienceUploadFormValues> =
  Yup.object().shape({
    file: Yup.mixed().nullable().required(FormErrors.Required).test(
      'fileType', FormErrors.SupportedExcelFormats, (value: File) => {

        if (!value) {
          return true;
        }

        if (([FileFormats.CSV, FileFormats.XLSX, FileFormats.XLS] as string[]).includes(value.type)) {
          return true;
        }

        return false;

      }
    )
  })


export const REVIEWER_CHANGE_AUDIENCE_SCHEMA: Yup.SchemaOf<any> = Yup.object({
  reviewer: Yup.object({
    label: Yup.string().required('Reviewer is required').defined(),
    value: Yup.string().required('Reviewer is required').defined(),
  })
    .nullable()  // Allow null for reviewer
    .required('Reviewer is required'),  // Error message if reviewer is missing
  retainValues: Yup.boolean().default(false),
});
