import { Card, ProgressBar } from '@themesberg/react-bootstrap';
import { IActiveReview } from 'interfaces';
import moment from 'moment';
import React from 'react';
import { DATE_TIME_FORMAT } from 'teamble-constants';

import { getActiveReviewDates } from '../utils/utils';


interface IReviewDetailsCardHeaderProps {
  activeReview: IActiveReview;
}

const ReviewDetailsCardHeader: React.FC<IReviewDetailsCardHeaderProps> = (
  { activeReview }
) => {

  const [startDate, endDate] = getActiveReviewDates(activeReview);
  const isEndDateInPast = !!(endDate && moment(endDate, DATE_TIME_FORMAT).isBefore(moment()));


  return (
    <Card.Header className='flex-shrink-0'>

      {
        activeReview.nominatorAudience?.verifiedEmailPairs?.length
          ?
          <div className='d-flex align-items-center w-50'>
            <strong className='flex-shrink-0 me-2'> Nomination Progress: </strong>
            <div className='d-flex align-items-center flex-grow-1'>
              <small>{activeReview.nominationProgress || 0}%</small>
              <ProgressBar
                variant="primary"
                className="progress-lg mb-0 ms-2 flex-grow-1"
                now={activeReview.nominationProgress || 0} />
            </div>
          </div>
          :
          null
      }

      <div className='d-flex align-items-center w-50 mt-3'>
        <strong className='flex-shrink-0 me-2'> Review Progress: </strong>
        <div className='d-flex align-items-center flex-grow-1'>
          <small>{activeReview.progress || 0}%</small>
          <ProgressBar
            variant="primary"
            className="progress-lg mb-0 ms-2 flex-grow-1"
            now={activeReview.progress || 0} />
        </div>
      </div>

      <div className="d-flex mt-3">
        <div className='d-flex align-items-center flex-shrink-0'>
          <strong className='flex-shrink-0 me-2'> Start: </strong>
          <small>
            {startDate || 'Not Available'}
          </small>
        </div>

        <div className='d-flex align-items-center flex-shrink-0 ms-3'>
          <strong className='flex-shrink-0 me-2'> End: </strong>
          <small>
            {endDate || 'To be closed manually'}
          </small>

          {
            isEndDateInPast &&
            <em> <strong className="text-danger ms-2"> Delayed </strong> </em>
          }

        </div>
      </div>

      <div className="d-flex mt-3">
        <div>
          <strong className='flex-shrink-0 me-2'> Anonymity: </strong>
          <small>
            {activeReview.anonymity === 'anonymous' ? 'Anonymous' : 'Non Anonymous'}
          </small>
        </div>

        {
          activeReview.perfReviewTemplateName &&
          <div className='ms-3'>
            <strong className='flex-shrink-0 me-2'> Template: </strong>
            <small>
              {activeReview.perfReviewTemplateName}
            </small>
          </div>
        }


      </div>

      <div className="d-flex mt-3">
        <SharingConfig activeReview={activeReview} />
      </div>

    </Card.Header>
  );
};


const SharingConfig: React.FC<{ activeReview: IActiveReview }> = ({ activeReview }) => {

  if (!activeReview.shareInfo) return null;

  const sharingConfig = activeReview.shareInfo;

  let Component = null;

  if (sharingConfig.automatic) {

    Component = (
      <small>
        <strong>Type: </strong>{'Automatic'}
        <strong> | On: </strong> {sharingConfig.trigger.join(", ")}
        <strong> | Share with: </strong>{sharingConfig.share.length ? sharingConfig.share.join(", ") : "Not Set"}
      </small>
    )

  } else {

    Component = (
      <small>
        <strong>Type: </strong>{'Manual'}
      </small>
    )

  }

  return (
    <div>
      <strong className='flex-shrink-0 me-2'> Sharing Details: </strong>
      {Component}
    </div>
  )

}

export default ReviewDetailsCardHeader;