import { Button, Modal } from '@themesberg/react-bootstrap';
import * as React from 'react';


interface IConfirmationModalProps {
  title: string;
  show: boolean;
  hideSubmit?: boolean;
  isSubmitting?: Boolean;
  submittingText?: string;
  positiveText?: string;
  negativeText?: string;
  closeOnUserResponse?: boolean;
  onPositiveResponse?: () => void;
  onNegativeResponse?: () => void;
  onCloseClick?: () => void;
  children: React.ReactNode;
}


const ConfirmationModal: React.FunctionComponent<IConfirmationModalProps> = (
  {
    title, show = false, children, isSubmitting = false, submittingText = '',
    positiveText = 'Yes', negativeText = 'Cancel', closeOnUserResponse = true,
    hideSubmit = false,
    onPositiveResponse, onNegativeResponse, onCloseClick
  }
) => {


  const handleResponse: (isPositive: boolean) => void = (isPositive) => {
    if (isPositive) {
      if (onPositiveResponse) {
        onPositiveResponse();
      }
    } else {
      if (onNegativeResponse) {
        onNegativeResponse();
      }
    }

    if (closeOnUserResponse && onCloseClick) {
      onCloseClick();
    }
  };


  return (
    <Modal as={Modal.Dialog} centered show={show}>
      <Modal.Header>
        <Modal.Title className="h6"> {title} </Modal.Title>
        <Button
          disabled={!!isSubmitting}
          variant="close"
          aria-label="Close"
          onClick={
            (e) => {
              onCloseClick?.();
              e.stopPropagation();
            }
          } />
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!!isSubmitting}
          variant="outline-primary"
          className="ms-auto"
          onClick={
            (e) => {
              e.stopPropagation();
              handleResponse(false);
            }
          }
        >
          {negativeText}
        </Button>

        {
          hideSubmit
            ? null
            : (
              <Button
                disabled={!!isSubmitting}
                variant="primary"
                className="ms-3"
                onClick={
                  (e) => {
                    e.stopPropagation();
                    handleResponse(true);
                  }
                }
              >
                {isSubmitting ? submittingText : positiveText}
              </Button>
            )
        }

      </Modal.Footer>
    </Modal>
  );

};

export default ConfirmationModal;
