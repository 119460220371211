import { IActiveReviewFormValues, ShareReviewResultTypes } from "interfaces";
import { REVIEW_RESULT_CAN_SHARE_BY_OPTIONS } from "teamble-constants";

export const getLaunchReviewFormInitialValues = (): IActiveReviewFormValues => {

  return {
    title: '',
    channel: null,
    reviewTemplate: null,
    startDate: null,
    endDate: null,
    anonymous: false,
    reviewerRevieweeInfos: null,
    shareWithType: ShareReviewResultTypes.MANUAL,
    canShareResult: [REVIEW_RESULT_CAN_SHARE_BY_OPTIONS[0]],
    shareResultWith: [],
    shareResultAfter: null,
    nominatorAudience: null,
    isAutomaticCloseOnEndDate: false
  };
};