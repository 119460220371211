import { Table, useTmblTable, useTmblTableData } from '@common/modules/table';
import { ConfirmationModal } from 'components';
import { MetaDataContext } from 'contexts';
import { cancelReviewCampaign, downloadExcelReportOfReview, removeReview, removeReviewSchedule, reopenReview } from 'data';
import downloadjs from 'downloadjs';
import {
  IActiveReview,
  IActiveReviewActionColumnProps,
  IMetadata,
  IResponse,
  IReviewStatusTypeConstants,
  MetaDataKeys,
  ReviewActions,
} from 'interfaces';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import { DelimitedArrayParam, useQueryParam } from 'use-query-params';

import { useActiveReviewTableColumns } from '../hooks/useActiveReviewTableColumns';
import { AxiosObservable } from 'axios-observable';



interface IReviewsListTableProps {
  reviewsList: IActiveReview[];
  onReviewDelete: (review: IActiveReview) => void;
}

interface IReviewWithAction {
  review: IActiveReview,
  action: string
}

interface IReviewConfirmationModal {
  reviewWithAction: IReviewWithAction | null | undefined,
  setReviewWithAction: React.Dispatch<React.SetStateAction<IReviewWithAction | null | undefined>>,
  reviewAction: (reviewWithAction: IReviewWithAction) => void
}

const ReviewsListTable: React.FC<IReviewsListTableProps> = (
  { reviewsList, onReviewDelete }
) => {

  /* State Hooks */
  const [loadingReviewId, setLoadingReviewId] = React.useState<string>('');
  const [reviewWithAction, setReviewWithAction] = React.useState<IReviewWithAction | null | undefined>();

  /* Route Hooks */
  const [reviewStatusQueryParam] = useQueryParam('reviewStatus', DelimitedArrayParam);
  const history = useHistory();

  /* Metadata hook */
  const metaData = React.useContext(MetaDataContext) as IMetadata;
  const reviewStatusTypesConstants =
    metaData[MetaDataKeys.ReviewStatusTypes].constants as IReviewStatusTypeConstants;

  /* Table Hooks */
  const tableColumns = useActiveReviewTableColumns();
  const tableData = useTmblTableData<IActiveReview>(reviewsList);
  const tableInstance = useTmblTable<IActiveReview>(
    tableColumns,
    tableData,
    reviewStatusQueryParam?.includes(reviewStatusTypesConstants.DRAFT)
      ? 'updatedAt' : 'startDate',
    true
  );


  const downloadData = React.useCallback(
    (review: IActiveReview): void => {

      setLoadingReviewId(review.id);

      downloadExcelReportOfReview(
        review.id,
      ).pipe(
        finalize(() => setLoadingReviewId(''))
      ).subscribe(
        (response) => {
          downloadjs(response.data, `${review.title}.xls`, 'application/vnd.ms-excel');
        }
      )
    },
    []
  );

  const handleReviewRowClick = (review: IActiveReview): void => {
    history.push(
      review.state === 'Draft'
        ?
        `/reviews/manage-reviews/draft/${review.id}`
        :
        `${Routes.ReviewsListPage.path}/${review.id}`
    );
  };

  const reviewAction = (reviewWithAction: IReviewWithAction) => {

    const { review, action } = reviewWithAction;

    let actionFunction: AxiosObservable<IResponse<void>> = null as unknown as AxiosObservable<IResponse<void>>;

    switch (action) {

      case ReviewActions.CancelReview: {
        actionFunction = cancelReviewCampaign(review.id);
        break;
      }

      case ReviewActions.DeleteReview: {
        actionFunction = removeReview(review.id)
        break;
      }

      case ReviewActions.DeleteSchedule: {
        actionFunction = removeReviewSchedule(review.id);
        break;
      }

      case ReviewActions.ReopenReview: {
        actionFunction = reopenReview(review.id);
        break;
      }

      default:
        break;
    }

    if (actionFunction) {
      setLoadingReviewId(review.id);
      actionFunction
        .pipe(finalize(() => setLoadingReviewId('')))
        .subscribe((response) => {
          toast.success(response.data.message);
          onReviewDelete(review);
          setReviewWithAction(null)
        });
    }
  }

  return (
    <>
      <Table<IActiveReviewActionColumnProps, IActiveReview>
        hover
        tableInstance={tableInstance}
        bodyCellProps={{
          loadingReviewId,
          onDownloadAction: downloadData,
          onDeleteReviewAction: (review) => setReviewWithAction({ review, action: ReviewActions.DeleteReview }),
          onCancelReviewAction: (review) => setReviewWithAction({ review, action: ReviewActions.CancelReview }),
          onReopenReviewAction: (review) => setReviewWithAction({ review, action: ReviewActions.ReopenReview }),
          onDeleteReviewScheduleAction: (review) => setReviewWithAction({ review, action: ReviewActions.DeleteSchedule }),
        }}
        onRowClick={(row) => !loadingReviewId && handleReviewRowClick(row.original)}
      />

      <ReviewConfirmationActionModal
        reviewAction={reviewAction}
        reviewWithAction={reviewWithAction}
        setReviewWithAction={setReviewWithAction}
      />
    </>
  )
};


const ReviewConfirmationActionModal: React.FC<IReviewConfirmationModal> = ({
  reviewAction,
  reviewWithAction,
  setReviewWithAction
}) => {

  if (!reviewWithAction) return null;

  const { review, action } = reviewWithAction;

  let title = '';
  let message = null;

  switch (action) {

    case ReviewActions.CancelReview: {
      title = 'Cancel Review';
      message = (
        <>
          <p>Are you sure you want to cancel the review <strong> {review.title} </strong>?</p>
          <em>This action is irreversible and wipes out all the data of the review.</em>
        </>
      )
      break;
    }

    case ReviewActions.DeleteReview: {
      title = 'Delete Review';
      message = (
        <>
          <p>Are you sure you want to delete the review <strong> {review.title} </strong>?</p>
          <em>This action is irreversible and wipes out all the data of the review.</em>
        </>
      )
      break;
    }

    case ReviewActions.DeleteSchedule: {
      title = 'Delete Schedule';
      message = (
        <>
          <p>Are you sure you want to delete the schedule from review <strong> {review.title} </strong>?</p>
          <em>This action will move review to the Draft state.</em>
        </>
      )
      break;
    }

    case ReviewActions.ReopenReview: {
      title = 'Reopen Review';
      message = (
        <>
          <p>Are you sure you want to re-open the from review <strong> {review.title} </strong>?</p>
          <em>This action will move review to the Live state.</em>
        </>
      )
      break;
    }

    default: {
      return null;
    }
  }

  return (
    <ConfirmationModal
      title={title}
      show={Boolean(reviewWithAction)}
      onNegativeResponse={() => setReviewWithAction(null)}
      onCloseClick={() => setReviewWithAction(null)}
      onPositiveResponse={() => reviewAction(reviewWithAction)}
    >
      {message}
    </ConfirmationModal>
  )
}

export default ReviewsListTable;