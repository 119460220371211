import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faClone,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
} from '@themesberg/react-bootstrap';
import { MetaDataContext } from 'contexts';
import { IMetadata, IQuestionTypeConstants, IReviewTemplateQuestion } from 'interfaces';
import * as React from 'react';

import ReviewTemplateQuestion from './ReviewTemplateQuestion';


interface IReviewTemplateQuestionWidgetProps {
  question: IReviewTemplateQuestion;
  index: number;
  onHeaderClick: () => void;
  onRemove: () => void;
  onDuplicate: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
}


const ReviewTemplateQuestionWidget: React.FunctionComponent<IReviewTemplateQuestionWidgetProps> = (
  { question, index, onHeaderClick, onRemove, onDuplicate, onMoveUp, onMoveDown, }
) => {

  const { activeEventKey } = React.useContext(AccordionContext);
  const [subTitle, setSubTitle] = React.useState<string>('');
  const [subTitlePlainText, setSubTitlePlainText] = React.useState<string>('');

  const metadata = React.useContext<IMetadata>(MetaDataContext as React.Context<IMetadata>);
  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  const subtitleRef = React.useRef<HTMLDivElement>(null);


  React.useEffect(
    () => {

      switch (question.type) {

        case questionTypeConstants.TITLE:
        case questionTypeConstants.SUBTITLE: {
          setSubTitle(question.titleValue?.text || '');
          break;
        }

        case questionTypeConstants.LONG_TEXT:
        case questionTypeConstants.SHORT_TEXT: {
          setSubTitle(question.textValue?.label || '');
          break;
        }

        case questionTypeConstants.MULTI_SELECT_QUESTION: {
          setSubTitle(question.multiSelectValue?.label || '');
          break;
        }

        case questionTypeConstants.COMPETENCY_QUESTION: {
          setSubTitle(question.competencyValue?.label || '');
          break;
        }

      }
    },
    [question, questionTypeConstants]
  );


  React.useEffect(
    () => {
      setSubTitlePlainText(subtitleRef.current?.innerText || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subtitleRef.current, subTitle]
  );



  return (
    <>
      <div
        ref={subtitleRef}
        className='d-none'
        dangerouslySetInnerHTML={{ __html: subTitle }}>
      </div>
      <Card className='mb-3'>

        <Card.Header
          className='d-flex justify-content-between align-items-center cursor-pointer bg-light py-2 px-3'
          onClick={onHeaderClick}
        >
          <div
            className='d-flex align-items-center'
            style={{ maxWidth: 'calc(100% - 150px)' }}
          >
            <Card.Title className='m-0 me-3 fs-6 fw-bolder flex-shrink-0'> {question.typeTitle} </Card.Title>
            <Card.Subtitle className='m-0 text-truncate fw-normal' style={{
              maxWidth: 'calc(100% - 95px)',
            }}>
              {subTitlePlainText}
            </Card.Subtitle>
          </div>

          <div className='d-flex flex-nowrap'>

            <OverlayTrigger
              overlay={
                <Tooltip id='move-down-tooltip'>Move Down</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onMoveDown();
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='move-up-tooltip'>Move Up</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onMoveUp();
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='duplicate-tooltip'>Duplicate</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onDuplicate();
                }}
              >
                <FontAwesomeIcon icon={faClone} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='delete-tooltip'>Delete</Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id='collapse-expand-tooltip'>
                  {activeEventKey === index.toString() ? 'Collapse' : 'Expand'}
                </Tooltip>
              }>
              <Button
                variant='link'
                size='sm'
                className='ms-1'>
                <FontAwesomeIcon icon={activeEventKey === index.toString() ? faChevronUp : faChevronDown} />
              </Button>
            </OverlayTrigger>

          </div>
        </Card.Header>
        <Accordion.Collapse eventKey={index.toString()}>
          <Card.Body>
            <ReviewTemplateQuestion
              index={index}
              question={question} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export default ReviewTemplateQuestionWidget;