import { Button } from '@themesberg/react-bootstrap';
import { Table, TableGlobalFilter, useTmblTable, useTmblTableData } from '@common/modules/table';
import { CustomDropdown } from 'components';
import { IActionTypes, INominateRevieweeInfo, ISimpleMenuOption } from 'interfaces';
import * as React from 'react';
import { REVIEW_AUDIENCE_TABLE_BULK_ACTIONS } from 'teamble-constants';

import {
  useReviewNominationAudienceTableColumns,
} from '../hooks/useReviewNominationAudienceTableColumns';

// updateNominateAudience

interface IReviewNominationAudienceTableProps {
  audience: Array<any>;
  isNominationInProgress: boolean;
  loadingAudience: boolean;
  isStartClearing: boolean;
  isAudienceAvailable: boolean;
  onPairRemove: (index: number) => void;
  onAudienceChange: (audience: any) => void;
  onBulkAction: (audience: any, updatedAudience: any) => void;
  onClearAllClick: () => void;
}


const ReviewNominationAudienceTable: React.FunctionComponent<
  IReviewNominationAudienceTableProps
> = ({
  audience,
  isNominationInProgress,
  isAudienceAvailable,
  isStartClearing,
  loadingAudience,
  onPairRemove,
  onAudienceChange,
  onBulkAction,
  onClearAllClick,
}) => {

    /* State Hooks */
    const [allRowsChecked, setAllRowsChecked] = React.useState<boolean>(false);
    const [showBulkAction, setShowBulkAction] = React.useState<boolean>(false);

    /* Table Hooks */
    const tableColumns = useReviewNominationAudienceTableColumns(isNominationInProgress);
    const tableData = useTmblTableData<INominateRevieweeInfo>(audience);
    const tableInstance = useTmblTable<INominateRevieweeInfo>(
      tableColumns,
      tableData,
      'reviewee',
    );


    React.useEffect(
      () => {
        if (audience.length > 0) {
          setAllRowsChecked(audience.every((row) => row.isChecked));
          setShowBulkAction(audience.some((row) => row.isChecked));
          return;
        }

        setShowBulkAction(false);
        setAllRowsChecked(false);
      },
      [audience]
    );


    const updateIsCheckedForAllRows = (isChecked: boolean): void => {
      setAllRowsChecked(isChecked);
      const newAudience = audience.map((aud) => ({ ...aud, isChecked }));
      onAudienceChange(newAudience);
    };


    const updateIsCheckedForRow = (index: number): void => {
      const newAudience = [
        ...audience.slice(0, index),
        {
          ...audience[index],
          isChecked: !audience[index].isChecked
        },
        ...audience.slice(index + 1)
      ]
      onAudienceChange(newAudience);
    };


    const handleAudienceBulkAction = (option: ISimpleMenuOption): void => {
      switch (option.value) {
        case IActionTypes.Remove: {
          const removeAudience = audience.filter((aud) => aud.isChecked);
          const updatedAudience = audience.filter((aud) => !aud.isChecked);
          onBulkAction(removeAudience, updatedAudience);
        }
      }
    };


    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <TableGlobalFilter
            className="w-50"
            globalFilter={tableInstance.state.globalFilter}
            setGlobalFilter={tableInstance.setGlobalFilter}
            placeholder="Search by Reviewee"
          />
          <div className='d-flex'>
            {showBulkAction && (
              <CustomDropdown
                id="audienceTableBulkActionDropdown"
                title="Bulk Actions"
                options={REVIEW_AUDIENCE_TABLE_BULK_ACTIONS}
                variant="outline-primary"
                onChange={handleAudienceBulkAction}
              />
            )}
            {
              !isNominationInProgress &&
              <Button
                type="button"
                className="ms-3"
                onClick={onClearAllClick}
                disabled={loadingAudience || !isAudienceAvailable || isStartClearing}
                variant="outline-danger"
              >
                {isStartClearing ? 'Clearing...' : 'Clear All'}
              </Button>
            }
          </div>
        </div>

        <Table
          tableInstance={tableInstance}
          headerCellProps={{
            allRowsChecked,
            updateIsCheckedForAllRows
          }}
          bodyCellProps={{
            onPairRemove,
            updateIsCheckedForRow,
          }} />

      </>
    );
  };


export default ReviewNominationAudienceTable;
