
import { Button, Col, Container, Row } from '@themesberg/react-bootstrap';
import { SignInWith } from "interfaces";
import React from "react";
import msTeamsLogo from './../../assets/img/ms-teams-logo.svg';
import slackLogo from './../../assets/img/slack-logo.svg';
import { useAuth } from "components";

const SignIn: React.FunctionComponent<{}> = () => {

  const { signIn } = useAuth();
  const [isSignInLoading, setIsSignInLoading] = React.useState<boolean>(false);


  return (
    <main className="h-100 d-flex align-items-center justify-content-center">
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">

                <div className="text-center mb-3">
                  <img
                    src="https://static.teamble.com/logos/teamble-logo--gradient.svg"
                    alt="Teamble - Gradient logo"
                    width="200" />
                </div>

                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to Teamble Admin</h3>
                </div>

                <div className="d-flex justify-content-center my-4">
                  <Button style={{width:'150px'}} variant="outline-primary" className="me-2" disabled={isSignInLoading}
                    onClick={() => {
                      setIsSignInLoading(true);
                      signIn(SignInWith.Slack);
                    }}
                  >
                    <img src={slackLogo} alt="Slack Logo" /> Slack
                  </Button>

                  <Button style={{width:'150px'}} variant="outline-primary" className="me-2" disabled={isSignInLoading}
                    onClick={() => {
                      setIsSignInLoading(true);
                      signIn(SignInWith.MsTeams);
                    }}
                  >
                    <img src={msTeamsLogo} alt="MS Teams Logo" /> MS Teams
                  </Button>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default SignIn;