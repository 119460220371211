import { Nav } from '@themesberg/react-bootstrap';
import { PermissionCheck } from 'components';
import { ITab } from 'interfaces';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import List from './List';

interface INavItemProps extends Omit<ITab, 'permission'> {
  asNavLink?: boolean;
}

interface ITabsProps {
  tabs: ITab[];
  asNavLink?: boolean;
  className?: string;
}


const NavItem: React.FC<INavItemProps> = (
  { toLink, label, asNavLink }
) => {

  // Route Hook
  const location = useLocation();

  return (
    <Nav.Item>
      {
        asNavLink ?
          <Nav.Link
            as={NavLink}
            to={{ pathname: toLink, search: location.search }}
          >
            {label}
          </Nav.Link>
          :
          <Nav.Link eventKey={toLink}>
            {label}
          </Nav.Link>
      }
    </Nav.Item>
  );
};

const Tabs: React.FC<ITabsProps> = (
  { tabs, className, asNavLink = true }
) => {

  return (
    <Nav className={`nav-tabs ${className}`}>
      <List
        items={tabs}
        renderItem={
          (tab) => tab.permission
            ?
            <PermissionCheck key={tab.label} permission={tab.permission}>
              <NavItem {...tab} asNavLink={asNavLink} />
            </PermissionCheck>
            :
            <NavItem key={tab.label} {...tab} asNavLink={asNavLink} />
        } />
    </Nav>
  );
};

export default Tabs;