import { Button } from '@themesberg/react-bootstrap';
import { ConfirmationModal, PageTitle, Preloader, PrimaryLayoutBox } from 'components';
import {
  cancelPerformanceReviewNomination,
  getActiveReviewById,
  getPerformanceReviewQuestionnairesAsMenuOptions,
  getRelationshipAsMenuOptions,
  launchNewReview,
  saveReviewDraft,
  startPerformanceReviewNomination,
} from 'data';
import { FormikHelpers, useFormik } from 'formik';
import { useApp, useOrganizationMenuOptions$ } from 'hooks';
import {
  IActiveReviewFormValues,
  IChannelMenuOption,
  IMenuOption,
  IRelationshipsResponse,
  IRouteParams,
  ISimpleMenuOption,
  ISlackWorkspaceUser,
  ShareReviewResultTypes,
  SignInWith,
} from 'interfaces';
import moment from 'moment';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { finalize, forkJoin } from 'rxjs';
import {
  DATE_TIME_FORMAT,
  REVIEW_RESULT_CAN_SHARE_BY_OPTIONS,
  REVIEW_RESULT_SHARE_AFTER_OPTIONS,
  REVIEW_RESULT_SHARE_WITH_OPTIONS,
} from 'teamble-constants';
import { LAUNCH_NEW_REVIEW_VALIDATION_SCHEMA } from 'validation-schemas';

import LaunchReviewForm from './components/LaunchReviewForm';
import { getLaunchReviewFormInitialValues } from './utils/formUtils';
import {
  getActiveReviewDates,
  getLaunchReviewRequestData,
  getSaveReviewRequestData,
} from './utils/utils';


type ModalToShow = 'launchReview' | 'startNominations' | 'cancelNominations' | 'alertNominations';


const LaunchNewReview: React.FunctionComponent<{}> = () => {

  /* State Hooks */
  const [modalToShow, setModalToShow] = React.useState<ModalToShow>();
  const [isReviewLoading, setIsReviewLoading] = React.useState<boolean>(false);
  const [isStartingNomination, setIsStartingNomination] = React.useState<boolean>(false);
  const [isCancellingNominations, setIsCancellingNominations] = React.useState<boolean>(false);
  const [isNominationInProgress, setIsNominationInProgress] = React.useState<boolean>(false);
  const [isLaunchReviewBtnEnable, setLaunchReviewBtnEnable] = React.useState<boolean>(false);
  const [reviewTemplates, setReviewTemplates] = React.useState<IMenuOption[] | null>(null);
  const [channelOptions, setChannelOptions] = React.useState<IChannelMenuOption[] | null>(null);
  const [invalidNominations, setInvalidNominations] = React.useState<ISlackWorkspaceUser[]>([]);
  const [relationshipsOptions, setRelationshipsOptions] = React.useState<ISimpleMenuOption[]>([]);

  /* Route Hooks */
  const { reviewId } = useParams<IRouteParams>();
  const history = useHistory();

  /* App hook */
  const app = useApp();

  /* Get workspace/group options */
  const channelOrWorkspaceMenuOptions$ = useOrganizationMenuOptions$();


  /* Launch the review */
  const submitLaunchNewReviewForm = (
    values: IActiveReviewFormValues,
    formikHelpers: FormikHelpers<IActiveReviewFormValues>
  ): void => {

    const requestData = getLaunchReviewRequestData(app, values);

    if (requestData) {
      formikHelpers.setSubmitting(true);
      launchNewReview(requestData, reviewId).subscribe(
        {
          next: (response) => {
            // calling settimeout to avoid state data fetch
            setTimeout(() => {
              formikHelpers.resetForm();
              formikHelpers.setSubmitting(false);
              toast.success(response.data.message);
              history.replace(Routes.ReviewsListPage.path);
            }, 2000);
          }
        }
      )
    }
  };


  /* Form Hook */
  const launchNewReviewForm = useFormik<IActiveReviewFormValues>({
    initialValues: getLaunchReviewFormInitialValues(),
    validationSchema: LAUNCH_NEW_REVIEW_VALIDATION_SCHEMA,
    onSubmit: submitLaunchNewReviewForm
  });


  /* Enable/Disable the Launch Review button */
  React.useEffect(
    () => {
      if (reviewId && launchNewReviewForm.isValid) {
        if (isNominationInProgress) {
          setLaunchReviewBtnEnable(true);
        } else if (launchNewReviewForm?.values?.reviewerRevieweeInfos?.length) {
          setLaunchReviewBtnEnable(true);
        } else {
          setLaunchReviewBtnEnable(false);
        }
      } else {
        setLaunchReviewBtnEnable(false);
      }
    },
    [isNominationInProgress, launchNewReviewForm, launchNewReviewForm.isValid, reviewId]
  )


  /* Set the form values after fetching Review templates & workspace/group options */
  React.useEffect(
    () => {
      setIsReviewLoading(true);

      forkJoin([
        channelOrWorkspaceMenuOptions$,
        getPerformanceReviewQuestionnairesAsMenuOptions(),
        getRelationshipAsMenuOptions()
      ]).subscribe(
        ([channelResponse, reviewTemplateResponse, relationshipResponse]) => {
          const relationshipOptions = relationshipResponse.data.data;
          setChannelOptions(channelResponse.data.data);
          setReviewTemplates(reviewTemplateResponse.data.data);
          setRelationshipsOptions(relationshipOptions);

          if (reviewId) {
            // setIsReviewLoading(true);
            // launchNewReviewForm.setValues(launchNewReviewFormInitialValues);

            getActiveReviewById(reviewId).subscribe(
              (response) => {

                const activeReviewResponse = response.data.data;
                setIsNominationInProgress(activeReviewResponse.nominationInProcess);

                if (reviewTemplateResponse.data.data && channelResponse.data.data && activeReviewResponse) {

                  const platformId = app === SignInWith.Slack ? response.data.data.workspaceId : response.data.data.channelId
                  const channel = channelResponse.data.data.find(
                    (option) => option.value === platformId
                  );

                  const reviewTemplate = reviewTemplateResponse.data.data.find(
                    (option) => option.value === activeReviewResponse.perfReviewTemplateId
                  );

                  let shareResultAfter: ISimpleMenuOption | null = null;
                  if (activeReviewResponse.resultSharingConfig?.isAutomaticOnSubmitReview) {
                    shareResultAfter = REVIEW_RESULT_SHARE_AFTER_OPTIONS[1];
                  } else if (activeReviewResponse.resultSharingConfig?.isAutomaticOnReviewClose) {
                    shareResultAfter = REVIEW_RESULT_SHARE_AFTER_OPTIONS[0];
                  }

                  const dates = getActiveReviewDates(activeReviewResponse);
                  const activeReviewResultShareWith = activeReviewResponse.resultSharingConfig?.shareResultWith || [];
                  let shareResultWith = relationshipOptions.filter(option => activeReviewResultShareWith.includes(option.value));

                  if (activeReviewResultShareWith.includes("reviewees")) {
                    shareResultWith.push({ label: "Reviewees", value: "reviewees" } as IRelationshipsResponse)
                  }

                  launchNewReviewForm.setValues({
                    title: activeReviewResponse.title,
                    channel: channel || null,
                    reviewTemplate: reviewTemplate || null,
                    startDate: dates[0] ? moment(dates[0], DATE_TIME_FORMAT).toDate() : null,
                    endDate: dates[1] ? moment(dates[1], DATE_TIME_FORMAT).toDate() : null,
                    anonymous: !!(activeReviewResponse.anonymity && activeReviewResponse.anonymity === 'anonymous'),
                    shareWithType: activeReviewResponse.resultSharingConfig?.isAutomatic
                      ? ShareReviewResultTypes.AUTOMATED
                      : ShareReviewResultTypes.MANUAL,
                    canShareResult: activeReviewResponse.resultSharingConfig?.isManualToReviewer
                      ? REVIEW_RESULT_CAN_SHARE_BY_OPTIONS
                      : [REVIEW_RESULT_CAN_SHARE_BY_OPTIONS[0]],
                    shareResultWith: shareResultWith,
                    shareResultAfter,
                    reviewerRevieweeInfos: activeReviewResponse.reviewerRevieweeInfos,
                    nominatorAudience: response?.data?.data?.nominatorAudience,
                    isAutomaticCloseOnEndDate: activeReviewResponse?.isAutomaticCloseOnEndDate,
                    multiCampaign: activeReviewResponse.multiCampaign
                  });
                }
                setIsReviewLoading(false);
              }
            );
          } else {
            setIsReviewLoading(false);
          }

        }
      );

    },
    []
  );


  /* Auto save the details */
  React.useEffect(
    () => {

      if (
        isReviewLoading ||
        !launchNewReviewForm.values.title ||
        !launchNewReviewForm.values.channel
      ) {
        return;
      }

      const requestData = getSaveReviewRequestData(app, launchNewReviewForm.values);

      if (!requestData) {
        return;
      }

      const subscription = saveReviewDraft(requestData, reviewId).subscribe(
        (response) => {

          setIsNominationInProgress(response.data.data.nominationInProcess);
          if (!reviewId) {
            history.replace(`/reviews/manage-reviews/draft/${response.data.data.id}`);
          }

        }
      );

      return () => subscription.unsubscribe();

    },
    [launchNewReviewForm.values, reviewId, history, app]
  );


  /* Start nominations */
  const startNominations = (initialCheck: boolean): void => {

    if (!reviewId || !launchNewReviewForm.values.channel?.value) {
      return;
    }

    setIsStartingNomination(true);
    startPerformanceReviewNomination(
      reviewId,
      launchNewReviewForm.values.channel?.value,
      initialCheck
    )
      .subscribe(
        {
          next: (response) => {

            if (initialCheck) {
              if (response.data.data.length) {
                setModalToShow('startNominations');
                setInvalidNominations(response.data.data);
                setIsStartingNomination(false);
              } else {
                startNominations(false);
              }
            } else {
              toast.success(response.data.message);
              setIsNominationInProgress(true);
              setIsStartingNomination(false);
            }
          },
          error: () => {
            setIsStartingNomination(false);
          }
        }
      );

  };


  const cancelNominations = (): void => {

    if (!reviewId) {
      return;
    }

    setIsCancellingNominations(true);

    cancelPerformanceReviewNomination(reviewId)
      .pipe(
        finalize(() => setIsCancellingNominations(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          window.location.reload();
        }
      );
  };


  const handleLaunchReviewClick = (): void => {

    if (
      !isNominationInProgress &&
      launchNewReviewForm.values.nominatorAudience?.verifiedEmailPairs?.length
    ) {
      setModalToShow('alertNominations');
      return;
    }
    setModalToShow('launchReview');
  };


  let scheduled = false;
  if (launchNewReviewForm.values.startDate) {
    scheduled = true;
  }

  return (
    <>
      <PageTitle title={Routes.LaunchReview.title} />
      <PrimaryLayoutBox
        title={Routes.LaunchReview.title}
        actions={
          <div className='d-flex align-items-center'>
            {
              isNominationInProgress &&
              <>
                <p className='text-success fw-bold m-0'>
                  Nominations are in progress.<br />
                  Launch Review to automatically have approved nominations start with reviews.
                </p>
                <Button
                  type='button'
                  className='ms-2'
                  variant='outline-danger'
                  disabled={launchNewReviewForm.isSubmitting || isCancellingNominations}
                  onClick={() => setModalToShow('cancelNominations')}
                >
                  {isCancellingNominations ? 'Cancelling Nominations...' : 'Cancel Nominations'}
                </Button>
              </>
            }
            <Button
              type='button'
              className='ms-2'
              disabled={isCancellingNominations || !isLaunchReviewBtnEnable || launchNewReviewForm.isSubmitting}
              onClick={handleLaunchReviewClick}
            >
              {launchNewReviewForm.isSubmitting ? `${scheduled ? "Scheduling" : "Launching"} Review...` : `${scheduled ? "Schedule Review" : "Launch Review"}`}
            </Button>
          </div>
        }
      >
        {
          isReviewLoading
            ?
            <Preloader show={isReviewLoading} />
            :
            <LaunchReviewForm
              isNominationInProgress={isNominationInProgress}
              isStartingNomination={isStartingNomination}
              startNominations={() => startNominations(true)}
              launchNewReviewForm={launchNewReviewForm}
              reviewTemplates={reviewTemplates}
              channelOptions={channelOptions}
              relationshipsOptions={relationshipsOptions}
            />
        }
      </PrimaryLayoutBox>

      {
        modalToShow === 'launchReview' &&
        <ConfirmationModal
          title={scheduled ? "Schedule Review" : "Launch Review"}
          show={modalToShow === 'launchReview'}
          onPositiveResponse={() => launchNewReviewForm.submitForm()}
          onCloseClick={() => setModalToShow(undefined)}
        >
          <p>
            You are about to {scheduled ? "schedule" : "launch"} the review <strong>{launchNewReviewForm.values.title}</strong>
            {
              launchNewReviewForm.values.reviewerRevieweeInfos?.length
                ?
                ` between ${launchNewReviewForm.values.reviewerRevieweeInfos?.length} audience pairs`
                :
                ''
            }, please confirm.
          </p>
        </ConfirmationModal>
      }

      {
        modalToShow === 'cancelNominations' &&
        <ConfirmationModal
          title='Cancel Nominations'
          show={modalToShow === 'cancelNominations'}
          onPositiveResponse={cancelNominations}
          onCloseClick={() => setModalToShow(undefined)}
        >
          <p>
            Are you sure to cancel the nominations of the review <strong>{launchNewReviewForm.values.title}</strong>?
          </p>
          <p>
            <em>
              This action is irreversible and wipes out all the data of the nominations.
            </em>
          </p>
        </ConfirmationModal>
      }

      {
        modalToShow === 'alertNominations' &&
        <ConfirmationModal
          title='Alert'
          show={modalToShow === 'alertNominations'}
          positiveText=''
          negativeText='Close'
          onNegativeResponse={() => setModalToShow(undefined)}
          onCloseClick={() => setModalToShow(undefined)}
        >
          <p>
            You have added nomination audience but haven't started the nominations.
            Either start nominations or clear nomination audience before launching the review.
          </p>
        </ConfirmationModal>
      }

      {
        invalidNominations.length && modalToShow === 'startNominations'
          ?
          <ConfirmationModal
            title='Alert'
            show={modalToShow === 'startNominations'}
            onPositiveResponse={() => startNominations(false)}
            onCloseClick={() => setModalToShow(undefined)}
          >
            <p>
              The following users do not have managers assigned in the system. Are you sure to launch the nomination process?
            </p>
            <ul>
              {
                invalidNominations.map(
                  (nomination) => (
                    <li key={nomination.id}> <strong> {nomination.name} </strong> </li>
                  )
                )
              }
            </ul>
          </ConfirmationModal>
          :
          null
      }

    </>
  );
};

export default LaunchNewReview;
