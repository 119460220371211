import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { CollapsibleCard, FormField } from 'components';
import { FormContext } from 'contexts';
import { removeFeedbackLoopSchedule, updateFeedbackLoopScheduler } from 'data';
import { useFormik } from 'formik';
import { useSchedulerEvents, useSchedulerFrequencyOptions } from 'hooks';
import {
  FormFieldTypeConstants,
  IFeedbackLoopTemplateTypes,
  IRouteParams,
  ISchedulerFormValues,
  IUpdateFeedbackLoopSchedulerRequestData,
} from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { ONE_ON_ONE_SCHEDULER_VALIDATION_SCHEMA, SCHEDULER_VALIDATION_SCHEMA } from 'validation-schemas';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import { getFeedbackLoopSchedulerFormValues } from '../utils/formUtils';
import { useParams } from 'react-router-dom';

interface IFeedbackLoopSchedulerWidgetProps { }

const FeedbackLoopSchedulerWidget: React.FC<IFeedbackLoopSchedulerWidgetProps> = () => {
  const { templateType } = useParams<IRouteParams>();
  // State Hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Feedback Loop Data
  const { feedbackLoop, onFeedbackLoopUpdate } = useFeedbackLoopConfig();

  // Form Hook
  const schedulerForm = useFormik<ISchedulerFormValues>({
    initialValues: getFeedbackLoopSchedulerFormValues(feedbackLoop),
    validationSchema:SCHEDULER_VALIDATION_SCHEMA,
    // validationSchema:templateType===IFeedbackLoopTemplateTypes?.Feedback ? SCHEDULER_VALIDATION_SCHEMA: ONE_ON_ONE_SCHEDULER_VALIDATION_SCHEMA,
    onSubmit: () => { }
  });

  // Hook to get the frequency options on start date change
  const frequencyOptions = useSchedulerFrequencyOptions(
    schedulerForm.isValid,
    schedulerForm.values.schedulerDateTime,
  );

  // Hook to get next 3 events according to start date and frequency
  const schedulerEvents = useSchedulerEvents(
    schedulerForm.isValid,
    schedulerForm.values.schedulerDateTime,
    schedulerForm.values.schedulerFrequency,
  );


  // Set the value of frequency dropdown from frequency options list.
  // `frequencyOptions` has the correct labels according to the selected
  // start date.
  React.useEffect(
    () => {
      if(schedulerForm.values.schedulerDateTime && templateType===IFeedbackLoopTemplateTypes?.OneOnOne){
        schedulerForm.setFieldValue('schedulerFrequency', {value: 'does-not-repeat', label: 'Does not repeat'} );
        return;
      }
      if (schedulerForm.values.schedulerFrequency) {
        const newFrequency = frequencyOptions.find(
          (option) => option.value === schedulerForm.values.schedulerFrequency?.value
        );
        schedulerForm.setFieldValue('schedulerFrequency', newFrequency || null);
      }
    },
    [frequencyOptions, schedulerForm.values.schedulerDateTime,templateType]
  );


  // Make an API call to update the feedback loop scheduler.
  React.useEffect(
    () => {

      // Only make an API call if
      // 1. Scheduler form is valid and
      // 2. The value of start date-time or frequency is changed.

      if (
        (
          !schedulerForm.isValid ||
          !schedulerForm.values.schedulerDateTime ||
          !schedulerForm.values.schedulerFrequency
        ) ||
        (
          schedulerForm.values.schedulerDateTime?.getTime() ===
          feedbackLoop.schedule?.timestamp &&
          schedulerForm.values.schedulerFrequency?.value ===
          feedbackLoop.schedule?.frequency
        )
      ) {
        return;
      }

      const requestData: IUpdateFeedbackLoopSchedulerRequestData = {
        feedbackLibraryLoopId: feedbackLoop.id,
        timestamp: schedulerForm.values.schedulerDateTime!.getTime(),
        frequency: schedulerForm.values.schedulerFrequency!.value,
        timezone: -(new Date().getTimezoneOffset() * 60 * 1000),
      };

      setIsLoading(true);
      if(!templateType) return;
      const subscription = updateFeedbackLoopScheduler(requestData,templateType)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            onFeedbackLoopUpdate(response.data.data);
            toast.success(response.data.message);
          }
        );

      return () => subscription.unsubscribe();

    },
    [
      feedbackLoop.id,
      feedbackLoop.workspaceId,
      schedulerForm.isValid,
      schedulerForm.values.schedulerFrequency,
      schedulerForm.values.schedulerDateTime,
      feedbackLoop.schedule,
      onFeedbackLoopUpdate,
      templateType
    ]
  );


  const handleScheduleClear = (): void => {

    setIsLoading(true);
    removeFeedbackLoopSchedule(feedbackLoop.id)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          onFeedbackLoopUpdate(response.data.data);
          schedulerForm.setValues({
            schedulerFrequency: null,
            schedulerDateTime: null,
          });
          schedulerForm.setTouched({
            schedulerFrequency: false,
            schedulerDateTime: false,
          });
        }
      );

  }


  return (
    <CollapsibleCard
      title='Scheduler'
      isLoading={isLoading}
    >
      <Form>
        <FormContext.Provider value={schedulerForm}>
          <Card.Body>
            <Row>
              <Col>
                <FormField
                  label='Start'
                  isDisabled={isLoading}
                  controlId={`schedulerFormSchedulerDateTime`}
                  type={FormFieldTypeConstants.Datepicker}
                  controlName='schedulerDateTime' />
              </Col>
              {templateType === IFeedbackLoopTemplateTypes.Feedback &&  <Col>
                {
                  <FormField
                    label='Frequency'
                    isDisabled={isLoading}
                    controlId={`schedulerFormSchedulerFrequency`}
                    type={FormFieldTypeConstants.Select}
                    options={frequencyOptions}
                    controlName='schedulerFrequency' />
                }
              </Col> }
             
            </Row>
            {
              schedulerEvents.length > 0 &&
              schedulerEvents.map(
                (event, index) => {
                  return (
                    <p
                      key={index}
                      className='m-0'
                    >
                      <span className='fw-bold'>{event.weekday}, {event.date}</span> at
                      <span className='fw-bold'> {' ' + event.time} </span>
                      {
                        event.warning &&
                        <span>, {event.warning}</span>
                      }
                    </p>
                  );
                }
              )
            }
          </Card.Body>

          <Card.Footer className='d-flex justify-content-end bg-light py-2 px-3'>
            <Button
              type='button'
              size='sm'
              variant='danger'
              disabled={isLoading}
              className='me-2'
              onClick={handleScheduleClear}> Clear Schedule </Button>
          </Card.Footer>
        </FormContext.Provider>
      </Form>
    </CollapsibleCard>
  );
};

export default FeedbackLoopSchedulerWidget;