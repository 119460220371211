import React from "react";
import "./PrimaryButton.scss";
interface PrimaryButtonProps {
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
  loadingText?: string;
}
const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  onClick,
  isLoading,
  loadingText,
}: any) => {
  return (
    <button
      onClick={() => {
        onClick?.();
      }}
      disabled={isLoading}
      className="PrimaryButton"
    >
      {isLoading ? loadingText : text}
    </button>
  );
};

export default PrimaryButton;
