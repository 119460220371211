import { Card } from '@themesberg/react-bootstrap';
import { Table, TableFooterCell, useTmblTableData } from '@common/modules/table';
import { useTmblTable } from '@common/modules/table';
import { IFeedbackLeaderBoardUser } from 'interfaces';
import React from 'react';
import { Column } from 'react-table';


const useLeaderBoardTableColumns = (): readonly Column<IFeedbackLeaderBoardUser>[] => {

  return React.useMemo<readonly Column<IFeedbackLeaderBoardUser>[]>(() => {

    return [
      {
        id: 'name',
        Header: 'Name',
        Footer: 'Total',
        accessor: 'user_name',
        cellClassName: '',
      },
      {
        Header: 'Feedback Given',
        cellClassName: 'text-center',
        accessor: 'feedback_sent',
        Footer: (info) => <TableFooterCell rows={info.rows} rowKey='feedback_sent' />
      },
      {
        Header: 'Feedback Received',
        cellClassName: 'text-center',
        accessor: 'feedback_received',
        Footer: (info) => <TableFooterCell rows={info.rows} rowKey='feedback_received' />
      },
      {
        Header: 'Feedback Requested',
        cellClassName: 'text-center',
        accessor: 'feedback_requested',
        Footer: (info) => <TableFooterCell rows={info.rows} rowKey='feedback_requested' />
      },
      {
        Header: 'Praise Sent',
        cellClassName: 'text-center',
        accessor: 'praise_sent',
        Footer: (info) => <TableFooterCell rows={info.rows} rowKey='praise_sent' />
      },
      {
        Header: 'Survey Submitted',
        cellClassName: 'text-center',
        accessor: 'survey_submitted',
        Footer: (info) => <TableFooterCell rows={info.rows} rowKey='survey_submitted' />
      },
      {
        id: 'total',
        Header: 'Total',
        cellClassName: 'text-center',
        accessor: 'total',
        Footer: (info) => <TableFooterCell rows={info.rows} rowKey='total' />
      },
    ];
  },
    [],
  )
};


const FeedbackInsightsLeaderBoardWidget: React.FunctionComponent<{ leadershipBoard: IFeedbackLeaderBoardUser[] }> = ({ leadershipBoard }) => {

  const tableColumns = useLeaderBoardTableColumns();
  leadershipBoard.length && leadershipBoard.pop();
  const tableData = useTmblTableData<IFeedbackLeaderBoardUser>(leadershipBoard);

  const tableInstance = useTmblTable<IFeedbackLeaderBoardUser>(
    tableColumns,
    tableData,
    'total',
    true
  );


  return (
    <Card>

      <Card.Header className='bg-light p-2'>
        <Card.Title className='m-0 fs-6 fw-bold'>
          <span role='img' aria-label='Leader Board trophy'> 🏆 </span> Leader Board
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Table
          showFooter
          stickyHeader={false}
          tableInstance={tableInstance}
        />
      </Card.Body>
    </Card>
  );
};

export default FeedbackInsightsLeaderBoardWidget;