import { faHashtag, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "@themesberg/react-bootstrap";
import { ImageWithNameInitials } from "components";
import { IMenuOption } from "interfaces";
import * as React from "react";
import Select, {
  ActionMeta,
  components,
  GroupTypeBase,
  OptionProps,
  OptionsType,
  Styles,
  ValueContainerProps,
  ValueType,
} from "react-select";
import { SELECT_ALL_OPTION } from "teamble-constants";

export interface ICustomSelectProps {
  isMulti?: boolean;
  className?: string;
  isInvalid?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  allowSelectAll?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  options: IMenuOption[];
  value?: IMenuOption | IMenuOption[] | null;
  optionType?: "user" | "checkbox" | "description" | "default" | "slackChannel";
  isNested?: boolean;
  onBlur?: () => void;
  onChange: (
    value: ValueType<IMenuOption | IMenuOption[], boolean>,
    action: ActionMeta<IMenuOption>
  ) => void;
}

const teambleStyle: Partial<
  Styles<IMenuOption, boolean, GroupTypeBase<IMenuOption>>
> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    border: `0.0625rem solid ${
      state.selectProps.isInvalid ? "#FA5252" : "#D1D7E0"
    }`,
    minHeight: "unset",
    padding: "0.3rem 0.75rem",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#4A5073",
    boxShadow: "unset",
    ":focus": {
      borderColor: "#566190",
      outline: 0,
    },
    ":hover": {
      borderColor: state.selectProps.isInvalid ? "#FA5252" : "#D1D7E0",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 61,
    overflowY: "auto",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  placeholder: (provided) => ({
    ...provided,
    color: "#66799e",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0 0 0 8px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    margin: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "6px 0 6px 10px",
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    zIndex: 999,
  }),
  multiValueLabel: (provided, state) => {
    return state.data.isFixed ? { ...provided, paddingRight: 6 } : provided;
  },
  multiValueRemove: (provided, state) => {
    return state.data.isFixed ? { ...provided, display: "none" } : provided;
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#d1d7e0"
      : state.isSelected
      ? "unset"
      : "inherit",
    color: state.isSelected ? "unset" : "inherit",
    fontWeight: state.isSelected ? 700 : "inherit",
    padding: "0.5rem 1.375rem",
    ":hover": {
      backgroundColor: "#d1d7e0",
    },
  }),
};

const UserSelectOption: React.ComponentType<
  OptionProps<IMenuOption, boolean, GroupTypeBase<IMenuOption>>
> = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        {props.isMulti && (
          <Form.Check type="checkbox" className="flex-shrink-0 me-2">
            <Form.Check.Input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
          </Form.Check>
        )}
        <ImageWithNameInitials
          imageOriginal={props.data.imageOriginal}
          label={props.label}
        />
        <div className="ms-2">
          <p className="my-0"> {props.label} </p>
          {props.data.description && (
            <p className="my-0 fs-8">
              {" "}
              <em> {props.data.description} </em>{" "}
            </p>
          )}
        </div>
      </div>
    </components.Option>
  );
};

const CheckboxSelectOption: React.ComponentType<
  OptionProps<IMenuOption, boolean, GroupTypeBase<IMenuOption>>
> = (props) => {
  return (
    <components.Option {...props}>
      <Form.Check type="checkbox" className="d-flex">
        <Form.Check.Input
          type="checkbox"
          className="flex-shrink-0"
          checked={props.isSelected}
          onChange={() => null}
        />
        <Form.Check.Label className="fw-normal ms-1">
          {" "}
          {props.label}{" "}
        </Form.Check.Label>
      </Form.Check>
      {props.data.description && (
        <p className="my-0 ms-4 fs-8">
          {" "}
          <em> {props.data.description} </em>{" "}
        </p>
      )}
    </components.Option>
  );
};

const DescriptionSelectOption: React.ComponentType<
  OptionProps<IMenuOption, boolean, GroupTypeBase<IMenuOption>>
> = (props) => {
  return (
    <components.Option {...props}>
      <p className="my-0"> {props.label} </p>
      {props.data.description && (
        <p className="my-0 fs-8">
          {" "}
          <em> {props.data.description} </em>{" "}
        </p>
      )}
    </components.Option>
  );
};

const SlackChannelSelectOption: React.ComponentType<
  OptionProps<IMenuOption, boolean, GroupTypeBase<IMenuOption>>
> = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          size="sm"
          icon={props.data.is_private ? faLock : faHashtag}
        />
        <p className="my-0 ms-2"> {props.label} </p>
      </div>
    </components.Option>
  );
};

const MultiSelectValueContainer: React.ComponentType<
  ValueContainerProps<IMenuOption, boolean, GroupTypeBase<IMenuOption>>
> = ({ children, ...props }) => {
  if (!children) {
    return null;
  }

  const currentValues = props.getValue();
  const typedChildren = children as React.ReactNode;
  let toBeRendered = typedChildren;

  if (currentValues.some((val) => val.value === SELECT_ALL_OPTION.value)) {
    toBeRendered = [
      [typedChildren?.[0 as keyof typeof typedChildren][0]],
      typedChildren?.[1 as keyof typeof typedChildren],
    ];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const CustomSelect: React.FunctionComponent<ICustomSelectProps> = ({
  options,
  value,
  className,
  placeholder = "Select...",
  optionType = "default",
  isMulti = false,
  isSearchable = false,
  isInvalid = false,
  allowSelectAll = false,
  isLoading = false,
  isNested = false,
  isDisabled = false,
  isClearable = false,
  onChange,
  onBlur,
}) => {
  const handleChange = (
    selectedOptions: IMenuOption | OptionsType<IMenuOption> | null,
    action: ActionMeta<IMenuOption>
  ): void => {
    let multipleSelectedOptions = selectedOptions as IMenuOption[];

    if (
      multipleSelectedOptions !== null &&
      multipleSelectedOptions.length > 0
    ) {
      if (
        multipleSelectedOptions[multipleSelectedOptions.length - 1].value ===
        SELECT_ALL_OPTION.value
      ) {
        if (isNested) {
          multipleSelectedOptions = options?.map((o) => o?.options || o).flat();
        } else {
          multipleSelectedOptions = options;
        }
      }
      let result: IMenuOption[] = [];
      //nested dropdown
      if (isNested) {
        const nestedOptions: any = options.map((o) => o?.options || o).flat();
        if (multipleSelectedOptions.length === nestedOptions.length - 1) {
          if (
            multipleSelectedOptions
              ?.map((o: any) => o?.value)
              .includes(SELECT_ALL_OPTION?.value)
          ) {
            result = multipleSelectedOptions.filter(
              (option) => option.value !== SELECT_ALL_OPTION.value
            );
          } else if (action.action === "select-option") {
            result = nestedOptions;
          }
          return onChange(result, action);
        }
      } else {
        if (multipleSelectedOptions.length === options.length - 1) {
          if (multipleSelectedOptions.includes(SELECT_ALL_OPTION)) {
            result = multipleSelectedOptions.filter(
              (option) => option.value !== SELECT_ALL_OPTION.value
            );
          } else if (action.action === "select-option") {
            result = options;
          }
          return onChange(result, action);
        }
      }
    }

    return onChange(multipleSelectedOptions, action);
  };

  React.useEffect(() => {
    if (!isMulti || !allowSelectAll) {
      return;
    }

    if (
      !isNested &&
      (value as IMenuOption[]).length === options?.length - 1 &&
      !(value as IMenuOption[]).includes(SELECT_ALL_OPTION)
    ) {
      handleChange([...(value as IMenuOption[]), SELECT_ALL_OPTION], {
        name: undefined,
        option: SELECT_ALL_OPTION,
        action: "select-option",
      });
    }
  }, [allowSelectAll, isMulti, options?.length, value, isNested]);

  React.useEffect(() => {
    if (
      isNested &&
      value &&
      (value as IMenuOption[])
        .map((v) => v.value)
        .includes(SELECT_ALL_OPTION.value)
    ) {
      handleChange([...(value as IMenuOption[]), SELECT_ALL_OPTION], {
        name: undefined,
        option: SELECT_ALL_OPTION,
        action: "select-option",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNested, (value as IMenuOption[])?.length]);

  return (
    <Select
      value={value}
      options={options}
      isMulti={isMulti}
      styles={teambleStyle}
      className={className}
      isLoading={isLoading}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isClearable={
        (isMulti && !options.some((opt) => opt.isFixed)) || isClearable
      }
      closeMenuOnSelect={!isMulti}
      classNamePrefix="teamble-select"
      components={
        optionType === "slackChannel"
          ? {
              Option: SlackChannelSelectOption,
              ValueContainer: isMulti ? MultiSelectValueContainer : undefined,
            }
          : optionType === "user"
          ? {
              Option: UserSelectOption,
              ValueContainer: isMulti ? MultiSelectValueContainer : undefined,
            }
          : optionType === "description"
          ? { Option: DescriptionSelectOption }
          : optionType === "checkbox"
          ? {
              Option: CheckboxSelectOption,
              ValueContainer: MultiSelectValueContainer,
            }
          : {}
      }
      hideSelectedOptions={
        !(optionType === "checkbox" || optionType === "user")
      }
      onChange={isMulti && allowSelectAll ? handleChange : onChange}
      onBlur={onBlur}
    />
  );
};

export default CustomSelect;
