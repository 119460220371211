import * as React from 'react';

const BackButtonArrowIcon = ({
    width = 6,
    height = 10,
    color = '#9E9FA2',
    strokeWidth = 1.3,
    viewBox = '0 0 6 10',
    ...props
}) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox={viewBox}
        {...props}
    >
        <path
            stroke={color}
            strokeWidth={strokeWidth}
            d='M4.875 1.25L1.125 5l3.75 3.75'
        />
    </svg>
);

export default BackButtonArrowIcon;
