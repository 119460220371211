import { Tabs } from '@common/components';
import { Tab } from '@themesberg/react-bootstrap';
import { IRouteParams, ReviewSummaryTabTypes } from 'interfaces';
import React from 'react';
import { useParams } from 'react-router-dom';
import { REVIEW_TODO_SUMMARY_TABS } from 'teamble-constants';
import { StringParam, useQueryParam } from 'use-query-params';

import RevieweeFeedbackListCard from './RevieweeFeedbackListCard';
import RevieweeGoalsListCard from './RevieweeGoalsListCard';
import RevieweeReviewsListCard from './RevieweeReviewsListCard';
import { useCompanyConfig } from '@common/modules/companyConfig';
import RevieweeObjectiveListCard from './RevieweeObjectiveListCard';

interface IRevieweeSummaryWidgetProps { }

const RevieweeSummaryWidget: React.FC<IRevieweeSummaryWidgetProps> = () => {

  const [organizationId] = useQueryParam('organizationId', StringParam);
  const { reviewId } = useParams<IRouteParams>();
  const companyConfig = useCompanyConfig();
  const [tabs, setTabs] = React.useState([...REVIEW_TODO_SUMMARY_TABS]);

  React.useEffect(() => {
    if (companyConfig.useObjectivesInWebAdmin) {
      setTabs(tabs.filter(tab => tab.toLink !== ReviewSummaryTabTypes.Goals))
    } else {
      setTabs(tabs.filter(tab => tab.toLink !== ReviewSummaryTabTypes.Objectives))
    }
  }, [])


  return (
    <div className='h-100 overflow-hidden d-flex flex-column'>

      <Tab.Container
        mountOnEnter
        defaultActiveKey={companyConfig.useObjectivesInWebAdmin ? ReviewSummaryTabTypes.Objectives : ReviewSummaryTabTypes.Goals}
      >
        <Tabs
          asNavLink={false}
          className='justify-content-evenly flex-shrink-0'
          tabs={tabs}
        />

        {
          reviewId &&
          <Tab.Content className='flex-grow-1 overflow-hidden'>

            {
              companyConfig.useObjectivesInWebAdmin && (
                <Tab.Pane
                  className='h-100 overflow-hidden'
                  eventKey={ReviewSummaryTabTypes.Objectives}
                >
                  <RevieweeObjectiveListCard
                    perfReviewId={reviewId}
                    revieweeUserId={reviewId.split('|')[2]}
                  />
                </Tab.Pane>
              )
            }

            {/* Goals */}
            <Tab.Pane
              className='h-100 overflow-hidden'
              eventKey={ReviewSummaryTabTypes.Goals}
            >
              <RevieweeGoalsListCard
                perfReviewId={reviewId}
                revieweeUserId={reviewId.split('|')[2]} />
            </Tab.Pane>

            {/* Feedback */}
            <Tab.Pane
              className='h-100 overflow-hidden'
              eventKey={ReviewSummaryTabTypes.Feedback}
            >
              <RevieweeFeedbackListCard
                reviewId={reviewId}
              />
            </Tab.Pane>

            {/* Reviews */}
            <Tab.Pane
              className='h-100 overflow-hidden'
              eventKey={ReviewSummaryTabTypes.Reviews}
            >
              <RevieweeReviewsListCard reviewId={reviewId} />
            </Tab.Pane>

          </Tab.Content>
        }
      </Tab.Container>
    </div>
  );
};

export default RevieweeSummaryWidget;