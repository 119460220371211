import React from 'react'

interface ISpacingProps {
  spacing: string;
}

const Spacing: React.FC<ISpacingProps> = ({ spacing, children }) => {

  return (
    <div className={spacing}>{children}</div>
  );
};

export default Spacing;