import { ISimpleMenuOption } from "interfaces";

export enum ObjectiveCampaignTypes {
  Users = "users",
  Company = "company",
}

export const OBJECTIVE_CAMPAIGN_AUDIENCE_TYPES: ISimpleMenuOption[] = [
  {
    value: ObjectiveCampaignTypes.Company,
    label: ObjectiveCampaignTypes.Company,
  },
  {
    value: ObjectiveCampaignTypes.Users,
    label: ObjectiveCampaignTypes.Users,
  },
];
