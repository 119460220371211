import Axios from "axios-observable";
import { IResponse } from "interfaces";

export const createObjectiveCampaign = (
  title: string,
  audience: any,
  startDate?: number,
  endDate?: number,
  notifyEmployees?: boolean,
  notifyManagers?: boolean
) => {
  return Axios.post<IResponse<any>>("/objectives/create-objectives-campaign ", {
    title,
    startDate,
    endDate,
    audience,
    notifyEmployees,
    notifyManagers,
  });
};

export const getObjectiveCampaignList = () => {
  return Axios.post("/objectives/list-objectives-campaigns");
};

export const getObjectiveCampaignById = (objectivesCampaignId: string) => {
  return Axios.post("/objectives/get-objectives-campaign", {
    objectivesCampaignId,
  });
};

export const updateObjectiveCampaignId = (
  objectivesCampaignId: string,
  title: string,
  audience: any,
  startDate?: number,
  endDate?: number,
  notifyEmployees?: boolean,
  notifyManagers?: boolean
) => {
  return Axios.post<IResponse<any>>("/objectives/update-objectives-campaign", {
    objectivesCampaignId,
    title,
    startDate,
    endDate,
    audience,
    notifyEmployees,
    notifyManagers,
  });
};

export const launchObjectiveCampaign = (objectivesCampaignId: string) => {
  return Axios.post<IResponse<any>>("/objectives/launch-objectives-campaign", {
    objectivesCampaignId,
  });
};
