import { IMenuOption } from 'interfaces';

import { IMenuOptionWithText } from './menuOptions.interface';


export enum MetaDataKeys {
  QuestionTypes = 'questionTypes',
  MultiSelectQuestionOptionsTypes = 'multiSelectQuestionOptionsTypes',
  ActiveReviewRowStatusTypes = 'activeReviewRowStatusTypes',
  ReviewStatusTypes = 'reviewStatusTypes',
  SurveyQuestionTypes = 'surveyQuestionTypes',
  SelectedView = 'selectedView'
}


export interface IMetaOptions {
  options: IMenuOption[];
  constants: IMetaOptionsResponse['constants'];
}


export type IMetadata = {
  [key in MetaDataKeys]: IMetaOptions;
}


export type IMetaDataResponseData = {
  [key in MetaDataKeys]: IMetaOptionsResponse;
}


export interface IQuestionTypeConstants {
  TITLE: string;
  SUBTITLE: string;
  SHORT_TEXT: string;
  LONG_TEXT: string;
  MULTI_SELECT_QUESTION: string;
  COMPETENCY_QUESTION: string;
  OBJECTIVE_QUESTION: string;
}

export interface IMultiSelectQuestionOptionsTypeConstants {
  NPS: string;
  YES_NO: string;
  SCORE_1_3: string;
  SCORE_1_5: string;
  SCORE_1_10: string;
  TRUE_FALSE: string;
  CUSTOM_ITEMS: string;
  LIKELY_UNLIKELY: string;
  AGREE_DISAGREE_2: string;
  AGREE_DISAGREE_3: string;
  AGREE_DISAGREE_4: string;
  AGREE_DISAGREE_5: string;
  CORRECT_INCORRECT: string;
}

export interface IActiveReviewRowStatusTypeConstants {
  CANCELED: string;
  CANCEL_REQUEST: string;
  COMPLETED: string;
  PENDING: string;
  STARTED: string;
  AWAITING_NOMINATION: string;
  NOMINATED: string;
  NOMINATION_MANAGER_APPROVED:string;
  NOMINATION_MANAGER_DECLINED:string;
  NOMINATION_REVIEWER_DECLINED:string;
  REVIEW_PROGRESS:string;
  REVIEWER_DECLINED:string;
}

export interface IReviewStatusTypeConstants {
  CLOSED: string;
  DRAFT: string;
  LIVE: string;
  SCHEDULED: string;
}

export interface ISurveyQuestionTypeConstants {
  GROUP?: string;
  CHOICE: string;
  TEXT_INPUT: string;
  STATIC_TEXT: string;
}

export interface ISchedulerFrequencyConstants {
  DOES_NOT_REPEAT: string;
  EVERY_DAY: string;
  MONDAY_TO_FRIDAY: string;
  EVERY_WEEK: string;
  EVERY_2_WEEKS: string;
  EVERY_3_WEEKS: string;
  MONTHLY_NTH_DAY: string;
  TWO_MONTHLY_NTH_DAY: string;
  MONTHLY_NTH_WEEKDAY: string;
  TWO_MONTHLY_NTH_WEEKDAY: string;
  MONTHLY_LAST_WEEKDAY: string;
  TWO_MONTHLY_LAST_WEEKDAY: string;
}

export interface ITemplateVisibilityConstants {
  EVERYONE: string;
  ADMINS: string;
  SUPER_ADMIN: string;
}

export interface ISurveyStateConstants {
  OPEN: 'open';
  CLOSED: 'closed';
}

export interface ISurveyNotificationSettingConstants {
  DM_ONLY: 'dm-only';
  DM_AND_CHANNELS: 'dm-and-channels';
  CHANNELS_ONLY: 'channels-only';
}

export interface IMetaOptionsResponse {
  options: IMenuOptionWithText[];
  constants: IQuestionTypeConstants |
  IMultiSelectQuestionOptionsTypeConstants |
  IActiveReviewRowStatusTypeConstants |
  IReviewStatusTypeConstants |
  ISurveyQuestionTypeConstants |
  ISchedulerFrequencyConstants |
  ITemplateVisibilityConstants |
  ISurveyStateConstants |
  ISurveyNotificationSettingConstants;
}