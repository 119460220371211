import { AxiosObservable } from 'axios-observable';
import {
  getMsTeamsChannelsAsMenuOptions,
  getSlackWorkspaceChannelsAsMenuOption,
  getSlackWorkspacesAsMenuOptions,
} from 'data';
import { useApp } from 'hooks';
import { IChannelMenuOption, IResponse, ISimpleMenuOption, SignInWith } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { finalize } from 'rxjs';

interface IOrganizationMenuOptionsResponse {
  isLoading: boolean;
  slackChannelOptions: ISimpleMenuOption[];
}

export const useOrganizationMenuOptions$ =
  (getAllChannels: boolean = false): AxiosObservable<IResponse<IChannelMenuOption[]>> => {

    const app = useApp();

    const [channelOrWorkspaceMenuOptions$, setChannelOrWorkspaceMenuOptions$] =
      useState<AxiosObservable<IResponse<IChannelMenuOption[]>>>(
        app === SignInWith.Slack ?
          getSlackWorkspacesAsMenuOptions() :
          getMsTeamsChannelsAsMenuOptions(getAllChannels)
      );


    useEffect(
      () => {
        setChannelOrWorkspaceMenuOptions$(
          app === SignInWith.Slack ?
            getSlackWorkspacesAsMenuOptions() :
            getMsTeamsChannelsAsMenuOptions(getAllChannels)
        );
      },
      [app, getAllChannels]
    );


    return channelOrWorkspaceMenuOptions$;

  };


export const useSlackChannelMenuOptions = (
  workspaceId: string
): IOrganizationMenuOptionsResponse => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [slackChannelOptions, setSlackChannelOptions] = React.useState<ISimpleMenuOption[]>([]);

  const app = useApp();


  useEffect(
    () => {

      if (app !== SignInWith.Slack) {
        setIsLoading(false);
        setSlackChannelOptions([]);
        return;
      }

      setIsLoading(true);
      setSlackChannelOptions([]);

      const subscription = getSlackWorkspaceChannelsAsMenuOption(workspaceId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setSlackChannelOptions(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [app, workspaceId]
  );

  return { isLoading, slackChannelOptions };

};


export const useOrganizationMenuOptions =
  (): { options: IChannelMenuOption[], isLoading: boolean } => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [organizationOptions, setOrganizationOptions] = useState<IChannelMenuOption[]>([]);

    const organizationMenuOptions$ = useOrganizationMenuOptions$();


    useEffect(
      () => {

        setIsLoading(true);
        setOrganizationOptions([]);

        const subscription = organizationMenuOptions$
          .pipe(
            finalize(() => setIsLoading(false))
          )
          .subscribe(
            (response) => {
              setOrganizationOptions(response.data.data);
            }
          );

        return () => subscription.unsubscribe();
      },
      [organizationMenuOptions$]
    );


    return {
      isLoading,
      options: organizationOptions
    };
  };
