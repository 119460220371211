import { Card, OverlayTrigger, Table, Tooltip } from '@themesberg/react-bootstrap';
import { IReviewAnalyticsResponse } from 'interfaces';
import React from 'react'
import { getAnalyticsRow } from 'modules/reviews/utils/utils';


const TableBody: React.FunctionComponent<{ analytics: IReviewAnalyticsResponse }> = ({ analytics }) => {

  const { rows } = analytics;

  return (
    <tbody style={{ whiteSpace: "break-spaces" }}>
      {
        rows.map((row) => {
          return (
            <tr>
              {
                row.map((cells) => getAnalyticsRow(cells))
              }
            </tr>
          )
        })
      }
    </tbody >
  )
}

const ReviewAnalyticsOverallTable: React.FC<{ analytics: IReviewAnalyticsResponse }> = ({ analytics }) => {
  return (
    <Card className='fill-height'>
      <Card.Body className='overflow-x-auto overflow-y-auto flex-grow-1 p-0 pe-2 pb-2'>
        <Table
          className='mx-auto my-0 position-relative'
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '6px',
          }}
        >
          {/* <TableHeader analytics={analytics} /> */}
          <TableBody analytics={analytics} />
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ReviewAnalyticsOverallTable;