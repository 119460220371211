import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { ConfirmationModal, FormField } from 'components';
import { FormContext } from 'contexts';
import { useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  IModalProps,
  IReminderFormValues,
  SendReminderAsTypes,
} from 'interfaces';
import * as React from 'react';
import { SEND_REMINDER_AS_OPTIONS } from 'teamble-constants';
import { REMINDER_VALIDATION_SCHEMA } from 'validation-schemas';

interface IReminderNoteModalProps extends IModalProps {
  onSubmit: (values: IReminderFormValues) => void;
  audienceLen: number
}

interface IReminderNoteFormProps {
  onClose: IModalProps['onClose'];
  onSubmit: IReminderNoteModalProps['onSubmit'];
  audienceLen: number
}


const ReminderNoteForm: React.FunctionComponent<IReminderNoteFormProps> = (
  { onClose, onSubmit, audienceLen }
) => {

  const [showDeclineConfirmationModal, setShowDeclineConfirmationModal] = React.useState<boolean>(false);

  const reminderNoteForm = useFormik<IReminderFormValues>({
    initialValues: {
      note: '',
      sendAs: [SendReminderAsTypes.AppMessage]
    },
    validationSchema: REMINDER_VALIDATION_SCHEMA,
    onSubmit: onSubmit
  });


  return (
    <>
      {
        !showDeclineConfirmationModal && <Form>
          <Modal.Body>
            <FormContext.Provider value={reminderNoteForm}>
              <FormField
                size='lg'
                label='Note'
                placeholder='Add a note...'
                controlId='reminderNoteFormField'
                type={FormFieldTypeConstants.Textarea}
                controlName='note' />

              <FormField
                size='lg'
                label='Send As'
                type={FormFieldTypeConstants.CheckboxGroup}
                controlName='sendAs'
                options={SEND_REMINDER_AS_OPTIONS} />
            </FormContext.Provider>
          </Modal.Body>
          <Modal.Footer className="justify-content-end px-4">
            <Button type='button' variant="outline-primary" onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => {
                setShowDeclineConfirmationModal(true);
              }}
              variant="primary">
              Send Reminder
            </Button>
          </Modal.Footer>
        </Form>
      }

      <ConfirmationModal
        title='Send Reminder ?'
        submittingText='Submitting...'
        positiveText='Send'
        isSubmitting={reminderNoteForm.isSubmitting}
        show={showDeclineConfirmationModal}
        closeOnUserResponse={false}
        onPositiveResponse={() => reminderNoteForm.handleSubmit()}
        onNegativeResponse={() => setShowDeclineConfirmationModal(false)}
        onCloseClick={() => setShowDeclineConfirmationModal(false)}
      >
        {
          audienceLen > 1
            ? <p>You are about to send a reminder to {audienceLen} users,  Please confirm.</p>
            : <p>Are you sure you want to send reminder?</p>
        }

      </ConfirmationModal>
    </>
  );

};


const ReminderNoteModal: React.FunctionComponent<IReminderNoteModalProps> = ({
  show, onClose, onSubmit, audienceLen
}) => {
  return (

    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={onClose}
    >

      <Modal.Header closeButton >
        <Modal.Title> Add Note </Modal.Title>
      </Modal.Header>

      <ReminderNoteForm onClose={onClose} onSubmit={onSubmit} audienceLen={audienceLen} />

    </Modal>

  );
};

export default ReminderNoteModal;
