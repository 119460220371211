import { Button, Card, Form } from '@themesberg/react-bootstrap';
import TeambleLogo from 'assets/img/brand/logo.png';
import { CustomSelect, QuestionBuilder } from 'components';
import { MetaDataContext } from 'contexts';
import { getCompetenciesListAsMenuOptions } from 'data';
import { useApp } from 'hooks';
import { IFeedbackTypes, IFormQuestionValue, IMenuOption, IMetadata, SignInWith } from 'interfaces';
import React, { useContext, useEffect } from 'react';
import { mapQuestionsToFormValues } from 'utils';
import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import { useFeedbackForSenderReceiverPreview } from '../hooks/useFeedbackForSenderReceiverPreview';
import { useFeedbackType } from '../hooks/useFeedbackType';
import { useCompanyConfig } from '@common/modules/companyConfig';

interface IFeedbackLoopGiveFeedbackModalPreviewWidgetProps {
}

const FeedbackLoopGiveFeedbackModalPreviewWidget: React.FC<IFeedbackLoopGiveFeedbackModalPreviewWidgetProps> = () => {

  const [competencies, setCompetencies] = React.useState<IMenuOption[]>([]);
  const [feedbackLoopQuestions, setFeedbackLoopQuestions] = React.useState<IFormQuestionValue[]>([]);

  const app = useApp();

  const { feedbackLoop, feedbackForAudiencePairs } = useFeedbackLoopConfig();
  const companyConfig = useCompanyConfig();

  const feedbackType = useFeedbackType(feedbackLoop.feedbackType);

  const [_, feedbackForReceivers] = useFeedbackForSenderReceiverPreview(
    feedbackLoop.feedbackFor,
    feedbackForAudiencePairs,
  );

  const metadata = useContext(MetaDataContext) as IMetadata;


  useEffect(
    () => {

      setCompetencies([]);

      getCompetenciesListAsMenuOptions().subscribe(
        (response) => {
          setCompetencies(response.data.data);
        }
      );

    },
    []
  );


  useEffect(
    () => {

      const mappedQuestions = mapQuestionsToFormValues(
        feedbackLoop.blocks || [],
        metadata
      );

      setFeedbackLoopQuestions(mappedQuestions);
    },
    [feedbackLoop.blocks, metadata]
  );


  return (
    <Card className='w-100 font-family-lato mt-5'>
      <Card.Header className='d-flex align-items-center'>
        <img
          height='40'
          width='40'
          src={TeambleLogo}
          className='rounded-3 me-3'
          alt="Teamble Blue Gradient logo" />
        <h4 className='m-0'>
          <span role='img' aria-label='Give Feedback Bulb'> 💡 </span> Give Feedback
        </h4>
      </Card.Header>

      <Card.Body>
        <p>
          <strong> Feedback Type: </strong>
          <span> {feedbackType || <i>Not set</i>} </span>
        </p>

        {
          feedbackLoop.feedbackType !== IFeedbackTypes.Company
            ?
            <p>
              <strong> Feedback For: </strong>
              <span> {feedbackForReceivers[0] || <i>Not set</i>} </span>
            </p>
            :
            <p>
              <em>
                <strong> ℹ️ Company Feedback is anonymous </strong>
                <span> and only received by company admins </span>
              </em>
            </p>
        }

        {
          companyConfig.useObjectivesInWebAdmin
            ? null
            : (
              feedbackLoop.feedbackType !== IFeedbackTypes.Company &&
              <div className='d-flex justify-content-between align-items-start mt-4'>
                <h6> 🎯 Development Goals </h6>
                <Button variant='outline-primary' size='sm'>
                  View Profile
                </Button>
              </div>
            )
        }

        {
          feedbackLoop.hideCompetencySelector
            ? null
            : (
              <Form.Group className='w-100 mt-2'>
                <CustomSelect
                  isMulti
                  placeholder='What is this feedback about?'
                  value={undefined}
                  options={competencies}
                  onChange={() => { }}
                />
              </Form.Group>
            )
        }

        <div className='mt-2'>
          {
            feedbackLoopQuestions.map(
              (question, index) => {
                return (
                  <QuestionBuilder.QuestionPreview
                    key={index}
                    question={question} />
                );
              }
            )
          }
        </div>

      </Card.Body>

      <Card.Footer className='d-flex justify-content-end'>
        <Button
          size={app === SignInWith.Slack ? 'lg' : 'sm'}
          variant={app === SignInWith.Slack ? 'outline-primary' : 'outline-info'}
        >
          Cancel
        </Button>
        <Button
          className='ms-2'
          size={app === SignInWith.Slack ? 'lg' : 'sm'}
          variant={app === SignInWith.Slack ? 'success' : 'info'}
        >
          Send Feedback
        </Button>
      </Card.Footer>

    </Card>
  );
};

export default FeedbackLoopGiveFeedbackModalPreviewWidget;