import { Button, Card } from '@themesberg/react-bootstrap';
import { FormikProps } from 'formik';
import { ISurveyBuilderFormValues } from 'interfaces';
import * as React from 'react';
import { ISurveyPreviewQuestionIndexState, SurveyPreviewQuestionIndexContext } from '../contexts/SurveyPreviewQuestionIndex';
import SurveyPreviewModal from '../modals/SurveyPreviewModal';
import SurveyQuestionPreview from './SurveyQuestionPreview';

interface ISurveyQuestionPreviewWidgetProps {
  surveyForm: FormikProps<ISurveyBuilderFormValues>;
}

const SurveyQuestionPreviewWidget: React.FunctionComponent<ISurveyQuestionPreviewWidgetProps> = (
  { surveyForm }
) => {

  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [blockToPreviewIndex, setBlockToPreviewIndex] = React.useState<number | null>(null);
  const [groupBlockToShowIndex, setGroupBlockToShowIndex] = React.useState<number | null>(null);

  const previewQuestionIndexContext =
    React.useContext(SurveyPreviewQuestionIndexContext) as ISurveyPreviewQuestionIndexState;


  React.useEffect(
    () => {
      if (!previewQuestionIndexContext.index) {
        setBlockToPreviewIndex(null);
        setGroupBlockToShowIndex(null);
        return;
      }

      const index = previewQuestionIndexContext.index;
      const [groupText, blockIndex, groupQuestionIndex] = index.split('_');
      if (groupText === 'group') {
        setGroupBlockToShowIndex(+groupQuestionIndex);
        setBlockToPreviewIndex(+blockIndex);
        return;
      }

      if (groupText === 'nonGroup') {
        setGroupBlockToShowIndex(null);
        setBlockToPreviewIndex(+blockIndex);
      }

    },
    [previewQuestionIndexContext.index]
  )


  return (
    <>
      <Card className='flex-grow-1 overflow-hidden'>

        <Card.Header className='flex-shrink-0'>
          <Card.Title className='m-0'> Preview </Card.Title>
        </Card.Header>

        <Card.Body className='overflow-y-auto d-flex justify-content-center align-items-center'>
          <div className='w-75'>
            {
              blockToPreviewIndex !== null &&
              <SurveyQuestionPreview
                question={surveyForm.values.blocks[blockToPreviewIndex]}
                groupQuestion={
                  groupBlockToShowIndex !== null
                    ?
                    surveyForm.values.blocks[blockToPreviewIndex]?.groupValue?.questions[groupBlockToShowIndex]
                    :
                    undefined
                } />
            }
          </div>
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end flex-shrink-0'>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => setShowPreview(true)}
          >
            Full Preview
          </Button>
        </Card.Footer>
      </Card>
      <SurveyPreviewModal
        survey={surveyForm.values}
        show={showPreview}
        onClose={() => setShowPreview(false)} />
    </>
  );
};

export default SurveyQuestionPreviewWidget;
