import { ChartConfiguration, ChartDataset, ChartOptions } from 'chart.js';

import { FEEDBACK_CHART_FONT_WHITE_COLORS } from './shared.constant';

export const CHART_FONT_CONFIG = {
  family: 'circular std, sans-serif',
  size: 16,
  weight: 'bold'
};


export const COMPETENCY_PERFORMANCE_CHART_OPTIONS: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'timeseries',
      ticks: {
        display: true,
        maxRotation: 0,
        autoSkipPadding: 30,
        source: 'data',
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
    }
  },
  elements: {
    point: {
      radius: 4,
      hoverRadius: 6,
    },
  },
  plugins: {
    legend: {
      display: false,
    }
  }
};

export const FEEDBACK_TREEMAP_CHART_DATA_CONFIG: Omit<ChartDataset<"treemap">, 'data'> = {
  groups: ['category', 'Details'],
  key: 'Details',
  borderWidth: 0,
  spacing: 2,
  backgroundColor: (ctx) => (ctx.raw as any)._data?.children[0].backgroundColor,
  labels: {
    display: true,
    align: 'left',
    position: 'bottom',
    font: {
      size: 20,
    },
    hoverFont: {
      size: 25,
      weight: 'bold'
    },
    formatter: (ctx) => ctx.raw.g + '%',
    color: (ctx: any) => {

      const backgroundColor = (ctx.raw as any)._data?.children[0].backgroundColor;
      return FEEDBACK_CHART_FONT_WHITE_COLORS.includes(backgroundColor) ? 'white' : 'black';
    },
  },
  captions: {
    align: 'left',
    display: true,
    font: {
      size: 15,
    },
    hoverFont: {
      size: 15,
      weight: 'bold'
    },

    color: (ctx: any) => {

      const backgroundColor = (ctx.raw as any)._data?.children[0].backgroundColor;
      return FEEDBACK_CHART_FONT_WHITE_COLORS.includes(backgroundColor) ? 'white' : 'black';
    },
    formatter: (ctx) => ctx.raw.g,
  }
};

export const FEEDBACK_TREEMAP_CHART_OPTIONS: ChartConfiguration<"treemap">['options'] = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      position: 'average',
      callbacks: {
        label: (ctx) => (ctx.dataset.tree?.[ctx.dataIndex] as any)?.category,
      }
    },
    title: {
      display: false,
    },
    legend: {
      display: false
    },
  }
}

export const SURVEY_QUESTION_TIMELINE_CHART_OPTIONS: ApexCharts.ApexOptions = {
  chart: {
    fontFamily: 'circular std',
    foreColor: '#4a5073',
    selection: {
      enabled: false
    },
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  xaxis: {
    type: 'datetime'
  },
  dataLabels: {
    enabled: true,
    formatter(_, { w, seriesIndex, dataPointIndex }) {
      return w.config.series[seriesIndex].data[dataPointIndex].value
    },
    background: {
      borderWidth: 0,
      padding: 10,
      opacity: 1
    }
  },
  fill: {
    opacity: 1
  },
  grid: {
    borderColor: '#eaedf2',
  },
  legend: {
    show: false
  },
  colors: ['#1B998B'],
  tooltip: {
    x: {
      format: 'd MMM, yyyy H:m:s'
    },
  },
  yaxis: {
    min: 0
  }
};