import { FormErrors, ISimpleMenuOption, ISurveyAudienceBuilderFormValues } from 'interfaces';
import * as Yup from 'yup';
import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';


export const SURVEY_AUDIENCE_BUILDER_VALIDATION_SCHEMA: Yup.SchemaOf<ISurveyAudienceBuilderFormValues> =
  Yup.object().shape(
    {
      isAnonymous: Yup.boolean().defined(),
      minSubmissionCount: Yup.number(),
      liveResults: Yup.string().defined(),
      audienceType: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.nullable(),
      selectedAudienceUsers: Yup.array()
        .of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
        .when(
          'audienceType',
          {
            is: (value: ISimpleMenuOption) => value?.value === 'users',
            then: Yup.array().required(FormErrors.Required).min(1, FormErrors.Required),
          }
        ),
      selectedSlackChannels: Yup.array()
        .of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
        .when(
          'audienceType',
          {
            is: (value: ISimpleMenuOption) => value?.value === 'channels',
            then: Yup.array().required(FormErrors.Required).min(1, FormErrors.Required),
            otherwise: Yup.array()
          }
        ),
      selectedGroups: Yup.array()
        .of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
        .when(
          'audienceType',
          {
            is: (value: ISimpleMenuOption) => value?.value === 'groups',
            then: Yup.array().required(FormErrors.Required).min(1, FormErrors.Required),
            otherwise: Yup.array()
          }
        ),
      inspectorType: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.nullable(),
      selectedInspectorsUsers: Yup.array()
        .of(SIMPLE_MENU_OPTION_VALIDATION_SCHEMA)
        .when(
          'inspectorType',
          {
            is: (value: ISimpleMenuOption) => value?.value === 'users',
            then: Yup.array().required(FormErrors.Required).min(1, FormErrors.Required),
            otherwise: Yup.array()
          }
        ),
    }
  );
