import {
  IChannelMenuOption,
  IMember,
  IMemberOption,
  IMenuOption,
  IMsTeamsChannelUser,
  ISimpleMenuOption,
  ISlackWorkspaceUser,
  IReviewNominateAudience,
  IFixedMenuOption,
  IAnswer,
  ITableRowCheckState,
  IEntityBase
} from 'interfaces';


export interface IReviewTemplate extends IReviewTemplateData, IReviewTemplateFormValues { }

export interface IReviewTemplateResponse extends IReviewTemplateData, IReviewTemplateResponseData { }

export interface IReviewTemplateFormValues {
  title: string;
  blocks: IReviewTemplateQuestion[];
}

export interface IReviewTemplateValidationSchema {
  title: string;
  blocks: IReviewTemplateQuestionValidationSchema[];
}

export interface IReviewTemplateResponseData {
  title: string;
  blocks: IReviewTemplateResponseDataBlock[];
}

export interface IReviewTemplateRequestData {
  title: string;
  blocks: IReviewTemplateRequestDataBlock[];
}

export interface IReviewTemplateData {
  id: string;
  createdBy: IMember;
  createdAt: number;
  logoUrl: string;
  description: string;
}

export interface IReviewTemplateRequestDataBlock
  extends
  IReviewTemplateRequestDataTitleBlock,
  IReviewTemplateTextQuestionValue,
  IReviewTemplateRequestDataMultiSelectBlock,
  Partial<IReviewTemplateObjectiveBlock>,
  Partial<IReviewTemplateCompetencyBlock> {
  type: string;
  id: string;
  typeTitle: string;
}

export interface IReviewTemplateResponseDataBlock extends IReviewTemplateRequestDataBlock {
  id: string;
}

export interface IReviewTemplateRequestDataTitleBlock {
  text?: string;
}

export interface IReviewTemplateRequestDataMultiSelectBlock {
  family?: string;
  comment?: string;
  commentBox?: boolean;
  required?: boolean;
  dropdown?: boolean;
  numericValue?: boolean;
  choices?: string[] | IMenuOption[];
  choicesValues?: {
    [key: string]: number;
  },
}

export interface IReviewTemplateCompetencyBlock {
  showBehaviours: boolean;
  showDescription: boolean;
  isBehavior: boolean;
  isNotApplicableOption: boolean;
  selectedView: ISimpleMenuOption;
}

export interface IReviewTemplateObjectiveBlock {
  showKPIs: boolean;
  showDescription: boolean;
  isKPI: boolean;
  isNotApplicableOption: boolean;
  selectedView: ISimpleMenuOption;
  comments: IReviewCommentsWithValue[]
}

export interface IReviewTemplateQuestion extends Partial<IReviewTemplateQuestionValidationSchema> {
  id: string;
  type: string;
  typeTitle: string;
  tempId?: string;
}

export interface IReviewTemplateCompetencyQuestionValue extends
  IReviewTemplateCompetencyBlock, IReviewTemplateMultiSelectQuestionValue {
}

export interface IReviewTemplateObjectiveQuestionValue extends
  IReviewTemplateObjectiveBlock, IReviewTemplateMultiSelectQuestionValue {
}

export interface IReviewTemplateQuestionValidationSchema {
  titleValue: { text: string };
  textValue: IReviewTemplateTextQuestionValue;
  multiSelectValue: IReviewTemplateMultiSelectQuestionValue;
  competencyValue: IReviewTemplateCompetencyQuestionValue;
  objectiveValue: IReviewTemplateObjectiveQuestionValue
}

export interface IReviewTemplateTextQuestionValue {
  label?: string;
  placeholder?: string;
  required?: boolean;
}

export interface IReviewChoiceWithValue {
  choice: string;
  value: number;
}

export interface IReviewCommentsWithValue {
  id: string;
  title: string;
  value: string;
}

export interface IReviewTemplateMultiSelectQuestionValue extends Omit<IReviewTemplateTextQuestionValue, 'placeholder'> {
  selectedOption: IMenuOption;
  commentBoxGuide?: string;
  commentBox: boolean;
  numericValue: boolean;
  dropdown: boolean;
  choices: IReviewChoiceWithValue[] | string;
}

export interface IActiveReviewResponse extends IActiveReviewMeta {
  reviewerRevieweeInfos: IActiveReviewAudienceResponse[];
  nominatorAudience: ISlackWorkspaceUser[]
}

export interface IActiveReview extends IActiveReviewMeta {
  reviewerRevieweeInfos: IActiveReviewAudience[];
  nominatorAudience: IFileUploadNominateAudienceResponseData
}

interface IActiveReviewMeta {
  id: string;
  title: string;
  state: string;
  startDate: number;
  closeSchedule?: {
    timestamp: number;
  }
  progress: number;
  nominationProgress: number;
  workspaceId: string;
  channelId: string;
  launchedAt: number;
  closedAt: number;
  anonymity: string;
  createdAt: number;
  updatedAt: number;
  nominationInProcess: boolean;
  perfReviewTemplateId: string;
  perfReviewTemplateName: string;
  resultSharingConfig?: {
    isAutomatic: boolean,
    isManual: boolean,
    isAutomaticOnSubmitReview: boolean,
    shareResultWith: string[],
    // isAutomaticToManager: boolean,
    // isAutomaticToReviewee: boolean,
    isManualToReviewer: boolean,
    isAutomaticOnReviewClose: boolean;
  };
  shareInfo?: {
    automatic: boolean,
    trigger: string[],
    share: string[]
  }
  isAutomaticCloseOnEndDate: boolean;
  createdByUser: string;
  multiCampaign?: ISimpleMenuOption[]
}

export interface IActiveReviewAudience {
  id: string;
  state?: string;
  reviewee: IMemberOption;
  reviewer: IMemberOption;
  fromNominations?: boolean;
  data?: {
    comment: string;
    author: string;
  };
}

export interface IActiveReviewAudienceResponse {
  id: string;
  state: string;
  reviewee: ISlackWorkspaceUser;
  reviewer: ISlackWorkspaceUser;
}

export interface IActiveReviewFormValues {
  multiCampaign?: ISimpleMenuOption[];
  title: string;
  channel: IChannelMenuOption | null;
  reviewTemplate: ISimpleMenuOption | null;
  startDate: Date | null;
  endDate: Date | null;
  anonymous: boolean;
  reviewerRevieweeInfos: IReviewerRevieweeInfos[] | null;
  shareWithType: string;
  canShareResult: IFixedMenuOption[];
  shareResultWith: ISimpleMenuOption[];
  shareResultAfter: ISimpleMenuOption | null;
  nominatorAudience: IFileUploadNominateAudienceResponseData | null;
  isAutomaticCloseOnEndDate: boolean
};

export interface IReviewerRevieweeInfos {
  reviewee: IMemberOption;
  reviewer: IMemberOption;
  isChecked?: boolean
}

export interface IReviewAudienceFilters {
  departmentFilter: string[];
}

export interface INominateRevieweeInfo extends IMemberOption {
  isChecked?: boolean;
}

export interface IReviewerRevieweeInfosResponse {
  reviewee: ISlackWorkspaceUser | IMsTeamsChannelUser;
  reviewer: ISlackWorkspaceUser | IMsTeamsChannelUser;
}

export interface ILaunchReviewRequestData {
  title: string;
  type: string;
  perfReviewTemplateId: string;
  startDate: number | null;
  endDate: number | null;
  companyId: string;
  reviewerRevieweeInfos?: IReviewerRevieweeInfosRequestData[];
  workspaceId?: string;
  channelId?: string;
  resultSharingConfig?: {
    isAutomatic: boolean,
    isManual: boolean,
    shareResultWith: string[]
    isAutomaticOnSubmitReview: boolean,
    // isAutomaticToManager: boolean,
    // isAutomaticToReviewee: boolean,
    isManualToReviewer: boolean;
    isAutomaticOnReviewClose: boolean;
  };
  isAutomaticCloseOnEndDate: boolean
  multiCampaign?: string[]
}

export interface IReviewerRevieweeInfosRequestData {
  revieweeUserId: string;
  reviewerUserId: string;
}

export interface IReviewResult {
  title: string;
  revieweeName: string;
  reviewerName: string;
  prcState: string;
  state: string;
  answers: IReviewResultBlock[];
}

export interface IReviewResultBlock extends IReviewTemplateResponseDataBlock {
  value: string;
  commentBlockId: string;
  commentValue: string;
}

export interface IFileUploadNominateAudienceResponseData {
  verifiedEmailPairs: IReviewNominateAudience[];
  unVerifiedEmailPairs: IReviewNominateAudience[];
}

export interface IFileUploadAudienceResponseData {
  verifiedEmailPairs: IReviewerRevieweeInfosResponse[];
  unVerifiedEmailPairs: IReviewerRevieweeInfosResponse[];
}

export interface IFileUploadAudience {
  verifiedEmailPairs: IReviewerRevieweeInfos[];
  unVerifiedEmailPairs: IReviewerRevieweeInfos[];
}

export interface IReminderFormValues {
  note: string;
  sendAs: string[];
}

export interface IReminderRequestData {
  note: string;
  sendAsEmail: boolean;
  sendAsAppMessage: boolean;
}

export interface IReasonModalFormValues {
  reason: string;
}

export interface IReviewTemplateDeleteResponse {
  templateInUse: boolean;
}

export enum ReviewStatus {
  Draft = 'Draft',
  Scheduled = 'Scheduled',
  Live = 'Live',
  Closed = 'Closed'
}

export enum ShareReviewResultTypes {
  MANUAL = 'Manual',
  AUTOMATED = 'Automated',
}

export enum ShareReviewResultTypeValues {
  'Manual' = 'MANUAL',
  'Automated' = 'AUTOMATED',
}

/* Review Todos types starts */

export enum ReviewTodoState {
  Completed = 'completed',
  Pending = 'pending'
}

export enum ReviewTodoTypes {
  Default = 'default',
  AwaitingNominations = 'awating_nominations',
  ManagerApproval = 'manager_approval',
  ReviewerApproval = 'reviewer_approval',
}

export interface IReviewTodoRevieweeItem {
  id: string;
  campingId: string;
  state: ReviewTodoState;
  type: ReviewTodoTypes;
  title: string;
  revieweeId: string;
  revieweeName: string;
  revieweeUserId: string;
  reviewerId: string;
  reviewerName: string;
  reviewerUserId: string;
  performanceReviewCampaignId: string;
  reviewee: {
    id: string;
    name: string;
  }
}

export interface IReviewTodo {
  title: string;
  createdBy: string;
  performanceReviewCampaignId: string;
  reviews: IReviewTodoRevieweeItem[];
}

export interface IReviewTodosResponse {
  total: number;
  completed: number;
  percentageComplete: number;
  reviewList: IReviewTodo[];
}

export interface IRevieweeFormValues {
  answers: IAnswer[];
}

export interface IReviewTodoAnswerResponse {
  id: string;
  workspaceId: string;
  revieweeName: string;
  reviewerName: string;
  state: ReviewTodoState;
  title: string;
  shareWithReviewer: boolean;
  answers: IAnswer[];
  revieweeChannelInfo: {
    slack: {
      userId: string;
    }
  };
  revieweeId: string;
  multiCampaign?: boolean;
  closeSchedule?: { timestamp: number };
}

export interface IRevieweeAnswersRequestData {
  saveOnly: boolean;
  perfReviewId: string;
  blocks: IAnswer[];
}

/* Review Todos types ends */
export interface IReviewItemToShare {
  id: string;
  performanceReviewCampaignId: string;
  revieweeId: string;
  revieweeName: string;
  reviewerId: string;
  reviewerName: string;
  state: string;
  title: string;
  name: string;
  type: string;
  children: IReviewItemToShare[]
}

export interface ISharedPerformanceReview {
  myTeam: Array<IReviewItemToShare>;
  reviewsGiven: Array<IReviewItemToShare>;
}

export enum ReviewResultCanShareBy {
  Admin = 'admin',
  Reviewer = 'reviewer'
}

export enum ReviewResultShareWith {
  Managers = 'managers',
  Reviewees = 'reviewees'
}

export enum ReviewResultShareAfter {
  Closed = 'closed',
  Submitted = 'submitted'
}

export enum ReviewActions {
  Download = 'download',
  CancelReview = 'cancelReview',
  DeleteReview = 'deleteReview',
  DeleteSchedule = 'deleteSchedule',
  ReopenReview = 'reopenReview'
}

export enum MyReviewTabs {
  ReviewsReceived = 'reviewsReceived',
  MyTeam = 'myTeam',
  ReviewsGiven = 'reviewsGiven',
  SharedWithMe = 'sharedWithMe'
}

export interface IActiveReviewActionColumnProps {
  loadingReviewId: string;
  onDownloadAction: (review: IActiveReview) => void,
  onDeleteReviewAction: (review: IActiveReview, initialCheck: boolean) => void,
  onCancelReviewAction: (review: IActiveReview) => void,
  onReopenReviewAction: (review: IActiveReview) => void,
  onDeleteReviewScheduleAction: (review: IActiveReview, initialCheck: boolean) => void
}

export interface IActiveReviewAudienceActionColumnProps {
  onShowResultClick: React.Dispatch<React.SetStateAction<string>>;
  onRemoveAudiencePair: (audience: IActiveReviewAudience) => void;
  onAudiencePairReopen: (audience: IActiveReviewAudience) => void;
  onReviewerChange: (audience: IActiveReviewAudience) => void;
  downloadReport: (perfReviewId: string) => void;
}

export interface IReviewAudienceActionColumnProps extends ITableRowCheckState {
  onPairRemove: (index: number) => void;
}

export type ActiveReviewUpdateStatus = 'finalizing' | 'sendingReminders';
export type ActiveReviewModalToShow = 'finalizeReview' | 'reminderNote';

export interface IReviewScore {
  id: string;
  label: string;
  competencyName: string;
  behaviorName: string;
  campaigns: {
    title: string;
    createdAt: number;
    value: number | string;
  }[];
}

export interface IScoreAnalyticsReview {
  id: string;
  title: string;
  createdAt: string;
}

export enum ReviewSummaryTabTypes {
  Goals = 'goals',
  Feedback = 'feedback',
  Reviews = 'reviews',
  Objectives = 'objectives'
}

export interface IRevieweeReview extends IEntityBase {
  reviewerName: string;
}

interface IReviewStatus {
  "Nominated": number
  "Awaiting Nomination": number
  "Manager Approved": number
  "Manager Rejected": number
  "Nomination Nominee Declined": number,
  "Reviewer Rejected": number
  "Review Inprogress": number
  "Review Completed": number
}

export interface ICampaignCompletionStatusData {
  id: string;
  name: string;
  reviewCount: number,
  nominationCount: number,
  reviewCounts: IReviewStatus,
  nominationCounts: IReviewStatus
}

export interface ICampaignViewingData {
  id: string;
  name: string;
  count: number,
  counts: IReviewStatus,
  choices: {
    id: number;
    title: string;
    percentCount: number;
    color: string;
    backgroundColor: any;
  }[]
}

export interface ICampaignCompletionStatusResponse {
  isNomination: boolean;
  data: ICampaignCompletionStatusData[];
}

export interface ICampaignCompletionStatusList {
  id: string
  title: string
  createdAt: number
}

export interface IReviewAnalyticsRow {
  id: string;
  title: string;
  category: string;
  cells: {
    [key: string]: {
      sum: number;
      count: number;
      skipped: number;
      value: number | null;
      color: string;
      backgroundColor: string;
    } | undefined
  };
};

export interface IReviewAnalyticsColumn {
  id: string, title: string
}

export interface IReviewAnalyticsViewCell {
  type: string;
  text?: string;
  value?: number;
  span?: number;
  rowSpan?: number;
  colSpan?: number;
}
export interface IReviewAnalyticsResponse {
  rows: IReviewAnalyticsViewCell[][];
}

export interface IReviewAnalyticsCell {
  sum: number;
  count: number;
  skipped: number;
  value: number | null;
  color: string;
  backgroundColor: string;
};

export interface IReviewAnalyticsComputedCell {
  cells: IReviewAnalyticsCell[]
  id: string;
  title: string;
  category: string;
};

export interface IReviewAnalyticsViewFilterFormValues {
  dataSelector: ISimpleMenuOption[];
  rows: ISimpleMenuOption | null;
  rowsGroupBy: ISimpleMenuOption | null;
  columns: ISimpleMenuOption | null;
  columnsGroupBy: ISimpleMenuOption | null;

  reviewee: ISimpleMenuOption[];
  revieweeRoles: ISimpleMenuOption[];
  revieweeDepartments: ISimpleMenuOption[];
  revieweeGroups: ISimpleMenuOption[];

  reviewer: ISimpleMenuOption[];
  reviewerRoles: ISimpleMenuOption[];
  reviewerDepartments: ISimpleMenuOption[];
  reviewerGroups: ISimpleMenuOption[];

  prc: ISimpleMenuOption[];
  prcQuestions: ISimpleMenuOption[];
}

export interface IReviewAnalyticsViewQuery {
  filters: {
    "prc"?: {
      type: string;
      values: string[];
    },
    "reviewer"?: {
      type: string;
      values: string[];
    },
    "reviewer.department"?: {
      type: string;
      values: string[];
    },
    "reviewer.role"?: {
      type: string;
      values: string[];
    },
    "reviewer.group"?: {
      type: string;
      values: string[];
    },
    "reviewee"?: {
      type: string;
      values: string[];
    },
    "reviewee.department"?: {
      type: string;
      values: string[];
    },
    "reviewee.role"?: {
      type: string;
      values: string[];
    },
    "reviewee.group"?: {
      type: string;
      values: string[];
    },
  };
  rows: {
    type: string | null;
  }[];
  columns: {
    type: string | null;
  }[];
};
export interface IReviewAnalyticsView {
  id: string;
  companyId: string;
  createdAt: number;
  query: IReviewAnalyticsViewQuery;
  title: string;
  updatedAt: number;
}

export interface IReviewAnalyticsComments {
  question: string;
  reviewer: string;
  comment: string;
}