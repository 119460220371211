import { IUserProfile, IUserProfileContextValue } from "interfaces";
import * as React from "react";

const UserProfileContext = React.createContext<IUserProfileContextValue | null>(null);


interface IProvideUserProfileProps {
  children: React.ReactNode;
}

const ProvideUserProfile: React.FunctionComponent<IProvideUserProfileProps> = ({ children }) => {

  const [userProfile, setUserProfile] = React.useState<IUserProfile | null>(null);

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default ProvideUserProfile;


export const useUserProfile = (): IUserProfileContextValue => {
  return React.useContext<IUserProfileContextValue | null>(UserProfileContext) as IUserProfileContextValue;
};