import { faBars, faChevronLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Dropdown, Nav, Navbar } from '@themesberg/react-bootstrap';
import { Breadcrumbs, ImageWithNameInitials, useAuth, useUserProfile } from "components";
import { useFrameContext } from "contexts";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

interface INavBarProps {
  routeProps: RouteComponentProps;
  setToggle: any;
}

const NavBar: React.FunctionComponent<INavBarProps> = (
  { routeProps, setToggle }
) => {

  const { userProfile } = useUserProfile();
  const { signOut } = useAuth();
  const { frameState, postFrameMessage } = useFrameContext();

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 mb-4 flex-shrink-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between align-items-center w-100">

          <Button
            id="sidebar-toggle"
            style={{ margin: '-4px', padding: 0 }}
            variant='link'
            className="sidebar-toggle me-3 btn btn-icon-only d-none d-lg-inline-block align-items-center justify-content-center"
            onClick={() => { setToggle((t: boolean) => !t) }}
          >
            <svg
              width="40px"
              height="35px"
              className="toggle-icon"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z">
              </path>
            </svg>
          </Button>

          <Breadcrumbs routeProps={routeProps} />
          <Nav className="align-items-center ms-auto">
            {
              frameState.embed ? (
                <Button variant="primary" className="animate-hover" onClick={() => postFrameMessage("home_view")}>
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  Go back home
                </Button>
              ) : (
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                    <div className="media d-flex align-items-center">
                      <ImageWithNameInitials imageOriginal={userProfile!.imageOriginal} label={userProfile!.name} />
                      <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="mb-0 font-small fw-bold">{userProfile!.name}</span>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                    <Dropdown.Item className="fw-bold" onClick={signOut}>
                      <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavBar;
