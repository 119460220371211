import * as React from "react";
const InnerChatIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={2}
      d="M9.25 3.855c1.622-2.964 5.878-2.964 7.5 0l1.306 2.39a4.273 4.273 0 0 0 1.699 1.699l2.39 1.307c2.964 1.62 2.964 5.877 0 7.498l-2.39 1.307a4.273 4.273 0 0 0-1.699 1.699l-1.307 2.39c-1.62 2.964-5.877 2.964-7.498 0l-1.307-2.39a4.273 4.273 0 0 0-1.699-1.699l-2.39-1.307c-2.964-1.62-2.964-5.877 0-7.498l2.39-1.307a4.273 4.273 0 0 0 1.699-1.699l1.307-2.39Z"
    />
  </svg>
);
export default InnerChatIcon;
