import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@themesberg/react-bootstrap';
import { AxiosObservable } from 'axios-observable';
import { ConfirmationModal, CustomDropdown, Preloader } from 'components';
import {
  IListReducerAction,
  IResponse,
  IRowConfirmationModalProps,
  ISimpleMenuOption,
  ITableRowCommonActions,
} from 'interfaces';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { TABLE_ROW_COMMON_ACTIONS } from 'teamble-constants';

interface ITableRowActionProps {
  row: any;
  confirmationModalProps: IRowConfirmationModalProps;
  launchPath: string;
  duplicateRowAPI: (id: string) => AxiosObservable<IResponse<any>>;
  deleteRowAPI: (id: string) => AxiosObservable<IResponse<any>>;
  dispatchList: React.Dispatch<IListReducerAction<any>>;
}

export const TableRowAction: React.FunctionComponent<ITableRowActionProps> = (
  { row, confirmationModalProps, launchPath, duplicateRowAPI, deleteRowAPI, dispatchList }
) => {

  const [zIndexStyle, setZIndexStyle] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);

  const history = useHistory();


  const duplicateRow = (): void => {

    setIsLoading(true);
    duplicateRowAPI(row.id)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          dispatchList({
            type: 'DUPLICATE',
            payload: [row, response.data.data]
          });
        }
      );
  };


  const deleteRow = (isInitialCheck: boolean): void => {

    if (isInitialCheck) {
      setShowConfirmationModal(true);
      return;
    }

    setIsLoading(true);
    deleteRowAPI(row.id)
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          dispatchList({
            type: 'DELETE',
            payload: row
          });
        }
      );
  };


  const handleActionChange = (action: ISimpleMenuOption): void => {

    switch (action.value) {

      case (ITableRowCommonActions.Duplicate): {
        duplicateRow();
        break;
      }

      case (ITableRowCommonActions.Delete): {
        deleteRow(true);
        break;
      }

      case (ITableRowCommonActions.PreviewAndLaunch): {
        history.push(launchPath);
        break;
      }

    }
  };


  return (
    <>
      <div
        style={{ zIndex: zIndexStyle }}
        className='position-relative'
      >
        {
          isLoading
            ?
            <Button
              disabled
              size='sm'
              variant='link'
            >
              <Preloader isIcon show={isLoading} />
            </Button>
            :
            <CustomDropdown
              size='sm'
              id={`rowAction${row.id}`}
              variant='link'
              options={TABLE_ROW_COMMON_ACTIONS}
              icon={faEllipsisV}
              onChange={handleActionChange}
              onToggle={(show) => show ? setZIndexStyle(1) : setZIndexStyle(0)}
            />
        }
      </div>
      <ConfirmationModal
        title={confirmationModalProps.title}
        show={showConfirmationModal}
        onPositiveResponse={() => deleteRow(false)}
        onCloseClick={() => setShowConfirmationModal(false)}
      >
        <p>
          {confirmationModalProps.message}
        </p>
        {
          confirmationModalProps.isRowInUse &&
          <p>
            <i>
              {confirmationModalProps.description}
            </i>
          </p>
        }
      </ConfirmationModal>
    </>
  );
};