import { AxiosError } from 'axios';
import { AxiosObservable } from 'axios-observable';
import { getFeedbackLoopLibraryDetails, getFeedbackLoopTemplateDetails } from 'data';
import { IErrorResponse, IFeedbackLoop, IResponse, IRouteParams, ITemplate } from 'interfaces';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';


interface IFeedbackLoopBuilderData {
  isLoading: boolean;
  feedbackLoop: IFeedbackLoop | null;
  feedbackLoopTemplate: ITemplate | null;
  showEmptyState: boolean;
  onFeedbackLoopUpdate: React.Dispatch<React.SetStateAction<IFeedbackLoop | null>>;
  onFeedbackLoopTemplateUpdate: React.Dispatch<React.SetStateAction<ITemplate | null>>;
}


export const useFeedbackLoopBuilderData = (
  templateId: IRouteParams['templateId'],
  feedbackLibraryLoopId: IRouteParams['feedbackLibraryLoopId'],
): IFeedbackLoopBuilderData => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showEmptyState, setShowEmptyState] = React.useState<boolean>(false);
  const [feedbackLoop, setFeedbackLoop] = React.useState<IFeedbackLoop | null>(null);
  const [feedbackLoopTemplate, setFeedbackLoopTemplate] = React.useState<ITemplate | null>(null);

  const history = useHistory();


  React.useEffect(
    () => {

      if (!templateId && !feedbackLibraryLoopId) {
        setShowEmptyState(true);
        setFeedbackLoop(null);
        setFeedbackLoopTemplate(null);
        return;
      }

      setIsLoading(true);
      setFeedbackLoop(null);
      setFeedbackLoopTemplate(null);

      let feedbackLoopSource$: AxiosObservable<IResponse<IFeedbackLoop | ITemplate>> | null = null;

      if (templateId) {
        feedbackLoopSource$ = getFeedbackLoopTemplateDetails(templateId);
      }
      if (feedbackLibraryLoopId) {
        feedbackLoopSource$ = getFeedbackLoopLibraryDetails(feedbackLibraryLoopId);
      }
      if (!feedbackLoopSource$) {
        return;
      }

      const subscription = feedbackLoopSource$
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          {
            next: (response) => {
              if (response.data.data) {
                if (templateId) {
                  setFeedbackLoopTemplate(response.data.data as ITemplate);
                }
                if (feedbackLibraryLoopId) {
                  setFeedbackLoop(response.data.data as IFeedbackLoop);
                }
                setShowEmptyState(false);
              } else {
                setShowEmptyState(true);
              }
            },
            error: (error: AxiosError<IErrorResponse>) => {
              if (error.response?.status === 403) {
                history.replace(Routes.FeedbackLoopsListPage.path);
              }
            }
          }
        );

      return () => subscription.unsubscribe();
    },
    [feedbackLibraryLoopId, history, templateId]
  );


  return {
    isLoading,
    showEmptyState,
    feedbackLoop,
    feedbackLoopTemplate,
    onFeedbackLoopUpdate: setFeedbackLoop,
    onFeedbackLoopTemplateUpdate: setFeedbackLoopTemplate
  }

};