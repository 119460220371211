import { IMenuOption } from 'interfaces';
import TurndownService from 'turndown';

export const getTurndownServiceInstance = (): TurndownService => {

  TurndownService.prototype.escape = (str) => str;
  const turndownService = new TurndownService();

  turndownService
    .addRule(
      'p',
      {
        filter: 'p',
        replacement: (content: string) => `\n${content}`
      }
    )
    .addRule(
      'strong',
      {
        filter: 'strong',
        replacement: (content: string) => `*${content}*`
      }
    )
    .addRule(
      'em',
      {
        filter: 'em',
        replacement: (content: string) => `_${content}_`
      }
    )
    .addRule(
      'strike',
      {
        filter: 'del',
        replacement: (content: string) => `~${content}~`
      }
    )
    .addRule(
      'link',
      {
        filter: 'a',
        replacement: (content: string, node: Node) => {
          const href = (node as HTMLElement).getAttribute('href');
          if (href) {
            return `<${href}| ${content} >`;
          }
          return content;
        }
      }
    );

  return turndownService;

};

export const replaceOptionsText = (options: IMenuOption[], sourceText: string, targetText: string) => {

  for (let index = 0; index < options.length; index++) {
    const option = options[index];
    if (option.label) {
      option.label = option.label.replace(sourceText, targetText);
    }
    if (Array.isArray(option.options) && option.options.length) {
      replaceOptionsText(option.options, sourceText, targetText);
    }
  }

  return options;
}
