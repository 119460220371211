import FormField from 'components/FormComponents/FormField';
import { FormContext, MetaDataContext } from 'contexts';
import { useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  IFormQuestionValue,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IMultiSelectQuestionPreviewFormValues,
  IQuestionTypeConstants,
  ITextQuestionPreviewFormValues,
} from 'interfaces';
import React from 'react';
import { getMultiSelectChoices } from 'utils';
import {
  MULTI_SELECT_QUESTION_PREVIEW_VALIDATION_SCHEMA,
  TEXT_QUESTION_PREVIEW_VALIDATION_SCHEMA,
} from 'validation-schemas';

interface IQuestionPreviewProps {
  question: IFormQuestionValue;
}


const TitleQuestionPreview: React.FC<IQuestionPreviewProps> = (
  { question }
) => {

  return (
    question.titleValue ?
      <p dangerouslySetInnerHTML={{ __html: question.titleValue.text }}></p>
      : null
  );

};


const TextQuestionPreview: React.FC<IQuestionPreviewProps> = (
  { question }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const questionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  const textForm = useFormik<ITextQuestionPreviewFormValues>({
    initialValues: {
      answer: '',
      required: question.textValue?.required
    },
    validationSchema: TEXT_QUESTION_PREVIEW_VALIDATION_SCHEMA,
    onSubmit: () => { }
  });


  React.useEffect(
    () => {
      textForm.setFieldValue('required', question.textValue?.required);
    },
    [question]
  );


  return (
    <FormContext.Provider value={textForm}>
      <FormField
        size='lg'
        label={
          question.textValue?.label ?
            `${question.textValue.label} ${question.textValue.required ? '' : '(optional)'}`
            : ''
        }
        controlId={`titleQuestion-${question.textValue?.label}`}
        type={
          question.type === questionTypeConstants.SHORT_TEXT
            ?
            FormFieldTypeConstants.Text
            :
            FormFieldTypeConstants.Textarea
        }
        controlName='answer'
        placeholder={question.textValue?.placeholder} />
    </FormContext.Provider>
  );
};


const MultiSelectQuestionPreview: React.FunctionComponent<IQuestionPreviewProps> = (
  { question }
) => {

  const [choices, setChoices] = React.useState<Partial<IMenuOption>[] | null>(null);

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const multiSelectChoicesConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  const multiSelectForm = useFormik<IMultiSelectQuestionPreviewFormValues>({
    initialValues: {
      radioAnswer: '',
      dropdownAnswer: null,
      commentBoxText: '',
      required: question.multiSelectValue?.required,
      commentBox: question.multiSelectValue?.enableCommentBox,
      dropdown: question.multiSelectValue?.dropdown
    },
    validationSchema: MULTI_SELECT_QUESTION_PREVIEW_VALIDATION_SCHEMA,
    onSubmit: () => { }
  });


  React.useEffect(
    () => {
      if (
        multiSelectChoicesConstants &&
        question.multiSelectValue?.selectedOption.value === multiSelectChoicesConstants.CUSTOM_ITEMS
      ) {

        const options: Partial<IMenuOption>[] | null =
          getMultiSelectChoices(question.multiSelectValue?.choices)
        setChoices(options);

      } else {

        const options = metadata?.multiSelectQuestionOptionsTypes.options.find(
          (option) => option.value === question.multiSelectValue?.selectedOption.value
        );
        if (options && options.options) {
          setChoices(options.options);
        }

      }

      multiSelectForm.setFieldValue('required', question.multiSelectValue?.required);
      multiSelectForm.setFieldValue('commentBox', question.multiSelectValue?.enableCommentBox);
      multiSelectForm.setFieldValue('radioAnswer', '');
      multiSelectForm.setFieldValue('dropdownAnswer', null);

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question]
  );



  return (
    <FormContext.Provider value={multiSelectForm}>
      <>
        {
          question.multiSelectValue?.dropdown ?
            (
              <FormField
                size='lg'
                label={
                  question.multiSelectValue?.label ?
                    `${question.multiSelectValue.label} ${question.multiSelectValue.required ? '' : '(optional)'}`
                    : ''
                }
                controlId='multiSelectQuestionField'
                type={FormFieldTypeConstants.Select}
                controlName='dropdownAnswer'
                placeholder='Pick an option'
                options={choices || []} />
            ) :
            (
              <FormField
                size='lg'
                label={
                  question.multiSelectValue?.label ?
                    `${question.multiSelectValue.label} ${question.multiSelectValue.required ? '' : '(optional)'}`
                    : ''
                }
                type={FormFieldTypeConstants.Radio}
                controlName='radioAnswer'
                options={choices || []} />
            )
        }
      </>
      {
        question.multiSelectValue?.enableCommentBox &&
        <FormField
          size='lg'
          label=''
          placeholder={question.multiSelectValue?.comment}
          controlId='multiSelectQuestionComment'
          type={FormFieldTypeConstants.Textarea}
          controlName='commentBoxText' />
      }
    </FormContext.Provider>
  );
};



const QuestionPreview: React.FC<IQuestionPreviewProps> = (
  { question }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const questionTypeConstants = metadata.questionTypes.constants as IQuestionTypeConstants;

  switch (question.type) {

    case questionTypeConstants.TITLE:
    case questionTypeConstants.SUBTITLE: {
      return (
        <TitleQuestionPreview question={question} />
      );
    }

    case questionTypeConstants.SHORT_TEXT:
    case questionTypeConstants.LONG_TEXT: {
      return (
        <TextQuestionPreview question={question} />
      );
    }

    case questionTypeConstants.MULTI_SELECT_QUESTION: {
      return (
        <MultiSelectQuestionPreview question={question} />
      );
    }
  }

  return null;
};

export default QuestionPreview;