import React, { Dispatch } from "react";
import "./TabButton.scss";
import { MeetingStatus } from "modules/1-on-1s/types";

interface TabButtonProps {
  name: MeetingStatus;
  currentStatus: string;
  setActive: Dispatch<React.SetStateAction<MeetingStatus>>;
}

const TabButton: React.FC<TabButtonProps> = ({
  name,
  currentStatus,
  setActive,
}) => {
  const active = currentStatus === name;
  return (
    <div
      onClick={() => setActive(name)}
      style={{
        color: active ? "#0A0D16" : "",
        borderBottom: active ? "1px solid #0A0D16" : "1px solid transparent",
      }}
      className="TabButton"
    >
      {name}
    </div>
  );
};

export default TabButton;
