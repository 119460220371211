import { Card } from '@themesberg/react-bootstrap';
import { CustomSelect } from 'components';
import {
  getReviewOptionsForScoreAnalytics
} from 'data';
import { ISimpleMenuOption, IUserDetailsContext } from 'interfaces';
import React, { useEffect } from 'react';
import { finalize } from 'rxjs';
import { DelimitedArrayParam, useQueryParam } from 'use-query-params';
import { getMultipleSelectedOptionsByQueryParam } from 'utils';

import { useUserDetails } from '../hooks/useUserDetails';

interface IMemberProfileReviewScoreCardHeaderProps { }

const MemberProfileReviewScoreCardHeader: React.FC<IMemberProfileReviewScoreCardHeaderProps> = () => {

  /* State hooks */
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reviewOptions, setReviewOptions] = React.useState<ISimpleMenuOption[]>([]);

  /* Route Hooks */
  const [reviewIdsQueryParam, setReviewIdsQueryParam] = useQueryParam('reviews', DelimitedArrayParam);

  /* Selected Options by query params */
  const selectedReviewOptions = getMultipleSelectedOptionsByQueryParam(
    reviewIdsQueryParam, reviewOptions
  );

  /* User Details Hook */
  const { userDetails } = useUserDetails() as IUserDetailsContext;


  // Get the list of performance review campaigns to be displayed
  // in the dropdown
  useEffect(
    () => {

      setIsLoading(true);
      const subscription = getReviewOptionsForScoreAnalytics(
        userDetails.teambleUserId as string
      )
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setReviewOptions(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [userDetails.teambleUserId]
  );


  return (
    <Card.Header className='flex-shrink-0 d-flex align-items-start justify-content-between position-relative z-index-2'>
      <div>
        <Card.Subtitle className='fw-normal fs-5'>
          Scores by questions
        </Card.Subtitle>
        <Card.Title className='m-0'>
          Performance Scores
        </Card.Title>
      </div>
      <CustomSelect
        isMulti
        className='w-50'
        isLoading={isLoading}
        options={reviewOptions}
        value={selectedReviewOptions}
        placeholder='Select performance reviews...'
        onChange={
          (options) => {
            setReviewIdsQueryParam(
              (options as ISimpleMenuOption[]).map(({ value }) => value)
            )
          }
        }
      />

    </Card.Header>
  );
};

export default MemberProfileReviewScoreCardHeader;