import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from '@themesberg/react-bootstrap';
import { MetaDataContext } from 'contexts';
import { FormikProps } from 'formik';
import { IMetadata, IQuestionTypeConstants, IReviewTemplateFormValues } from 'interfaces';
import * as React from 'react';

import ReviewTemplatePreviewModal from '../modals/ReviewTemplatePreviewModal';
import ReviewTemplateQuestionPreview from './ReviewTemplateQuestionPreview';

interface IReviewTemplateQuestionPreviewWidgetProps {
  activeQuestionIndex: number | null;
  reviewForm: FormikProps<IReviewTemplateFormValues>;
}

const ReviewTemplateQuestionPreviewWidget: React.FunctionComponent<IReviewTemplateQuestionPreviewWidgetProps> = (
  { activeQuestionIndex, reviewForm }
) => {

  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [previewTitle, setPreviewTitle] = React.useState<string>('Preview');

  const metadata = React.useContext(MetaDataContext) as IMetadata;
  const questionTypeConstants: IQuestionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;


  React.useEffect(
    () => {

      if (activeQuestionIndex === null) {
        setPreviewTitle('Preview');
        return;
      }

      switch (reviewForm.values.blocks[activeQuestionIndex].type) {

        case questionTypeConstants.TITLE: {
          setPreviewTitle('Title preview');
          return;
        }

        case questionTypeConstants.SUBTITLE: {
          setPreviewTitle('Subtitle preview');
          return;
        }

        case questionTypeConstants.SHORT_TEXT: {
          setPreviewTitle('Short text question preview');
          return;
        }

        case questionTypeConstants.LONG_TEXT: {
          setPreviewTitle('Long text question preview');
          return;
        }

        case questionTypeConstants.MULTI_SELECT_QUESTION: {
          setPreviewTitle('Multi select question preview');
          return;
        }

        case questionTypeConstants.COMPETENCY_QUESTION: {
          setPreviewTitle('Competency question preview');
          return;
        }

        case questionTypeConstants.OBJECTIVE_QUESTION: {
          setPreviewTitle('Objective question preview');
          return;
        }

        default: {
          setPreviewTitle('Preview');
        }
      }

    },
    [activeQuestionIndex, questionTypeConstants, reviewForm.values.blocks]
  );


  return (
    <>
      <Card className='flex-grow-1 overflow-hidden'>

        <Card.Header className='flex-shrink-0'>
          <Card.Title> {previewTitle} </Card.Title>
          {
            activeQuestionIndex !== null &&
            reviewForm.values.blocks[activeQuestionIndex].type === questionTypeConstants.COMPETENCY_QUESTION &&
            <Card.Subtitle className='fw-normal small'>
              <FontAwesomeIcon icon={faInfoCircle} /> This is just the preview of a question. All the competencies will be adjusted according to reviewee role after launching of a performance review.
            </Card.Subtitle>
          }
           {
            activeQuestionIndex !== null &&
            reviewForm.values.blocks[activeQuestionIndex].type === questionTypeConstants.OBJECTIVE_QUESTION &&
            <Card.Subtitle className='fw-normal small'>
              <FontAwesomeIcon icon={faInfoCircle} /> This is just the preview of a question. All the question will be adjusted according to reviewee objective after launching of a performance review.
            </Card.Subtitle>
          }
        </Card.Header>

        <Card.Body className='overflow-y-auto'>
          <div className='h-100 d-flex flex-column'>
            <div className='w-75 m-auto'>
              {
                activeQuestionIndex !== null &&
                <ReviewTemplateQuestionPreview question={reviewForm.values.blocks[activeQuestionIndex]} />
              }
            </div>
          </div>
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end flex-shrink-0'>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => setShowPreview(true)}
          >
            Full Preview
          </Button>
        </Card.Footer>
      </Card>
      <ReviewTemplatePreviewModal
        template={reviewForm.values}
        show={showPreview}
        onClose={() => setShowPreview(false)} />
    </>
  );
};

export default ReviewTemplateQuestionPreviewWidget;
