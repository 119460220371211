import { IUserProfile } from './userProfile.interface';

export interface IAuthContextValue {
  isSignedIn: boolean;
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  signIn: (signInWith: SignInWith) => void;
  signOut: () => void;
}

export interface ISignInResponseData {
  redirect_url: string;
}

export interface ICompleteSlackAuthorizationResponseData {
  profileDetail: IUserProfile;
  idToken: string;
  refreshToken: string;
  type: 'redirect' | 'authorized';
  redirectUrl: string;
  continueURL?: string;
}

export enum SignInWith {
  Slack = 'slack',
  MsTeams = 'msteams'
}