import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from '@themesberg/react-bootstrap';
import { AxiosObservable } from 'axios-observable';
import { Table, useTmblTable, useTmblTableData } from '@common/modules/table';
import { EmptyState, PageTitle, Preloader, PrimaryLayoutBox } from 'components';
import { getPerformanceReviewQuestionnaires, getSurveyLibrary } from 'data';
import { useApp } from 'hooks';
import {
  IResponse,
  IReviewOrSurvey,
  IReviewOrSurveyType,
  IReviewTemplate,
  IRoute,
  ISurvey,
} from 'interfaces';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';

import { useReviewOrSurveyLibraryTableColumns } from './hooks/useReviewOrSurveyLibraryTableColumns';

interface IReviewOrSurveyProps {
  context: IReviewOrSurvey;
}

interface IPageMetadata {
  title: string;
  description: string;
  addNewPath: IRoute['path'];
}

interface IReviewOrSurveyTableProps extends IReviewOrSurveyProps {
  reviewOrSurveyData: (IReviewOrSurveyType)[];
  setReviewOrSurveyData: (reviewOrSurveyData: (IReviewOrSurveyType)[]) => void;
  app: string;
}


const ReviewOrSurveyTable: React.FunctionComponent<IReviewOrSurveyTableProps> =
  ({ context, reviewOrSurveyData, setReviewOrSurveyData }) => {

    const history = useHistory();

    const tableColumns = useReviewOrSurveyLibraryTableColumns(context);

    const tableData = useTmblTableData<IReviewOrSurveyType>(reviewOrSurveyData);

    const tableInstance = useTmblTable<IReviewOrSurveyType>(
      tableColumns,
      tableData,
      'createdAt',
      true
    );


    const duplicateRow = (rowData: IReviewOrSurveyType, index: number): void => {

      const reviewOrSurveyDataCopy = reviewOrSurveyData.slice(0);
      reviewOrSurveyDataCopy.splice(index + 1, 0, rowData);
      setReviewOrSurveyData(reviewOrSurveyDataCopy);
    };


    const deleteRow = (index: number): void => {

      const reviewOrSurveyDataCopy = reviewOrSurveyData.slice(0);
      reviewOrSurveyDataCopy.splice(index, 1);
      setReviewOrSurveyData(reviewOrSurveyDataCopy);
    };


    return (
      <Table
        hover
        tableInstance={tableInstance}
        bodyCellProps={{
          duplicateRow,
          deleteRow
        }}
        onRowClick={
          (row) => {
            history.push(
              context === 'reviews' ?
                `${Routes.PerformanceReviewQuestionnaires.path}/${row.original.id}` :
                `${Routes.SurveyLibrary.path}/${row.original.id}`
            );
          }
        }
      />
    );
  };


const ReviewOrSurveyWidgetEmptyState: React.FunctionComponent<IReviewOrSurveyProps> = ({ context }) => {

  return context === 'reviews' ?
    <EmptyState message={<>No review questionnaires found. <br /> Click Add New to create one!</>} /> :
    <EmptyState message={<>No Surveys found. <br /> Add a new one!</>} />;

};


const ReviewOrSurveyWidget: React.FunctionComponent<IReviewOrSurveyProps> = ({ context }) => {

  const [reviewOrSurveyData, setReviewOrSurveyData] =
    React.useState<(IReviewOrSurveyType)[]>([]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const app = useApp();

  React.useEffect(
    () => {

      const getDataSource =
        (pageContext: typeof context): AxiosObservable<IResponse<(IReviewOrSurveyType)[]>> => {

          switch (pageContext) {
            case 'reviews': {
              return getPerformanceReviewQuestionnaires() as AxiosObservable<IResponse<IReviewTemplate[]>>;
            }

            case 'surveys': {
              return getSurveyLibrary() as AxiosObservable<IResponse<ISurvey[]>>;
            }
          }
        };

      setIsLoading(true);
      setReviewOrSurveyData([]);

      getDataSource(context)
        .pipe(
          finalize(
            () => setIsLoading(false)
          )
        )
        .subscribe(
          {
            next: (response) => {
              setReviewOrSurveyData(response.data.data);
            },
          }
        );
    },
    [context]
  );


  return (
    <>
      {
        isLoading ?
          (<Preloader show={isLoading} />) :
          (
            <>
              {
                !reviewOrSurveyData.length ?
                  (
                    <ReviewOrSurveyWidgetEmptyState context={context} />
                  ) :
                  (
                    <Card border="light" className="shadow-sm overflow-hidden h-100">
                      <Card.Body className="py-0 mt-4 position-relative h-100 overflow-y-auto">
                        <ReviewOrSurveyTable
                          context={context}
                          reviewOrSurveyData={reviewOrSurveyData}
                          setReviewOrSurveyData={setReviewOrSurveyData}
                          app={app}
                        />
                      </Card.Body >
                    </Card>
                  )
              }
            </>
          )
      }
    </>
  );

};


const ReviewOrSurveyLibrary: React.FunctionComponent<IReviewOrSurveyProps> = (
  { context }
) => {

  const getPageMetadata = React.useCallback(
    (pageContext: typeof context): IPageMetadata => {
      switch (pageContext) {

        case 'reviews': {
          return {
            title: Routes.PerformanceReviewQuestionnaires.title,
            description: 'Your Performance Review Questionnaires.',
            addNewPath: Routes.PerformanceReviewTemplates.path
          };
        }

        case 'surveys': {
          return {
            title: Routes.SurveyLibrary.title,
            description: 'Your Survey Library.',
            addNewPath: Routes.SurveyTemplates.path
          }
        }

      }
    },
    [context]
  );


  const [pageMetadata, setPageMetadata] = React.useState<IPageMetadata>(
    () => getPageMetadata(context)
  );


  React.useEffect(
    () => {
      setPageMetadata(getPageMetadata(context));
    },
    [context, getPageMetadata]
  );


  return (
    <>
      <PageTitle
        title={pageMetadata.title} />

      <PrimaryLayoutBox
        title={pageMetadata.title}
        description={pageMetadata.description}
        actions={
          <Link to={pageMetadata.addNewPath}>
            <Button size='sm'>
              <FontAwesomeIcon icon={faPlus} />
              <span> Add New </span>
            </Button>
          </Link>
        }
      >
        <div className='flex-grow-1 position-relative overflow-hidden'>
          <ReviewOrSurveyWidget context={context} />
        </div>
      </PrimaryLayoutBox>
    </>
  );
};

export default ReviewOrSurveyLibrary;
