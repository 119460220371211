import { Form } from '@themesberg/react-bootstrap';
import { CustomSelect } from 'components';
import { getFeedbackTypeMenuOptions } from 'data';
import { ISimpleMenuOption } from 'interfaces';
import React from 'react';
import { finalize } from 'rxjs';
import { NONE_OPTION } from 'teamble-constants';
import { StringParam, useQueryParam } from 'use-query-params';
import { getSelectedOptionByQueryParam } from 'utils';

interface IFeedbackTypeFilterProps { }

const FeedbackTypeFilter: React.FC<IFeedbackTypeFilterProps> = () => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // Options states
  const [feedbackTypeOptions, setFeedbackTypeOptions] = React.useState<ISimpleMenuOption[]>([]);

  // Route Hooks
  const [feedbackType, setFeedbackType] = useQueryParam('feedbackType', StringParam);

  // Selected Options by query params
  const selectedFeedbackTypeOption = getSelectedOptionByQueryParam(
    feedbackType, feedbackTypeOptions
  );


  // Fetch the filter options
  React.useEffect(
    () => {

      setIsLoading(true);
      const subscription = getFeedbackTypeMenuOptions()
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setFeedbackTypeOptions([NONE_OPTION, ...response.data.data]);
          }
        );

      return () => subscription.unsubscribe();
    },
    []
  );


  return (
    <div>
      <Form.Label>Feedback Type</Form.Label>
      <CustomSelect
        isSearchable
        isLoading={isLoading}
        options={feedbackTypeOptions}
        value={selectedFeedbackTypeOption}
        onChange={(option) => setFeedbackType((option as ISimpleMenuOption).value)}
      />
    </div>
  );
};

export default FeedbackTypeFilter;