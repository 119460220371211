import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModal, CustomDropdown, Divider, PrimaryLayoutBox } from 'components';
import { MetaDataContext } from 'contexts';
import { updateSurveyLibraryTemplate, updateSurveyTemplate } from 'data';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  ICreateOrUpdateSurveyTemplateRequestData,
  IMenuOption,
  IMetadata,
  ISurvey,
  ISurveyBuilderFormValues,
  ISurveyQuestionTypeConstants,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { finalize, tap } from 'rxjs';

import { SurveyPreviewQuestionIndexContext } from '../contexts/SurveyPreviewQuestionIndex';
import CreateUpdateSurveyTemplateModal from '../modals/CreateUpdateSurveyTemplateModal';
import {
  getNewSurveyQuestion,
  getSurveyFormInitialValues,
  getSurveyFormValidationSchema,
  getSurveyTemplateRequestBlocks,
  hasTrackableNotLockedQuestion,
} from '../utils/utils';
import SurveyForm from './SurveyForm';
import SurveyQuestionPreviewWidget from './SurveyQuestionPreviewWidget';

interface ISurveyBuilderWidgetProps {
  survey: ISurvey;
  surveyId?: string;
  surveyTemplateId?: string;
  onSurveyChange: (survey: ISurvey) => void;
}

const SurveyBuilderWidget: React.FunctionComponent<ISurveyBuilderWidgetProps> = (
  { survey, surveyId, surveyTemplateId, onSurveyChange }
) => {

  const [activeQuestionIndex, setActiveQuestionIndex] = React.useState<string>('');
  const [previewQuestionIndex, setPreviewQuestionIndex] = React.useState<string>('');
  const [showSurveyTemplateModal, setShowSurveyTemplateModal] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const [surveyBlockTempIdIndex, setSurveyBlockTempIdIndex] = React.useState<number>(survey?.blocks?.length || 0);
  const [newQuestionAddIndex, setNewQuestionAddIndex] = React.useState<number>(0);


  const saveActionTypeRef = React.useRef<'template' | 'submit' | 'next'>();


  const metadata = React.useContext<IMetadata | null>(MetaDataContext) as IMetadata;

  const history = useHistory();

  const app = useApp();


  const getSaveSurveyUpdatesObservable = (
    values: ISurveyBuilderFormValues,
    surveyTempId: string
  ) => {

    return updateSurveyLibraryTemplate(
      {
        surveyTemplateId: surveyTempId,
        ...values,
        blocks: getSurveyTemplateRequestBlocks(values.blocks, metadata, app)
      },
      metadata,
      app
    ).pipe(
      tap(
        (response) => {
          toast.success(response.data.message);
        }
      )
    )
  };


  const updateSurveyTemplateData = (
    values: ISurveyBuilderFormValues,
    formikHelpers: FormikHelpers<ISurveyBuilderFormValues>
  ): void => {

    formikHelpers.setSubmitting(true);

    const requestData: ICreateOrUpdateSurveyTemplateRequestData = {
      title: values.title,
      desc: survey.desc,
      workspaceId: survey.workspaceId,
      visibleToEveryone: survey.visibleToEveryone,
      visibleToWorkspaceAdmin: survey.visibleToWorkspaceAdmin,
      blocks: getSurveyTemplateRequestBlocks(values.blocks, metadata, app)
    };

    updateSurveyTemplate(
      { templateId: surveyTemplateId, ...requestData },
      metadata,
      app
    )
      .pipe(
        finalize(() => formikHelpers.setSubmitting(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          onSurveyChange(response.data.data);
        }
      );
  };


  const submitSurveyBuilderForm = (
    values: ISurveyBuilderFormValues,
    formikHelpers: FormikHelpers<ISurveyBuilderFormValues>
  ): void => {

    if (surveyTemplateId) {
      updateSurveyTemplateData(values, formikHelpers);
      return;
    }

    if (!surveyId) {
      formikHelpers.setSubmitting(false);
      return;
    }

    formikHelpers.setSubmitting(true);

    getSaveSurveyUpdatesObservable(values, surveyId)
      .pipe(
        finalize(() => formikHelpers.setSubmitting(false))
      )
      .subscribe(
        (response) => {
          onSurveyChange(response.data.data);
        }
      );

  };


  const surveyForm: FormikProps<ISurveyBuilderFormValues> =
    useFormik<ISurveyBuilderFormValues>({
      initialValues: getSurveyFormInitialValues(),
      validationSchema: getSurveyFormValidationSchema(metadata, app),
      onSubmit(values, formikHelpers) {

        formikHelpers.setSubmitting(false);
        if (
          app !== SignInWith.Slack ||
          saveActionTypeRef.current === 'submit'
        ) {
          saveActionTypeRef.current = undefined;
          submitSurveyBuilderForm(values, formikHelpers);
          return;
        }

        const isWarningRequired = hasTrackableNotLockedQuestion(
          values.blocks,
          metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants
        );

        if (!isWarningRequired) {
          submitSurveyBuilderForm(values, formikHelpers);
          return;
        }

        saveActionTypeRef.current = 'submit';
        setShowConfirmationModal(true);
      }
    });


  const saveSurveyUpdates = () => {

    if (!surveyId) {
      return;
    }
    surveyForm.validateForm().then(
      (errors) => {

        if (Object.keys(errors).length) {
          return;
        }

        if (app === SignInWith.Slack && saveActionTypeRef.current === undefined) {
          const isWarningRequired = hasTrackableNotLockedQuestion(
            surveyForm.values.blocks,
            metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants
          );

          if (isWarningRequired) {
            saveActionTypeRef.current = 'next';
            setShowConfirmationModal(true);
            return;
          }
        }

        saveActionTypeRef.current = undefined;
        surveyForm.setSubmitting(true);
        getSaveSurveyUpdatesObservable(surveyForm.values, surveyId)
          .pipe(
            finalize(() => surveyForm.setSubmitting(false))
          )
          .subscribe(
            () => {
              surveyForm.resetForm({ values: surveyForm.values });
              history.push(`/surveys/survey-library/${surveyId}/survey-audience`);
            }
          );

      }
    );
  };


  React.useEffect(
    () => {

      if (survey) {

        const questionTypeConstants: ISurveyQuestionTypeConstants =
          metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

        let surveyFormValue = survey;

        if (!survey.blocks?.length) {
          surveyFormValue['blocks'] = [];
        }
        surveyForm.setValues(surveyFormValue);

        if (survey.blocks.length) {
          if (survey.blocks[0].type === questionTypeConstants.GROUP) {
            setActiveQuestionIndex('group_0_0');
            setPreviewQuestionIndex('group_0_0');
          } else {
            setActiveQuestionIndex('notGroup_0');
            setPreviewQuestionIndex('notGroup_0');
          }
        } else {
          setActiveQuestionIndex('');
          setPreviewQuestionIndex('');
        }

      }
    },
    [survey]
  );


  const handleQuestionAdd = (option: IMenuOption) => {

    const questionTypeConstants: ISurveyQuestionTypeConstants =
      metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

    const newQuestion = getNewSurveyQuestion(
      (surveyBlockTempIdIndex + 1).toString(),
      option,
      questionTypeConstants,
      metadata.multiSelectQuestionOptionsTypes.options,
      app
    );

    surveyForm.setFieldValue(
      'blocks',
      [
        ...surveyForm.values.blocks,
        newQuestion
      ],
      true
    );

    let indexToSet = '';
    if (option.value === questionTypeConstants.GROUP) {
      indexToSet = surveyForm.values.blocks.length
        ?
        `group_${surveyForm.values.blocks.length}_0`
        :
        'group_0_0';
    } else {
      indexToSet = surveyForm.values.blocks.length
        ?
        `nonGroup_${surveyForm.values.blocks.length}`
        :
        'nonGroup_0';
    }

    setActiveQuestionIndex(indexToSet);
    setPreviewQuestionIndex(indexToSet);
    setNewQuestionAddIndex(newQuestionAddIndex + 1);
    setSurveyBlockTempIdIndex(surveyBlockTempIdIndex + 1);

  };


  const handleCreateOrUpdateTemplateClick = (): void => {

    if (app !== SignInWith.Slack) {
      setShowSurveyTemplateModal(true);
      return;
    }

    const isWarningRequired = hasTrackableNotLockedQuestion(
      surveyForm.values.blocks,
      metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants
    );

    if (!isWarningRequired) {
      setShowSurveyTemplateModal(true);
      return;
    }

    saveActionTypeRef.current = 'template';
    setShowConfirmationModal(true);
  };


  const handleConfirmationModalPositiveResponse = (): void => {
    setShowConfirmationModal(false);

    if (saveActionTypeRef.current === 'template') {
      setShowSurveyTemplateModal(true);
      saveActionTypeRef.current = undefined;
      return;
    }

    if (saveActionTypeRef.current === 'submit') {
      surveyForm.submitForm();
      return;
    }

    if (saveActionTypeRef.current === 'next') {
      saveSurveyUpdates();
      return;
    }
  };


  return (
    <SurveyPreviewQuestionIndexContext.Provider
      value={{ index: previewQuestionIndex, setIndex: setPreviewQuestionIndex }}
    >
      <div className='d-flex overflow-hidden flex-grow-1'>
        <div className='h-100 d-flex flex-column flex-grow-1 flex-basis-33 pt-1 mw-33'>

          <PrimaryLayoutBox
            title={surveyTemplateId ? Routes.EditSurveyTemplate.title : Routes.SurveyBuilder.title}
            description={`
              ${surveyTemplateId ? 'Update your survey template here' : 'Build your survey here'}
            `}
            actions={
              <>
                {
                  metadata && metadata.surveyQuestionTypes &&
                  <CustomDropdown
                    id='add-survey-question-dropdown'
                    variant='outline-primary'
                    title='Add Question'
                    options={metadata.surveyQuestionTypes.options}
                    icon={faPlus}
                    onChange={handleQuestionAdd}
                  />
                }
              </>
            }
          >
            <SurveyForm
              surveyForm={surveyForm}
              newQuestionAddIndex={newQuestionAddIndex}
              activeQuestionIndex={activeQuestionIndex}
              onSaveAndNextClick={saveSurveyUpdates}
              surveyBlockTempIdIndex={surveyBlockTempIdIndex}
              setSurveyBlockTempIdIndex={setSurveyBlockTempIdIndex}
              onActiveQuestionIndexChange={setActiveQuestionIndex}
              onCreateSurveyTemplateClick={handleCreateOrUpdateTemplateClick}
            />
          </PrimaryLayoutBox>

        </div>

        <Divider isVertical={true} />

        <div className='h-100 d-flex flex-column flex-grow-1 pt-1'>
          {
            <PrimaryLayoutBox
              title='Preview'
              description={surveyTemplateId ? 'Preview your survey template here' : 'Preview your survey here'}
            >
              {
                surveyForm.values.blocks?.length ?
                  (
                    <SurveyQuestionPreviewWidget surveyForm={surveyForm} />
                  ) :
                  (null)
              }
            </PrimaryLayoutBox>
          }
        </div>
      </div>

      {
        showSurveyTemplateModal &&
        <CreateUpdateSurveyTemplateModal
          survey={survey}
          blocks={surveyForm.values.blocks}
          templateId={surveyTemplateId}
          show={showSurveyTemplateModal}
          surveyId={surveyId}
          onClose={() => setShowSurveyTemplateModal(false)}
          onSurveyChange={onSurveyChange} />
      }

      {
        <ConfirmationModal
          title='Trackable Questions'
          show={showConfirmationModal}
          closeOnUserResponse={false}
          onPositiveResponse={handleConfirmationModalPositiveResponse}
          onNegativeResponse={() => {
            saveActionTypeRef.current = undefined;
            setShowConfirmationModal(false);
          }}
          onCloseClick={() => {
            saveActionTypeRef.current = undefined;
            setShowConfirmationModal(false);
          }}
        >
          <p>
            Are you sure to save the questions?
          </p>
          <p>
            <i>
              This survey contains trackable questions.
              Once saved, all trackable questions will be automatically locked,
              and you won't be able to edit or remove them.
            </i>
          </p>
        </ConfirmationModal>
      }

      {/* <Prompt
        when={!!(surveyId && surveyForm.dirty)}
        message='You have unsaved changes. Are you sure to leave the page?' /> */}

    </SurveyPreviewQuestionIndexContext.Provider>
  );
};

export default SurveyBuilderWidget;
