import { Form } from '@themesberg/react-bootstrap';
import * as React from 'react';
import { useAsyncDebounce, TableInstance } from 'react-table';

interface ITableGlobalFilterProps {
  className?: string,
  placeholder?: string;
  globalFilter: TableInstance['state']['globalFilter'],
  setGlobalFilter: TableInstance['setGlobalFilter'],
}

export const TableGlobalFilter: React.FunctionComponent<ITableGlobalFilterProps> = (
  { globalFilter, className, placeholder = 'Search...', setGlobalFilter
  }
) => {

  const [value, setValue] = React.useState<TableInstance['state']['globalFilter']>(globalFilter);

  const onChange = useAsyncDebounce(
    (value) => {
      setGlobalFilter(value || undefined)
    },
    500
  );

  return (
    <Form.Control
      type="text"
      value={value || ""}
      className={className}
      placeholder={placeholder}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }} />
  )

};