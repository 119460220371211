import React, { Dispatch, SetStateAction, useState } from "react";
import CustomTextArea from "../../../../components/CustomTextArea/CustomTextArea";
import HeaderText from "../../../../components/HeaderText/HeaderText";
import AiTagButton from "../../../../components/AiTagButton/AiTagButton";
import "./AiQuestionCard.scss";
import { FeedbackData, TagOptionsProps } from "modules/1-on-1s/types";
import { changeFeedbackOpeningStatementTone } from "data/teamble-ai-one-on-one";
import { AxiosError } from "axios";
import { finalize } from "rxjs";

interface AiQuestionCardProps {
  data?: FeedbackData;
  label: string;
  question: string;
  placeholder: string;
  options?: TagOptionsProps[];
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  section?: string;
}
const AiQuestionCard: React.FC<AiQuestionCardProps> = ({
  data,
  label,
  question,
  placeholder,
  options,
  value,
  setValue,
  section,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleOpeningStatementToneChange = async (
    tag: string,
    openingStatement: string,
    userFeedback: string
  ) => {
    if (!openingStatement || !userFeedback || !data?.id) return;
    setIsLoading(true);
    changeFeedbackOpeningStatementTone(
      data.id,
      tag,
      openingStatement,
      userFeedback
    )
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe({
        next: (response: any) => {
          setValue?.(response?.data?.feedback?.openingStatement);
        },
        error: (error: AxiosError) => {
          console.log(error);
          setIsLoading(false);
        },
      });
  };
  const handleOnClick = (name: string) => {
    switch (section) {
      case "specificity":
        setValue?.(
          options?.find((item: any) => item?.tag === name)?.expanded || ""
        );

        break;
      case "actionability":
        setValue?.((prev: any) => {
          const expandedText =
            options?.find((item: any) => item?.tag === name)?.expanded || "";
          const alreadyExists = prev?.includes(expandedText);
          if (alreadyExists) {
            return prev;
          } else {
            return prev ? prev + `\n\n` + expandedText : expandedText;
          }
        });
        break;
      case "aiTone":
        handleOpeningStatementToneChange(name, value, data?.userFeedback ?? "");
        break;
      default:
        return;
    }
  };
  return (
    <div className="AiQuestionCard">
      <div className="AiCardInfo">
        <div className="label-flex">
          <HeaderText text={label} fontSize={"16px"} lineHeight={"16px"} />
          <div className="question">{question}</div>
        </div>
        <div className="options">
          {options?.map((item: TagOptionsProps, index: number) => (
            <AiTagButton
              name={item?.tag ?? ""}
              key={index}
              onClick={handleOnClick}
            />
          ))}
        </div>
      </div>
      <div className="text-container">
        <CustomTextArea
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default AiQuestionCard;
