import { MetaDataContext } from 'contexts';
import {
  BehaviourTypes,
  IFeedback,
  IFeedbackMaps,
  IFeedbackTagFilters,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
} from 'interfaces';
import moment from 'moment';
import React, { useContext } from 'react';
import { Column } from 'react-table';
import { DATE_TIME_FORMAT } from 'teamble-constants';
import {
  getDateSortingFunc,
  getFeedbackCC,
  getFeedbackPreviewText,
  getFeedbackSenderText,
  getFeedbackTagsText,
  getFeedbackTypeText,
} from 'utils';


export const useFeedbacksListTableColumns = (
  feedbackMaps: IFeedbackMaps | undefined
): readonly Column<IFeedback>[] => {

  const dateSortingFunc = React.useMemo(
    () => getDateSortingFunc<IFeedback>(),
    []
  );

  const metadata = useContext(MetaDataContext) as IMetadata;

  const multiQuestionTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
  const multiQuestionTypeOptions = metadata.multiSelectQuestionOptionsTypes.options;

  return React.useMemo<readonly Column<IFeedback>[]>(
    () => [
      {
        id: 'feedbackType',
        Header: 'Type',
        accessor: (row) => row.feedbackType!.title,
        filter: (rows, _, filterValue: string) => {

          if (!filterValue) {
            return rows;
          }
          return rows.filter(
            (row) => row.original.feedbackType!.id === filterValue
          );
        }
      },
      {
        id: 'sender',
        Header: 'From',
        accessor: (row) => getFeedbackSenderText(row),
        filter: (rows, _, filterValue: string) => {

          if (!filterValue) {
            return rows;
          }
          return rows.filter(
            (row) => !row.original.anonymous && row.original.sender.user_id === filterValue
          );
        }
      },
      {
        Header: 'CC',
        cellClassName: 'ws-pre-line',
        accessor: (row) => getFeedbackCC(row) || 'None',
      },
      {
        id: 'competency',
        Header: 'Tags',
        cellClassName: 'ws-pre-line',
        accessor: (row) => getFeedbackTagsText(row, feedbackMaps) || 'None',
        filter: (rows, _, filterValue: IFeedbackTagFilters) => {

          if (!filterValue.competency) {
            return rows;
          }

          return rows.filter(
            (row) => {

              const dimension = row.original.dimensionInfos?.find(
                (dimension) => dimension.id === filterValue.competency
              );

              if (!filterValue.behaviour) {
                return !!dimension;
              }

              const focusArea = dimension?.focusAreas?.find(
                (focus) => focus.id === filterValue.behaviour
              );

              if (!filterValue.behaviourType) {
                return !!focusArea;
              }

              if (filterValue.behaviourType === BehaviourTypes.Strength) {
                return focusArea?.str;
              }

              if (filterValue.behaviourType === BehaviourTypes.DevelopmentArea) {
                return focusArea?.sug
              }

              return false;
            }
          );

        }
      },
      {
        Header: 'Feedback',
        cellClassName: 'wb-break-word',
        accessor: (row) => getFeedbackPreviewText(row, multiQuestionTypeOptions, multiQuestionTypeConstants) || 'None'
      },
      {
        id: 'sentAt',
        Header: 'Sent At',
        accessor: (row) => moment(row.createdAt).format(DATE_TIME_FORMAT),
        sortType: dateSortingFunc,
      }
    ],
    [dateSortingFunc, feedbackMaps, multiQuestionTypeConstants, multiQuestionTypeOptions]
  );
};