import React, { useEffect, useMemo, useCallback } from "react";
import "./TableListManagerView.scss";
import DottedRightArrow from "../SvgReactIcons/DottedRightArrow";
import FilterDropdownIcon from "../SvgReactIcons/filterDropdownIcon";
import { useHistory } from "react-router-dom";
import {
  MeetingStatus,
  oneOnOneDetailListProps,
  OneOnOneStatus,
} from "modules/1-on-1s/types";
import { Cell, useGlobalFilter, useTable } from "react-table";
import { useSortBy } from "react-table"; // Ensure you import useSortBy
import {
  convertDate,
  statusColorPicker,
  statusText,
} from "modules/1-on-1s/utils";

interface TableListManagerViewProps {
  data: oneOnOneDetailListProps[];
  meetingStatus: MeetingStatus;
  globalSearch: string;
}

const TableListManagerView: React.FC<TableListManagerViewProps> = React.memo(
  ({ data, meetingStatus, globalSearch }) => {
    const dataList: oneOnOneDetailListProps[] = useMemo(() => {
      return (data || []).filter((item) => {
        if (meetingStatus === MeetingStatus?.Upcoming) {
          return item?.state !== "closed";
        } else {
          return item?.state === "closed";
        }
      });
    }, [data, meetingStatus]);

    const globalFilterFunction = useCallback(
      (rows: any[], columnIds: string[], filterValue: string) => {
        const searchValue = filterValue.toLowerCase();

        return rows.filter((row) => {
          return columnIds.some((columnId) => {
            const cellValue = row.values[columnId];
            if (!cellValue) return false;
            switch (columnId) {
              case "startAt":
              case "scheduledTimestamp": {
                const formattedDate = cellValue ? convertDate(cellValue) : "";
                return formattedDate?.toLowerCase().includes(searchValue);
              }
              case "state":
                const displayStatus = {
                  [OneOnOneStatus.Preparation]: "Preparation",
                  [OneOnOneStatus.Open]: "Open",
                  [OneOnOneStatus.Session]: "Session",
                  [OneOnOneStatus.Closed]: "Closed",
                };
                const displayValue = (
                  displayStatus[cellValue as OneOnOneStatus] || ""
                ).toLowerCase();
                return displayValue.includes(searchValue);

              default:
                return cellValue.toLowerCase().includes(searchValue);
            }
          });
        });
      },
      []
    );

    const columns = React.useMemo(
      () =>
        [
          {
            Header: "Meeting by",
            accessor: "reviewerName" as const,
          },
          {
            Header: "1-on-1 with",
            accessor: "revieweeName" as const,
          },
          {
            Header: "Scheduled on",
            accessor:
              meetingStatus === MeetingStatus?.Upcoming
                ? ("scheduledTimestamp" as const)
                : ("startAt" as const),
          },
          {
            Header: "Status",
            accessor: "state" as const,
          },
        ] as const,
      [meetingStatus]
    );
    const tableInstance = useTable(
      {
        columns,
        data: dataList,
        initialState: { sortBy: [] },
        globalFilter: globalFilterFunction,
        disableSortRemove: false,
        disableMultiSort: true,
      },
      useGlobalFilter,
      useSortBy
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      setGlobalFilter,
    } = tableInstance;

    useEffect(() => {
      setGlobalFilter(globalSearch);
    }, [globalSearch]);

    const history = useHistory();

    const renderCell = (cell: Cell<oneOnOneDetailListProps>) => {
      switch (cell?.column?.Header) {
        case "Scheduled on":
          return (
            <td {...cell.getCellProps()} title={cell.value}>
              {convertDate(cell?.value)}
            </td>
          );
        case "Status":
          return (
            <td
              {...cell.getCellProps()}
              title={cell.value}
              style={{
                color: statusColorPicker(cell.value),
              }}
            >
              {statusText(cell?.value)}
            </td>
          );
        default:
          return (
            <td {...cell.getCellProps()} title={cell.value}>
              {cell.render("Cell")}
            </td>
          );
      }
    };

    const SortIcon: React.FC<{
      isSorted: boolean;
      isSortedDesc: boolean | undefined;
    }> = ({ isSorted, isSortedDesc }) => {
      if (!isSorted) {
        return null;
      }

      return (
        <FilterDropdownIcon
          height={16}
          width={16}
          style={{
            transform: isSortedDesc ? "rotate(0deg)" : "rotate(180deg)",
            opacity: isSorted ? 1 : 0.3,
          }}
        />
      );
    };

    return (
      <table {...getTableProps()} className="TableListManagerView">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="table-row" {...headerGroup.getHeaderGroupProps()}>
              {/* <th className="checkBox" /> */}
              {headerGroup.headers.map((column) => {
                const toggleSort = (e: React.MouseEvent) => {
                  const clickedColumn = column;
                  if (!clickedColumn.getSortByToggleProps) return;
                  const { isSorted, isSortedDesc } = clickedColumn;
                  if (!isSorted) {
                    clickedColumn.toggleSortBy(false);
                  } else if (isSorted && !isSortedDesc) {
                    clickedColumn.toggleSortBy(true);
                  } else {
                    clickedColumn.clearSortBy();
                  }
                };
                return (
                  <th onClick={toggleSort} {...column.getHeaderProps()}>
                    <div className="header-cell">
                      {column.render("Header")}
                      <SortIcon
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && (
            <tr className="table-row-empty">
              <td
                className="empty-row-cell"
                colSpan={5}
                style={{ textAlign: "center" }}
              >
                {meetingStatus === MeetingStatus.Upcoming
                  ? "No upcoming 1-on-1s scheduled"
                  : "No past 1-on-1s found"}
              </td>
            </tr>
          )}

          {rows.length > 0 &&
            rows?.map((row, index) => {
              prepareRow(row);

              return (
                <tr
                  className="table-row"
                  onClick={() =>
                    history.push(`/one-on-ones/${row?.original?.id}`)
                  }
                  {...row.getRowProps()}
                >
                  {/* <td>
                  <div className="checkBox"></div>
                </td> */}
                  {row.cells.map((cell) => {
                    return renderCell(cell);
                  })}
                  <td className="next-arrow">
                    <div className="arrow-cell">
                      <DottedRightArrow height={26} width={26} />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  },
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.meetingStatus === nextProps.meetingStatus &&
    prevProps.globalSearch === nextProps.globalSearch
);

export default TableListManagerView;
