import { Card, Form } from '@themesberg/react-bootstrap';
import { DataState } from 'components';
import { getActiveReviews } from 'data';
import { IActiveReview, IListReducer, ReviewStatus } from 'interfaces';
import React from 'react';
import { listReducer } from 'reducers';
import { finalize } from 'rxjs';
import { DelimitedArrayParam, StringParam, useQueryParams } from 'use-query-params';

import ReviewsListFilters from './ReviewsListFilters';
import ReviewsListTable from './ReviewsListTable';


interface IReviewsListCardProps { }

const ReviewsListCard: React.FC<IReviewsListCardProps> = () => {

  /* State Hooks */
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reviewsList, dispatchReviewsList] = React.useReducer<IListReducer<IActiveReview>>(
    listReducer, []
  );

  /* Route Hooks */
  const [queryParams] = useQueryParams({
    reviewStatus: DelimitedArrayParam,
    // launchedByFilter: StringParam,
    filterByUserType: DelimitedArrayParam,
    filterByUserTypeIds: DelimitedArrayParam,
    filterLaunchedBy: DelimitedArrayParam
  });

  const {
    reviewStatus: reviewStatusQueryParam,
    // launchedByFilter: launchedByFilterQueryParam,
    filterByUserType: filterByUserTypeQueryParam,
    filterByUserTypeIds: filterByUserTypeIdsQueryParam,
    filterLaunchedBy: filterLaunchedByQueryParam
  } = queryParams

  const prevCountRef = React.useRef(queryParams);

  /* Get the list of reviews based on filters */
  React.useEffect(
    () => {

      if (!reviewStatusQueryParam?.length || !filterLaunchedByQueryParam?.length) {
        dispatchReviewsList({
          type: 'SET',
          payload: []
        });
        setIsLoading(false);
        return;
      }

      if (!filterByUserTypeIdsQueryParam?.length || !filterByUserTypeQueryParam?.length) {
        if (!filterByUserTypeIdsQueryParam?.length && !filterByUserTypeQueryParam?.length) {
          // if both are empty refetch.
        } else if (prevCountRef.current?.reviewStatus === reviewStatusQueryParam) {

          // } else if (prevCountRef.current?.launchedByFilter === launchedByFilterQueryParam && prevCountRef.current?.reviewStatus === reviewStatusQueryParam) {
          return;
        }
      }

      const reviewStatuses = reviewStatusQueryParam
        .filter((status) => !!status) as ReviewStatus[];

      setIsLoading(true);
      const subscription = getActiveReviews(
        reviewStatuses,
        filterLaunchedByQueryParam as string[],
        filterByUserTypeQueryParam as string[],
        filterByUserTypeIdsQueryParam as string[],
      ).pipe(
        finalize(() => setIsLoading(false))
      ).subscribe(
        (response) => {

          dispatchReviewsList({
            type: 'SET',
            payload: response.data.data
          });
        }
      );

      prevCountRef.current = queryParams;
      return () => subscription.unsubscribe();
    },
    [filterLaunchedByQueryParam, reviewStatusQueryParam, filterByUserTypeQueryParam, filterByUserTypeIdsQueryParam]
  );

  return (
    <div className='h-100 overflow-hidden d-flex flex-column'>

      <ReviewsListFilters />
      <div className='flex-grow-1 position-relative overflow-y-auto'>
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!(reviewsList.length && reviewStatusQueryParam?.length)}
          emptyStateMessage={
            !reviewStatusQueryParam?.length
              ?
              <>Please select a status to view the reviews!</>
              :
              <>No reviews found. <br /> Launch a new one!</>
          }
        >
          <Card border="light" className="shadow-sm overflow-hidden h-100">
            <Card.Body className="py-0 mt-3 position-relative h-100 overflow-y-auto">
              <ReviewsListTable
                reviewsList={reviewsList}
                onReviewDelete={
                  (review) => dispatchReviewsList({
                    type: 'DELETE',
                    payload: review
                  })
                } />
            </Card.Body>
          </Card>
        </DataState>
      </div>
    </div>
  );
};

export default ReviewsListCard;