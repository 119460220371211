import { FormField } from 'components';
import { MetaDataContext } from 'contexts';
import { useApp } from 'hooks';
import {
  FormFieldTypeConstants,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IAnswer,
  ISimpleMenuOption,
} from 'interfaces';
import React from 'react';
import { toHTML } from 'slack-markdown';
import { MULTI_SELECT_QUESTION_NA_OPTION } from 'teamble-constants';
import KPIWidget from './deloitte/KPIWidget';
import { Form } from '@themesberg/react-bootstrap';


interface IRevieweeFormQuestionProps {
  answer: IAnswer;
  type?: string;
  index?: number;
  readonly?: boolean;
  multiCampaign?: boolean;
}


const RevieweeFormTitleQuestion: React.FC<IRevieweeFormQuestionProps> = (
  { answer }
) => {
  return (
    <>
      {
        answer.text
          ?
          <p style={{ wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: toHTML(answer.text, { hrefTarget: '_blank' }) }}></p>
          :
          null
      }
    </>
  )

};


const RevieweeFormTextQuestion: React.FC<IRevieweeFormQuestionProps> = (
  { answer, index }
) => {

  return (
    <FormField
      label={`${answer.label} ${answer.required ? '' : '(optional)'}`}
      controlId={`textQuestion-${answer.id}`}
      type={answer.type === 'short-text' ? FormFieldTypeConstants.Text : FormFieldTypeConstants.Textarea}
      controlName={`answers[${index}].value`}
      placeholder={answer.placeholder}
      textValueChangeDebounceTime={350} />
  )

};


export const RevieweeFormCompetencyQuestionDescription: React.FunctionComponent<IRevieweeFormQuestionProps> = (
  { answer }
) => {


  return (
    <>
      {
        !answer.isBehavior
          ?
          <>
            {
              answer.showDescription && answer.description
                ?
                <p> {answer.description} </p>
                :
                null
            }
            {
              answer.showBehaviours && answer.behaviours?.length
                ?
                <ul className='ps-3'>
                  {
                    answer.behaviours.map(
                      (beh) => (
                        <li key={beh?.id}>{beh?.name}</li>
                      )
                    )
                  }
                </ul>
                :
                null
            }
          </>
          :
          null
      }
    </>
  );

}

export const RevieweeFormQuestionDescription: React.FunctionComponent<IRevieweeFormQuestionProps> = (
  { answer, index, readonly }
) => {

  let descriptionComponent = null;

  switch (answer.type) {
    case 'competency-question':
      if (answer.showBehaviours && answer.behaviours?.length) {
        descriptionComponent = (
          <ul className='ps-3'>
            {
              answer.behaviours.map(
                (beh) => (
                  <li key={beh?.id}>{beh?.name}</li>
                )
              )
            }
          </ul>
        )
      }
      break;

    case 'objective-question':
      // if (answer.showKPIs && answer.kpis?.length) {

      //   let role = '';

      //   if (answer.objectiveName) {
      //     role += answer.objectiveName;
      //     if ((answer as any).weight) {
      //       role += ` | ${(answer as any).weight}%`;
      //     }
      //   }

      //   descriptionComponent = (
      //     <>
      //       <KPIWidget
      //         role={role}
      //         docType={(answer as any).docType}
      //         kpis={answer.kpis} index={index || 0}
      //         readonly={readonly}
      //       />
      //       {
      //         (answer.dropdown || answer?.selectedView?.value === 'dropdown') && (
      //           <Form.Label className='ws-pre-line mr-2'> Score: </Form.Label>
      //         )
      //       }
      //     </>
      //   )
      // }
      break;

    default:

      if (answer.showDescription && answer.description) {
        descriptionComponent = (<p> {answer.description} </p>)
      }
      break;
  }

  return (descriptionComponent);

}

const RevieweeFormQuestionComments: React.FunctionComponent<IRevieweeFormQuestionProps> = (
  { answer, index }
) => {

  let commentComponent = null;

  switch (answer.type) {

    case 'objective-question':
      if (answer.commentBox && answer.comments?.length) {
        commentComponent = (
          <>
            {
              answer.comments.map((comment, commentIndex) => {
                return (
                  <FormField
                    key={comment.id}
                    label={comment.title || "Comment:"}
                    placeholder={comment.title}
                    controlId={`multiSelectQuestionComment-${comment.id}`}
                    type={FormFieldTypeConstants.Textarea}
                    controlName={`answers[${index}].comments[${commentIndex}].value`}
                    textValueChangeDebounceTime={350}
                  />
                )
              })
            }
          </>
        )
      }
      break;

    default:
      if (answer.comment) {
        commentComponent = (
          <FormField
            label='Comment'
            placeholder={answer.comment}
            controlId={`multiSelectQuestionComment-${answer.id}`}
            type={FormFieldTypeConstants.Textarea}
            controlName={`answers[${index}].commentValue`}
            textValueChangeDebounceTime={350}
          />
        )
      }
      break;
  }

  return (commentComponent);

}

const RevieweeFormMultiSelectQuestion: React.FC<IRevieweeFormQuestionProps> = ({
  index,
  answer,
  multiCampaign
}) => {

  const [choices, setChoices] = React.useState<ISimpleMenuOption[]>([]);
  const isMatrix = answer?.selectedView?.value === 'matrix' || false;
  const isRadio = answer?.selectedView?.value === 'radio';
  const app = useApp();

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const multiSelectChoicesConstants: IMultiSelectQuestionOptionsTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;


  React.useEffect(
    () => {

      const isNotApplicableOption = (answer.type === 'competency-question' || answer.type === 'objective-question') && answer.isNotApplicableOption;

      if (answer.family === multiSelectChoicesConstants.CUSTOM_ITEMS) {

        const options: ISimpleMenuOption[] = answer.choices!.map(
          (choice) => ({
            label: choice,
            value: (answer as any).choicesValues ? (answer as any).choicesValues[choice] : choice
          })
        );

        setChoices(
          isNotApplicableOption
            ?
            options
              ?
              [...options, MULTI_SELECT_QUESTION_NA_OPTION]
              :
              []
            :
            options
        );

      } else {

        const options = metadata.multiSelectQuestionOptionsTypes.options.find(
          (option) => option.value === answer.family
        );
        if (options?.options) {
          setChoices(
            isNotApplicableOption
              ?
              [...options.options, MULTI_SELECT_QUESTION_NA_OPTION]
              :
              options.options
          );
        }

      }

    },
    [
      answer.choices, answer.family, answer.isNotApplicableOption, answer.type, app,
      metadata.multiSelectQuestionOptionsTypes.options, multiSelectChoicesConstants.CUSTOM_ITEMS
    ]
  );

  let labelDropdown = '';
  let labelNonDropdown = '';

  switch (answer.type) {
    case 'competency-question':

      labelDropdown = `${answer.label}: ${answer?.behaviorTitle || answer?.competencyName || ''} ${!isMatrix ? (answer.required ? '' : '(optional)') : ''}`;
      labelNonDropdown = `${isRadio ? `${answer?.label}:` : ''} ${(answer?.behaviorTitle || answer?.competencyName) || `${answer.label}:`} ${!isMatrix ? (answer.required ? '' : '(optional)') : ''}`;
      break;

    case 'objective-question':
      labelDropdown = `${answer.label}: ${answer?.kpiName || answer?.objectiveName || ''} ${!isMatrix ? (answer.required ? '' : '(optional)') : ''}`;
      labelNonDropdown = `${isRadio ? `${answer?.label}:` : ''} ${(answer?.kpiName || answer?.objectiveName) || `${answer.label}:`} ${!isMatrix ? (answer.required ? '' : '(optional)') : ''}`;
      break;

    default:

      labelDropdown = `${answer.label} ${!isMatrix ? answer.required ? '' : '(optional)' : ''}`;
      labelNonDropdown = `${isRadio ? `${answer?.label}:` : ''} ${answer.label} ${!isMatrix ? answer.required ? '' : '(optional)' : ''}`
      break;
  }

  let kpiDescriptionComponent = null;

  if (answer.showKPIs && answer.kpis?.length) {

    let role = '';

    if (answer.objectiveName) {
      role += answer.objectiveName;
      if ((answer as any).weight) {
        role += ` | ${(answer as any).weight}%`;
      }
    }

    kpiDescriptionComponent = (
      <KPIWidget
        role={role}
        docType={(answer as any).docType}
        kpis={answer.kpis} index={index || 0}
        readonly={false}
      />
    )
  }

  if (!choices.length) {
    return null;
  }

  return (
    <>
      {
        (answer.dropdown || answer?.selectedView?.value === 'dropdown') ? (
          <>
            {
              answer.type === "objective-question" && (
                <>
                  {kpiDescriptionComponent}
                  {
                    multiCampaign && (
                      <>
                        {Boolean(index) && (<hr />)}
                        <h5> Your Review:</h5>
                      </>
                    )
                  }
                  <Form.Label className='ws-pre-line mr-2'> Score: </Form.Label>
                </>
              )
            }
            <FormField
              // label={labelDropdown}
              description={<RevieweeFormQuestionDescription answer={answer} index={index} readonly={false} />}
              controlId={`multiSelectQuestion-${answer.id}`}
              type={FormFieldTypeConstants.Select}
              controlName={`answers[${index}].multiSelectValue`}
              placeholder={answer.type === "objective-question" ? 'Score' : 'Pick an option'}
              options={choices}
            />
          </>
        ) : (
          <FormField
            label={labelNonDropdown}
            description={<RevieweeFormQuestionDescription answer={answer} index={index} readonly={false} />}
            type={FormFieldTypeConstants.Radio}
            controlName={`answers[${index}].value`}
            controlId={`revieweeFormMultiSelectQuestion${answer.id}`}
            options={choices}
            isRadioMatrixView={isMatrix}
          />
        )
      }

      <RevieweeFormQuestionComments answer={answer} index={index} />
    </>
  )

};


const RevieweeFormQuestion: React.FC<IRevieweeFormQuestionProps> = ({
  index,
  answer,
  multiCampaign
}) => {

  let QuestionComponent = null;

  switch (answer.type) {

    case 'title':
    case 'sub-title': {
      QuestionComponent = RevieweeFormTitleQuestion;
      break;
    }

    case 'short-text':
    case 'long-text': {
      QuestionComponent = RevieweeFormTextQuestion;
      break;
    }

    case 'choice':
    case 'objective-question':
    case 'competency-question': {
      QuestionComponent = RevieweeFormMultiSelectQuestion;
      break;
    }

  }

  return QuestionComponent
    ?
    <QuestionComponent
      index={index}
      answer={answer}
      type={answer.type}
      multiCampaign={multiCampaign}
    />
    :
    null;
};

export default RevieweeFormQuestion;