import { Button, Card, Form, Modal } from '@themesberg/react-bootstrap';
import { ConfirmationModal, CustomDropdown, CustomSelect, FormField, SelectUserPairsField } from 'components';
import { addUsersToActiveReview, changeReviewerAudiencePair, getReportsByPerformanceReviewIds, removeRevieweePairs, reopenReviewAudiencePair } from 'data';
import { useApp, useCompanyUsersMemberOptions } from 'hooks';
import {
  FormFieldTypeConstants,
  IActiveReview,
  IActiveReviewAudience,
  IMemberOption,
  IMenuOption,
  ISimpleMenuOption,
  ReviewStatus,
  SignInWith,
} from 'interfaces';
import * as React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';

import {
  getDistinctDuplicateAudiencePairs,
  getRequestDataOfUpdateUsersInActiveReview,
} from '../utils/utils';
import ActiveReviewAudienceTable from './ActiveReviewAudienceTable';
import ReviewDetailsCardHeader from './ReviewDetailsCardHeader';
import downloadjs from 'downloadjs';
import { FormContext } from 'contexts';
import { SEND_REMINDER_AS_OPTIONS } from 'teamble-constants';
import { useFormik } from 'formik';
import { REVIEWER_CHANGE_AUDIENCE_SCHEMA } from 'validation-schemas';


interface IReviewDetailsCardProps {
  activeReview: IActiveReview;
  onActiveReviewUpdate: () => void;
}


const ReviewDetailsCard: React.FunctionComponent<IReviewDetailsCardProps> = (
  { activeReview, onActiveReviewUpdate }
) => {

  /* State Hooks */
  const [audienceToRemove, setAudienceToRemove] = React.useState<IActiveReviewAudience | any>([]);
  const [isAddingAudiencePairs, setIsAddingAudiencePairs] = React.useState<boolean>(false);
  const [showAudienceRemoveConfirmationModal, setShowAudienceRemoveConfirmationModal] = React.useState<boolean>(false);
  const [reviewReopen, setReviewReopen] = React.useState<IActiveReviewAudience | null>(null);
  const [reviewerChange, setReviewerChange] = React.useState<IActiveReviewAudience | null>(null);

  /* App hook */
  const app = useApp();


  /* Add new audience pairs */
  const addSelectedPairsToAudienceList = (
    reviewees: IMenuOption[] | null,
    reviewers: IMenuOption[] | null
  ): void => {

    const newAudiencePairs: Partial<IActiveReviewAudience>[] = [];

    if (reviewees?.length && reviewers?.length) {

      reviewees.forEach(reviewee => {
        reviewers.forEach(reviewer => {
          newAudiencePairs.push(
            {
              reviewee,
              reviewer
            }
          );
        })
      });
    }

    const distinctPairs = getDistinctDuplicateAudiencePairs(
      newAudiencePairs,
      activeReview.reviewerRevieweeInfos
    );

    if (distinctPairs?.length) {

      setIsAddingAudiencePairs(true);
      const requestData = getRequestDataOfUpdateUsersInActiveReview(distinctPairs);

      addUsersToActiveReview(
        app,
        requestData,
        app === SignInWith.Slack ? activeReview.workspaceId : activeReview.channelId,
        activeReview.id
      ).pipe(
        finalize(
          () => setIsAddingAudiencePairs(false)
        )
      ).subscribe(
        () => {
          onActiveReviewUpdate();
        }
      );
    }

  };


  /* Remove the selected audience pairs */
  const removeAudiencePair = (
    audience: IActiveReviewAudience
  ): void => {

    const perfReviewIds = [audience.id];
    removeRevieweePairs(perfReviewIds, activeReview?.id).pipe(
      finalize(
        () => {
          setShowAudienceRemoveConfirmationModal(false);
          setAudienceToRemove([]);
          onActiveReviewUpdate();
        }
      )
    ).subscribe(
      (response) => {
        toast.success(response.data.message);
      }
    )
  };


  /* Reopen the review of a particular audience pair */
  const handleAudiencePairReopen = (
    audience: IActiveReviewAudience
  ): void => {
    reopenReviewAudiencePair(audience.id).subscribe(
      (response) => {
        onActiveReviewUpdate();
        toast.success(response.data.message);
      }
    )
  };

  const downloadReport = (reviewId: string): void => {
    toast.success("Downloading Report");
    getReportsByPerformanceReviewIds([reviewId], true).subscribe(
      (response) => {
        // downloadjs(response.data as Blob, 'report.pdf', 'application/pdf');
        toast.success("Report will be emailed once completed")
      }
    );
  };

  return (
    <>
      <Card style={{ height: '95%' }} className='overflow-hidden flex-grow-1'>
        <ReviewDetailsCardHeader activeReview={activeReview} />
        <Card.Body className='overflow-y-auto'>
          {
            activeReview.state !== 'Closed' &&
            <div className='mb-3 position-relative z-index-2'>
              <SelectUserPairsField
                firstSelectTitle='Reviewees'
                secondSelectTitle='Reviewers'
                state={activeReview.state}
                organizationId={
                  app === SignInWith.Slack ?
                    activeReview.workspaceId :
                    activeReview.channelId
                }
                isLoading={isAddingAudiencePairs}
                onAddToListClick={addSelectedPairsToAudienceList}
              />
            </div>
          }

          <div className='position-relative z-index-1'>
            <ActiveReviewAudienceTable
              activeReview={activeReview}
              reviewerRevieweeInfos={activeReview.reviewerRevieweeInfos}
              isReviewClosed={activeReview.state === 'Closed'}
              onRemoveAudiencePair={(audience) => {
                if (activeReview?.state === ReviewStatus.Live) {
                  setAudienceToRemove(audience);
                  setShowAudienceRemoveConfirmationModal(true)
                } else {
                  removeAudiencePair(audience);
                }
              }}
              downloadReport={downloadReport}
              onAudiencePairReopen={(audience) => setReviewReopen(audience)}
              onReviewerChange={(audience) => setReviewerChange(audience)}
            />
          </div>
        </Card.Body>
      </Card>

      {
        showAudienceRemoveConfirmationModal &&
        <ConfirmationModal
          title='Delete Reviewee Pair'
          show={showAudienceRemoveConfirmationModal}
          onPositiveResponse={() => removeAudiencePair(audienceToRemove)}
          onCloseClick={() => {
            setShowAudienceRemoveConfirmationModal(false);
            setAudienceToRemove([]);
          }}
        >
          <p>Are you sure to delete the Reviewee Pair?</p>
          <p><strong>Reviewee:</strong> {audienceToRemove.reviewee.name}</p>
          <p><strong>Reviewer:</strong> {audienceToRemove.reviewer.name || "Awaiting reviewer"}</p>
        </ConfirmationModal>
      }

      {
        reviewReopen &&
        <ConfirmationModal
          title='Reopen pair ?'
          show={Boolean(reviewReopen)}
          onPositiveResponse={() => {
            handleAudiencePairReopen(reviewReopen)
          }}
          onCloseClick={() => {
            setReviewReopen(null);
          }}
        >
          <>
          </>
          <p>Are you sure to Reopen the Reviewee Pair?</p>
          <p><strong>Reviewee:</strong> {reviewReopen.reviewee.name}</p>
          <p><strong>Reviewer:</strong> {reviewReopen.reviewer.name || "Awating"}</p>
        </ConfirmationModal>
      }

      {
        reviewerChange &&
        <ChangeReviewerModal
          audience={reviewerChange}
          setReviewerChange={setReviewerChange}
          onActiveReviewUpdate={onActiveReviewUpdate}
        />
      }

    </>
  )
};



const ChangeReviewerModal: React.FunctionComponent<{
  audience: IActiveReviewAudience,
  onActiveReviewUpdate: () => void;
  setReviewerChange: React.Dispatch<React.SetStateAction<IActiveReviewAudience | null>>
}> = ({
  audience,
  setReviewerChange,
  onActiveReviewUpdate,
}) => {

    const { isLoading, memberOptions } = useCompanyUsersMemberOptions();

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const handleReviewerChange = (
      audience: IActiveReviewAudience,
      reviewerId: string,
      retainValues = false
    ): void => {
      changeReviewerAudiencePair(audience.id, reviewerId, retainValues).subscribe(
        (response) => {
          onActiveReviewUpdate();
          toast.success(response.data.message);
        }
      )
    };

    const newReviewerForm = useFormik<{
      reviewer: ISimpleMenuOption | null,
      retainValues: false
    }>({
      initialValues: {
        retainValues: false,
        reviewer: null
      },
      validationSchema: REVIEWER_CHANGE_AUDIENCE_SCHEMA,
      onSubmit: (values) => {
        if (!values.reviewer) return;
        handleReviewerChange(audience, values.reviewer.value, values.retainValues)
      }
    });

    return (
      <>
        {
          !showConfirmation && (
            <Modal
              centered
              as={Modal.Dialog}
              show={Boolean(audience)}
              onHide={() => {
                setReviewerChange(null);
                setShowConfirmation(false);
              }}
            >

              <Modal.Header closeButton >
                <Modal.Title>Assign New Reviewer</Modal.Title>
              </Modal.Header>

              <FormContext.Provider value={newReviewerForm}>

                <Form>

                  <Modal.Body>
                    <p>Current Pair:-</p>
                    <p><strong>Reviewee:</strong> {audience.reviewee.name}</p>
                    <p><strong>Reviewer:</strong> {audience.reviewer.name}</p>

                    <FormField
                      size='sm'
                      controlId='reviewer'
                      controlName='reviewer'
                      isLoading={isLoading}
                      options={memberOptions}
                      label='Select new reviewer'
                      placeholder='Select reviewer...'
                      type={FormFieldTypeConstants.Select}
                    />

                    {
                      audience.state === "started" && (
                        <>
                          <p>
                            {audience.reviewer.name} already started this review.
                            do you want to keep {audience.reviewer.name}’s draft review and start this as a new review?
                          </p>
                          <FormField
                            controlId='retainValues'
                            controlName='retainValues'
                            label='Save draft review'
                            type={FormFieldTypeConstants.Checkbox}
                          />
                        </>
                      )
                    }

                  </Modal.Body>

                </Form>
              </FormContext.Provider>

              <Modal.Footer className='d-flex justify-content-end'>

                <Button type='button' variant="outline-primary" onClick={() => setReviewerChange(null)}>
                  Close
                </Button>

                <Button type='button' variant="outline-primary" onClick={() => {
                  if (newReviewerForm.values.reviewer) {
                    setShowConfirmation(true);
                  } else {
                    newReviewerForm.setFieldTouched('reviewer', true);
                    newReviewerForm.setErrors({ ...newReviewerForm.errors, reviewer: "Required" })
                  }
                }}>
                  Submit
                </Button>


              </Modal.Footer>

            </Modal>
          )
        }
        {
          showConfirmation && (
            <ConfirmationModal
              title='Change Reviewer'
              show={showConfirmation}
              onPositiveResponse={() => {
                newReviewerForm.handleSubmit();
                setReviewerChange(null);
                setShowConfirmation(false);
              }}
              onCloseClick={() => {
                setShowConfirmation(false);
              }}
            >
              <p>Are you sure you want to change reviewer?</p>
              <p>Updated pair:</p>
              <p><strong>Reviewee:</strong> {audience.reviewee.name}</p>
              <p><strong>Reviewer:</strong> {newReviewerForm.values.reviewer?.label}</p>
            </ConfirmationModal>
          )
        }
      </>
    )
  }



export default ReviewDetailsCard;