import {
  faChevronDown,
  faChevronUp,
  faEye,
  faShareSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse, FormCheck, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { ConfirmationModal, useUserProfile } from 'components';
import { useApp } from 'hooks';
import { IReportTableRow, SignInWith } from 'interfaces';
import { AccordionData } from 'modules/reviews/context/AccordionData.context';
import * as React from 'react';

import ShareReportModal from '../modals/ShareReportModal';
import { getPerformanceReviewIdsOfReportRows } from '../utils';
import { ReviewReportsPreviewModal } from '@common/components';
import { downloadBulkReports } from 'data';
import { toast } from 'react-toastify';

interface IReportsTableProps {
  reportTableRows: IReportTableRow[];
  isShare?: boolean;
  isPreviewModal?: boolean;
}

interface TableRowProps {
  reportTableRow: IReportTableRow;
  isCollapsed?: boolean;
  isCollapsable?: boolean;
  onCollapseToggleClick?: () => void;
  isShare?: boolean;
  isPreviewModal?: boolean;
  isCheckedMap?: any
  setBulkShareCheckBox: (reportRow: IReportTableRow) => void,
  bulkShare: IReportTableRow[],
  setBulkShare: React.Dispatch<React.SetStateAction<IReportTableRow[]>>
}

type SecondLevelCollapse = {
  [key: string]: boolean;
}


const TableRow: React.FunctionComponent<TableRowProps> = ({
  reportTableRow,
  isCollapsed,
  isCollapsable = true,
  onCollapseToggleClick,
  isShare = true,
  isPreviewModal = true,
  setBulkShareCheckBox,
  isCheckedMap
}) => {
  const { setSelectedPreviewRow } = React.useContext(AccordionData);

  const [showPreviewModal, setShowPreviewModal] = React.useState<boolean>(false);
  const [showShareReportModal, setShowShareReportModal] = React.useState<boolean>(false);
  const app = useApp();

  return (
    <>
      <div className='d-flex align-items-center border py-2'>

        <div className='mx-3 flex-shrink-0' style={{ width: 30 }}>
          {
            isCollapsable &&
            <OverlayTrigger
              overlay={
                <Tooltip id={`${reportTableRow.id}CollapseExpand`}>
                  {isCollapsed ? 'Expand' : 'Collapse'}
                </Tooltip>
              }
            >
              <Button
                type='button'
                variant='link'
                size='sm'
                onClick={onCollapseToggleClick}
              >
                <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
              </Button>
            </OverlayTrigger>
          }
        </div>

        {
          isShare && (
            <FormCheck type="checkbox" className="d-flex" >
              <FormCheck.Input
                id={reportTableRow.id}
                checked={Boolean(isCheckedMap[reportTableRow.uniqueId])}
                onChange={() => setBulkShareCheckBox(reportTableRow)}
              />
            </FormCheck>
          )
        }

        <div className='mx-3 flex-grow-1'> {`${reportTableRow.type}: ${reportTableRow.name}`} </div>

        {isShare && <div
          className='me-3 flex-shrink-0 cursor-pointer'
          style={{ width: 30 }}
          onClick={() => setShowShareReportModal(true)}
        >
          <OverlayTrigger
            overlay={
              <Tooltip id={`${reportTableRow.id}ShareReport`}>
                Share Report
              </Tooltip>
            }
          >
            <Button
              type='button'
              variant='link'
              size='sm'
              onClick={() => { }}
            >
              <FontAwesomeIcon icon={faShareSquare} />
            </Button>
          </OverlayTrigger>
        </div>
        }
        <div
          className='me-3 flex-shrink-0 cursor-pointer'
          style={{ width: 30 }}
          onClick={() => { isPreviewModal ? setShowPreviewModal(true) : setSelectedPreviewRow(reportTableRow) }}
        >
          <OverlayTrigger
            overlay={
              <Tooltip id={`${reportTableRow.id}Preview`}>
                Preview
              </Tooltip>
            }
          >
            <Button
              type='button'
              variant='link'
              size='sm'
              onClick={() => { }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </OverlayTrigger>
        </div>

      </div>
      {
        showPreviewModal &&
        <ReviewReportsPreviewModal
          showDownloadAction
          show={showPreviewModal}
          reviewIds={getPerformanceReviewIdsOfReportRows(reportTableRow)}
          onClose={() => setShowPreviewModal(false)} />
      }
      {
        showShareReportModal &&
        <ShareReportModal
          show={showShareReportModal}
          reportTableRow={reportTableRow}
          onClose={() => setShowShareReportModal(false)} />
      }
    </>
  )
};


const ReportsTable: React.FunctionComponent<IReportsTableProps> = (
  { reportTableRows, isShare = true, isPreviewModal = true }
) => {

  const [firstLevelCollapse, setFirstLevelCollapse] = React.useState<boolean[]>([]);
  const [secondLevelCollapse, setSecondLevelCollapse] = React.useState<SecondLevelCollapse>({});

  const [bulkShare, setBulkShare] = React.useState<IReportTableRow[]>([]);
  const [showShareReportModal, setShowShareReportModal] = React.useState<boolean>(false);
  const [isCheckedMap, setIsCheckedMap] = React.useState<any>({});

  const [bulkCheck, setBulkCheck] = React.useState(false);
  const [confirmDownload, setConfirmDownload] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   console.log(Object.keys(isCheckedMap).length, bulkShare.length)
  //   if (Object.keys(isCheckedMap).length && Object.keys(isCheckedMap).length !== reportTableRows.length) {
  //     setBulkCheck(false);
  //   }
  // }, [isCheckedMap])

  const { userProfile } = useUserProfile();
  const app = useApp();

  React.useEffect(
    () => {
      if (reportTableRows && reportTableRows.length) {

        const firstLevelCollapseCopy: boolean[] = [];
        const secondLevelCollapseCopy: SecondLevelCollapse = {};

        reportTableRows.forEach(
          (report) => {

            firstLevelCollapseCopy.push(true);

            report.children.forEach(
              (child) => {
                secondLevelCollapseCopy[`${report.id}${child.id}`] = true;
              }
            );

          }
        );

        setFirstLevelCollapse(firstLevelCollapseCopy);
        setSecondLevelCollapse(secondLevelCollapseCopy);

      }
    },
    [reportTableRows]
  );

  const setBulkShareCheckBox = (reportRow: IReportTableRow) => {

    const updatedArray = [...bulkShare];
    const checkedMap = { ...isCheckedMap };

    const indexOfObject = updatedArray.findIndex(row => {
      return row.id === reportRow.id;
    });

    if (indexOfObject >= 0) {
      // found: then remove.
      delete checkedMap[reportRow.uniqueId]
      updatedArray.splice(indexOfObject, 1);
    } else {
      checkedMap[reportRow.uniqueId] = true;
      updatedArray.push(reportRow);
    }

    setIsCheckedMap(checkedMap);
    setBulkShare(updatedArray);
  }

  const checkAll = () => {
    // const
    if (bulkCheck) {
      setIsCheckedMap({});
      setBulkShare([]);
      setBulkCheck(false);
    } else {
      const keys: any = {};
      reportTableRows.forEach(data => {
        keys[data.uniqueId] = true;
      });
      setIsCheckedMap(keys);
      setBulkShare(reportTableRows);
      setBulkCheck(true);
    }

  }

  const getBulkShareIds = () => {

    let performanceReviewIds: string[] = [];

    bulkShare.forEach((reportTableRow, index) => {
      performanceReviewIds = [...performanceReviewIds, ...getPerformanceReviewIdsOfReportRows(reportTableRow)];
      performanceReviewIds = [...new Set(performanceReviewIds)];
    });

    return performanceReviewIds;
  }

  const dispatchBulkReportDownloads = (): void => {

    const perfReviewIds = getBulkShareIds();
    if (!perfReviewIds.length) return;

    downloadBulkReports(perfReviewIds).subscribe();
    toast.success("Report(s) will be emailed once completed");
  };

  return (
    <>
      {
        isShare && (
          <Card.Header style={{ zIndex: 10 }}>

            <div style={{ justifyContent: "space-between" }} className='d-flex align-items-center flex-shrink-0 position-relative px-0 pt-0'>

              <FormCheck type="checkbox" className="d-flex" >
                <FormCheck.Input
                  checked={bulkCheck}
                  onChange={checkAll}
                />
                <FormCheck.Label className='ms-1'>Select All</FormCheck.Label>
              </FormCheck>

              {
                Boolean(bulkShare.length) && (
                  <div>
                    <Button
                      className='ms-2'
                      onClick={() => setConfirmDownload(true)}
                    >
                      Bulk Download
                    </Button>

                    <Button
                      className='ms-2'
                      onClick={() => setShowShareReportModal(true)}
                    >
                      Bulk Share
                    </Button>
                  </div>
                )
              }

            </div>

          </Card.Header>
        )
      }
      {
        !!reportTableRows.length && !!firstLevelCollapse.length &&
        <div className="table-centered table-nowrap mb-0 position-relative border rounded">
          {
            reportTableRows.map(
              (reportRow, index: number) => {
                return (
                  <div className='bg-gray-500' key={reportRow.id}>

                    <TableRow
                      isPreviewModal={isPreviewModal}
                      isShare={isShare}
                      reportTableRow={reportRow}
                      isCollapsed={firstLevelCollapse[index]}
                      setBulkShareCheckBox={setBulkShareCheckBox}
                      bulkShare={bulkShare}
                      setBulkShare={setBulkShare}
                      isCheckedMap={isCheckedMap}
                      onCollapseToggleClick={
                        () => {
                          firstLevelCollapse[index] = !firstLevelCollapse[index];
                          setFirstLevelCollapse([...firstLevelCollapse]);
                        }
                      } />

                    <Collapse in={!firstLevelCollapse[index]}>
                      <div>
                        {
                          reportRow.children.map(
                            (secondLevelReportRow) => {
                              return (
                                <div key={secondLevelReportRow.id} className='bg-gray-300'>

                                  <TableRow
                                    isPreviewModal={isPreviewModal}
                                    isShare={isShare}
                                    reportTableRow={secondLevelReportRow}
                                    isCollapsable={
                                      app === SignInWith.MsTeams ||
                                      (userProfile?.isSuperAdmin || userProfile?.isTeamAdmin) ||
                                      secondLevelReportRow.anonymity !== 'anonymous'
                                    }
                                    setBulkShareCheckBox={setBulkShareCheckBox}
                                    bulkShare={bulkShare}
                                    isCheckedMap={isCheckedMap}
                                    setBulkShare={setBulkShare}
                                    isCollapsed={secondLevelCollapse[`${reportRow.id}${secondLevelReportRow.id}`]}
                                    onCollapseToggleClick={
                                      () => {
                                        secondLevelCollapse[`${reportRow.id}${secondLevelReportRow.id}`] = !secondLevelCollapse[`${reportRow.id}${secondLevelReportRow.id}`];
                                        setSecondLevelCollapse({ ...secondLevelCollapse });
                                      }
                                    } />

                                  {
                                    app === SignInWith.MsTeams ||
                                      (userProfile?.isSuperAdmin || userProfile?.isTeamAdmin) ||
                                      secondLevelReportRow.anonymity !== 'anonymous'
                                      ?
                                      <Collapse in={!secondLevelCollapse[`${reportRow.id}${secondLevelReportRow.id}`]}>
                                        <div>
                                          {
                                            secondLevelReportRow.children.map(
                                              (thirdLevelReport) => {
                                                return (
                                                  <div key={thirdLevelReport.id} className='bg-white'>
                                                    <TableRow
                                                      isPreviewModal={isPreviewModal}
                                                      isShare={isShare}
                                                      reportTableRow={thirdLevelReport}
                                                      isCollapsable={false}
                                                      setBulkShareCheckBox={setBulkShareCheckBox}
                                                      bulkShare={bulkShare}
                                                      isCheckedMap={isCheckedMap}
                                                      setBulkShare={setBulkShare}
                                                    />
                                                  </div>

                                                );
                                              }
                                            )
                                          }
                                        </div>
                                      </Collapse>
                                      :
                                      null
                                  }

                                </div>
                              );
                            }
                          )
                        }
                      </div>
                    </Collapse>
                  </div>
                );
              }
            )
          }
        </div>
      }
      {
        showShareReportModal && (
          <ShareReportModal
            show={showShareReportModal}
            reportTableRow={undefined as unknown as IReportTableRow}
            bulkReportIds={getBulkShareIds()}
            onClose={() => setShowShareReportModal(false)}
          />
        )
      }

      {
        confirmDownload &&
        <ConfirmationModal
          title='Confirm bulk download?'
          show={confirmDownload}
          onPositiveResponse={() => dispatchBulkReportDownloads()}
          onCloseClick={() => setConfirmDownload(false)}
        >
          <p>
            Are you sure to download the bulk report(s) ?
          </p>
        </ConfirmationModal >
      }
    </>
  );

};

export default ReportsTable;