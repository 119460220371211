import { Button } from '@themesberg/react-bootstrap';
import * as React from 'react';

interface ISubmitButtonProps {
  text: string;
  disabled?: boolean;
  className?: string;
  isSubmitting?: boolean;
  submittingText?: string;
}

const SubmitButton: React.FunctionComponent<ISubmitButtonProps> = (
  {
    className, isSubmitting, text,
    submittingText, disabled = false
  }
) => {

  return (
    <Button
      type='submit'
      variant='primary'
      className={className}
      disabled={disabled || isSubmitting}
    >
      {isSubmitting ? submittingText : text}
    </Button>
  );
};

export default SubmitButton;
