import { Button, Form } from '@themesberg/react-bootstrap';
import { CustomSelect } from 'components';
import { useTeamMemberMenuOptions } from 'hooks';
import { IMenuOption } from 'interfaces';
import * as React from 'react';
import { SELECT_ALL_OPTION } from 'teamble-constants';

interface ISelectAudienceFieldProps {
  channelOrWorkspaceId: string;
  onAddToListClick: (reviewees: IMenuOption[]) => void;
}

const SelectAudienceField: React.FunctionComponent<ISelectAudienceFieldProps> = (
  { channelOrWorkspaceId, onAddToListClick, }
) => {

  const { isLoading, memberOptions } = useTeamMemberMenuOptions(channelOrWorkspaceId);
  const [selectedReviewees, setSelectedReviewees] = React.useState<IMenuOption[]>([]);


  React.useEffect(
    () => {
      setSelectedReviewees([]);
    },
    [channelOrWorkspaceId]
  );


  const addSelectedPairsToAudienceList = (): void => {
    setSelectedReviewees([]);
    onAddToListClick(
      selectedReviewees.filter(({ value }) => value !== SELECT_ALL_OPTION.value),
    );
  };


  return (
    <div className='d-flex align-items-start justify-content-between mt-2'>
      <div className='d-flex flex-grow-1' style={{ marginInlineEnd: 15 }}>

        {
          !isLoading &&
          <div className='flex-grow-1 mr-3 w-50'>
            <Form.Label> Reviewees  </Form.Label>
            <CustomSelect
              isLoading={isLoading}
              isMulti
              optionType='user'
              isSearchable={true}
              allowSelectAll
              options={[SELECT_ALL_OPTION, ...memberOptions]}
              value={selectedReviewees as IMenuOption[]}
              onChange={
                (reviewees) => {
                  setSelectedReviewees(reviewees as (IMenuOption[]));
                }
              }
            />
          </div>
        }

      </div>
      <Button
        type='button'
        className='flex-shrink-0 mt-md'
        disabled={!selectedReviewees?.length || isLoading}
        onClick={addSelectedPairsToAudienceList}
      >
        Add to List
      </Button>
    </div>
  );
};

export default SelectAudienceField;
