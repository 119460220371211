import React from "react";
import "./EditFormModal.scss";
import { oneOnOneDetailListProps } from "modules/1-on-1s/types";
import PrimaryButton from "modules/1-on-1s/components/PrimaryButton/PrimaryButton";
import SecondaryButton from "modules/1-on-1s/components/SecondaryButton/SecondaryButton";
import { IMetadata, IRevieweeFormValues } from "interfaces";
import { getOneOnOneDetails, submitOneOnOne } from "data/teamble-ai-one-on-one";
import { finalize } from "rxjs";
import { getReviewTodoAnswers } from "data";
import { getMappedRevieweeAnswers } from "modules/reviews/utils/utils";
import { Card, Form, Modal } from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import { FormContext, MetaDataContext } from "contexts";
import RevieweeFormQuestion from "modules/reviews/components/RevieweeFormQuestion";
import { CustomModal, Preloader } from "components";

interface EditFormModalProps {
  data: oneOnOneDetailListProps | null;
  oneOnOneId: string;
  onClose: () => void;
}

const EditFormModal: React.FC<EditFormModalProps> = ({
  data,
  oneOnOneId,
  onClose,
}) => {
  const metadata = React.useContext<IMetadata | null>(
    MetaDataContext
  ) as IMetadata;
  const [isLoading, setIsLoading] = React.useState(false);
  const [oneOnOne, setOneOnOne] = React.useState<any>();

  function getOnOneOne() {
    setIsLoading(true);
    getOneOnOneDetails(oneOnOneId, false)
      .pipe(
        finalize(() => {
          setIsLoading(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          const oneOnOne = response?.data?.data;
          const answers = oneOnOne?.answers;
          const responses = getReviewTodoAnswers(answers, metadata);
          revieweeForm.initialValues.answers = responses || [];
          setOneOnOne(oneOnOne);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  function onSubmitHandler(data: IRevieweeFormValues) {
    const responses = getMappedRevieweeAnswers(data.answers, metadata);

    submitOneOnOne(oneOnOneId, responses as any)
      .pipe(
        finalize(() => {
          revieweeForm.setSubmitting(false);
          window.location.reload();
        })
      )
      .subscribe({
        next: (response: any) => {},
        error: (error) => {
          console.log(error);
        },
      });
    // send to an API
  }

  const revieweeForm = useFormik<IRevieweeFormValues>({
    initialValues: {
      answers: [],
    },
    // validationSchema: REVIEWEE_FORM_VALIDATION_SCHEMA,
    onSubmit: onSubmitHandler,
  });

  React.useEffect(() => {
    getOnOneOne();
  }, []);
  return (
    <CustomModal
      show={true}
      title="Edit Prep Survey"
      onClose={onClose}
      size="lg"
    >
      <Modal.Body>
        {isLoading ? (
          <div className="edit-survey-loader">
            <Preloader show={true} />
          </div>
        ) : (
          <div className="EditFormModalPrepSurvey">
            <div className="form-section">
              <Form
                className="d-flex overflow-hidden flex-column flex-grow-1"
                onSubmit={(e) => {}}
              >
                <FormContext.Provider value={revieweeForm}>
                  <Card.Body
                    id="scrolable"
                    className="overflow-y-auto flex-grow-1"
                  >
                    {revieweeForm.values.answers.map((answer, index) => {
                      return (
                        <div
                          id={`item-${answer?.id}`}
                          key={answer.id}
                          className="mb-4"
                        >
                          <RevieweeFormQuestion index={index} answer={answer} />
                        </div>
                      );
                    })}
                  </Card.Body>
                </FormContext.Provider>
              </Form>
            </div>
          </div>
        )}
        <Modal.Footer
          style={{
            gap: "10px",
          }}
        >
          <SecondaryButton name={"cancel"} onClick={onClose} />
          <PrimaryButton
            text={"submit"}
            isLoading={revieweeForm.isSubmitting}
            loadingText={"Submitting"}
            onClick={() => revieweeForm.submitForm()}
          />
        </Modal.Footer>
      </Modal.Body>
    </CustomModal>
  );
};

export default EditFormModal;
