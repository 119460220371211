import { ImageWithNameInitials } from "components";
import { IMemberOption } from "interfaces";
import * as React from 'react';

interface IMemberProps {
  member: IMemberOption;
}

const Member: React.FunctionComponent<IMemberProps> = (
  { member }
) => {
  return (
    <>
      {
        member
          ?
          <div className='d-flex align-items-center'>
            <ImageWithNameInitials size='md' imageOriginal={member?.imageOriginal} label={member?.label} />
            <div className='ms-2'>
              <h6
                className='m-0'
                style={{ color: member.isExcluded ? "#93a5be" : "inherit" }}
              >
                {member?.label}
              </h6>

              <p
                className='m-0 small'
                style={{ color: member.isExcluded ? "#93a5be" : "inherit" }}
              >
                {member?.description}
              </p>
            </div>
          </div>
          :
          null
      }
    </>
  );
};

export default Member;