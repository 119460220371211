import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { CustomDropdown, Preloader } from 'components';
import {
  ReviewActions,
  BodyCellProps,
  IActiveReview,
  IActiveReviewActionColumnProps,
  ISimpleMenuOption,
} from 'interfaces';
import React from 'react';
import {
  CLOSED_REVIEW_ACTIONS,
  DRAFT_REVIEW_ACTIONS,
  LIVE_REVIEW_ACTIONS,
  SCHEDULED_REVIEW_ACTIONS,
} from 'teamble-constants';


interface IActiveReviewTableActionColumnProps {
  reviewStatus: string | null;
  cellProps: BodyCellProps<IActiveReview, void, IActiveReviewActionColumnProps>;
}


const ActiveReviewTableActionColumn: React.FC<IActiveReviewTableActionColumnProps> = (
  { reviewStatus, cellProps }
) => {

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const actionOptions: Record<string, ISimpleMenuOption[]> = {
    Scheduled: SCHEDULED_REVIEW_ACTIONS,
    Live: LIVE_REVIEW_ACTIONS,
    Draft: DRAFT_REVIEW_ACTIONS,
    Closed: CLOSED_REVIEW_ACTIONS,
  };

  const review = cellProps.row.original;
  const isLoading = cellProps.loadingReviewId === review.id;


  const handleActionChange = (action: ISimpleMenuOption): void => {

    const actions = {
      [ReviewActions.Download]: () => cellProps.onDownloadAction(review),
      [ReviewActions.DeleteReview]: () => cellProps.onDeleteReviewAction(review, true),
      [ReviewActions.CancelReview]: () => cellProps.onCancelReviewAction(review),
      [ReviewActions.DeleteSchedule]: () => cellProps.onDeleteReviewScheduleAction(review, true),
      [ReviewActions.ReopenReview]: () => cellProps.onReopenReviewAction(review),
    };

    actions[action.value as keyof typeof actions]();
  };


  return (
    <>
      {
        isLoading
          ?
          <Preloader isIcon show={isLoading} />
          :
          <CustomDropdown
            size='sm'
            variant='link'
            icon={faEllipsisV}
            id={`scheduledReviewAction${review.id}`}
            className={isOpen ? 'z-index-1 position-relative' : ''}
            options={reviewStatus ? actionOptions[reviewStatus] : []}
            onToggle={(open) => setIsOpen(open)}
            onChange={(action) => handleActionChange(action)}
          />
      }
    </>
  )
};

export default ActiveReviewTableActionColumn;