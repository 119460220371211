import * as React from 'react';

const LoaderAiIcon = ({
    width = 60,
    height = 60,
    strokeColor = '#fff',
    fill = 'none',
    ...props
}) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill={fill}
        {...props}
    >
        <g stroke={strokeColor} strokeWidth={2.727} clipPath='url(#clipPath)'>
            <path d='M24.887 17.53c2.21-4.042 8.015-4.042 10.225 0l1.783 3.258a5.827 5.827 0 0 0 2.316 2.317l3.26 1.782c4.04 2.21 4.04 8.015 0 10.225l-3.26 1.783a5.828 5.828 0 0 0-2.316 2.316l-1.783 3.26c-2.21 4.04-8.014 4.04-10.225 0l-1.782-3.26a5.828 5.828 0 0 0-2.317-2.316l-3.259-1.783c-4.04-2.21-4.04-8.014 0-10.225l3.26-1.782a5.827 5.827 0 0 0 2.316-2.317l1.782-3.259Z' />
            <path d='M24.465 16.831c1.756-5.342 9.314-5.342 11.07 0l.97 2.949a5.827 5.827 0 0 0 3.715 3.715l2.949.97c5.342 1.756 5.342 9.314 0 11.07l-2.949.97a5.827 5.827 0 0 0-3.715 3.715l-.97 2.949c-1.756 5.342-9.314 5.342-11.07 0l-.97-2.949a5.827 5.827 0 0 0-3.715-3.715l-2.949-.97c-5.342-1.756-5.342-9.314 0-11.07l2.949-.97a5.827 5.827 0 0 0 3.715-3.715l.97-2.949Z' />
        </g>
        <defs>
            <clipPath id='clipPath'>
                <path fill='#fff' d='M0 0h60v60H0z' />
            </clipPath>
        </defs>
    </svg>
);

export default LoaderAiIcon;
