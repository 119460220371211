import { ISettingsFormValues, IUserProfile } from 'interfaces';
import {
  ANALYTICS_COLOR_TYPES,
  COLOR_CODE_DEFAULT_SETTING_FIRST_COLOR,
  COLOR_CODE_REVERSE_SETTING_FIRST_COLOR,
} from 'teamble-constants';

export const getSettingsFormInitialValues = (userProfile: IUserProfile | null): ISettingsFormValues => {

  if (!userProfile) {
    return {
      selectedColorTypes: null
    };
  }

  if (userProfile.colorCodes[0].bgColor === COLOR_CODE_DEFAULT_SETTING_FIRST_COLOR) {
    return {
      selectedColorTypes: ANALYTICS_COLOR_TYPES[0]
    };
  }

  if (userProfile.colorCodes[0].bgColor === COLOR_CODE_REVERSE_SETTING_FIRST_COLOR) {
    return {
      selectedColorTypes: ANALYTICS_COLOR_TYPES[1]
    };
  }

  return {
    selectedColorTypes: null
  };
};