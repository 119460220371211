export interface IPermissionErrorInfo {
  name: string;
  message: string;
  errorCode: string;
}

export enum PermissionErrorTypes {
  PerformanceReview = 'performance review',
  PerformanceReviewReminder = 'performance review reminder',
  LaunchSurvey = 'survey',
  SurveyReminder = 'survey reminder',
  LaunchFeedbackLoop = 'feedback loop',
  FeedbackLoopReminder = 'feedback loop reminder',
}