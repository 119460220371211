import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from '@themesberg/react-bootstrap';
import { ACTION_COLUMN_CONFIG } from '@common/modules/table';
import { Member } from 'components';
import {
  BodyCellProps,
  HeaderCellProps,
  IReviewAudienceActionColumnProps,
  IReviewerRevieweeInfos,
  ITableCheckState
} from 'interfaces';
import React from 'react';
import { CellProps, Column } from 'react-table';
import { TABLE_CHECKBOX_COLUMN_WIDTH } from 'teamble-constants';

export const useReviewAudienceTableColumns = () => {

  return React.useMemo<readonly Column<IReviewerRevieweeInfos>[]>(
    () => [
      {
        disableSortBy: true,
        accessor: 'isChecked',
        cellWidth: TABLE_CHECKBOX_COLUMN_WIDTH,
        Header: (cellProps: HeaderCellProps<IReviewerRevieweeInfos, ITableCheckState>) => {

          return (
            <Form.Check
              type='checkbox'
              className='d-inline-block'
              checked={cellProps.allRowsChecked}
              onChange={() => cellProps.updateIsCheckedForAllRows(!cellProps.allRowsChecked)} />
          );
        },
        Cell: (cellProps: BodyCellProps<IReviewerRevieweeInfos, void, IReviewAudienceActionColumnProps>) => {

          return (
            <Form.Check
              type='checkbox'
              checked={cellProps.row.original.isChecked}
              className='z-index-2'
              onChange={() => cellProps.updateIsCheckedForRow(cellProps.row.index)} />
          );
        }
      },
      {
        id: 'reviewee',
        Header: 'Reviewee',
        accessor: (row) => row.reviewee.label,
        Cell: (cellProps: CellProps<IReviewerRevieweeInfos>) => {
          return <Member member={cellProps.row.original.reviewee} />
        }
      },
      {
        Header: 'Reviewer',
        accessor: (row) => row.reviewer.label,
        Cell: (cellProps: CellProps<IReviewerRevieweeInfos>) => {

          const reviewer = cellProps.row.original.reviewer;

          return (
            <>
              {
                reviewer?.id
                  ?
                  <Member member={reviewer} />
                  :
                  <div>Awaiting Nomination</div>
              }
            </>
          )
        }
      },
      {
        ...ACTION_COLUMN_CONFIG,
        Cell: (cellProps: BodyCellProps<IReviewerRevieweeInfos, void, IReviewAudienceActionColumnProps>) => {

          return (
            <FontAwesomeIcon
              icon={faTrash}
              className='cursor-pointer'
              onClick={() => cellProps.onPairRemove(cellProps.row.index)} />
          )
        },
      }
    ],
    []
  );
};