import { FormErrors, IRevieweeFormValues } from 'interfaces';
import * as Yup from 'yup';

import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from './simple-menu-option.validation-schema';

export const REVIEWEE_FORM_VALIDATION_SCHEMA: Yup.SchemaOf<IRevieweeFormValues> = Yup.object({
  answers: Yup.array().of(
    Yup.object(
      {
        id: Yup.string().defined(FormErrors.Required),
        required: Yup.boolean(),
        dropdown: Yup.boolean(),
        showDescription: Yup.boolean(),

        // compentecy
        competencyName: Yup.string(),
        description: Yup.string(),
        isBehavior: Yup.boolean(),
        showBehaviours: Yup.boolean(),
        behaviours: Yup.array().of(
          Yup.object(
            {
              id: Yup.string(),
              name: Yup.string()
            }
          )
        ).default(undefined),
        behaviorTitle: Yup.string().notRequired(),

        // objective
        objectiveName: Yup.string(),
        isKPI: Yup.boolean(),
        showKPIs: Yup.boolean(),
        kpis: Yup.array().of(
          Yup.object(
            {
              id: Yup.string(),
              name: Yup.string(),
              actual: Yup.number().when(['docType'], {
                is: (docType: string) => docType === "Income Statement",
                then: Yup.number().required(FormErrors.Required),
                otherwise: Yup.number()
              }),
            }
          )
        ).default(undefined),
        kpiName: Yup.string().notRequired(),
        comments: Yup.array(),
        commentBox: Yup.boolean(),



        isNotApplicableOption: Yup.boolean(),
        text: Yup.string(),
        label: Yup.string(),
        placeholder: Yup.string(),
        type: Yup.string(),
        family: Yup.string(),
        comment: Yup.string(),
        commentValue: Yup.string().when(['required', 'comment'], {
          is: (required: boolean, comment: boolean) => required && comment,
          then: Yup.string().required(FormErrors.Required),
          otherwise: Yup.string()
        }),

        choices: Yup.array().of(Yup.string()).default(undefined),
        selected_option: Yup.object().shape(
          {
            value: Yup.string(),
            text: Yup.string()
          }
        ).default(undefined),
        value: Yup.string().when(
          ['required', 'dropdown'],
          {
            is: (required: boolean, dropdown: boolean) => !dropdown && required,
            then: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
          }
        ),
        multiSelectValue: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.when(
          ['required', 'dropdown'],
          {
            is: (required: boolean, dropdown: boolean) => dropdown && required,
            then: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.defined(FormErrors.Required).required(FormErrors.Required),
          }
        ).default(undefined),
        selectedView: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.when(
          ['dropdown'],
          {
            is: (required: boolean, dropdown: boolean) => dropdown && required,
            then: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.defined(FormErrors.Required).required(FormErrors.Required),
          }
        ).default(undefined),
      },

    )
  )
});