import React from 'react';
import { Row } from 'react-table';

interface ITableFooterCellProps {
  rows: Row<any>[];
  rowKey: string;
}

export const TableFooterCell: React.FC<ITableFooterCellProps> = (
  { rows, rowKey }
) => {

  const total = React.useMemo(
    () => rows.reduce((sum, row) => row.values[rowKey] + sum, 0),
    [rowKey, rows]
  );

  return <>{total}</>;
};