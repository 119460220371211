import { Card } from '@themesberg/react-bootstrap';
import { DataState } from 'components';
import { FeedbackForAudienceTypes, IFeedbackLoopAudiencePair, ISimpleMenuOption } from 'interfaces';
import React from 'react';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import FeedbackLibraryLoopSenderReceiverTable from './FeedbackLibraryLoopSenderReceiverTable';

interface IFeedbackLoopSenderReceiverWidgetProps {
  feedbackForValue: ISimpleMenuOption | null;
  isFeedbackLoopAudience: boolean;
  isFeedbackForUpdating?: boolean;
  onFeedbackLoopAudiencePairRemove: (audiencePair: IFeedbackLoopAudiencePair) => void;
}


const getEmptyStateMessage = (
  isFeedbackLoopAudience: boolean,
  feedbackForValue: FeedbackForAudienceTypes | undefined
): string => {

  return !isFeedbackLoopAudience
    ?
    'Select the audience to configure Feedback-For!'
    :
    feedbackForValue === FeedbackForAudienceTypes.ManuallyDefine
      ?
      'Create new sender-receiver pairs!'
      :
      feedbackForValue === FeedbackForAudienceTypes.ThreeSixty
        ?
        'Select at least two members from audience!'
        :
        'No sender-receiver pairs available!'
};


const FeedbackLoopSenderReceiverWidget: React.FC<IFeedbackLoopSenderReceiverWidgetProps> = (
  { feedbackForValue, isFeedbackLoopAudience, isFeedbackForUpdating, onFeedbackLoopAudiencePairRemove }
) => {

  // Feedback Loop config hook
  const {
    isFeedbackForAudiencePairsLoading,
    feedbackForAudiencePairs
  } = useFeedbackLoopConfig();

  const emptyStateMessage = getEmptyStateMessage(
    isFeedbackLoopAudience,
    feedbackForValue?.value as FeedbackForAudienceTypes
  );


  return (
    <Card
      className='position-relative'
      style={{
        height: isFeedbackForUpdating || isFeedbackForAudiencePairsLoading || !isFeedbackLoopAudience || !feedbackForAudiencePairs?.length ?
          100 : 'auto'
      }}
    >
      <Card.Body>
        <DataState
          isDataLoading={!!(isFeedbackForAudiencePairsLoading || isFeedbackForUpdating)}
          isDataAvailable={!!(isFeedbackLoopAudience && feedbackForAudiencePairs?.length)}
          emptyStateMessage={<> {emptyStateMessage} </>}
        >
          <FeedbackLibraryLoopSenderReceiverTable
            readOnly={feedbackForValue?.value !== FeedbackForAudienceTypes.ManuallyDefine}
            feedbackForAudiencePair={feedbackForAudiencePairs}
            onFeedbackLoopAudiencePairRemove={onFeedbackLoopAudiencePairRemove} />
        </DataState>
      </Card.Body>
    </Card >
  );
};

export default FeedbackLoopSenderReceiverWidget;