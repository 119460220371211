import {
  Chart as ChartJS,
  ChartDataset,
  defaults,
  LinearScale,
  LineElement,
  PointElement,
  TimeSeriesScale,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { CHART_FONT_CONFIG, COMPETENCY_PERFORMANCE_CHART_OPTIONS } from 'teamble-constants';
import 'chartjs-adapter-moment';

interface IUserCompetencyPerformanceChartProps {
  competencyChartDatasets: ChartDataset<'line'>[];
}

ChartJS.register(
  TimeSeriesScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
);

defaults.font = {
  ...defaults.font,
  ...CHART_FONT_CONFIG
}


const UserCompetencyPerformanceChart: React.FC<IUserCompetencyPerformanceChartProps> = (
  { competencyChartDatasets }
) => {

  return (
    <Line
      options={COMPETENCY_PERFORMANCE_CHART_OPTIONS}
      data={{ datasets: competencyChartDatasets }} />
  );
};

export default UserCompetencyPerformanceChart;