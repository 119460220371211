import { useCompanyConfig } from '@common/modules/companyConfig';
import {
  ACTION_COLUMN_CONFIG,
  MANAGER_TO_COLUMN,
  MS_TEAMS_USER_DEPARTMENT_COLUMN,
  MS_TEAMS_USER_NAME_COLUMN,
  MS_TEAMS_USER_ROLE_COLUMN,
  SLACK_USER_DEPARTMENT_COLUMN,
  SLACK_USER_NAME_COLUMN,
  SLACK_USER_ROLE_COLUMN,
  MEMBER_STATUS_COLUMN,
  USER_DEPARTMENT_COLUMN,
  USER_MANAGEES,
  USER_NAME_COLUMN,
  USER_ROLE_COLUMN,
  USER_STATUS_COLUMN
} from '@common/modules/table';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Form } from '@themesberg/react-bootstrap';
import { CustomDropdown, Preloader } from 'components';
import { useApp } from 'hooks';
import {
  BodyCellProps,
  HeaderCellProps,
  IActionTypes,
  IMsTeamsChannelUser,
  IOrgUser,
  ISimpleMenuOption,
  ISlackWorkspaceUser,
  ITableCheckState,
  IUserActionCellProps,
  SignInWith
} from 'interfaces';
import * as React from 'react';
import { Column } from 'react-table';
import { TABLE_CHECKBOX_COLUMN_WIDTH, USER_TABLE_ACTIONS } from 'teamble-constants';


interface IUserTableActionColumnProps {
  cellProps: BodyCellProps<IOrgUser, void, IUserActionCellProps>
}

export const UserTableActionColumn: React.FC<IUserTableActionColumnProps> = (
  { cellProps }
) => {

  // App hook
  const app = useApp();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const user = cellProps.row.original;

  if (user.isDeleted) {
    return null;
  }

  const userActions = user.isExcluded
    ? USER_TABLE_ACTIONS.filter(
      (action) => action.value !== IActionTypes.Exclude
    )
    : USER_TABLE_ACTIONS.filter(
      (action) => action.value !== IActionTypes.Include
    )

  return (
    <>
      {
        cellProps.updatingUser?.id === user.id
          ?
          <Preloader
            isIcon
            show={cellProps.updatingUser?.id === user.id} />
          :
          <CustomDropdown
            size='sm'
            icon={faEllipsisV}
            variant='link'
            id='userTableActionDropdown'
            options={userActions}
            className={isOpen ? 'z-index-1 position-relative' : ''}
            onToggle={(open) => setIsOpen(open)}
            onChange={(option) => cellProps.onUserActionChange?.(option, user)}
          />
      }
    </>
  );
};

export const useUsersListTableColumn = (allowActions: boolean = true): readonly Column<IOrgUser>[] => {

  // App hook
  const app = useApp();

  // CompanyConfig hook
  const companyConfig = useCompanyConfig();

  return React.useMemo<readonly Column<IOrgUser>[]>(() => {

    const checkboxColumn: Column<IOrgUser> = {
      accessor: 'isChecked',
      cellWidth: TABLE_CHECKBOX_COLUMN_WIDTH,
      Header: (cellProps: HeaderCellProps<IOrgUser, ITableCheckState>) => {

        const statusFilter = cellProps.state.filters.find(
          (filter) => filter.id === 'status'
        );

        if (statusFilter?.value.value === IActionTypes.Deactivate) {
          return null;
        }

        return (
          <Form.Check
            type='checkbox'
            className='d-inline-block'
            checked={cellProps.allRowsChecked}
            onClick={(e) => e.stopPropagation()}
            onChange={() => cellProps.updateIsCheckedForAllRows?.(!cellProps.allRowsChecked)} />
        );
      },
      Cell: (cellProps: BodyCellProps<IOrgUser, void, IUserActionCellProps>) => {

        return (
          <>
            {
              !cellProps.row.original.isDeleted
                ?
                <Form.Check
                  type='checkbox'
                  checked={cellProps.row.original.isChecked}
                  className='z-index-2'
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => cellProps.updateIsCheckedForRow?.(cellProps.row.index)} />
                :
                null
            }
          </>
        );
      }
    };

    const actionsColumn: Column<IOrgUser> = {
      ...ACTION_COLUMN_CONFIG,
      Cell: (cellProps: any) => {

        return <UserTableActionColumn cellProps={cellProps} />
      }
    };

    if (companyConfig?.defaultRelationshipName) {
      USER_MANAGEES.Header = companyConfig.defaultRelationshipName;
    }

    return [
      checkboxColumn,
      USER_NAME_COLUMN,
      USER_ROLE_COLUMN,
      USER_DEPARTMENT_COLUMN,
      USER_MANAGEES,
      USER_STATUS_COLUMN,
      actionsColumn
    ];

  }, [
    app,
    allowActions,
    companyConfig.showManagerToColumn
  ]);

};