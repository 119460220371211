import React from "react";
import "./EngagementStatusView.scss";

interface EngagementStatusViewProps {
  topic?: string;
  value?: string;
  maxWidth?: string;
  color?: string;
}

const EngagementStatusView: React.FC<EngagementStatusViewProps> = ({
  topic,
  value,
  maxWidth,
  color,
}: any) => {
  return (
    <div
      style={{
        maxWidth,
      }}
      className="EngagementStatusView"
    >
      <div
        style={{
          color,
        }}
        className="topic"
      >
        {topic}
      </div>
      <div title={value} className="value">
        {value}
      </div>
    </div>
  );
};

export default EngagementStatusView;
