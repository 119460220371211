import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortBy, SortKeyType } from 'interfaces';
import * as React from 'react';
import { HeaderGroup, TableInstance } from 'react-table';


interface ITableHeadProps {
  headerGroups: TableInstance<any>['headerGroups'];
  enableSorting?: boolean;
  headerCellProps?: any;
  stickyHeader?: boolean;
  onHeaderClick?: (sortKey?: string, sortBy?: SortBy, sortType?: SortKeyType) => void;
}


export const TableHead: React.FunctionComponent<ITableHeadProps> = (
  { headerGroups, headerCellProps, enableSorting = true, stickyHeader = true, onHeaderClick }
) => {


  const handleHeaderCellClick = (column: HeaderGroup<any>): void => {

    if (!column.manualSortingProps) {
      return;
    }

    const isSorted = column.manualSortingProps.isSorted
      ?
      column.manualSortingProps.sortBy === 'DESC' ? 'ASC' : 'DESC'
      :
      column.manualSortingProps.sortKeyType === 'date' ? 'DESC' : 'ASC';

    onHeaderClick?.(
      column.manualSortingProps.sortKey,
      isSorted,
      column.manualSortingProps.sortKeyType
    )
  };


  return (
    <thead className="thead-light">
      {
        headerGroups.map(
          (headerGroup) => (

            <tr {...headerGroup.getHeaderGroupProps()}>
              <th
                className={`top-0 align-middle bg-white z-index-2 ${stickyHeader ? 'position-sticky' : 'position-relative'}`}
              >
                No.
              </th>
              {
                headerGroup.headers.map(
                  (column) => {

                    const headerPropGetter = [
                      {
                        style: {
                          width: column.cellWidth
                        }
                      },
                    ];

                    if (enableSorting) {
                      headerPropGetter.push(
                        (column as any).getSortByToggleProps()
                      );
                    }


                    return (
                      <th
                        className={
                          `top-0 align-middle bg-white z-index-2
                        ${column.manualSortingProps?.sortKey ? 'cursor-pointer' : ''}
                        ${stickyHeader ? 'position-sticky z-index-5' : 'position-relative'}
                        ${column.cellClassName || ''}`
                        }
                        onClick={() => handleHeaderCellClick(column)}
                        {...column.getHeaderProps(headerPropGetter)}
                      >
                        {column.render('Header', headerCellProps)}
                        {
                          (column.isSorted || column.manualSortingProps?.isSorted) &&
                          <FontAwesomeIcon
                            className='ms-2'
                            icon={
                              (column.isSortedDesc || column.manualSortingProps?.sortBy === 'DESC')
                                ?
                                faCaretDown
                                :
                                faCaretUp
                            } />
                        }
                      </th>
                    );
                  }
                )
              }

            </tr>

          )
        )
      }
    </thead>
  );
};
