import { Modal } from '@themesberg/react-bootstrap';
import {
  AnswersList,
  Divider,
  FeedbackDevelopmentAreasSection,
  FeedbackSender,
  FeedbackStrengthsSection,
  FeedbackTags,
  FeedbackText,
  FeedbackType,
} from 'components';
import { IFeedback, IFeedbackMaps, IModalProps } from 'interfaces';
import React from 'react';


interface IFeedbackTextProps {
  feedback: IFeedback;
  feedbackMaps: IFeedbackMaps;
}

interface IFeedbackDetailsModalProps extends IModalProps, IFeedbackTextProps { }


const FeedbackDetailsModal: React.FC<IFeedbackDetailsModalProps> = (
  { show, feedback, feedbackMaps, onClose }
) => {

  return (
    <>
      {
        show &&
        <Modal
          scrollable
          as={Modal.Dialog}
          centered
          size="lg"
          show={show}
          onHide={onClose}
        >

          <Modal.Header
            closeButton
            className="px-4"
          >
            <Modal.Title>
              Feedback
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FeedbackType
              feedbackType={feedback.feedback_type}
              feedbackTypeTitle={feedback.feedbackType?.title}
              feedbackTypeHeader={feedback.feedbackTypeHeader}
            />
            {
              feedback.blocks?.length
                ?
                <AnswersList answers={feedback.blocks} />
                :
                <FeedbackText feedback={feedback} />
            }
            <Divider />
            {
              feedback.dimensionInfos?.length
                ?
                <>
                  <FeedbackTags
                    feedback={feedback}
                    feedbackMaps={feedbackMaps} />
                  <Divider />
                </>
                :
                null
            }
            <FeedbackStrengthsSection
              feedback={feedback}
              feedbackMaps={feedbackMaps} />
            <FeedbackDevelopmentAreasSection
              feedback={feedback}
              feedbackMaps={feedbackMaps} />
            <FeedbackSender feedback={feedback} />
            <p>
              <strong>Sent At:</strong>
              <span> {new Date(feedback.createdAt).toLocaleString()} </span>
            </p>
          </Modal.Body>

        </Modal>
      }
    </>
  );
};

export default FeedbackDetailsModal;