import { UserPermissions } from 'interfaces';
import React from 'react';

import { useUserProfile } from './ProvideUserProfile';

interface IPermissionCheckProps {
  permission: UserPermissions;
}

const PermissionCheck: React.FC<IPermissionCheckProps> = (
  { permission, children }
) => {

  const { userProfile } = useUserProfile();

  return (
    <>
      {
        userProfile?.permissions?.includes(permission)
          ?
          <> {children} </>
          :
          null
      }
    </>
  );
};

export default PermissionCheck;