export interface IUsersAudience {
  type: string;
  users: {
    name: string;
    userId: string;
    channelInfo: {
      slack: { userId: string };
      msteams: { userId: string };
    }
  }[];
}


export interface IChannelsAudience {
  type: string;
  channels: {
    channelInfo: {
      slack: {
        channelId: string;
        name: string;
      }
    }
  }[];
}


export type IAudience = (IUsersAudience | IChannelsAudience | IGroupsAudience)[];


export enum IAudienceType {
  Workspace = 'workspace',
  Channels = 'channels',
  Users = 'users',
  Team = 'team',
  Groups = 'groups'
}


export interface IAudiencePill {
  id: string;
  name: string;
  isChannel: boolean;
  isWorkspace: boolean;
  isGroup?: boolean;
  slackUserId?: string;
  msTeamsUserId?: string;
}

export interface IGroupsAudience {
  type: string;
  groups: {
    isCustom: boolean;
    name: string;
    userId: string;
    channelInfo: {
      msteams: { groupId: string };
    }
  }[]
};