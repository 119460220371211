import { IMenuOption, IReviewAnalyticsComments, IReviewAnalyticsResponse, SignInWith } from 'interfaces';
import Axios, { AxiosObservable } from "axios-observable";
import { IResponse, IReport, IReportRequestFilters, IReportTableRow, IUserProfile } from "interfaces";

export const getReportsByFilters =
  (filters: IReportRequestFilters): AxiosObservable<IResponse<IReportTableRow[]>> => {
    return Axios.post<IResponse<IReportTableRow[]>>('/filter-reports', filters);
  };

export const getReportsByPerformanceReviewIds = (
  perfReviewIds: string[],
  isReport: boolean,
  isEmail: boolean = false,
  emails: string[] = [],
  workspaceOrChannelId?: string,
  app?: IUserProfile['app'],
  userIds?: string[],
  share: boolean = false,
): AxiosObservable<IResponse<IReport[]> | Blob> => {

  let requestData: any = {
    perfReviewIds,
    isReport,
    isEmail,
    emails,
    userIds,
    share
  };

  if (app === SignInWith.Slack) {
    requestData = {
      ...requestData,
      workspaceId: workspaceOrChannelId
    };
  } else if (app === SignInWith.MsTeams) {
    requestData = {
      ...requestData,
      channelId: workspaceOrChannelId
    };
  }

  return Axios.post<IResponse<IReport[]> | Blob>(
    isReport ? "/detail-performance-review-report-pdf" : "/detail-performance-review-report",
    requestData,
    {
      responseType: isReport && !isEmail ? 'arraybuffer' : 'json'
    }
  );
};


export const getReviewReportTable = (data: any): AxiosObservable<IResponse<IReviewAnalyticsResponse>> => {
  return Axios.post<IResponse<IReviewAnalyticsResponse>>(
    "/performance-review/analytics/render-query",
    data
  )
};

export const getReviewReportCommentsTable = (data: any): AxiosObservable<IResponse<IReviewAnalyticsComments[]>> => {
  return Axios.post<IResponse<IReviewAnalyticsComments[]>>(
    "/performance-review/analytics/render-comments",
    data
  )
};

export const downloadAnalyticsReport = (data: any): AxiosObservable<IResponse<IReviewAnalyticsResponse>> => {
  return Axios.post<IResponse<IReviewAnalyticsResponse>>(
    "/performance-review/analytics/report",
    data,
    {
      responseType: "arraybuffer"
    }
  )
};

export const downloadBulkReports = (perfReviewIds: any): AxiosObservable<IResponse<void>> => {
  return Axios.post<IResponse<void>>(
    "/detail-performance-review-report-bulk-pdf",
    { perfReviewIds, isReport: true }
  )
};


export const getUserReportReviewees = (): AxiosObservable<IResponse<IMenuOption[]>> => {

  return Axios.post<IResponse<IMenuOption[]>>(
    "/get-review-report-reviewees", {}
  )
};