import * as React from 'react';
import { Helmet } from 'react-helmet';

interface IPageTitleProps {
  title: string;
}

const PageTitle: React.FunctionComponent<IPageTitleProps> = ({ title }) => {
  return (
    <Helmet
      title={title}
      titleTemplate='%s | Teamble Admin'
    />
  );
};

export default PageTitle;
