import { ISimpleMenuOption } from "interfaces";
import moment from "moment";
import { ObjectiveCampaignTypes } from "teamble-constants/objective.constants";
import * as Yup from "yup";

export const objectiveLaunchValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required").trim(),
  startDate: Yup.string()
    .nullable()
    .test(
      "is-current-or-future",
      "Start date and time must be set to the current moment or a future date and time.",
      function (value) {
        if (!value || !moment(value).isValid()) return true;
        const startDateTime = moment(value);
        const now = moment();
        return startDateTime.isSameOrAfter(now);
      }
    ),
  endDate: Yup.string()
    .nullable()
    .test(
      "date-comparison",
      "End date must be the same as or later than the start date.",
      function (value) {
        const { startDate } = this.parent;
        if (!startDate || !value) return true;
        const startMoment = moment(startDate);
        const endMoment = moment(value);
        if (!startMoment.isValid() || !endMoment.isValid()) return false;
        return endMoment.isSameOrAfter(startMoment);
      }
    ),
  audienceType: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .required("Audience type is required")
    .min(1, "At least one audience type must be selected"),
  users: Yup.array().when("audienceType", {
    is: (audienceType: ISimpleMenuOption[]) =>
      Array.isArray(audienceType) &&
      audienceType.some((item) => item.value === ObjectiveCampaignTypes.Users),
    then: Yup.array()
      .min(
        1,
        "At least one user must be selected when 'Users' audience is chosen."
      )
      .required("Users are required when 'Users' audience is selected."),
    otherwise: Yup.array().nullable(),
  }),
  notifyManagers: Yup.boolean().test(
    "at-least-one-notify",
    "Either 'Notify Managers' or 'Notify Employee' must be selected.",
    function () {
      const { notifyManagers, notifyEmployees } = this.parent;
      return notifyManagers || notifyEmployees;
    }
  ),
  notifyEmployees: Yup.boolean().test(
    "at-least-one-notify",
    "Either 'Notify Managers' or 'Notify Employee' must be selected.",
    function () {
      const { notifyManagers, notifyEmployees } = this.parent;
      return notifyManagers || notifyEmployees;
    }
  ),
});
