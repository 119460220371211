import { Table as BootstrapTable } from '@themesberg/react-bootstrap';
import * as React from 'react';
import { Row, TableInstance } from 'react-table';

import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';


export interface ITableProps<T extends object, U = void> {
  tableInstance: TableInstance<T>;
  hover?: boolean;
  className?: string;
  bodyCellProps?: U;
  headerCellProps?: any;
  showFooter?: boolean;
  stickyHeader?: boolean;
  enableSorting?: boolean;
  style?: React.CSSProperties;
  onRowClick?: (row: Row<T>) => void;
  onHeaderClick?: (sortKey?: string) => void;
}


export const Table = <U, T extends object>(
  {
    tableInstance, style, bodyCellProps, headerCellProps,
    hover = false, className = '', showFooter = false,
    enableSorting = true, stickyHeader = true,
    onHeaderClick, onRowClick
  }: ITableProps<T, U>
) => {
  return (
    <BootstrapTable
      hover={hover}
      style={{
        // For the sticky headers. If border-collapse is not 'separate',
        // it scrolls the border of the table-header as well.
        borderCollapse: 'separate',

        borderSpacing: 0,
        ...style
      }}
      className={`rounded mb-0 bg-white ${className}`}
      {...tableInstance.getTableProps()}
    >

      <TableHead
        stickyHeader={stickyHeader}
        enableSorting={enableSorting}
        headerCellProps={headerCellProps}
        headerGroups={tableInstance.headerGroups}
        onHeaderClick={onHeaderClick}
      />

      <TableBody
        rows={tableInstance.rows}
        bodyCellProps={bodyCellProps}
        prepareRow={tableInstance.prepareRow}
        getTableBodyProps={tableInstance.getTableBodyProps}
        onRowClick={onRowClick} />

      {
        showFooter
          ?
          <TableFooter footerGroups={tableInstance.footerGroups} />
          :
          null
      }

    </BootstrapTable>
  );
};

export default Table;
