import { List } from '@common/components';
import { Card } from '@themesberg/react-bootstrap';
import { DataState, FeedbackCard } from 'components';
import { getRevieweeFeedbacks } from 'data';
import { useApp } from 'hooks';
import { IFeedbacks } from 'interfaces';
import React from 'react';
import { finalize } from 'rxjs';


interface IRevieweeFeedbackListCardProps {
  reviewId: string;
}

const RevieweeFeedbackListCard: React.FC<IRevieweeFeedbackListCardProps> = (
  { reviewId }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [feedbacks, setFeedbacks] = React.useState<IFeedbacks | null>(null);

  const app = useApp();

  React.useEffect(
    () => {

      if (!reviewId) {
        return;
      }

      setIsLoading(true);

      const subscription = getRevieweeFeedbacks(reviewId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setFeedbacks(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [reviewId]
  );


  return (
    <Card className='h-100 overflow-hidden'>
      <Card.Body
        style={{ height: isLoading || !feedbacks?.feedbacks?.length ? 100 : '' }}
        className='h-100 overflow-y-auto position-relative'
      >
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!feedbacks?.feedbacks?.length}
          emptyStateMessage='No Feedback available!'
        >
          {
            feedbacks?.feedbacks?.length
              ?
              <List
                items={feedbacks.feedbacks}
                renderItem={
                  (feedback) => (
                    <FeedbackCard
                      key={feedback.feedback_id}
                      feedback={feedback}
                      feedbackMaps={feedbacks.maps}
                    />
                  )
                }
              />
              :
              null
          }
        </DataState>
      </Card.Body>
    </Card>
  );
};

export default RevieweeFeedbackListCard;