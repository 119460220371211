import Axios, { AxiosObservable } from 'axios-observable';
import {
  ICompetencyPerformanceScore,
  IFeedbacks,
  IGoal,
  IGoalApprovals,
  IMemberManager,
  IMemberWithRelationships,
  IObjectiveValues,
  IResponse,
  IReviewScore,
  IScoreAnalyticsReview,
  ISimpleMenuOption,
  ITimestampsRange,
  IUserGoalActionRequestData,
  IUserProfile,
  Nullable,
} from 'interfaces';
import { map } from 'rxjs';
import { getUserProfileWithPermissions } from 'utils';


export const getUserProfile = (): AxiosObservable<IResponse<IUserProfile>> => {
  return Axios.post<IResponse<IUserProfile>>('/logged-in-user-info')
    .pipe(
      map(
        (response) => {
          response.data.data = getUserProfileWithPermissions(
            response.data.data
          );
          return response;
        }
      )
    );
};


export const getTeammateProfile = (
  profileVieweeTeambleUserId: string,
  relationshipId?: string | null,
): AxiosObservable<IResponse<IMemberWithRelationships>> => {
  return Axios.post<IResponse<IMemberWithRelationships>>(
    "/user-profile-info",
    {
      profileVieweeTeambleUserId,
      relationshipId
    }
  );
};


export const getTeammateFeedbacks = (
  profileVieweeTeambleUserId: string | undefined,
  feedbackFilters?: Nullable<Partial<ITimestampsRange>>,
  relationshipId?: string | null
): AxiosObservable<IResponse<IFeedbacks>> => {

  return Axios.post<IResponse<IFeedbacks>>(
    "/dashboard-user-feedbacks",
    {
      profileVieweeTeambleUserId,
      relationshipId,
      ...feedbackFilters
    }
  );
};


export const downloadTeammateFeedbacks = (
  profileVieweeTeambleUserId: string | undefined,
  feedbackIds: string[],
  timezoneOffset: number,
  relationshipId?: string | null
): AxiosObservable<Blob> => {

  return Axios.post<Blob>(
    "/dashboard-user-feedbacks",
    {
      profileVieweeTeambleUserId,
      feedbackIds,
      relationshipId,
      isReport: true,
      timezoneOffset
    },
    { responseType: 'arraybuffer' }
  );
};


export const getTeammateCompetencyPerformanceScore =
  (revieweeId: string): AxiosObservable<IResponse<ICompetencyPerformanceScore[]>> => {
    return Axios.post<IResponse<ICompetencyPerformanceScore[]>>(
      "/competency-performance-chart",
      { revieweeId }
    );
  };


export const getTeammateGoals =
  (profileVieweeTeambleUserId?: string, relationshipId?: string | null): AxiosObservable<IResponse<IGoal[]>> => {

    return Axios.post<IResponse<IGoal[]>>(
      '/dashboard-user-goals',
      {
        profileVieweeTeambleUserId,
        relationshipId
      }
    );
  };


export const performGoalsAction = (data: IUserGoalActionRequestData): AxiosObservable<IResponse<IGoal[]>> => {
  return Axios.post<IResponse<IGoal[]>>(
    "/dashboard-user-goals-create-update",
    { ...data }
  );

};


export const goalsApprovalAndCompletion = (data: {
  id: string,
  type: string,
  approvals: IGoalApprovals
  profileVieweeTeambleUserId?: string,
  relationshipId?: string
}): AxiosObservable<IResponse<IGoal[]>> => {
  return Axios.post<IResponse<IGoal[]>>(
    "/dashboard-user-goals-create-update",
    data
  );
};


export const getMemberManagers = (profileVieweeTeambleUserId: string) => {
  return Axios.post<IResponse<IMemberManager[]>>(
    '/list-user-manager',
    { profileVieweeTeambleUserId }
  );
};


export const createMemberManagers = (
  profileVieweeTeambleUserId: string,
  managerTeambleUserIds: string[]
) => {

  return Axios.post<IResponse<IMemberManager[]>>(
    '/create-user-manager',
    {
      profileVieweeTeambleUserId,
      managerTeambleUserIds
    }
  );
};


export const removeMemberManager = (
  profileVieweeTeambleUserId: string,
  managerTeambleUserIds: string[]
) => {

  return Axios.post<IResponse<void>>(
    '/remove-user-manager',
    {
      profileVieweeTeambleUserId,
      managerTeambleUserIds
    }
  );
};


export const getReviewScores = (
  PRCampaignIds: string[],
  profileVieweeTeambleUserId: string
): AxiosObservable<IResponse<IReviewScore[]>> => {

  return Axios.post<IResponse<IReviewScore[]>>(
    "/performance-review/analytics/campaign-question-average-score",
    { PRCampaignIds, profileVieweeTeambleUserId }
  );
};


export const getReviewOptionsForScoreAnalytics = (
  profileVieweeTeambleUserId: string,
): AxiosObservable<IResponse<ISimpleMenuOption[]>> => {

  return Axios.post<IResponse<IScoreAnalyticsReview[]>>(
    "/performance-review/analytics/list-campaign",
    { profileVieweeTeambleUserId }
  ).pipe(
    map(
      (response) => ({
        ...response,
        data: {
          ...response.data,
          data: response.data.data.map(
            (review) => ({
              value: review.id,
              label: review.title
            })
          )
        }
      })
    )
  );
};

export const getUserObjective = (
  profileVieweeTeambleUserId: string,
  relationshipId: string | null = null,
  perfReviewId: string | null = null
): AxiosObservable<IResponse<IObjectiveValues[]>> => {
  return Axios.post<IResponse<IObjectiveValues[]>>(
    '/dashboard-user-objectives', {
    profileVieweeTeambleUserId,
    relationshipId,
    perfReviewId
  })
};

export const userObjectiveActions = (
  type: string,
  profileVieweeTeambleUserId: string,
  objective: IObjectiveValues,
  relationshipId?: string,
): AxiosObservable<IResponse<IObjectiveValues>> => {
  return Axios.post<IResponse<IObjectiveValues>>(
    '/dashboard-user-objectives-create-update', {
    type,
    profileVieweeTeambleUserId,
    relationshipId,
    objective
  })
};