import axios, { AxiosError } from "axios";
import { Axios } from "axios-observable";
import { IErrorResponse } from "interfaces";
import { toast } from "react-toastify";

// Add a response interceptor
const setErrorHandlerInterceptor = () => {
  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError<IErrorResponse>) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      const message =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        "Something went wrong.";
      toast.error(message);
      toast.clearWaitingQueue();

      if (!error.response) {
        return Promise.reject({
          ...error,
          response: {
            data: {
              message,
            },
          },
        });
      }

      return Promise.reject(error);
    }
  );
};

export const setResponseInterceptors = () => {
  setErrorHandlerInterceptor();
};
