import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { FormField, SubmitButton, useUserProfile } from 'components';
import { FormContext } from 'contexts';
import { updateAnalyticsColorCodes } from 'data';
import { useFormik } from 'formik';
import { FormFieldTypeConstants, IModalProps, ISettingsFormValues } from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize, Subscription } from 'rxjs';
import { ANALYTICS_COLOR_TYPES } from 'teamble-constants';

import { getSettingsFormInitialValues } from '../utils/formUtils';

interface ISettingsFormProps {
  onClose: IModalProps['onClose'];
}

const SettingsForm: React.FC<ISettingsFormProps> = (
  { onClose }
) => {

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const { userProfile } = useUserProfile();



  const saveSettings = React.useCallback(
    (isAutoSave: boolean = false): Subscription => {

      setIsSubmitting(true);

      return updateAnalyticsColorCodes()
        .pipe(
          finalize(() => setIsSubmitting(false))
        )
        .subscribe(
          (response) => {

            if (!isAutoSave) {
              toast.success(response.data.message);
              window.location.reload();
              onClose();
            }

          }
        );
    },
    [onClose]
  );


  const settingsForm = useFormik<ISettingsFormValues>(
    {
      initialValues: getSettingsFormInitialValues(userProfile),
      onSubmit: () => { saveSettings(); }
    }
  );


  return (
    <Form onSubmit={settingsForm.handleSubmit}>

      <Modal.Body>
        <FormContext.Provider value={settingsForm}>
          <FormField
            label='Analytics Color Types'
            controlName='selectedColorTypes'
            options={ANALYTICS_COLOR_TYPES}
            optionType='description'
            controlId='analyticsColorTypesField'
            type={FormFieldTypeConstants.Select} />
        </FormContext.Provider>
      </Modal.Body>

      <Modal.Footer className="justify-content-end px-4">
        <Button type='button' variant="outline-primary" onClick={onClose}>
          Close
        </Button>
        <SubmitButton
          isSubmitting={isSubmitting}
          text='Save'
          submittingText='Saving...'
        />
      </Modal.Footer>

    </Form>
  );
};

export default SettingsForm;