import * as React from 'react';
import { TableInstance } from 'react-table';


interface ITableFooterProps<T extends object> {
  footerGroups: TableInstance<T>['footerGroups'];
}

export const TableFooter = <T extends object,>(
  { footerGroups }: ITableFooterProps<T>
) => {

  return (
    <tfoot>
      {
        footerGroups.map(
          (group) => (
            <tr {...group.getFooterGroupProps()}>
              {
                group.headers.map(
                  (column) => (
                    <td
                      style={{ width: column.cellWidth }}
                      className={column.cellClassName}
                      {...column.getFooterProps()}
                    >
                      {column.render('Footer')}
                    </td>
                  )
                )
              }
            </tr>
          )
        )
      }
    </tfoot>
  );
};