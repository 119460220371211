import { OneOnOneStatus } from "./types";

export const statusColorPicker = (status: OneOnOneStatus) => {
  switch (status) {
    case OneOnOneStatus?.Preparation:
      return "#FB0";
    case OneOnOneStatus?.Open:
      return "#09B96E";
    case OneOnOneStatus?.Session:
      return "#F54F1B";
    case OneOnOneStatus?.Closed:
      return "red";
    default:
      return "#FB0";
  }
};

export function convertDate(inputTimestamp: string) {
  const timestamp = parseInt(inputTimestamp, 10);
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  return formattedDate;
}

export const statusText = (status: string) => {
  switch (status) {
    case OneOnOneStatus?.Preparation:
      return "Preparation";
    case OneOnOneStatus?.Open:
      return "Open";
    case OneOnOneStatus?.Session:
      return "Session";
    case OneOnOneStatus?.Closed:
      return "Closed";
    default:
      return status ?? "";
  }
};
