import { Card } from "@themesberg/react-bootstrap";
import { DataState } from "components";
import { ObjectiveCampaignListProps } from "interfaces/objective-campaign.interface";
import React, { useState } from "react";
import ObjectiveListTable from "./ObjectiveListTable";
import { getObjectiveCampaignList } from "data/objective-campaign.data";
import { AxiosError } from "axios";
import { finalize } from "rxjs";

interface IReviewsListCardProps {}

const ObjectiveListCard: React.FC<IReviewsListCardProps> = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [objectiveList, setObjectiveList] = useState<
    ObjectiveCampaignListProps[]
  >([]);

  React.useEffect(() => {
    setIsLoading(true);
    getObjectiveCampaignList()
      .pipe(
        finalize(() => {
          setIsLoading(false);
        })
      )
      .subscribe({
        next: (response: any) => {
          setObjectiveList(response?.data?.data?.objectivesCampaigns);
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
  }, []);

  if (isLoading) {
    return <>Loading....</>;
  }

  return (
    <div className="h-100 overflow-hidden d-flex flex-column">
      <div className="flex-grow-1 position-relative overflow-y-auto">
        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!objectiveList.length}
          emptyStateMessage={
            <>
              No reviews found. <br /> Launch a new one!
            </>
          }
        >
          <Card border="light" className="shadow-sm overflow-hidden h-100">
            <Card.Body className="py-0 mt-3 position-relative h-100 overflow-y-auto">
              <ObjectiveListTable objectiveList={objectiveList} />
            </Card.Body>
          </Card>
        </DataState>
      </div>
    </div>
  );
};

export default ObjectiveListCard;
