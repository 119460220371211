import { Button, Card, Collapse, Form } from '@themesberg/react-bootstrap';
import { MetaDataContext } from 'contexts';
import { IAnswer, IMetadata, IMultiSelectQuestionOptionsTypeConstants } from 'interfaces';
import React, { useContext } from 'react';
import { getMultiSelectAnswerValue } from 'utils';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KPIWidgetBody } from './deloitte/KPIBody';
import { toHTML } from 'slack-markdown';


interface IAnswerProps {
  answer: IAnswer;
  multiCampaign?: boolean
  currentReviewer?: string;
}

interface IRevieweeFormQuestionProps {
  answer: IAnswer;
  type?: string;
  index?: number;
}


export const RevieweeFormQuestionDescription: React.FunctionComponent<IRevieweeFormQuestionProps> = ({ answer, index }) => {

  let descriptionComponent = null;

  switch (answer.type) {
    case 'competency-question':
      if (answer.showBehaviours && answer.behaviours?.length) {
        descriptionComponent = (
          <ul className='ps-3'>
            {
              answer.behaviours.map(
                (beh) => (
                  <li key={beh?.id}>{beh?.name}</li>
                )
              )
            }
          </ul>
        )
      }
      break;

    case 'objective-question':
      if (answer.showKPIs && answer.kpis?.length) {

        let role = '';
        if (answer.objectiveName) {
          role += answer.objectiveName;
          if ((answer as any).weight) {
            role += ` | ${(answer as any).weight}%`;
          }
        }

        descriptionComponent = (
          <KPIWidgetBody
            role={role}
            docType={(answer as any).docType}
            kpis={answer.kpis} index={index || 0}
          />
        )
      }
      break;

    default:

      if (answer.showDescription && answer.description) {
        descriptionComponent = (<p> {answer.description} </p>)
      }
      break;
  }

  return (descriptionComponent);

}


const TextAnswer: React.FC<IAnswerProps> = ({ answer }) => {
  return (
    <div>
      {/* <h5> {answer.label} </h5> */}
      {
        answer.value
          ? <p style={{ wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: toHTML(answer.value, { hrefTarget: '_blank' }) }}></p>
          : <p><i> Not Answered </i></p>
      }
    </div>
  );
};


const MultiSelectAnswer: React.FC<IAnswerProps> = ({ answer, multiCampaign, currentReviewer }) => {

  const metadata = useContext(MetaDataContext) as IMetadata;

  const multiQuestionTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
  const multiQuestionTypeOptions = metadata.multiSelectQuestionOptionsTypes.options;

  const answerValue = getMultiSelectAnswerValue(answer, multiQuestionTypeOptions, multiQuestionTypeConstants);

  const isObjectiveQuestion = answer.type === 'objective-question';

  const reviewerName = (answer as any).reviewerName;
  const reviewTitle = (answer as any).title;
  const showTitle = reviewerName && reviewTitle;

  return (
    <div key={answer.id}>

      <Form.Text muted style={{ fontSize: "15px" }}>
        <RevieweeFormQuestionDescription answer={answer} />
      </Form.Text>

      <Card className='my-2' key={answer.id}>
        {
          showTitle && (
            <Card.Header
              aria-expanded={true}
              className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
            >
              <Card.Title className='m-0' as='h6'>
                {reviewerName}'s {reviewTitle} review {(answer as any).state === "pending" ? " | ⚠️ This review is not submitted yet" : ""}
              </Card.Title>
            </Card.Header>
          )
        }

        <Card.Body>
          {
            isObjectiveQuestion && (
              <>
                {
                  multiCampaign && (
                    <>
                      <h5>{currentReviewer ? `${currentReviewer}'s Review: ` : "Your Review: "}</h5>
                    </>
                  )
                }
              </>
            )
          }
          <p>
            {isObjectiveQuestion ? "Score: " : ""} {answerValue || "Not Answered "}
          </p>

          {
            answer.commentValue &&
            <>
              <p className='m-0 fw-bold'>Comment</p>
              <p className='ws-pre-line'>{answer.commentValue}</p>
            </>
          }
          {
            answer.comments && answer.comments.map((comment) => {
              return (
                <div key={comment.id}>
                  <p className='mb-0'> <strong> {comment.title || "Comment:"} </strong> </p>
                  <div className='mb-1'>
                    {
                      comment.value
                        ? <p style={{ wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: toHTML(comment.value, { hrefTarget: '_blank' }) }}></p>
                        : <p>n/a</p>
                    }
                  </div>
                </div>
              )
            })
          }
        </Card.Body>
      </Card>
    </div>
  );
};


export const AnswerPreview: React.FC<IAnswerProps> = ({ answer, multiCampaign, currentReviewer }) => {

  let AnswerComponent = null;

  switch (answer.type) {

    case 'short-text':
    case 'long-text': {
      AnswerComponent = TextAnswer;
      break;
    }

    case 'choice':
    case 'objective-question':
    case 'competency-question': {
      AnswerComponent = MultiSelectAnswer;
      break;
    }

  }

  return AnswerComponent
    ? (
      <AnswerComponent
        answer={answer}
        multiCampaign={multiCampaign}
        currentReviewer={currentReviewer}
      />
    ) : null;
};

export const LinkedAnswerPreview: React.FC<IAnswerProps> = ({ answer }) => {

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  let title = '';

  if (answer.showKPIs && answer.kpis?.length) {

    if (answer.objectiveName) {
      title += answer.objectiveName;
      if ((answer as any).weight) {
        title += ` | ${(answer as any).weight}%`;
      }
    }
  }

  if (!title) {
    title = `Preview: ${(answer as any).title} | Reviewer: ${(answer as any).reviewerName}`;
  }

  return (
    <Card className='my-2' key={`${answer.id}-${title}`}>
      <Card.Header
        aria-expanded={!isCollapsed}
        className='d-flex justify-content-between align-items-center cursor-pointer py-2 px-3 bg-light'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Card.Title className='m-0' as='h6'>
          {title}
        </Card.Title>
        <Button variant='link' size='sm'>
          <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
        </Button>
      </Card.Header>

      <Collapse in={!isCollapsed}>
        <Card.Body>
          <AnswerPreview answer={answer} />
        </Card.Body>
      </Collapse>
    </Card>
  );
};