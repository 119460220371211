import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { CustomModal, FormField, SubmitButton } from 'components';
import { FormContext } from 'contexts';
import { goalsApprovalAndCompletion } from 'data';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  IGoal,
  IGoalApprovalFormValues,
  IModalProps,
} from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';


interface ICreateUpdateGoalModalProps extends IModalProps {
  goalToUpdate: IGoal;
  profileVieweeTeambleUserId: string;
  relationshipId?: string;
  onCreateOrUpdateGoal: (goal: IGoal) => void;
}


interface ICreateUpdateGoalFormProps {
  goalToUpdate: IGoal;
  profileVieweeTeambleUserId: string;
  relationshipId?: string;
  onClose: IModalProps['onClose'];
  onCreateOrUpdateGoal: (goal: IGoal) => void;
}

const GoalsAction: React.FC<ICreateUpdateGoalFormProps> = ({
  onClose,
  goalToUpdate,
  relationshipId,
  onCreateOrUpdateGoal,
  profileVieweeTeambleUserId,

}) => {

  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  const getGoalFormRequestData = (formValues: IGoalApprovalFormValues, goalToUpdate: IGoal): IGoalApprovalFormValues => {
    return {
      id: goalToUpdate?.id || "",
      approvalStatus: Boolean(formValues?.approvalStatus),
      completionStatus: Boolean(formValues?.completionStatus),
    }
  };

  const submitGoalForm = (values: IGoalApprovalFormValues, formikHelpers: FormikHelpers<IGoalApprovalFormValues>): void => {

    const requestData = getGoalFormRequestData(values, goalToUpdate);

    formikHelpers.setSubmitting(true);

    const { id, ...approvals } = requestData;
    goalsApprovalAndCompletion({
      id,
      approvals,
      relationshipId,
      profileVieweeTeambleUserId,
      type: "goal-approvals"
    })
      .pipe(
        finalize(() => formikHelpers.setSubmitting(false))
      )
      .subscribe(
        (response) => {
          formikHelpers.resetForm();
          toast.success(response.data.message);
          onCreateOrUpdateGoal(response.data.data[0]);
          onClose();
        }
      );
  };


  const getGoalFormInitialValues = (goal: IGoal): IGoalApprovalFormValues => {
    return {
      id: goal.id,
      approvalStatus: Boolean(goal?.approvalStatus),
      completionStatus: Boolean(goal?.completionStatus),
    }
  };


  // Form Hook
  const goalForm = useFormik<IGoalApprovalFormValues>({
    initialValues: getGoalFormInitialValues(goalToUpdate),
    validate: (values) => {
      const errors: FormikErrors<IGoalApprovalFormValues> = {};
      if (values.completionStatus && !values.approvalStatus) {
        errors.completionStatus = 'Goal cannot be completed without approval';
      }
      return errors;
    },
    onSubmit: submitGoalForm
  });

  return (
    <Form onSubmit={goalForm.handleSubmit} >
      <Modal.Body>
        <FormContext.Provider value={goalForm}>

          <div className='d-flex flex-direction-row'>
            <FormField
              label='Approve Goal'
              controlId={`approvalStatus`}
              type={FormFieldTypeConstants.Checkbox}
              controlName={`approvalStatus`}
            />
            {goalToUpdate?.approvalStatus && (
              <Form.Label
                style={{ marginLeft: "10px" }}
              >
                (By {goalToUpdate?.approvalActionBy})
              </Form.Label>
            )}
          </div>

          <div className='d-flex flex-direction-row'>
            <FormField
              label='Complete Goal'
              controlId={`completionStatus`}
              type={FormFieldTypeConstants.Checkbox}
              controlName={`completionStatus`}
            />
            {goalToUpdate?.completionStatus && (
              <Form.Label
                style={{ marginLeft: "10px" }}
              >
                (By {goalToUpdate?.completionActionBy})
              </Form.Label>
            )}
          </div>

        </FormContext.Provider>
      </Modal.Body>

      <Modal.Footer className="justify-content-end px-4">
        {
          Object.values(goalForm.errors).map((value, index) => {
            return (
              <Form.Control.Feedback type="invalid" className='d-block'>
                {value}
              </Form.Control.Feedback>
            )
          })
        }
        <Button type='button' variant="outline-primary" onClick={onClose}>
          Close
        </Button>
        <SubmitButton
          isSubmitting={goalForm.isSubmitting}
          text='Save'
          submittingText='Saving...'
        />
      </Modal.Footer>
    </Form>
  );
};


const CreateUpdateGoalModal: React.FC<ICreateUpdateGoalModalProps> = ({
  show,
  onClose,
  goalToUpdate,
  relationshipId,
  onCreateOrUpdateGoal,
  profileVieweeTeambleUserId,
}) => {

  return (
    <CustomModal
      title={'Goals Action'}
      show={show}
      onClose={onClose}
    >
      <GoalsAction
        goalToUpdate={goalToUpdate}
        relationshipId={relationshipId}
        profileVieweeTeambleUserId={profileVieweeTeambleUserId}
        onClose={onClose}
        onCreateOrUpdateGoal={onCreateOrUpdateGoal}
      />
    </CustomModal>
  );
};

export default CreateUpdateGoalModal;