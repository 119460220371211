import { Button, Modal } from '@themesberg/react-bootstrap';
import Logo from 'assets/img/brand/gradient-teamble-logo.png';
import { ConfirmationModal, CustomModal, DataState } from 'components';
import { getReportsByPerformanceReviewIds } from 'data';
import downloadjs from 'downloadjs';
import { IModalProps, IReport, IResponse } from 'interfaces';
import * as React from 'react';
import { finalize } from 'rxjs';

import { If, List, ReviewReportsPreviewModalBody } from '@common/components';
import { toast } from 'react-toastify';


interface IReviewReportsPreviewModalProps extends IModalProps {
  showDownloadAction?: boolean;
  reviewIds: string[];
}


const ReviewReportsPreviewModal: React.FC<IReviewReportsPreviewModalProps> = (
  { reviewIds, show, showDownloadAction = false, onClose }
) => {


  const [isReportsLoading, setIsReportsLoading] = React.useState<boolean>(false);
  const [isReportsDownloading, setIsReportsDownloading] = React.useState<boolean>(false);
  const [reports, setReports] = React.useState<IReport[]>([]);
  const [confirmDownload, setConfirmDownload] = React.useState<boolean>(false);

  React.useEffect(
    () => {

      if (!reviewIds.length) {
        return;
      }

      setIsReportsLoading(true);
      setReports([]);

      const subscription = getReportsByPerformanceReviewIds(reviewIds, false).pipe(
        finalize(
          () => setIsReportsLoading(false)
        )
      ).subscribe(
        (response) => {
          setReports((response.data as IResponse<IReport[]>).data);
        }
      );

      return () => subscription.unsubscribe();
    },
    [reviewIds]
  );


  const downloadReports = (): void => {

    if (!reviewIds.length) {
      return;
    }

    setIsReportsDownloading(true);

    getReportsByPerformanceReviewIds(reviewIds, true).pipe(
      finalize(
        () => setIsReportsDownloading(false)
      )
    ).subscribe(
      (response) => {
        // downloadjs(response.data as Blob, 'report.pdf', 'application/pdf');
        toast.success("Report will be emailed once completed");
        onClose();
      }
    );
  };


  return (
    <>
      <CustomModal
        show={show}
        onClose={onClose}
        size='xl'
        title={<img height='30' src={Logo} alt="Teamble Logo" />}
      >
        <Modal.Body
          className="px-5"
          style={{ height: isReportsLoading || !(reports && reports.length) ? '100px' : 'auto' }}
        >
          <DataState
            isDataLoading={isReportsLoading}
            isDataAvailable={!!reports?.length}
            emptyStateMessage='No reports available!'
          >
            <List
              items={reports}
              renderItem={
                (report) =>
                  <ReviewReportsPreviewModalBody
                    key={report.id}
                    report={report} />
              }
            />
          </DataState>
        </Modal.Body>

        <Modal.Footer className="justify-content-end px-4">
          <Button variant="outline-primary" onClick={onClose}>
            Close
          </Button>
          <If condition={showDownloadAction}>
            <Button variant="primary" disabled={isReportsDownloading} onClick={()=> setConfirmDownload(true)}>
              {isReportsDownloading ? 'Downloading...' : 'Download'}
            </Button>
          </If>
        </Modal.Footer>

      </CustomModal>
      {
        confirmDownload &&
        <ConfirmationModal
          title='Confirm download ?'
          show={confirmDownload}
          onPositiveResponse={() => {
            downloadReports();
          }}
          onCloseClick={() => setConfirmDownload(false)}
        >
          <p>
            Are you sure to download the report ?
          </p>
        </ConfirmationModal >
      }
    </>
  )
};

export default ReviewReportsPreviewModal;