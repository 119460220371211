import React from "react";
import { Col, Row, Button, Container } from '@themesberg/react-bootstrap';
import { Link } from "react-router-dom";
import { Routes } from "routes";

const AuthorizationError: React.FunctionComponent<{}> = () => {
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} className="text-center">
              <h1 className="text-primary mt-5">
                Please select the correct workspace.
                You are not authorized for the selected workspace to use the <br /> Teamble Admin.
              </h1>
              <a href="https://teamble.com/#/contact">
                <Button variant="outline-primary">
                  Contact Us
                </Button>
              </a>
              <Link to={Routes.Signin.path}>
                <Button className='ms-3'>
                  Go back to Sign In
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default AuthorizationError;