import Axios, { AxiosObservable } from 'axios-observable';
import {
  BehaviourTypes,
  IFeedbackAnalyticsRequestData,
  IFeedbackInsightsChartData,
  IFeedbackInsightsChartDataResponse,
  IFeedbackInsightsOverview,
  IFeedbackLeaderBoardUser,
  IResponse,
  ISurveyDepartmentInsight,
} from 'interfaces';
import { map } from 'rxjs';

export const getSurveyInsightsByDepartment =
  (surveyId: string): AxiosObservable<IResponse<ISurveyDepartmentInsight[]>> => {

    return Axios.post<IResponse<ISurveyDepartmentInsight[]>>(
      "/survey-insights-by-department",
      { surveyId }
    );
  };


export const getFeedbackInsightsOverview =
  (data: IFeedbackAnalyticsRequestData): AxiosObservable<IResponse<IFeedbackInsightsOverview> | Blob> => {

    return Axios.post<IResponse<IFeedbackInsightsOverview> | Blob>(
      '/feedback-trends-v2',
      { ...data },
      {
        responseType: data.isReport ? 'arraybuffer' : 'json'
      }
    );
  };

export const getFeedbackInsightsCompetency =
  (data: IFeedbackAnalyticsRequestData): AxiosObservable<IResponse<IFeedbackInsightsChartData>> => {

    return Axios.post<IResponse<IFeedbackInsightsChartDataResponse>>(
      '/feedback-trends-competency-v2',
      { ...data }
    ).pipe(
      map(
        (response) => {

          return {
            ...response,
            data: {
              ...response.data,
              data: {
                competency: response.data.data.competency,
                strengthCompetency: response.data.data.strengthCompetency.map(
                  (comp) => ({
                    ...comp,
                    behaviourType: BehaviourTypes.Strength
                  })
                ),
                developmentCompetency: response.data.data.developmentCompetency.map(
                  (comp) => ({
                    ...comp,
                    behaviourType: BehaviourTypes.DevelopmentArea
                  })
                ),
              }
            }
          }

        }
      )
    );
  };


export const getFeedbackInsightsLeaderboard =
  (data: IFeedbackAnalyticsRequestData): AxiosObservable<IResponse<IFeedbackLeaderBoardUser[]>> => {

    return Axios.post<IResponse<IFeedbackLeaderBoardUser[]>>(
      "/leader-board-v2",
      { ...data },
      {
        responseType: data.isReport ? 'arraybuffer' : 'json'
      }
    );
  };