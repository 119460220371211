import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { AxiosObservable } from 'axios-observable';
import { CustomModal, FormField, useUserProfile } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { FormikHelpers, useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  ICreateUpdateTemplateFormValues,
  ICreateUpdateTemplateRequestData,
  IFormQuestionValue,
  IMetadata,
  IModalProps,
  IResponse,
  ITemplate,
  ITemplateVisibilityConstants,
} from 'interfaces';
import * as React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { TEMPLATE_VISIBILITY_METADATA } from 'teamble-constants';
import { mapFormValuesToQuestions } from 'utils';
import { CREATE_UPDATE_TEMPLATE_VALIDATION_SCHEMA } from 'validation-schemas';


interface ICreateUpdateTemplateModalProps extends IModalProps {
  title: string;
  templateId?: string;
  templateValues: ITemplate;
  workspaceOrChannelId?: string;
  templateQuestions: IFormQuestionValue[];
  updateTemplateMethod: (data: ICreateUpdateTemplateRequestData) => AxiosObservable<IResponse<ITemplate>>;
  createTemplateMethod: (data: ICreateUpdateTemplateRequestData) => AxiosObservable<IResponse<ITemplate>>;
  onTemplateUpdate?: (template: ITemplate) => void;
}


const CreateUpdateTemplateModal: React.FunctionComponent<ICreateUpdateTemplateModalProps> = (
  {
    show, title, templateValues, templateQuestions, templateId, workspaceOrChannelId,
    updateTemplateMethod, createTemplateMethod, onTemplateUpdate, onClose
  }
) => {

  const { userProfile } = useUserProfile();
  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const templateVisibilityConstants = TEMPLATE_VISIBILITY_METADATA.constants as ITemplateVisibilityConstants;


  const submitTemplateForm = (
    values: ICreateUpdateTemplateFormValues, formikHelpers: FormikHelpers<ICreateUpdateTemplateFormValues>
  ): void => {

    formikHelpers.setSubmitting(true);

    const requestData: ICreateUpdateTemplateRequestData = {
      title: values.title,
      desc: values.desc,
      visibleToEveryone: values.visibility === templateVisibilityConstants.EVERYONE,
      visibleToTeambleTeamAdmin: values.visibility === templateVisibilityConstants.ADMINS,
      blocks: mapFormValuesToQuestions(templateQuestions, metadata),
    };

    const templateSource$ =
      templateId
        ?
        updateTemplateMethod({ templateId, ...requestData })
        :
        createTemplateMethod({ ...requestData })

    templateSource$
      .pipe(
        finalize(() => formikHelpers.setSubmitting(false))
      )
      .subscribe(
        (response) => {
          toast.success(response.data.message);
          if (onTemplateUpdate && templateId) {
            onTemplateUpdate(response.data.data);
          }
          onClose();
        }
      );
  };


  const createUpdateTemplateForm = useFormik<ICreateUpdateTemplateFormValues>(
    {
      initialValues: {
        title: templateValues.title,
        desc: templateValues.desc,
        visibility: (templateValues.visibleToEveryone && templateVisibilityConstants.EVERYONE) ||
          (templateValues.visibleToTeambleTeamAdmin && templateVisibilityConstants.ADMINS) ||
          templateVisibilityConstants.SUPER_ADMIN,
      },
      validationSchema: CREATE_UPDATE_TEMPLATE_VALIDATION_SCHEMA,
      onSubmit: submitTemplateForm,
    }
  );


  return (
    <CustomModal
      show={show}
      title={title}
      onClose={onClose}
    >

      <FormContext.Provider value={createUpdateTemplateForm}>
        <Form onSubmit={createUpdateTemplateForm.handleSubmit}>
          <Modal.Body>

            <FormField
              placeholder='Enter template name'
              label='Template Name *'
              controlId='templateTitleField'
              type={FormFieldTypeConstants.Text}
              controlName='title' />

            <FormField
              placeholder='Enter description'
              label='Description'
              controlId='templateDescriptionField'
              type={FormFieldTypeConstants.Text}
              controlName='desc' />

            {
              userProfile!.isSuperAdmin &&
              <FormField
                controlName='visibility'
                controlId='templateVisibilityField'
                type={FormFieldTypeConstants.Radio}
                options={TEMPLATE_VISIBILITY_METADATA.options} />
            }

          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-end'>
            <Button type='button' variant="outline-primary" onClick={onClose}>
              Close
            </Button>
            <Button type='submit' disabled={createUpdateTemplateForm.isSubmitting}>
              {
                createUpdateTemplateForm.isSubmitting ?
                  templateId ? 'Updating Template...' : 'Creating Template...' :
                  templateId ? 'Update Template' : 'Create Template'
              }
            </Button>
          </Modal.Footer>
        </Form>
      </FormContext.Provider>

    </CustomModal>
  );
};

export default CreateUpdateTemplateModal;
