import { Divider } from 'components';
import { IFeedback, IFeedbackMaps } from 'interfaces';
import React from 'react'

interface IFeedbackStrengthsSectionProps {
  feedback: IFeedback;
  feedbackMaps: IFeedbackMaps;
}

const FeedbackStrengthsSection: React.FC<IFeedbackStrengthsSectionProps> = (
  { feedback, feedbackMaps }
) => {

  const [feedbackStrengths, setFeedbackStrengths] = React.useState<string[]>([]);


  React.useEffect(
    () => {

      if (!feedback.dimensionInfos?.length) {
        setFeedbackStrengths([]);
        return;
      }

      const strengths: string[] = [];

      for (const dimensionInfo of feedback.dimensionInfos) {

        if (!dimensionInfo.focusAreas) {
          continue;
        }

        const dimension = feedbackMaps.dimensionsMap[
          dimensionInfo.id
        ];
        if (!dimension) {
          continue;
        }

        for (const focusAreaInfo of dimensionInfo.focusAreas) {

          if (!focusAreaInfo.str) {
            continue;
          }

          const focusArea = feedbackMaps.focusAreasMap[
            focusAreaInfo.id
          ];

          if (!focusArea) {
            continue;
          }

          strengths.push(
            `${dimension.name} - ${focusArea.name}`
          );
        }
      }

      setFeedbackStrengths(strengths);

    },
    [feedback.dimensionInfos, feedbackMaps.dimensionsMap, feedbackMaps.focusAreasMap]
  );


  return (
    <>
      {
        feedbackStrengths.length
          ?
          <>
            <p>
              <strong>
                <span role='img' aria-label='Strengths' > 💪 </span> Strengths
              </strong>
            </p>
            {
              feedbackStrengths.map(
                (strength) => (
                  <p key={strength} className='m-0'> {strength} </p>
                )
              )
            }
            <Divider />
          </>
          :
          null
      }
    </>
  );
};

export default FeedbackStrengthsSection;