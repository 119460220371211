import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  ProgressBar,
  Table,
  Tooltip,
} from '@themesberg/react-bootstrap';
import { DataState, EmptyState } from 'components';
import { getCampaignCompletionStatus, generateChoices, downloadCampaignCompletionStatus } from 'data';
import { FileFormats, ICampaignCompletionStatusResponse, ICampaignViewingData, IRouteParams } from 'interfaces';
import * as React from 'react';
import { finalize } from 'rxjs';
import Logo from './../../assets/img/brand/logo.png';
import { useParams } from 'react-router-dom';
import downloadjs from 'downloadjs';


interface IReviewAnalyticsOverviewProps { }


interface ITooltipContentProps {
  rowData: ICampaignViewingData;
}


const TooltipContent: React.FC<ITooltipContentProps> = ({ rowData }) => {
  return (
    <>
      {
        rowData.choices.map(
          (choice, index) => (
            <div className='d-flex mb-1 align-items-center' key={index}>
              <div
                className='p-2 rounded-circle'
                style={{
                  backgroundColor: choice.backgroundColor,
                }}
              />
              <p className='text-white ms-2 mb-0'> {choice.title} </p>
            </div>
          )
        )
      }
    </>
  )
};

const TableBody: React.FunctionComponent<{ reviewStatusData: ICampaignViewingData[] }> = ({ reviewStatusData }) => {

  return (
    <tbody>
      {
        reviewStatusData.map((rowData, index) => (

          <tr key={rowData?.name + index}>

            <td style={{ whiteSpace: 'pre-line', }} className='border-0 py-4 fs-4'>
              {
                rowData.name
              }
            </td>

            <td className='border-0 px-0 py-4 text-center fw-bolder fs-5 align-middle rounded w-50'>
              {
                <OverlayTrigger
                  overlay={
                    <Tooltip id={rowData.id + 'ChartTooltip'}>
                      <TooltipContent rowData={rowData} />
                    </Tooltip>
                  }
                >
                  <ProgressBar style={{ height: '28px', maxHeight: '28px' }} className='w-100'>
                    {
                      rowData.choices.map((choice, index) => {
                        return (
                          <ProgressBar
                            key={index}
                            className='fw-bolder fs-6'
                            style={{
                              backgroundColor: choice.backgroundColor,
                              color: choice.color
                            }}
                            now={choice.percentCount}
                            label={choice.percentCount + '%'} />
                        );
                      })
                    }
                  </ProgressBar>
                </OverlayTrigger>
              }
            </td>
          </tr>
        ))
      }
    </tbody>
  )
};

const ReviewAnalyticsOverviewWidget: React.FunctionComponent<{
  reviewStatusData: ICampaignCompletionStatusResponse,
  activeReviewId: string
}> = ({ reviewStatusData, activeReviewId }) => {

  const [showNomination, setShowNominaton] = React.useState(false);
  const [filteredReviewStatusData, setFilteredReviewStatusData] = React.useState<ICampaignViewingData[]>([]);
  const [downloading, setDownloading] = React.useState(false);

  React.useEffect(() => {

    let rows = reviewStatusData?.data?.map(value => {
      const { nominationCounts, nominationCount, reviewCount, reviewCounts, ...rest } = value;
      return {
        ...rest,
        count: showNomination ? nominationCount : reviewCount,
        counts: showNomination ? nominationCounts : reviewCounts,
      }
    }).filter(value => Boolean(value.count)).map(row => {
      return {
        ...row,
        choices: generateChoices(row)
      }
    });

    setFilteredReviewStatusData(rows);

  }, [showNomination]);


  const downloadReport = () => {

    setDownloading(true);

    downloadCampaignCompletionStatus({
      reportType: showNomination ? "nomination" : "review",
      PRCampaignId: activeReviewId
    }).pipe(
      finalize(() => setDownloading(false))
    ).subscribe(
      (response) => {
        downloadjs(
          response.data,
          'report.xlsx',
          FileFormats.XLSX
        );
      }
    );
  }

  return (
    <Card className='overflow-hidden h-100'>
      <Card.Header className='d-flex flex-shrink-0 align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <img className='rounded' width='70' height='70' src={Logo} alt='Teamble Logo' />
          <h3 className='fw-bolder ms-3 mb-0'> {"Review Status Analytics"} </h3>
        </div>
        <div className='d-flex align-items-center'>
          {Boolean(reviewStatusData?.isNomination) && (
            <div>
              <Form.Check
                type="switch"
                id="nominationBarSwitch"
                label="Nomination"
                checked={showNomination}
                onChange={() => setShowNominaton(!showNomination)}
              />
            </div>
          )}
          <Button disabled={downloading} className='ms-2' onClick={downloadReport}>Download</Button>
        </div>

      </Card.Header>

      {
        Boolean(filteredReviewStatusData.length)
          ? <Card.Body className='flex-grow-1 overflow-auto'>
            <Table
              style={{
                borderCollapse: 'separate',
                borderSpacing: '6px',
              }}
            >
              <TableBody reviewStatusData={filteredReviewStatusData} />
            </Table>
          </Card.Body>
          : <EmptyState message='No data available' />
      }

    </Card>
  )
};


const ReviewStatusAnalytics: React.FunctionComponent<IReviewAnalyticsOverviewProps> = () => {

  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);
  const [reviewStatusData, setReviewStatusData] = React.useState<ICampaignCompletionStatusResponse>({ isNomination: false, data: [] });

  const { activeReviewId } = useParams<IRouteParams>();


  React.useEffect(
    () => {

      if (!activeReviewId) return;
      setIsDataLoading(true);

      const subscription = getCampaignCompletionStatus({ PRCampaignId: activeReviewId }).pipe(
        finalize(() => setIsDataLoading(false))
      ).subscribe(
        (response) => {
          setReviewStatusData(response.data.data);
        }
      );

      return () => subscription.unsubscribe();
    },
    [activeReviewId]
  );

  if (!activeReviewId) return (
    <div className='overflow-hidden position-relative h-100'>
      <EmptyState message={'No data available'} />
    </div>
  );

  return (
    <div className='overflow-hidden position-relative h-100'>
      <DataState
        isDataLoading={isDataLoading}
        isDataAvailable={Boolean(reviewStatusData?.data?.length)}
        emptyStateMessage='No data available'
      >
        <ReviewAnalyticsOverviewWidget reviewStatusData={reviewStatusData} activeReviewId={activeReviewId} />
      </DataState>
    </div>
  );
};

export default ReviewStatusAnalytics;
