import { Button, ButtonGroup } from '@themesberg/react-bootstrap';
import React from 'react';

enum ActiveSelectField {
  Select = 'select',
  Upload = 'upload'
}

interface IFeedbackLoopManuallyDefineActionsProps {
  isLoading: boolean;
  activeSelectField: ActiveSelectField;
  isAudiencePairsAvailable: boolean;
  onClearAllClick: () => void;
  onActiveSelectFieldChange: (activeSelectField: ActiveSelectField) => void;
}

const FeedbackLoopManuallyDefineActions: React.FC<IFeedbackLoopManuallyDefineActionsProps> = (
  {
    isLoading, activeSelectField, isAudiencePairsAvailable,
    onClearAllClick, onActiveSelectFieldChange
  }
) => {

  return (
    <div className='d-flex justify-content-between align-items-center mb-2'>
      <h5>Feedback Senders/Receivers</h5>
      <div>
        <ButtonGroup>

          <Button
            type='button'
            disabled={isLoading}
            variant={`outline-primary ${activeSelectField === ActiveSelectField.Select ? 'active' : ''}`}
            onClick={() => onActiveSelectFieldChange(ActiveSelectField.Select)}
          >
            Select
          </Button>

          <Button
            type='button'
            disabled={isLoading}
            variant={`outline-primary ${activeSelectField === ActiveSelectField.Upload ? 'active' : ''}`}
            onClick={() => onActiveSelectFieldChange(ActiveSelectField.Upload)}
          >
            Upload
          </Button>

        </ButtonGroup>
        {
          isAudiencePairsAvailable &&
          <Button
            type='button'
            disabled={isLoading}
            className='ms-2'
            variant='outline-danger'
            onClick={onClearAllClick}
          >
            Clear All
          </Button>
        }
      </div>
    </div>
  );
};

export default FeedbackLoopManuallyDefineActions;