import {
  Column,
  TableInstance,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';

export const useTmblTable = <T extends object>(
  columns: readonly Column<T>[],
  data: readonly T[],
  initialSortId?: string,
  sortByDesc: boolean = false,
): TableInstance<T> => {

  return useTable<T>(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetFilters: false,
      disableSortRemove: true,
      autoResetGlobalFilter: false,
      initialState: initialSortId
        ?
        {
          sortBy: [
            {
              id: initialSortId,
              desc: sortByDesc
            }
          ]
        }
        :
        undefined
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

}