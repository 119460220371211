import { Card } from '@themesberg/react-bootstrap';
import { EmptyState, PageTitle, Preloader, PrimaryLayoutBox } from 'components';
import { getPredefinedPerformanceReviewTemplates } from 'data';
import { IReviewTemplate } from 'interfaces';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import { BUILD_FROM_SCRATCH_REVIEW_TEMPLATE } from 'teamble-constants';


interface IReviewTemplateCardProps {
  template: IReviewTemplate;
}


const ReviewTemplateCard: React.FunctionComponent<IReviewTemplateCardProps> = (
  { template }
) => {

  return (
    <Link
      key={template.id}
      to={
        template.id === 'build-from-scratch' ?
          `${Routes.PerformanceReviewQuestionnaires.path}/new` :
          `${Routes.PerformanceReviewTemplates.path}/${template.id}`
      }
    >
      <Card className='teamble-template-card'>
        <Card.Body className='d-flex flex-column justify-content-between'>
          <img width='50' height='50' className='icon-img' src={template.logoUrl} alt={template.title} />
          <h5 className='flex-shrink-0 mb-0'> {template.title} </h5>
        </Card.Body>
      </Card>
    </Link>
  );
};


const PerformanceReviewTemplates: React.FunctionComponent<{}> = () => {


  const [templates, setTemplates] = React.useState<IReviewTemplate[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  React.useEffect(
    () => {

      setIsLoading(true);
      setTemplates([]);

      getPredefinedPerformanceReviewTemplates()
        .pipe(
          finalize(
            () => setIsLoading(false)
          )
        )
        .subscribe(
          {
            next: (response) => {
              setTemplates([
                BUILD_FROM_SCRATCH_REVIEW_TEMPLATE as IReviewTemplate,
                ...response.data.data
              ]);
            }
          }
        );
    },
    []
  );


  return (
    <>
      <PageTitle title={Routes.PerformanceReviewTemplates.title} />
      <PrimaryLayoutBox title={Routes.PerformanceReviewTemplates.title}>
        {
          isLoading ?
            (<Preloader show={isLoading} />) :
            (
              <>
                {
                  !templates.length ?
                    (
                      <EmptyState message={<>No Questionnaire found.</>} />
                    ) :
                    (
                      <div className='d-flex flex-wrap flex-grow-1 overflow-y-auto'>
                        {
                          templates.map(
                            (template) => {
                              return (
                                <ReviewTemplateCard key={template.id} template={template} />
                              )
                            }
                          )
                        }
                      </div>
                    )
                }
              </>
            )
        }
      </PrimaryLayoutBox>
    </>
  );
};

export default PerformanceReviewTemplates;
