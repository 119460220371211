import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button } from '@themesberg/react-bootstrap';
import { CustomSelect, DataState, PermissionCheck, useUserProfile } from 'components';
import { createMemberManagers, getMemberManagers, removeMemberManager } from 'data';
import { useApp, useCompanyMemberMenuOptions } from 'hooks';
import { IMemberManager, IMemberOption, IUserDetailsContext, UserPermissions } from 'interfaces';
import React, { useEffect } from 'react';
import { finalize } from 'rxjs';

import { useUserDetails } from '../hooks/useUserDetails';
import { useCompanyConfig } from '@common/modules/companyConfig';

interface IUserManagersAccordionProps { }

const UserManagersAccordion: React.FC<IUserManagersAccordionProps> = () => {

  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [managersList, setManagersList] = React.useState<IMemberManager[]>([]);
  const [memberOptions, setMemberOptions] = React.useState<IMemberOption[]>([]);
  const [isManagersUpdating, setIsManagersUpdating] = React.useState<boolean>(false);
  const [selectedManagers, setSelectedManagers] = React.useState<IMemberOption[]>([]);

  // App hook
  const app = useApp();

  // User-Profile Hook
  const { userProfile } = useUserProfile();

  // Member Details
  const {
    userDetails: memberDetails,
  } = useUserDetails() as IUserDetailsContext;

  // Member Options
  const {
    isLoading: isCompanyMembersLoading,
    memberOptions: companyMemberOptions
  } = useCompanyMemberMenuOptions();

  const companyConfig = useCompanyConfig();

  // Get the member managers list
  useEffect(
    () => {

      if (!memberDetails.teambleUserId) {
        return;
      }

      setIsLoading(true);
      const subscription = getMemberManagers(memberDetails.teambleUserId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setManagersList(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    [memberDetails.teambleUserId]
  );


  // Remove existing managers from the dropdown list
  React.useEffect(
    () => {

      if (!companyMemberOptions.length) {
        return;
      }

      if (!managersList.length) {
        setMemberOptions(companyMemberOptions);
        return;
      }

      const managerIds = managersList.map(({ teambleUserId }) => teambleUserId);
      const filteredMemOptions = companyMemberOptions
        .filter((member) => !managerIds.includes(member.teambleUserId || ''));
      setMemberOptions(filteredMemOptions);

    },
    [app, companyMemberOptions, managersList]
  );


  const handleUserManagerDelete = (
    managerToDelete: IMemberManager,
    index: number
  ): void => {

    setIsManagersUpdating(true);
    removeMemberManager(
      memberDetails.teambleUserId as string,
      [managerToDelete.teambleUserId]
    )
      .pipe(
        finalize(() => setIsManagersUpdating(false))
      )
      .subscribe(
        (response) => {
          setManagersList([
            ...managersList.slice(0, index),
            ...managersList.slice(index + 1)
          ]);
          setSelectedManagers([]);
        }
      );
  };


  const handleUserManagerAdd = (managers: IMemberOption[]): void => {

    const selectedManagerIds = managers.map(({ teambleUserId }) => teambleUserId as string);

    setIsManagersUpdating(true);
    createMemberManagers(
      memberDetails.teambleUserId as string,
      selectedManagerIds
    )
      .pipe(
        finalize(() => setIsManagersUpdating(false))
      )
      .subscribe(
        (response) => {
          setManagersList([
            ...managersList,
            ...response.data.data
          ]);
          setSelectedManagers([]);
        }
      );
  };



  return (
    <div
      className='position-relative'
      style={{ height: isLoading ? 100 : '' }}
    >
      <DataState
        isDataLoading={isLoading}

        // When user is checking own profile and no managers assigned for him
        isDataAvailable={userProfile?.teambleUserId !== memberDetails.teambleUserId || !!managersList.length}

        emptyStateMessage={<>No managers assigned!</>}
      >
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {companyConfig?.defaultRelationshipName || "Managers"}
            </Accordion.Header>
            <Accordion.Body>
              {
                managersList.map(
                  (manager, index) => (
                    <div key={manager.teambleUserId} className="d-flex justify-content-between align-items-center mb-1">
                      <span>{manager.name}</span>
                      {
                        userProfile?.teambleUserId !== memberDetails.teambleUserId &&
                        <PermissionCheck permission={UserPermissions.UpdateUserManagers}>
                          <Button
                            disabled={isManagersUpdating}
                            variant='link'
                            size='sm'
                            onClick={() => handleUserManagerDelete(manager, index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </PermissionCheck>
                      }
                    </div>
                  )
                )
              }
              {
                userProfile?.teambleUserId !== memberDetails.teambleUserId &&
                <PermissionCheck permission={UserPermissions.UpdateUserManagers}>
                  <div className="d-flex justify-content-between align-items-start mt-2 text-left">
                    <CustomSelect
                      isMulti
                      isSearchable
                      className='flex-grow-1'
                      placeholder={'Select Managers...'}
                      options={memberOptions}
                      isLoading={isCompanyMembersLoading}
                      value={selectedManagers}
                      onChange={(options) => setSelectedManagers(options as IMemberOption[])}
                    />
                    <Button
                      disabled={isCompanyMembersLoading || isManagersUpdating}
                      className='flex-shrink-0 ms-2'
                      onClick={() => handleUserManagerAdd(selectedManagers)}
                    >
                      Add
                    </Button>
                  </div>
                </PermissionCheck>
              }

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </DataState>
    </div>
  );
};

export default UserManagersAccordion;