import { Modal, Table } from '@themesberg/react-bootstrap';
import { CustomModal } from 'components';
import { IModalProps } from 'interfaces';
import React from 'react'

interface IInvalidPairsModalProps extends IModalProps {
  title?: string;
  description: string;
  invalidPairs: {
    firstMemberName: string;
    secondMemberName: string;
  }[];
  firstColumnName: string;
  secondColumnName: string;
}

const InvalidPairsModal: React.FC<IInvalidPairsModalProps> = (
  {
    title = 'Invalid Pairs', description = '', show, invalidPairs,
    firstColumnName, secondColumnName,
    onClose
  }
) => {

  return (
    <CustomModal
      title={title}
      show={show}
      onClose={onClose}
    >
      <Modal.Body>

        <p>{description}</p>

        <Table className="table-centered table-nowrap rounded mb-0 position-relative">
          <thead>
            <tr>
              <th>{firstColumnName}</th>
              <th>{secondColumnName}</th>
            </tr>
          </thead>
          <tbody>
            {
              invalidPairs.map(
                (invalidPair) => {
                  return (
                    <tr key={`${invalidPair.firstMemberName}${invalidPair.firstMemberName}`}>
                      <td className='p-3'>
                        {invalidPair.firstMemberName}
                      </td>
                      <td className='p-3'>
                        {invalidPair.secondMemberName}
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>
      </Modal.Body>
    </CustomModal>
  );
};

export default InvalidPairsModal;