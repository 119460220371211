import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { AxiosObservable } from 'axios-observable';
import { FormField, Preloader } from 'components';
import { FormContext } from 'contexts';
import { createSurveyFromScratch, createSurveyLibraryTemplateFromExistedTemplate, getSlackWorkspacesAsMenuOptions, getMsTeamsChannelsAsMenuOptions } from 'data';
import { FormikHelpers, useFormik } from 'formik';
import { useOptionsAndSelectedOption } from 'hooks';
import { FormErrors, FormFieldTypeConstants, IChannelMenuOption, ICreateSurveyFormValues, IModalProps, IResponse, ISurvey, SignInWith } from 'interfaces';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from 'validation-schemas';
import * as Yup from 'yup';
import { Routes } from 'routes';
import { useApp } from 'hooks';

interface ICreateNewSurveyModalProps extends IModalProps {
  type: "predefined" | "custom";
  templateId: string;
  isBuildFromScratch?: boolean;
}

const CreateNewSurveyModal: React.FunctionComponent<ICreateNewSurveyModalProps> = (
  { show, type, templateId, isBuildFromScratch = false, onClose }
) => {

  const history = useHistory();
  const app = useApp();


  const createNewSurvey =
    (values: ICreateSurveyFormValues, formikHelpers: FormikHelpers<ICreateSurveyFormValues>): void => {

      formikHelpers.setSubmitting(true);

      let createSurveySource$: AxiosObservable<IResponse<ISurvey>>;

      if (isBuildFromScratch) {
        createSurveySource$ = createSurveyFromScratch({
          title: 'New Survey',
          [app === SignInWith.Slack ? "workspaceId" : "channelId"]: values.workspaceOrChannel!.value,
        })
      } else {
        createSurveySource$ =
          createSurveyLibraryTemplateFromExistedTemplate(templateId, type, values.workspaceOrChannel!.value, app)
      }

      createSurveySource$
        .pipe(
          finalize(() => formikHelpers.setSubmitting(false))
        )
        .subscribe(
          (response) => {
            toast.success(response.data.message);
            history.push(`${Routes.SurveyLibrary.path}/${response.data.data.id}`);
            onClose();
          }
        );

    };


  const createNewSurveyForm = useFormik<ICreateSurveyFormValues>(
    {
      initialValues: {
        workspaceOrChannel: null,
      },
      validationSchema: Yup.object().shape({
        workspaceOrChannel: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.required(FormErrors.Required).nullable(),
      }),
      onSubmit: createNewSurvey
    }
  );


  const {
    options: workspaceOrChannelOptions,
    isLoading
  } = useOptionsAndSelectedOption<IChannelMenuOption>(
    app === SignInWith.Slack ? getSlackWorkspacesAsMenuOptions() : getMsTeamsChannelsAsMenuOptions()
  );


  React.useEffect(
    () => {

      if (!workspaceOrChannelOptions.length) {
        return;
      }

      createNewSurveyForm.setFieldValue('workspaceOrChannel', workspaceOrChannelOptions[0]);

    },
    [workspaceOrChannelOptions]
  )


  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          Create New Survey
        </Modal.Title>
      </Modal.Header>
      <FormContext.Provider value={createNewSurveyForm}>
        <Form onSubmit={createNewSurveyForm.handleSubmit}>
          <Modal.Body style={{ height: isLoading ? "100px" : "auto" }} >
            {
              isLoading
                ? <Preloader show={isLoading} />
                : <FormField
                  label={app === SignInWith.Slack ? 'Select slack workspace *' : 'Select MS teams channel *'}
                  controlId='selectWorkspaceField'
                  type={FormFieldTypeConstants.Select}
                  options={workspaceOrChannelOptions}
                  controlName='workspaceOrChannel'
                />
            }

          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-end'>
            <Button type='button' variant="outline-primary" onClick={onClose}>
              Close
            </Button>
            <Button type='submit' disabled={createNewSurveyForm.isSubmitting}>
              {createNewSurveyForm.isSubmitting ? 'Creating Survey...' : 'Create Survey'}
            </Button>
          </Modal.Footer>
        </Form>
      </FormContext.Provider>
    </Modal>
  );
};

export default CreateNewSurveyModal;
