import { ITemplateGroupType } from "./template.interface";

export interface ISurveyMenuOption extends IMenuOption {
  state?: string;
}

export interface IMenuOption extends IFixedMenuOption, IMemberOption, ISlackChannelMenuOption {
  options?: IMenuOption[];
  variant?: string;
  link?: string;
  id?: string;
  header?: string;
  launchedAt?: string;
  type?: string;
}

export interface ISlackChannelMenuOption extends ISimpleMenuOption {
  is_private?: boolean;
}

export interface ISimpleMenuOptionWithSubOptions extends ISimpleMenuOption {
  subOptions: ISimpleMenuOption[];
}

export interface IMemberOption extends ISimpleMenuOption {
  email?: string;
  teamble_id?: string;
  description?: string;
  imageOriginal?: string | null;
  membershipId?: string;
  teambleUserId?: string;
  departmentId?: string;
  id?: string;
  name?: string;
  isExcluded?: boolean;
}

export interface IFixedMenuOption extends ISimpleMenuOption {
  isFixed?: boolean;
}

export interface ISimpleMenuOption {
  value: string;
  label: string;
}

export interface ISimpleTemplateMenuOption {
  value: string;
  label: string;
  type: ITemplateGroupType | '';
}

export interface ISimpleTeamsMenuOption {
  id: string;
  header: string;
}

export interface IChannelMenuOption extends ISimpleMenuOption {
  companyId?: string;
  teambleTeamId?: string;
  teamId?: string;
}

export interface IMenuOptionWithText extends ISimpleMenuOption {
  options: ISimpleMenuOptionWithText[];
}

interface ISimpleMenuOptionWithText {
  value: string;
  text: string;
}

export enum ITableRowCommonActions {
  Duplicate = 'duplicate',
  Delete = 'delete',
  PreviewAndLaunch = 'previewAndLaunch'
}

export enum IActionTypes {
  Exclude = 'exclude',
  Include = 'include',
  Edit = 'edit',
  Remove = 'remove',
  Deactivate = 'deactivate'
}

export interface IChannelOrWorkspaceMemberOption {
  id: string;
  name: string;
  email?: string;
  realName: string;
  teambleTeamId: string;
  workspaceId: string;
  teambleUserId: string;
  departmentId: string;
  roleId: string;
  membershipId: string;
}

export enum ICommonTableRowActions {
  Edit = 'edit',
  Delete = 'delete',
}

export interface ITeamsGroupMenuOptions {
  id: string
  isCustom: boolean
  msteams_channel_name: string
  msteams_name: string
  name: string
}