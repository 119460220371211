import { faCheck, faCheckCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { Divider } from 'components';
import { IGoal } from 'interfaces';
import React from 'react'

interface IGoalsListProps {
  goalsList: IGoal[];
  showGoalActions?: boolean;
  onEditGoalClick?: (goal: IGoal) => void;
  onDeleteGoalClick?: (goal: IGoal) => void;
  onApproveGoalClick?: (goal: IGoal) => void;
}

const GoalsList: React.FC<IGoalsListProps> = (
  {
    onApproveGoalClick,
    goalsList, showGoalActions = false,
    onEditGoalClick, onDeleteGoalClick
  }
) => {

  return (
    <ul className='list-unstyled m-0'>
      {
        goalsList.map(
          (goal) => (
            <li key={goal.id} className='mb-2'>
              <div className='d-flex justify-content-between align-items-start'>
                <div className='flex-grow-1 mw-0'>
                  <p className='fw-bolder mb-1'> {goal.name} </p>
                  {
                    goal.competency?.map(
                      (comp) => {
                        return (
                          <Badge
                            key={comp.id}
                            bg='secondary'
                            className='badge-text-wrap me-2 badge-lg text-dark'
                          >
                            {comp.name}
                          </Badge>
                        )
                      }
                    )
                  }
                  {
                    goal.behaviors?.map(
                      (beh) => (
                        <Badge
                          key={beh.id}
                          bg='info'
                          className='badge-text-wrap me-2 badge-lg'
                        >
                          {beh.name}
                        </Badge>
                      )
                    )
                  }
                  <p className='m-0'> {goal.description} </p>
                </div>
                {
                  <div className='flex-shrink-0'>
                    {
                      goal.canEdit && (
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          overlay={
                            <Tooltip id={''}>Edit</Tooltip>
                          }>
                          <Button
                            size='sm'
                            variant='link'
                            onClick={() => onEditGoalClick?.(goal)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </OverlayTrigger>
                      )
                    }
                    {
                      goal.canDelete && (
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          overlay={
                            <Tooltip id={''}>Delete</Tooltip>
                          }>
                          <Button
                            size='sm'
                            variant='link'
                            onClick={() => onDeleteGoalClick?.(goal)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </OverlayTrigger>
                      )
                    }
                    {
                      (goal.canApprove || goal.canComplete) && (
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          overlay={
                            <Tooltip id={''}>Goal Actions</Tooltip>
                          }>
                          <Button
                            size='sm'
                            variant='link'
                            onClick={() => onApproveGoalClick?.(goal)}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </Button>
                        </OverlayTrigger>
                      )
                    }
                  </div>
                }
              </div>
              <GoalApprovalComponent goal={goal} />
              <Divider />
            </li>
          )
        )
      }
    </ul>
  );
};


const GoalApprovalComponent: React.FC<{ goal: IGoal }> = ({ goal }) => {

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row align-items-center'>
        {
          goal?.approvalStatus && (<OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={''}>Approved By {goal?.approvalActionBy}</Tooltip>
            }>
            <Button
              size='sm'
              variant='link'
            >
              <FontAwesomeIcon icon={faCheck} /> Approved
            </Button>
          </OverlayTrigger>
          )
        }
      </div>

      <div className='d-flex flex-row align-items-center'>
        {
          goal?.completionStatus && (<OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={''}>Marked completed By {goal?.completionActionBy}</Tooltip>
            }>
            <Button
              size='sm'
              variant='link'
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Completed
            </Button>
          </OverlayTrigger>
          )
        }
      </div>
    </div>
  )
}

export default GoalsList;