import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonProps, Dropdown, DropdownProps } from '@themesberg/react-bootstrap';
import { IMenuOption } from 'interfaces';
import * as React from 'react';

interface ICustomDropdownProps {
  id: string;
  title?: string;
  options: IMenuOption[];
  icon?: IconProp;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
  className?: string;
  disabled?: boolean;
  onChange?: (option: IMenuOption, index: number) => void;
  onToggle?: DropdownProps['onToggle'];
}

const CustomDropdown: React.FunctionComponent<ICustomDropdownProps> = (props) => {

  const [show, setShow] = React.useState<boolean>(false);

  return (
    <Dropdown
      className={props.className}
      show={show}
      onToggle={
        (isOpen, event, metadata) => {
          if (event) event?.stopPropagation();
          setShow(isOpen);
          props.onToggle?.(isOpen, event, metadata);
        }
      }
    >
      <Dropdown.Toggle
        id={props.id}
        variant={props.variant}
        size={props.size}
        disabled={props.disabled}
      >
        {
          props.icon &&
          <FontAwesomeIcon
            className={props.title ? 'me-1' : ''}
            icon={props.icon} />
        }
        <span> {props.title} </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {
          props.options && props.options.length ?
            (
              props.options.map(
                (option, index) => {
                  return (
                    <Dropdown.Item
                      as='div'
                      className='cursor-pointer'
                      key={option.value}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onChange?.(option, index)
                      }}
                    >
                      {option.label}
                    </Dropdown.Item>
                  );
                }
              )
            ) :
            (null)
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
