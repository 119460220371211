import React from "react";
import "./TextGradientSecondaryBtn.scss";

interface TextGradientSecondaryBtnProps {
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
  loadingText?: string;
}

const TextGradientSecondaryBtn: React.FC<TextGradientSecondaryBtnProps> = ({
  text,
  onClick,
  isLoading,
  loadingText,
}) => {
  return (
    <div
      className="TextGradientSecondaryBtn"
      onClick={() => {
        onClick?.();
      }}
    >
      <span className="craft-feedback-radiant-text">
        {isLoading ? loadingText : text}
      </span>
    </div>
  );
};

export default TextGradientSecondaryBtn;
