import React from "react";
import "./HeaderText.scss";

interface HeaderTextProps {
  text: string;
  fontSize?: string;
  lineHeight?: string;
  letterSpacing?: string; // Optional prop to add letter spacing to the text. Default is empty string.
}
const HeaderText: React.FC<HeaderTextProps> = ({
  text,
  fontSize,
  lineHeight,
  letterSpacing,
}) => {
  return (
    <div
      className="HeaderText"
      style={{
        fontSize: fontSize ?? "",
        lineHeight: lineHeight ?? "",
        letterSpacing: letterSpacing ?? "",
      }}
    >
      {text}
    </div>
  );
};

export default HeaderText;
