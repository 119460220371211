import { ISchedulerFormValues } from 'interfaces';
import { ONE_ON_ONE_SIMPLE_MENU_OPTION_VALIDATION_SCHEMA, SIMPLE_MENU_OPTION_VALIDATION_SCHEMA } from 'validation-schemas';
import * as Yup from 'yup';
import { ONE_ON_ONE_START_DATE_TIME_VALIDATION_SCHEMA, START_DATE_TIME_VALIDATION_SCHEMA } from './dateTimeValidationSchema';

export const SCHEDULER_VALIDATION_SCHEMA: Yup.SchemaOf<ISchedulerFormValues> = Yup.object().shape(
  {
    schedulerDateTime: START_DATE_TIME_VALIDATION_SCHEMA
      .nullable()
      .defined(),
    schedulerFrequency: SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.nullable()
  }
);


export const ONE_ON_ONE_SCHEDULER_VALIDATION_SCHEMA:Yup.SchemaOf<ISchedulerFormValues>= Yup.object().shape(
  {
    schedulerDateTime: ONE_ON_ONE_START_DATE_TIME_VALIDATION_SCHEMA
      .defined(),
    schedulerFrequency: ONE_ON_ONE_SIMPLE_MENU_OPTION_VALIDATION_SCHEMA.nullable()
  }
);
