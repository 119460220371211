import { ACTION_COLUMN_CONFIG, PROGRESS_COLUMN_CONFIG } from '@common/modules/table';
import { ProgressBar } from '@themesberg/react-bootstrap';
import { MetaDataContext } from 'contexts';
import {
  BodyCellProps,
  IActiveReview,
  IActiveReviewActionColumnProps,
  IMetadata,
  IReviewStatusTypeConstants,
  MetaDataKeys,
  SignInWith,
} from 'interfaces';
import moment from 'moment';
import React from 'react';
import { CellProps, Column } from 'react-table';
import { DATE_TIME_FORMAT } from 'teamble-constants';
import { DelimitedArrayParam, useQueryParam } from 'use-query-params';
import { getDateSortingFunc } from 'utils';

import ActiveReviewTableActionColumn from '../components/ActiveReviewTableActionColumn';
import { getActiveReviewDates } from '../utils/utils';
import { useApp } from 'hooks';


const getEndDateText = (review: IActiveReview): {
  endDateString: string,
  isEndDateInPast: boolean,
} => {

  const endDateString = getActiveReviewDates(review)[1];
  if (!endDateString) {
    return {
      endDateString: 'Not Available',
      isEndDateInPast: false
    };
  }

  const isEndDateInPast = moment(endDateString, DATE_TIME_FORMAT).isBefore(moment());

  return {
    endDateString,
    isEndDateInPast
  }
};


export const useActiveReviewTableColumns = (): readonly Column<IActiveReview>[] => {

  /* App hook */
  const app = useApp();

  /* Route hook */
  const [reviewStatusQueryParam] = useQueryParam('reviewStatus', DelimitedArrayParam);

  /* Metadata hook */
  const metaData = React.useContext(MetaDataContext) as IMetadata;
  const reviewStatusTypesConstants =
    metaData[MetaDataKeys.ReviewStatusTypes].constants as IReviewStatusTypeConstants;

  /* Date sorting function */
  const dateSortingFunc = React.useMemo(
    () => getDateSortingFunc<IActiveReview>(),
    []
  );


  return React.useMemo<readonly Column<IActiveReview>[]>(
    () => {

      /* Title Column */
      const titleColumn: Column<IActiveReview> = {
        Header: 'Title',
        accessor: 'title',
      };

      /* Date Columns */
      const updatedAtColumn: Column<IActiveReview> = {
        id: 'updatedAt',
        Header: 'Updated At',
        accessor: (row) => row.updatedAt
          ?
          moment(row.updatedAt).format(DATE_TIME_FORMAT)
          :
          'Not Available',
        sortType: dateSortingFunc,
      };

      const launchedByColumn: Column<IActiveReview> = {
        id: 'createdByUser',
        Header: 'Launched By',
        accessor: (row) => row.createdByUser || 'Not Available',
        sortType: dateSortingFunc as any,
      };

      const reviewStateColumn: Column<IActiveReview> = {
        id: 'state',
        Header: 'State',
        accessor: (row) => row.state || 'Not Available',
        sortType: dateSortingFunc as any,
      };


      const commonDateColumns: readonly Column<IActiveReview>[] = [
        {
          id: 'startDate',
          Header: 'Start',
          accessor: (row) => getActiveReviewDates(row)[0] || 'Not Available',
          sortType: dateSortingFunc as any,
        },
        launchedByColumn,
        reviewStateColumn,
        {
          Header: 'End',
          sortType: dateSortingFunc as any,
          accessor: (row) => {

            const { endDateString, isEndDateInPast } = getEndDateText(row);

            if (row?.isAutomaticCloseOnEndDate && endDateString && !isEndDateInPast) {
              return `Closing At: ${endDateString}`;
            }

            return isEndDateInPast ? endDateString + ' Delayed' : endDateString;
          },
          Cell: (cellProps: CellProps<IActiveReview, string>) => {

            const { endDateString, isEndDateInPast } = getEndDateText(cellProps.row.original);

            if (cellProps?.row?.original?.isAutomaticCloseOnEndDate && endDateString) {
              return <span>Closing At: {endDateString} </span>;
            }

            return isEndDateInPast
              ?
              <>
                <span>{endDateString}</span>
                <em> <strong className="text-danger"> Delayed </strong> </em>
              </>
              :
              <span>{endDateString}</span>;
          }
        },
      ];

      const dateColumns: readonly Column<IActiveReview>[] =
        reviewStatusQueryParam?.includes(reviewStatusTypesConstants.DRAFT)
          ?
          reviewStatusQueryParam.length === 1
            ? [
              updatedAtColumn,
              launchedByColumn,
              reviewStateColumn
            ] // Only Draft status selected
            :
            [updatedAtColumn, ...commonDateColumns] // Draft and other statuses selected
          :
          commonDateColumns; // Draft status is not selected


      /* Action Column */
      const actionColumn: Column<IActiveReview> = {
        ...ACTION_COLUMN_CONFIG,
        Cell: (cellProps: BodyCellProps<IActiveReview, void, IActiveReviewActionColumnProps>) => {
          return (
            <ActiveReviewTableActionColumn
              cellProps={cellProps}
              reviewStatus={cellProps.row.original.state} />
          );
        }
      };

      /* Nomination Status column for Live and Draft reviews */
      const nominationStatusColumn: Column<IActiveReview> = {
        Header: 'Nomination Status',
        accessor: (row) => row.nominationInProcess &&
          [reviewStatusTypesConstants.DRAFT, reviewStatusTypesConstants.LIVE].includes(row.state)
          ? 'Nomination Ongoing'
          : 'N/A'
      };

      /* Progress Column */
      const progressColumn: Column<IActiveReview> = {
        ...PROGRESS_COLUMN_CONFIG,
        accessor: (row) => [reviewStatusTypesConstants.CLOSED, reviewStatusTypesConstants.LIVE].includes(row.state)
          ? `${row.progress}%`
          : 'N/A',
        Cell: (cellProps: CellProps<IActiveReview, string>) => {

          return (
            <>
              {
                [reviewStatusTypesConstants.CLOSED, reviewStatusTypesConstants.LIVE].includes(cellProps.row.original.state)
                  ?
                  <div className="d-flex align-items-center">
                    <small className="fw-bold">{cellProps.value}</small>
                    <ProgressBar variant="primary" className="progress-lg mb-0 ms-3" now={cellProps.row.original.progress} />
                  </div>
                  :
                  'N/A'
              }
            </>
          )
        },
      };

      let columns: readonly Column<IActiveReview>[] = [
        titleColumn
      ];

      if (
        app === SignInWith.Slack &&
        (
          reviewStatusQueryParam?.includes(reviewStatusTypesConstants.DRAFT) ||
          reviewStatusQueryParam?.includes(reviewStatusTypesConstants.LIVE)
        )
      ) {
        columns = columns.concat(nominationStatusColumn);
      }

      columns = columns.concat(dateColumns);

      if (
        reviewStatusQueryParam?.includes(reviewStatusTypesConstants.CLOSED) ||
        reviewStatusQueryParam?.includes(reviewStatusTypesConstants.LIVE)
      ) {
        columns = columns.concat(progressColumn);
      }

      return columns.concat(actionColumn);

    },
    [
      app, dateSortingFunc, reviewStatusQueryParam, reviewStatusTypesConstants.CLOSED,
      reviewStatusTypesConstants.DRAFT, reviewStatusTypesConstants.LIVE
    ]
  );

};