import Axios, { AxiosObservable } from 'axios-observable';
import {
  FeedbackForAudienceTypes,
  ICreateUpdateTemplateRequestData,
  IFeedbackLoop,
  IFeedbackLoopAudiencePair,
  IFeedbackLoopContext,
  IFeedbackLoopSenderReceiverInfosResponse,
  IFeedbackLoopTemplateTypes,
  IFeedbackType,
  IFeedbackTypes,
  IHookResponse,
  ILaunchedFeedbackLoop,
  IRequestTimeoutRequestData,
  IResponse,
  IRouteParams,
  ISimpleMenuOption,
  ITemplate,
  ITemplateGroupType,
  IUpdateAnonymityRequestData,
  IUpdateAudienceRequestData,
  IUpdateFeedbackForRequestData,
  IUpdateFeedbackLoopRequestData,
  IUpdateFeedbackLoopSchedulerRequestData,
  IUpdateFeedbackTypeConfigRequestData,
  IUpdateNotificationMessageRequestData,
  IUpdateNotificationPreferenceRequestData,
  SortBy,
  SortKeyType,
} from 'interfaces';
import React from 'react';
import { finalize, map } from 'rxjs';

export const getFeedbackLoopsList = (page: number, sortKey: string, sortBy: SortBy, sortKeyType: SortKeyType):
  AxiosObservable<IResponse<IFeedbackLoop[]>> => {

  return Axios.post<IResponse<IFeedbackLoop[]>>(
    'list-feedback-library-loop',
    {
      page,
      limit: 5000,
      sortKey,
      sortBy,
      sortKeyType,
    }
  );
};


export const createFeedbackLoopFromTemplate = (
  templateId: string,
  type: ITemplateGroupType,
  teambleTeamId: string,
  templateType?: string
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "create-feedback-library-loop-from-existed-template",
    { templateId, type, teambleTeamId, workflowType: templateType }
  );
};


export const createFeedbackLoopFromScratch = (
  title: string,
  teambleTeamId: string,
  templateType?: string
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "create-feedback-library-loop-from-scratch",
    { title, teambleTeamId, workflowType: templateType }
  );
};


export const duplicateFeedbackLoopTemplate = (
  templateId: string
): AxiosObservable<IResponse<ITemplate>> => {

  return Axios.post<IResponse<ITemplate>>(
    "duplicate-custom-feedback-template",
    { templateId }
  );

};


export const deleteFeedbackLoopTemplate = (
  templateId: string
): AxiosObservable<IResponse<void>> => {

  return Axios.post<IResponse<void>>(
    "remove-custom-feedback-template",
    { templateId }
  );

};


export const getFeedbackLoopTemplateDetails = (
  templateId: string
): AxiosObservable<IResponse<ITemplate>> => {

  return Axios.post<IResponse<ITemplate>>(
    "detail-custom-feedback-template",
    { templateId }
  );

};


export const getFeedbackLoopLibraryDetails = (
  feedbackLibraryLoopId: string
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "detail-feedback-library-loop",
    { feedbackLibraryLoopId }
  );

};


export const duplicateFeedbackLoop = (
  feedbackLoopId: string
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "duplicate-feedback-library-loop",
    { feedbackLoopId }
  );

};


export const deleteFeedbackLoop = (
  feedbackLoopId: string
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "remove-feedback-library-loop",
    { feedbackLoopId }
  );

};


export const createFeedbackLoopTemplate = (
  data: ICreateUpdateTemplateRequestData
): AxiosObservable<IResponse<ITemplate>> => {

  return Axios.post<IResponse<ITemplate>>(
    "create-custom-feedback-template",
    data
  );

};


export const updateFeedbackLoopTemplate = (
  data: ICreateUpdateTemplateRequestData
): AxiosObservable<IResponse<ITemplate>> => {

  return Axios.post<IResponse<ITemplate>>(
    "update-custom-feedback-template",
    data
  );

};


export const updateFeedbackLoop = (
  data: IUpdateFeedbackLoopRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-blocks",
    data
  );

};


export const updateFeedbackLoopFeedbackType = (
  data: IUpdateFeedbackTypeConfigRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-feedback-type",
    data
  );

};


export const updateFeedbackLoopScheduler = (
  data: IUpdateFeedbackLoopSchedulerRequestData,
  workflowType: IFeedbackLoopTemplateTypes
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-schedular",
   {...data, workflowType}
  );

};


export const updateFeedbackLoopRequestTtl = (
  data: IRequestTimeoutRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-ttl",
    data
  );

};


export const removeFeedbackLoopSchedule = (
  feedbackLibraryLoopId: string
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "remove-feedback-library-loop-schedular",
    { feedbackLibraryLoopId }
  );

};


export const updateFeedbackLoopNotificationPreference = (
  data: IUpdateNotificationPreferenceRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-notification-preference",
    data
  );

};


export const updateFeedbackLoopNotificationMessage = (
  data: IUpdateNotificationMessageRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-notification-message",
    data
  );

};


export const updateFeedbackLoopAudience = (
  data: IUpdateAudienceRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-audience",
    data
  );

};


export const updateFeedbackLoopAnonymity = (
  data: IUpdateAnonymityRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-anonymity",
    data
  );

};


export const updateFeedbackLoopFeedbackFor = (
  data: IUpdateFeedbackForRequestData
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "update-feedback-library-loop-feedback-for",
    data
  );

};


export const launchFeedbackLoop = (
  feedbackLibraryLoopId: string
): AxiosObservable<IResponse<IFeedbackLoop>> => {

  return Axios.post<IResponse<IFeedbackLoop>>(
    "launch-feedback-library-loop",
    { feedbackLibraryLoopId }
  );

};


export const getFeedbackForPairsFromFile =
  (formData: FormData): AxiosObservable<IResponse<IFeedbackLoopSenderReceiverInfosResponse>> => {

    return Axios.post<IResponse<IFeedbackLoopSenderReceiverInfosResponse>>(
      "update-feedback-library-loop-upload-pair",
      formData,
    );
  };


export const getFeedbackForAudiencePair = (
  feedbackLibraryLoopId: IFeedbackLoop['id'],
): AxiosObservable<IResponse<IFeedbackLoopAudiencePair[]>> => {

  return Axios.post<IResponse<IFeedbackLoopAudiencePair[]>>(
    "details-feedback-library-loop-audience-pair",
    { feedbackLibraryLoopId }
  );
};

export const feedbackLoopAudienceActions = (
  feedbackLoopId: string,
  audiencePairs: IFeedbackLoopAudiencePair[],
  action: string
): AxiosObservable<IResponse<IFeedbackLoopAudiencePair[]>> => {

  const data = audiencePairs.map(sr => ({
    receiverId: sr?.receiver?.teambleUserId,
    senderId: sr?.sender?.teambleUserId,
  }));

  return Axios.post<IResponse<IFeedbackLoopAudiencePair[]>>(
    "feedback-loops-audience-pair-action",
    { feedbackLoopId, audiencePairs: data, action }
  );
};


export const sendFeedbackLoopReminders = (
  feedbackLoopId: string,
): AxiosObservable<IResponse<void>> => {

  return Axios.post<IResponse<void>>(
    "reminder-feedback-loop",
    { feedbackLoopId }
  );
};


export const useFeedbackForAudiencePair = (
  feedbackLibraryLoopId: IFeedbackLoop['id'] | undefined,
  feedbackFor: IFeedbackLoop['feedbackFor'],
  feedbackType: IFeedbackLoop['feedbackType'],
  audienceUpdatedCount: number,
): IHookResponse<IFeedbackLoopAudiencePair[]> => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [feedbackForAudiencePair, setFeedbackForAudiencePair] =
    React.useState<IFeedbackLoopAudiencePair[]>();

  React.useEffect(
    () => {

      if (
        !feedbackLibraryLoopId ||
        !feedbackFor ||
        feedbackFor === FeedbackForAudienceTypes.SelfSelect ||
        feedbackType === IFeedbackTypes.Company
      ) {
        return;
      }

      setIsLoading(true);
      setFeedbackForAudiencePair([]);
      const subscription = getFeedbackForAudiencePair(feedbackLibraryLoopId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setFeedbackForAudiencePair(response.data.data);
          }
        );

      return () => subscription.unsubscribe();

    },
    [feedbackLibraryLoopId, audienceUpdatedCount, feedbackFor, feedbackType]
  );

  return {
    isLoading,
    data: feedbackForAudiencePair
  }

};


export const useFeedbackLoopsList = (
  page: number | undefined | null,
  sortKey: string | undefined | null,
  sortBy: SortBy | undefined | null,
  sortKeyType: SortKeyType | undefined | null,
  startDate: number | undefined | null,
  endDate: number | undefined | null,
  filterBy: (string | null)[] | null | undefined
): IHookResponse<ILaunchedFeedbackLoop[]> => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [launchedFeedbackLoopsList, setLaunchedFeedbackLoopsList] =
    React.useState<ILaunchedFeedbackLoop[]>([]);


  React.useEffect(
    () => {

      if (!page || !sortBy || !sortKey || !sortKeyType || !filterBy) {
        return;
      }

      setIsLoading(true);
      setLaunchedFeedbackLoopsList([]);
      const subscription = Axios.post<IResponse<ILaunchedFeedbackLoop[]>>(
        "list-feedback-loops",
        {
          page,
          limit: 5000,
          sortKey,
          sortBy,
          sortKeyType,
          startDate,
          endDate,
          filterBy,
        }
      )
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setLaunchedFeedbackLoopsList(response.data.data);
          }
        );

      return () => subscription.unsubscribe();

    },
    [endDate, filterBy, page, sortBy, sortKey, sortKeyType, startDate]
  );

  return {
    isLoading,
    data: launchedFeedbackLoopsList
  }

};

export const useFeedbackLoopDetails = (feedbackLoopId: string | undefined): IFeedbackLoopContext => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [feedbackLoop, setFeedbackLoop] = React.useState<ILaunchedFeedbackLoop>();

  React.useEffect(() => {

    if (!feedbackLoopId) {
      setIsLoading(false);
      setFeedbackLoop(undefined);
      return;
    }

    setIsLoading(true);
    setFeedbackLoop(undefined);
    const subscription = Axios.post<IResponse<ILaunchedFeedbackLoop>>(
      "detail-feedback-loop",
      { feedbackLoopId }
    )
      .pipe(
        finalize(() => setIsLoading(false))
      )
      .subscribe(
        (response) => {
          setFeedbackLoop(response.data.data);
        }
      );

    return () => subscription.unsubscribe();

  }, [feedbackLoopId]);

  return {
    isLoading,
    feedbackLoop: feedbackLoop,
    updateFeedbackLoop: setFeedbackLoop,
  }

};


export const getFeedbackTypeMenuOptions = ():
  AxiosObservable<IResponse<ISimpleMenuOption[]>> => {

  return Axios.post<IResponse<IFeedbackType[]>>(
    "list-company-feedback-types",
  ).pipe(
    map(
      (response) => {
        return {
          ...response,
          data: {
            ...response.data,
            data: response.data.data
              .filter(
                (feedbackType) => feedbackType.isEnabled
              )
              .map(
                (feedbackType) => ({
                  value: feedbackType.id,
                  label: feedbackType.title
                })
              )
          }
        }
      }
    )
  );
};