import { Card, ProgressBar } from '@themesberg/react-bootstrap';
import { Divider } from 'components';
import { IReviewTodosResponse } from 'interfaces';
import React from 'react';

import RevieweeTodoRow from './RevieweeTodoRow';

interface IRevieweeTodosWidgetProps {
  reviewTodos: IReviewTodosResponse;
}

const RevieweeTodosWidget: React.FC<IRevieweeTodosWidgetProps> = (
  { reviewTodos }
) => {

  return (
    <Card className='h-100 overflow-hidden border-top-left-radius-0 border-top-right-radius-0'>

      <Card.Header className='flex-shrink-0 bg-gray-400'>
        <Card.Title>
          Progress
        </Card.Title>
        <div className="d-flex align-items-center">
          <small className="flex-shrink-0 fw-bold">{reviewTodos.percentageComplete.toFixed(2)}%</small>
          <ProgressBar
            variant="secondary"
            className="progress-lg mb-0 ms-3 flex-grow-1"
            now={+reviewTodos.percentageComplete.toFixed(2)} />
        </div>
      </Card.Header>

      <Card.Body className='flex-grow-1 overflow-y-auto'>
        {
          reviewTodos.reviewList.map(
            (review) => (
              <React.Fragment key={review.performanceReviewCampaignId}>
                <h5>{review.title}</h5>
                {
                  review.reviews.map(
                    (reviewee) => (
                      <RevieweeTodoRow
                        key={review.performanceReviewCampaignId + reviewee.id}
                        revieweeRow={reviewee} />
                    )
                  )
                }
                <Divider />
              </React.Fragment>
            )
          )
        }
      </Card.Body>
    </Card>
  );
};

export default RevieweeTodosWidget;