import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { ConfirmationModal, DataState, Divider } from 'components';
import { getReviewManagerApproveNominationData, submitManagerTodos } from 'data';
import { useFormik } from 'formik';
import {
  INomination,
  INominationActionFormValues,
  INominationActionRequestData,
  IRouteParams,
  NominationActions
} from 'interfaces';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { NOMINATION_ACTION_VALIDATION_SCHEMA } from 'validation-schemas';

import ReviewTodoManagerAddReviewersForm from './ReviewTodoManagerAddReviewersForm';
import ReviewTodoNominationActionForm from './ReviewTodoNominationActionForm';


interface IReviewTodoManagerApproveNominationWidgetProps {
  onNominationSubmit: (reviewId: string) => void
}

const ReviewTodoManagerApproveNominationWidget: React.FC<IReviewTodoManagerApproveNominationWidgetProps> = (
  { onNominationSubmit }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [nominationDetails, setNominationDetails] =
    React.useState<INomination[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);

  const { reviewId, revieweeId } = useParams<IRouteParams>();


  const nominationActionForm = useFormik<INominationActionFormValues>({
    initialValues: {
      nominations: []
    },
    validationSchema: NOMINATION_ACTION_VALIDATION_SCHEMA,
    onSubmit: (values, formikHelpers) => {

      if (!reviewId) {
        return;
      }

      const requestData: INominationActionRequestData = {
        campaignId: reviewId,
        responses: values.nominations.map(
          (nomination, index) => ({
            id: nominationDetails[index].id,
            value: nomination.approveStatus === NominationActions.Approve ? 'accept' : 'reject',
            reason: nomination.reason
          })
        )
      };

      submitManagerTodos(requestData)
        .pipe(
          finalize(() => formikHelpers.setSubmitting(false))
        )
        .subscribe(
          (response) => {

            toast.success(response.data.message);
            setShowConfirmationModal(false);
            onNominationSubmit(nominationDetails[0].id);
          }
        );

    }
  });


  const updateNominationDetailsWithMappedNominations = (nominationData: INomination[]): void => {

    nominationData = nominationData.map(
      (nomination, index) => ({
        ...nomination,
        approveStatus: nominationActionForm.values.nominations[index]?.approveStatus || '',
        reason: nominationActionForm.values.nominations[index]?.reason || '',
      })
    );

    nominationActionForm.setFieldValue(
      'nominations',
      nominationData,
      true
    );
    nominationActionForm.setFieldTouched(
      'nominations',
      false,
      true
    );
    setNominationDetails(nominationData);

  };


  useEffect(
    () => {

      if (!reviewId || !revieweeId) {
        setIsLoading(false);
        setNominationDetails([]);
        return;
      }

      setIsLoading(true);
      setNominationDetails([]);

      const subscription = getReviewManagerApproveNominationData(reviewId, revieweeId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            updateNominationDetailsWithMappedNominations(response.data.data.openListData);
          }
        );

      return () => subscription.unsubscribe();

    },
    [reviewId, revieweeId]
  );


  return (
    <>
      <Card className='h-100 overflow-hidden'>

        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!nominationDetails.length}
          emptyStateMessage={<>No nominations available!</>}
        >
          {
            nominationDetails.length
              ?
              <>
                <Card.Header className='flex-shrink-0'>
                  <Card.Title className='m-0 fw-bold fs-4'>
                    Manager Approval ({nominationDetails[0].reviewee.name})
                  </Card.Title>
                </Card.Header>

                <Form
                  className='d-flex overflow-hidden flex-column flex-grow-1'
                  onSubmit={(e) => {
                    if (nominationActionForm.isValid) {
                      e.preventDefault();
                      setShowConfirmationModal(true);
                    } else {
                      nominationActionForm.handleSubmit(e);
                    }
                  }}
                >

                  <Card.Body className='flex-grow-1 overflow-y-auto'>

                    <Card.Subtitle className='fs-5'>
                      Review Nomination Ready for Approval
                    </Card.Subtitle>
                    <p>
                      <em>Note: these individuals will still have an opportunity to opt out.</em>
                    </p>

                    {
                      nominationActionForm.values.nominations.length
                        ?
                        <ReviewTodoNominationActionForm
                          nominations={nominationDetails}
                          nominationActionForm={nominationActionForm} />
                        :
                        <p>No nominations to approve/deny.</p>
                    }
                    {
                      nominationDetails[0].reviewee.name
                        ?
                        <>
                          <Divider />
                          <ReviewTodoManagerAddReviewersForm
                            revieweeName={nominationDetails[0].reviewee.name}
                            revieweeSlackId={nominationDetails[0].revieweeSlackId}
                            onValidPairsAdd={(validPairs) => {
                              updateNominationDetailsWithMappedNominations(
                                [...nominationDetails, ...validPairs],
                              )
                            }} />
                        </>
                        :
                        null
                    }

                  </Card.Body>

                  <Card.Footer className='d-flex justify-content-end flex-shrink-0 '>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={nominationActionForm.isSubmitting}
                    >
                      {nominationActionForm.isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                  </Card.Footer>

                </Form>
              </>
              :
              null
          }

        </DataState>
      </Card>

      <ConfirmationModal
        title='Submit nominations'
        submittingText='Submitting...'
        positiveText='Submit'
        isSubmitting={nominationActionForm.isSubmitting}
        show={showConfirmationModal}
        closeOnUserResponse={false}
        onPositiveResponse={() => {
          nominationActionForm.submitForm();
        }}
        onNegativeResponse={() => setShowConfirmationModal(false)}
        onCloseClick={() => setShowConfirmationModal(false)}
      >
        <p>
          Are you sure you want to submit the nominations?
        </p>
        <i>Once you submit you can't edit your nominations.</i>
      </ConfirmationModal>
    </>
  );
};

export default ReviewTodoManagerApproveNominationWidget;