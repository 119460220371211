import FormField from 'components/FormComponents/FormField';
import { MetaDataContext } from 'contexts';
import { useApp } from 'hooks';
import { FormFieldTypeConstants, IFormQuestionValue, IMetadata, IMultiSelectQuestionOptionsTypeConstants, IQuestionTypeConstants, SignInWith } from 'interfaces';
import * as React from 'react';

interface IQuestionProps {
  index: number;
  question: IFormQuestionValue;
}


const TitleQuestion: React.FunctionComponent<IQuestionProps> = (
  { index }
) => {

  const app = useApp();

  return (
    <FormField
      label=''
      controlId={`titleQuestion-${index}`}
      type={app === SignInWith.Slack ? FormFieldTypeConstants.RichText : FormFieldTypeConstants.Text}
      placeholder='Enter title'
      controlName={`questions[${index}].titleValue.text`} />
  );
};


const TextQuestion: React.FunctionComponent<IQuestionProps> = (
  { index }
) => {

  return (
    <>
      <FormField
        label='Question *'
        controlId={`shortTextQuestionLabel-${index}`}
        type={FormFieldTypeConstants.Textarea}
        placeholder='Enter question'
        controlName={`questions[${index}].textValue.label`} />

      <FormField
        label='Answer Guidance'
        controlId={`shortTextQuestionDescription-${index}`}
        type={FormFieldTypeConstants.Text}
        placeholder='Enter answer guidance'
        controlName={`questions[${index}].textValue.placeholder`} />

      <FormField
        label='Required'
        controlId={`shortTextQuestionRequired-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`questions[${index}].textValue.required`} />
    </>
  );
};


const MultiSelectQuestion: React.FunctionComponent<IQuestionProps> = (
  { index, question }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  const multiSelectQuestionTypeConstants =
    metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

  return (
    <>
      <FormField
        label='Question *'
        controlId={`multiSelectQuestionLabel-${index}`}
        type={FormFieldTypeConstants.Textarea}
        placeholder='Enter question'
        controlName={`questions[${index}].multiSelectValue.label`} />

      <FormField
        label='Predefined Options'
        controlId={`multiSelectQuestionOptions-${index}`}
        type={FormFieldTypeConstants.Select}
        options={metadata?.multiSelectQuestionOptionsTypes.options.map(
          (option) => ({ value: option.value, label: option.label })
        )}
        controlName={`questions[${index}].multiSelectValue.selectedOption`} />

      {
        question.multiSelectValue?.selectedOption.value ===
        multiSelectQuestionTypeConstants.CUSTOM_ITEMS &&
        <FormField
          label='Choices *'
          controlId={`multiSelectQuestionChoices-${index}`}
          type={FormFieldTypeConstants.Textarea}
          controlName={`questions[${index}].multiSelectValue.choices`}
          description='Add each choice on a new line.' />
      }

      {
        question.multiSelectValue?.enableCommentBox &&
        <FormField
          label='Comment Box Guideline *'
          controlId={`multiSelectQuestionCommentBox-${index}`}
          type={FormFieldTypeConstants.Text}
          placeholder='Enter Comment Box Guideline'
          controlName={`questions[${index}].multiSelectValue.comment`} />
      }

      <FormField
        label='Add Comment Box'
        controlId={`multiSelectQuestionComment-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`questions[${index}].multiSelectValue.enableCommentBox`} />

      <FormField
        label='Required'
        controlId={`multiSelectQuestionRequired-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`questions[${index}].multiSelectValue.required`} />

      <FormField
        label='Dropdown'
        controlId={`multiSelectQuestionDropdown-${index}`}
        type={FormFieldTypeConstants.Checkbox}
        controlName={`questions[${index}].multiSelectValue.dropdown`} />
    </>
  );
};


const Question: React.FunctionComponent<IQuestionProps> = (
  { index, question }
) => {

  const metadata = React.useContext(MetaDataContext) as IMetadata;

  let QuestionComponent: React.FunctionComponent<IQuestionProps> | null = null;
  const questionTypeConstants =
    metadata.questionTypes.constants as IQuestionTypeConstants;

  switch (question.type) {

    case questionTypeConstants.TITLE:
    case questionTypeConstants.SUBTITLE: {
      QuestionComponent = TitleQuestion;
      break;
    }

    case questionTypeConstants.LONG_TEXT:
    case questionTypeConstants.SHORT_TEXT: {
      QuestionComponent = TextQuestion;
      break;
    }

    case questionTypeConstants.MULTI_SELECT_QUESTION: {
      QuestionComponent = MultiSelectQuestion;
      break;
    }

  }

  if (QuestionComponent) {
    return (
      <QuestionComponent
        index={index}
        question={question}
      />
    );
  }

  return null;

};

export default Question;
