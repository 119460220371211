import { Row, Col, Button } from '@themesberg/react-bootstrap';
import { CustomSelect, useUserProfile } from 'components';
import { MetaDataContext } from 'contexts';
import { getSlackTeamMembersAsMenuOptions } from 'data';
import { useApp, useCompanyUsersMemberOptions } from 'hooks';
import { IChannelOrWorkspaceMemberOption, IMetadata, IReviewStatusTypeConstants, ISimpleMenuOption, MetaDataKeys, SignInWith } from 'interfaces';
import React, { useEffect } from 'react';
import { REVIEW_LAUNCHED_BY_FILTER_OPTIONS } from 'teamble-constants';
import { DelimitedArrayParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { getMultipleSelectedOptionsByQueryParam, getSelectedOptionByQueryParam } from 'utils';

export const filterByUserTypeOptions: ISimpleMenuOption[] = [
  {
    value: "reviewer",
    label: "Reviewer"
  },
  {
    value: "reviewee",
    label: "Reviewee"
  }
];

interface IReviewsListFiltersProps { }

const ReviewsListFilters: React.FC<IReviewsListFiltersProps> = () => {

  /* Metadata hook */
  const metaData = React.useContext(MetaDataContext) as IMetadata;
  const reviewStatusTypesOptions = metaData[MetaDataKeys.ReviewStatusTypes].options;
  const reviewStatusTypesConstants = metaData[MetaDataKeys.ReviewStatusTypes].constants as
    IReviewStatusTypeConstants;

  /* User-Profile Hook */
  const { userProfile } = useUserProfile();

  const { isLoading, memberOptions } = useCompanyUsersMemberOptions();

  /* Route Hooks */
  const [
    {
      reviewStatus: reviewStatusQueryParam,
      // launchedByFilter: launchedByFilterQueryParam,
      filterByUserType: filterByUserTypeQueryParam,
      filterByUserTypeIds: filterByUserTypeIdsQueryParam,
      filterLaunchedBy: filterLaunchedByQueryParam
    },
    setQueryParams
  ] = useQueryParams({
    reviewStatus: DelimitedArrayParam,
    // launchedByFilter: StringParam,
    filterByUserType: DelimitedArrayParam,
    filterByUserTypeIds: DelimitedArrayParam,
    filterLaunchedBy: DelimitedArrayParam
  });

  /* Launched by Filter options depending on the review status selection */
  // const launchedByFilterOptions = reviewStatusQueryParam?.includes(reviewStatusTypesConstants.DRAFT)
  //   ? [REVIEW_LAUNCHED_BY_FILTER_OPTIONS[0]]
  //   : REVIEW_LAUNCHED_BY_FILTER_OPTIONS;

  /* Get selected review status by using query param */
  const selectedReviewStatuses = getMultipleSelectedOptionsByQueryParam(
    reviewStatusQueryParam, reviewStatusTypesOptions
  );

  const selectedLaunchedBy = getMultipleSelectedOptionsByQueryParam(
    filterLaunchedByQueryParam, REVIEW_LAUNCHED_BY_FILTER_OPTIONS
  );

  const selectedUserTypeFilters = getMultipleSelectedOptionsByQueryParam(
    filterByUserTypeQueryParam, filterByUserTypeOptions
  );

  const selectedUserTypeFilterIds = getMultipleSelectedOptionsByQueryParam(
    filterByUserTypeIdsQueryParam, memberOptions
  );


  /* Get selected launched by filter by using query param */
  // const selectedLaunchedByFilter = getSelectedOptionByQueryParam(
  //   launchedByFilterQueryParam, REVIEW_LAUNCHED_BY_FILTER_OPTIONS
  // );


  /* Set the review status query param if not already set only once */
  useEffect(
    () => {
      if (!reviewStatusQueryParam?.length) {
        setQueryParams({
          reviewStatus: [reviewStatusTypesConstants.LIVE, reviewStatusTypesConstants.DRAFT],
          filterByUserType: [],
          filterByUserTypeIds: [],
          filterLaunchedBy: REVIEW_LAUNCHED_BY_FILTER_OPTIONS.map(data => data.value)
        }, 'replaceIn');
      }
    },
    []
  );


  /* Set the launched-by-filter query param if not already set */
  // useEffect(
  //   () => {
  //     if (!launchedByFilterQueryParam && userProfile?.isSuperAdmin) {
  //       setQueryParams(
  //        { launchedByFilter: REVIEW_LAUNCHED_BY_FILTER_OPTIONS[0].value },
  //         'replaceIn'
  //       );
  //     }
  //   },
  //   [launchedByFilterQueryParam, setQueryParams, userProfile]
  // );

  /* Handle the review status change by setting the query params */
  const handleReviewStatusChange = (options: ISimpleMenuOption[]): void => {

    const ids = options.map(({ value }) => value);
    let queryParams = {
      reviewStatus: ids,
    };
    if (ids.includes(reviewStatusTypesConstants.DRAFT) && userProfile?.isSuperAdmin) {
      queryParams = {
        ...queryParams,
        // launchedByFilter: REVIEW_LAUNCHED_BY_FILTER_OPTIONS[0].value,
        filterLaunchedBy: [REVIEW_LAUNCHED_BY_FILTER_OPTIONS[0].value]
      } as any;
    }
    setQueryParams(queryParams);
  };


  return (
    <div style={{ zIndex: 6 }}>

      <Row className='align-items-end'>
        <Col xs={4}>
          <CustomSelect
            isMulti
            placeholder='Select status...'
            options={reviewStatusTypesOptions}
            value={selectedReviewStatuses}
            onChange={(options) => {
              setQueryParams(
                { reviewStatus: (options as ISimpleMenuOption[]).map(({ value }) => value) },
                'replaceIn'
              );
            }}
          // onChange={(options) => handleReviewStatusChange(options as ISimpleMenuOption[])}
          />
        </Col>

        <Col xs={4}>
          {
            userProfile?.isSuperAdmin && (
              <>

                {/* <CustomSelect
                  options={launchedByFilterOptions}
                  value={selectedLaunchedByFilter}
                  onChange={
                    (option) => setQueryParams(
                      // { launchedByFilter: (option as ISimpleMenuOption).value }
                    )
                  }
                /> */}


                <CustomSelect
                  isMulti
                  options={REVIEW_LAUNCHED_BY_FILTER_OPTIONS}
                  placeholder='Select launched by...'
                  value={selectedLaunchedBy}
                  onChange={(options) => {
                    setQueryParams(
                      { filterLaunchedBy: (options as ISimpleMenuOption[]).map(({ value }) => value) },
                      'replaceIn'
                    );
                  }}
                />
              </>
            )
          }
        </Col>
      </Row>

      {
        Boolean(userProfile?.isSuperAdmin) && (
          <Row className='align-items-end mt-2 mb-1'>
            <Col xs={4}>
              <CustomSelect
                placeholder='Select user type...'
                isMulti
                options={filterByUserTypeOptions}
                value={selectedUserTypeFilters}
                onChange={(options) => {
                  setQueryParams(
                    { filterByUserType: (options as ISimpleMenuOption[]).map(({ value }) => value) },
                    'replaceIn'
                  );
                }}
              />
            </Col>

            <Col xs={4}>
              <CustomSelect
                isMulti
                isSearchable
                isLoading={isLoading}
                placeholder='Select user...'
                options={memberOptions}
                value={selectedUserTypeFilterIds}
                onChange={(options) => {
                  setQueryParams(
                    { filterByUserTypeIds: (options as ISimpleMenuOption[]).map(({ value }) => value) },
                    'replaceIn'
                  );
                }}
              />
            </Col>

            <Col className='d-flex align-items-center'>
              <Button
                onClick={() => {
                  setQueryParams({
                    filterByUserType: [],
                    filterByUserTypeIds: []
                  }, 'replaceIn');
                }}
              >
                Clear User Filter
              </Button>
            </Col>
          </Row>
        )
      }
    </div>
  );
};

export default ReviewsListFilters;