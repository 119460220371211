import { Badge } from '@themesberg/react-bootstrap';
import { IReviewTodoRevieweeItem, ReviewTodoState, ReviewTodoTypes } from 'interfaces';
import React, { useRef } from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { Routes } from 'routes';

import { AccordionData } from '../context/AccordionData.context';
import { getReviewTodoPathName, getReviewTodoRowTitle } from '../utils/utils';

interface IRevieweeTodoRowProps {
  revieweeRow: IReviewTodoRevieweeItem;
}

const RevieweeTodoRow: React.FC<IRevieweeTodoRowProps> = ({ revieweeRow }) => {
  const { setSelectedPreviewRow } = React.useContext(AccordionData);
  const [revieweeNameInitials, setRevieweeNameInitials] = React.useState<string>('');

  /* Ref hook */
  const elementRef = useRef<HTMLAnchorElement>(null);

  /* Route hooks */
  const location = useLocation();

  const pathname = getReviewTodoPathName(revieweeRow);
  const revieweeRowTitle = getReviewTodoRowTitle(revieweeRow);

  React.useEffect(
    () => {

      if (revieweeRow.type !== ReviewTodoTypes.Default) {
        setRevieweeNameInitials('');
        return;
      }

      const revieweeNames = revieweeRow.revieweeName
        .split(' ')
        .map((names) => names.charAt(0).toUpperCase())
        .join('');

      setRevieweeNameInitials(revieweeNames);
    },
    [revieweeRow.revieweeName, revieweeRow.type]
  );


  React.useEffect(
    () => {

      if (elementRef.current && pathname === location.pathname) {
        elementRef.current.scrollIntoView({
          behavior: 'smooth'
        });
      }

    },
    []
  );


  return (
    <NavLink
      className='reviewee-todo-link'
      onClick={() => { setSelectedPreviewRow?.(null) }}
      to={{
        pathname,
        search: location.search
      }}
      ref={elementRef}
    >
      <div className='d-flex justify-content-between align-items-center p-1 reviewee-todo rounded-3'>
        <div className='d-flex align-items-center'>
          {
            revieweeNameInitials &&
            <Badge bg='primary' className='rounded-circle p-2 me-2'>
              {revieweeNameInitials}
            </Badge>
          }
          <p className={revieweeRow.state === ReviewTodoState.Completed ? 'text-decoration-line-through m-0' : 'm-0'}>
            {revieweeRowTitle}
          </p>
        </div>
        <Badge
          bg={revieweeRow.state === ReviewTodoState.Completed ? 'success' : 'danger'}
          className='rounded-circle p-1'
        >
          {' '}
        </Badge>
      </div>
    </NavLink>
  );
};

export default RevieweeTodoRow;