import React from 'react'

interface IListProps<T> {
  items: Array<T>;
  renderItem: (item: T, index: number) => JSX.Element;
}

const List = <T,>(
  { items, renderItem }: IListProps<T>
) => {

  return (
    <>{items.map((item, index) => renderItem(item, index))}</>
  )
};

export default List;