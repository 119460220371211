import { Card } from '@themesberg/react-bootstrap';
import React from 'react';

import UserCompetencyPerformanceWidget from './UserCompetencyPerformanceWidget';
import MemberProfileReviewScoreCard from './MemberProfileReviewScoreCard';

interface IUserDetailsPerformanceWidgetProps { }

const UserDetailsPerformanceWidget: React.FC<IUserDetailsPerformanceWidgetProps> = () => {

  return (
    <Card className='h-100 overflow-hidden'>
      <Card.Header className='flex-shrink-0'>
        <Card.Title className='m-0'>
          Performance Insights
        </Card.Title>
      </Card.Header>
      <Card.Body className='flex-grow-1 overflow-y-auto'>
        <div className='mb-4'>
          <MemberProfileReviewScoreCard />
        </div>
        <UserCompetencyPerformanceWidget />
      </Card.Body>
    </Card>
  );
};

export default UserDetailsPerformanceWidget;