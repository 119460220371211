import Axios, { AxiosObservable } from 'axios-observable';
import { ICompetency, ICompetencyRequestData, IObjectiveQuestion, IResponse, IUserRole } from 'interfaces';
import { map } from 'rxjs';

import { ISimpleMenuOptionWithSubOptions } from './../interfaces/menuOptions.interface';

export const getCompetenciesList = (): AxiosObservable<IResponse<ICompetency[]>> => {
  return Axios.post<IResponse<ICompetency[]>>("/get-competency");
};

export const getObjectiveList = (): AxiosObservable<IResponse<IObjectiveQuestion[]>> => {
  return Axios.post<IResponse<IObjectiveQuestion[]>>('/get-objectives');
};


export const getCompetenciesListAsMenuOptions = (): AxiosObservable<IResponse<ISimpleMenuOptionWithSubOptions[]>> => {
  return getCompetenciesList().pipe(
    map(
      (response) => {
        return {
          ...response,
          data: {
            ...response.data,
            data: response.data.data.map(
              (option) => ({
                ...option,
                value: option.id,
                label: option.name,
                subOptions: option.behaviours.map(
                  (beh) => ({
                    value: beh.id,
                    label: beh.name
                  })
                )
              })
            )
          }

        };
      }
    )
  )
}


export const createCompetency =
  (data: ICompetencyRequestData): AxiosObservable<IResponse<ICompetency>> => {
    return Axios.post<IResponse<ICompetency>>("/create-competency", { ...data });
  };

export const updateCompetency = (id: string, data: ICompetencyRequestData): AxiosObservable<IResponse<ICompetency>> => {
  return Axios.post<IResponse<ICompetency>>("/update-competency", { id, ...data });
}

export const deleteCompetencyRequest =
  (id: string, initialCheck: boolean): AxiosObservable<IResponse<IUserRole[]>> => {
    return Axios.post<IResponse<IUserRole[]>>("/remove-competency", { id, initialCheck });
  };
