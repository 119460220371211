import Axios, { AxiosObservable } from "axios-observable";
import { IResponse } from "interfaces";

export const getTeambleAiOneOnOneFeedback = (
  id: string
): AxiosObservable<IResponse<any>> => {
  return Axios.post<IResponse<void>>("/get-teamble-ai-one-on-one-service", {
    id,
  });
};

interface EnhanceTeambleAiFeedbackProps {
  id: string;
  situation: string;
  behavior: string;
  impact: string;
  feedbackText: string;
}

export const enhanceTeambleAiFeedback = ({
  id,
  situation,
  behavior,
  impact,
  feedbackText,
}: EnhanceTeambleAiFeedbackProps): AxiosObservable<IResponse<any>> => {
  return Axios.post<IResponse<void>>("/feedback-specificity-enhancer", {
    id,
    situation,
    behavior,
    impact,
    feedbackText,
  });
};

export const createTeambleAiOneOnOneFeedback = (
  feedbackText: string,
  oneOnOneId: string
) => {
  return Axios.post<IResponse<void>>("/create-teamble-ai-one-on-one-service", {
    feedbackText,
    oneOnOneId,
  });
};

export const changeFeedbackOpeningStatementTone = (
  id: string,
  suggestedTone: string,
  openingStatement: string,
  userFeedback: string
) => {
  return Axios.post<IResponse<void>>("/change-feedback-opening-statement", {
    id,
    suggestedTone,
    openingStatement,
    userFeedback,
  });
};

export const mergeOpeningStatementWithUserFeedback = (
  id: string,
  openingStatement: string,
  userFeedback: string
) => {
  return Axios.post<IResponse<void>>("/merge-opening-statement", {
    id,
    openingStatement,
    userFeedback,
  });
};

export const addActionabilityToUserFeedback = (
  id: string,
  userFeedback: string,
  actionableSteps: string
) => {
  return Axios.post<IResponse<void>>("/add-actionability", {
    id,
    actionableSteps,
    userFeedback,
  });
};

export const textModificationAgent = (
  id: string,
  userFeedback: string,
  selectedText: string,
  modificationInstruction: string
) => {
  return Axios.post<IResponse<void>>("/text-refinement-agent", {
    id,
    userFeedback,
    selectedText,
    modificationInstruction,
  });
};

export const getCompetencyList = () => {
  return Axios.post<IResponse<void>>("/get-competency-list");
};

export const oneOnOneDetailsList = (isAdmin?: boolean) => {
  return Axios.post<IResponse<void>>("/get-one-on-one-details", {
    isAdmin: isAdmin,
  });
};

export const getAiFeedbackByOneOnOneId = (oneOnOneId: string) => {
  return Axios.post<IResponse<void>>("/get-ai-feedback-by-one-on-one-id", {
    oneOnOneId,
  });
};

export const submitAiFeedback = (id: string) => {
  return Axios.post<IResponse<void>>("/submit-aiFeedback", {
    id,
  });
};

export const refreshingAiScoreApi = (id: string, feedbackText: string) => {
  return Axios.post<IResponse<void>>("/refresh-ai-score", {
    id,
    feedbackText,
  });
};

export const getOneOnOne = (id: string) => {
  return Axios.post<IResponse<void>>("/get-one-on-one", {
    id,
  });
};

export const getOneOnOneDetails = (id: string, aggregations: boolean) => {
  return Axios.post<IResponse<void>>("/get-one-on-one-details-v2", {
    id,
    aggregations,
  });
};
export const submitOneOnOne = (id: string, responses = [], notes?: any) => {
  return Axios.post<IResponse<void>>("/submit-one-on-one", {
    id,
    responses,
    ...(notes ? { notes } : {}),
  });
};

export const aiCompetency = (id: string, feedbackText: string) => {
  return Axios.post<IResponse<void>>("/ai-competency", {
    id,
    feedbackText,
  });
};

export const addStrengthCompetency = (id: string, competencyId: string) => {
  return Axios.post<IResponse<void>>("/add-strength-competency", {
    id,
    competencyId,
  });
};
export const removeStrengthCompetency = (id: string, competencyId: string) => {
  return Axios.post<IResponse<void>>("/remove-strength-competency", {
    id,
    competencyId,
  });
};
export const addDevelopmentCompetency = (id: string, competencyId: string) => {
  return Axios.post<IResponse<void>>("/add-development-competency", {
    id,
    competencyId,
  });
};
export const removeDevelopmentCompetency = (
  id: string,
  competencyId: string
) => {
  return Axios.post<IResponse<void>>("/remove-development-competency", {
    id,
    competencyId,
  });
};

export const getTeambleAIGoalsApi = (
  profileVieweeTeambleUserId?: string
) => {
  return Axios.post<IResponse<void>>("/one-on-one-goals", {
    profileVieweeTeambleUserId
  });
};
