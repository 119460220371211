import { Badge } from '@themesberg/react-bootstrap';
import { ISurveyInspector } from 'interfaces';
import * as React from 'react';

interface ISurveyInspectorsProps {
  surveyInspectors: ISurveyInspector[];
}

const SurveyInspectors: React.FunctionComponent<ISurveyInspectorsProps> = (
  { surveyInspectors }
) => {
  return (
    <div>
      {
        surveyInspectors.map(
          (inspector) => (
            <Badge
              key={inspector.id}
              pill={true}
              bg={inspector.isAudience ? 'success' : 'primary'}
              className='badge-lg me-1'
            >
              {inspector.isAudience ? '' : '@ '}{inspector.name}
            </Badge>
          )
        )
      }
    </div>
  );
};

export default SurveyInspectors;
