import { Badge } from '@themesberg/react-bootstrap';
import { ISurveyAudience } from 'interfaces';
import * as React from 'react';

interface ISurveyAudienceProps {
  surveyAudience: ISurveyAudience[];
}

const SurveyAudience: React.FunctionComponent<ISurveyAudienceProps> = (
  { surveyAudience }
) => {
  return (
    <div>
      {
        surveyAudience.map(
          (audience) => (
            <Badge
              key={audience.id}
              pill={true}
              bg={
                audience.isWorkspace ? 'success' :
                  audience.isChannel ? 'info' : 'primary'}
              className='badge-lg me-1'
            >
              {audience.isWorkspace || audience.isChannel ? '' : '@ '}{audience.name}
            </Badge>
          )
        )
      }
    </div>
  );
};

export default SurveyAudience;
