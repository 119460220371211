import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { FormField } from 'components';
import { FormContext } from 'contexts';
import { FormikErrors, FormikProps } from 'formik';
import { FormFieldTypeConstants, IReviewTemplateQuestion } from 'interfaces';
import { IReviewTemplateFormValues } from 'interfaces';
import * as React from 'react';

import ReviewTemplateQuestionsAccordion from './ReviewTemplateQuestionsAccordion';


interface IReviewTemplateFormProps {
  reviewForm: FormikProps<IReviewTemplateFormValues>;
  activeQuestionIndex: number | null;
  onActiveQuestionIndexChange: (index: number | null) => void;
}


const ReviewTemplateForm: React.FunctionComponent<IReviewTemplateFormProps> = (
  { reviewForm, activeQuestionIndex, onActiveQuestionIndexChange }
) => {

  const cardBodyRef = React.useRef<HTMLDivElement>(null);
  const [elRefs, setElRefs] = React.useState<React.RefObject<HTMLDivElement>[]>([]);
  const [oldQuestionCount, setOldQuestionCount] = React.useState<number>(-1);


  React.useEffect(
    () => {

      setOldQuestionCount(reviewForm.values.blocks.length);
      if (reviewForm.values.blocks.length === oldQuestionCount + 1) {
        cardBodyRef.current?.scrollTo({
          behavior: 'smooth',
          top: cardBodyRef.current?.scrollHeight,
        });
      }

    },
    [reviewForm.values.blocks.length, oldQuestionCount]
  );


  React.useEffect(
    () => {

      setElRefs(
        (elRefs) =>
          Array(reviewForm.values.blocks.length)
            .fill(0)
            .map((_, i) => elRefs[i] || React.createRef<HTMLDivElement>()),
      );

    },
    [reviewForm.values.blocks.length]
  );


  React.useEffect(
    () => {

      if (activeQuestionIndex === null) {
        return;
      }

      setTimeout(() => {
        elRefs[activeQuestionIndex]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, 500);

    },
    [activeQuestionIndex, elRefs]
  );


  const handleAccordionToggle = (index: number, ref: React.RefObject<HTMLDivElement>) => {
    if (index === activeQuestionIndex) {
      onActiveQuestionIndexChange(null);
    } else {
      onActiveQuestionIndexChange(index);
    }
  };


  const scrollToFormErrorField = (): void => {

    if (!Object.keys(reviewForm.errors).length) {
      return;
    }

    if (reviewForm.errors.title) {
      cardBodyRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }

    if (!reviewForm.errors.blocks?.length) {
      return;
    }

    const errorIndex = ((reviewForm.errors.blocks) as FormikErrors<IReviewTemplateQuestion>[])
      .findIndex((error) => error && Object.keys(error).length);

    if (errorIndex !== -1) {
      if (errorIndex === activeQuestionIndex) {
        setTimeout(() => {
          elRefs[errorIndex]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 500);
      } else {
        onActiveQuestionIndexChange(errorIndex);
      }
    }

  };


  return (
    <Card className='flex-grow-1 overflow-hidden'>
      <Form
        className='h-100 d-flex flex-column'
        onSubmit={
          (e) => {
            e.preventDefault();
            scrollToFormErrorField();
            reviewForm.handleSubmit(e);
          }
        }
      >

        <Card.Body ref={cardBodyRef} className='overflow-y-auto flex-grow-1 overflow-x-hidden'>
          <FormContext.Provider value={reviewForm}>
            <FormField
              label='Review Name *'
              controlId='review-template-title'
              type={FormFieldTypeConstants.Text}
              placeholder='Enter Review Name'
              controlName='title' />

            {
              reviewForm.values.blocks?.length ?
                (
                  <ReviewTemplateQuestionsAccordion
                    elRefs={elRefs}
                    activeQuestionIndex={activeQuestionIndex}
                    onAccordionToggle={handleAccordionToggle} />
                ) :
                (null)
            }
          </FormContext.Provider>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end flex-shrink-0'>
          <Button
            className='ms-3'
            variant="primary"
            type="submit"
            disabled={reviewForm.isSubmitting}
          >
            {reviewForm.isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </Card.Footer>

      </Form>
    </Card>
  );
};

export default ReviewTemplateForm;