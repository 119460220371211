import * as React from 'react';
import { Card, Table } from '@themesberg/react-bootstrap';
import Logo from './../../assets/img/brand/logo.png';
import { IMenuOption, ISurveyDepartmentInsight } from 'interfaces';
import { CustomSelect, EmptyState, Preloader } from 'components';
import { getSurveyInsightsByDepartment, getSurveysAsMenuOptions } from 'data';
import { finalize } from 'rxjs';
import { useApp, useOptionsAndSelectedOption } from 'hooks';


interface ISurveyInsightsDepartmentProps {
  workspaceOrChannelId: string;
}

interface IDepartmentInsightsWidgetProps {
  survey: IMenuOption;
}

interface ITableHeaderRowProps {
  title: string;
}

interface ITableHeaderProps {
  departments: string[];
}

interface ITableRowProps {
  insight: ISurveyDepartmentInsight;
}


const TableHeaderRow: React.FC<ITableHeaderRowProps> = (
  { title }
) => {
  return (
    <th
      style={{ width: '155px' }}
      className='border-0 px-0 py-4 text-center fs-4 fw-bold text-capitalize'
    >
      {title}
    </th>
  );
}


const TableHeader: React.FunctionComponent<ITableHeaderProps> = (
  { departments }
) => {

  return (
    <thead>
      <tr>
        <th className='border-0 py-3'></th>
        {
          departments.map(
            (department) => (
              <TableHeaderRow
                key={department}
                title={department} />
            )
          )
        }
      </tr>
    </thead>
  );

};


const TableRow: React.FunctionComponent<ITableRowProps> = (
  { insight }
) => {
  return (
    <tr>
      <td
        className='border-0 py-4 fw-bolder fs-4'
      >
        {insight.question}
      </td>
      {
        insight.departments.map(
          (department) => {
            return (
              <td
                className='border-0 px-0 py-4 text-center fw-bolder fs-5 align-middle rounded'
                key={department.name}
                style={{
                  backgroundColor: department.color,
                  width: '155px'
                }}
              >
                {department.value}
              </td>
            );
          }
        )
      }
    </tr>
  );
};


const DepartmentInsightsWidget: React.FunctionComponent<IDepartmentInsightsWidgetProps> =
  ({ survey }) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [insightsByDepartment, setInsightsByDepartment] =
      React.useState<ISurveyDepartmentInsight[]>([]);

    const [departments, setDepartments] = React.useState<string[]>([]);


    React.useEffect(
      () => {

        if (!insightsByDepartment.length) {
          setDepartments([]);
        } else {

          const departmentNames = {} as any;
          const departmentList: string[] = [];

          insightsByDepartment.forEach(
            (insight) => {

              insight.departments.forEach(
                (department) => {
                  departmentNames[department.name] = true;
                }
              );
            }
          );

          Object.keys(departmentNames).forEach(
            (departmentName) => {
              departmentList.push(departmentName);
            }
          );

          setDepartments(departmentList);

        }
      },
      [insightsByDepartment]
    );


    React.useEffect(
      () => {

        setIsLoading(true);
        setInsightsByDepartment([]);

        getSurveyInsightsByDepartment(survey.value)
          .pipe(
            finalize(() => setIsLoading(false))
          )
          .subscribe(
            (response) => {
              setInsightsByDepartment(response.data.data);
            }
          );

      },
      [survey.value]
    );


    return (
      <div className='position-relative flex-grow-1 overflow-y-auto'>
        {
          isLoading ?
            (<Preloader show={isLoading} />) :
            (
              insightsByDepartment.length > 0 ?
                (
                  <Card className='overflow-hidden h-100'>
                    <Card.Header className='d-flex align-items-center'>
                      <img className='rounded' width='70' height='70' src={Logo} alt='Teamble Logo' />
                      <h3 className='fw-bolder ms-3'> {survey.label} </h3>
                    </Card.Header>
                    <Card.Body className='ps-0 overflow-x-auto'>
                      <Table
                        className='mx-auto my-0'
                        style={{
                          borderCollapse: 'separate',
                          borderSpacing: '6px',
                          maxWidth: '90%',
                          width: 'auto',
                        }}
                      >
                        <TableHeader departments={departments} />
                        <tbody>
                          {
                            insightsByDepartment.map(
                              (insight) => {
                                return (
                                  <TableRow key={insight.id} insight={insight} />
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                ) :
                (
                  <EmptyState message={<> No insights available for the selected survey. </>} />
                )
            )
        }
      </div>
    );

  };


const SurveyInsightsDepartment: React.FunctionComponent<ISurveyInsightsDepartmentProps> = (
  { workspaceOrChannelId }
) => {

  const app = useApp();

  const {
    isLoading,
    options: surveyOptions,
    selectedOption: selectedSurveyOption,
    onSelectedOptionUpdate: setSelectedSurveyOption,
  } = useOptionsAndSelectedOption<IMenuOption>(
    getSurveysAsMenuOptions(app, workspaceOrChannelId),
    [workspaceOrChannelId]
  );


  return (
    <div className='position-relative h-100 d-flex flex-column overflow-hidden'>
      {
        isLoading ?
          (<Preloader show={isLoading} />) :
          (
            surveyOptions.length > 0 && selectedSurveyOption ?
              (
                <>
                  <div className='flex-shrink-0'>
                    <CustomSelect
                      className='w-25 mb-3'
                      optionType='description'
                      options={surveyOptions}
                      value={selectedSurveyOption}
                      placeholder='Select a survey...'
                      onChange={(option) => setSelectedSurveyOption(option as IMenuOption)} />
                  </div>
                  <DepartmentInsightsWidget survey={selectedSurveyOption} />
                </>
              ) :
              (
                <EmptyState message={<> No surveys available to view the insights. </>} />
              )
          )
      }
    </div>
  );
};

export default SurveyInsightsDepartment;
