import { Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { CustomSelect, EmptyState, Preloader } from 'components';
import { getReviewsByState, getUserDepartmentsAsMenuOptions, getUserReportReviewees, getCompanyUsers } from 'data';
import { IMenuOption, IReportFilters, ISimpleMenuOption } from 'interfaces';
import * as React from 'react';
import { finalize, forkJoin } from 'rxjs';
import { SELECT_ALL_OPTION, USER_TABLE_DEACTIVATE_OPTION, USER_TABLE_FILTER_OPTIONS } from 'teamble-constants';

interface IFiltersCardProps {
  selectedFilters: IReportFilters;
  onSelectedFiltersChange: (filters: IReportFilters) => void;
}

const FiltersCard: React.FunctionComponent<IFiltersCardProps> = ({
  selectedFilters,
  onSelectedFiltersChange
}) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reviewOptions, setReviewOptions] = React.useState<IMenuOption[]>([]);
  const [reviewerOptions, setReviewerOptions] = React.useState<IMenuOption[]>([]);
  const [revieweeOptions, setRevieweeOptions] = React.useState<IMenuOption[]>([]);
  const [departmentOptions, setDepartmentOptions] = React.useState<IMenuOption[]>([]);
  const [revieweeClone, setRevieweeClone] = React.useState<IMenuOption[]>([]);
  const [revieweeUserState, setRevieweeUserState] = React.useState<ISimpleMenuOption | null>(USER_TABLE_FILTER_OPTIONS[1]);

  React.useEffect(
    () => {

      setIsLoading(true);
      setReviewOptions([]);
      setReviewerOptions([]);
      setRevieweeOptions([]);

      const subscription = forkJoin(
        [
          getReviewsByState(["Live", "Closed"]),
          getUserReportReviewees(),
          getCompanyUsers(),
          getUserDepartmentsAsMenuOptions()
        ]
      ).pipe(
        finalize(
          () => setIsLoading(false)
        )
      ).subscribe(
        ([reviewsResponse, revieweeResponse, reviewerResponse, departmentResponse]) => {
          const reviewOptions = reviewsResponse.data.data;
          const revieweeOptions = revieweeResponse.data.data;
          const departmentOptions = departmentResponse.data.data;
          const reviewerOptions = reviewerResponse.data.data.map(data => ({ label: data.name as string, value: data.id as string }));


          setReviewOptions([SELECT_ALL_OPTION, ...reviewOptions]);
          setReviewerOptions([SELECT_ALL_OPTION, ...reviewerOptions]);

          // setting reviewee
          const activeReviewees = revieweeOptions.filter((user: any) => user.state === "Active");
          setRevieweeOptions([SELECT_ALL_OPTION, ...activeReviewees]);
          setRevieweeClone(revieweeOptions);


          setDepartmentOptions(departmentOptions);

          onSelectedFiltersChange({
            reviews: [SELECT_ALL_OPTION, ...reviewOptions],
            reviewers: [SELECT_ALL_OPTION, ...reviewerOptions],
            reviewees: [SELECT_ALL_OPTION, ...activeReviewees],
            departments: []
          });
        }
      );

      return () => subscription.unsubscribe();
    }, []);


  React.useEffect(() => {

    if (!revieweeUserState) return;

    const userState = revieweeUserState.value;

    let reviewees = [...revieweeClone];

    if (userState === 'include') {
      reviewees = reviewees.filter((user: any) => user.state === "Active");
    } else if (userState === 'exclude') {
      reviewees = reviewees.filter((user: any) => user.state === "In-Active");
    } else if (userState === 'deactivate') {
      reviewees = reviewees.filter((user: any) => user.state === "Deativated");
    }


    onSelectedFiltersChange({
      ...selectedFilters,
      reviewees: reviewees
    });

    setRevieweeOptions(reviewees.length ? [SELECT_ALL_OPTION, ...reviewees] : [...reviewees]);

  }, [revieweeUserState])

  const handleFilterChange = (key: string, selectedOptions: IMenuOption[]) => {
    onSelectedFiltersChange({
      ...selectedFilters,
      [key]: selectedOptions
    });
  };


  return (
    <Card className='mb-3 flex-shrink-0'>
      <Card.Header>
        <div style={{ justifyContent: "space-between" }} className='d-flex align-items-center flex-shrink-0 px-0 pt-0'>
          <h5 className='m-0'>Filters</h5>
          <Col xs={2}>
            <label>User Status</label>
            <CustomSelect
              options={[...USER_TABLE_FILTER_OPTIONS, USER_TABLE_DEACTIVATE_OPTION]}
              value={revieweeUserState}
              onChange={
                (option) => {
                  setRevieweeUserState(option as ISimpleMenuOption);
                }
              }
            />
          </Col>
        </div>
      </Card.Header>
      <Card.Body
        style={{
          height: isLoading || !(reviewOptions.length && reviewerOptions.length && revieweeOptions.length) ? '100px' : 'auto'
        }}
      >
        {
          isLoading ?
            (
              <Preloader show={isLoading} transparentBg={false} />
            ) :
            (
              reviewOptions.length && reviewerOptions.length ?
                (
                  <Row>
                    <Col>
                      <Form.Label> Reviews  </Form.Label>
                      <CustomSelect
                        isMulti
                        allowSelectAll
                        optionType='checkbox'
                        isSearchable
                        options={reviewOptions}
                        value={selectedFilters.reviews}
                        onChange={(options) => { handleFilterChange('reviews', options as IMenuOption[]) }} />
                    </Col>
                    <Col>
                      <Form.Label> Reviewers  </Form.Label>
                      <CustomSelect
                        isMulti
                        allowSelectAll
                        isSearchable
                        optionType='user'
                        options={reviewerOptions}
                        value={selectedFilters.reviewers}
                        onChange={(options) => { handleFilterChange('reviewers', options as IMenuOption[]) }} />
                    </Col>
                    <Col>
                      <Form.Label> Reviewees  </Form.Label>
                      <CustomSelect
                        isMulti
                        allowSelectAll
                        isSearchable
                        optionType='user'
                        options={revieweeOptions}
                        value={selectedFilters.reviewees}
                        onChange={(options) => { handleFilterChange('reviewees', options as IMenuOption[]) }} />
                    </Col>
                    <Col>
                      <Form.Label> Reviewees Department  </Form.Label>
                      <CustomSelect
                        isMulti
                        isSearchable
                        optionType='user'
                        options={departmentOptions}
                        value={selectedFilters.departments}
                        onChange={(options) => { handleFilterChange('departments', options as IMenuOption[]) }} />
                    </Col>
                  </Row>
                ) :
                (
                  <EmptyState message={<>No filters available.</>} />
                )
            )
        }
      </Card.Body>
    </Card>
  );
};

export default FiltersCard;
