import { useApp } from './useApp';
import {
  IAudience,
  IAudiencePill,
  IAudienceType,
  IChannelsAudience,
  IUsersAudience,
  SignInWith,
  IGroupsAudience
} from 'interfaces';
import React from 'react';

export const useAudiencePills = (audience?: IAudience): IAudiencePill[] => {

  const [audiencePills, setAudiencePills] = React.useState<IAudiencePill[]>([]);

  const app = useApp();


  React.useEffect(
    () => {

      if (!audience?.length) {
        setAudiencePills([]);
        return;
      }

      const organizationAudiencePills: IAudiencePill[] = [];
      const channelAudiencePills: IAudiencePill[] = [];
      const userAudiencePills: IAudiencePill[] = [];

      audience.forEach(
        (aud) => {

          switch (aud.type) {

            case IAudienceType.Team: {
              organizationAudiencePills.push({
                id: aud.type,
                name: 'Entire Team',
                isChannel: false,
                isWorkspace: true,
              });
              break;
            }

            case IAudienceType.Workspace: {
              organizationAudiencePills.unshift({
                id: aud.type,
                name: 'Entire Workspace',
                isChannel: false,
                isWorkspace: true,
              });
              break;
            }

            case IAudienceType.Groups: {
              (aud as IGroupsAudience).groups.forEach(
                (group) => {
                  channelAudiencePills.push({
                    id: group.channelInfo.msteams.groupId,
                    name: group.name,
                    isChannel: false,
                    isGroup: true,
                    isWorkspace: false,
                  });
                }
              );
              break;
            }

            case IAudienceType.Channels: {
              (aud as IChannelsAudience).channels.forEach(
                (channel) => {
                  channelAudiencePills.push({
                    id: channel.channelInfo.slack.channelId,
                    name: channel.channelInfo.slack.name,
                    isChannel: true,
                    isWorkspace: false,
                  });
                }
              );
              break;
            }

            case IAudienceType.Users: {
              (aud as IUsersAudience).users.forEach(
                (user) => {
                  userAudiencePills.push({
                    id: user.userId,
                    name: user.name,
                    isChannel: false,
                    isWorkspace: false,
                    msTeamsUserId: app === SignInWith.MsTeams ? user.channelInfo.msteams.userId : undefined,
                    slackUserId: app === SignInWith.Slack ? user.channelInfo.slack.userId : undefined
                  });
                }
              );
              break;
            }

          }

        }
      );

      setAudiencePills(
        [
          ...organizationAudiencePills,
          ...channelAudiencePills,
          ...userAudiencePills,
        ]
      );

    },
    [app, audience]
  );

  return audiencePills;

};