import { Divider } from 'components';
import { IFeedback, IFeedbackMaps } from 'interfaces';
import React from 'react';

interface IFeedbackDevelopmentAreasSectionProps {
  feedback: IFeedback;
  feedbackMaps: IFeedbackMaps;
}


const FeedbackDevelopmentAreasSection: React.FC<IFeedbackDevelopmentAreasSectionProps> = (
  { feedback, feedbackMaps }
) => {

  const [developmentAreas, setDevelopmentAreas] = React.useState<React.ReactNode[]>([]);


  React.useEffect(
    () => {

      if (!feedback.dimensionInfos?.length) {
        setDevelopmentAreas([]);
        return;
      }

      const devAreas: React.ReactNode[] = [];

      for (const dimensionInfo of feedback.dimensionInfos) {

        if (!dimensionInfo.focusAreas) {
          continue;
        }

        const dimension = feedbackMaps.dimensionsMap[
          dimensionInfo.id
        ];
        if (!dimension) {
          continue;
        }

        for (const focusAreaInfo of dimensionInfo.focusAreas) {

          if (!focusAreaInfo.sug) {
            continue;
          }

          const focusArea = feedbackMaps.focusAreasMap[
            focusAreaInfo.id
          ];
          if (!focusArea) {
            continue;
          }

          devAreas.push(
            <p className='m-0'> {`${dimension.name} - ${focusArea.name}`} </p>
          );

          if (!focusAreaInfo.suggestions) {
            continue;
          }

          for (const suggestionInfo of focusAreaInfo.suggestions) {

            const suggestion = feedbackMaps.suggestionsMap[
              suggestionInfo.id
            ];
            if (!suggestion) {
              continue;
            }

            devAreas.push(
              <p><strong> teamble suggestions </strong></p>
            );

            switch (suggestion.type) {
              case 'video': {
                devAreas.push(
                  <div className='d-flex mb-2'>
                    <div className='me-2 bg-gray rounded-3 flex-shrink-0' style={{ width: 4 }} />
                    <div className='flex-grow-1'>
                      <p>
                        <em>
                          {suggestion.desc}
                        </em>
                      </p>
                      <a href={suggestion.url} target='_blank'> {suggestion.title} </a>
                    </div>
                  </div>
                );
                break;
              }

              default: {
                devAreas.push(
                  <div className='d-flex mb-2'>
                    <div className='me-2 bg-gray rounded-3 flex-shrink-0' style={{ width: 4 }} />
                    <div className='flex-grow-1'>
                      <p>
                        <em>
                          {suggestion.text}
                        </em>
                      </p>
                    </div>
                  </div>
                );
                break;
              }

            }
          }
        }
      }

      setDevelopmentAreas(devAreas);

    },
    [feedback.dimensionInfos, feedbackMaps.dimensionsMap, feedbackMaps.focusAreasMap, feedbackMaps.suggestionsMap]
  );


  return (
    <>
      {
        developmentAreas.length
          ?
          <>
            <p>
              <strong>
                <span role='img' aria-label='Strengths' > 🎯 </span> Development Areas
              </strong>
            </p>
            {
              developmentAreas.map(
                (developmentArea, index) => (
                  <React.Fragment key={index}>
                    {developmentArea}
                  </React.Fragment>
                )
              )
            }
            <Divider />
          </>
          :
          null
      }
    </>
  );
};

export default FeedbackDevelopmentAreasSection;