import { AxiosObservable } from "axios-observable";
import { IMenuOption, IResponse } from "interfaces";
import React, { useCallback } from "react";
import { finalize } from "rxjs";
import { SELECT_ALL_OPTION } from "teamble-constants";

interface ISelectedOptionState<T> {
  selectedOption: T | null;
  onSelectedOptionUpdate: (option: T | null) => void;
  onOptionsUpdate: (options: T[]) => void;
}

interface IUseOptionsAndSelectedOption<T> extends ISelectedOptionState<T> {
  isLoading: boolean;
  options: T[];
}

export const useOptionsAndSelectedOption = <T extends IMenuOption>(
  optionsSource$: AxiosObservable<IResponse<T[]>> | null,
  optionsDependencies: any[] = [],
  selectNestedOption: boolean = false,
  selectFirstOptionAsDefault: boolean = true,
  allowSelectAll: boolean = false
): IUseOptionsAndSelectedOption<T> => {


  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [options, setOptions] = React.useState<T[]>([]);
  const [selectedOption, setSelectedOption] = React.useState<T | null>(null);

  const onSelectedOptionUpdate = useCallback(
    (option: T | null) => {
      setSelectedOption(option);
    },
    []
  );


  const onOptionsUpdate = useCallback(
    (options: T[]) => {
      setOptions(allowSelectAll ? [SELECT_ALL_OPTION as T, ...options] : options);
    },
    [allowSelectAll]
  );


  React.useEffect(
    () => {

      setIsLoading(true);
      setOptions([]);
      setSelectedOption(null);

      if (!optionsSource$) {
        setIsLoading(false);
        return;
      }

      const subscription = optionsSource$
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {

            setOptions(
              allowSelectAll ?
                [SELECT_ALL_OPTION as T, ...response.data.data] :
                response.data.data
            );

            if (!selectFirstOptionAsDefault) {
              return;
            }

            if (selectNestedOption) {
              setSelectedOption(response.data.data[0].options?.[0] as T);
            } else {
              setSelectedOption(response.data.data[0]);
            }
          }

        );

      return () => {
        subscription.unsubscribe();
      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...optionsDependencies]
  );


  return {
    isLoading,
    options,
    selectedOption,
    onSelectedOptionUpdate,
    onOptionsUpdate
  };

};