import {
  IAudienceType,
  ICompanyConfig,
  IFeedbackLoopTemplateTypes,
  IGroupedSurveyTemplates,
  IMetaOptions,
  IReviewTemplate,
  ISimpleMenuOption,
  ISimpleTemplateMenuOption,
  ITemplate,
} from "interfaces";

import PlusIcon from "./../assets/img/icons/plus-solid.svg";
// @ts-ignore
import moment from "moment/min/moment-with-locales";
moment.locale(window.navigator.language);

const format = moment.localeData().longDateFormat("L");

export const DATE_TIME_FORMAT = `${format} hh:mm a`;
export const DATE_FORMAT = `${format}`;

export const BUILD_FROM_SCRATCH_REVIEW_TEMPLATE: Partial<IReviewTemplate> = {
  id: "build-from-scratch",
  logoUrl: PlusIcon,
  title: "Build from Scratch",
};

export const BUILD_FROM_SCRATCH_TEMPLATE: ITemplate = {
  id: "build-from-scratch",
  title: "➕ Build from Scratch",
};

export const BUILD_FROM_SCRATCH_SURVEY_TEMPLATE: Partial<IGroupedSurveyTemplates> =
  {
    group: "Build from Scratch",
    templates: [
      {
        group: "Build from Scratch",
        ...BUILD_FROM_SCRATCH_TEMPLATE,
      },
    ],
  };

export const BUILD_FROM_SCRATCH_SURVEY_TEMPLATE_OPTION: ISimpleTemplateMenuOption =
  {
    value: (BUILD_FROM_SCRATCH_SURVEY_TEMPLATE as IGroupedSurveyTemplates)
      .templates[0].id,
    label: (BUILD_FROM_SCRATCH_SURVEY_TEMPLATE as IGroupedSurveyTemplates)
      .templates[0].title,
    type: "",
  };

export const SLACK_AUDIENCE_TYPES: ISimpleMenuOption[] = [
  {
    value: IAudienceType.Workspace,
    label: "➕ Entire Workspace",
  },
  {
    value: IAudienceType.Channels,
    label: "➕ Select Channels",
  },
  {
    value: IAudienceType.Users,
    label: "➕ Select Users",
  },
];

export const MSTEAMS_AUDIENCE_TYPES: ISimpleMenuOption[] = [
  {
    value: IAudienceType.Team,
    label: "➕ Entire Team",
  },
  {
    value: IAudienceType.Users,
    label: "➕ Select Users",
  },
  {
    value: IAudienceType.Groups,
    label: "➕ Select Groups",
  },
];

export const SURVEY_INSPECTOR_TYPES: ISimpleMenuOption[] = [
  {
    value: "audience",
    label: "➕ Audience",
  },
  {
    value: "users",
    label: "➕ Select Users",
  },
];

export const SURVEY_RESULT_OPTIONS: ISimpleMenuOption[] = [
  {
    value: "survey-closed",
    label: "Once survey is closed",
  },
  {
    value: "real-time",
    label: "Real Time",
  },
];

export const SURVEY_DURATION_SPAN_OPTIONS: ISimpleMenuOption[] = Array.from(
  { length: 60 },
  (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })
);

export const SURVEY_DURATION_UNIT_OPTIONS: ISimpleMenuOption[] = [
  {
    value: "minutes",
    label: "Minute(s)",
  },
  {
    value: "hours",
    label: "Hour(s)",
  },
  {
    value: "days",
    label: "Day(s)",
  },
  {
    value: "weeks",
    label: "Week(s)",
  },
];

export const SCHEDULER_FREQUENCY_OPTIONS: IMetaOptions = {
  options: [
    {
      value: "does-not-repeat",
      label: "Does not repeat",
    },
    {
      value: "every-day",
      label: "Every day at",
    },
    {
      value: "monday-to-friday",
      label: "Every weekday at",
    },
    {
      value: "every-week",
      label: "Every week on",
    },
    {
      value: "every-2-weeks",
      label: "Every 2 weeks on",
    },
    {
      value: "every-3-weeks",
      label: "Every 3 weeks on",
    },
    {
      value: "monthly-nth-day",
      label: "Every month on day",
    },
    {
      value: "monthly-nth-weekday",
      label: "Every month on",
    },
    {
      value: "monthly-last-weekday",
      label: "Every month on last",
    },
    {
      value: "two-monthly-nth-day",
      label: "Every 2 months on day",
    },
    {
      value: "two-monthly-nth-weekday",
      label: "Every 2 months on",
    },
    {
      value: "two-monthly-last-weekday",
      label: "Every 2 months on last",
    },
  ],
  constants: {
    DOES_NOT_REPEAT: "does-not-repeat",
    EVERY_DAY: "every-day",
    MONDAY_TO_FRIDAY: "monday-to-friday",
    EVERY_WEEK: "every-week",
    EVERY_2_WEEKS: "every-2-weeks",
    EVERY_3_WEEKS: "every-3-weeks",
    MONTHLY_NTH_DAY: "monthly-nth-day",
    TWO_MONTHLY_NTH_DAY: "two-monthly-nth-day",
    MONTHLY_NTH_WEEKDAY: "monthly-nth-weekday",
    TWO_MONTHLY_NTH_WEEKDAY: "two-monthly-nth-weekday",
    MONTHLY_LAST_WEEKDAY: "monthly-last-weekday",
    TWO_MONTHLY_LAST_WEEKDAY: "two-monthly-last-weekday",
  },
};

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const NUMBER_POSTFIX = {
  1: "st",
  2: "nd",
  3: "rd",
  4: "th",
};

export const NUMBER_OF_SCHEDULE_EVENTS = 3;

export const SURVEY_STATE_FILTERS: IMetaOptions = {
  options: [
    {
      value: "open",
      label: "Live",
    },
    {
      value: "closed",
      label: "Closed",
    },
  ],
  constants: {
    OPEN: "open",
    CLOSED: "closed",
  },
};

export const TEMPLATE_VISIBILITY_METADATA: IMetaOptions = {
  options: [
    {
      value: "everyone",
      label: "Visible to Everyone",
    },
    {
      value: "admins",
      label: "Visible to Workspace Admins only",
    },
    {
      value: "superAdmin",
      label: "Visible to Me only",
    },
  ],
  constants: {
    EVERYONE: "Visible to Everyone",
    ADMINS: "Visible to Workspace Admins only",
    SUPER_ADMIN: "Visible to Me only",
  },
};

export const NOTIFICATION_SETTINGS: IMetaOptions = {
  options: [
    {
      value: "dm-and-channels",
      label: "Both DM and Post",
    },
    {
      value: "dm-only",
      label: "Direct Message",
    },
    {
      value: "channels-only",
      label: "Channel Post",
    },
  ],
  constants: {
    DM_ONLY: "dm-only",
    DM_AND_CHANNELS: "dm-and-channels",
    CHANNELS_ONLY: "channels-only",
  },
};

export const SCALE_11_BAR_COLORS = [
  "#5553DA",
  "#23A8F5",
  "#4FB9F7",
  "#7BCBF9",
  "#A7DCFB",
  "#FFBA7A",
  "#F0BACA",
  "#E998B0",
  "#E17596",
  "#DA537B",
  "#BD51FF",
];

export const SCALE_10_BAR_COLORS = [
  "#5553DA",
  "#23A8F5",
  "#4FB9F7",
  "#7BCBF9",
  "#A7DCFB",
  "#F0BACA",
  "#E998B0",
  "#E17596",
  "#DA537B",
  "#BD51FF",
];

export const SCALE_9_BAR_COLORS = [
  "#23A8F5",
  "#4FB9F7",
  "#7BCBF9",
  "#A7DCFB",
  "#FFBA7A",
  "#F0BACA",
  "#E998B0",
  "#E17596",
  "#DA537B",
];

export const SCALE_8_BAR_COLORS = [
  "#23A8F5",
  "#4FB9F7",
  "#7BCBF9",
  "#A7DCFB",
  "#F0BACA",
  "#E998B0",
  "#E17596",
  "#DA537B",
];

export const SCALE_7_BAR_COLORS = [
  "#23A8F5",
  "#7BCBF9",
  "#A7DCFB",
  "#FFBA7A",
  "#F0BACA",
  "#E998B0",
  "#DA537B",
];

export const SCALE_6_BAR_COLORS = [
  "#23A8F5",
  "#7BCBF9",
  "#A7DCFB",
  "#F0BACA",
  "#E998B0",
  "#DA537B",
];

export const SCALE_5_BAR_COLORS = [
  "#23A8F5",
  "#7BCBF9",
  "#FFBA7A",
  "#E998B0",
  "#DA537B",
];

export const SCALE_4_BAR_COLORS = ["#23A8F5", "#7BCBF9", "#E998B0", "#DA537B"];

export const SCALE_3_BAR_COLORS = ["#23A8F5", "#FFBA7A", "#DA537B"];

export const SCALE_2_BAR_COLORS = ["#23A8F5", "#DA537B"];

export const CUSTOM_SCALE_BAR_COLORS = [
  "#5553DA",
  "#7775E1",
  "#9998E9",
  "#BBBAF0",
  "#A7DCFB",
  "#7BCBF9",
  "#4FB9F7",
  "#23A8F5",
  "#4DE1F8",
  "#71E7F9",
  "#94EDFB",
  "#B8F3FC",
  "#99EED8",
  "#66E5C4",
  "#33DDB1",
  "#00D59D",
  "#7BD274",
  "#95DB90",
  "#95DB90",
  "#CAEDC7",
];

export const FEEDBACK_LOOP_TEMPLATE_TYPES = [
  {
    id: IFeedbackLoopTemplateTypes.Feedback,
    title: "Feedback",
    emoji: "💡",
  },

];

export const COMPETENCY_COLORS = [
  "#00D49D",
  "#23A8F5",
  "#4DE1F8",
  "#5553DA",
  "#7BD274",
  "#BD51FF",
  "#DA537B",
  "#E37F60",
  "#FFA959",
  "#FFE249",
  "#33DDB1",
  "#4FB9F7",
  "#71E7F9",
  "#7775E1",
  "#95DB90",
  "#CA74FF",
  "#E17596",
  "#E99980",
  "#FFBA7A",
  "#FFE86E",
  "#66E5C4",
  "#7BCBF9",
  "#94EDFB",
  "#9998E9",
  "#B0E4AC",
  "#D796FF",
  "#E998B0",
  "#EEB29F",
  "#FFCB9B",
  "#FFEE92",
  "#99EED8",
  "#A7DCFB",
  "#B8F3FC",
  "#BBBAF0",
  "#CAEDC7",
  "#E5B9FF",
  "#F0BACA",
  "#F4CCBF",
  "#FFDDBD",
  "#FFF3B6",
];

export const COLOR_CODE_DEFAULT_SETTING_FIRST_COLOR = "#19BEFB";
export const COLOR_CODE_REVERSE_SETTING_FIRST_COLOR = "#DA537B";

export const ANONYMITY_TYPE_LABELS = {
  "non-anonymous": "Non Anonymous",
  anonymous: "Anonymous",
};

export const TABLE_ACTION_COLUMN_WIDTH = "200px";
export const TABLE_PROGRESS_COLUMN_WIDTH = "210px";
export const TABLE_CHECKBOX_COLUMN_WIDTH = "75px";

export const FEEDBACK_CHART_COLORS = [
  "#19BEFB", // white color text
  "#A0E0FA",
  "#DA537B", // white color text
  "#ECB5C7",
  "#FFA959", // white color text
  "#FFC48D",
  "#7BD274", // white color text
  "#A7DDA2",
  "#5553DA", // white color text
  "#ABA9FF",
  "#BD51FF", // white color text
  "#DA9EFF",
  "#7775E1", // white color text
  "#BBBAF0",
  "#9998E9", // white color text
  "#A7DCFB",
  "#7BCBF9",
  "#4FB9F7",
  "#23A8F5",
  "#4DE1F8",
  "#71E7F9",
  "#94EDFB",
  "#B8F3FC",
  "#99EED8",
  "#66E5C4",
  "#33DDB1",
  "#00D59D",
  "#95DB90",
  "#CAEDC7",
];

export const FEEDBACK_CHART_FONT_WHITE_COLORS = [
  "#7775E1",
  "#9998E9",
  "#19BEFB",
  "#DA537B",
  "#FFA959",
  "#7BD274",
  "#5553DA",
  "#BD51FF",
];

export const DEFAULT_COMPANY_CONFIG: ICompanyConfig = {
  showManagerToColumn: true,
};

export const OBJECTIVE_MODE = {
  EDIT: "edit",
  ADD: "add",
  READ: "read",
  ACTION: "action",
  REMOVE: "remove",
  APPROVED: "approved",
  PENDING: "pending",
};

export const fbReqStates = {
  open: "open",
  submitted: "submitted",
  declined: "declined",
  expired: "expired",
  canceled: "canceled",
};
