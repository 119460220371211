import { IMetadata, IMetaDataResponseData, IMetaOptionsResponse, IResponse, MetaDataKeys } from 'interfaces';
import Axios, { AxiosObservable } from 'axios-observable';
import { map } from 'rxjs';


export const getMetadata = (): AxiosObservable<IResponse<IMetadata>> | any => {
  return Axios.post<IResponse<IMetaDataResponseData>>("/list-metadata").pipe(
    map(
      (response) => {

        const metaData = response.data.data;

        (Object.keys(metaData) as MetaDataKeys[]).forEach(
          (key: MetaDataKeys) => {

            const newFirstLevelOptions = (metaData[key] as IMetaOptionsResponse).options?.map(
              (firstLevelOption) => {

                const newSecondLevelOptions = firstLevelOption.options?.map(
                  (secondLevelOption) => ({
                    ...secondLevelOption,
                    label: secondLevelOption.text
                  })
                );

                return {
                  ...firstLevelOption,
                  options: newSecondLevelOptions
                };

              }
            );

            (metaData[key] as IMetaOptionsResponse) = {
              ...metaData[key],
              options: newFirstLevelOptions
            };

          }
        );

        return {
          ...response,
          data: {
            ...response.data,
            data: {
              ...metaData,
            }
          }
        };

      }
    )
  );
};
