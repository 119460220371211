import { Col, Form, Row } from '@themesberg/react-bootstrap';
import { CustomSelect } from 'components';
import { getCompetenciesListAsMenuOptions } from 'data';
import { ISimpleMenuOption, ISimpleMenuOptionWithSubOptions } from 'interfaces';
import React from 'react';
import { finalize } from 'rxjs';
import { NONE_OPTION } from 'teamble-constants';
import { StringParam, useQueryParam } from 'use-query-params';
import { getSelectedOptionByQueryParam } from 'utils';

interface ICompetencyAndBehaviourFilterProps { }

const CompetencyAndBehaviourFilter: React.FC<ICompetencyAndBehaviourFilterProps> = () => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // Options states
  const [competencyOptions, setCompetencyOptions] = React.useState<ISimpleMenuOptionWithSubOptions[]>([]);

  // Route Hooks
  const [competency, setCompetency] = useQueryParam('competency', StringParam);
  const [behaviour, setBehaviour] = useQueryParam('behaviour', StringParam);

  // Selected Options by query params
  const selectedCompetencyOption = getSelectedOptionByQueryParam<ISimpleMenuOptionWithSubOptions>(
    competency, competencyOptions
  );
  const selectedBehaviourOption = getSelectedOptionByQueryParam(
    behaviour, selectedCompetencyOption?.subOptions
  );

  // Fetch the competency options
  React.useEffect(
    () => {

      setIsLoading(true);
      const subscription = getCompetenciesListAsMenuOptions()
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setCompetencyOptions(response.data.data);
          }
        );

      return () => subscription.unsubscribe();
    },
    []
  );


  return (
    <Row>
      <Col>
        <Form.Label>Competency</Form.Label>
        <CustomSelect
          isSearchable
          isLoading={isLoading}
          options={[NONE_OPTION, ...competencyOptions]}
          value={selectedCompetencyOption}
          onChange={(option) => {
            setCompetency((option as ISimpleMenuOption).value);
          }}
        />
      </Col>

      <Col>
        <Form.Label>Behaviour</Form.Label>
        <CustomSelect
          isSearchable
          isLoading={isLoading}
          options={selectedCompetencyOption?.subOptions ? [NONE_OPTION, ...selectedCompetencyOption.subOptions] : []}
          value={selectedBehaviourOption}
          onChange={(option) => {
            setBehaviour((option as ISimpleMenuOption).value);
          }}
        />
      </Col>
    </Row>
  );
};

export default CompetencyAndBehaviourFilter;