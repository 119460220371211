import { Image } from "@themesberg/react-bootstrap";
import HeaderText from "modules/1-on-1s/components/HeaderText/HeaderText";
import TextGradientPrimaryButton from "modules/1-on-1s/components/TextGradientPrimaryButton/TextGradientPrimaryButton";
import TeambleAiIconSvg from "assets/img/teambleAiIcon.svg";
import "./AiFeedbackContainer.scss";
import React from "react";
import { FeedbackData, oneOnOneDetailListProps } from "modules/1-on-1s/types";
import { useUserProfile } from "components";
import { useHistory, useLocation } from "react-router-dom";

interface AiFeedbackContainerProps {
  aiFeedback: FeedbackData | null;
  data: oneOnOneDetailListProps | null;
}

const AiFeedbackContainer: React.FC<AiFeedbackContainerProps> = ({
  aiFeedback,
  data,
}) => {
  const location = useLocation();
  const { userProfile } = useUserProfile();
  const history = useHistory();

  if (!aiFeedback) {
    return <></>;
  }
  return (
    <div className="aiFeedback-container">
      <div className="header">
        <HeaderText
          text={"Feedback for upcoming 1-on-1"}
          fontSize="24px"
          lineHeight="24px"
          letterSpacing="-0.24px"
        />
        {/* <TertiaryButton text={"Share"} /> */}
      </div>
      <div className="body-container">
        <div className="ai-feedback-sidebar">
          <div className="ai-score-tag">
            <div className="tag-name">AI score</div>
            <div className="tag-button-score">
              <Image src={TeambleAiIconSvg} height={26} width={26} />
              {aiFeedback && aiFeedback?.aiScore >= 0
                ? Math.floor(aiFeedback?.aiScore ?? 0)
                : 0}
            </div>
          </div>
          {data?.reviewerId &&
            userProfile?.teambleUserId &&
            data?.reviewerId === userProfile?.teambleUserId && (
              <TextGradientPrimaryButton
                text={"Edit"}
                width={"82px"}
                onClick={() => {
                  history.push(
                    `${location.pathname}/prepare-feedback/${aiFeedback?.id}`
                  );
                }}
              />
            )}
        </div>
        <div className="ai-feedback-info-container">
          <div className="info-box">
            <div className="info-box-content">
              <div className="ai-feedback-information">
                <div className="header-text">AI Score</div>
                <div className="subtext-container">
                  <div className="ans">
                    {Math.floor(aiFeedback?.aiScore ?? 0)}
                  </div>
                  <div className="subtext">/100</div>
                </div>
              </div>
              <div className="ai-feedback-information">
                <div className="header-text">Specificity</div>
                <div className="subtext-container">
                  <div className="ans">
                    {Math.floor(
                      aiFeedback?.feedbackSpecificity
                        ?.feedbackSpecificityScore ?? 0
                    )}
                    %
                  </div>
                </div>
              </div>
              <div className="ai-feedback-information">
                <div className="header-text">Tone</div>
                <div className="subtext-container">
                  <div className="ans">
                    {aiFeedback?.feedbackTone?.toneCategory}
                  </div>
                </div>
              </div>
              <div className="ai-feedback-information">
                <div className="header-text">Focus</div>
                <div className="subtext-container">
                  <div className="ans">
                    {aiFeedback?.feedbackFocusCategory?.feedbackFocusCategory}
                  </div>
                </div>
              </div>
              <div className="ai-feedback-information">
                <div className="header-text">Actionability</div>
                <div className="subtext-container">
                  <div className="ans">
                    {Math.floor(
                      aiFeedback?.feedbackActionability
                        ?.feedbackActionableScore ?? 0
                    )}
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ai-feedback-text-container">
            {aiFeedback?.userFeedback}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiFeedbackContainer;
