import { Card } from '@themesberg/react-bootstrap';
import { Table, useTmblTable, useTmblTableData } from '@common/modules/table';
import {
  CustomSelect,
  EmptyState,
  PageTitle,
  PermissionCheck,
  Preloader,
  PrimaryLayoutBox
} from 'components';
import { getSurveysByFilters } from 'data';
import { ISimpleMenuOption, ISurvey, ISurveyResponse, UserPermissions } from 'interfaces';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { finalize } from 'rxjs';
import { SURVEY_LAUNCHED_BY_FILTER_OPTIONS, SURVEY_STATE_FILTERS } from 'teamble-constants';
import { getDateSortingFunc } from 'utils';

import { useSurveysListTableColumns } from './hooks/useSurveysListTable';

interface IManageSurveysProps {
}

interface ISurveysTableProps {
  surveysList: ISurveyResponse[];
  stateFilter: string;
}


const SurveysTable: React.FunctionComponent<ISurveysTableProps> = (
  { surveysList, stateFilter }
) => {

  const history = useHistory();

  const columns = useSurveysListTableColumns(stateFilter);
  const tableData = useTmblTableData<ISurvey>(surveysList);
  const tableInstance = useTmblTable<ISurvey>(
    columns,
    tableData,
    'launchedAt',
    true
  );


  return (
    <Table
      hover
      tableInstance={tableInstance}
      onRowClick={
        (row) => history.push(`${Routes.ManageSurveys.path}/${row.original.id}`)
      }
    />
  )

};


const ManageSurveys: React.FunctionComponent<IManageSurveysProps> = () => {

  const [selectedStateFilterOption, setSelectedStateFilterOption] =
    React.useState<ISimpleMenuOption>(SURVEY_STATE_FILTERS.options[0]);

  const [selectedLaunchedByOption, setSelectedLaunchedByOption] =
    React.useState<ISimpleMenuOption>(SURVEY_LAUNCHED_BY_FILTER_OPTIONS[0]);

  const [surveysList, setSurveysList] = React.useState<ISurveyResponse[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  React.useEffect(
    () => {

      setIsLoading(true);

      const subscription = getSurveysByFilters(selectedStateFilterOption.value, selectedLaunchedByOption.value)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            setSurveysList(response.data.data);
          }
        );

      return () => subscription.unsubscribe();

    },
    [selectedStateFilterOption, selectedLaunchedByOption]
  )


  return (
    <>
      <PageTitle title={Routes.ManageSurveys.title} />
      <PrimaryLayoutBox title={Routes.ManageSurveys.title}>
        <div className='flex-grow-1 position-relative overflow-hidden'>
          <div className='h-100 overflow-hidden d-flex flex-column'>

            <div className='d-flex flex-shrink-0 mb-2 mt-1 ms-1 position-relative' style={{ zIndex: 6 }}>
              <CustomSelect
                className='w-25'
                options={SURVEY_STATE_FILTERS.options}
                value={selectedStateFilterOption as ISimpleMenuOption}
                onChange={(option) => setSelectedStateFilterOption(option as ISimpleMenuOption)}
              />
              {
                <PermissionCheck permission={UserPermissions.ViewLaunchedByOthersSurveysFilter}>
                  <CustomSelect
                    className='w-25 ms-3'
                    options={SURVEY_LAUNCHED_BY_FILTER_OPTIONS}
                    value={selectedLaunchedByOption as ISimpleMenuOption}
                    onChange={(option) => setSelectedLaunchedByOption(option as ISimpleMenuOption)}
                  />
                </PermissionCheck>
              }

            </div>

            <div className='flex-grow-1 position-relative overflow-y-auto'>
              {
                isLoading ?
                  (<Preloader show={isLoading} />) :
                  (
                    <>
                      {
                        surveysList.length > 0 ?
                          (
                            <Card border="light" className="shadow-sm overflow-hidden h-100">
                              <Card.Body className="py-0 mt-3 position-relative h-100 overflow-y-auto">
                                <SurveysTable
                                  surveysList={surveysList}
                                  stateFilter={selectedStateFilterOption.value} />
                              </Card.Body>
                            </Card>
                          ) :
                          (
                            <EmptyState message={<>No Surveys found.</>} />
                          )
                      }
                    </>
                  )
              }
            </div>
          </div>
        </div>
      </PrimaryLayoutBox>
    </>
  );
};

export default ManageSurveys;
