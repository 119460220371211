import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { DataState, FormField } from 'components';
import { FormContext } from 'contexts';
import { getReviewOwnerTodoNominationData } from 'data';
import { useFormik } from 'formik';
import { useTeamMemberMenuOptions } from 'hooks';
import {
  FormFieldTypeConstants,
  IAddReviewersFormValues,
  IMemberOption,
  IReviewOwnerTodoNominationData,
  IRouteParams,
} from 'interfaces';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { finalize } from 'rxjs';
import { StringParam, useQueryParam } from 'use-query-params';
import { ADD_REVIEWERS_VALIDATION_SCHEMA } from 'validation-schemas';

interface IReviewOwnerTodoWidgetProps { }

const ReviewOwnerTodoWidget: React.FC<IReviewOwnerTodoWidgetProps> = () => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [nominationDetails, setNominationDetails] = React.useState<IReviewOwnerTodoNominationData>();

  const { reviewId, revieweeId } = useParams<IRouteParams>();
  const [organizationId] = useQueryParam('organizationId', StringParam);

  const {
    isLoading: isMembersLoading,
    memberOptions
  } = useTeamMemberMenuOptions(organizationId || '');

  const addReviewersForm = useFormik<IAddReviewersFormValues>({
    initialValues: {
      reviewers: []
    },
    validationSchema: ADD_REVIEWERS_VALIDATION_SCHEMA,
    onSubmit: () => { }
  });


  useEffect(
    () => {

      if (!reviewId || !revieweeId || !memberOptions.length) {
        setIsLoading(false);
        setNominationDetails(undefined);
        return;
      }

      getReviewOwnerTodoNominationData(reviewId, revieweeId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {

            const reviewers = response.data.data.reviewers
              .map(
                (reviewer) => {
                  return memberOptions.find(
                    (member) => member.id === reviewer.id
                  );
                }
              )
              .filter(
                (reviewer) => !!reviewer
              ) as IMemberOption[];

            addReviewersForm.setFieldValue(
              'reviewers',
              reviewers || []
            );
            setNominationDetails(response.data.data);
          }
        );

    },
    [memberOptions, reviewId, revieweeId]
  );


  return (
    <Card className='h-100 overflow-hidden'>

      <DataState
        isDataLoading={isLoading}
        isDataAvailable={!!nominationDetails}
        emptyStateMessage={<>No reviewee details available!</>}
      >
        {
          nominationDetails &&
          <>
            <Card.Header className='flex-shrink-0'>
              <Card.Title className='m-0 fw-bold fs-4'>
                Reviewers Needed ({nominationDetails.reviewee.name})
              </Card.Title>
            </Card.Header>

            <Form
              className='d-flex overflow-hidden flex-column flex-grow-1'
              onSubmit={addReviewersForm.handleSubmit}
            >
              <FormContext.Provider value={addReviewersForm}>

                <Card.Body className='flex-grow-1 overflow-y-auto'>

                  {
                    nominationDetails.reviewers.length
                      ?
                      <p>
                        @{nominationDetails.reviewee.name} nominated for
                        {nominationDetails.reviewers.map((reviewer) => '@' + reviewer.name).join(', ')}
                      </p>
                      :
                      null
                  }

                  <FormField
                    multiple
                    size='sm'
                    type={FormFieldTypeConstants.Select}
                    isLoading={isMembersLoading}
                    options={memberOptions}
                    optionType='user'
                    controlName='reviewers'
                    placeholder='Select reviewer(s)...'
                    label={`Please select reviewer(s) for reviewee @${nominationDetails.reviewee.name}`}
                    controlId='addReviewersFormReviewersField'
                  />

                </Card.Body>

                <Card.Footer className='d-flex justify-content-end flex-shrink-0 '>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={addReviewersForm.isSubmitting}
                  >
                    {addReviewersForm.isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </Card.Footer>

              </FormContext.Provider>
            </Form>
          </>
        }

      </DataState>
    </Card>
  );
};

export default ReviewOwnerTodoWidget;