import Axios, { AxiosObservable } from 'axios-observable';

import { IOrgUser, IResponse } from 'interfaces';

interface ICompanyUser {
  id: string,
  name: string,
  email: string
}

export const getCompanyUsers = (): AxiosObservable<IResponse<ICompanyUser[]>> => {

  return Axios.post<IResponse<ICompanyUser[]>>(
    "/get-users"
  );
};

export const getOrgUsers = (filterBy: string | null = null, isReport: boolean = false): AxiosObservable<IResponse<IOrgUser[]>> => {

  return Axios.post<IResponse<IOrgUser[]>>(
    "/get-org-users",
    { filterBy, isReport },
    {
      responseType: isReport ? 'arraybuffer' : 'json'
    }
  );
};

