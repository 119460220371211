import { IFeedbackLoopSenderReceiverInfosRequestData } from 'interfaces';

export const getDistinctAudiencePairs = (
  audiencePairs: IFeedbackLoopSenderReceiverInfosRequestData[]
): IFeedbackLoopSenderReceiverInfosRequestData[] => {

  if (!audiencePairs.length) {
    return [];
  }

  const distinctAudiencePairs: IFeedbackLoopSenderReceiverInfosRequestData[] = [];
  const distinctAudiencePairsObject: {
    [key: string]: IFeedbackLoopSenderReceiverInfosRequestData
  } = {};

  audiencePairs.forEach(
    (currentAudiencePair) => {

      const distinctAudiencePair =
        distinctAudiencePairsObject[`${currentAudiencePair.sender.userId}_${currentAudiencePair.receiver!.userId}`];

      if (!distinctAudiencePair) {
        distinctAudiencePairsObject[`${currentAudiencePair.sender.userId}_${currentAudiencePair.receiver!.userId}`] =
          currentAudiencePair;
        distinctAudiencePairs.push(currentAudiencePair);
      }

    }
  );

  return distinctAudiencePairs;
};
