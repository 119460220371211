import { FormErrors, IDepartmentFormValues } from 'interfaces';
import * as Yup from 'yup';


export const USER_DEPARTMENT_VALIDATION_SCHEMA: Yup.SchemaOf<IDepartmentFormValues> =
  Yup.object().shape(
    {
      department: Yup.string().required(FormErrors.Required).trim(FormErrors.Required),
    }
  );
