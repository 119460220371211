import { Axios } from 'axios-observable';

const setAuthTokenInterceptor = () => {

  Axios.interceptors.request.use(
    (request) => {

      if (process.env.REACT_APP_API_URL) {
        const isApiUrl = request.baseURL?.startsWith(process.env.REACT_APP_API_URL);
        const authToken = localStorage.getItem('token');
        if (isApiUrl && authToken) {
          request.headers.common.Authorization = `Bearer ${authToken}`;
        }
      }

      return request;
    }
  );
}

export const setRequestInterceptors = () => {
  setAuthTokenInterceptor();
};