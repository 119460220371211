import * as React from "react";
const OuterAiChatIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={2}
      d="M9.94 4.343c1.29-3.918 6.83-3.918 8.12 0l.71 2.162a4.273 4.273 0 0 0 2.725 2.725l2.162.71c3.918 1.29 3.918 6.83 0 8.12l-2.162.71a4.273 4.273 0 0 0-2.725 2.725l-.71 2.162c-1.29 3.918-6.83 3.918-8.12 0l-.71-2.162a4.273 4.273 0 0 0-2.725-2.725l-2.162-.71c-3.918-1.29-3.918-6.83 0-8.12l2.162-.71A4.273 4.273 0 0 0 9.23 6.505l.71-2.162Z"
    />
  </svg>
);
export default OuterAiChatIcon;
