import { Card, Form } from '@themesberg/react-bootstrap';
import { CollapsibleCard, FormField } from 'components';
import { FormContext } from 'contexts';
import { updateFeedbackLoopFeedbackType } from 'data';
import { useFormik } from 'formik';
import {
  FormFieldTypeConstants,
  IFeedbackTypeConfigFormValues,
  IUpdateFeedbackTypeConfigRequestData,
} from 'interfaces';
import React from 'react';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { FEEDBACK_TYPES } from 'teamble-constants';
import { FEEDBACK_TYPE_CONFIG_VALIDATION_SCHEMA } from 'validation-schemas';

import { useFeedbackLoopConfig } from '../contexts/feedbackLoopConfigContext';
import { getFeedbackTypeConfigFormValues } from '../utils/formUtils';
import { useCompanyConfig } from '@common/modules/companyConfig';

interface IFeedbackTypeConfigWidgetProps { }

const FeedbackTypeConfigWidget: React.FC<IFeedbackTypeConfigWidgetProps> = () => {

  // State hooks
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const companyConfig = useCompanyConfig();

  // Feedback Loop config hook
  const { feedbackLoop, onFeedbackLoopUpdate } = useFeedbackLoopConfig();

  // Form hook
  const feedbackTypeConfigForm = useFormik<IFeedbackTypeConfigFormValues>({
    initialValues: getFeedbackTypeConfigFormValues(feedbackLoop),
    validationSchema: FEEDBACK_TYPE_CONFIG_VALIDATION_SCHEMA,
    onSubmit: () => { }
  });


  React.useEffect(
    () => {

      if (
        !feedbackTypeConfigForm.isValid ||
        (
          (
            !feedbackLoop.feedbackType &&
            !feedbackTypeConfigForm.values.feedbackType?.value
          ) ||
          feedbackTypeConfigForm.values.feedbackType?.value ===
          feedbackLoop.feedbackType
        )
      ) {
        return;
      }

      const requestData: IUpdateFeedbackTypeConfigRequestData = {
        feedbackLibraryLoopId: feedbackLoop.id,
        feedbackType: feedbackTypeConfigForm.values.feedbackType?.value || ''
      };

      setIsLoading(true);
      const subscription = updateFeedbackLoopFeedbackType(requestData)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {
            onFeedbackLoopUpdate(response.data.data);
            toast.success(response.data.message);
          }
        );

      return () => subscription.unsubscribe();

    },
    [
      feedbackLoop.feedbackType, feedbackLoop.id, feedbackLoop.workspaceId,
      feedbackTypeConfigForm.isValid, feedbackTypeConfigForm.values.feedbackType,
      onFeedbackLoopUpdate
    ]
  );

  React.useEffect(() => {

    if (companyConfig?.defaultRelationshipName) {
      [...FEEDBACK_TYPES].forEach((option) => {
        if (option.label) {
          option.label = option.label.replace("Managers", companyConfig.defaultRelationshipName as string)
        }
      })
    }

  }, [])

  return (
    <CollapsibleCard
      title='Feedback Type'
      isLoading={isLoading}
    >
      <Card.Body>
        <Form>
          <FormContext.Provider value={feedbackTypeConfigForm}>

            <FormField
              label='Feedback Type *'
              controlId={`feedbackTypeConfigField`}
              type={FormFieldTypeConstants.Select}
              options={FEEDBACK_TYPES}
              controlName='feedbackType'
              isDisabled={isLoading}
            />

          </FormContext.Provider>
        </Form>
      </Card.Body>
    </CollapsibleCard>
  );
};

export default FeedbackTypeConfigWidget;