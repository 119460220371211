import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from '@themesberg/react-bootstrap';
import { ACTION_COLUMN_CONFIG } from '@common/modules/table';
import { Member } from 'components';
import {
  BodyCellProps,
  HeaderCellProps,
  INominateRevieweeInfo,
  IReviewAudienceActionColumnProps,
  ITableCheckState
} from 'interfaces';
import React from 'react';
import { CellProps, Column } from 'react-table';
import { TABLE_CHECKBOX_COLUMN_WIDTH } from 'teamble-constants';

export const useReviewNominationAudienceTableColumns = (
  isNominationInProgress: boolean,
) => {

  return React.useMemo<readonly Column<INominateRevieweeInfo>[]>(
    () => {

      const revieweeColumn: Column<INominateRevieweeInfo> = {
        id: "reviewee",
        Header: "Reviewee",
        accessor: (row) => row.label,
        Cell: (cellProps: CellProps<INominateRevieweeInfo>) => {
          return <Member member={cellProps.row.original} />;
        },
      };

      return !isNominationInProgress ?
        [
          {
            disableSortBy: true,
            accessor: "isChecked",
            cellWidth: TABLE_CHECKBOX_COLUMN_WIDTH,
            Header: (cellProps: HeaderCellProps<INominateRevieweeInfo, ITableCheckState>) => {
              return (
                <Form.Check
                  type="checkbox"
                  className="d-inline-block"
                  checked={cellProps.allRowsChecked}
                  onChange={() => cellProps.updateIsCheckedForAllRows(!cellProps.allRowsChecked)}
                />
              );
            },
            Cell: (cellProps: BodyCellProps<INominateRevieweeInfo, void, IReviewAudienceActionColumnProps>) => {
              return (
                <Form.Check
                  type="checkbox"
                  checked={cellProps.row.original.isChecked}
                  className="z-index-2"
                  onChange={() => cellProps.updateIsCheckedForRow(cellProps.row.index)}
                />
              );
            },
          },
          revieweeColumn,
          {
            ...ACTION_COLUMN_CONFIG,
            Cell: (cellProps: CellProps<INominateRevieweeInfo>) => {
              return (
                <FontAwesomeIcon
                  icon={faTrash}
                  className="cursor-pointer"
                  onClick={() => cellProps.onPairRemove(cellProps.row.index)}
                />
              );
            },
          },
        ]
        :
        [revieweeColumn];
    },
    [isNominationInProgress]
  );
};