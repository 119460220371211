import * as React from 'react';

const PlusIcon = ({ width = 16, height = 16, ...props }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 16 16'
        {...props}
    >
        <path
            stroke='currentColor'
            strokeLinejoin='round'
            d='M8 8V3m0 5H3m5 0v5m0-5h5'
        />
    </svg>
);

export default PlusIcon;
