import { IFeedback, IFeedbackMaps } from 'interfaces';
import React from 'react'
import { getFeedbackTagsText } from 'utils';

interface IFeedbackTagsProps {
  feedback: IFeedback;
  feedbackMaps: IFeedbackMaps;
}

const FeedbackTags: React.FC<IFeedbackTagsProps> = (
  { feedback, feedbackMaps }
) => {

  const feedbackTags = getFeedbackTagsText(feedback, feedbackMaps);

  return (
    <>
      {
        feedbackTags
          ?
          <p>
            <strong>Tags: </strong>
            <span>{feedbackTags}</span>
          </p>
          :
          null
      }
    </>
  );
};

export default FeedbackTags;