import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, InputGroup } from '@themesberg/react-bootstrap';
import { FormContext } from 'contexts';
import { FormikProps, getIn } from 'formik';
import { FileFormats, IMenuOption } from 'interfaces';
import { FormFieldTypeConstants } from 'interfaces';
import moment from 'moment';
import QuillInstance from 'quill';
import * as React from 'react';
import { useContext } from 'react';
import Datetime from 'react-datetime';
import ReactQuill, { Quill } from 'react-quill';
import { DATE_FORMAT, DATE_TIME_FORMAT } from "teamble-constants";
import CustomSelect from './CustomSelect';


interface IFormFieldProps {
  label?: string;
  size?: 'sm' | 'lg';
  minValue?: number;
  multiple?: boolean;
  htmlLabel?: string;
  controlId?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  controlName: string;
  placeholder?: string;
  description?: React.ReactNode;
  allowSelectAll?: boolean;
  isClearable?: boolean;
  type: FormFieldTypeConstants;
  options?: Partial<IMenuOption>[];
  acceptedFileFormats?: FileFormats[];
  textValueChangeDebounceTime?: number;
  fieldDescription?: React.ReactNode;
  optionType?: 'user' | 'checkbox' | 'description' | 'default' | 'slackChannel';
  onChange?: (value: any) => void;
  isRadioMatrixView?: boolean;
  timeFormat?: boolean;
  dateTimeformat?: string;
}


const Strike = Quill.import('formats/strike');
Strike.tagName = 'del';   // Quill uses <s> by default
Quill.register(Strike, true);


const FormField: React.ForwardRefExoticComponent<IFormFieldProps & React.RefAttributes<HTMLInputElement>> =
  React.forwardRef((
    {
      label, htmlLabel, controlId, type, placeholder, controlName, fieldDescription,
      description, options, multiple, size = 'sm', acceptedFileFormats, textValueChangeDebounceTime = 0,
      minValue, isLoading, isDisabled = false, allowSelectAll = false, optionType = 'default',
      onChange, isRadioMatrixView, isClearable = false, timeFormat = true, dateTimeformat = DATE_TIME_FORMAT
    }, ref
  ) => {

    const form = useContext<FormikProps<any>>(FormContext as React.Context<FormikProps<any>>);
    const fieldValue = form.getFieldProps(controlName).value;

    const [debounceTimer, setDebounceTimer] = React.useState<ReturnType<typeof setTimeout> | null>(null);
    const [textTargetValue, setTextTargetValue] = React.useState<string>(fieldValue);
    const [quillInstance, setQuillInstance] = React.useState<QuillInstance | null>(null);

    React.useEffect(
      () => {
        if ([FormFieldTypeConstants.Text, FormFieldTypeConstants.Textarea].includes(type)) {
          setTextTargetValue(fieldValue);
        }
      },
      [fieldValue, type]
    );


    const handleTextChanceWithDebounceTimer: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
      (changeEvent) => {

        setTextTargetValue(changeEvent.target.value);
        changeEvent.persist();

        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }

        setDebounceTimer(
          setTimeout(() => {
            form.handleChange(changeEvent);
          }, textValueChangeDebounceTime)
        );

      };


    let formLabelControlEl: React.ReactElement | null = null;

    switch (type) {

      case FormFieldTypeConstants.RichText: {

        const fieldProps = form.getFieldProps(controlName);

        formLabelControlEl = (
          <ReactQuill
            ref={(el) => { return el ? setQuillInstance(el.getEditor()) : null }}
            theme='snow'
            value={fieldProps.value}
            placeholder={placeholder}
            modules={{
              toolbar: [['bold', 'italic', 'strike'], ['link'], ['clean']]
            }}
            className={`tml-quill
            ${(getIn(form.touched, controlName) && getIn(form.errors, controlName)) ? 'is-invalid' : ''}`}
            onChange={
              (value) => {
                const hasContent = !!value.replace(/<(.|\n)*?>/g, '').trim().length;
                form.setFieldValue(controlName, hasContent ? value : '')
              }
            }
            onBlur={() => form.setFieldTouched(controlName, true)} />
        );
        break;
      }

      case FormFieldTypeConstants.Text: {

        formLabelControlEl = (
          <Form.Control
            type={type}
            disabled={isDisabled}
            placeholder={placeholder}
            isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
            {...form.getFieldProps(controlName)}
            value={textTargetValue}
            onChange={handleTextChanceWithDebounceTimer} />
        );
        break;
      }

      case FormFieldTypeConstants.Number: {

        formLabelControlEl = (
          <Form.Control
            type={type}
            step="0.01"
            min={minValue}
            placeholder={placeholder}
            isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
            {...form.getFieldProps(controlName)}
            // Blur the input element on the mousewheel to avoid changing the number on mousewheel
            onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement).blur()} />
        );
        break;
      }

      case FormFieldTypeConstants.Checkbox: {

        formLabelControlEl = (
          <Form.Check
            type={type}
            label={label}
            disabled={isDisabled}
            isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
            {...form.getFieldProps({ name: controlName, type })} />
        );
        break;
      }

      case FormFieldTypeConstants.CheckboxGroup: {

        formLabelControlEl = (
          <>
            {
              options && options.length !== 0 &&
              options.map(
                (option) => {
                  return (
                    <Form.Check
                      key={option.value}
                      type={FormFieldTypeConstants.Checkbox}
                      label={option.label}
                      disabled={isDisabled}
                      isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
                      {...form.getFieldProps({
                        name: controlName,
                        type: FormFieldTypeConstants.Checkbox,
                        value: option.value
                      })} />
                  );
                }
              )
            }
          </>
        );
        break;
      }

      case FormFieldTypeConstants.Select: {
        formLabelControlEl = (
          <CustomSelect
            isSearchable
            isMulti={multiple}
            isLoading={isLoading}
            isDisabled={isDisabled}
            optionType={optionType}
            placeholder={placeholder}
            allowSelectAll={allowSelectAll}
            isClearable={isClearable}
            options={options as IMenuOption[]}
            value={form.getFieldProps(controlName).value}
            isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
            className={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName)) ? 'is-invalid' : ''}
            onChange={(option) => {
              form.setFieldValue(controlName, option);
              if (onChange) {
                onChange(option as any);
              }
            }}
            onBlur={() => form.setFieldTouched(controlName, true)}
          />
        );
        break;
      }

      case FormFieldTypeConstants.Textarea: {

        formLabelControlEl = (
          <Form.Control
            as='textarea'
            rows={4}
            disabled={isDisabled}
            placeholder={placeholder}
            isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
            {...form.getFieldProps(controlName)}
            value={textTargetValue}
            onChange={handleTextChanceWithDebounceTimer} />
        );
        break;
      }

      case FormFieldTypeConstants.Radio: {
        if (isRadioMatrixView) {
          formLabelControlEl = (
            <>
              {
                options && options.length !== 0 &&
                options.map(
                  (option, index) => {
                    return (
                      <Form.Check
                        id={`${controlId}_${index}`}
                        key={index}
                        type={type}
                        style={{ minWidth: 150, width: 150 }}
                        className="text-center"
                        {...form.getFieldProps(controlName)}
                        value={option?.label}
                        checked={option.label === getIn(form.values, controlName)}
                        isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))} />
                    );
                  }
                )
              }
            </>
          );
        } else {
          formLabelControlEl = (
            <div>
              {
                options && options.length !== 0 &&
                options.map(
                  (option, index) => {
                    return (
                      <Form.Check
                        id={`${controlId}_${index}`}
                        key={index}
                        type={type}
                        label={option.label}
                        {...form.getFieldProps(controlName)}
                        value={option.label}
                        checked={option.label === getIn(form.values, controlName)}
                        isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))} />
                    );
                  }
                )
              }
            </div>
          );
        }

        break;
      }

      case FormFieldTypeConstants.Datepicker: {

        const fieldProps = form.getFieldProps(controlName);

        const handleOnChange = (momentValue: string | moment.Moment): void => {
          const value = moment(momentValue).toDate();
          form.setFieldValue(controlName, value);
        };

        formLabelControlEl = (
          <Datetime
            timeFormat={timeFormat}
            closeOnSelect
            value={fieldProps.value || null}
            onChange={handleOnChange}
            className={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName)) ? 'is-invalid' : ''}
            renderInput={(_, openCalendar, closeCalendar) => (
              <InputGroup
                onBlur={() => form.setFieldTouched(controlName, true)}
              >
                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                <Form.Control
                  readOnly
                  type="text"
                  disabled={isDisabled}
                  isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
                  value={fieldProps.value ? moment(fieldProps.value).format(dateTimeformat) : ""}
                  placeholder={placeholder || DATE_TIME_FORMAT}
                  onFocus={openCalendar as any}
                  onChange={() => { closeCalendar() }}
                />
                {isClearable && <Button onClick={() => form.setFieldValue(controlName, null)}>Clear</Button>}
              </InputGroup>
            )}
          />
        );
        break;
      }

      case FormFieldTypeConstants.File: {

        formLabelControlEl = (
          <Form.Control
            ref={ref}
            type={type}
            accept={acceptedFileFormats?.join(', ')}
            isInvalid={!!(getIn(form.touched, controlName) && getIn(form.errors, controlName))}
            name={controlName}
            onBlur={() => form.setFieldTouched(controlName, true)}
            onChange={
              (event) => {
                const target = event.currentTarget as HTMLInputElement;
                if (target.files) {
                  form.setFieldValue(controlName, target.files[0]);
                } else {
                  form.setFieldValue(controlName, null);
                }
              }
            } />
        );
        break;
      }

      default: {
        formLabelControlEl = null;
        break;
      }

    }

    if (isRadioMatrixView) {

      return (
        <Form.Group
          className={`mb-3 d-flex`}
          controlId={controlId}
        >
          {
            <div style={{ minWidth: 300, width: 300 }}>
              {
                label && type !== FormFieldTypeConstants.Checkbox &&
                <Form.Label className={`${size === 'lg' ? 'fw-bolder' : ''} ws-pre-line mr-2`}> {label} </Form.Label>
              }
              {
                description &&
                <div>
                  <Form.Text muted>
                    {description}
                  </Form.Text>
                </div>
              }
            </div>
          }
          {
            htmlLabel && type !== FormFieldTypeConstants.Checkbox &&
            <div dangerouslySetInnerHTML={{ __html: htmlLabel }}></div>
          }
          {formLabelControlEl}
          {
            fieldDescription &&
            <Form.Text muted>
              {fieldDescription}
            </Form.Text>
          }

          <Form.Control.Feedback type="invalid">
            {getIn(form.touched, controlName) ? getIn(form.errors, controlName) : null}
          </Form.Control.Feedback>
        </Form.Group>
      )
    }

    return (
      <Form.Group
        className='mb-3'
        controlId={controlId}
      >
        {
          label && type !== FormFieldTypeConstants.Checkbox &&
          <Form.Label className={`${size === 'lg' ? 'fw-bolder' : ''} ws-pre-line`}> {label} </Form.Label>
        }
        {
          htmlLabel && type !== FormFieldTypeConstants.Checkbox &&
          <div dangerouslySetInnerHTML={{ __html: htmlLabel }}></div>
        }
        {
          description &&
          <div>
            <Form.Text muted>
              {description}
            </Form.Text>
          </div>
        }
        {formLabelControlEl}
        {
          fieldDescription &&
          <Form.Text muted>
            {fieldDescription}
          </Form.Text>
        }

        <Form.Control.Feedback type="invalid">
          {getIn(form.touched, controlName) ? getIn(form.errors, controlName) : null}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  );

export default FormField;
