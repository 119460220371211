import { IMetaOptions, IChannelsAudience, IUsersAudience, SignInWith, IGroupsAudience } from "interfaces";

export const getSurveyAudienceDescription =
  (surveyAudience?: (IUsersAudience | IChannelsAudience)[]): string => {

    if (!(surveyAudience?.length)) {
      return 'Not Set';
    }

    const entireWorkspaceAsAudience = surveyAudience.find(
      (audience) => audience.type === 'workspace'
    );
    if (entireWorkspaceAsAudience) {
      return 'Entire workspace';
    }

    let audience = '';

    const channelsAsAudience = surveyAudience.find(
      (audience) => audience.type === 'channels'
    ) as IChannelsAudience;

    if (channelsAsAudience) {

      const channelNames = channelsAsAudience.channels.map(
        (channel) => channel.channelInfo.slack.name
      ).join(', ');

      audience = `People from channels ${channelNames}`;
    }


    const groupsAsAudience = surveyAudience.find(
      (audience) => audience.type === 'groups'
    ) as unknown as IGroupsAudience;

    if (groupsAsAudience) {

      const groupNames = groupsAsAudience.groups.map(
        (group) => group.name
      ).join(', ');

      if (audience) {
        audience += `, People from groups ${groupNames}`;
      } else {
        audience = `People from groups ${groupNames}`;
      }
    }

    const usersAsAudience = surveyAudience.find(
      (audience) => audience.type === 'users'
    ) as IUsersAudience;

    if (usersAsAudience) {
      if (!audience) {
        return `${usersAsAudience.users.length} People`;
      } else {
        return `${audience} and ${usersAsAudience.users.length} other People`;
      }
    } else {
      return audience;
    }

  };


export const getSurveyQuestionTypesMetaData = (app: SignInWith): IMetaOptions | null => {

  const commonOptions = [
    {
      value: 'static-text',
      label: 'Statement'
    },
    {
      value: 'text-input',
      label: 'Free Text Question'
    },
    {
      value: 'choice',
      label: 'Multi Choice Question'
    },
  ];

  const commonConstants = {
    STATIC_TEXT: 'static-text',
    TEXT_INPUT: 'text-input',
    CHOICE: 'choice',
  };

  if (app === SignInWith.MsTeams) {
    return {
      options: commonOptions,
      constants: commonConstants
    };
  }

  if (app === SignInWith.Slack) {
    return {
      options: [
        ...commonOptions,
        {
          value: 'group',
          label: 'Group'
        }
      ],
      constants: {
        ...commonConstants,
        GROUP: 'group'
      }
    };
  }

  return null;
};