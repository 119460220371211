import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { FormField, PermissionCheck } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { FormikErrors, FormikProps } from 'formik';
import {
  FormFieldTypeConstants,
  IMetadata,
  IRouteParams,
  ISurveyBuilderBlock,
  ISurveyBuilderFormValues,
  ISurveyGroupBlockValue,
  ISurveyQuestionTypeConstants,
  UserPermissions,
} from 'interfaces';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import {
  ISurveyPreviewQuestionIndexState,
  SurveyPreviewQuestionIndexContext,
} from '../contexts/SurveyPreviewQuestionIndex';
import SurveyQuestionsAccordion from './SurveyQuestionsAccordion';
import { getQuestionAccordionIndex } from '../utils/utils';
import { SurveyInvalidGroupQuestionIndexContext } from '../contexts/SurveyInvalidGroupQuestionIndexContext';

interface ISurveyFormProps {
  activeQuestionIndex: string;
  surveyForm: FormikProps<ISurveyBuilderFormValues>;
  surveyBlockTempIdIndex: number;
  newQuestionAddIndex: number;
  setSurveyBlockTempIdIndex: React.Dispatch<React.SetStateAction<number>>;
  onSaveAndNextClick: () => void;
  onCreateSurveyTemplateClick: () => void;
  onActiveQuestionIndexChange: (index: string) => void;
}

const SurveyForm: React.FunctionComponent<ISurveyFormProps> = (
  {
    surveyForm, activeQuestionIndex, surveyBlockTempIdIndex, newQuestionAddIndex,
    onSaveAndNextClick, setSurveyBlockTempIdIndex,
    onActiveQuestionIndexChange, onCreateSurveyTemplateClick
  }
) => {

  const [invalidGroupQuestionIndex, setInvalidGroupQuestionIndex] = React.useState<string>('');

  const cardBodyRef = React.useRef<HTMLDivElement>(null);

  const { surveyId, surveyTemplateId } = useParams<IRouteParams>();

  const previewQuestionIndexContext =
    React.useContext(SurveyPreviewQuestionIndexContext) as ISurveyPreviewQuestionIndexState;
  const metadata = React.useContext(MetaDataContext) as IMetadata;


  React.useEffect(
    () => {

      if (newQuestionAddIndex) {
        cardBodyRef.current?.scrollTo({
          behavior: 'smooth',
          top: cardBodyRef.current?.scrollHeight,
        });
      }

    },
    [newQuestionAddIndex]
  );


  const handleAccordionToggle = (index: string) => {

    if (index === activeQuestionIndex) {

      cardBodyRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      onActiveQuestionIndexChange('');
      previewQuestionIndexContext.setIndex('');
    } else {
      onActiveQuestionIndexChange(index);
      previewQuestionIndexContext.setIndex(index);
    }

  };


  const handleQuestionsUpdate = (
    questions: ISurveyBuilderBlock[],
    index: string,
    ref: React.RefObject<HTMLDivElement> | null
  ) => {

    surveyForm.setFieldValue('blocks', questions, true);

    if (index !== '') {
      onActiveQuestionIndexChange(index);
      previewQuestionIndexContext.setIndex(index);
    }

    setTimeout(() => {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 500);

  };


  const scrollToFormErrorField = (): void => {

    if (surveyForm.errors.title) {
      cardBodyRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }

    if (!(surveyForm.values.blocks.length && surveyForm.errors.blocks?.length)) {
      return;
    }

    const errorIndex = ((surveyForm.errors.blocks) as FormikErrors<ISurveyBuilderBlock>[])
      .findIndex((error) => error && Object.keys(error).length);

    if (errorIndex === -1) {
      return;
    }

    const questionAtErrorIndex = surveyForm.values.blocks[errorIndex];
    const questionTypeConstants: ISurveyQuestionTypeConstants =
      metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants;

    if (questionAtErrorIndex.type === questionTypeConstants.GROUP) {

      const groupQuestionError = ((surveyForm.errors.blocks[errorIndex]) as FormikErrors<ISurveyBuilderBlock>)
        .groupValue as FormikErrors<ISurveyGroupBlockValue> | undefined;
      const groupErrorIndex = (groupQuestionError?.questions as FormikErrors<ISurveyBuilderBlock>[] | undefined)
        ?.findIndex((error) => error && Object.keys(error).length);

      const accordionIndex = getQuestionAccordionIndex(
        errorIndex,
        questionAtErrorIndex.type,
        metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
        {
          isGroup: false,
          groupIndex: errorIndex
        }
      )
      onActiveQuestionIndexChange(accordionIndex);

      if (groupErrorIndex !== -1 && groupErrorIndex !== undefined) {
        const groupAccordionIndex = getQuestionAccordionIndex(
          groupErrorIndex,
          questionAtErrorIndex.type,
          metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
          {
            isGroup: true,
            groupIndex: errorIndex
          }
        )
        setInvalidGroupQuestionIndex(groupAccordionIndex);
        previewQuestionIndexContext.setIndex(groupAccordionIndex);
        return;
      }

      previewQuestionIndexContext.setIndex(accordionIndex);
      return;
    }

    const accordionIndex = getQuestionAccordionIndex(
      errorIndex,
      questionAtErrorIndex.type,
      metadata.surveyQuestionTypes.constants as ISurveyQuestionTypeConstants,
      {
        isGroup: false,
        groupIndex: errorIndex
      }
    )
    onActiveQuestionIndexChange(accordionIndex);
    previewQuestionIndexContext.setIndex(accordionIndex);
    return;
  };


  const handleFormSubmissionAction = (
    action: 'template' | 'submit' | 'next',
    event?: React.FormEvent<HTMLFormElement>
  ): void => {

    event?.preventDefault();

    surveyForm.validateForm().then(
      (errors) => {

        if (!Object.keys(errors).length) {
          switch (action) {

            case 'next': {
              onSaveAndNextClick();
              break;
            }

            case 'template': {
              onCreateSurveyTemplateClick();
              break;
            }

            case 'submit': {
              surveyForm.handleSubmit();
              break;
            }
          }
          return;
        }

        surveyForm.handleSubmit();
        scrollToFormErrorField();
      }
    );

  };


  return (
    <Card className='flex-grow-1 overflow-hidden'>
      <Form className='h-100 d-flex flex-column' onSubmit={(e) => handleFormSubmissionAction('submit', e)}>

        <Card.Body ref={cardBodyRef} className='overflow-y-auto flex-grow-1 overflow-x-hidden'>
          <FormContext.Provider value={surveyForm}>
            <FormField
              label='Name *'
              controlId='survey-name'
              type={FormFieldTypeConstants.Text}
              placeholder='Enter Name'
              controlName='title' />

            {
              surveyForm.values.blocks?.length ?
                (
                  <SurveyInvalidGroupQuestionIndexContext.Provider value={{
                    index: invalidGroupQuestionIndex,
                    setIndex: setInvalidGroupQuestionIndex
                  }}>
                    <SurveyQuestionsAccordion
                      questions={surveyForm.values.blocks}
                      setSurveyBlockTempIdIndex={setSurveyBlockTempIdIndex}
                      surveyBlockTempIdIndex={surveyBlockTempIdIndex}
                      activeQuestionIndex={activeQuestionIndex}
                      onAccordionToggle={handleAccordionToggle}
                      onQuestionsUpdate={handleQuestionsUpdate} />
                  </SurveyInvalidGroupQuestionIndexContext.Provider>
                ) :
                (null)
            }
          </FormContext.Provider>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end flex-shrink-0'>
          <PermissionCheck permission={UserPermissions.CreateUpdateSurveyTemplate}>
            {
              !!(surveyId || surveyTemplateId) &&
              <Button
                className='ms-3'
                variant="outline-primary"
                type="button"
                disabled={surveyForm.isSubmitting}
                onClick={() => handleFormSubmissionAction('template')}
              >
                {`${surveyId ? 'Create' : 'Update'} Survey Template`}
              </Button>
            }
          </PermissionCheck>
          <Button
            className='ms-3'
            variant="primary"
            type="submit"
            disabled={surveyForm.isSubmitting}
          >
            {surveyForm.isSubmitting ? 'Saving...' : 'Save'}
          </Button>
          {
            !!surveyId &&
            <Button
              type="button"
              className='ms-3'
              variant="success"
              disabled={surveyForm.isSubmitting}
              onClick={() => handleFormSubmissionAction('next')}
            >
              {surveyForm.isSubmitting ? 'Redirecting...' : 'Next'}
            </Button>
          }
        </Card.Footer>

      </Form>
    </Card>
  );
};

export default SurveyForm;
