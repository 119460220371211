import { List } from '@common/components';
import { Col, Row } from '@themesberg/react-bootstrap';
import { FeedbackInsightOverviewDataCard } from 'components';
import {
  FeedbackInsightTypes,
  IFeedbackInsightsNonPersonalOverview,
  IFeedbackInsightsOverview,
  IFeedbackInsightsPersonalOverview,
  ISimpleMenuOption,
} from 'interfaces';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IFeedbackInsightOverviewDataCardsProps {
  insightType: FeedbackInsightTypes;
  feedbackOverviewInsights: IFeedbackInsightsOverview;
}

interface IFeedbackInsightsOverviewItem extends ISimpleMenuOption {
  id: string;
}


const getFeedbackInsightsList = (
  insightType: FeedbackInsightTypes,
  feedbackOverviewInsights: IFeedbackInsightsOverview
): IFeedbackInsightsOverviewItem[] => {

  if (!feedbackOverviewInsights) {
    return [];
  }

  if (insightType === FeedbackInsightTypes.PERSONAL) {

    const fbkOverviewInsights = feedbackOverviewInsights as IFeedbackInsightsPersonalOverview;

    return [
      {
        id: uuidv4(),
        label: '💡 Feedback Sent',
        value: fbkOverviewInsights.totalFeedbackSent?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '🙏🏻 Feedback Received',
        value: fbkOverviewInsights.totalFeedbackReceived?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '❓ Feedback Requested',
        value: fbkOverviewInsights.totalFeedbackRequested?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '🙌 Public Praise Sent',
        value: fbkOverviewInsights.totalPraiseSent?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '🙏🏻 Public Praise Received',
        value: fbkOverviewInsights.totalPraiseReceived?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '🚀 Surveys Completed',
        value: fbkOverviewInsights.totalSurveysSubmitted?.toString() || '0'
      },
    ];
  } else {

    const fbkOverviewInsights = feedbackOverviewInsights as IFeedbackInsightsNonPersonalOverview;

    return [
      {
        id: uuidv4(),
        label: '💡 Feedback Sent',
        value: fbkOverviewInsights.totalFeedbackCount?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '❓ Feedback Requested',
        value: fbkOverviewInsights.totalFeedbackRequestCount?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '🙌 Public Praise Shared',
        value: fbkOverviewInsights.totalPublicPraise?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '🚀 Surveys Completed',
        value: fbkOverviewInsights.totalSurveySubmittedCount?.toString() || '0'
      },
      {
        id: uuidv4(),
        label: '👥 User Participated',
        value: fbkOverviewInsights.userParticipatedCount?.toString() || '0'
      },
    ];
  }

};


const FeedbackInsightOverviewDataCards: React.FC<IFeedbackInsightOverviewDataCardsProps> = (
  { insightType, feedbackOverviewInsights }
) => {

  const fbkOverviewInsights = getFeedbackInsightsList(
    insightType,
    feedbackOverviewInsights
  );

  return (
    <Row className='align-items-stretch'>
      <List
        items={fbkOverviewInsights}
        renderItem={
          (item) => (
            <Col
              key={item.id}
              className='my-2'
            >
              <FeedbackInsightOverviewDataCard
                label={item.label}
                value={item.value}
              />
            </Col>
          )
        }
      />
    </Row>
  );

};

export default FeedbackInsightOverviewDataCards;