import { IMsTeamsChannelUser, ISlackWorkspaceUser } from './channelAndWorkspace.interface';
import { IMemberOption, ISimpleMenuOption } from './menuOptions.interface';
import { IActiveReview } from './review.interface';

export interface IAwaitingNominationFormValues {
  nominees: ISimpleMenuOption[];
}

export interface INomination {
  id: string;
  reviewee: {
    id: string;
    name: string;
  };
  reviewer: {
    id: string;
    name: string;
  };
  revieweeSlackId: string;
  approveStatus: string;
  reason?: string;
}

export interface IReviewNominationActionData {
  openListData: INomination[];
}

export interface IReviewOwnerTodoNominationData {
  reviewee: IMemberOption;
  review: IActiveReview;
  reviewers: ISlackWorkspaceUser[] | IMsTeamsChannelUser[];
}

export interface INominationActionFormValues {
  nominations: {
    approveStatus: string;
    reason?: string;
  }[]
}

export interface IAddReviewersFormValues {
  reviewers: ISimpleMenuOption[];
}

export interface IManagerAddReviewersReqData {
  campaignId: string;
  reviewers: string[];
  revieweeSlackId: string;
}

export interface IManagerAddReviewersResponse {
  existingPairs: INomination[];
  nonExistingPairs: INomination[];
}

export enum NominationActions {
  Approve = 'Approve',
  Deny = 'Deny'
}

export interface ISubmitRevieweeTodosRequestData {
  reviewers: string[];
  campaignId: string;
}

export interface INominationActionRequestData {
  campaignId: string;
  responses: {
    id: string;
    value: 'reject' | 'accept';
    reason?: string;
  }[];
}