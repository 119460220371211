import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { ConfirmationModal, DataState } from 'components';
import { getReviewReviewerApproveNominationData, submitReviewerTodos } from 'data';
import { useFormik } from 'formik';
import {
  INomination,
  INominationActionFormValues,
  INominationActionRequestData,
  IRouteParams,
  NominationActions
} from 'interfaces';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finalize } from 'rxjs';
import { NOMINATION_ACTION_VALIDATION_SCHEMA } from 'validation-schemas';

import ReviewTodoNominationActionForm from './ReviewTodoNominationActionForm';

interface IReviewTodoReviewerApproveNominationWidgetProps {
  onNominationSubmit: (reviewId: string) => void
}

const ReviewTodoReviewerApproveNominationWidget: React.FC<IReviewTodoReviewerApproveNominationWidgetProps> = (
  { onNominationSubmit }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [nominationDetails, setNominationDetails] = React.useState<INomination[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);

  const { reviewId } = useParams<IRouteParams>();

  const nominationActionForm = useFormik<INominationActionFormValues>({
    initialValues: {
      nominations: []
    },
    validationSchema: NOMINATION_ACTION_VALIDATION_SCHEMA,
    onSubmit: (values, formikHelpers) => {

      if (!reviewId) {
        return;
      }

      const requestData: INominationActionRequestData = {
        campaignId: reviewId,
        responses: values.nominations.map(
          (nomination, index) => ({
            id: nominationDetails[index].id,
            value: nomination.approveStatus === NominationActions.Approve ? 'accept' : 'reject',
            reason: nomination.reason
          })
        )
      };

      submitReviewerTodos(requestData)
        .pipe(
          finalize(() => formikHelpers.setSubmitting(false))
        )
        .subscribe(
          (response) => {

            toast.success(response.data.message);
            setShowConfirmationModal(false);
            onNominationSubmit(nominationDetails[0].id);
          }
        );

    }
  });


  useEffect(
    () => {

      if (!reviewId) {
        setIsLoading(false);
        setNominationDetails([]);
        return;
      }

      setIsLoading(true);
      setNominationDetails([]);

      const subscription = getReviewReviewerApproveNominationData(reviewId)
        .pipe(
          finalize(() => setIsLoading(false))
        )
        .subscribe(
          (response) => {

            response.data.data.openListData = response.data.data.openListData.map(
              (nomination) => ({
                ...nomination,
                approveStatus: ''
              })
            );

            nominationActionForm.setFieldValue(
              'nominations',
              response.data.data.openListData,
              true
            );
            nominationActionForm.setFieldTouched(
              'nominations',
              false,
              true
            );
            setNominationDetails(response.data.data.openListData);

          }
        );

      return () => subscription.unsubscribe();
    },
    [reviewId]
  );


  return (
    <>
      <Card className='h-100 overflow-hidden'>

        <DataState
          isDataLoading={isLoading}
          isDataAvailable={!!nominationDetails.length}
          emptyStateMessage={<>No nominations available!</>}
        >

          {
            nominationDetails.length
              ?
              <>
                <Card.Header className='flex-shrink-0'>
                  <Card.Title className='m-0 fw-bold fs-4'>
                    Reviewer Approval
                  </Card.Title>
                </Card.Header>

                <Form
                  className='d-flex overflow-hidden flex-column flex-grow-1'
                  onSubmit={(e) => {
                    if (nominationActionForm.isValid) {
                      e.preventDefault();
                      setShowConfirmationModal(true);
                    } else {
                      nominationActionForm.handleSubmit(e);
                    }
                  }}
                >

                  <Card.Body className='flex-grow-1 overflow-y-auto'>
                    <p>
                      You've been nominated to be a peer reviewer!
                      <br />
                      You need to accept or decline this request.
                    </p>
                    {
                      nominationActionForm.values.nominations.length
                        ?
                        <ReviewTodoNominationActionForm
                          nominations={nominationDetails}
                          nominationActionForm={nominationActionForm} />
                        :
                        <p>No nominations to approve/deny.</p>
                    }
                  </Card.Body>

                  <Card.Footer className='d-flex justify-content-end flex-shrink-0 '>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={nominationActionForm.isSubmitting}
                    >
                      {nominationActionForm.isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                  </Card.Footer>

                </Form>
              </>
              :
              null
          }

        </DataState>

      </Card>

      <ConfirmationModal
        title='Submit nominations'
        submittingText='Submitting...'
        positiveText='Submit'
        isSubmitting={nominationActionForm.isSubmitting}
        show={showConfirmationModal}
        closeOnUserResponse={false}
        onPositiveResponse={() => {
          nominationActionForm.submitForm();
        }}
        onNegativeResponse={() => setShowConfirmationModal(false)}
        onCloseClick={() => setShowConfirmationModal(false)}
      >
        <p>
          Are you sure you want to submit the nominations?
        </p>
        <i>Once you submit you can't edit your nominations.</i>
      </ConfirmationModal>
    </>
  );
};

export default ReviewTodoReviewerApproveNominationWidget;