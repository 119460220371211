import { getManagerTo, TableExcludedColumn } from '@common/modules/table';
import { Member } from 'components';
import {
  IActionTypes,
  IRelationshipTeamMember,
  IMsTeamsChannelUser,
  ISlackWorkspaceUser,
  IOrgUser,
} from 'interfaces';
import * as React from 'react';
import { CellProps, Column, Row } from 'react-table';
import { SELECT_ALL_OPTION, TABLE_ACTION_COLUMN_WIDTH, TABLE_PROGRESS_COLUMN_WIDTH } from 'teamble-constants';

export const MEMBER_NAME_COLUMN: Column<IRelationshipTeamMember> = {
  id: 'name',
  Header: 'Name',
  accessor: 'name',
  Cell: (cellProps: CellProps<IRelationshipTeamMember>) => {
    const member = cellProps.row.original;

    return (
      <Member member={{
        value: member.id,
        imageOriginal: member.imageOriginal,
        label: member.name,
        description: member.title?.title,
        isExcluded: member.isExcluded
      }} />
    );

  }
};


export const MEMBER_ROLE_COLUMN: Column<IRelationshipTeamMember> = {
  Header: 'Role',
  cellClassName: 'table-cell-text',
  accessor: (row) => row.role?.name || 'Not Set',
  Cell: (cellProps: CellProps<IRelationshipTeamMember>) => {
    const member = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={!!member.isExcluded}>
        {member.role?.name || "Not Set"}
      </TableExcludedColumn>
    );
  },
};


export const MEMBER_DEPARTMENT_COLUMN: Column<IRelationshipTeamMember> = {
  Header: 'Department',
  cellClassName: 'table-cell-text',
  accessor: (row) => row.department?.name || 'Not Set',
  Cell: (cellProps: CellProps<IRelationshipTeamMember>) => {
    const member = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={!!member.isExcluded}>
        {member.department?.name || "Not Set"}
      </TableExcludedColumn>
    );
  },
};


export const SLACK_USER_NAME_COLUMN: Column<ISlackWorkspaceUser> = {
  id: 'name',
  Header: 'Name',
  accessor: 'realName',
  Cell: (cellProps: CellProps<ISlackWorkspaceUser>) => {
    const user = cellProps.row.original;

    return (
      <Member member={{
        value: user.id,
        imageOriginal: user.imageOriginal,
        label: user.realName,
        description: user.title,
        isExcluded: user.isExcluded || user.isDeleted
      }} />
    );

  }
};


export const MS_TEAMS_USER_NAME_COLUMN: Column<IMsTeamsChannelUser> = {
  id: 'name',
  Header: 'Name',
  accessor: 'name',
  Cell: (cellProps: CellProps<IMsTeamsChannelUser>) => {
    const user = cellProps.row.original;

    return (
      <Member member={{
        value: user.id,
        label: user.name,
      }} />
    );

  }
};


export const SLACK_USER_ROLE_COLUMN: Column<ISlackWorkspaceUser> = {
  Header: "Role",
  cellClassName: 'table-cell-text',
  accessor: (row) => row.roleInfo?.role || "Not Set",
  Cell: (cellProps: CellProps<ISlackWorkspaceUser>) => {
    const user = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={!!(user.isExcluded || user.isDeleted)}>
        {user.roleInfo?.role || "Not Set"}
      </TableExcludedColumn>
    );
  },
};


export const MS_TEAMS_USER_ROLE_COLUMN: Column<IMsTeamsChannelUser> = {
  Header: 'Role',
  cellClassName: 'table-cell-text',
  accessor: (row) => row.role?.name || 'Not Set',
};


export const SLACK_USER_DEPARTMENT_COLUMN: Column<ISlackWorkspaceUser> = {
  Header: "Department",
  accessor: (row) => row.department || "Not Set",
  Cell: (cellProps: CellProps<ISlackWorkspaceUser>) => {
    const user = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={!!(user.isExcluded || user.isDeleted)}>
        {user.department || "Not Set"}
      </TableExcludedColumn>
    );
  },
};


export const MS_TEAMS_USER_DEPARTMENT_COLUMN: Column<IMsTeamsChannelUser> = {
  Header: 'Department',
  cellClassName: 'table-cell-text',
  accessor: (row) => row.department?.name || 'Not Set',
};


export const MANAGER_TO_COLUMN: Column<ISlackWorkspaceUser> | Column<IMsTeamsChannelUser> = {
  Header: "Manager To",
  cellClassName: 'table-cell-text',
  accessor: (row: ISlackWorkspaceUser | IMsTeamsChannelUser) => getManagerTo(row.manageesTo),
  Cell: (cellProps: CellProps<ISlackWorkspaceUser | IMsTeamsChannelUser>) => {
    const user = cellProps.row.original;

    return (
      <TableExcludedColumn
        isExcluded={!!((user as ISlackWorkspaceUser).isExcluded || (user as ISlackWorkspaceUser).isDeleted)}
      >
        {getManagerTo(user.manageesTo)}
      </TableExcludedColumn>
    );
  },
};


export const MEMBER_STATUS_COLUMN: Column<ISlackWorkspaceUser> = {
  id: "status",
  Header: "Status",
  accessor: (row) => row.isDeleted ? 'Deactivated' :
    row.isExcluded ? 'Inactive' : 'Active',
  Cell: (cellProps: CellProps<ISlackWorkspaceUser>) => {
    const user = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={!!(user.isExcluded || user.isDeleted)}>
        {user.isDeleted ? 'Deactivated' : user.isExcluded ? 'Inactive' : 'Active'}
      </TableExcludedColumn>
    );
  },
  filter: (
    rows: Row<ISlackWorkspaceUser>[],
    _: string[],
    filterValue: string
  ): Row<ISlackWorkspaceUser>[] => {

    switch (filterValue) {
      case SELECT_ALL_OPTION.value: {
        return rows;
      }

      case IActionTypes.Deactivate: {
        return rows.filter((row) => row.original.isDeleted);
      }

      case IActionTypes.Include: {
        return rows.filter((row) => !row.original.isExcluded && !row.original.isDeleted);
      }

      case IActionTypes.Exclude: {
        return rows.filter((row) => row.original.isExcluded && !row.original.isDeleted);
      }

      default: {
        return [];
      }
    }
  },
};


export const ACTION_COLUMN_CONFIG = {
  Header: 'Actions',
  cellWidth: TABLE_ACTION_COLUMN_WIDTH,
  cellClassName: 'text-center',
};


export const PROGRESS_COLUMN_CONFIG = {
  Header: 'Progress',
  cellWidth: TABLE_PROGRESS_COLUMN_WIDTH,
};


export const USER_NAME_COLUMN: Column<IOrgUser> = {
  id: 'name',
  Header: 'Name',
  accessor: 'name',
  Cell: (cellProps: CellProps<IOrgUser>) => {
    const user = cellProps.row.original;

    return (
      <Member member={{
        value: user.id,
        imageOriginal: user.imageOriginal,
        label: user.name,
        description: user.title,
        isExcluded: user.isExcluded || user.isDeleted
      }} />
    );
  }
};

export const USER_ROLE_COLUMN: Column<IOrgUser> = {
  Header: "Role",
  cellClassName: 'table-cell-text',
  accessor: (row) => row.role?.name || "Not Set",
  Cell: (cellProps: CellProps<IOrgUser>) => {
    const user = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={!!(user.isExcluded || user.isDeleted)}>
        {user.role?.name || "Not Set"}
      </TableExcludedColumn>
    );
  },
};

export const USER_DEPARTMENT_COLUMN: Column<IOrgUser> = {
  Header: "Department",
  accessor: (row) => row.department?.name || "Not Set",
  Cell: (cellProps: CellProps<IOrgUser>) => {
    const user = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={!!(user.isExcluded || user.isDeleted)}>
        {user.department?.name || "Not Set"}
      </TableExcludedColumn>
    );
  },
};

export const USER_MANAGEES: Column<IOrgUser> = {
  Header: "Manager To",
  cellClassName: 'table-cell-text',
  accessor: (row: IOrgUser) => row.managees?.length
    ? row.managees.map(user => user.name).join(", ")
    : "Not Set",
  Cell: (cellProps: CellProps<IOrgUser>) => {
    const user = cellProps.row.original;

    return (
      <TableExcludedColumn isExcluded={Boolean(user.isExcluded || user.isDeleted)}>
        {
          user.managees?.length
            ? user.managees.map(user => user.name).join(", ")
            : "Not Set"
        }
      </TableExcludedColumn>
    );
  },
};


export const USER_STATUS_COLUMN: Column<IOrgUser> = {
  id: "status",
  Header: "Status",
  accessor: (row) => row.isDeleted ? 'Deactivated' : row.isExcluded ? 'Inactive' : 'Active',
  Cell: (cellProps: CellProps<IOrgUser>) => {

    const user = cellProps.row.original;
    return (
      <TableExcludedColumn isExcluded={!!(user.isExcluded || user.isDeleted)}>
        {user.isDeleted ? 'Deactivated' : user.isExcluded ? 'Inactive' : 'Active'}
      </TableExcludedColumn>
    );
  },
  filter: (rows: Row<IOrgUser>[], _: string[], filterValue: string): Row<IOrgUser>[] => {

    switch (filterValue) {
      case SELECT_ALL_OPTION.value: {
        return rows;
      }

      case IActionTypes.Deactivate: {
        return rows.filter((row) => row.original.isDeleted);
      }

      case IActionTypes.Include: {
        return rows.filter((row) => !row.original.isExcluded && !row.original.isDeleted);
      }

      case IActionTypes.Exclude: {
        return rows.filter((row) => row.original.isExcluded && !row.original.isDeleted);
      }

      default: {
        return [];
      }
    }
  },
};
