import { IReviewTemplateFormValues, IReviewTemplateQuestion } from 'interfaces';
import { FormContext } from 'contexts';
import * as React from 'react';
import dragula from 'dragula';
import { Accordion } from '@themesberg/react-bootstrap';
import ReviewTemplateQuestionWidget from './ReviewTemplateQuestionWidget';
import { FormikProps } from 'formik';


interface IQuestionsAccordionProps {
  activeQuestionIndex: number | null;
  elRefs: React.RefObject<HTMLDivElement>[];
  onAccordionToggle: (index: number, ref: React.RefObject<HTMLDivElement>) => void
}


const ReviewTemplateQuestionsAccordion: React.FunctionComponent<IQuestionsAccordionProps> = (
  { activeQuestionIndex, elRefs, onAccordionToggle }
) => {


  const reviewForm = React.useContext<FormikProps<IReviewTemplateFormValues>>(
    FormContext as React.Context<FormikProps<IReviewTemplateFormValues>>
  );
  const dragulaContainerRef = React.useRef<HTMLDivElement | null>(null);


  React.useEffect(
    () => {

      let drake: dragula.Drake;
      const updateDraggedBlockIndex = (element: HTMLDivElement): void => {
        let dataIndex = element.getAttribute('data-index');
        if (dataIndex !== null) {

          const sourceIndex = +dataIndex;
          const targetIndex = Array.prototype.indexOf.call(dragulaContainerRef.current?.children, element);

          if (sourceIndex === targetIndex) {
            return;
          }

          const questionsCopy: IReviewTemplateQuestion[] = [...reviewForm.values.blocks];
          const questionToMove = questionsCopy.splice(+sourceIndex, 1)[0];
          questionsCopy.splice(targetIndex, 0, questionToMove);
          reviewForm.setFieldValue('blocks', questionsCopy);

          if (activeQuestionIndex !== targetIndex) {
            onAccordionToggle(targetIndex, elRefs[targetIndex]);
          }
        }
      };

      if (dragulaContainerRef.current) {
        drake = dragula([dragulaContainerRef.current]);
        drake.on('dragend', (element) => {
          updateDraggedBlockIndex(element as HTMLDivElement);
        });
      }

      return () => {
        if (drake) {
          drake.destroy();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewForm, activeQuestionIndex]
  );


  const handleQuestionRemove = (index: number) => {

    const questionsCopy = [...reviewForm.values.blocks];
    questionsCopy.splice(index, 1);
    reviewForm.setFieldValue('blocks', questionsCopy);

    if (activeQuestionIndex !== null) {
      if (index === reviewForm.values.blocks.length - 1) {
        onAccordionToggle(index - 1, elRefs[index - 1]);
      } else {
        onAccordionToggle(index, elRefs[index]);
      }
    }

  };


  const handleQuestionDuplicate = (index: number) => {

    const questionsCopy = [...reviewForm.values.blocks];
    questionsCopy.splice(index + 1, 0, {
      ...questionsCopy[index],
      tempId: (reviewForm.values.blocks.length + 1).toString()
    });
    reviewForm.setFieldValue('blocks', questionsCopy);
    setTimeout(() => {
      onAccordionToggle(index + 1, elRefs[index + 1]);
    }, 500);
  };


  const handleQuestionMoveUp = (index: number) => {

    if (index !== 0) {
      const questionsCopy = [...reviewForm.values.blocks];
      const questionToMoveUp = questionsCopy.splice(index, 1)[0];
      questionsCopy.splice(index - 1, 0, questionToMoveUp);
      reviewForm.setFieldValue('blocks', questionsCopy);
      onAccordionToggle(index - 1, elRefs[index - 1]);
    }
  };


  const handleQuestionMoveDown = (index: number) => {

    if (index !== (reviewForm.values.blocks.length - 1)) {
      const questionsCopy = [...reviewForm.values.blocks];
      const questionToMoveDown = questionsCopy.splice(index, 1)[0];
      questionsCopy.splice(index + 1, 0, questionToMoveDown);
      reviewForm.setFieldValue('blocks', questionsCopy);
      onAccordionToggle(index + 1, elRefs[index + 1]);
    }
  };


  return (
    <Accordion activeKey={activeQuestionIndex?.toString()}>
      <div ref={dragulaContainerRef}>
        {
          reviewForm.values.blocks.map(
            (question, index) => {
              return (
                <div
                  data-index={index}
                  ref={elRefs[index]}
                  key={question.tempId || question.id}
                >
                  <ReviewTemplateQuestionWidget
                    index={index}
                    question={question}
                    onRemove={() => { handleQuestionRemove(index); }}
                    onMoveUp={() => { handleQuestionMoveUp(index); }}
                    onMoveDown={() => { handleQuestionMoveDown(index); }}
                    onDuplicate={() => { handleQuestionDuplicate(index); }}
                    onHeaderClick={() => { onAccordionToggle(index, elRefs[index]); }}
                  />
                </div>
              );
            }
          )
        }
      </div>
    </Accordion>
  );
};

export default ReviewTemplateQuestionsAccordion;