import { IFeedbackTypes } from "interfaces";
import React, { useEffect } from "react";
import { FEEDBACK_TYPES } from "teamble-constants";

export const useFeedbackType = (feedbackLoopFeedbackType?: IFeedbackTypes): string => {

  const [feedbackType, setFeedbackType] = React.useState<string>('');

  useEffect(
    () => {

      const feedType = FEEDBACK_TYPES.find(
        (feedback) => feedback.value === feedbackLoopFeedbackType
      );

      if (feedType) {
        setFeedbackType(feedType.label);
        return;
      }

      setFeedbackType('');

    },
    [feedbackLoopFeedbackType]
  )

  return feedbackType;
};