import {
  IChannelsAudience,
  IFeedbackLoop,
  IGroupsAudience,
  ISimpleMenuOption,
  IUsersAudience,
  SignInWith,
} from 'interfaces';
import React from 'react';


export const useAudience = <T extends ISimpleMenuOption>(
  type: 'users' | 'channels' | 'groups',
  options: T[],
  feedbackLoop: IFeedbackLoop,
  app: SignInWith,
): ISimpleMenuOption[] => {

  const [selectedAudienceOptions, setSelectedAudienceOptions] = React.useState<ISimpleMenuOption[]>([]);

  React.useEffect(
    () => {

      if (
        !options.length ||
        !feedbackLoop.audience?.length
      ) {
        setSelectedAudienceOptions([]);
        return;
      }

      let selectedOptionIds: string[] = [];

      feedbackLoop.audience!.forEach(
        (aud) => {

          switch (aud.type) {

            case "users": {
              const audienceUsersOrChannels = (aud as IUsersAudience).users;
              selectedOptionIds = audienceUsersOrChannels.map(
                (userOrChannel) => (userOrChannel.channelInfo as IUsersAudience['users'][0]['channelInfo'])[app].userId
              );
              break;
            }

            case "channels": {
              const audienceUsersOrChannels = (aud as IChannelsAudience).channels;
              selectedOptionIds = audienceUsersOrChannels.map(
                (userOrChannel) => (userOrChannel.channelInfo as IChannelsAudience['channels'][0]['channelInfo']).slack.channelId
              );
              break;
            }

            case "groups": {
              const audienceUsersOrChannels = (aud as IGroupsAudience).groups;
              selectedOptionIds = audienceUsersOrChannels.map(
                (userOrChannel) => (userOrChannel.channelInfo as IGroupsAudience['groups'][0]['channelInfo']).msteams.groupId
              );
              break;
            }

            default:
              break;
          }

          // if (aud.type === type) {
          //   const audienceUsersOrChannels = type === 'users' ?
          //     (aud as IUsersAudience).users :
          //     (aud as IChannelsAudience).channels;

          //   selectedOptionIds = audienceUsersOrChannels
          //     .map(
          //       (userOrChannel) => {
          //         return type === 'users'
          //           ?
          //           (userOrChannel.channelInfo as IUsersAudience['users'][0]['channelInfo'])[app].userId
          //           :
          //           (userOrChannel.channelInfo as IChannelsAudience['channels'][0]['channelInfo']).slack.channelId
          //       }
          //     );
          // }
        }
      );

      if (!selectedOptionIds.length) {
        setSelectedAudienceOptions([]);
        return;
      }

      const selectedOptions = options.filter(
        (member) => selectedOptionIds.includes(member.value)
      );

      setSelectedAudienceOptions(selectedOptions);

    },
    [app, feedbackLoop.audience, options, type]
  );

  return selectedAudienceOptions;
};
